import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';

import YetiGraphQLConnection from './YetiGraphQLConnection';
import YetiGraphQLRequester from './YetiGraphQLRequester';
import YetiGraphQLSubscriber from './YetiGraphQLSubscriber';

import Log from '../log/Log';
import { getCustomTemplateCountsV2 } from '../templates/TemplateUtils.js';
import { loadBuiltInTemplates } from '../templates/TemplateLoader.js';
import {deflateStr, isString} from "../templates/TemplateUtils";
import SingularLiveGraphicsController from './SingularLiveGraphicsController';
import { FacebookGetAllActiveBroadcasts } from './FacebookController';

const base64 = require('base-64');
const pako = require('pako');
const jsonic = require('jsonic');

class ClearCasterAdminController
{
	constructor(graphQLCredentials)
	{
		this.graphQLCredentials = graphQLCredentials;
		this.graphQLConnection = new YetiGraphQLConnection(graphQLCredentials);
		this.graphQLRequester = new YetiGraphQLRequester(this.graphQLConnection);
		this.graphQLSubscriber = new YetiGraphQLSubscriber(this.graphQLConnection);

		this.pollingTimer = undefined;
		this.log = Log;
		this.templateInfoBuiltIn = undefined;
		this.state = ClearCasterStore.getState();
	}

	setGraphQLCredentials(graphQLCredentials)
	{
		this.graphQLConnection.setGraphQLCredentials(graphQLCredentials);
	}

	async getUserNamesAndRoles(namespaceId)
	{
		let meData = {};
		let retData = await this.graphQLRequester.getMe();
		if (retData.data != null && retData.data.me != null)
			meData = retData.data.me;

		let action = {
			type:ActionTypes.SET_USER_NAMES_AND_ROLES,
			email:'',
			firstName:'',
			lastName:'',
			roles:[],
			id:'',
			admin:false
		};
		if (meData['user'] != null)
		{
			if (meData['user']['email'] != null)
				action.email = meData['user']['email'];
			if (meData['user']['firstName'] != null)
				action.firstName = meData['user']['firstName'];
			if (meData['user']['lastName'] != null)
				action.lastName = meData['user']['lastName'];
			if (meData['user']['namespaceUsers'] != null &&
				meData['user']['namespaceUsers'].length > 0)
			{
				for (let i = 0; i < meData.user.namespaceUsers.length; i++)
				{
					let currUser = meData.user.namespaceUsers[i];
					if (currUser.namespace.id === namespaceId)
					{
						action.id = currUser.id;
						action.admin = currUser.admin;
						action.roles = currUser.roles;
					}
				}
			}
			ClearCasterStore.dispatch(action);
		}
	}

	userIsAdmin(){
		return this.state.userWowza.admin ? true : false;
	}

	userIsSubscriptionCurrent()
	{
		if (process.env.REACT_APP_ENABLE_SUBSCRIPTIONS === "true" && this.state.userWowza.subscriptionInfo.loaded)
		{
			// -1 is unlimited; 0 is none; >0 is some
			return this.state.userWowza.subscriptionInfo.service_channel_count !== 0;
		}
		return true;
	}

	userIsSubscriptionUnderwater()
	{
		if (process.env.REACT_APP_ENABLE_SUBSCRIPTIONS === "true" && this.state.userWowza.subscriptionInfo.loaded)
		{
			return (this.state.userWowza.subscriptionInfo.service_channel_count < this.state.encoderList.length && this.state.userWowza.subscriptionInfo.service_channel_count >= 0);
		}
		return false;
	}

	userIsSubscriptionAtGoLiveLimit()
	{
		if (!this.userIsSubscriptionCurrent())
		{
			return true;
		}
		if (this.userIsSubscriptionUnderwater())
		{
			return this.state.userWowza.subscriptionInfo.service_channel_count <= this.state.broadcastsNumLive;
		}
		return false;
	}

	userIsSubscriptionDirect()
	{
		if (process.env.REACT_APP_ENABLE_SUBSCRIPTIONS === "true" && this.state.userWowza.subscriptionInfo.loaded)
		{
			if (this.state.userWowza.subscriptionInfo.service_data['clearcaster_service'] != null)
			{
				let clearcaster_services = this.state.userWowza.subscriptionInfo.service_data['clearcaster_service'];
				for (let i = 0; i < clearcaster_services.length; i++)
				{
					if (clearcaster_services[i]['sales_model'] != null && clearcaster_services[i]['sales_model'].toUpperCase() !== 'DIRECT')
					{
						return false;
					}
				}
			}
		}
		return true;
	}

	userSubscriptionDaysUntilUnderwater()
	{
		let retVal = -1;
		if (process.env.REACT_APP_ENABLE_SUBSCRIPTIONS === "true" &&
			this.state.userWowza.subscriptionInfo.loaded &&
			this.state.userWowza.subscriptionInfo.service_underwater_date > 0)
		{
			let now = new Date();
			let underwaterDate = new Date(this.state.userWowza.subscriptionInfo.service_underwater_date);
			retVal = (underwaterDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
		}
		return retVal;
	}

	userSubscriptionDaysUntilAutoRenew()
	{
		let retVal = -1;
		if (process.env.REACT_APP_ENABLE_SUBSCRIPTIONS === "true" &&
			this.state.userWowza.subscriptionInfo.loaded &&
			this.state.userWowza.subscriptionInfo.service_auto_renew_expiration > 0)
		{
			let now = new Date();
			let autoRenewDate = new Date(this.state.userWowza.subscriptionInfo.service_auto_renew_expiration);
			retVal = (autoRenewDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
		}
		return retVal;
	}

	async getGraphQLVersion()
	{
		let versionData = await this.graphQLRequester.getGraphQLVersion();

		if (versionData['data'] !== undefined && versionData['data'] !== null && versionData['data']['build'] !== undefined && versionData['data']['version'] !== undefined)
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_GRAPHQLVERSION,
				build: versionData['data']['build'],
				version: versionData['data']['version']
			});
		}
	}

	addToUniqueBitrates(bitrate, uniqueBitrates = undefined)
	{
		if (uniqueBitrates !== undefined && isString(bitrate))
		{
			if (!uniqueBitrates.includes(bitrate))
				uniqueBitrates.push(bitrate);
		}

	}

	async loadNamespaceExtraProperties()
	{
		let extraProperties = await this.graphQLRequester.getNamespaceExtraProperties();

		let subscriptionInfo = {
			service_channel_count:0,
			service_auto_renew_expiration:-1,
			service_non_auto_expiration:-1,
			service_data:{},
			service_underwater_date:-1
		};
		let manageSettings = {};
		let additionalTemplates = {};
		let namespaceAssetPackageData = null;
		let overlayGraphics = {};
		let overlayGraphicsData = {};

		if (extraProperties !== undefined && extraProperties.data !== undefined &&
			extraProperties.data.allNamespaces !== undefined &&
			extraProperties.data.allNamespaces.length > 0 &&
			extraProperties.data.allNamespaces[0].extraProperties !== undefined &&
			extraProperties.data.allNamespaces[0].extraProperties.length > 0
		)
		{
			// subscriptions
			for (let key in extraProperties.data.allNamespaces[0].extraProperties)
			{
				let property = extraProperties.data.allNamespaces[0].extraProperties[key];

				if (property.name === "com.wowza.clearcaster.service_channel_count" && property.value !== undefined)
				{
					subscriptionInfo.service_channel_count = parseInt(property.value);
				}
				if (property.name === "com.wowza.clearcaster.service_auto_renew_expiration" && property.value !== undefined)
				{
					subscriptionInfo.service_auto_renew_expiration = parseInt(property.value);
				}
				if (property.name === "com.wowza.clearcaster.service_non_auto_expiration" && property.value !== undefined)
				{
					subscriptionInfo.service_non_auto_expiration = parseInt(property.value);
				}
				if (property.name === "com.wowza.clearcaster.service_underwater_date" && property.value !== undefined)
				{
					subscriptionInfo.service_underwater_date = parseInt(property.value);
				}
				if (property.name === "com.wowza.clearcaster.service_data" && property.value !== undefined)
				{
					let binaryString = base64.decode(property.value.replace(/"/g,''));
					subscriptionInfo.service_data = jsonic(pako.inflate(binaryString, {to: 'string'}));
				}

			}

			// manageSettings
			for (let key in extraProperties.data.allNamespaces[0].extraProperties)
			{
				let property = extraProperties.data.allNamespaces[0].extraProperties[key];

				if (property.name === "com.wowza.clearcaster.manageSettings" && property.value !== undefined)
				{
					try
					{
						let binaryString = base64.decode(property.value);

						manageSettings = jsonic(pako.inflate(binaryString, {to: 'string'}));

						// console.log(JSON.stringify(manageSettings, null, 2));
					} catch (error)
					{
						console.log("ERROR: loadNamespaceExtraProperties: Error loading manageSettings: namespaceId:" + extraProperties.data.allNamespaces[0]);
					}
				}
			}
			if (manageSettings !== {})
			{
				if (manageSettings['limitAccess'] == null)
					manageSettings['limitAccess'] = false;

				ClearCasterStore.dispatch({
					type:ActionTypes.SET_MANAGE_SETTINGS,
					manageSettings:manageSettings
				});
			}

			// loadBroadcastTemplates
			for (let key in extraProperties.data.allNamespaces[0].extraProperties)
			{
				let property = extraProperties.data.allNamespaces[0].extraProperties[key];

				if (property.name === "com.wowza.clearcaster.customTemplates" && property.value !== undefined)
				{
					try
					{
						let binaryString = base64.decode(property.value);

						additionalTemplates = jsonic(pako.inflate(binaryString, {to: 'string'}));

						//console.log(JSON.stringify(additionalTemplates, null, 2));
					}
					catch (error)
					{
						console.log("ERROR: loadNamespaceExtraProperties: Error decoding extra templates: namespaceId:" + extraProperties.data.allNamespaces[0]);
					}
					break;
				}
			}

			// loadOverlayGraphics and loadAssetPackageData
			for (let key in extraProperties.data.allNamespaces[0].extraProperties)
			{
				let property = extraProperties.data.allNamespaces[0].extraProperties[key];

				if (property.name === "com.wowza.clearcaster.overlayGraphics" && property.value !== undefined)
				{
					try
					{
						let binaryString = base64.decode(property.value);

						overlayGraphics = jsonic(pako.inflate(binaryString, {to: 'string'}));

						// console.log(JSON.stringify(overlayGraphics, null, 2));
					} catch (error)
					{
						console.log("ERROR: loadNamespaceExtraProperties: Error decoding overlay graphics: namespaceId:" + extraProperties.data.allNamespaces[0]);
						overlayGraphics = {graphics:{appInstances:[]}};
					}
				}
				else if (property.name.startsWith("com.wowza.clearcaster.overlayGraphicsData.") && property.value !== undefined)
				{
					try
					{
						let binaryString = base64.decode(property.value);

						let overlayGraphicsDataItem = jsonic(pako.inflate(binaryString, {to: 'string'}));
						let overlayGraphicsId = property.name.substr("com.wowza.clearcaster.overlayGraphicsData.".length);

						if (overlayGraphicsDataItem.hasOwnProperty('slate1'))
						{
							overlayGraphicsData[overlayGraphicsId] = overlayGraphicsDataItem;
						}
						// Else just forget old stuff. I think this only fixes Jan's QA namespace
					} catch (error)
					{
						console.log("ERROR: loadNamespaceExtraProperties: Error decoding overlay graphics: namespaceId:" + extraProperties.data.allNamespaces[0]);
					}
				}
				else if (property.name.startsWith("com.wowza.clearcaster.assetPackageData") && property.value !== undefined)
				{
					try
					{
						let binaryString = base64.decode(property.value);
						namespaceAssetPackageData = jsonic(pako.inflate(binaryString, {to: 'string'}));
					}
					catch (error)
					{
						console.log("ERROR: loadNamespaceExtraProperties: Error decoding asset package data: namespaceId:" + extraProperties.data.allNamespaces[0]);
					}
				}
			}
			if (overlayGraphics.graphics != null && overlayGraphics.graphics.appInstances != null)
			{
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_SINGULAR_LIVE_APP_INSTANCES,
					appInstances:overlayGraphics.graphics.appInstances,
					data:overlayGraphicsData
				});
				if (overlayGraphics.graphics.appInstances.instances != null)
				{
					for(let key in overlayGraphics.graphics.appInstances.instances)
					{
						await SingularLiveGraphicsController.getAppInstanceShownById(overlayGraphics.graphics,key);
					}
				}
			}
			if (namespaceAssetPackageData != null)
			{
				// let currentState = ClearCasterStore.getState();
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_NAMESPACE_ASSET_PACKAGE_DATA,
					namespaceAssetPackageData:namespaceAssetPackageData
				});
			}
		}
		// Always save subscriptions to handle case where there's no data present
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_USER_SUBSCRIPTION_INFO,
			subscriptionInfo:subscriptionInfo
		});
		// Handle cases where no data is present
		if (overlayGraphics.graphics == null || overlayGraphics.graphics.appInstances == null)
		{
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_SINGULAR_LIVE_APP_INSTANCES,
				appInstances:[],
				data:{}
			});
		}
		this.updateCustomTemplates(additionalTemplates);
	}

	async persistCustomTemplates(customTemplatePackageDeflated)
	{
		let input = {
			name: "com.wowza.clearcaster.customTemplates",
			value: customTemplatePackageDeflated,
			type: "String"
		};

		try
		{
			await this.setNamespaceExtraProperty(input);
		}
		catch(error)
		{

			console.log("persistCustomTemplates[request]: "+JSON.stringify(error));
		}
	}

	enableGraphics()
	{
		return this.graphQLRequester.setNamespaceExtraPropertyOverlayGraphics(this.state.userWowza.namespaceId);
	}

	async persistGraphicsData(graphicsId, graphicsData)
	{
		let graphicsDataDeflated = deflateStr(JSON.stringify(graphicsData));

		let input = {
			name: "com.wowza.clearcaster.overlayGraphicsData."+graphicsId,
			value: graphicsDataDeflated,
			type: "String"
		};

		try
		{
			await this.setNamespaceExtraProperty(input);
		}
		catch(error)
		{

			console.log("persistGraphicsData[request]: "+JSON.stringify(error));
		}
	}

	async persistManageSettings(manageSettings)
	{
		let manageSettingsDeflated = deflateStr(JSON.stringify(manageSettings));

		let input = {
			name: "com.wowza.clearcaster.manageSettings",
			value: manageSettingsDeflated,
			type: "String"
		};

		try
		{
			await this.setNamespaceExtraProperty(input);
		}
		catch(error)
		{
			console.log("persistManageSettings[request]: "+JSON.stringify(error));
		}
	}

	async persistLinkedInAccessToken(name,authCode)
	{
		console.log(name + " " + authCode);
		let input = {
			name: name,
			accessKey: authCode,
			secretKey: authCode,
			type: "LINKEDIN_ACCESS_TOKEN"
		};

		this.mutationCreateProviderKey(input).then((resp) => {
			// {"data":{"createProviderKey":{"id":"a14f351bb0496b4203bff1c4a13da22aab"}}}
			console.log(JSON.stringify(resp));
		});
	}

	async persistFacebookPairedAccessToken(broadcastId, name, accessToken)
	{
		let input = {
			name: name,
			accessKey: accessToken,
			secretKey: accessToken,
			broadcastId: broadcastId,
			type: "FACEBOOK_GRAPH_API_SHORT"
		};

		this.mutationCreateProviderKey(input).then((resp) => {
			console.log(JSON.stringify(resp));
		});
	}

	async updateCustomTemplates(customTemplatePackage)
	{
		if (this.templateInfoBuiltIn === undefined)
		{
			this.templateInfoBuiltIn = await loadBuiltInTemplates();
		}

		let templateInfo = JSON.parse(JSON.stringify(this.templateInfoBuiltIn));

		let settings = {
			showBuiltInTemplates: true
		}

		if (customTemplatePackage !== undefined)
		{
			if (customTemplatePackage.settings !== undefined)
			{
				if (customTemplatePackage.settings.showBuiltInTemplates !== undefined)
				{
					settings.showBuiltInTemplates = customTemplatePackage.settings.showBuiltInTemplates;
				}
			}

			if (customTemplatePackage.templateCategories !== undefined)
			{
				for(let key in customTemplatePackage.templateCategories)
				{
					for(let setId in templateInfo.templateSets)
					{
						let templateSet = templateInfo.templateSets[setId];

						templateSet[key] = customTemplatePackage.templateCategories[key];
					}

					if (customTemplatePackage.templateCategoryOrder === undefined)
						templateInfo.templateCategoryOrder.push(key);
				}
			}

			if (customTemplatePackage.templateCategoryOrder !== undefined)
			{
				for(let key in customTemplatePackage.templateCategoryOrder)
				{
					templateInfo.templateCategoryOrder.push(key);
				}
			}

			if (customTemplatePackage.templates !== undefined)
			{
				for(let key in customTemplatePackage.templates)
				{
					templateInfo.templates[key] = customTemplatePackage.templates[key];
				}
			}
		}

		for(let setId in customTemplatePackage.templateSets)
		{
			let templateSet = customTemplatePackage.templateSets[setId];

			for(let key in templateSet)
			{
				templateSet[key].id = key;
			}
		}

		let uniqueBitrates = [];

		for(let key in templateInfo.templates)
		{
			let template = templateInfo.templates[key];

			template.id = key;
		}

		let customTemplateCounts = getCustomTemplateCountsV2(templateInfo, "pro");

		templateInfo.settings = settings;

		await ClearCasterStore.dispatch({
			type: ActionTypes.SET_BROADCAST_TEMPLATES,
			broadcastTemplates: templateInfo,
			uniqueBitrates: uniqueBitrates,
			customTemplateCounts: customTemplateCounts
		});
	}

	async startPolling()
	{
		if (this.pollingTimer)
			clearInterval(this.pollingTimer);

		this.fetchPollingData();
	}

	stopPolling()
	{
		if (this.pollingTimer)
			clearInterval(this.pollingTimer);
		this.pollingTimer = undefined;
	}

	async forcePolling()
	{
		if (this.pollingTimer)
			clearInterval(this.pollingTimer);
		this.pollingTimer = undefined;

		await this.fetchPollingData();
	}

	async fetchPollingData()
	{
		if (this.state.userWowza.namespaceId !== null)
		{
			this.getLiveBroadcastHealths(this.state.userWowza.namespaceId);
		}
		let result = await this.graphQLRequester.fetchPollingData();

		console.log('fetchPollingData');

		if (this.state.userWowza.namespaceId === null
			&& result.data != null
			&& result.data.namespaces != null
			&& result.data.namespaces[0] != null
			&& result.data.namespaces[0].id != null)
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_USER_NAMESPACE_ID,
				namespaceId: result.data.namespaces[0].id
			});
			this.getUserNamesAndRoles(result.data.namespaces[0].id);
		}

		if (result !== undefined)
		{
			var action = {
				type:ActionTypes.UPDATE_REMOTEMODEL_DATA
			};

			if (result.data !== undefined && result.data.encoders !== undefined)
			{
				action.encoders = result.data.encoders;
			}

			if (result.data !== undefined && result.data.broadcasts !== undefined)
			{
				action.broadcasts = result.data.broadcasts;
			}

			if (result.data !== undefined && result.data.namespaces !== undefined && result.data.namespaces.length === 1)
			{
				action.namespace = result.data.namespaces[0];
			}

			if (action.encoders !== undefined || action.broadcasts !== undefined || action.namespace !== undefined)
				ClearCasterStore.dispatch(action);
		}

		this.pollingTimer = setTimeout( () => {
			this.fetchPollingData();
		},process.env.REACT_APP_POLLING_INTERVAL);

	}

	initSubscriptions(){
		for (const broadcast of Object.entries(this.state.broadcastMap)) {
			this.graphQLSubscriber.subscribeToBroadcastState(broadcast[0])
		}
	}

	async getBroadcastGoLiveEvents()
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_BROADCAST_GO_LIVE_EVENTS_LOADING,
			loading:true
		});

		return this.graphQLRequester.getBroadcastGoLiveEvents(this.state.userWowza.namespaceId)
		.then((result)=>{
			if (result['data'] && result['data']['broadcastGoLiveEventsByNamespace'])
			{
				let action = {
					type:ActionTypes.LOAD_BROADCAST_GO_LIVE_EVENTS,
					data:result['data']['broadcastGoLiveEventsByNamespace']
				};
				ClearCasterStore.dispatch(action);
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_BROADCAST_GO_LIVE_EVENTS_LOADING,
					loading:false
				});
			}
		});
	}

	async getBroadcastAccessKeysMap()
	{
		return this.graphQLRequester.getBroadcastAccessKeys()
			.then((result)=>{
				if (result['data'] &&
					result['data']['allBroadcasts'])
				{
					let broadcastAccessKeysMap = {};
					for (let i = 0; i < result.data.allBroadcasts.length; i++)
					{
						let broadcastId = result.data.allBroadcasts[i].id;
						let accessKeys = result.data.allBroadcasts[i].accessKeys;
						broadcastAccessKeysMap[broadcastId] = accessKeys;
					}
					ClearCasterStore.dispatch({
						type:ActionTypes.UPDATE_BROADCAST_ACCESSKEYS_MAP,
						broadcastAccessKeysMap:broadcastAccessKeysMap
					});
				}
			});
	}

	async getNamespaceAccessKeys()
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ACCESS_KEYS_LOADING,
			loading:true
		});

		return this.graphQLRequester.getNamespaceAccessKeys(this.state.userWowza.namespaceId)
		.then((result)=>{
			if (result['data'] &&
				result['data']['namespace'] &&
				result['data']['namespace']['accessKeys']
			)
			{
				let accessKeysMap = {};
				for (let i = 0; i < result.data.namespace.accessKeys.length; i++)
				{
					let key = result.data.namespace.accessKeys[i];
					accessKeysMap[key.id] = key;
				}
				let action = {
					type:ActionTypes.LOAD_ACCESS_KEYS,
					accessKeys:result.data.namespace.accessKeys,
					accessKeysMap:accessKeysMap
				};
				ClearCasterStore.dispatch(action);
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_ACCESS_KEYS_LOADING,
					loading:false
				});
			}
		});
	}

	async getNamespaceUsers()
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_USERS_LOADING,
			loading:true
		});

		return this.graphQLRequester.getNamespaceUsers(this.state.userWowza.namespaceId)
			.then((result)=>{
				if (result['data'] &&
					result['data']['namespace'] &&
					result['data']['namespace']['namespaceUsers']
				)
				{
					let action = {
						type:ActionTypes.LOAD_USERS,
						users:result.data.namespace.namespaceUsers,
					};
					ClearCasterStore.dispatch(action);
					ClearCasterStore.dispatch({
						type:ActionTypes.SET_USERS_LOADING,
						loading:false
					});
				}
			});
	}

	async setNamespaceUserPrivs(namespaceUserId, input)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_USER_UPDATING,
			updating:true,
			id:namespaceUserId
		});
		await this.graphQLRequester.setNamespaceUserPrivs(namespaceUserId,input);
		await this.getNamespaceUsers();
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_USER_UPDATING,
			updating:false,
			id:namespaceUserId
		});
	}

	async getLiveBroadcastHealths(namespaceUserId)
	{
		return this.graphQLRequester.queryGetBroadcastsByStatus(namespaceUserId,['LIVE'])
		.then((result)=>{
			if (result['data'] && result['data']['broadcastsByStatus'])
			{
				let action = {
					type:ActionTypes.UPDATE_LIVE_BROADCAST_HEALTHS,
					broadcasts:{}
				};
				for (let i in result['data']['broadcastsByStatus'])
				{
					action.broadcasts[result['data']['broadcastsByStatus'][i].id] = result['data']['broadcastsByStatus'][i];
				}
				ClearCasterStore.dispatch(action);
			}
		})
	}

	async getNearRealTimeDataByBroadcast(broadcastId,startDateTime,endDateTime)
	{
		return this.graphQLRequester.getNearRealTimeDataByBroadcast(broadcastId,startDateTime,endDateTime)
		.then((result)=>{
			if (result['data'] && result['data']['nearRealTimeDataByBroadcast'])
			{
				let action = {
					type:ActionTypes.LOAD_LIVE_BROADCAST_DATA
				};
				action.broadcastId = broadcastId;
				action.data = result['data']['nearRealTimeDataByBroadcast'];
				ClearCasterStore.dispatch(action);
			}
		});
	}

	async getNearRealTimeArchiveDataByBroadcast(broadcastGoLiveId,broadcastId,startDateTime,endDateTime)
	{
		return this.graphQLRequester.getNearRealTimeArchiveDataByBroadcast(broadcastId,startDateTime,endDateTime)
		.then((result)=>{
			if (result['errors'])
			{
				console.log('ERROR fetching archive data');
				console.log(result);
			}
			if (result['data'] && result['data']['nearRealTimeDataByBroadcast'])
			{
				let action = {
					type:ActionTypes.LOAD_ARCHIVE_BROADCAST_DATA
				};
				action.broadcastGoLiveId = broadcastGoLiveId;
				action.broadcastId = broadcastId;
				action.startDateTime = startDateTime;
				action.endDateTime = endDateTime;
				action.data = result['data']['nearRealTimeDataByBroadcast'];
				ClearCasterStore.dispatch(action);
			}
		});
	}

	async getArchiveData(broadcastGoLiveId,broadcastId,startTime,endTime)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ARCHIVE_BROADCAST_DATA_LOADING,
			broadcastGoLiveId:broadcastGoLiveId,
			loading:true
		});

		for (let loopEndTimeMS = endTime.getTime(); loopEndTimeMS > startTime.getTime(); loopEndTimeMS -= (5 * 60000))
		{
			let loopEndTime = new Date(loopEndTimeMS);
			let loopStartTime = new Date((loopEndTimeMS - (5 * 60000)));
			if (loopStartTime < startTime)
				loopStartTime = startTime;

			await this.getNearRealTimeArchiveDataByBroadcast(
				broadcastGoLiveId,
				broadcastId,
				loopStartTime,
				loopEndTime
			);
		}

		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ARCHIVE_BROADCAST_DATA_LOADING,
			broadcastGoLiveId:broadcastGoLiveId,
			loading:false
		});
	}

	async getFacebookActiveBroadcasts(userFacebook)
	{
		let facebookActiveBroadcasts = await FacebookGetAllActiveBroadcasts(userFacebook);
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_ACTIVE_BROADCASTS,
			facebookActiveBroadcasts:facebookActiveBroadcasts
		});
	}

	getAllEncoders()
	{
		this.graphQLRequester.getAllEncoders();
	}

	async preflightActivateEncoder(activateForm)
	{
		let activationInput = {
			"activationCode":activateForm.activationCode,
			"encoderName":""
		};
		return this.graphQLRequester.preflightActivateEncoder(this.state.userWowza.namespaceId,activationInput);
	}

	async activateEncoder(activateForm)
	{
		let activationInput = {
			"activationCode":activateForm.activationCode,
			"encoderName":activateForm.activateName
		};
		return this.graphQLRequester.activateEncoder(this.state.userWowza.namespaceId,activationInput);
	}

	async getEncoderCommandStatus(correlationId)
	{
		return this.graphQLRequester.getEncoderCommandStatus(correlationId);
	}

	async renameEncoder(encoderId,name)
	{
		return this.graphQLRequester.renameEncoder(encoderId,name);
	}

	async getLocalVersions(encoderId)
	{
		return this.graphQLRequester.getLocalVersions(encoderId);
	}

	async setEncoderEncoderAssetInfo(encoderId,input)
	{
		return this.graphQLRequester.setEncoderEncoderAssetInfo(encoderId,input);
	}

	async updateEncoder(encoderId)
	{
		return this.graphQLRequester.updateEncoder(encoderId);
	}

	async updateEncoderWithUpdateString(encoderId,jsonStrBase64)
	{
		return this.graphQLRequester.updateEncoderWithUpdateString(encoderId,jsonStrBase64);
	}

	async restartEncoder(encoderId)
	{
		return this.graphQLRequester.restartEncoder(encoderId);
	}

	async deactivateEncoder(encoderId)
	{
		return this.graphQLRequester.deactivateEncoder(encoderId);
	}

	async sendEncoderLogs(encoderId)
	{
		return this.graphQLRequester.sendEncoderLogs(encoderId);
	}

	async createAccessKey(name)
	{
		return this.graphQLRequester.createAccessKey(this.state.userWowza.namespaceId,name);
	}

	async createAccessKey2(identityId,input)
	{
		return this.graphQLRequester.createAccessKey2(identityId,input);
	}

	async deleteAccessKey(id)
	{
		return this.graphQLRequester.deleteAccessKey(id);
	}

	createBroadcast(input)
	{
		return this.graphQLRequester.createBroadcast(this.state.userWowza.namespaceId, input);
	}

	setBroadcastExtraProperty(broadcastId,input)
	{
		return this.graphQLRequester.setBroadcastExtraProperty(broadcastId,input);
	}

	setNamespaceExtraProperty(input)
	{
		return this.graphQLRequester.setNamespaceExtraProperty(this.state.userWowza.namespaceId, input);
	}

	activateBroadcastEncoders(broadcastId)
	{
		return this.graphQLRequester.activateBroadcastEncoders(this.state.userWowza.namespaceId, broadcastId);
	}

	deactivateBroadcastEncoders(broadcastId)
	{
		return this.graphQLRequester.deactivateBroadcastEncoders(this.state.userWowza.namespaceId, broadcastId);
	}

	setStatusBroadcast(broadcastId, status)
	{
		return this.graphQLRequester.setStatusBroadcast(this.state.userWowza.namespaceId, broadcastId, status);
	}

	deleteBroadcast(broadcastId)
	{
		return this.graphQLRequester.deleteBroadcast(this.state.userWowza.namespaceId, broadcastId)
	}

	renameBroadcast(broadcastId, name)
	{
		return this.graphQLRequester.renameBroadcast(this.state.userWowza.namespaceId, broadcastId, name)
	}

	async endBroadcast(broadcastId, doDelete)
	{
		await this.setStatusBroadcast(broadcastId, 'STOPPED');
		await this.setStatusBroadcast(broadcastId, 'IDLE');
		await this.deactivateBroadcastEncoders(broadcastId);

		if (doDelete)
		{
			await this.deleteBroadcast(broadcastId);
		}
	}

	async goLiveBroadcast(broadcastId)
	{
		await this.activateBroadcastEncoders(broadcastId);
		await this.setStatusBroadcast(broadcastId, 'PREVIEW');
		await this.setStatusBroadcast(broadcastId, 'LIVE');
	}

	async goPreviewFacebookPairedBroadcast(broadcastId,doSpeedTest)
	{
		await this.activateBroadcastEncoders(broadcastId);
		if (doSpeedTest)
			await this.setStatusBroadcast(broadcastId, 'PATH_OPTIMIZE');
		else
			await this.setStatusBroadcast(broadcastId, 'PREVIEW');
	}

	async goIdleFacebookPairedBroadcast(broadcastId)
	{
		await this.deactivateBroadcastEncoders(broadcastId);
		await this.setStatusBroadcast(broadcastId, 'IDLE');
	}

	async goLiveFacebookPairedBroadcast(broadcastId)
	{
		await this.activateBroadcastEncoders(broadcastId);
		await this.setStatusBroadcast(broadcastId, 'LIVE');
	}

	async endFacebookPairedBroadcast(broadcastId)
	{
		this.endBroadcast(broadcastId,false);
	}

	setBroadcastChanging(broadcastId,changing)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_BROADCAST_STATE_CHANGING,
			broadcastId:broadcastId,
			changing:changing
		});
	}

	async replaceBroadcastEncoder(broadcastId, broadcastEncoderId, broadcastEncoderInput)
	{
		let _this = this;

		await _this.graphQLRequester.deleteBroadcastEncoder(this.state.userWowza.namespaceId, broadcastEncoderId);
		await _this.graphQLRequester.createBroadcastEncoder(this.state.userWowza.namespaceId, broadcastId, broadcastEncoderInput);
	}

	setBroadcastStreamTargetInfo(broadcastStreamTargetId, input)
	{
		return this.graphQLRequester.setBroadcastStreamTargetInfo(this.state.userWowza.namespaceId, broadcastStreamTargetId, input);
	}

	setBroadcastInputInfo(broadcastId, input)
	{
		return this.graphQLRequester.setBroadcastInputInfo(broadcastId, input);
	}

	setBroadcastInputVideoAspectRatio(broadcastId, input)
	{
		return this.graphQLRequester.setBroadcastInputVideoAspectRatio(broadcastId, input);
	}

	setBroadcastInputVideoOverlay(broadcastId, input)
	{
		return this.graphQLRequester.setBroadcastInputVideoOverlay(broadcastId, input);
	}

	setBroadcastInputAudioLevel(broadcastId, input)
	{
		return this.graphQLRequester.setBroadcastInputAudioLevel(broadcastId, input);
	}

	setBroadcastInputAudioChannels(broadcastId, input)
	{
		return this.graphQLRequester.setBroadcastInputAudioChannels(broadcastId, input);
	}

	setBroadcastTranscodingConfigurationDecodingVideoParameter(broadcastId, input)
	{
		return this.graphQLRequester.setBroadcastTranscodingConfigurationDecodingVideoParameter(broadcastId, input);
	}

	createBroadcastDisplay(broadcastId, input)
	{
		return this.graphQLRequester.createBroadcastDisplay(this.state.userWowza.namespaceId, broadcastId, input);
	}

	createBroadcastRecording(broadcastOutputId, input)
	{
		return this.graphQLRequester.createBroadcastRecording(this.state.userWowza.namespaceId, broadcastOutputId, input);
	}

	setBroadcastDisplayInfo(broadcastDisplayId, input)
	{
		return this.graphQLRequester.setBroadcastDisplayInfo(this.state.userWowza.namespaceId, broadcastDisplayId, input);
	}

	deleteBroadcastDisplay(broadcastDisplayId)
	{
		return this.graphQLRequester.deleteBroadcastDisplay(this.state.userWowza.namespaceId, broadcastDisplayId);
	}

	deleteBroadcastRecording(broadcastRecordingId)
	{
		return this.graphQLRequester.deleteBroadcastRecording(this.state.userWowza.namespaceId, broadcastRecordingId);
	}

	setBroadcastEncodingConfigurationVideo(encodingConfigurationVideoId, input)
	{
		return this.graphQLRequester.setBroadcastEncodingConfigurationVideo(this.state.userWowza.namespaceId, encodingConfigurationVideoId, input);
	}

	setBroadcastEncodingConfigurationAudio(encodingConfigurationAudioId, input)
	{
		return this.graphQLRequester.setBroadcastEncodingConfigurationAudio(this.state.userWowza.namespaceId, encodingConfigurationAudioId, input);
	}

	queryBroadcastProviderStreamTargets(providerKeyId)
	{
		return this.graphQLRequester.queryBroadcastProviderStreamTargets(this.state.userWowza.namespaceId, providerKeyId);
	}

	queryBroadcastProviderStreamTarget(providerKeyId, providerId, providerType)
	{
		return this.graphQLRequester.queryBroadcastProviderStreamTarget(this.state.userWowza.namespaceId, providerKeyId, providerId, providerType);
	}

	mutationCreateProviderKey(input)
	{
		return this.graphQLRequester.mutationCreateProviderKey(this.state.userWowza.namespaceId, input);
	}

	mutationDeleteProviderKey(providerKeyId)
	{
		return this.graphQLRequester.mutationDeleteProviderKey(this.state.userWowza.namespaceId, providerKeyId);
	}

	mutationSetSlateOn(broadcastId,slateName)
	{
		return this.graphQLRequester.mutationSetSlateOn(broadcastId,slateName);
	}

	mutationSetSlateOff(broadcastId)
	{
		return this.graphQLRequester.mutationSetSlateOff(broadcastId);
	}

	getVersionsFile()
	{
		fetch(window.config.REACT_APP_VERSIONS)
			.then(function(response) {
				if (response.status >= 400) {
					Log.error('Error loading versions file:');
					Log.error(response);
					return;
				}
				return response.json();
			})
			.then(function(data) {

				let versions = {};

				if (data['settings'] && data['settings']['remote'] && data['settings']['remote']['force_versions'])
				{
					versions['force_versions'] = data['settings']['remote']['force_versions'];
				}

				if (data['settings'] && data['settings']['remote'] && data['settings']['remote']['force_versions'])
				{
					versions['clearcaster'] = data['clearcaster'];
				}
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_VERSIONS,
					versions:versions
				});
			});
	}
};

export default ClearCasterAdminController
