import React, { Component } from 'react';

import { Card, CardDeck } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';
import { compareObjectValues } from '../../../controller/ClearCasterAdminUtils';
import { formatFrameRate, humanBitrate } from '../../UIUtils';

class BroadcastItemArchiveSummary extends Component
{
	render()
	{
		if (this.props.broadcastGoLiveEventInfo === undefined ||
			this.props.broadcastGoLiveEventInfo === null ||
			this.props.broadcastArchive === undefined ||
			this.props.broadcastArchive === null ||
			this.props.broadcastArchive.data === undefined ||
			this.props.broadcastArchive.data === null ||
			this.props.broadcastArchive.data.length === 0 ||
			this.props.broadcastArchive.data[0] === undefined)
		{
			return (<span>no archive data</span>);
		}

		let duration = moment.duration(this.props.broadcastGoLiveEventInfo.endTime - this.props.broadcastGoLiveEventInfo.startTime);

		let encoder = this.props.broadcastArchive.data[0].encoder;
		let input = '';
		let outputFramerate = '';
		let keyFrameInterval = '';
		let audio = '';
		let outputsUIMap = {};
		let outputsUI = [];
		let broadcastName = '';

		if (this.props.broadcastGoLiveEventInfo.broadcastName === '' &&
			this.props.broadcastArchive.data[0].hasOwnProperty('sessionInfo') &&
			this.props.broadcastArchive.data[0]['sessionInfo'].hasOwnProperty('publishSession'))
		{
				broadcastName = <span>
					{this.props.broadcastArchive.data[0]['sessionInfo']['publishSession']['title']}: {this.props.broadcastGoLiveEventInfo.humanTime}
				</span>;
		}
		else
		{
			broadcastName = this.props.broadcastGoLiveEventInfo.displayName;
		}

		if (encoder !== undefined && encoder !== null)
		{
			if (encoder['captureSessionInfo'])
			{
				if (encoder.captureSessionInfo.videoInput === 1)
				{
					input += 'SDI ';
				}
				else
				{
					input += 'HDMI ';
				}
				input += encoder.captureSessionInfo.videoFrameWidth + ' x ' + encoder.captureSessionInfo.videoFrameHeight;
				input += encoder.captureSessionInfo.videoProgressive ? ' p ' : ' i ';
				input += formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, 1);
				outputFramerate = formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, encoder.captureSessionInfo.videoFrameRateDivisor);

				keyFrameInterval = encoder.captureSessionInfo.videoKeyFrameIntervalAdjusted + ' frames';

				audio = encoder.captureSessionInfo.audioChannels + ' @ ' + (encoder.captureSessionInfo.audioSampleRate/1000) + ' kHz';
			}
			if (encoder['outputs'])
			{
				for (let outputIndex = 0; outputIndex < encoder['outputs'].length; outputIndex++)
				{
					outputsUIMap[encoder['outputs'][outputIndex]['id']] = encoder['outputs'][outputIndex];
					outputsUIMap[encoder['outputs'][outputIndex]['id']].codec = '';
					if (encoder['outputs'][outputIndex]['videoCodec'] != null)
					{
						// check version for VPX
						if (encoder['outputs'][outputIndex]['videoCodec'] === 8)
						{
							outputsUIMap[encoder['outputs'][outputIndex]['id']].codec =
								outputsUIMap[encoder['outputs'][outputIndex]['id']].codec +
								this.props.strings.app['VideoCodec_VPX_'+encoder['outputs'][outputIndex]['videoCodecVersion']] + ' ';
						}
						else
						{
							outputsUIMap[encoder['outputs'][outputIndex]['id']].codec =
								outputsUIMap[encoder['outputs'][outputIndex]['id']].codec +
								this.props.strings.app['VideoCodec_'+encoder['outputs'][outputIndex]['videoCodec']] + ' ';
						}
					}
					if (encoder['outputs'][outputIndex]['audioCodec'] != null)
					{
						outputsUIMap[encoder['outputs'][outputIndex]['id']].codec =
							outputsUIMap[encoder['outputs'][outputIndex]['id']].codec +
							this.props.strings.app['AudioCodec_'+encoder['outputs'][outputIndex]['audioCodec']];
					}
					if (encoder['outputs'][outputIndex]['videoFrameSizeWidth'] != null && encoder['outputs'][outputIndex]['videoFrameSizeHeight'] != null)
					{
						outputsUIMap[encoder['outputs'][outputIndex]['id']].frameSize =
							encoder['outputs'][outputIndex]['videoFrameSizeWidth'] + ' x ' +
							encoder['outputs'][outputIndex]['videoFrameSizeHeight'] + ' p' +
							outputFramerate;
					}
					outputsUIMap[encoder['outputs'][outputIndex]['id']].streamTargets = [];
				}
			}
		}
		if (this.props.broadcastArchive.data[0]['streamTargets'])
		{
			for (let streamTargetIndex = 0; streamTargetIndex < this.props.broadcastArchive.data[0]['streamTargets'].length; streamTargetIndex++)
			{
				let streamTarget = this.props.broadcastArchive.data[0]['streamTargets'][streamTargetIndex];
				if (outputsUIMap.hasOwnProperty(streamTarget.outputId))
				{
					outputsUIMap[streamTarget.outputId].streamTargets.push(streamTarget);
				}
				else
				{
					console.log("ERROR: No outputId " + streamTarget.outputId + " on encoder");
					console.log('encoder outputs:');
					console.log(encoder['outputs']);
					console.log('broadcastArchive');
					console.log(this.props.broadcastArchive);
				}
			}
		}
		for (let key in outputsUIMap)
		{
			outputsUI.push(outputsUIMap[key]);
		}
		outputsUI = outputsUI.sort(compareObjectValues('videoBitrate','desc'));

		return (
			<>
				<div className="row">
					<div className="col-12">
						<h3 className="mt-4 border-bottom">{broadcastName}</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-4">
						<strong>{this.props.strings.app.ArchiveSummaryDate} </strong><Moment format="YYYY-MM-DD h:mma" date={this.props.broadcastGoLiveEventInfo.startTime} /><br />
						<strong>{this.props.strings.app.ArchiveSummaryDuration} </strong>{duration.humanize()}
					</div>
					<div className="col-sm-4">
						<strong>{this.props.strings.app.ArchiveSummaryInput} </strong>{input}<br />
						<strong>{this.props.strings.app.ArchiveSummaryAudioChannels} </strong>{audio}
					</div>
					<div className="col-sm-4">
						<strong>{this.props.strings.app.ClearCaster}: </strong>{this.props.broadcastGoLiveEventInfo.encoderName}<br />
						<strong>{this.props.strings.app.SerialNumber}: </strong>{this.props.broadcastGoLiveEventInfo.encoderSerial}
					</div>
				</div>
				<div className="row">
					<CardDeck className="w-100 col-sm-12">
						{outputsUI.map((o,key)=>{ return (
							<span key={key}>
								<Card bg="transparent" border="0">
									<Card.Header className="pl-0 pb-1 border-0 bg-white">
										{this.props.strings.app.ArchiveSummaryOutput} {key+1}
									</Card.Header>
									<Card.Body className="bg-light">
										{o.frameSize !== undefined &&
											<span>
												<strong>{this.props.strings.app.ArchiveSummaryFrameSize} </strong>{o.frameSize}<br />
											</span>
										}
										<strong>{this.props.strings.app.ArchiveSummaryBitrate} </strong>{humanBitrate(o.videoBitrate)}<br />
										{o.codec !== '' &&
											<span>
												<strong>{this.props.strings.app.ArchiveSummaryCodec} </strong>{o.codec}<br />
											</span>
										}
										{keyFrameInterval.length > 0 &&
										<span><strong>{this.props.strings.app.ArchiveSummaryKeyFrameInterval} </strong> {keyFrameInterval}</span>}
										{o.streamTargets.map((st,key2)=>{ return (
											<div className="text-truncate" key={key2}>
												<strong>{this.props.strings.app.ArchiveSummaryTarget} {key2+1}: </strong><br />
												{st.url}
											</div>
										)})}
									</Card.Body>
								</Card>
								{key%4===3 && <div className="w-100 d-none d-lg-block"></div>}
								{key%2===1 && <div className="w-100 d-none d-sm-block d-lg-none"></div>}
							</span>
						)})}
					</CardDeck>
				</div>
			</>
		);
	}
}
export default BroadcastItemArchiveSummary;