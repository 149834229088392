import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

class FacebookSharedBroadcastButtons extends Component
{
  goPreview (broadcast, doSpeedTest, controller)
  {
    return (evt) =>
    {
      console.log('goPreview');
      controller.goPreviewFacebookPairedBroadcast(broadcast.id,doSpeedTest).then((data) =>
      {
        console.log(JSON.stringify(data));
        controller.forcePolling();
      });
    }
  }

  goIdle (broadcast, controller)
  {
    return (evt) =>
    {
      controller.goIdleFacebookPairedBroadcast(broadcast.id).then((data) =>
      {
        console.log(JSON.stringify(data));
        controller.forcePolling();
      });
    }
  }

  goLive (broadcast, controller)
  {
    return (evt) =>
    {
      controller.goLiveFacebookPairedBroadcast(broadcast.id).then((data) =>
      {
        console.log(JSON.stringify(data));
        controller.forcePolling();
      });
    }
  }

  goLiveSecondary (broadcast, doSpeedTest, controller)
  {
    return (evt) =>
    {
      if (doSpeedTest)
      {
        controller.goPreviewFacebookPairedBroadcast(broadcast.id, true).then((data) =>
        {
          console.log(JSON.stringify(data));
          controller.forcePolling();
        });
      }
      else
      {
        controller.goLiveFacebookPairedBroadcast(broadcast.id).then((data) =>
        {
          console.log(JSON.stringify(data));
          controller.forcePolling();
        });
      }
    }
  }

  goStop (broadcast, controller)
  {
    return (evt) =>
    {
      controller.endFacebookPairedBroadcast(broadcast.id).then((data) =>
      {
        console.log(JSON.stringify(data));
        controller.forcePolling();
      });
    }
  }

  setEndFacebookBroadcast (broadcast,facebookData,endFacebookBroadcast,controller) {
    return (evt) => {
      let newFacebookData = {...facebookData,endFacebookBroadcast:endFacebookBroadcast+''};
      controller.setBroadcastExtraProperty(broadcast.id,{name:'facebook.endFacebookBroadcast',value:endFacebookBroadcast+'',type:'Boolean'});
      ClearCasterStore.dispatch({
        type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
        facebookData:newFacebookData
      });
    }
  }

  render()
  {
    let { broadcast, facebookData, controller } = this.props;

    let disableButtons = (facebookData.ui.state !== 'ready' || facebookData.broadcast.status === "ERROR" || facebookData.broadcast.status === "VOD");
    let doSpeedTest = !(facebookData.doSpeedTest === 'false'); // default to true
    let isPrimary = !(facebookData.isPrimary === 'false'); // default to true
    let endFacebookBroadcast = !(facebookData.endFacebookBroadcast === 'false'); // default to true

    // handle case of primary stream that has been stopped but the backup is active and the fb broadcast is already live
    if (isPrimary && broadcast.status !== 'LIVE' && broadcast.status !== 'PREVIEW' && facebookData.broadcast.status === 'LIVE')
    {
      return (
        <Button onClick={this.goLive(broadcast, controller)} variant="primary" style={{width:'200px'}} disabled={disableButtons}>
          <i className="fa fa-video-camera mr-2"></i>
          Start Streaming
        </Button>
      );
    }
    else if (broadcast.status === 'LIVE')
    {
      if (isPrimary)
      {
        return (
          <div className="d-flex justify-content-between">
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" checked={endFacebookBroadcast} onChange={this.setEndFacebookBroadcast(broadcast,facebookData,!endFacebookBroadcast,controller)} label="End Facebook broadcast" />
            </Form.Group>
            <Button onClick={this.goStop(broadcast, controller)} variant="danger" style={{width:'200px'}}>
              <i className="fa fa-times mr-2"></i>
              Stop Streaming
            </Button>
          </div>
        );
      }
      else
      {
        return (
          <Button onClick={this.goStop(broadcast, controller)} variant="danger" style={{width:'200px'}}>
            <i className="fa fa-times mr-2"></i>
            Stop Streaming
          </Button>
        );
      }
    }
    else if (broadcast.status === 'PREVIEW')
    {
      if (isPrimary)
      {
        return (
          <>
            <Button onClick={this.goIdle(broadcast, controller)} variant="danger" style={{width:'150px'}} className="mr-2">
              <i className="fa fa-times mr-2"></i>
              Stop Preview
            </Button>
            <Button onClick={this.goLive(broadcast, controller)} variant="primary" style={{width:'150px'}} disabled={disableButtons}>
              <i className="fa fa-video-camera mr-2"></i>
              Go Live
            </Button>
          </>
        );
      }
      else // should never happen
      {
        return (
          <>
            <Button onClick={this.goIdle(broadcast, controller)} variant="danger" style={{width:'150px'}} className="mr-2">
              <i className="fa fa-times mr-2"></i>
              Stop Preview
            </Button>
            <Button onClick={this.goLive(broadcast, controller)} variant="primary" style={{width:'200px'}} disabled={disableButtons}>
              <i className="fa fa-video-camera mr-2"></i>
              Start Streaming
            </Button>
          </>
        );
      }
    }
    else if (broadcast.status === 'PATH_OPTIMIZE')
    {
      return (
        <Button disabled={true} variant="outline-secondary" style={{width:'150px'}}>
          <PulseLoader sizeUnit="px" size="5"/>
        </Button>
      );
    }
    else if (broadcast.status === 'PATH_OPTIMIZE_ERROR')
    {
      return (
        <Button onClick={this.goStop(broadcast, controller)} variant="danger" style={{width:'150px'}}>
          <i className="fa fa-refresh mr-2"></i>
          Reset
        </Button>
      );
    }
    else
    {
      if (isPrimary)
      {
        return (
          <Button onClick={this.goPreview(broadcast, doSpeedTest, controller)} variant="warning" style={{width:'150px'}} disabled={disableButtons}>
            <i className="fa fa-video-camera mr-2"></i>
            Preview
          </Button>
        );
      }
      else
      {
        return (
          <Button onClick={this.goLiveSecondary(broadcast, doSpeedTest, controller)} variant="primary" style={{width:'200px'}} disabled={disableButtons}>
            <i className="fa fa-video-camera mr-2"></i>
            Start Streaming
          </Button>
        );
      }
    }
  }
}
export default FacebookSharedBroadcastButtons;
