import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import { logout } from '../../controller/UniversalAdminUtils';

class LogoutConfirmationDialog extends Component
{
	constructor() {
		
		super();

		this.handleDelete = this.handleDelete.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	onShow()
	{

	}

	handleClose()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_LOGOUTCONFIRMATION_DIALOG,
		});
	}

	handleDelete()
	{
		logout();

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_LOGOUTCONFIRMATION_DIALOG,
		});
	}

	render()
    {
		let appStrings = this.props.strings;

		let signoutPrompt = appStrings.nav.SignOutWowzaPrompt;
		let signoutUser = (this.props.clearcaster.userWowza && this.props.clearcaster.userWowza.username?this.props.clearcaster.userWowza.username:"");

		signoutPrompt = signoutPrompt.replace("##user##", signoutUser);

		return (
			<Modal show={ this.props.clearcaster.logoutConfirmationInfo !== undefined } onShow={ this.onShow } onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.nav.signOut }</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{ signoutPrompt }
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
					<Button variant="primary" onClick={this.handleDelete}>{ appStrings.nav.signOut }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default LogoutConfirmationDialog;
