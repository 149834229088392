import React, { Component } from 'react';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IndexLinkContainer } from 'react-router-bootstrap';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import { redirectToSwitchOrganization } from '../../controller/UniversalAdminUtils';

import './WZHeader.css';


class WzHeader extends Component
{
    initialState = {};

	constructor() {
		super();

		this.isTabActive = this.isTabActive.bind(this);
	}

	signoutWowzaUser()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_LOGOUTCONFIRMATION_DIALOG,
			logoutConfirmationInfo: {}
		});
	}

	isTabActive(path, match, location)
	{
		let retActive = false;

		if (location && location.pathname)
		{

			if (location.pathname.startsWith(path))
				retActive = true;
		}

		return retActive;
	}

	render()
	{
		const strings = this.props.strings;

		let state = ClearCasterStore.getState();
		let showManageInfoIcon = false;

		let daysUntilAutoRenew = this.props.controller.userSubscriptionDaysUntilAutoRenew();
		if (daysUntilAutoRenew > 0 && daysUntilAutoRenew < 29)
		{
			let daysUntilUnderwater = this.props.controller.userSubscriptionDaysUntilUnderwater();
			if (daysUntilUnderwater < 0 || daysUntilUnderwater > 28)
			{
				showManageInfoIcon = true;
			}
		}

		let userName = state.userWowza.email;
		if (state.userWowza.firstName !== '' || state.userWowza.lastName !== '')
		{
			userName = state.userWowza.firstName + ' ' + state.userWowza.lastName;
		}


		return (
			<Navbar collapseOnSelect expand="lg" className="wzHeader" variant="dark">
				<div className="container">
					<div className="position-relative">
						<Link to={"/"}>
							<Navbar.Brand>
									<img src="/images/clearcaster-logo-header-cloud-orchestration.png" className="wzbrand-image" alt="Wowza ClearCaster" />
							</Navbar.Brand>
						</Link>
					</div>

					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse>
						<Nav className="mr-auto">
							<IndexLinkContainer to="/broadcast" isActive={ (match, location) => { return this.isTabActive("/broadcast", match, location) } } activeClassName="active">
								<Nav.Item>
									<i className="fa fa-video-camera"></i>
									<span className='hidden-sm'>
										{ strings.nav.Broadcast }
									</span>
								</Nav.Item>
							</IndexLinkContainer>
							{ this.props.hidemonitoring !== true &&
								<IndexLinkContainer to="/monitor" isActive={ (match, location) => { return this.isTabActive("/monitor", match, location) } } activeClassName="active">
									<Nav.Item>
										<i className="fa fa-heartbeat"></i>
										<span className='hidden-sm'>
										{ strings.nav.Monitor }
										</span>
									</Nav.Item>
								</IndexLinkContainer>
							}
							{ this.props.clearcaster.broadcastSharing.active !== true &&
							<IndexLinkContainer to="/manage/encoders" isActive={ (match, location) => { return this.isTabActive("/manage", match, location) } } activeClassName="active">
								<Nav.Item>
									<i className="fa fa-server"></i>
									<span className='hidden-sm'>
									{ strings.nav.Manage }
									</span>
									{ showManageInfoIcon &&
										<i id="wzheader_manage_icon" className="fa fa-exclamation-circle ml-2" style={{color:"#BEE4FF"}}></i>
									}
								</Nav.Item>
							</IndexLinkContainer>
							}
						</Nav>
						{(process.env.REACT_APP_IS_DEMO_MODE !== "true") &&
							<Nav>
                <IndexLinkContainer to="/support" isActive={ (match, location) => { return this.isTabActive("/support", match, location) } } activeClassName="active">
									<Nav.Item>
										<i className="fa fa-info-circle"></i>
										<span className='hidden-sm'>
										{ strings.nav.Support }
  									</span>
									</Nav.Item>
								</IndexLinkContainer>
								{this.props.clearcaster.broadcastSharing.active !== true &&
									<NavDropdown title={
											<span>
												<i className='fa fa-user'></i>
												{userName}
											</span>
										} id="user-nav-dropdown">
										<NavDropdown.Item id="user-nav-change-organization" onClick={redirectToSwitchOrganization}><i className='fa fa-users'></i> {strings.nav.SwitchOrganization}</NavDropdown.Item>
										<NavDropdown.Item id="user-nav-sign-out" onClick={this.signoutWowzaUser}><i className='fa fa-sign-out'></i> {strings.nav.signOut}</NavDropdown.Item>
									</NavDropdown>
								}
							</Nav>
						}
					</Navbar.Collapse>
				</div>
			</Navbar>
			);
	}
};

export default WzHeader;
