import {
	STREAMTARGET_TYPES,
	X264_PRESETS,
	getDefaultSettings,
	createSimpleTemplate,
	updateSettingsBasedOnTemplateId,
	updateTemplateBasedOnTemplateId
} from './TemplateBuilder.js';

import {
	getRootTemplateName,
	removeDisabledFeatureTemplates
} from './TemplateUtils.js';

export async function loadBuiltInTemplate(id)
{
	let template = undefined;

	let rootId = getRootTemplateName(id);

	switch(rootId)
	{
		case "facebook-1080p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 60;
			settings.videoBitrate = "14m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.superfast;

			template = createSimpleTemplate(id, "Facebook 1080p60", settings);
			break;
		}
		case "facebook-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "8m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Facebook 1080p30", settings);
			break;
		}
		case "facebook-720p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 60;
			settings.videoBitrate = "14m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Facebook 720p60", settings);
			break;
		}
		case "facebook-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "4m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "Facebook 720p30", settings);
			break;
		}
		case "facebookpaired-1080p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 60;
			settings.videoBitrate = "14m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.superfast;
			settings.streamTargetType = STREAMTARGET_TYPES.FB_PAIRED_STREAM_TARGET;

			template = createSimpleTemplate(id, "Facebook Paired 1080p60", settings);
			break;
		}
		case "facebookpaired-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "8m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;
			settings.streamTargetType = STREAMTARGET_TYPES.FB_PAIRED_STREAM_TARGET;

			template = createSimpleTemplate(id, "Facebook Paired 1080p30", settings);
			break;
		}
		case "facebookpaired-720p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 60;
			settings.videoBitrate = "14m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;
			settings.streamTargetType = STREAMTARGET_TYPES.FB_PAIRED_STREAM_TARGET;

			template = createSimpleTemplate(id, "Facebook Paired 720p60", settings);
			break;
		}
		case "facebookpaired-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "4m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;
			settings.streamTargetType = STREAMTARGET_TYPES.FB_PAIRED_STREAM_TARGET;

			template = createSimpleTemplate(id, "Facebook Paired 720p30", settings);
			break;
		}
		case "facebookpaired-1080p60-vr":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "facebookpaired-1080p60-mediacaster":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "facebookpaired-1080p60-vertical":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "youtube-2160p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 3840;
			settings.height = 2160;
			settings.frameRate = 30;
			settings.videoBitrate = "28m";
			settings.videoBitrateMin = "4m";
			settings.videoProfile = "high";
			settings.videoPresets.x264 = X264_PRESETS.ultrafast;
			settings.videoPresets.quicksync = "4";

			template = createSimpleTemplate(id, "YouTube 4k30", settings);
			break;
		}
		case "youtube-1440p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 2560;
			settings.height = 1440;
			settings.frameRate = 60;
			settings.videoBitrate = "14m";
			settings.videoBitrateMin = "2.5m";
			settings.videoProfile = "high";
			settings.videoPresets.x264 = X264_PRESETS.superfast;
			settings.videoPresets.quicksync = "3";

			template = createSimpleTemplate(id, "YouTube 1440p60", settings);
			break;
		}
		case "youtube-1440p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 2560;
			settings.height = 1440;
			settings.frameRate = 30;
			settings.videoBitrate = "10m";
			settings.videoBitrateMin = "1.5m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.superfast;
			settings.videoPresets.quicksync = "3";

			template = createSimpleTemplate(id, "YouTube 1440p30", settings);
			break;
		}
		case "youtube-1080p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 60;
			settings.videoBitrate = "8m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.superfast;

			template = createSimpleTemplate(id, "YouTube 1080p60", settings);
			break;
		}
		case "youtube-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5.5m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "YouTube 1080p30", settings);
			break;
		}
		case "youtube-720p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 60;
			settings.videoBitrate = "5.5m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "YouTube 720p60", settings);
			break;
		}
		case "youtube-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "3.5m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "YouTube 720p30", settings);
			break;
		}
		case "wowza-2160p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 3840;
			settings.height = 2160;
			settings.frameRate = 30;
			settings.videoBitrate = "25m";
			settings.videoBitrateMin = "4m";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264 = X264_PRESETS.ultrafast;
			settings.videoPresets.quicksync = "4";

			template = createSimpleTemplate(id, "Wowza 4k30", settings);
			break;
		}
		case "wowza-2160p30-h265":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 3840;
			settings.height = 2160;
			settings.frameRate = 30;
			settings.videoCodec = "H.265";
			settings.videoBitrate = "17.5m";
			settings.videoBitrateMin = "4m";
			settings.streamTargetWithCredentials = true;
			settings.videoBFrames = 0;
			settings.videoPresets.x264 = X264_PRESETS.ultrafast;
			settings.videoPresets.quicksync = "6";

			template = createSimpleTemplate(id, "Wowza 4k30 HEVC", settings);
			break;
		}
		case "wowza-1080p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 60;
			settings.videoBitrate = "8m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.superfast;

			template = createSimpleTemplate(id, "Wowza 1080p60", settings);
			break;
		}
		case "wowza-1080p60-h265":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 60;
			settings.videoCodec = "H.265";
			settings.videoBitrate = "5.6m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoBFrames = 0;
			settings.videoPresets.quicksync = "2";

			template = createSimpleTemplate(id, "Wowza 1080p60 HEVC", settings);
			break;
		}
		case "wowza-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Wowza 1080p30", settings);
			break;
		}
		case "wowza-1080p30-h265":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoCodec = "H.265";
			settings.videoBitrate = "3.5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoBFrames = 1;
			settings.videoPresets.quicksync = "1";

			template = createSimpleTemplate(id, "Wowza 1080p30 HEVC", settings);
			break;
		}
		case "wowza-720p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 60;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Wowza 720p60", settings);
			break;
		}
		case "wowza-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "3.5m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "Wowza 720p30", settings);
			break;
		}
		case "wowza-360p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 640;
			settings.height = 360;
			settings.frameRate = 30;
			settings.videoBitrate = "500k";
			settings.videoBitrateMin = "250k";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "Wowza 360p30", settings);
			break;
		}
		case "wowza-360p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 640;
			settings.height = 360;
			settings.frameRate = 60;
			settings.videoBitrate = "800k";
			settings.videoBitrateMin = "250k";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "Wowza 360p60", settings);
			break;
		}
		case "twitch-1080p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 60;
			settings.videoBitrate = "5.5m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.superfast;

			template = createSimpleTemplate(id, "Twitch 1080p60", settings);
			break;
		}
		case "twitch-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "4.5m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Twitch 1080p30", settings);
			break;
		}
		case "twitch-720p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 60;
			settings.videoBitrate = "4.5m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Twitch 720p60", settings);
			break;
		}
		case "twitch-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "3.5m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "Twitch 720p30", settings);
			break;
		}
		case "twitter-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.keyFrameInterval = 3000;
			settings.videoBitrate = "4m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "Twitter 720p30", settings);
			break;
		}
		case "twitter-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.keyFrameInterval = 3000;
			settings.videoBitrate = "9m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Twitter 1080p30", settings);
			break;
		}
		case "general-2160p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 3840;
			settings.height = 2160;
			settings.frameRate = 30;
			settings.videoBitrate = "25m";
			settings.videoBitrateMin = "4m";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264 = X264_PRESETS.ultrafast;
			settings.videoPresets.quicksync = "4";

			template = createSimpleTemplate(id, "General 4k30", settings);
			break;
		}
		case "general-1080p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 60;
			settings.videoBitrate = "14m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.superfast;

			template = createSimpleTemplate(id, "General 1080p60", settings);
			break;
		}
		case "general-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "8m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "General 1080p30", settings);
			break;
		}
		case "general-720p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 60;
			settings.videoBitrate = "8m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "General 720p60", settings);
			break;
		}
		case "general-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "6m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "General 720p30", settings);
			break;
		}
		case "general-low-2160p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 3840;
			settings.height = 2160;
			settings.frameRate = 30;
			settings.videoBitrate = "15m";
			settings.videoBitrateMin = "4m";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264 = X264_PRESETS.ultrafast;
			settings.videoPresets.quicksync = "4";

			template = createSimpleTemplate(id, "General Low Bitrate 4k30", settings);
			break;
		}
		case "general-low-1080p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 60;
			settings.videoBitrate = "6m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.superfast;

			template = createSimpleTemplate(id, "General Low Bitrate 1080p60", settings);
			break;
		}
		case "general-low-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "3m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "General Low Bitrate 1080p30", settings);
			break;
		}
		case "general-low-720p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 60;
			settings.videoBitrate = "3m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "General Low Bitrate 720p60", settings);
			break;
		}
		case "general-low-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "1m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "General Low Bitrate 720p30", settings);
			break;
		}
		case "low-latency-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.videoBFrames = 0;
			settings.videoRefFrames = 1;
			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "3m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264ZeroLatency = true;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Low Latency 1080p30", settings);
			break;
		}
		case "low-latency-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.videoBFrames = 0;
			settings.videoRefFrames = 1;
			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "1m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264ZeroLatency = true;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "Low Latency Bitrate 720p30", settings);
			break;
		}
		case "wsc-4k30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 3840;
			settings.height = 2160;
			settings.frameRate = 30;
			settings.videoBitrate = "25m";
			settings.videoBitrateMin = "4m";
			settings.streamTargetType = STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET;
			settings.videoPresets.x264 = X264_PRESETS.ultrafast;
			settings.videoPresets.quicksync = "4";

			template = createSimpleTemplate(id, "Wowza Streaming Cloud 4k30", settings);
			break;
		}
		case "wsc-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetType = STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Wowza Streaming Cloud 1080p30", settings);
			break;
		}
		case "wsc-1080p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 60;
			settings.videoBitrate = "8m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetType = STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.superfast;

			template = createSimpleTemplate(id, "Wowza Streaming Cloud 1080p60", settings);
			break;
		}
		case "wsc-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "3.5m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetType = STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "Wowza Streaming Cloud 720p30", settings);
			break;
		}
		case "wsc-720p60":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 60;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetType = STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Wowza Streaming Cloud 720p60", settings);
			break;
		}
		case "wsc-ull-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "3m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetType = STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 0;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;
			settings.videoPresets.x264ZeroLatency = true;

			template = createSimpleTemplate(id, "Wowza Streaming Cloud ULL 1080p30", settings);
			break;
		}
		case "wsc-ull-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "1m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetType = STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 0;
			settings.videoPresets.x264 = X264_PRESETS.faster;
			settings.videoPresets.x264ZeroLatency = true;

			template = createSimpleTemplate(id, "Wowza Streaming Cloud ULL 720p30", settings);
			break;
		}
		case "other-output-mpegts-udp":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Output MPEGTS UDP", settings);

			template.template.outputs[0].streamTargets[0] = {
				"tag": "streamTarget0",
				"protocol": "MPEGTS",
				"streamTargetEncoderIndex": 0,
				"url": "$streamTargetUrl"
			};

			template.presentation.widgets[1] = {
				"widget": "TextEnterString",
				"parameters": {
					"value": {
						"variable": "$streamTargetUrl",
						"defaultValue": "{\"url\":\"udp://[Ip Address]:[port]\"}",
						"label": "MPEGTS Output URL"
					}
				}
			};
			break;
		}
		case "other-output-mpegts-srt":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Output SRT", settings);

			template.template.outputs[0].streamTargets[0] = {
				"tag": "streamTarget0",
				"protocol": "MPEGTS",
				"streamTargetEncoderIndex": 0,
				"url": "$streamTargetUrl"
			};

			template.presentation.widgets[1] = {
				"widget": "TextEnterString",
				"parameters": {
					"value": {
						"variable": "$streamTargetUrl",
						"defaultValue": "{\"url\":\"udp://[Publish_IP]\",\"implementation\":\"srt\"}",
						"label": "MPEGTS SRT Output URL"
					}
				}
			};
			break;
		}
		case "vimeo-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "4m";
			settings.videoBitrateMin = "1m";
			settings.videoProfile = "high";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Vimeo 1080p30", settings);
			break;
		}
		case "vimeo-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "3.5m";
			settings.videoBitrateMin = "750k";
			settings.videoProfile = "high";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;

			template = createSimpleTemplate(id, "Vimeo 720p30", settings);
			break;
		}
		case "example-widget-max-frame-rate":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = undefined;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Text Float (demo set max frame rate)", settings);
			break;
		}
		case "example-widget-max-latency":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Text Int (demo set max latency drift)", settings);
			break;
		}
		case "example-widget-turn-off-adaptive-encoding":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.videoAutoAdjustBitrate = undefined;
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Checkbox (demo turn off adaptive network encoding)", settings);
			break;
		}
		case "example-widget-profile-implementation-fit":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "List (demo Profile, Implementation, Fit)", settings);
			break;
		}
		case "example-config-storeall":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Store All Broadcast Configuration", settings);
			break;
		}
		case "example-config-lowlatency":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.videoBFrames = 0;
			settings.videoRefFrames = 1;
			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "3m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264ZeroLatency = true;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Low Latency", settings);
			break;
		}
		case "example-config-recording":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Recording", settings);
			break;
		}
		case "example-config-max-latency":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Config Max Latency", settings);
			break;
		}
		case "example-config-audio-config":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Audio Configuration", settings);
			break;
		}
		case "vertical-center-cut-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 608;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Vertical Center Cut 1080p30", settings);

			template.template.input = {
				"videoAspectRatioMode": 3,
				"videoAspectRatioWidth": 9,
				"videoAspectRatioHeight": 16
			};
			break;
		}
		case "vertical-rotate-right-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1080;
			settings.height = 1920;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Vertical Rotate Right 1080p30", settings);

			template.template.input = {
				"videoAspectRatioMode": 3,
				"videoAspectRatioWidth": 9,
				"videoAspectRatioHeight": 16,
				"videoAspectRatioRotation": 4.712
			};
			break;
		}
		case "vertical-center-cut-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 406;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "3.5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Vertical Center Cut 720p30", settings);

			template.template.input = {
				"videoAspectRatioMode": 3,
				"videoAspectRatioWidth": 9,
				"videoAspectRatioHeight": 16
			};
			break;
		}
		case "vertical-rotate-right-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 720;
			settings.height = 1280;
			settings.frameRate = 30;
			settings.videoBitrate = "3.5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Vertical Rotate Right 720p30", settings);

			template.template.input = {
				"videoAspectRatioMode": 3,
				"videoAspectRatioWidth": 9,
				"videoAspectRatioHeight": 16,
				"videoAspectRatioRotation": 4.712
			};
			break;
		}

		case "low-latency-vertical-center-cut-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.videoBFrames = 0;
			settings.videoRefFrames = 1;
			settings.width = 608;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "2m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264ZeroLatency = true;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Low Latency Vertical Center Cut 1080p30", settings);

			template.template.input = {
				"videoAspectRatioMode": 3,
				"videoAspectRatioWidth": 9,
				"videoAspectRatioHeight": 16
			};
			break;
		}
		case "low-latency-vertical-rotate-right-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.videoBFrames = 0;
			settings.videoRefFrames = 1;
			settings.width = 1080;
			settings.height = 1920;
			settings.frameRate = 30;
			settings.videoBitrate = "2m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264ZeroLatency = true;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Low Latency Vertical Rotate Right 1080p30", settings);

			template.template.input = {
				"videoAspectRatioMode": 3,
				"videoAspectRatioWidth": 9,
				"videoAspectRatioHeight": 16,
				"videoAspectRatioRotation": 4.712
			};
			break;
		}
		case "low-latency-vertical-center-cut-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.videoBFrames = 0;
			settings.videoRefFrames = 1;
			settings.width = 406;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "1m";
			settings.videoBitrateMin = "500k";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264ZeroLatency = true;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Low Latency Vertical Center Cut 720p30", settings);

			template.template.input = {
				"videoAspectRatioMode": 3,
				"videoAspectRatioWidth": 9,
				"videoAspectRatioHeight": 16
			};
			break;
		}
		case "low-latency-vertical-rotate-right-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.videoBFrames = 0;
			settings.videoRefFrames = 1;
			settings.width = 720;
			settings.height = 1280;
			settings.frameRate = 30;
			settings.videoBitrate = "1m";
			settings.videoBitrateMin = "500k";
			settings.streamTargetWithCredentials = true;
			settings.videoPresets.x264ZeroLatency = true;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "Low Latency Vertical Rotate Right 720p30", settings);

			template.template.input = {
				"videoAspectRatioMode": 3,
				"videoAspectRatioWidth": 9,
				"videoAspectRatioHeight": 16,
				"videoAspectRatioRotation": 4.712
			};
			break;
		}

		case "other-input-ip-rtp":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "IP Input RTP", settings);

			template.template.input = {
				"inputType": "MEDIACASTER",
				"videoFrameWidthMax": 1920,
				"videoFrameHeightMax": 1080,
				"videoFrameRateMax": 30,
				"videoKeyFrameIntervalMilliseconds": 2000,
				"mediaCasterType": "rtp",
				"mediaCasterStreamInfo": "$mediaCasterStreamInfo"
			};

			let widget = {
				"widget": "TextEnterString",
				"parameters": {
					"value": {
						"variable": "$mediaCasterStreamInfo",
						"defaultValue": "udp://0.0.0.0:6000",
						"label": "MediaCaster RTP Input URL"
					}
				}
			};

			template.presentation.widgets.splice(1, 0, widget);
			break;
		}
		case "other-input-ip-srt":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "IP Input SRT", settings);

			template.template.input = {
				"inputType": "MEDIACASTER",
				"videoFrameWidthMax": 1920,
				"videoFrameHeightMax": 1080,
				"videoFrameRateMax": 30,
				"videoKeyFrameIntervalMilliseconds": 2000,
				"mediaCasterType": "srt",
				"mediaCasterStreamInfo": "$mediaCasterStreamInfo"
			};

			let widget = {
				"widget": "TextEnterString",
				"parameters": {
					"value": {
						"variable": "$mediaCasterStreamInfo",
						"defaultValue": "srt://0.0.0.0:6000",
						"label": "MediaCaster SRT Input URL"
					}
				}
			};

			template.presentation.widgets.splice(1, 0, widget);
			break;
		}
		case "other-input-ip-rtsp":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "IP Input RTSP", settings);

			template.template.input = {
				"inputType": "MEDIACASTER",
				"videoFrameWidthMax": 1920,
				"videoFrameHeightMax": 1080,
				"videoFrameRateMax": 30,
				"videoKeyFrameIntervalMilliseconds": 2000,
				"mediaCasterType": "rtp",
				"mediaCasterStreamInfo": "$mediaCasterStreamInfo"
			};

			let widget = {
				"widget": "TextEnterString",
				"parameters": {
					"value": {
						"variable": "$mediaCasterStreamInfo",
						"defaultValue": "rtsp://exampleStream.com/appName/myStream",
						"label": "RTSP Input URL"
					}
				}
			};

			template.presentation.widgets.splice(1, 0, widget);
			break;
		}
		case "other-input-ip-applehls":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetWithCredentials = true;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;

			template = createSimpleTemplate(id, "IP Input HLS", settings);

			template.template.input = {
				"inputType": "MEDIACASTER",
				"videoFrameWidthMax": 1920,
				"videoFrameHeightMax": 1080,
				"videoFrameRateMax": 30,
				"videoKeyFrameIntervalMilliseconds": 2000,
				"mediaCasterType": "applehls",
				"mediaCasterStreamInfo": "$mediaCasterStreamInfo"
			};

			let widget = {
				"widget": "TextEnterString",
				"parameters": {
					"value": {
						"variable": "$mediaCasterStreamInfo",
						"defaultValue": "http://[ip address]:1935/appName/myStream/playlist.m3u8",
						"label": "Apple HLS Input URL"
					}
				}
			};

			template.presentation.widgets.splice(1, 0, widget);
			break;
		}

		case "linkedin-1080p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1920;
			settings.height = 1080;
			settings.frameRate = 30;
			settings.videoBitrate = "5m";
			settings.videoBitrateMin = "1m";
			settings.streamTargetType = STREAMTARGET_TYPES.LINKEDIN_LIVE_STREAM_TARGET;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.veryfast;
			settings.addLinkedInRegions = true;
			settings.addRecordWidget = false;

			template = createSimpleTemplate(id, "LinkedIn 1080p30", settings);
			break;
		}

		case "linkedin-720p30":
		{
			let settings = getDefaultSettings();

			updateSettingsBasedOnTemplateId(id, settings);

			settings.width = 1280;
			settings.height = 720;
			settings.frameRate = 30;
			settings.videoBitrate = "3.5m";
			settings.videoBitrateMin = "750k";
			settings.streamTargetType = STREAMTARGET_TYPES.LINKEDIN_LIVE_STREAM_TARGET;
			settings.videoRefFrames = 1;
			settings.videoBFrames = 1;
			settings.videoPresets.x264 = X264_PRESETS.faster;
			settings.addLinkedInRegions = true;
			settings.addRecordWidget = false;

			template = createSimpleTemplate(id, "LinkedIn 720p30", settings);
			break;
		}

		case "example-widget-rtmp-auth":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "example-widget-ip-input-source":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "example-widget-low-high-framerate":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "example-widget-list-frame-sizes":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "example-config-vertical-rotate-right":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "example-config-vertical-center-cut":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "example-config-ten-stream-targets":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-multitarget-fb-youtube":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-multitarget-fb-youtube-twitter":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-multitarget-2x-1080p30":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-multitarget-3x-1080p30":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-abr-1080p30":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-abr-720p30":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-4kp30":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-1080p30":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-1080p60":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-720p30":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-720p60":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		case "v2-general-hevc":
		{
			template = await require("./v2/"+rootId+".js");
			updateTemplateBasedOnTemplateId(id, template);
			break;
		}
		default:
	}

	return template;
}

export async function loadBuiltInTemplates()
{
	let allTemplates = await require("./TemplateCategories");

	allTemplates.templates = {};

	for(let setId in allTemplates.templateSets)
	{
		let templateSet = allTemplates.templateSets[setId];

		// Remove templates for a disabled feature
		let newTemplates = removeDisabledFeatureTemplates(allTemplates,templateSet);
		allTemplates = newTemplates.allTemplates;
		templateSet = newTemplates.templateSet;

		for(let catId in templateSet)
		{
			let category = templateSet[catId];

			category.id = catId;

			for(let tmpIdx in category.templates)
			{
				let templateId = category.templates[tmpIdx];

				if (!allTemplates.templates.hasOwnProperty(templateId))
				{
					let template = await loadBuiltInTemplate(templateId);

					if (template !== undefined)
					{
						template.id = templateId;
						template.isReadOnly = true;

						allTemplates.templates[templateId] = template;
					}
					else
						console.log("Missing: "+templateId);
				}
			}
		}
	}
	// console.log(allTemplates);
	return allTemplates;
}
