import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Col } from 'react-bootstrap';
import { getEncodersByName, compareObjectValues } from '../../../controller/ClearCasterAdminUtils';
import { toISODate } from '../../../controller/UniversalAdminUtils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import queryString from 'query-string';

class BroadcastGoLiveEventSearch extends Component
{
	constructor()
	{
		super();

		this.blurClearButton = this.blurClearButton.bind(this);
		this.getUniqueBroadcastsFromGoLiveEvents = this.getUniqueBroadcastsFromGoLiveEvents.bind(this);

		this.getFiltersFromQueryString = this.getFiltersFromQueryString.bind(this);
		this.handleEncoderIdChange = this.handleEncoderIdChange.bind(this);
		this.handleBroadcastIdChange = this.handleBroadcastIdChange.bind(this);
		this.handleFromChange = this.handleFromChange.bind(this);
		this.handleToChange = this.handleToChange.bind(this);
		this.updateQueryString = this.updateQueryString.bind(this);
		this.filterBroadcastGoLiveEvents = this.filterBroadcastGoLiveEvents.bind(this);

		this.clearButton = React.createRef();
	}

	blurClearButton()
	{
		if (this.clearButton.hasOwnProperty('current') && typeof this.clearButton.current.blur === 'function')
		{
			this.clearButton.current.blur();
		}
		return false;
	}

	getUniqueBroadcastsFromGoLiveEvents(goLiveEvents)
	{
		let foundBroadcastIds = [];
		let uniqueBroadcasts = [];

		for(let i = 0; i < goLiveEvents.length; i++)
		{
			if (!foundBroadcastIds.includes(goLiveEvents[i].broadcastId))
			{
				foundBroadcastIds.push(goLiveEvents[i].broadcastId);
				uniqueBroadcasts.push({id:goLiveEvents[i].broadcastId,name:goLiveEvents[i].broadcastName});
			}
		}
		uniqueBroadcasts = uniqueBroadcasts.sort(compareObjectValues("name","asc"));

		return uniqueBroadcasts;
	}

	getFiltersFromQueryString()
	{
		const query = queryString.parse(this.props.location.search);
		const today = new Date();
		let sevendaysago = new Date();
		sevendaysago.setDate(today.getDate() - 7);
		let filters = {
			broadcastId:'0',
			encoderId:'0',
			from:sevendaysago,
			to:today
		};
		if (query.broadcastid !== undefined)
		{
			filters.broadcastId = query.broadcastid;
		}
		if (query.encoderid !== undefined)
		{
			filters.encoderId = query.encoderid;
		}
		if (query.from !== undefined)
		{
			let fromDate = new Date(query.from);
			fromDate = new Date( fromDate.getTime() + (fromDate.getTimezoneOffset() * 60000));
			filters.from = fromDate;
		}
		if (query.to !== undefined)
		{
			let toDate = new Date(query.to);
			toDate = new Date(toDate.getTime() + (toDate.getTimezoneOffset() * 60000) + (24*60*60*1000) - 1);
			filters.to = toDate;
		}
		return filters;
	}

	handleEncoderIdChange(evt)
	{
		let filters = this.getFiltersFromQueryString();
		filters.encoderId = evt.target.value;
		this.updateQueryString(filters);
	}
	handleBroadcastIdChange(evt)
	{
		let filters = this.getFiltersFromQueryString();
		filters.broadcastId = evt.target.value;
		this.updateQueryString(filters);
	}
	handleFromChange(newDate)
	{
		let filters = this.getFiltersFromQueryString();
		filters.from = new Date(newDate);
		this.updateQueryString(filters);
	}
	handleToChange(newDate)
	{
		let filters = this.getFiltersFromQueryString();
		filters.to = new Date(newDate);
		this.updateQueryString(filters);
	}

	updateQueryString(filters)
	{
		let stringFilters = {
			broadcastid:filters.broadcastId,
			encoderid:filters.encoderId,
			from:toISODate(filters.from),
			to:toISODate(filters.to)
		};
		this.props.history.push({
			pathname:'/monitor/archive',
			search:queryString.stringify(stringFilters)
		});
	}

	filterBroadcastGoLiveEvents(goLiveEvents,filters)
	{
		let filteredGoLiveEvents = [];

		for (let i = 0; i < goLiveEvents.length; i++)
		{
			let event = goLiveEvents[i];
			let passFilter = true;
			if (filters.broadcastId !== '0')
			{
				if (event['broadcastId'] !== filters.broadcastId)
				{
					passFilter = false;
				}
			}
			if (filters.encoderId !== '0')
			{
				if (!event['encoderIds'].includes(filters.encoderId))
				{
					passFilter = false;
				}
			}
			if (event['startTime'] < filters.from)
			{
				passFilter = false;
			}
			if (event['startTime'] > filters.to)
			{
				passFilter = false;
			}
			if (passFilter)
			{
				filteredGoLiveEvents.push(event);
			}
		}

		return filteredGoLiveEvents;
	}

	render()
	{
		const encoders = getEncodersByName(this.props.encoderMap);
		const filters = this.getFiltersFromQueryString();
		const filteredGoLiveEvents = this.filterBroadcastGoLiveEvents(this.props.broadcastGoLiveEvents,filters);
		const broadcasts = this.getUniqueBroadcastsFromGoLiveEvents(filteredGoLiveEvents,filters);
		let hiddenCurrentBroadcast = null;
		if (filteredGoLiveEvents.length === 0 && filters.broadcastId !== '0')
		{
			for (let i = 0; i < this.props.broadcastGoLiveEvents.length; i++)
			{
				if (this.props.broadcastGoLiveEvents[i].broadcastId === filters.broadcastId)
				{
					hiddenCurrentBroadcast = {
						id:this.props.broadcastGoLiveEvents[i].broadcastId,
						name:this.props.broadcastGoLiveEvents[i].broadcastName
					};
					break;
				}
			}
		}

		if (this.props.currentEvent != null)
		{
			return (
				<div className="row mb-3">
					<div className="col-sm-12">
						<h3>{this.props.strings.app.SearchForBroadcast}</h3>
					</div>
					<div className="col-sm-12">
						<Link to={"/monitor/archive"}>
							<Button variant="light">{this.props.strings.app.ArchiveSearchStartOver}</Button>
						</Link>
					</div>
				</div>
			);
		}
		return (
			<>
				<div className="row mb-3">
					<div className="col-sm-12">
						<h3>{this.props.strings.app.SearchForBroadcast}</h3>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-12">
						<Form>
							<Form.Row>
								<Form.Group as={Col} controlId="archiveSearchBroadcastName">
									<Form.Label>{this.props.strings.app.ArchiveSearchBroadcastName}</Form.Label>
									<Form.Control as="select" value={filters.broadcastId} onChange={this.handleBroadcastIdChange}>
										<option value={0}>{this.props.strings.app.ArchiveSearchBroadcastAllBroadcasts}</option>
										{hiddenCurrentBroadcast !== null &&
										<option value={hiddenCurrentBroadcast.id}>{hiddenCurrentBroadcast.name}</option>
										}
										{broadcasts.map((b,key)=>{ return (
											<option key={key} value={b.id}>{b.name}</option>
										)})}
									</Form.Control>
								</Form.Group>
								<Form.Group as={Col} controlId="archiveSearchClearcaster">
									<Form.Label>{this.props.strings.app.ArchiveSearchClearcaster}</Form.Label>
									<Form.Control as="select" value={filters.encoderId} onChange={this.handleEncoderIdChange}>
										<option value={0}>{this.props.strings.app.ArchiveSearchClearcasterAllClearcasters}</option>
										{encoders.map((e,key)=>{ return (
											<option key={key} value={e.id}>{e.name}</option>
										)})}
									</Form.Control>
								</Form.Group>
								<Form.Group as={Col} controlId="archiveSearchFrom">
									<Form.Label>{this.props.strings.app.ArchiveSearchFrom}</Form.Label><br />
									<div className="react-datepicker-icon-wrapper">
										<Form.Control as={DatePicker}
																	selected={filters.from}
																	selectsStart={true}
																	startDate={filters.from}
																	endDate={filters.to}
																	maxDate={filters.to}
																	peekNextMonth={true}
																	onChange={this.handleFromChange}/>
										<i className="fa fa-calendar input-icon react-datepicker-icon"></i>
									</div>
								</Form.Group>
								<Form.Group as={Col} controlId="archiveSearchTo">
									<Form.Label>{this.props.strings.app.ArchiveSearchTo}</Form.Label><br />
									<div className="react-datepicker-icon-wrapper">
										<Form.Control as={DatePicker}
																	selected={filters.to}
																	selectsEnd={true}
																	startDate={filters.from}
																	endDate={filters.to}
																	minDate={filters.from}
																	maxDate={new Date()}
																	peekNextMonth={true}
																	onChange={this.handleToChange}/>
										<i className="fa fa-calendar input-icon react-datepicker-icon"></i>
									</div>
								</Form.Group>
								<Form.Group as={Col} controlId="archiveClear">
									<Form.Label>&nbsp;</Form.Label>
									<Link to={"/monitor/archive"}>
										<Form.Control as="button" className="btn-secondary" ref={this.clearButton} onClick={this.blurClearButton}>Clear</Form.Control>
									</Link>
								</Form.Group>
							</Form.Row>
						</Form>
					</div>
				</div>

				{filteredGoLiveEvents.length === 0 &&
				<div className="row mb-3">
					<div className="col-sm-12">
						<h4 className="mb-3">{this.props.strings.app.ArchiveSearchBroadcastsFound({found:filteredGoLiveEvents.length})}</h4>
					</div>
				</div>
				}
				{filteredGoLiveEvents.length > 0 &&
					<div className="row mb-3">
						<div className="col-sm-12">
							<h4 className="mb-3">{this.props.strings.app.ArchiveSearchBroadcastsFound({found:filteredGoLiveEvents.length})}</h4>
							<ReactTable data={filteredGoLiveEvents}
													columns={[
														{Header:this.props.strings.app.ArchiveSearchTableBroadcastName,
															accessor:'broadcastName',
														  className:'d-flex flex-column justify-content-center'},
														{Header:this.props.strings.app.ArchiveSearchTableClearcaster,
															id:'clearcaster',
															accessor:'encoderName',
															className:'d-flex flex-column justify-content-center'
														},
														{Header:this.props.strings.app.ArchiveSearchTableDateTime,
															accessor:'timeGoLive',
															className:'d-flex flex-column justify-content-center',
															Cell: c => <span>{c.original.humanTime}</span>
														},
														{Header:this.props.strings.app.ArchiveSearchTableDuration,
															id:'duration',
															accessor: d => {return d.duration._milliseconds;},
															className:'d-flex flex-column justify-content-center',
															Cell: c => <span>{c.original.durationHumanTime}</span>
														},
														{Header:'',accessor:'broadcastGoLiveId',sortable:false,
															Cell: props => <span><Link to={"/monitor/archive/"+props.value}>
																<Button variant="light" size="sm" className="w-100 m-1">{this.props.strings.app.ArchiveSearchTableView}</Button>
															</Link></span>
														}
													]}
													pageSizeOptions={[ 20, 50, 100]}
													defaultPageSize={20}
							/>
						</div>
					</div>
				}
			</>
		);
	}
}

export default BroadcastGoLiveEventSearch;