import React, { Component } from 'react';

import {Form, Button, Modal, InputGroup, FormControl, Table} from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

import {
	TEMPLATECOUNTS_TEMPLATES_ENABLED,
	isTemplateObjEnabled,
	getTemplateCategoryListV2
} from '../../../templates/TemplateUtils.js';

const uuidv1 = require('uuid/v1');

class ManageDestinationsDialog extends Component
{
	static getDefaultState()
	{
		return {
			createTemplateName:"",
		};
	}

	constructor() {
		
		super();

		this.state = ManageDestinationsDialog.getDefaultState();

		this.templateSet = "pro";

		this.handleClose = this.handleClose.bind(this);
		this.onShow = this.onShow.bind(this);
		this.onEnter = this.onEnter.bind(this);
		this.handleAddNow = this.handleAddNow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.toggleVisibility = this.toggleVisibility.bind(this);
		this.moveUp = this.moveUp.bind(this);
		this.moveDown = this.moveDown.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onDeleteCancel = this.onDeleteCancel.bind(this);
		this.toggleHideBuiltIn = this.toggleHideBuiltIn.bind(this);
		this.renameYes = this.renameYes.bind(this);
		this.renameNo = this.renameNo.bind(this);
	}

	onShow()
	{

	}

	onEnter()
	{
		let templateInfo = this.props.parent.state.templateInfo;

		let newState = {};

		let templateCategoryList = getTemplateCategoryListV2(templateInfo, this.templateSet, true);
		for (let key in templateCategoryList)
		{
			let v = templateCategoryList[key];

			newState['name:' + v.id] = v.name;
			newState['namereset:' + v.id] = v.name;
			newState['namedirty:' + v.id] = false;
		}

		this.setState(newState);
	}

	handleAddNow()
	{
		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategoryId = uuidv1();
		let templateCategory = {
			id: templateCategoryId,
			name: this.state.createTemplateName,
			defaultTemplate: undefined,
			templates: []
		};

		templateInfo.templateCategoryOrder.push(templateCategoryId);
		templateInfo.templateSets[this.templateSet][templateCategoryId] = templateCategory;

		this.props.parent.doSave(templateInfo, this.templateSet);

		let newState = {};

		newState['name:' + templateCategoryId] = this.state.createTemplateName;
		newState['namereset:' + templateCategoryId] = this.state.createTemplateName;
		newState['namedirty:' + templateCategoryId] = false;

		newState.createTemplateName = "";

		this.setState(newState);
	}

	handleClose()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_MANAGEDESTINATIONS_DIALOG,
		});
	}

	getTemplateCategory(templateInfo, id)
	{
		let templateCategory = undefined;

		if (templateInfo.templateSets[this.templateSet].hasOwnProperty(id))
			templateCategory = templateInfo.templateSets[this.templateSet][id];

		return templateCategory;
	}

	renameYes(evt)
	{
		let id = evt.target.id.split(":")[1];

		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategory = this.getTemplateCategory(templateInfo, id);
		if (templateCategory !== undefined)
		{
			templateCategory.name = this.state['name:' + id].trim();

			let newState = {};

			newState['namereset:'+id] = this.state['name:' + id];
			newState['namedirty:' + id] = false;

			this.setState(newState);

			this.props.parent.doSave(templateInfo, this.templateSet);
		}
	}

	renameNo(evt)
	{
		let id = evt.target.id.split(":")[1];

		let newState = {};

		newState['name:' + id] = this.state['namereset:'+id];
		newState['namedirty:' + id] = false;

		this.setState(newState);
	}

	onDelete(templateCategoryId)
	{
		this.props.parent.doDeleteTemplateCategory(templateCategoryId);
		this.props.parent.fixTemplateCategorySelection();

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_MANAGEDESTINATIONS_DIALOG,
		});
	}

	onDeleteCancel(id)
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_MANAGEDESTINATIONS_DIALOG,
		});
	}

	deleteRow(evt)
	{
		let id = evt.target.id.split(":")[1];

		let appStrings = this.props.strings;
		let templateInfo = this.props.parent.state.templateInfo;

		let bodyStr = "";
		let templateCategory = this.getTemplateCategory(templateInfo, id);
		if (templateCategory !== undefined)
		{
			bodyStr = "Are you sure you want to delete the custom destination '"+templateCategory.name+"'?";
			if (templateCategory.templates.length > 0)
			{
				bodyStr += " Deleting this custom destination will delete "+templateCategory.templates.length+" associated custom encoding template"+(templateCategory.templates.length>1?"s":"")+".";
			}
		}

		let contextObj = {
			titleStr: appStrings.app.DeleteCustomDestination,
			bodyStr: bodyStr,
			buttonStr: appStrings.app.DeleteCustomDestination,
			context: id,
			onDelete: this.onDelete,
			onCancel: this.onDeleteCancel
		}

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_MANAGEDESTINATIONS_DIALOG,
		});

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_CUSTOMTEMPLATECONFIRMDELETE_DIALOG,
			context: contextObj
		});
	}

	toggleVisibility(evt)
	{
		let id = evt.target.id.split(":")[1];

		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategory = this.getTemplateCategory(templateInfo, id);
		if (templateCategory !== undefined)
		{
			let isVisiable = isTemplateObjEnabled(templateCategory);

			templateCategory["enabled"] = !isVisiable;

			this.props.parent.doSave(templateInfo, this.templateSet);
		}
	}

	moveUp(evt)
	{
		let id = evt.target.id.split(":")[1];

		let templateInfo = this.props.parent.state.templateInfo;

		var index = templateInfo.templateCategoryOrder.indexOf(id);
		if (index > 0)
		{
			templateInfo.templateCategoryOrder.splice(index, 1);
			index--;
			templateInfo.templateCategoryOrder.splice(index, 0, id);
			this.props.parent.doSave(templateInfo, this.templateSet);
		}
	}

	moveDown(evt)
	{
		let id = evt.target.id.split(":")[1];

		let templateInfo = this.props.parent.state.templateInfo;

		var index = templateInfo.templateCategoryOrder.indexOf(id);
		if (index >= 0)
		{
			templateInfo.templateCategoryOrder.splice(index, 1);
			index++;
			templateInfo.templateCategoryOrder.splice(index, 0, id);
			this.props.parent.doSave(templateInfo, this.templateSet);
		}
	}

	renderTable(templateCategoryList)
	{
		let elements;

		let index = 0;

		let count = Object.keys(templateCategoryList).length;

		if (count <= 0)
		{
			let element = <tr><td colSpan="4"><i>No custom destinations</i></td></tr>
			elements = [elements, element];
		}
		else
		{
			for (let key in templateCategoryList)
			{
				let v = templateCategoryList[key];

				let isVisiable = isTemplateObjEnabled(v);

				// fa-check

				let element = <tr id={"id:" + v.id} key={"id:" + v.id}>
					<td style={{textAlign: "center"}}>
						{
							index === 0 ?
								<i key={'moveup:' + v.id} id={'moveup:' + v.id} className="fa fa-caret-up fa-disabled integration-icon"></i> :
								<i key={'moveup:' + v.id} id={'moveup:' + v.id} onClick={this.moveUp} className="integration-icon-button fa fa-caret-up integration-icon"></i>
						}
						&ensp;
						{
							index === (count - 1) ?
								<i key={'movedown:' + v.id} id={'movedown:' + v.id} className="fa fa-caret-down fa-disabled integration-icon"></i> :
								<i key={'movedown:' + v.id} id={'movedown:' + v.id} onClick={this.moveDown} className="integration-icon-button fa fa-caret-down integration-icon"></i>
						}
					</td>
					<td style={{textAlign: "center"}}>
						<i key={'visibility:' + v.id} id={'visibility:' + v.id} onClick={this.toggleVisibility} className={"integration-icon-button fa integration-icon " + (isVisiable ? "fa-eye" : "fa-eye-slash")}></i>
					</td>
					<td>
						<InputGroup>
							<FormControl key={ 'name:'+v.id } id={ 'name:'+v.id } size="sm" className="integration-table-textenter" value={ this.state['name:'+v.id] } onChange={ evt => { let newState = {}; newState['name:'+v.id] = evt.target.value; newState['namedirty:'+v.id] = true; this.setState(newState) } } />
							{
							this.state['namedirty:'+v.id] &&
								<InputGroup.Append>
									<div className={"integration-table-textenter-button integration-icon-button"} onClick={ this.renameYes }>
										<i key={'renameyes:' + v.id} id={'renameyes:' + v.id} className="fa integration-icon-button fa-check integration-icon"></i>
									</div>
								</InputGroup.Append>
							}
							{
										this.state['namedirty:'+v.id] &&
								<InputGroup.Append>
									<div className={"integration-table-textenter-button integration-icon-button"} onClick={ this.renameNo }>
										<i key={'renameno:' + v.id} id={'renameno:' + v.id} className="fa integration-icon-button fa-times integration-icon"></i>
									</div>
								</InputGroup.Append>
							}

						</InputGroup>
					</td>
					<td style={{textAlign: "center"}}>
						<i key={'delete:' + v.id} id={'delete:' + v.id} onClick={this.deleteRow} className='integration-icon-button integration-delete fa fa-trash integration-icon'></i>
					</td>
				</tr>;

				elements = [elements, element];

				index++;
			}

		}

		return <tbody>{ elements }</tbody>;
	}

	toggleHideBuiltIn(evt)
	{
		let templateInfo = this.props.parent.state.templateInfo;

		templateInfo.settings.showBuiltInTemplates = !templateInfo.settings.showBuiltInTemplates;

		this.props.parent.doSave(templateInfo, this.templateSet);

	}

	render()
    {
		let appStrings = this.props.strings;
		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategoryList = getTemplateCategoryListV2(templateInfo, this.templateSet, true);

		let disableAddNow = (this.state.createTemplateName.length <= 0);

		let templatesEnabled = this.props.parent.state.customTemplateCounts[TEMPLATECOUNTS_TEMPLATES_ENABLED];
		let showDefaultDestinations = ( templatesEnabled <= 0 || this.props.parent.state.templateInfo.settings.showBuiltInTemplates );
		let disableDefaultDestinations = ( templatesEnabled <= 0 );

		return (
			<Modal size="lg" show={ this.props.clearcaster.manageDestinations !== undefined } className="manage-destinations-dialog" onEnter={ this.onEnter } onShow={ this.onShow } onHide={ this.handleClose }>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.app.ManageDestinations }</Modal.Title><br/>
				</Modal.Header>

				<Modal.Body>
					<p>{ appStrings.app.ManageDestinationsDescription }</p>
					<div className="manage-destinations-list">
						<div className="integration-heading">{ appStrings.app.CustomDestinations }</div>
						<div className="integration-scrolling-list">
							<Table className="integration-manage-destinations-table" bordered size={"sm"}>
								<thead>
									<tr>
										<th style={{textAlign:"center"}}>{ appStrings.app.Reorder }</th>
										<th style={{textAlign:"center"}}>{ appStrings.app.Visibility }</th>
										<th>{ appStrings.app.DestinationName }</th>
										<th style={{textAlign:"center"}}>{ appStrings.app.Delete }</th>
									</tr>
								</thead>
								{ this.renderTable(templateCategoryList) }
							</Table>
						</div>
					</div>
					<div className="manage-destinations-disable-buitin">
						<div>
							<Form.Check key={ "hideBuiltIn" } id="hideBuiltIn" disabled={ disableDefaultDestinations } checked={ showDefaultDestinations } onChange={evt =>  { this.toggleHideBuiltIn(evt) } } type="checkbox" label={ appStrings.app.ShowDefaultDestinations } />
						</div>
					</div>
					<div className="manage-destinations-create">
						<div className="integration-heading">{ appStrings.app.CreateANewCategory }</div>
						<div>
							<InputGroup className="mb-3">
								<FormControl key={ "create-name" } id={ "create-name" } value={ this.state.createTemplateName } onChange={ evt => { this.setState({createTemplateName: evt.target.value}) } } />
								<InputGroup.Append>
									<Button key={ "create-add-now" } id={ "create-add-now" } disabled={ disableAddNow } variant={ disableAddNow?"outline-secondary":"secondary" } onClick={ this.handleAddNow }>{ appStrings.app.AddNow }</Button>
								</InputGroup.Append>
							</InputGroup>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Close }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ManageDestinationsDialog;
