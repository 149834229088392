import React, { Component } from 'react';
import { Col, Modal, Form, Button, InputGroup, Table, Alert } from 'react-bootstrap';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import { PulseLoader } from 'react-spinners';
import Moment from "react-moment";
import { sleep } from '../UIUtils';

class BroadcastShareDialog extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			key:'',
			name:'',
			expireDays:7,
			passphrase:'',
			creating:false,
			copiedToClipboard:false
		};

		this.close = this.close.bind(this);
		this.onShow = this.onShow.bind(this);
		this.onPassphraseChange = this.onPassphraseChange.bind(this);
		this.generatePassphrase = this.generatePassphrase.bind(this);
		this.createKey = this.createKey.bind(this);
		this.deleteKey = this.deleteKey.bind(this);
		this.renderSharingUrlInput = this.renderSharingUrlInput.bind(this);
	}

	close(event)
	{
		this.setState({
			key:'',
			passphrase:'',
			creating:false,
			keyCreated:false
		});
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_BROADCAST_SHARING,
			showBroadcastShareDialog:false,
			broadcastShareDialogId:-1
		});
	}

	onShow()
	{
		let broadcastId = this.props.clearcaster.broadcastSharing.broadcastShareDialogId;
		if (broadcastId == null)
			return null;
		let broadcast = this.props.clearcaster.broadcastMap[broadcastId];
		if (broadcast == null)
			return null;

		this.setState({name:broadcast.name+" Sharing URL"});
	}

	onPassphraseChange(event)
	{
		this.setState({passphrase:event.target.value});
	}

	isPassphraseValid(passphrase)
	{
		if (passphrase.length < 8 || passphrase.length > 255)
			return false;

		return true;
	}

	generatePassphrase(){
		let chars = "abcdfghjklmnpqrstvwxyzABCDFGHJKLMNPQRSTVWXYZ0123456789!@#$&";
		let passphrase = "";
		for (var i = 0, n = chars.length; i < 12; ++i) {
        passphrase += chars.charAt(Math.floor(Math.random() * n));
    }
		this.setState({passphrase: passphrase});
	}

	async createKey(event)
	{
		let broadcastId = this.props.clearcaster.broadcastSharing.broadcastShareDialogId;
		if (broadcastId == null)
			return null;
		let broadcast = this.props.clearcaster.broadcastMap[broadcastId];
		if (broadcast == null)
			return null;

		let name = this.state.name;

		this.setState({creating:true});

		let returnObj;
		try
		{
			returnObj = await this.props.controller.createAccessKey2(broadcastId,{name:name,secret:this.state.passphrase,expiration:this.state.expireDays+'d'});
			if (returnObj['data'] != null && returnObj['data']['createAccessKey'] != null && returnObj['data']['createAccessKey']['id'] != null)
			{
				await this.props.controller.getBroadcastAccessKeysMap();
				this.setState({
					key:returnObj['data']['createAccessKey']['id'],
					creating:false
				});
			}
		}
		catch(e)
		{
			console.log('ERROR');
			console.log(e);
		}
	}

	deleteKey(keyId,name)
	{
		return function (evt)
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SHOW_CCACCESSKEYCONFIRMDELETE_DIALOG,
				ccAccessKeyInfo: { id: keyId, name: name }
			});
		}
	}

	renderSharingUrlInput(accesskey,noAlert = false)
	{
		const _this = this;
		let now = new Date();
		let inputRef = React.createRef();
		let showToastStateKey = 'showToast-'+accesskey.id;
		let shareUrl = window.location.href+'?k='+accesskey.id;
		let deleting = this.props.clearcaster.broadcastSharing.deletingKey != null && accesskey.id.indexOf(this.props.clearcaster.broadcastSharing.deletingKey) === 0;
		let expired = false;
		if (accesskey.expiration != null)
		{
			expired = (now > (new Date(accesskey.expiration)));
		}

		async function showCopyToast()
		{
			let newState = {};
			newState[showToastStateKey] = 'showing';
			_this.setState(newState);
			await sleep(3000);
			newState[showToastStateKey] = '';
			_this.setState(newState);
		}

		function copyToClipboard()
		{
			inputRef.current.select();
			document.execCommand('copy');
			showCopyToast();
		}


		return (
			<tr key={accesskey.id} className={expired ? 'table-danger' : ''}>
				<td className="p-2">
					<div>
						<strong>{accesskey.name}</strong><br />
						{accesskey.createdAt != null &&
							<small><strong>{this.props.strings.app.KeyCreatedAt}</strong> <Moment date={accesskey.createdAt} format="llll" /> </small>
						}
						{accesskey.expiration != null &&
							<small><strong>{this.props.strings.app.KeyExpiresAt}</strong> <Moment date={accesskey.expiration} format="llll" /></small>
						}
						{ deleting &&
							<div className="p-1">
								<PulseLoader sizeUnit="px" size="5" />
							</div>
						}
						{ !expired && !deleting &&
							<InputGroup>
								<input id="dialog-sharingurl" className="form-control" type="text" defaultValue={shareUrl}
											 ref={inputRef}
											 onClick={copyToClipboard} />
								<InputGroup.Append>
									<Button variant={"light"} onClick={copyToClipboard}><i className="fa fa-copy"></i></Button>
								</InputGroup.Append>
								<InputGroup.Append>
									<Button variant={"dark"} onClick={() => window.open(shareUrl)}><i className="fa fa-external-link"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						}
						{ expired && !deleting &&
							<div>
								<i>Key Expired</i>
							</div>
						}
						{!noAlert &&
							<Alert variant={"success"} className="p-2 mb-0 mt-2" show={this.state[showToastStateKey] === 'showing'} >
								{this.props.strings.app.CopiedToClipboard}
							</Alert>
						}
					</div>
				</td>
				<td style={{textAlign:"center"}}>
					{(this.props.clearcaster.broadcastSharing.deletingKey == null || accesskey.id.indexOf(this.props.clearcaster.broadcastSharing.deletingKey) !== 0) &&
						<i onClick={ this.deleteKey(accesskey.id,accesskey.name) } className='integration-icon-button fa fa-trash integration-icon'></i>
					}
				</td>
			</tr>
		);
	}

	render()
	{
		let broadcastId = this.props.clearcaster.broadcastSharing.broadcastShareDialogId;
		if (broadcastId == null)
			return null;
		let broadcast = this.props.clearcaster.broadcastMap[broadcastId];
		if (broadcast == null)
			return null;

		let accessKeys = [];
		if (this.props.clearcaster.broadcastAccessKeysMap[broadcastId] != null && this.props.clearcaster.broadcastAccessKeysMap[broadcastId].length > 0)
		{
			accessKeys = this.props.clearcaster.broadcastAccessKeysMap[broadcastId];
		}

		let showDialog = this.props.clearcaster.broadcastSharing.showBroadcastShareDialog && this.props.clearcaster.ccAccessKeyConfirmDelete === undefined;

		return (
			<Modal show={showDialog} onHide={this.close} onShow={this.onShow}>
				<Form>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.strings.app.BroadcastShareDialogTitle({broadcastName:broadcast.name})}</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						{ (accessKeys.length > 0) &&
						<>
							<div className="integration-heading">{this.props.strings.app.BroadcastShared}</div>
							<p>{this.props.strings.app.PassphraseDialogPrompt2}</p>
							<Table striped bordered>
								<tbody>
									{ accessKeys.map((k,key) => { return this.renderSharingUrlInput(k);})}
								</tbody>
							</Table>
						</>
						}

						<div className="integration-heading">{this.props.strings.app.GenerateNewSharingURL}</div>
						<p>{this.props.strings.app.PassphraseDialogPrompt1}</p>
						<Form.Row>
							<Form.Group as={Col} className="col-9">
								<label style={{width: '100%'}} htmlFor="dialog-passphrase">
									{this.props.strings.app.PassphraseName}
								</label>
									<input id="dialog-passphrasename" className="form-control" type="text" value={this.state.name} disabled={this.state.creating}
												 onChange={(e)=>this.setState({name:e.target.value})}/>
							</Form.Group>
							<Form.Group as={Col} className="col-3">
								<label style={{width: '100%'}} htmlFor="dialog-passphrase">
									Expires
								</label>
								<Form.Control as="select" value={this.state.expireDays} onChange={(e)=>this.setState({expireDays:e.target.value})} disabled={this.state.creating}>
									<option value={1}>{this.props.strings.app.ExpireDay}</option>
									<option value={2}>2 {this.props.strings.app.ExpireDays}</option>
									<option value={3}>3 {this.props.strings.app.ExpireDays}</option>
									<option value={4}>4 {this.props.strings.app.ExpireDays}</option>
									<option value={5}>5 {this.props.strings.app.ExpireDays}</option>
									<option value={6}>6 {this.props.strings.app.ExpireDays}</option>
									<option value={7}>7 {this.props.strings.app.ExpireDays}</option>
								</Form.Control>
							</Form.Group>
						</Form.Row>
						<Form.Group>
							<label style={{width: '100%'}} htmlFor="dialog-passphrase">
								{this.props.strings.app.Passphrase}
							</label>
							<InputGroup>
								<input id="dialog-passphrase" className="form-control" type="text" value={this.state.passphrase} disabled={this.state.creating}
											 onChange={(e) => this.setState({passphrase:e.target.value})}/>
								 <InputGroup.Append>
	 								<Button variant={"light"} title="generate passphrase" onClick={this.generatePassphrase}><i className="fa fa-user-secret"></i></Button>
	 							</InputGroup.Append>
							</InputGroup>
						</Form.Group>
						{this.state.creating ?
							<Button className="pull-right mb-3 ml-2" variant={'outline-secondary'} style={{width: '200px'}} disabled>
								<PulseLoader sizeUnit="px" size="5"/>
							</Button>
							: this.isPassphraseValid(this.state.passphrase) ?
								<Button className="pull-right mb-3 ml-2"
									style={{width: '200px'}} id="dialog-broadcastsharing-createkey-button" variant={'primary'}
									onClick={this.createKey}>{this.props.strings.app.GenerateSharingURL}</Button>
								:
								<Button className="pull-right mb-3 ml-2"
									style={{width: '200px'}} id="dialog-broadcastsharing-createkey-button" variant={'outline-secondary'}
									disabled
									onClick={this.createKey}>{this.props.strings.app.GenerateSharingURL}</Button>
						}
						<Button className="pull-right mb-3" style={{width: '90px'}} id="dialog-broadcastsharing-close-button"
										variant={'secondary'} onClick={this.close}>{this.props.strings.app.Close}</Button>

					</Modal.Body>
				</Form>
			</Modal>
		);
	}
}

export default BroadcastShareDialog;
