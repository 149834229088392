import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

class ClearCasterAccessKeyConfirmDeleteDialog extends Component
{
	constructor() {
		
		super();

		this.handleDelete = this.handleDelete.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	onShow()
	{

	}

	handleClose()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_CCACCESSKEYCONFIRMDELETE_DIALOG,
		});
	}

	handleDelete()
	{
		let accessKeyId = this.props.clearcaster.ccAccessKeyConfirmDelete.id;
		// let accessKeyName = this.props.clearcaster.ccAccessKeyConfirmDelete.name;

		let _this = this;

		this.props.controller.deleteAccessKey(accessKeyId).then((resp) => {
			console.log(JSON.stringify(resp));
			_this.props.controller.getNamespaceAccessKeys();
		});

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_CCACCESSKEYCONFIRMDELETE_DIALOG,
		});
	}

	render()
    {
		let appStrings = this.props.strings;
		let accessKeyId = '';
		let accessKeyName = '';
		if (this.props.clearcaster.ccAccessKeyConfirmDelete !== undefined)
		{
			accessKeyId = this.props.clearcaster.ccAccessKeyConfirmDelete.id;
			accessKeyName = this.props.clearcaster.ccAccessKeyConfirmDelete.name;
		}

		return (
			<Modal show={ this.props.clearcaster.ccAccessKeyConfirmDelete !== undefined } onShow={ this.onShow } onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.app.DeleteWSCAccessKey }</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="row mb-4">
						<div className="col">
							{ appStrings.app.DeleteCCAccessKeyConfirm }
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<strong>Name:</strong>
						</div>
						<div className="col-8">
							{ accessKeyName }
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<strong>Access Key:</strong>
						</div>
						<div className="col-8">
							{ accessKeyId.substr(0, 6)+"..." }
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
					<Button variant="primary" onClick={this.handleDelete}>{ appStrings.app.DeleteWSCAccessKey }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ClearCasterAccessKeyConfirmDeleteDialog;
