import React, { Component } from 'react';

import { Button } from 'react-bootstrap'

import BroadcastList from './BroadcastList';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';

import GetSharedBroadcastPassphrase from '../dialogs/GetSharedBroadcastPassphrase';
import BroadcastCreateDialog from '../dialogs/BroadcastCreateDialog';
import BroadcastShareDialog from '../dialogs/BroadcastShareDialog';
import BroadcastShareDeleteUrlDialog from "../dialogs/BroadcastShareDeleteUrlDialog";
import DeleteBroadcastConfirmationDialog from '../dialogs/DeleteBroadcastConfirmationDialog';
import GoLiveConfirmationDialog from '../dialogs/GoLiveConfirmationDialog';

class BroadcastView extends Component
{
	constructor()
	{
		super();

		this.createBroadcast = this.createBroadcast.bind(this);

		this.broadcastCreateDialog = React.createRef();
	}

    initialState = {
	};

	createBroadcast()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_BROADCASTCREATE_DIALOG,
			broadcastCreateDialogInfo: {},
		});
	}

	async componentDidMount()
	{
		if (!this.props.clearcaster.broadcastSharing.active)
			await this.props.controller.getBroadcastAccessKeysMap();
	}

	render()
	{
		if(this.props.clearcaster.broadcastSharing.active){
			window.history.replaceState(null, null, "?k="+this.props.clearcaster.broadcastSharing.accessKey);
		}
		if (this.props.clearcaster.broadcastSharing.active && this.props.clearcaster.broadcastSharing.showGetPassphraseDialog)
		{
			return (
				<GetSharedBroadcastPassphrase strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
			);
		}
		return (
			<div className="text-left">
				<BroadcastShareDeleteUrlDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				<BroadcastShareDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				<BroadcastCreateDialog ref={ this.broadcastCreateDialog } strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				<DeleteBroadcastConfirmationDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				<GoLiveConfirmationDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />

				{ this.props.clearcaster.broadcastSharing.active !== true &&
				<div className="row broadcast-create-container" style={{marginBottom:'1em'}}>
					<div className="col-xs-12">
						<Button variant="primary" onClick={this.createBroadcast}>
							<i className="fa fa-plus-circle"></i> {this.props.strings.app.CreateBroadcast}
						</Button>
					</div>
				</div>
				}
				<BroadcastList strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
			</div>
		);
	}
};

export default BroadcastView;
