module.exports = {
	"name": "Text Int (demo set max latency drift)",
	"id": "example-widget-max-latency",
	"type": "full",
	"presentation": {
		"layout": "SingleOutput",
		"widgets": [
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "TextEnterInt",
				"parameters": {
					"value": {
						"variable": "$maxLatency",
						"defaultValue": 2000,
						"minValue": 0,
						"minWarning": "Key frame interval is less than minimum of 0",
						"maxValue": 60000,
						"maxWarning": "Key frame interval is greater than maximum of 60 seconds",
						"units": "INTEGER",
						"label": "Max Target Latency (A reconnect occurs if exceeded)"
					}
				}
			}
		]
	},
	"template": {
		"input": {
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": 1920,
			"videoFrameHeightMax": 1080,
			"videoFrameRateMax": 30,
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition0",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"maxLatency": "$maxLatency",
						"url": "$streamTargetUrl",
						"streamName": "$streamTargetStreamName",
						"username": "$streamTargetUsername",
						"password": "$streamTargetPassword"
					}
				],
				"encodingConfiguration": {
					"name": "rendition0",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "x264",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1920,
						"frameSizeHeight": 1080,
						"profile": "main",
						"bitrate": "5m",
						"bitrateMin": "1m",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "x264.preset",
								"value": "3",
								"type": "Long"
							},
							{
								"name": "x264.ref",
								"value": "1",
								"type": "Long"
							},
							{
								"name": "x264.bframes",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "128k",
						"parameters": []
					}
				}
			}
		]
	}
};