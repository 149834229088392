import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import NearRealTimeDataCache from '../../../model/NearRealTimeDataCache';

class BroadcastItemArchiveFramesChart extends Component
{
	static getDataSetTemplate(targetIndex,targetName,variant)
	{
		let color = '#61388F';
		if (variant === 1)
			color = '#7DC8FE';
		let dataSet = {
			label: targetName,
			yAxisID: variant+'',
			fill: false,
			lineTension: 0.1,
			backgroundColor: color,
			borderColor: color,
			borderCapStyle: 'butt',
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			borderWidth: 2,
			pointBorderColor: color,
			pointBackgroundColor: color,
			pointBorderWidth: 1,
			pointHoverRadius: 2,
			pointHoverBackgroundColor: color,
			pointHoverBorderColor: color,
			pointHoverBorderWidth: 2,
			pointRadius: 0.5,
			pointHitRadius: 10,
			data:[]
		};
		return dataSet;
	}
	render()
	{
		let dataSets = [];

		let broadcastData = NearRealTimeDataCache.framesChartAdapter(this.props.broadcastArchive.data);
		let minDroppedFrames = Number.MAX_VALUE;
		let dataDroppedFrames = [];
		let dataTotalFrames = [];
		let dataSetDroppedFrames = BroadcastItemArchiveFramesChart.getDataSetTemplate(0,"Dropped Frames",0);
		let dataSetTotalFrames = BroadcastItemArchiveFramesChart.getDataSetTemplate(0,"Total Frames",1);

		let windowStartTime = this.props.broadcastArchive.startDateTime;
		for (let i = 0; i < broadcastData.frames.length; i++)
		{
			if ((new Date(broadcastData.frames[i].eventTime)) >= (new Date(windowStartTime)))
			{
				if (broadcastData.frames[i].droppedFrames < minDroppedFrames)
					minDroppedFrames = broadcastData.frames[i].droppedFrames;
			}
		}
		if (minDroppedFrames === Number.MAX_VALUE)
			minDroppedFrames = 0;

		for (let dataIndex = 0; dataIndex < broadcastData.frames.length; dataIndex++)
		{
			dataDroppedFrames.push({x:broadcastData.frames[dataIndex].eventTime,y:(broadcastData.frames[dataIndex].droppedFrames - minDroppedFrames)});
			dataTotalFrames.push({x:broadcastData.frames[dataIndex].eventTime,y:broadcastData.frames[dataIndex].totalFrames});
		}
		dataSetDroppedFrames.data = dataDroppedFrames;
		dataSetTotalFrames.data = dataTotalFrames;
		dataSets.push(dataSetDroppedFrames);
		dataSets.push(dataSetTotalFrames);

		return (
			<Card bg="transparent" border="0">
				<Card.Header className="pl-0 pb-1 border-0 bg-white">
					{this.props.strings.app.MonitoringCartTitleFramesChart}
				</Card.Header>
				<Card.Body className="bg-white p-0">
					<div>
						<Line
							height={200}
							options={{
								maintainAspectRatio:false,
								animation:{
									duration:0
								},
								layout:{
									padding:{
										left:0,
										top:0,
										right:0,
										bottom:0
									}
								},
								scales: {
									xAxes: [{
										type: 'time',
										position: 'bottom',
										time:{
											min:windowStartTime,
											tooltipFormat:'h:mm:ss a'
										},
										ticks:{
											minRotation:0,
											maxRotation:0
										}
									}],
									yAxes: [{
										id:'0',
										type: 'linear',
										position: 'left',
										gridLines:{
											display:false
										},
										ticks:{
											min:0,
											suggestedMax:10
										}
									},
									{
										id:'1',
										type: 'linear',
										position: 'right',
										ticks:{
											min:0,
											suggestedMax:100
										}
									}]
								}
							}}
							legend={{
								display: false
							}}
							data={{
								datasets: dataSets
							}}
						/>
					</div>
					<div className="row bg-light m-1 p-1">
						{dataSets.map((d,key) => { return (
							<div key={key} className="col-sm-4 chart-legend-label">
								<div className="chart-legend-box" style={{
									borderStyle: (d.borderDash.length > 0 ? "dashed" : "solid"),
									borderTopColor: d.borderColor
								}}></div>
								{d.label}
							</div>
						);
						})}
					</div>
				</Card.Body>
			</Card>
		);
	}
}
export default BroadcastItemArchiveFramesChart;