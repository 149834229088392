import React, { PureComponent } from 'react';
import './Toggle.css';

class Toggle extends PureComponent {
	render () {
		let aId;
		let aClassName;
		let iClassName;
		let ariaLabel = 'toggle';
		if (this.props.id != null)
		{
			aId = this.props.id;
		}
		if (this.props.loading != null && this.props.loading === true)
		{
			aClassName = 'toggle-link toggle-disabled';
			iClassName = 'fa fa-circle-o-notch fa-spin fa-fw';
			ariaLabel = 'toggle loading';
		}
		else if (this.props.disabled != null && this.props.disabled === true)
		{
			aClassName = 'toggle-link toggle-disabled';
			ariaLabel = 'toggle disabled';
			if (this.props.on === true)
			{
				iClassName = 'fa fa-toggle-on fa-fw';
			}
			else
			{
				iClassName = 'fa fa-toggle-on fa-rotate-180 fa-fw';
			}
		}
		else if (this.props.on != null && this.props.on === true)
		{
			aClassName = 'toggle-link toggle-on';
			iClassName = 'fa fa-toggle-on fa-fw';
			ariaLabel = 'toggle indicates on';
		}
		else
		{
			aClassName = 'toggle-link toggle-off';
			iClassName = 'fa fa-toggle-on fa-rotate-180 fa-fw';
			ariaLabel = 'toggle indicates off';
		}
		if (this.props.size != null)
		{
			iClassName += ' fa-'+this.props.size;
		}
		if (this.props.ariaLabel != null)
		{
			ariaLabel = this.props.ariaLabel;
		}
		return (
			<div className={this.props.className}>
				<button id={aId} className={aClassName} onClick={this.props.onClick} aria-label={ariaLabel}>
					<i className={iClassName} aria-hidden={true}></i>
				</button>
			</div>
		);
	}
}
export default Toggle;