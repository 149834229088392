import React, { PureComponent } from 'react';
import { Badge } from 'react-bootstrap';

class EncoderStatusBadge extends PureComponent
{
  render()
  {
    let { encoder, strings, controller, showSubscriptionExpired = true } = this.props;
    let isSubscriptionCurrent = controller.userIsSubscriptionCurrent();
    let ret = [];

    if (showSubscriptionExpired && !isSubscriptionCurrent)
    {
      ret.push(<Badge key="1" variant="danger" className="mr-2">{strings.app.SubscriptionExpiredBadge}</Badge>);
    }
    if (!showSubscriptionExpired || isSubscriptionCurrent || encoder.modelInfo.model === "CC_PRO" || encoder.modelInfo.model === "UNKNOWN")
    {
      if (encoder.isOnline)
      {
        if (encoder.encoderStatus != null && encoder.encoderStatus.status != null && encoder.encoderStatus.status.toUpperCase().indexOf("UPDATING") === 0)
        {
          ret.push(<Badge key="0" variant="secondary">{encoder.encoderStatus.status.toUpperCase()}</Badge>);
        }
        else
        {
          switch(encoder.broadcastStatus)
          {
            case strings.app.streamStatusPreview:
              ret.push(<Badge key="0" variant="warning">{encoder.broadcastStatus}</Badge>);
              break;
            case strings.app.streamStatusLive:
              ret.push(<Badge key="0" variant="danger">{encoder.broadcastStatus}</Badge>);
              break;
            default:
            case strings.app.streamStatusReady:
              ret.push(<Badge key="0" variant="success">{encoder.broadcastStatus}</Badge>);
              break;
          }
        }
      }
      else
        ret.push(<Badge key="0" variant="secondary">{strings.app.StatusOffline}</Badge>);
    }

    return (<>{ ret }</>);
  }
}
export default EncoderStatusBadge;