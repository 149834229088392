import React, { Component } from 'react';

import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';

import Activate from '../components/Activate';
import ActivateDialog from '../dialogs/ActivateDialog';
import NoSubscriptionDialog from '../dialogs/NoSubscriptionDialog'

import SubscriptionAutoRenewBanner from '../shell/SubscriptionAutoRenewBanner';
import EncoderList from './EncoderList';
import ManageIntegrations from './manage/ManageIntegrations';
import ManageTemplates from './manage/ManageTemplates';
import ManageRecordings from './manage/ManageRecordings';
import ManageUsers from './manage/ManageUsers';
import ManageGraphics from './manage/ManageGraphics'

class ManageView extends Component
{
	constructor(props)
	{
		super(props);
		this.isAdmin = this.isAdmin.bind(this);
	}

	isAdmin()
	{
		return (process.env.REACT_APP_ENABLE_ROLE_RESTRICTIONS === 'false' ||
			this.props.clearcaster.manageSettings.limitAccess === false ||
			this.props.clearcaster.userWowza.admin ||
			this.props.clearcaster.userWowza.roles.indexOf('organization_owner') >= 0
		);
	}

	onSelectTab()
	{

	}

	render()
	{
		const rbui = this.props.rbui || false;
		const rbuiForce = this.props.rbuiForce || false;
		const isSubscriptionCurrent = this.props.controller.userIsSubscriptionCurrent();

		return (
			<>
				{this.props.clearcaster.activateForm.dialogShowing &&
					<ActivateDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				}
				{this.props.clearcaster.activateNoSubscription.dialogShowing &&
					<NoSubscriptionDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				}

			<SubscriptionAutoRenewBanner
				strings={this.props.strings}
				userIsSubscriptionUnderwater={this.props.controller.userIsSubscriptionUnderwater()}
				userSubscriptionDaysUntilUnderwater={this.props.controller.userSubscriptionDaysUntilUnderwater()}
				userSubscriptionDaysUntilAutoRenew={this.props.controller.userSubscriptionDaysUntilAutoRenew()}
			/>
			<div className="text-left">
				<div>
					<Nav variant="tabs" activeKey={this.props.tab} onSelect={ this.onSelectTab }  className="w-100">
						<Nav.Item className="ml-5">
							<Nav.Link as={NavLink} to={'/manage/encoders'} eventKey={'encoders'}>
								<span><strong>{this.props.strings.nav.ManageEncoders}</strong></span>
							</Nav.Link>
						</Nav.Item>
						{ this.isAdmin() && isSubscriptionCurrent &&
						<>
							<Nav.Item>
								<Nav.Link as={NavLink} to={'/manage/templates'} eventKey={'templates'}>
									<span><strong>{this.props.strings.nav.ManageTemplates}</strong></span>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
							<Nav.Link as={NavLink} to={'/manage/integrations'} eventKey={'integrations'}>
								<span><strong>{this.props.strings.nav.ManageIntegrations}</strong></span>
								</Nav.Link>
							</Nav.Item>
						</>
						}
						{ this.isAdmin() && !isSubscriptionCurrent &&
						<>
							<Nav.Item>
								<div className="nav-link inactive">
									<span><strong>{this.props.strings.nav.ManageTemplates}</strong></span>
								</div>
							</Nav.Item>
							<Nav.Item>
								<div className="nav-link inactive">
									<span><strong>{this.props.strings.nav.ManageIntegrations}</strong></span>
								</div>
							</Nav.Item>
						</>
						}
						{ isSubscriptionCurrent &&
							<Nav.Item>
								<Nav.Link as={NavLink} to={'/manage/recordings'} eventKey={'recordings'}>
									<span><strong>{this.props.strings.nav.ManageRecordings}{this.props.strings.nav.EnterpriseOnly}</strong></span>
								</Nav.Link>
							</Nav.Item>
						}
						{ !isSubscriptionCurrent &&
							<Nav.Item>
								<div className="nav-link inactive">
									<span><strong>{this.props.strings.nav.ManageRecordings}{this.props.strings.nav.EnterpriseOnly}</strong></span>
								</div>
							</Nav.Item>
						}
						<Nav.Item>
							<Nav.Link as={NavLink} to={'/manage/users'} eventKey={'users'}>
								<span><strong>{this.props.strings.nav.ManageUsers}{this.props.strings.nav.EnterpriseOnly}</strong></span>
							</Nav.Link>
						</Nav.Item>
						{ isSubscriptionCurrent &&
							<Nav.Item>
								<Nav.Link as={NavLink} to={'/manage/graphics'} eventKey={'graphics'}>
									<span><strong>{this.props.strings.nav.ManageTiltlingGraphics}{this.props.strings.nav.EnterpriseOnly}</strong></span>
								</Nav.Link>
							</Nav.Item>
						}
						{ !isSubscriptionCurrent &&
							<Nav.Item>
								<div className="nav-link inactive">
									<span><strong>{this.props.strings.nav.ManageTiltlingGraphics}{this.props.strings.nav.EnterpriseOnly}</strong></span>
								</div>
							</Nav.Item>
						}
					</Nav>

					<div className="manage-tab-body">
						{ this.props.tab === 'encoders' &&
							<>
								<EncoderList strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} rbui={rbui} rbuiForce={rbuiForce}/>
								<Activate strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
							</>
						}
						{ this.props.tab === 'templates' &&
							<div>
								{ isSubscriptionCurrent && process.env.REACT_APP_ENABLE_MANAGE_TEMPLATES === "true" && this.isAdmin() ?
									<ManageTemplates strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller}/> :
									<div>No templates available.</div>
								}
							</div>
						}
						{ this.props.tab === 'integrations' &&
							<div>
								{ isSubscriptionCurrent && process.env.REACT_APP_ENABLE_MANAGE_INTEGRATIONS === "true" && this.isAdmin() ?
									<ManageIntegrations strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} /> :
									<div>No integrations available</div>
								}
							</div>
						}
						{ this.props.tab === 'recordings' &&
						<div>
							{ isSubscriptionCurrent && process.env.REACT_APP_ENABLE_MANAGE_RECORDINGS === "true" ?
								<ManageRecordings strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} /> :
								<div>No recordings available.</div>
							}
						</div>
						}
						{ this.props.tab === 'users' &&
						<div>
							{ process.env.REACT_APP_ENABLE_MANAGE_USERS === "true" ?
								<ManageUsers strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} /> :
								<div>Coming Soon</div>
							}
						</div>
						}
						{ this.props.tab === 'graphics' &&
						<div>
							{ isSubscriptionCurrent && process.env.REACT_APP_ENABLE_MANAGE_SLATES === "true" ?
								<ManageGraphics strings={this.props.strings} log={this.props.log} graphicsid={this.props.graphicsid} clearcaster={this.props.clearcaster} controller={this.props.controller} /> :
								<div>No graphics available.</div>
							}
						</div>
						}
					</div>
				</div>
			</div>
			</>
		);
	}
}

export default ManageView;
