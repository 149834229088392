import React, { Component } from 'react';
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';
import { Form } from 'react-bootstrap';

class FacebookTitleDescription extends Component
{
	changeTitle (facebookData) {
		return (evt) => {
			let title = evt.target.value;
			let newFacebookData = {...facebookData,broadcast:{...facebookData.broadcast,title:title}};
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData:newFacebookData
			});
		}
	}
	changeDescription (facebookData) {
		return (evt) => {
			let description = evt.target.value;
			let newFacebookData = {...facebookData,broadcast:{...facebookData.broadcast,description:description}};
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData:newFacebookData
			});
		}
	}
	render()
	{
		let facebookData = this.props.facebookData;

		return (
			<>
				<Form.Group controlId="title">
					<Form.Label>Title&nbsp;*</Form.Label>
					<Form.Control value={ facebookData.broadcast.title || '' } onChange={ this.changeTitle(facebookData) } />
				</Form.Group>
				<Form.Group controlId="description">
					<Form.Label>Description&nbsp;*</Form.Label>
					<Form.Control as="textarea" value={ facebookData.broadcast.description || '' } onChange={ this.changeDescription(facebookData) } />
				</Form.Group>
			</>
		);
	}
}
export default FacebookTitleDescription;