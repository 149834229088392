import React, { Component } from 'react';

import BroadcastItemArchiveSummary from './BroadcastItemArchiveSummary';
import BroadcastItemArchiveBitrateChart from './BroadcastItemArchiveBitrateChart';
import BroadcastItemArchiveFramesChart from './BroadcastItemArchiveFramesChart';
import BroadcastItemArchivePingChart from './BroadcastItemArchivePingChart';
import BroadcastItemArchiveConnectionAttemptsChart from './BroadcastItemArchiveConnectionAttemptsChart';
import BroadcastItemArchiveApplianceChart from './BroadcastItemArchiveApplianceChart';
import NearRealTimeDataCache from '../../../model/NearRealTimeDataCache';

class BroadcastItemArchive extends Component
{
	constructor (props)
	{
		super(props);

		this.loadData = this.loadData.bind(this);
	}

	async loadData(prevProps)
	{
		if (this.props.broadcastGoLiveEventInfo !== undefined && this.props.broadcastGoLiveEventInfo !== null)
		{
			if (prevProps === undefined ||
				prevProps === null ||
				prevProps.broadcastGoLiveEventInfo === undefined ||
				prevProps.broadcastGoLiveEventInfo === null ||
				prevProps.broadcastGoLiveEventInfo.broadcastGoLiveId !== this.props.broadcastGoLiveEventInfo.broadcastGoLiveId)
			{
				let startTime = this.props.broadcastGoLiveEventInfo.startTime;
				let endTime = this.props.broadcastGoLiveEventInfo.endTime;

				await this.props.controller.getArchiveData(
					this.props.broadcastGoLiveEventInfo.broadcastGoLiveId,
					this.props.broadcastGoLiveEventInfo.broadcastId,
					startTime,
					endTime
				);
			}
		}
	}

	async componentDidMount()
	{
		await this.loadData(null);
	}
	async componentDidUpdate(prevProps)
	{
		await this.loadData(prevProps);
	}

	render()
	{
		if (this.props.broadcastGoLiveEventInfo === undefined || this.props.broadcastGoLiveEventInfo === null)
		{
			return (<div>No broadcast selected</div>);
		}

		let broadcastArchive = this.props.clearcaster.broadcastArchiveDataMap[this.props.broadcastGoLiveEventInfo.broadcastGoLiveId];

		if (broadcastArchive === undefined || broadcastArchive === null)
		{
			return (<div>Loading</div>);
		}

		let broadcast = null;
		if (this.props.clearcaster.broadcastMap.hasOwnProperty(this.props.broadcastGoLiveEventInfo.broadcastId))
		{
			broadcast = this.props.clearcaster.broadcastMap[this.props.broadcastGoLiveEventInfo.broadcastId];
		}

		let broadcastNetworkData = NearRealTimeDataCache.networkChartAdapter(broadcastArchive.data);

		return (
			<div>
				<div className="mb-4">
					<BroadcastItemArchiveSummary broadcastGoLiveEventInfo={this.props.broadcastGoLiveEventInfo}
																			 broadcastArchive={broadcastArchive}
																			 strings={this.props.strings}
					/>
				</div>
				<div className="row mb-4">
					<div className="col-12">
						<h3 className="mt-4 border-bottom">Encoding / Outputs</h3>
						{broadcastArchive.loading &&
							<div>Loading...</div>
						}
						{!broadcastArchive.loading &&
						<BroadcastItemArchiveBitrateChart broadcast={broadcast}
																							broadcastArchive={broadcastArchive}
																							strings={this.props.strings}
						/>
						}
						{!broadcastArchive.loading &&
						<BroadcastItemArchiveFramesChart broadcast={broadcast}
																						 broadcastArchive={broadcastArchive}
																						 strings={this.props.strings}
						/>
						}
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h3 className="mt-4 border-bottom">Network / Connections</h3>
						{broadcastArchive.loading &&
							<div>Loading...</div>
						}
					</div>
				</div>
				{!broadcastArchive.loading &&
				<div className="row mb-4">
					<div className="col-sm-6">
						<BroadcastItemArchivePingChart broadcast={broadcast}
																					 broadcastArchive={broadcastArchive}
																					 broadcastNetworkData={broadcastNetworkData}
																					 strings={this.props.strings}
						/>
					</div>
					<div className="col-sm-6">
						<BroadcastItemArchiveConnectionAttemptsChart broadcast={broadcast}
																												 broadcastArchive={broadcastArchive}
																												 broadcastNetworkData={broadcastNetworkData}
																												 strings={this.props.strings}
						/>
					</div>
				</div>
				}
				<div className="row mb-4">
					<div className="col-12">
						<h3 className="mt-4 border-bottom">Appliance</h3>
						{broadcastArchive.loading &&
						<div>Loading...</div>
						}
						{!broadcastArchive.loading &&
						<div>
							{<BroadcastItemArchiveApplianceChart broadcast={broadcast}
																									 broadcastArchive={broadcastArchive}
																									 strings={this.props.strings}
							/>}
						</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default BroadcastItemArchive;