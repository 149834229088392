import ClearCasterStore from '../model/ClearCasterStore';

export function isVersionGreaterThan (v1, v2)
{
	let v1parts = v1.split(".");
	let v2parts = v2.split(".");

	for (var i = 0; i < Math.min(v1parts.length, v2parts.length); i++)
	{
		let v1part = parseInt(v1parts[i],10);
		let v2part = parseInt(v2parts[i],10);
		if (v1part === v2part)
			continue;
		return (v1part > v2part);
	}
	return v1parts.length > v2parts.length;
}

export function isVersionGreaterThanOrEqual (v1, v2)
{
	try
	{
		let v1parts = v1.split(".");
		let v2parts = v2.split(".");

		if (v1parts.length !== v2parts.length)
		{
			return v1parts.length > v2parts.length;
		}

		for (var i = 0; i < v1parts.length; i++)
		{
			let v1part = parseInt(v1parts[i], 10);
			let v2part = parseInt(v2parts[i], 10);
			if (v1part === v2part)
				continue;
			return (v1part > v2part);
		}

		return true;
	}
	catch(err)
	{
		return false;
	}
}

export function checkFeature (featureName, encoderVersion)
{
	if (process.env.REACT_APP_CHECK_FEATURE_VERSIONS === 'false')
		return true;

	let state = ClearCasterStore.getState();
	let featureVersion = state.featureEncoderVersions[ featureName ];

	if (featureVersion === null || typeof featureVersion === 'undefined')
		return false;

	if ( isVersionGreaterThan(featureVersion, encoderVersion))
		return false;

	return true;
}

export function checkGraphQLFeature (featureName, graphQLBuild)
{
	if (process.env.REACT_APP_CHECK_FEATURE_VERSIONS === 'false')
		return true;

	let state = ClearCasterStore.getState();
	let featureVersion = state.featureGraphQLBuilds[ featureName ];

	if (featureVersion === null || typeof featureVersion === 'undefined')
		return false;

	if (isVersionGreaterThan(featureVersion, graphQLBuild))
		return false;

	return true;
}

export function getBroadcastsByDateCreated (broadcastMap)
{
	let broadcasts = [];
	for(let key in broadcastMap)
	{
		let broadcast = broadcastMap[key];
		broadcasts.push(broadcast);
	}
	broadcasts.sort(function (a, b) {
		let aCreated = Date.parse(a.createdAt);
		let bCreated = Date.parse(b.createdAt);
		return bCreated - aCreated;
	});
	return broadcasts;
}

export function getBroadcastsByName (broadcastMap)
{
	let broadcasts = [];
	for(let key in broadcastMap)
	{
		let broadcast = broadcastMap[key];
		broadcasts.push(broadcast);
	}
	broadcasts.sort(compareObjectValues("name","asc"));
	return broadcasts;
}

export function getEncodersByName (encoderMap)
{
	let encoders = [];
	for (let key in encoderMap)
	{
		let encoder = encoderMap[key];
		encoders.push(encoder);
	}
	encoders.sort(compareObjectValues("name","asc"));
	return encoders;
}

export function findNode(node, keys)
{
	if (typeof keys === 'string')
	{
		return node[keys];
	}
	else if (keys.length === 1)
	{
		return node[keys[0]] !== undefined ? node[keys[0]] : null;
	}
	else
	{
		let newKeys = [...keys];
		let key = newKeys.shift();
		return findNode(node[key], newKeys);
	}
}

// use this to sort arrays of objects
export function compareObjectValues(key, order='asc') {
	return function(a, b) {

		let varA = findNode(a,key);
		let varB = findNode(b,key);

		if(varA === null || varB === null) {
			// property doesn't exist on either object
			return 0;
		}

		varA = (typeof varA === 'string') ? varA.toUpperCase() : varA;
		varB = (typeof varB === 'string') ? varB.toUpperCase() : varB;

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return (
			(order === 'desc') ? (comparison * -1) : comparison
		);
	};
}

export function makeCancelablePromise (promise)
{
	let hasCanceled_ = false;
	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then((val) =>
			hasCanceled_ ? reject({isCanceled: true}) : resolve(val)
		);
		promise.catch((error) =>
			hasCanceled_ ? reject({isCanceled: true}) : reject(error)
		);
	});
	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true;
		},
	};
}

export function doubleEquals(a, b, threshold = 0.01)
{
	return (Math.abs((a-b)) <= threshold);
}
