import React, { Component } from 'react';

import {Form, Button, Modal, InputGroup, FormControl, Table} from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

import {
	isTemplateObjEnabled,
	getTemplateCategoryListV2,
	getTemplateListV2
} from '../../../templates/TemplateUtils.js';

// const uuidv1 = require('uuid/v1');

class ManageTemplatesDialog extends Component
{
	static getDefaultState()
	{
		return {
			templateCategory: undefined,
		};
	}

	constructor() {
		
		super();

		this.state = ManageTemplatesDialog.getDefaultState();

		this.templateSet = "pro";

		this.handleClose = this.handleClose.bind(this);
		this.onShow = this.onShow.bind(this);
		this.onEnter = this.onEnter.bind(this);
		this.defaultChanged = this.defaultChanged.bind(this);
		this.moveUp = this.moveUp.bind(this);
		this.moveDown = this.moveDown.bind(this);
		this.toggleVisibility = this.toggleVisibility.bind(this);
		this.toggleVisibilityCategory = this.toggleVisibilityCategory.bind(this);
		this.renameYes = this.renameYes.bind(this);
		this.renameNo = this.renameNo.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onDeleteCancel = this.onDeleteCancel.bind(this);
		this.deleteRow = this.deleteRow.bind(this);

	}

	onShow()
	{

	}

	onEnter()
	{
		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategoryList = getTemplateCategoryListV2(templateInfo, this.templateSet, true);

		let newState = {};

		if (this.props.clearcaster.manageTemplates.templateCategoryId !== undefined)
		{
			newState.templateCategory = this.props.clearcaster.manageTemplates.templateCategoryId;
		}
		else if (templateCategoryList.length > 0)
		{
			newState.templateCategory = templateCategoryList[0].id;
		}
		else
		{
			newState.templateCategory = undefined;
		}

		for (let key in templateCategoryList)
		{
			let templateCategory = templateCategoryList[key];

			let templateList = getTemplateListV2(templateInfo, this.templateSet, templateCategory.id);

			for (let key2 in templateList)
			{
				let template = templateList[key2];

				newState['name:' + template.id] = template.name;
				newState['namereset:' + template.id] = template.name;
				newState['namedirty:' + template.id] = false;
			}
		}

		this.setState(newState);
	}

	onDelete(context)
	{
		let templateSelectId = this.props.parent.doDeleteTemplate(context.templateCategoryId, context.templateId);

		this.props.parent.fixTemplateSelection(templateSelectId);

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_MANAGETEMPLATES_DIALOG,
			templateCategoryId: context.templateCategoryId
		});
	}

	onDeleteCancel(id)
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_MANAGETEMPLATES_DIALOG,
		});
	}

	deleteRow(evt)
	{
		let id = evt.target.id.split(":")[1];

		let appStrings = this.props.strings;

		let contextObj = {
			titleStr: appStrings.app.DeleteCustomTemplate,
			bodyStr: appStrings.app.DeleteCustomTemplateConfirm,
			buttonStr: appStrings.app.DeleteCustomTemplate,
			context: {templateId: id, templateCategoryId: this.state.templateCategory},
			onDelete: this.onDelete,
			onCancel: this.onDeleteCancel
		}

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_MANAGETEMPLATES_DIALOG,
		});

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_CUSTOMTEMPLATECONFIRMDELETE_DIALOG,
			context: contextObj
		});
	}

	renameYes(evt)
	{
		let id = evt.target.id.split(":")[1];

		let templateInfo = this.props.parent.state.templateInfo;

		if (templateInfo.templates.hasOwnProperty(id))
		{
			let template = templateInfo.templates[id];

			template.name = this.state['name:' + id].trim();

			let newState = {};

			newState['namereset:'+id] = this.state['name:' + id];
			newState['namedirty:' + id] = false;

			this.setState(newState);

			this.props.parent.doSave(templateInfo, this.templateSet);
		}
	}

	renameNo(evt)
	{
		let id = evt.target.id.split(":")[1];

		let newState = {};

		newState['name:' + id] = this.state['namereset:'+id];
		newState['namedirty:' + id] = false;

		this.setState(newState);
	}

	getTemplateCategory(templateInfo, id)
	{
		let templateCategory = undefined;

		if (templateInfo.templateSets[this.templateSet].hasOwnProperty(id))
			templateCategory = templateInfo.templateSets[this.templateSet][id];

		return templateCategory;
	}

	toggleVisibility(evt)
	{
		let id = evt.target.id.split(":")[1];

		let templateInfo = this.props.parent.state.templateInfo;

		if (templateInfo.templates.hasOwnProperty(id))
		{
			let template = templateInfo.templates[id];

			let isVisiable = isTemplateObjEnabled(template);

			template["enabled"] = !isVisiable;

			this.props.parent.doSave(templateInfo, this.templateSet);
		}
	}

	moveUp(evt)
	{
		let id = evt.target.id.split(":")[1];

		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategory = this.getTemplateCategory(templateInfo, this.state.templateCategory);
		if (templateCategory !== undefined)
		{
			var index = templateCategory.templates.indexOf(id);
			if (index > 0)
			{
				templateCategory.templates.splice(index, 1);
				index--;
				templateCategory.templates.splice(index, 0, id);
				this.props.parent.doSave(templateInfo, this.templateSet);
			}
		}
	}

	moveDown(evt)
	{
		let id = evt.target.id.split(":")[1];

		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategory = this.getTemplateCategory(templateInfo, this.state.templateCategory);
		if (templateCategory !== undefined)
		{
			var index = templateCategory.templates.indexOf(id);
			if (index >= 0)
			{
				templateCategory.templates.splice(index, 1);
				index++;
				templateCategory.templates.splice(index, 0, id);
				this.props.parent.doSave(templateInfo, this.templateSet);
			}
		}
	}

	defaultChanged(evt)
	{
		let id = evt.target.id.split(":")[1];

		console.log("defaultChanged: "+id);
		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategory = this.getTemplateCategory(templateInfo, this.state.templateCategory);
		if (templateCategory !== undefined)
		{
			templateCategory.defaultTemplate = id;
			this.props.parent.doSave(templateInfo, this.templateSet);
		}

	}

	handleClose()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_MANAGETEMPLATES_DIALOG,
		});
	}

	renderTable(templateCategoryId, templateList, isCategoryVisiable)
	{
		let elements;

		let index = 0;

		let count = Object.keys(templateList).length;

		let templateInfo = this.props.parent.state.templateInfo;
		let templateCategory = this.getTemplateCategory(templateInfo, templateCategoryId);

		if (templateCategory !== undefined)
		{
			if (count <= 0)
			{
				let element = <tr><td colSpan="5"><i>This destination has no templates</i></td></tr>
				elements = [elements, element];
			}
			else
			{
				for(let key in templateList)
				{
					let v = templateList[key];

					// let nameIdStr = 'name:' + v.id;

					let isVisiable = isCategoryVisiable && isTemplateObjEnabled(v);

					let element = <tr id={ "id:"+v.id } key={ "id:"+v.id }>
						<td style={{textAlign:"center"}}>
							{
								index === 0 ?
									<i key={ 'moveup:'+v.id } id={ 'moveup:'+v.id } className="fa fa-caret-up fa-disabled integration-icon"></i> :
									<i key={ 'moveup:'+v.id } id={ 'moveup:'+v.id } onClick={ this.moveUp } className="integration-icon-button fa fa-caret-up integration-icon"></i>
							}
							&ensp;
							{
								index === (count-1) ?
									<i key={ 'movedown:'+v.id } id={ 'movedown:'+v.id } className="fa fa-caret-down fa-disabled integration-icon"></i> :
									<i key={ 'movedown:'+v.id } id={ 'movedown:'+v.id } onClick={ this.moveDown } className="integration-icon-button fa fa-caret-down integration-icon"></i>
							}
						</td>
						<td style={{textAlign:"center"}}>
							{
								!isCategoryVisiable?
									<i key={ 'visibility:'+v.id } id={ 'visibility:'+v.id } className={ "fa fa-eye-slash fa-disabled integration-icon" }></i> :
									<i key={ 'visibility:'+v.id } id={ 'visibility:'+v.id } onClick={ this.toggleVisibility } className={ "integration-icon-button fa integration-icon "+(isVisiable?"fa-eye":"fa-eye-slash") }></i>
							}
						</td>
						<td style={{textAlign:"center"}}><Form.Check key={ 'default:'+v.id } id={ 'default:'+v.id } checked={ templateCategory.defaultTemplate === v.id } onChange={ this.defaultChanged } type="radio"  /></td>
						<td>
							<InputGroup>
								<FormControl key={ 'name:'+v.id } id={ 'name:'+v.id } size="sm" className="integration-table-textenter" value={ this.state['name:'+v.id] } onChange={ evt => { let newState = {}; newState['name:'+v.id] = evt.target.value; newState['namedirty:'+v.id] = true; this.setState(newState) } } />
								{
									this.state['namedirty:'+v.id] &&
									<InputGroup.Append>
										<div className={"integration-table-textenter-button integration-icon-button"} onClick={ this.renameYes }>
											<i key={'renameyes:' + v.id} id={'renameyes:' + v.id} className="fa integration-icon-button fa-check integration-icon"></i>
										</div>
									</InputGroup.Append>
								}
								{
									this.state['namedirty:'+v.id] &&
									<InputGroup.Append>
										<div className={"integration-table-textenter-button integration-icon-button"} onClick={ this.renameNo }>
											<i key={'renameno:' + v.id} id={'renameno:' + v.id} className="fa integration-icon-button fa-times integration-icon"></i>
										</div>
									</InputGroup.Append>
								}

							</InputGroup>
						</td>
						<td style={{textAlign: "center"}}>
							<i key={'delete:' + v.id} id={'delete:' + v.id} onClick={this.deleteRow} className='integration-icon-button integration-delete fa fa-trash integration-icon'></i>
						</td>
					</tr>;

					elements = [elements, element];

					index++;
				}
			}
		}


		return <tbody>{ elements }</tbody>;
	}

	toggleVisibilityCategory(evt)
	{
		let templateInfo = this.props.parent.state.templateInfo;

		// let id = evt.target.id.split(":")[1];

		let templateCategory = this.getTemplateCategory(templateInfo, this.state.templateCategory);
		if (templateCategory !== undefined)
		{
			let isEnabled = isTemplateObjEnabled(templateCategory);

			templateCategory["enabled"] = !isEnabled;
			this.props.parent.doSave(templateInfo, this.templateSet);
		}

	}

	render()
    {
		let appStrings = this.props.strings;
		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategoryList = getTemplateCategoryListV2(templateInfo, this.templateSet, true);
		let templateList = getTemplateListV2(templateInfo, this.templateSet, this.state.templateCategory);

		let isCategoryVisiable = false;

		let templateCategory = this.getTemplateCategory(templateInfo, this.state.templateCategory);
		if (templateCategory !== undefined)
		{
			isCategoryVisiable = isTemplateObjEnabled(templateCategory);
		}

		return (
			<Modal size="lg" show={ this.props.clearcaster.manageTemplates !== undefined } className="manage-templates-dialog" onEnter={ this.onEnter }  onShow={ this.onShow } onHide={ this.handleClose }>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.app.ManageTemplates }</Modal.Title><br/>
				</Modal.Header>

				<Modal.Body>
					<p>{ appStrings.app.ManageTemplatesDescription }</p>
					<div className="manage-templates-list">
						<Form.Group controlId="templateCategory">
							<Form.Label>{ appStrings.app.EncodingTemplateCategory }</Form.Label>
							<InputGroup key={ "overlayGraphicsURL-group" } className="mb-3">
								<Form.Control ref={ this.templateCategory } as="select" value={ this.state.templateCategory } onChange={ (evt) => { this.setState({templateCategory: evt.target.value}) } }>
									{ templateCategoryList.map ((v, key) => { return (
										<option key={ v.id } value={ v.id }>{ v.name }</option>
									)})}
								</Form.Control>
								<InputGroup.Append>
									<div style={{paddingTop:"6px"}}>
										&nbsp;&nbsp;&nbsp;&nbsp;<i key={ 'visibility:'+this.state.templateCategory } id={ 'visibility:'+this.state.templateCategory } onClick={ this.toggleVisibilityCategory } className={ "integration-icon-button fa big-icon "+(isCategoryVisiable?"fa-eye":"fa-eye-slash") }></i>&nbsp;&nbsp;&nbsp;&nbsp;
									</div>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
						<div className="integration-scrolling-list">
							<Table className={"integration-manage-templates-table"} bordered size={"sm"}>
								<thead>
								<tr>
									<th style={{textAlign:"center"}}>{ appStrings.app.Reorder }</th>
									<th style={{textAlign:"center"}}>{ appStrings.app.Visibility }</th>
									<th style={{textAlign:"center"}}>{ appStrings.app.Default }</th>
									<th>{ appStrings.app.TemplateName }</th>
									<th style={{textAlign:"center"}}>{ appStrings.app.Delete }</th>
								</tr>
								</thead>
								{ this.renderTable(this.state.templateCategory, templateList, isCategoryVisiable) }
							</Table>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Close }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ManageTemplatesDialog;
