import SingularLiveGraphicsController from '../../../../controller/SingularLiveGraphicsController';
const deepEqual = require('deep-equal');

export function isDataDirty(singularLiveData,appInstanceId,data)
{
	return !deepEqual(singularLiveData.data[appInstanceId],data);
}

export function luminosityFromRGB(hexRGB)
{
	let m = hexRGB.substr(1).match(hexRGB.length === 7 ? /(\S{2})/g : /(\S{1})/g);
	let r = parseInt(m[0], 16), g = parseInt(m[1], 16), b = parseInt(m[2], 16);
	return Math.round((r*2+b+g*3)/6);
}

export function textColorFromBackground(hexRGB)
{
	if (luminosityFromRGB(hexRGB) > 160)
		return "black";
	else
		return "white";
}

export function showSlate(appInstance,graphic,compositionData,hideFirst)
{
	return async (evt) => {
		if (hideFirst === undefined || hideFirst === true)
		{
			await SingularLiveGraphicsController.hideGraphic(appInstance,graphic,compositionData);
		}
		await SingularLiveGraphicsController.showGraphic(appInstance,graphic,compositionData);
	}
}

export function formChange(singularLiveData,previewAppInstance,appInstance,graphic,compositionData,changeObj)
{
	let compositions = compositionData[graphic].compositions;
	for (let i = 0; i < compositions.length; i++)
	{
		for (let changeKey in changeObj)
		{
			if (compositions[i].controlNode.payload.hasOwnProperty(changeKey))
			{
				compositions[i].controlNode.payload[changeKey] = changeObj[changeKey];
			}
		}
	}
	SingularLiveGraphicsController.saveData(singularLiveData,previewAppInstance.id,compositionData);
	return compositionData;
}

export function colorChange(singularLiveData,previewAppInstance,appInstance,graphic,compositionData,objKey)
{
	return async (color) => {
		let changeObj = {};
		changeObj[objKey] = color.hex;
		formChange(singularLiveData,previewAppInstance,appInstance,graphic,compositionData,changeObj);
		await showSlate(previewAppInstance,graphic,compositionData)();
	};
}

export function persistData(controller,previewAppInstance,appInstance,singularLiveData)
{
	return async (evt) => {
		let pageState = SingularLiveGraphicsController.getData(singularLiveData,"manage");
		if (pageState == null) pageState = {};
		let compositionData = SingularLiveGraphicsController.getData(singularLiveData,previewAppInstance.id);
		pageState.saving = true;
		SingularLiveGraphicsController.saveData(singularLiveData,"manage",pageState);
		SingularLiveGraphicsController.saveData(singularLiveData,appInstance.id,compositionData);
		await SingularLiveGraphicsController.persistData(controller,appInstance.id,compositionData);
		pageState.saving = false;
		pageState.editing = '';
		SingularLiveGraphicsController.saveData(singularLiveData,"manage",pageState);
	}
}

export function cancel(singularLiveData,previewAppInstance)
{
	return async (evt) => {
		let pageState = SingularLiveGraphicsController.getData(singularLiveData,"manage");
		await SingularLiveGraphicsController.saveData(singularLiveData,previewAppInstance.id,undefined);
		pageState.forceRefresh = true;
		pageState.editing = '';
		await SingularLiveGraphicsController.saveData(singularLiveData,"manage",pageState);
	}
}

export async function graphics_componentDidMount(singularLiveData,appInstance,graphic)
{
	let previewAppInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,appInstance.preview);
	let compositionData = SingularLiveGraphicsController.getData(singularLiveData,previewAppInstance.id);
	if (compositionData == null)
	{
		compositionData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
	}
	if (compositionData == null)
	{
		compositionData = SingularLiveGraphicsController.getDefaultData();
	}
	await SingularLiveGraphicsController.hideAllGraphics(previewAppInstance,compositionData);
	showSlate(previewAppInstance,graphic,compositionData)();
}