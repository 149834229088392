import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { healthStatusClasses } from '../../../model/BroadcastUIAdapter';

class BroadcastItemMonitorAppliance extends Component
{
	render()
	{
		let cpuTemp = 0;
		let cpuTempWidth = '0';
		let cpuLoad = 0;
		let cpuLoadWidth = '0';
		let captureCardTemp = 0;
		let captureCardTempWidth = '0';

		if (this.props.encoder !== null &&
			this.props.encoder['nearRealTimeData'] &&
			this.props.encoder['nearRealTimeData']['hardwareDynamic'])
		{
			if (this.props.encoder['nearRealTimeData']['hardwareDynamic']['cpuTemperature'])
			{
				cpuTemp = this.props.encoder['nearRealTimeData']['hardwareDynamic']['cpuTemperature'];
			}
			else if (this.props.encoder['nearRealTimeData']['hardwareDynamic']['cpuTempurature'])
			{
				cpuTemp = this.props.encoder['nearRealTimeData']['hardwareDynamic']['cpuTempurature'];
			}
			cpuTempWidth = (cpuTemp*100/120)+'%';
			cpuLoad = (this.props.encoder['nearRealTimeData']['hardwareDynamic'].cpuLoadSystem*100).toFixed(0);
			cpuLoadWidth = cpuLoad+'%';
		}

		if (this.props.encoder !== null &&
			this.props.encoder['nearRealTimeData'] &&
			this.props.encoder['nearRealTimeData']['encoder'] &&
			this.props.encoder['nearRealTimeData']['encoder']['captureSessionInfo'])
		{
			if (this.props.encoder['nearRealTimeData']['encoder']['captureSessionInfo']['temperature'] != null)
			{
				captureCardTemp = this.props.encoder['nearRealTimeData']['encoder']['captureSessionInfo']['temperature'];
			}
			captureCardTempWidth = (captureCardTemp*100/120)+'%';
		}
		console.log(this.props.encoder['nearRealTimeData']);
		console.log('captureCardTemp:' + captureCardTemp);

		return (
			<Card bg="transparent" border="0">
				<Card.Header className="pl-0 pb-1 border-0 bg-white">
					{this.props.strings.app.MonitoringCardTitleAppliance}&nbsp;
					{(this.props.broadcastUI !== null &&
						this.props.broadcastUI.healthStatuses.appliance > 0) &&
						<i className={healthStatusClasses[this.props.broadcastUI.healthStatuses.appliance]}></i>
					}
				</Card.Header>
				<Card.Body className="bg-white">
					<div className="container m-0 p-0">
						<div className="row">
							<div className="col-3 pl-0 pt-2 pr-2 pb-2 text-center">
								CPU Temp<br /><small className="text-muted">(&deg;C)</small>
							</div>
							<div className="col-9 p-2">
								<div className="monitor-barchart-outer monitor-barchart-outer-temp">
									<div className="monitor-barchart-bar" style={{width:cpuTempWidth}}>
										<div className="monitor-barchart-label">
											{cpuTemp}
										</div>
									</div>
								</div>
								<div className="monitor-barchart-scale">
									<div className="monitor-barchart-scale-6 monitor-barchart-scale-label"><span className="float-left">0</span> 20</div>
									<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">40</div>
									<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">60</div>
									<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">80</div>
									<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">100</div>
									<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">120</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-3 pl-0 pt-2 pr-2 pb-2 text-center">
								CPU Load<br/><small className="text-muted">(%)</small>
							</div>
							<div className="col-9 p-2">
								<div className="monitor-barchart-outer monitor-barchart-outer-load">
									<div className="monitor-barchart-bar" style={{width:cpuLoadWidth}}>
										<div className="monitor-barchart-label">
											{cpuLoad}
										</div>
									</div>
								</div>
								<div className="monitor-barchart-scale">
									<div className="monitor-barchart-scale-5 monitor-barchart-scale-label"><span className="float-left">0</span> 20</div>
									<div className="monitor-barchart-scale-5 monitor-barchart-scale-label">40</div>
									<div className="monitor-barchart-scale-5 monitor-barchart-scale-label">60</div>
									<div className="monitor-barchart-scale-5 monitor-barchart-scale-label">80</div>
									<div className="monitor-barchart-scale-5 monitor-barchart-scale-label">100</div>
								</div>
							</div>
						</div>
						{ captureCardTemp > 0 &&
							<div className="row">
								<div className="col-3 pl-0 pt-2 pr-2 pb-2 text-center">
									Capture Card Temp<br /><small className="text-muted">(&deg;C)</small>
								</div>
								<div className="col-9 p-2">
									<div className="monitor-barchart-outer monitor-barchart-outer-capturecard-temp">
										<div className="monitor-barchart-bar" style={{width:captureCardTempWidth}}>
											<div className="monitor-barchart-label">
												{captureCardTemp}
											</div>
										</div>
									</div>
									<div className="monitor-barchart-scale">
										<div className="monitor-barchart-scale-6 monitor-barchart-scale-label"><span className="float-left">0</span> 20</div>
										<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">40</div>
										<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">60</div>
										<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">80</div>
										<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">100</div>
										<div className="monitor-barchart-scale-6 monitor-barchart-scale-label">120</div>
									</div>
								</div>
							</div>
						}
					</div>
				</Card.Body>
			</Card>
		);
	}
}
export default BroadcastItemMonitorAppliance;