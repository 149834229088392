import React from 'react';

// TODO: When internationalizing, solve two cases:
// 1. in App.js where we find a strings object and pass it down, and
// 2. in controller/* where we grab AppStrings directly

const date = new Date();

const AppStrings =
{
	calendarStrings:
	{
		lastDay : '[Yesterday at] LT',
		sameDay : '[Today at] LT',
		nextDay : '[Tomorrow at] LT',
		lastWeek : '[last] dddd [at] LT',
		nextWeek : 'dddd [at] LT',
		sameElse : 'L'
	},
	nav:
	{
		EnterpriseOnly:"",

		Account:"Account",
		Broadcasts:"Broadcasts",
		Broadcast:"Broadcast",
		copyrightNotice:"" + String.fromCharCode(169) +
			" " + date.getFullYear() +
			" Wowza Media Systems" + String.fromCharCode(8482) + ", LLC. " +
			"All rights reserved.",
		ClearCasters:"ClearCasters",
		Manage:"Manage",
		help:"Help",
		legalNotice:"Legal Notice",
		ManageSupportContacts:"Manage Support Contacts",
		Monitoring:"Monitoring",
		Monitor:"Monitor",
		MonitoringLiveBroadcasts:"Live Broadcasts",
		MonitoringArchivedBroadcasts:"Archived Broadcasts",

		ManageEncoders: "Encoders",
		ManageTemplates: "Templates",
		ManageWSC: "Wowza Streaming Cloud",
		ManageIntegrations: "Integrations",
		ManageRecordings: "Recordings",
		ManageTiltlingGraphics: "Graphics",
		ManageUsers: "Users",

		privacyPolicy:"Privacy Policy",
		signIn:"Sign In",
		SignInToFacebook:"Sign in to Facebook",
		signOut:"Sign Out",
		SignOutFacebookPrompt:"Sign out of Facebook account: Are you sure?",
		SignOutWowzaPrompt:"Sign out of Wowza account ##user##: Are you sure?",
		Simulcast:"Simulcast",
		Support:"Support",
		supportTools:"Support Tools",
		systemStatus:"System Status",
		SwitchOrganization:"Switch Organization",
		wowzaHome:"Wowza Home",
		wowzaMediaSystemsLLC:"Wowza Media Systems&tm;, LLC"
	},
	error:
	{
	},
	app:
	{
		YesLower: "yes",
		NoLower: "no",
		YesTitle: "Yes",
		NoTitle: "No",

		ON: "ON",
		OFF: "OFF",

		Beta: "BETA",
		ComingSoon: "Coming soon...",
		Unknown:"Unknown",

		Passphrase:"Passphrase (minimum 8 characters)",
		PassphraseName:"Name",
		PassphraseDialogPrompt1:"Enter a name, expiration time, and passphrase to share access to this broadcast. Make note of your passphrase and the corresponding URL. The passphrase isn’t accessible after you close this dialog.",
		PassphraseDialogPrompt2:"Copy and share this URL, along with the corresponding passphrase, to grant access to the broadcast.",
		PassphraseProtected:"Passphrase protected",
		PassphrasePrompt:"Enter the passphrase",
		PassphraseSubmit:"OK",
		BroadcastShared:"This broadcast is shared.",
		BroadcastShareDialogTitle:(props) => <span>Share broadcast: {props.broadcastName}</span>,
		SharingURL:"Sharing URL",
		GenerateSharingURL:"Generate Sharing URL",
		GenerateNewSharingURL:"Generate a new sharing URL",
		DeleteSharingURL:"Delete Sharing URL",
		DeleteSharingURLPrompt:"Are you sure you want to delete this broadcast sharing URL?",
		KeyCreatedAt:"Created: ",
		KeyExpiresAt:"Expires: ",
		ExpireDay:"1 day",
		ExpireDays:"days",
		CopiedToClipboard:"Copied to clipboard",

		Activate:"Activate",
		ActivatingClearCaster:(props)=><span>Activating {props.name}</span>,
		ActivateEnterCode:"ClearCaster Activation Code",
		ActivateNow:"Activate",
		ActivatePrompt:"Turn on your ClearCaster and enter the activation code shown on your connected monitor.",
		ActivateTitle:"Activate a New ClearCaster",
		ActivateNoSubscriptionTitle: "No Available Subscriptions",
		ActivateSubscriptionLimitReached: "Your ClearCaster Cloud Orchestration Subscription has to have enough open channels to activate a new ClearCaster device. See <a href='https://www.wowza.com/pricing' target='_blank'>our store</a> for more information on how to purchase a subscription.",
		ActivateProNoSubscription: "Your ClearCaster Cloud Orchestration subscription has no available channels. This device will be restricted to Subscription Expired mode. To access full functionality, remove an existing ClearCaster from your account or purchase an additional channel.",
		NameThisClearCaster:"Name this ClearCaster",
		TOSMessage:(props) => <span>I understand that my use of the ClearCaster hardware device and included software, is governed by the <a href={props.tosLink} target="_blank" rel="noopener noreferrer">End User License Agreement for Wowza Streaming Engine™ 4 Software for ClearCaster Cloud Orchestration Subscription</a> ("ClearCaster EULA"), <a href={props.cloudTosLink} target="_blank" rel="noopener noreferrer">Wowza ClearCaster Cloud Orchestration Subscription Terms of Use</a>, and the <a href={props.privacyLink} target="_blank" rel="noopener noreferrer">Wowza Privacy and Cookies policies</a>. I have read and I agree to be bound by the ClearCaster EULA,  and the Wowza Privacy & Cookies policies.</span>,
		TOSErrorMessage:"Accept the Terms of Service",

		UpdateApply:"Apply Update",
		UpdateAvailable:"Update Available",
		UpdateAvailablePrompt:(props) => <span>A software update is available for your ClearCaster. This will update your software version from <strong>{props.currentVersion}</strong> to <strong>{props.newVersion}</strong></span>,
		UpdatingClearCaster:(props)=><span>Updating {props.name}</span>,

		Settings:"Settings",
		Save:"Save",
		SendLogs:"Send Logs",
		Restart:"Restart",
		RestartNow:"Restart now",
		RestartLater:"Restart later",
		Deactivate:"Delete",
		EncoderSettingsClearCasterName:"ClearCaster Name",
		EncoderSettingsApplyAssetPackage:"Apply Asset Package",
		EncoderSettingsAssetPackageName:"Asset Package Name",
		EncoderSettingsAssetPackageURL:"Asset Package URL",
		RollbackBegin:"Begin Roll Back",
		RollbackDialogTitle:"Roll back to Prior Software Version",
		RollbackDialogPrompt1:"Rolling back is an advanced option that should only be done if absolutely necessary.",
		RollbackDialogPrompt2:"will reboot and install a prior software version. This process may take up to 30 minutes. Do not turn off the ClearCaster until the roll back is complete.",
		RollbackNotAvailable:"No roll back version available",
		RollbackToVersion:"Roll back to version: ",
		CurrentVersionColon:"Current version:",

		BuiltInDestinations:"Built-in",
		ExampleDestinations:"Example",
		CustomDestinations:"Custom",

		RecordBroadcast: "Record Broadcast",

		BroadcastItemsInputs:"INPUTS",
		BroadcastItemsOutputs:"OUTPUTS",
		BroadcastItemsTargets:"TARGETS",
		BroadcastItemsInput:"Input",
		BroadcastItemsStatus:"STATUS",
		BroadcastItemsHealth:"HEALTH",
		BroadcastItemsPerformance: "OUTPUT PERFORMANCE",
		BroadcastItemsTemplate: "Encoding Template",
		BroadcastItemsDestination: "Destination",
		BroadcastItemsServerURL: "Server URL",
		BroadcastItemsStreamName: "Stream Name",
		BroadcastItemsConnected: "CONNECTED",
		BroadcastItemsNotConnected: "NOT CONNECTED",

		EncoderModel: "Model",
		EncoderModel_CC_PRO: "ClearCaster Pro",
		EncoderModel_CC_MICRO: "ClearCaster Micro",
		EncoderModel_CC_ENTERPRISE: "ClearCaster Enterprise",

		SelectAnExistingTemplate:"Select an Existing Template",
		EditThisTemplate:"Edit This Template",
		EditACopy:"Edit a Copy",
		DeleteTemplate:"Delete Template",
		SaveTemplate:"Save Template",
		ManageCategoriesAndTemplates:"Manage Destinations & Templates",
		ManageDestinations:"Manage Destinations",
		ManageTemplates:"Manage Templates",
		TemplateJSON:"Template JSON",
		ManageDestinationsDescription:"Create, delete, and reorder your custom destinations.",
		ManageTemplatesDescription:"Manage the order and visibility of your custom templates.",
		CreateANewCategory:"Create a New Destination",
		AddNow:"Add Now",
		Reorder:"Reorder",
		Visibility:"Visibility",
		DestinationName:"Destination Name",
		TemplateName:"Template Name",
		Default:"Default",
		ShowDefaultDestinations:"Show built-in destinations in Create Broadcast dialog",
		SelectDestinationTitle:"Select Destination For New Template",
		SelectDestination:"Select Destination",
		Info:"Info",
		Status:"Status",
		Broadcast:"Broadcast",
		Namespace:"Namespace",
		SerialNumber:"Serial Number",
		LocalIpV4Address:"Local IP Address",
		SoftwareVersion:"Software Version",
		EncoderID:"Encoder ID",
		ClearCaster:"ClearCaster",
		Created: "Created",
		BroadcastId: "Broadcast ID",

		EndAndDeleteBroadcast: "Stop & Delete Broadcast",
		EndBroadcastTitle: "Stop Broadcast",
		EndBroadcastPrompt: "Do you want to stop and delete broadcast?",

		LinkedInAccessTokenName: "Name",


		WSCAccessKeyName: "Name",
		WSCAccessKeyAccessKey: "Access Key",
		WSCAccessKeyAPIKey: "API Key",
		CCAccessKeyName: "Name",
		CCAccessKeyAPIKey: "API Key",
		CCAccessKeySecretKey: "Secret Key",
		CCAccessGraphiQLURL: "GraphiQL URL",
		CCAccessKeyNote:"NOTE: Copy your API Key, Secret Key and GraphiQL URL now. Once you hit Close, you will no longer be able to view these keys.",

		DeleteBroadcast: "Delete Broadcast",
		DeleteWSCAccessKey: "Delete API Key",
		DeleteWSCAccessKeyConfirm: "Are you sure you want to delete this API Key?",
		DeleteLinkedinAccessToken: "Delete Access Token",
		DeleteLinkedinAccessTokenConfirm: "Are you sure you want to delete this Access Token?",
		DeleteCCAccessKey: "Delete API Key",
		DeleteCCAccessKeyConfirm: "Are you sure you want to delete this API Key?",
		AddWSCAccessKey: "Add a Wowza Streaming Cloud API Key",
		AddCCAccessKey: "Generate a New ClearCaster API Key",

		DeleteConfirm:"Are you sure you want to delete this broadcast?",

		DeleteCustomTemplate: "Delete Custom Template",
		DeleteCustomTemplateConfirm:"Are you sure you want to delete this custom template?",
		DeleteCustomDestination: "Delete Custom Destination",

		GoLiveConfirmTitle:(props) => <span>Go Live with <strong>{props.goLiveBroadcastName}</strong></span>,
		GoLiveConfirm:(props) => <span>The ClearCaster associated with this broadcast is currently being used by the live broadcast <strong>{props.activeBroadcastName}</strong>. If you click <strong>Go Live</strong>, the <strong>{props.activeBroadcastName}</strong> broadcast will be stopped.</span>,

		Monitor:"Monitor",
		Monitoring:"Monitoring",

		MISSINGFIELDS: "The following required fields are missing",
		MISSINGFIELD: "The following required field is missing",

		HDMIMonitorView: "HDMI Monitor View",
		EncodingTemplate: "Encoding Template",
		EncodingTemplateCategory:"Destination",
		EncodingTemplateModels:"Encoder Model",
		BroadcastName:"Broadcast Name",
		EnterTitle:"",
		Broadcast60FPS:"High Motion (50fps or greater)",
		EngineeringViewCheckbox:"Enable Engineering View",
		EngineeringViewCheckboxHelp: "Engineering View shows stream health data that can help troubleshoot issues.",
		OverlayGraphicsEnable: "Enable Graphics",
		GraphicsApplicationURL: "Graphics URL",
		TitlingAndGraphics:"Graphics",
		TitlingAndGraphicsHelp:"Enables browser-based titling and graphics overlays on your broadcast.",
		Vidpresso:"Vidpresso",
		Custom:"Custom",
		ServerURL:"Server URL",
		StreamName:"Stream Name/Key",
		EnterServerURL:"rtmp(s)://mycompany.com/live/stream",
		Username:"Username",
		EnterUsername:"",
		Password:"Password",
		EnterPassword:"",
		VideoBitrate:"Video Bitrate",
		AudioBitrate:"Audio Bitrate",
		AudioChannels:"Audio Channels",
		EnterVideoBitrate:"",
		EnterRTMPAuthentication:"Enter Server Authentication (optional)",

		AddWSCAPIKey:"Add a Wowza Streaming Cloud API Key",
		AddLinkedInAccessToken:"Add a LinkedIn Live Access Token",

		CreateBroadcast:"Create Broadcast",
		EditBroadcast:"Edit Broadcast",
		SaveBroadcast:"Save Broadcast",
		SearchForBroadcast:"Search for Broadcast",
		GoLive:"Go Live",
		Delete:"Delete",
		Edit:"Edit",
		Cancel:"Cancel",
		Close:"Close",
		EndBroadcast:"Stop Broadcast",
		Template:"Template",
		Category:"Category",
		Unavailable: "Unavailable",
		BroadcastProviderStreamTarget: {
			OverlayMessage: {
				"WSC_PUBLIC_API": "Loading Wowza Streaming Cloud Streams...",
				"LINKEDIN_ACCESS_TOKEN": "Loading LinkedIn Member details..."
			},
			NoProviderKeyMessage: {
				"WSC_PUBLIC_API": "No Wowza Streaming Cloud Access Keys",
				"LINKEDIN_ACCESS_TOKEN": "No LinkedIn Access Tokens"
			},
			NoProviderIdMessage: {
				"WSC_PUBLIC_API": "No Wowza Streaming Cloud Streams",
				"LINKEDIN_ACCESS_TOKEN": "No LinkedIn Destinations"
			}
		},

		Temperature:"Temperature",
		CPULoad:"CPU Load",
		MemoryFree:"Memory Free",

		Destination:"Destination",
		PingTime:"Ping Time",
		StreamTargetConnected:"Connected",
		ConnectionAttempts:"Connection Attempts",

		VideoSourceNotDetected:"Video source not detected",
		AudioNotDetected:"Audio not detected",

		EncoderStatus: "Status",
		BroadcastStatus:"Broadcast Status",
		VideoSourceSource: "Source",
		VideoSourceInput: "Input",
		VideoSourceOutput: "Output",
		VideoSourceTarget: "Target",
		VideoSourceFrameSize: "Frame Size",
		VideoSourceFrameRate: "Frame Rate",
		VideoSourceInterlaced: "Interlaced",
		VideoSourceCaptions: "Closed Captions",
		ApplianceStatus: "APPLIANCE STATUS",

		BitrateTarget: "Bitrate Target",
		BitrateAvailable: "Bitrate Available",
		BitrateActual: "Bitrate Actual",
		DroppedFrames: "Dropped Frames",

		StatusOffline:"OFFLINE",
		StatusReady:"READY",
		streamStatusPathOptimize:"PATH_OPTIMIZE",
		streamStatusReady:"READY",
		streamStatusPreview:"PREVIEW",
		streamStatusLive:"LIVE",

		ArchiveSummaryDate:"Date:",
		ArchiveSummaryDuration:"Duration:",
		ArchiveSummaryInput:"Input:",
		ArchiveSummaryAudioChannels:"Audio Channels: ",
		ArchiveSummaryOutput:"OUTPUT",
		ArchiveSummaryBitrate:"Bitrate:",
		ArchiveSummaryFrameSize:"Frame Size:",
		ArchiveSummaryCodec:"Codec:",
		ArchiveSummaryTarget:"Target",
		ArchiveSummaryKeyFrameInterval:"Keyframe Interval:",

		ArchiveSearchStartOver:"Start Over",
		ArchiveSearchClearcaster:"Clearcaster:",
		ArchiveSearchClearcasterAllClearcasters:"All Clearcasters",
		ArchiveSearchFrom:"From:",
		ArchiveSearchTo:"To:",
		ArchiveSearchBroadcastName:"Broadcast Name:",
		ArchiveSearchBroadcastAllBroadcasts:"All Broadcasts",
		ArchiveSearchBroadcastsFound:(props) => <span>{props.found} Broadcasts Found</span>,
		ArchiveSearchTableBroadcastName:"Broadcast Name",
		ArchiveSearchTableDateTime:"Date/Time",
		ArchiveSearchTableClearcaster:"Clearcaster",
		ArchiveSearchTableDuration:"Duration",
		ArchiveSearchTableView:"View",

		MonitoringCardTitleInput:"INPUT",
		MonitoringCardTitleOutput:"OUTPUT",
		MonitoringCardTitleHealth:"HEALTH",
		MonitoringCardTitleAdaptive:"ENCODING OUTPUTS (Mbps)",
		MonitoringCardTitleConnectionAttempts:"CONNECTION ATTEMPTS",
		MonitoringCardTitleDestinations:"DESTINATIONS",
		MonitoringCardTitleDroppedFrames:"DROPPED FRAMES",
		MonitoringCardTitleAppliance:"APPLIANCE",
		MonitoringCardTitleCPUGraph:"CPU LOAD (%) / CPU TEMPERATURE (°C)",
		MonitoringCardTitleEncoder:"ENCODER",
		MonitoringCardTitlePing:"ROUND TRIP TIME (ms)",
		MonitoringCartTitleFramesChart:"DROPPED FRAMES / TOTAL FRAMES",
		MonitoringCardTitleEventLog:"EVENT LOG",

		MonitoringIOEncodingTemplate:"Encoding Template",
		MonitoringIOInput:"Input",
		MonitoringIOOutput:"Output",
		MonitoringIOAudioChannels:"Audio Channels",
		MonitoringIOCodec:"Codec",

		MonitoringHealthOverallBroadcast:"OVERALL BROADCAST",
		MonitoringHealthVideo:"VIDEO",
		MonitoringHealthAudio:"AUDIO",
		MonitoringHealthNetwork:"NETWORK",
		MonitoringHealthDestinations:"DESTINATIONS",
		MonitoringHealthEncoding:"ENCODING",
		MonitoringHealthAppliance:"APPLIANCE",

		MonitoringDestinationsBandwidthRealization:"Bandwidth Realization:",
		MonitoringDestinationsOutputsAndTargets:"Outputs & Targets",
		MonitoringDestinationsBitrates:"Bitrates (Mbps)",
		MonitoringDestinationsAvailable:"Available",
		MonitoringDestinationsTarget:"Target",
		MonitoringDestinationsActual:"Actual",
		MonitoringDestinationsOutputBandwidth:(props) => <span>{props.streamName} BANDWIDTH:</span>,
		MonitoringDestinationsCurrentTotalBandwidth:"CURRENT TOTAL BANDWIDTH:",

		MonitoringEncoderEncoder:'Encoder',
		MonitoringEncoderCurrent:'Current',
		MonitoringEncoderFramerate:'Frame Rate',
		MonitoringEncoderDroppedFrames:'Dropped Frames',
		MonitoringEncoderPacketLoss:'Packet Loss',

		MonitoringHelpHealthVideoRed:"The ClearCaster is not receiving video. Check the incoming source.",
		MonitoringHelpHealthAudioYellow:"The audio level is zero for the incoming source. The audio source may be muted, or the wrong audio channel may be connected.",
		MonitoringHelpHealthAudioRed:"The ClearCaster is not receiving an audio / video source.",
		MonitoringHelpHealthNetworkYellow:"The ClearCaster bandwidth realization is below 60%. The total video bitrate that you are sending is higher than what your network has available. Consider lowering your total target bitrate or improving your onsite network.",
		MonitoringHelpHealthNetworkRed:"The ClearCaster appliance is offline. Check your network, physical connections, and the state of the appliance.",
		MonitoringHelpHealthDestinationsYellow:"At least one of your destinations has a round trip time greater than 100 ms. If this is a sustained measurement, you may be currently routed to a far-away destination (for example, San Francisco to Paris). If the round trip time is spiking, check your network availability.",
		MonitoringHelpHealthDestinationsRed:"At least one of your destinations is not connected. Check the availability of the ingest server and your target configuration.",
		MonitoringHelpHealthEncodingRed:"Frames are being dropped during the encoding process. Check your encoding configuration.",
		MonitoringHelpHealthApplianceYellow:"The ClearCaster appliance is reaching elevated load or temperature levels. Further increases in load or temperature may cause degradation in stream quality.",
		MonitoringHelpHealthApplianceRed:"The appliance CPU temperature or load is extremely high. ClearCaster will likely throttle the encoder to achieve lower levels, and stream degradation is possible.",
		MonitoringHelpBitrateChart:"The Encoding Outputs graph displays the target, available, and actual video bitrate for each output rendition in your broadcast. When using adaptive network encoding, the actual bitrate should be +/- 15% of the target bitrate. If you're using still video content, like a slate, the actual bitrate will drop to 500 Kbps or less regardless of the available bitrate, as there is limited complexity in the video.",
		MonitoringHelpBandwidthRealization:"Bandwidth Realization is the total available bitrate over the target bitrate. A value less than 100% indicates that adaptive network encoding is active and that the appliance doesn't have enough bandwidth to send the target bitrate to the destination. ",
		MonitoringHelpRoundTripTime:"The Round Trip Time graph displays the elapsed time, in milliseconds, for the ClearCaster appliance to send data via a TCP connection to the destination and receive a round trip response. A healthy round trip time is less than 50 ms; round trip time greater than 100 ms is cause for concern.",

		ArchivedBroadcasts:"Archived Broadcasts",

		ShowSlate:"Show Slate",
		MuteAudio:"Mute Audio",

		VendorNone:"None",
		VendorBuiltIn:"Built-in",
		VendorCustom:"Custom",

		VideoCodec_1:"Unknown",
		VideoCodec_2:"Spark",
		VideoCodec_3:"SCREEN",
		VideoCodec_4:"VP6",
		VideoCodec_5:"VP6A",
		VideoCodec_6:"SCREEN2",
		VideoCodec_7:"H.264",
		VideoCodec_8:"VPX",
		VideoCodec_9:"H.263",
		VideoCodec_10:"MPEG4",
		VideoCodec_11:"MPEG2",
		VideoCodec_12:"H.265",
		VideoCodec_VPX_8:"VP8",
		VideoCodec_VPX_9:"VP9",

		AudioCodec_00:"Unknown",
		AudioCodec_0:"PCM_BE",
		//AudioCodec_1:"PCM_SWF",
		AudioCodec_1:"AC3",
		AudioCodec_2:"MP3",
		AudioCodec_3:"PCM_LE",
		AudioCodec_4:"NellyMoser_16Mono",
		AudioCodec_5:"NellyMoser_8Mono",
		AudioCodec_6:"NellyMoser",
		AudioCodec_7:"G711_ALAW",
		AudioCodec_8:"G711_MULAW",
		//AudioCodec_9:"RESERVED",
		AudioCodec_9:"VORBIS",
		AudioCodec_10:"AAC",
		AudioCodec_11:"SPEEX",
		AudioCodec_12:"OPUS",
		AudioCodec_13:"ALS",
		AudioCodec_15:"MP3_8",

		NoLiveBroadcasts:"No live broadcasts",
		NoArchivedBroadcasts:"No archived broadcasts",
		NoneDetected:"None detected",

		ManageUsersTableFirstName:"First Name",
		ManageUsersTableLastName:"Last Name",
		ManageUsersTableEmail:"Email Address",
		ManageUsersTableRoles:"Roles",

		ManageGraphicsBackgroundColor:"Background Color",
		ManageGraphicsBackgroundImageURL:"Background Image URL",
		ManageGraphicsBaseColor:"Base Color",
		ManageGraphicsBevelColor:"Bevel Color",
		ManageGraphicsCountdownHours:"Hours",
		ManageGraphicsCountdownMinutes:"Minutes",
		ManageGraphicsCountdownSeconds:"Seconds",
		ManageGraphicsEditGraphics:"Edit Graphics",
		ManageGraphicsEditLogo:"Edit Logo",
		ManageGraphicsEditLowerThird:"Edit Lower Third",
		ManageGraphicsEditSlate:"Edit Slate",
		ManageGraphicsEditSlate1:"Edit Slate #1",
		ManageGraphicsEditSlate2:"Edit Slate #2",
		ManageGraphicsEnableGraphics:"Enable Graphics",
		ManageGraphicsEnableGraphicsPrompt:"Add slates, lower-third titles, and logo bugs to your broadcasts by turning on Graphics for your account.",
		ManageGraphicsImageURL:"Image URL",
		ManageGraphicsLocation:"Location",
		ManageGraphicsNoDataFound:"No graphics data found",
		ManageGraphicsPreview:"Preview",
		ManageGraphicsRename:"Rename:",
		ManageGraphicsSaveGraphics:"Save Graphics",
		ManageGraphicsSaving:"Saving...",
		ManageGraphicsSelectPackage:"Select a Graphics Package",
		ManageGraphicsSize:"Size",
		ManageGraphicsSubtitle:"Subtitle",
		ManageGraphicsText:"Text",
		ManageGraphicsTitle:"Title",
		ManageGraphicsTitle_logo:"LOGO",
		ManageGraphicsTitle_lower3rd:"LOWER THIRD",
		ManageGraphicsTitle_slate1:"SLATE #1",
		ManageGraphicsTitle_slate2:"SLATE #2",
		ManageGraphicsTransparency:"Transparency",
		ManageGraphicsUseBackgroundImage:"Use Background Image",
		ManageGraphicsUseCountdownClock:"Use Countdown Clock",

		SubscriptionBannerNoSubscriptions:"No ClearCaster Cloud Orchestration subscriptions found. Limited functionality available.",
		SubscriptionBannerClickToFAQ:"Check our FAQs for more information.",
		SubscriptionBannerClickToBuy:"Visit the store to purchase a subscription.",
		SubscriptionBannerClickToRenew:"Add another channel to your subscription.",
		SubscriptionBannerClickToReviewPayment:"Click here to review your payment settings.",
		SubscriptionBannerResellerToRenew:"Contact your reseller to renew your subscription.",
		SubscriptionBannerSubscriptionUnderwater:"Warning: Your account does not have a ClearCaster Cloud Orchestration Subscription with enough channels for all your paired devices.",
		SubscriptionBannerSubscriptionAtGoLiveLimit:(props) => <span>You have reached your subscription's channel limit.<br />You cannot start another broadcast until you stop an existing broadcast or <a href={props.portalLink} target="_blank" rel="noopener noreferrer">add another channel to your subscription.</a></span>,
		SubscriptionBannerSubscriptionAtGoLiveLimitReseller:(props) => <span>You have reached your subscription's channel limit.<br />You cannot start another broadcast until you stop an existing broadcast or <a href={props.storeLink} target="_blank" rel="noopener noreferrer">purchase another subscription.</a></span>,
		SubscriptionBannerSubscriptionWillExpire:(props) => <span>Warning: Your ClearCaster Orchestration subscription will expire in {props.days} days.</span>,
		SubscriptionBannerSubscriptionWillAutoRenew:(props) => <span>Your ClearCaster Orchestration subscription will auto renew in {props.days} days.</span>,
		SubscriptionExpiredBadge:"SUBSCRIPTION EXPIRED",
		SubscriptionsUnlimited:"unlimited subscriptions",
		SubscriptionsCapacityPlural:(props) => <span>You have {props.numEncoders} active ClearCasters and your account has capacity for {props.numSubscriptions}.</span>,
		SubscriptionsCapacitySingular:(props) => <span>You have {props.numEncoders} active ClearCaster and your account has capacity for {props.numSubscriptions}.</span>,
		FacebookEncoderVersionBanner: "<i class=\"fa fa-exclamation-triangle\"></i> Wowza ClearCaster Encoder build <strong>2.3.9.00</strong> or later is required to use Facebook Paired functionality. Please ensure the encoder(s) you are using are upgraded to at least <strong>2.3.9.00</strong> to stream from this page. See <a href=\"https://www.wowza.com/docs/wowza-clearcaster-user-guide-software-updates\">Update Wowza ClearCaster™</a> software for instructions on how to update the software on your Wowza ClearCaster appliance."
	},
};

export default AppStrings
