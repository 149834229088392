import React, { Component } from 'react';

import { CardDeck } from 'react-bootstrap';
import BroadcastUIAdapter from '../../../model/BroadcastUIAdapter';
import BroadcastItemMonitorHealth from './BroadcastItemMonitorHealth';
import BroadcastItemMonitorBitrateChart from './BroadcastItemMonitorBitrateChart';
import BroadcastItemMonitorDestinations from './BroadcastItemMonitorDestinations';
import BroadcastItemMonitorAppliance from './BroadcastItemMonitorAppliance';
import BroadcastItemMonitorEncoder from './BroadcastItemMonitorEncoder';
import BroadcastItemMonitorPingChart from './BroadcastItemMonitorPingChart';
import BroadcastItemMonitorConnectionAttemptsChart from './BroadcastItemMonitorConnectionAttemptsChart';

class BroadcastItemMonitor extends Component
{
	async componentDidMount()
	{
		// backfill graph data
		if (this.props.broadcast !== null)
		{
			let now = new Date();
			let startDateTime = null;
			if (this.props.broadcast.liveAt != null)
			{
				startDateTime = new Date(this.props.broadcast.liveAt);
			}
			for (let minsAgo = 0; minsAgo < this.props.clearcaster.broadcastLiveDataWindowSizeMinutes; minsAgo += 5)
			{
				let windowEndTime = new Date(now.getTime() - (minsAgo * 60000));
				let windowStartTime = new Date(windowEndTime - (5 * 6000));
				if (windowStartTime < startDateTime)
				{
					windowStartTime = startDateTime;
				}
				await this.props.controller.getNearRealTimeDataByBroadcast(this.props.broadcast.id,windowStartTime,windowEndTime);
			}
		}
	}

	render()
	{
		if (this.props.broadcast === undefined || this.props.broadcast === null)
		{
			return (<span></span>);
		}
		let broadcastData = this.props.broadcastLiveDataMap[this.props.broadcast.id];
		let broadcastUI = new BroadcastUIAdapter(this.props.broadcast,broadcastData,this.props.encoderMap,this.props.strings);
		let encoder = null;
		if (broadcastUI.broadcastEncoders.length > 0)
		{
			encoder = broadcastUI.broadcastEncoders[0];
		}

		return (
			<div>
				<div className="row mb-4">
					<BroadcastItemMonitorHealth broadcast={this.props.broadcast}
																			broadcastUI={broadcastUI}
																			encoder={encoder}
																			strings={this.props.strings}
					/>
				</div>
				<div className="row mb-4">
					<CardDeck className="w-100 col-sm-12">
						<BroadcastItemMonitorBitrateChart broadcast={this.props.broadcast}
																							broadcastUI={broadcastUI}
																							clearcaster={this.props.clearcaster}
																							strings={this.props.strings}
						/>
						<BroadcastItemMonitorDestinations broadcast={this.props.broadcast}
																							broadcastUI={broadcastUI}
																							strings={this.props.strings}
						/>
					</CardDeck>
				</div>
				<div className="row mb-4">
					<CardDeck className="w-100 col-sm-12">
						<BroadcastItemMonitorAppliance encoder={encoder}
																					 broadcastUI={broadcastUI}
																					 strings={this.props.strings}
						/>
						<BroadcastItemMonitorEncoder broadcastUI={broadcastUI}
																				 encoder={encoder}
																				 clearcaster={this.props.clearcaster}
																				 strings={this.props.strings}
						/>
					</CardDeck>
				</div>
				<div className="row mb-4">
					<div className="col-sm-6">
						<BroadcastItemMonitorPingChart broadcast={this.props.broadcast}
																					 broadcastUI={broadcastUI}
																					 clearcaster={this.props.clearcaster}
																					 strings={this.props.strings}
						/>
					</div>
					<div className="col-sm-6">
						<BroadcastItemMonitorConnectionAttemptsChart broadcast={this.props.broadcast}
																												 broadcastUI={broadcastUI}
																												 clearcaster={this.props.clearcaster}
																												 strings={this.props.strings}
						/>
					</div>
				</div>
				{/*
				<div className="row mb-4">
					<BroadcastItemMonitorEventLog broadcast={this.props.broadcast}
																				broadcastUI={broadcastUI}
																				strings={this.props.strings}
					/>
				</div>
				*/}

			</div>
		);
	}
}

export default BroadcastItemMonitor;