module.exports = {
	"name": "Low Latency",
	"id": "example-config-lowlatency",
	"type": "full",
	"presentation": {
		"layout": "SingleOutput",
		"widgets": [
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			}
		]
	},
	"template": {
		"input": {
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": 1920,
			"videoFrameHeightMax": 1080,
			"videoFrameRateMax": 30,
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition0",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl",
						"streamName": "$streamTargetStreamName",
						"username": "$streamTargetUsername",
						"password": "$streamTargetPassword"
					}
				],
				"encodingConfiguration": {
					"name": "rendition0",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "x264",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1920,
						"frameSizeHeight": 1080,
						"profile": "main",
						"bitrate": "3m",
						"bitrateMin": "1m",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "x264.preset",
								"value": "3",
								"type": "Long"
							},
							{
								"name": "x264.ref",
								"value": "1",
								"type": "Long"
							},
							{
								"name": "x264.bframes",
								"value": "0",
								"type": "Long"
							},
							{
								"name": "x264.tune-zerolatency",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "128k",
						"parameters": []
					}
				}
			}
		],
		"transcodingConfiguration": {
			"postProcessing": {
				"sortBufferEnable": true,
				"sortBufferSize": 50,
				"sortBufferFlushInterval": 25
			}
		}
	}
};