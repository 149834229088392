import React, { Component } from 'react';

import { Form, Button, Modal, Alert } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';
import { makeCancelablePromise } from '../../../controller/ClearCasterAdminUtils';

class ClearCasterAccessKeyCreateDialog extends Component
{
	static getDefaultState()
	{
		return {
			name:"",
			accessKey:"",
			secretKey:"",
			createPromise:null,
			alert:undefined,
		};
	}

	constructor(props) {
		
		super(props);

		this.state = ClearCasterAccessKeyCreateDialog.getDefaultState();

		this.handleAdd = this.handleAdd.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.onEnter = this.onEnter.bind(this);
	}

	componentWillUnmount() {
		if (this.state.createPromise !== null)
		{
			this.state.createPromise.cancel();
		}
	}

	onShow()
	{

	}

	onEnter()
	{
		this.setState(ClearCasterAccessKeyCreateDialog.getDefaultState());
	}

	handleClose()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_CCACCESSKEYCREATE_DIALOG,
		});
	}

	handleAdd()
	{
		if (this.state.createPromise !== null)
		{
			return;
		}
		let appStrings = this.props.strings;

		let missingFields;
		let missingCount = 0;

		if (this.state.name === undefined || this.state.name.length <= 0)
		{
			let keyStr = appStrings.app.WSCAccessKeyName;

			if (missingFields !== undefined)
				missingFields = [missingFields, (<span key={ keyStr+" dim" }>, </span>)];

			missingFields = [missingFields, (<strong key={ keyStr }>{ keyStr }</strong>)];
			missingCount++;
		}

		if (missingCount > 0)
		{
			let msg = missingCount>1?appStrings.app.MISSINGFIELDS:appStrings.app.MISSINGFIELD;
			let alert = {
				body: <span key={ "msg" }>{ msg }:&nbsp;<span key={ "body" }>{ missingFields }</span></span>,
			};

			this.setState({alert: alert});

			return;
		}
		else
		{
			this.setState({alert:undefined});
		}

		let _this = this;
		let createPromise = makeCancelablePromise(
			this.props.controller.createAccessKey(this.state.name).then((resp) => {

				console.log(JSON.stringify(resp));

				if (resp['errors'] && resp['errors'].length > 0)
				{
					let msg = resp['errors'][0]['message'];
					_this.setState({
						alert:{
							body: <span key={ "msg" }>{ msg }</span>
						},
						createPromise:null
					});

				}
				else if (resp['data']['createAccessKey'])
				{
					_this.setState({
						name:resp['data']['createAccessKey']['name'],
						accessKey:resp['data']['createAccessKey']['id'],
						secretKey:resp['data']['createAccessKey']['secret'],
						createPromise:null
					});
					_this.props.controller.getNamespaceAccessKeys();
				}
				else
				{
					_this.setState({createPromise:null});
				}
			})
		);

		this.setState({createPromise:createPromise});

		// let _this = this;
		//
		// let input = {
		// 	name: this.state.name,
		// 	accessKey: this.state.accessKey,
		// 	secretKey: this.state.apiKey,
		// 	type: "WSC_PUBLIC_API"
		// };
		//
		// this.props.controller.mutationCreateProviderKey(input).then((resp) => {
		// 	console.log(JSON.stringify(resp));
		// 	_this.props.controller.forcePolling();
		// });
		//
		// ClearCasterStore.dispatch({
		// 	type: ActionTypes.HIDE_WSCACCESSKEYCREATE_DIALOG,
		// });
	}

	render()
    {
		let appStrings = this.props.strings;

		var jsonStr = "{\"key\":\""+this.state.accessKey+"\",\"secret\":\""+this.state.secretKey+"\",\"url\":\""+window.config.REACT_APP_GRAPHQL_URL+"\"}";

		return (
			<Modal className="integrations-add-cc-dialog" show={ this.props.clearcaster.ccAccessKeyCreateDialogShowing } onEnter={ this.onEnter } onShow={ this.onShow } onHide={this.handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.app.AddCCAccessKey }</Modal.Title>
				</Modal.Header>

				<Modal.Body style={{minHeight:'115px'}}>
					{ this.state.alert !== undefined &&
						<Alert variant="danger">
						{ this.state.alert.heading !== undefined &&
							<Alert.Heading>{ this.state.alert.heading }</Alert.Heading>
						}
						{ this.state.alert.body }
						</Alert>
					}
					{this.state.createPromise === null && this.state.accessKey === '' &&
						<Form>
							<Form.Group controlId="name">
								<Form.Label>{ appStrings.app.WSCAccessKeyName }&nbsp;*</Form.Label>
								<Form.Control value={ this.state.name } onChange={evt => this.setState({ name: evt.target.value })} />
							</Form.Group>
						</Form>
					}
					{this.state.createPromise === null && this.state.accessKey !== '' &&
						<Form>
							<div className="row mb-4">
								<div className="col-4">
									<strong>{this.props.strings.app.CCAccessKeyName}</strong><br />
									<Form.Control value={ this.state.name } readOnly={true}/>
								</div>
							</div>
							<div className="row mb-4">
								<div className="col">
									<strong>{this.props.strings.app.CCAccessKeyAPIKey}</strong><br />
									<Form.Control value={ this.state.accessKey } readOnly={true} />
								</div>
							</div>
							<div className="row mb-4">
								<div className="col">
									<strong>{this.props.strings.app.CCAccessKeySecretKey}</strong><br />
									<Form.Control value={ this.state.secretKey } readOnly={true} />
								</div>
							</div>
							<div className="row mb-4">
								<div className="col">
									<strong>{this.props.strings.app.CCAccessGraphiQLURL}</strong><br />
									<Form.Control as="textarea" rows="6" value={ window.config.REACT_APP_GRAPHQL_URL.replace("graphql", "graphiql") + "/?credentials=" + encodeURIComponent(jsonStr) } readOnly={true} />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<Alert variant={'secondary'}>
										{this.props.strings.app.CCAccessKeyNote}
									</Alert>
								</div>
							</div>
						</Form>
					}
				</Modal.Body>

				<Modal.Footer>
					{this.state.accessKey === '' &&
						<>
							<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
							<Button variant="primary" onClick={this.handleAdd}>{ appStrings.app.AddCCAccessKey }</Button>
						</>
					}
					{this.state.accessKey !== '' &&
						<div>
							<Button variant="primary" onClick={this.handleClose}>{ appStrings.app.Close }</Button>
						</div>
					}
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ClearCasterAccessKeyCreateDialog;
