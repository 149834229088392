import React, { Component } from 'react';

import BroadcastItem from './BroadcastItem';
import FacebookSharedBroadcast from './facebook/FacebookSharedBroadcast';
import { getBroadcastTemplateInfo } from '../../model/BroadcastUIAdapter';
import { getBroadcastsByDateCreated } from '../../controller/ClearCasterAdminUtils';

class BroadcastList extends Component
{
    render()
    {
		let broadcasts = getBroadcastsByDateCreated(this.props.clearcaster.broadcastMap);

		if (process.env.REACT_APP_ENABLE_FACEBOOK_PAIRED_WORKFLOW === 'true' &&
			this.props.clearcaster.broadcastSharing.active &&
			broadcasts.length === 1 &&
			getBroadcastTemplateInfo(broadcasts[0])['isFacebookPaired'] === true)
		{
			return <FacebookSharedBroadcast broadcast={broadcasts[0]} strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />;
		}

		return (
			<div className="broadcast-list">
			{ broadcasts.map((v, key) => {
					return <BroadcastItem key={key} broadcast={v} strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				})
			}
			</div>
		);
	}
};

export default BroadcastList;
