import React, { Component } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import LoadingOverlay from 'react-loading-overlay';
import { mixObjects } from '../../controller/UniversalAdminUtils';

class ActivateDialog extends Component
{

	constructor(props)
	{
		super(props);
		this.onActivateNameChange = this.onActivateNameChange.bind(this);
		this.onAcceptedTOSChange = this.onAcceptedTOSChange.bind(this);
		this.close = this.close.bind(this);
		this.activate = this.activate.bind(this);
	}

	onActivateNameChange(event)
	{
		let activateForm = this.props.clearcaster.activateForm;
		activateForm.activateName = event.target.value;
		ClearCasterStore.dispatch(mixObjects(
			{type:ActionTypes.SET_ACTIVATE_FORM},
			activateForm
		));
	}
	onAcceptedTOSChange(event)
	{
		let activateForm = this.props.clearcaster.activateForm;
		activateForm.acceptedTOS = event.target.checked;
		activateForm.error = !event.target.checked;
		if (activateForm.error)
		{
			activateForm.errorMessage = this.props.strings.app.TOSErrorMessage;
		}
		ClearCasterStore.dispatch(mixObjects(
			{type:ActionTypes.SET_ACTIVATE_FORM},
			activateForm
		));
	}

	close(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ACTIVATE_FORM,
			dialogShowing:false
		});
		if (event != null)
			event.preventDefault();
	}

	async activate(event)
	{
		let activateForm = this.props.clearcaster.activateForm;
		if (!this.props.clearcaster.activateForm.acceptedTOS)
		{
			activateForm.error = true;
			activateForm.errorMessage = this.props.strings.app.TOSErrorMessage;
			ClearCasterStore.dispatch(mixObjects(
				{type:ActionTypes.SET_ACTIVATE_FORM},
				activateForm
			));
		}
		else
		{
			activateForm.acceptedTOS = true;
			activateForm.error = false;
			activateForm.loading = true;
			ClearCasterStore.dispatch(mixObjects(
				{type:ActionTypes.SET_ACTIVATE_FORM},
				activateForm
			));
			this.props.controller.activateEncoder(activateForm)
			.then((result)=>{
				console.log('success');
				console.log(result);
				if (result['errors'] != null && result['errors'].length > 0)
				{
					activateForm.error = true;
					activateForm.errorMessage = <div>{result['errors'].map((obj,key)=>{ return (<div key={key}>{obj.message}</div>) })}</div>;
					activateForm.loading = false;
					ClearCasterStore.dispatch(mixObjects(
						{type:ActionTypes.SET_ACTIVATE_FORM},
						activateForm
					));
				}
				else
				{
					ClearCasterStore.dispatch({
						type:ActionTypes.SET_ACTIVATE_FORM,
						dialogShowing:false,
						activationCode:'',
						activateName:'',
						loading:false
					});
				}
			})
			.catch((err)=>{
				console.log('error');
				console.log(err);
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_ACTIVATE_FORM,
					error:true,
					errorMessage:JSON.stringify(err),
					loading:false
				});
			});
		}
	}

	render()
	{
		let tosMessage = this.props.strings.app.TOSMessage({
			"tosLink":"https://www.wowza.com/wp-content/uploads/WowzaStreamingEngine_LicenseAgreement_ClearCaster_Units.pdf",
			"cloudTosLink":"https://www.wowza.com/legal/clearcaster-cloud-terms-of-use",
			"privacyLink":"https://www.wowza.com/legal/privacy"
		});

		let tosClassName = this.props.clearcaster.activateForm.tosError ? "has-error" : "";

		return (
			<Modal show={this.props.clearcaster.activateForm.dialogShowing} onHide={this.close}>
				<LoadingOverlay active={ this.props.clearcaster.activateForm.loading } spinner text={ this.props.strings.app.ActivatingClearCaster({name:this.props.clearcaster.activateForm.activateName}) } >
					<Modal.Header closeButton>
						<Modal.Title>{this.props.strings.app.ActivateTitle}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group>
								{ this.props.clearcaster.activateForm.proNoSubscription &&
									<strong>{this.props.strings.app.ActivateProNoSubscription}</strong>
								}
							</Form.Group>
							<Form.Group>
								<label style={{width:'100%'}} htmlFor="dialog-activate-name-input">
									{this.props.strings.app.NameThisClearCaster}
								</label>
								<input id="dialog-activate-name-input" className="form-control" type="text" value={this.props.clearcaster.activateForm.activateName} onChange={this.onActivateNameChange} />
							</Form.Group>
							<Form.Group>
								<div className={tosClassName}>
									{ this.props.clearcaster.activateForm.error &&
									<Alert id="dialog-activate-tos-error" variant={"danger"}>{ this.props.clearcaster.activateForm.errorMessage }</Alert>
									}
									<div className="checkbox">
										<Form.Check type={"checkbox"} defaultChecked={this.props.clearcaster.activateForm.acceptedTOS} label={tosMessage} onChange={this.onAcceptedTOSChange} />
									</div>
								</div>
							</Form.Group>
							<Form.Group>
								<Button id="dialog-activate-cancel-button" variant="outline-secondary" onClick={this.close}>{ this.props.strings.app.Cancel }</Button>&nbsp;
								<Button id="dialog-activate-activate-button" variant="primary" onClick={this.activate}>{ this.props.strings.app.Activate }</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
				</LoadingOverlay>
			</Modal>
		);
	}
}

export default ActivateDialog;
