import React, { Component } from 'react';

import './WZFooter.css';

class WzFooter extends Component
{
    initialState = {
	};
	
    render()
    {
		const strings = this.props.strings;

		return (
			<div id="footer" className="row">
				<div className="col-lg-12"  style={{margin:'0 auto',maxWidth:'1140px'}}>
					<div className="pull-left">
						{strings.nav.copyrightNotice}
						<a id="footer-privacy-link" href="http://www.wowza.com/privacy" target="_blank" rel="noopener noreferrer">{strings.nav.privacyPolicy}</a>
						<a id="footer-legal-link" href="http://www.wowza.com/legal" target="_blank" rel="noopener noreferrer">{strings.nav.legalNotice}</a>
						<a id="footer-status-link" href="http://status.wowza.com" target="_blank" rel="noopener noreferrer">{strings.nav.systemStatus}</a>
					</div>
					<div className="pull-right">
						<span id="footer-remoteadmin-version" className="text-muted">{ process.env.REACT_APP_VERSION }</span> <span id="footer-remoteadmin-revision">{ process.env.REACT_APP_GIT_REVISION}</span>
					</div>
					<div className="clearfix" />
				</div>
			</div>
		);
	}
};

export default WzFooter;
