import React, { Component } from 'react';
import { Card, CardDeck } from 'react-bootstrap';
import { healthStatusClasses } from '../../../model/BroadcastUIAdapter';
import { isVersionGreaterThanOrEqual } from "../../../controller/ClearCasterAdminUtils";
import HelpTooltip from '../../shell/HelpTooltip';

class BroadcastItemMonitorDestinations extends Component
{
	showModelInfo(model, softwareVersion)
	{
		if (model === "UNKNOWN")
		{
			if (softwareVersion == null || isVersionGreaterThanOrEqual(softwareVersion,"2.2.0.0"))
			{
				return false;
			}
		}
		return true;
	}
	renderWithHelps(healthStatus,className,label,helpYellow,helpRed)
	{
		return (
			<>
				{healthStatus === 0 &&
					<span><i className={className}></i> {label}</span>
				}
				{healthStatus === 1 &&
				<span>
					<HelpTooltip trigger={<i className={className+" mr-1"}></i>}
						placement="left"
					>{helpYellow}</HelpTooltip>
					{label}
					</span>
				}
				{healthStatus === 2 &&
				<span>
					<HelpTooltip trigger={<i className={className+" mr-1"}></i>}
						placement="left"
					>{helpRed}</HelpTooltip>
					{label}
				</span>
				}
			</>
		);
	}
	render()
	{
		let broadcastUI = this.props.broadcastUI;
		let encoder = this.props.encoder;

		let healthStatuses = broadcastUI.healthStatuses;

		let inputClass = Math.max(healthStatuses.video,healthStatuses.audio);

		return (
			<CardDeck className="w-100 col-sm-12">
				<Card bg="transparent" border="0">
					<Card.Header className="pl-0 pb-1 border-0 bg-white">
						{this.props.strings.app.MonitoringCardTitleInput}&nbsp;
						{inputClass > 0 &&
							<i className={healthStatusClasses[inputClass]}></i>
						}
					</Card.Header>
					<Card.Body className="bg-light">
						<strong>{this.props.strings.app.ClearCaster}: </strong>
						{ encoder != null &&
						<span>{encoder.name}</span>
						}
						<br />
						{ encoder != null && encoder.modelInfo != null && this.showModelInfo(encoder.modelInfo.model,encoder.clearCasterSoftwareVersion) &&
							<span>
								<strong>{this.props.strings.app.EncoderModel}: </strong>
								{this.props.strings.app["EncoderModel_"+encoder.modelInfo.model]}
								<br />
							</span>
						}
						<strong>{this.props.strings.app.MonitoringIOInput}: </strong>
						{healthStatuses.video > 0 &&
							<span><i className={healthStatusClasses[healthStatuses.video]}></i> {this.props.strings.app.VideoSourceNotDetected}</span>
						}
						{broadcastUI.videoSourceInfo}
						<br />
						<strong>{this.props.strings.app.MonitoringIOAudioChannels}: </strong>
						{healthStatuses.audio > 0 ?
							<span><i className={healthStatusClasses[healthStatuses.audio]}></i> {this.props.strings.app.AudioNotDetected}</span>
						:
							<span>{broadcastUI.inputAudioInfo}</span>
						}
					</Card.Body>
				</Card>
				<Card bg="transparent" border="0">
					<Card.Header className="pl-0 pb-1 border-0 bg-white">
						{this.props.strings.app.MonitoringCardTitleOutput}
					</Card.Header>
					<Card.Body className="bg-light">
						<strong>{this.props.strings.app.MonitoringIOEncodingTemplate}:</strong> {broadcastUI.templateName}<br />
						{broadcastUI.outputUIs.map((n,key)=>{ return (
							<div key={key}>
								<strong>{this.props.strings.app.MonitoringIOOutput}:</strong> {n.frameInfo}<br />
								<strong>{this.props.strings.app.MonitoringIOCodec}:</strong> {n.codecInfo}
							</div>
						)})}
					</Card.Body>
				</Card>
				<Card bg="transparent" border="0">
					<Card.Header className="pl-0 pb-1 border-0 bg-white">
						{this.props.strings.app.MonitoringCardTitleHealth}
					</Card.Header>
					<Card.Body className="bg-light">
						<div className="container m-0 p-0">
							<div className="row pb-2">
								<div className="col-12">
									<i className={healthStatusClasses[healthStatuses.overall]}></i> <strong>{this.props.strings.app.MonitoringHealthOverallBroadcast}</strong>
								</div>
							</div>
							<div className="row">
								<div className="col-5 text-truncate">
									{this.renderWithHelps(healthStatuses.video,
										healthStatusClasses[healthStatuses.video],
										this.props.strings.app.MonitoringHealthVideo,
										this.props.strings.app.MonitoringHelpHealthVideoRed,
										this.props.strings.app.MonitoringHelpHealthVideoRed
									)}
								</div>
								<div className="col-7 text-truncate">
									{this.renderWithHelps(healthStatuses.destinations,
										healthStatusClasses[healthStatuses.destinations],
										this.props.strings.app.MonitoringHealthDestinations,
										this.props.strings.app.MonitoringHelpHealthDestinationsYellow,
										this.props.strings.app.MonitoringHelpHealthDestinationsRed
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-5 text-truncate">
									{this.renderWithHelps(healthStatuses.audio,
										healthStatusClasses[healthStatuses.audio],
										this.props.strings.app.MonitoringHealthAudio,
										this.props.strings.app.MonitoringHelpHealthAudioYellow,
										this.props.strings.app.MonitoringHelpHealthAudioRed
									)}
								</div>
								<div className="col-7 text-truncate">
									{this.renderWithHelps(healthStatuses.encoding,
										healthStatusClasses[healthStatuses.encoding],
										this.props.strings.app.MonitoringHealthEncoding,
										this.props.strings.app.MonitoringHelpHealthEncodingRed,
										this.props.strings.app.MonitoringHelpHealthEncodingRed
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-5 text-truncate">
									{this.renderWithHelps(healthStatuses.network,
										healthStatusClasses[healthStatuses.network],
										this.props.strings.app.MonitoringHealthNetwork,
										this.props.strings.app.MonitoringHelpHealthNetworkYellow,
										this.props.strings.app.MonitoringHelpHealthNetworkRed
									)}
								</div>
								<div className="col-7 text-truncate">
									{this.renderWithHelps(healthStatuses.appliance,
										healthStatusClasses[healthStatuses.appliance],
										this.props.strings.app.MonitoringHealthAppliance,
										this.props.strings.app.MonitoringHelpHealthApplianceYellow,
										this.props.strings.app.MonitoringHelpHealthApplianceRed
									)}
								</div>
							</div>
						</div>
					</Card.Body>
				</Card>
			</CardDeck>
		);
	}
}
export default BroadcastItemMonitorDestinations;