import React, { Component } from 'react';

import BroadcastUIAdapter from '../../../model/BroadcastUIAdapter';
import Moment from 'react-moment';

class FacebookSharedBroadcastMonitor extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      eventId:'-1',
      eventTime:undefined,
      previousIoSessionScheduledWriteBytesToZero:0,
      broadcastUI:undefined,
      bytesWaiting:undefined,
      cpuTemp:-1,
      cpuLoad:-1,
      captureCardTemp:-1,
      pingTime:-1
    }
  }
  componentDidUpdate()
  {
    let { strings, broadcast, clearcaster } = this.props;
    let { eventId, previousIoSessionScheduledWriteBytesToZero } = this.state;
    let broadcastUI = null;
    let encoder = undefined;
    let latestNRT = undefined;
    let cpuTemp = undefined;
    let cpuLoad = undefined;
    let captureCardTemp = undefined;
    let pingTime = undefined;
    let ioSessionScheduledWriteBytesToZero = undefined;
    let bytesWaiting = undefined;

    if (broadcast == null) {
      return;
    }

    broadcastUI = new BroadcastUIAdapter(broadcast,null,clearcaster.encoderMap,strings);
    if (broadcastUI.broadcastEncoders.length > 0)
    {
      encoder = broadcastUI.broadcastEncoders[0];
    }
    if (encoder !== null && encoder['nearRealTimeData'])
    {
      latestNRT = encoder['nearRealTimeData'];
    }
    if (!latestNRT || latestNRT.eventId === eventId) {
      return;
    }

    if (latestNRT['hardwareDynamic'])
    {
      if (latestNRT['hardwareDynamic']['cpuTemperature'])
      {
        cpuTemp = latestNRT['hardwareDynamic']['cpuTemperature'];
      }
      else if (latestNRT['hardwareDynamic']['cpuTempurature'])
      {
        cpuTemp = latestNRT['hardwareDynamic']['cpuTempurature'];
      }
      cpuLoad = (latestNRT['hardwareDynamic'].cpuLoadSystem*100).toFixed(0);
    }

    if (latestNRT['encoder'] && latestNRT['encoder']['captureSessionInfo'])
    {
      if (latestNRT['encoder']['captureSessionInfo']['temperature'] != null)
      {
        captureCardTemp = latestNRT['encoder']['captureSessionInfo']['temperature'];
      }
    }

    let streamTarget = undefined;
    if (broadcastUI.destinations != null && broadcastUI.destinations.outputs != null && broadcastUI.destinations.outputs.length > 0 && broadcastUI.destinations.outputs[0].streamTargets != null && broadcastUI.destinations.outputs[0].streamTargets[0] != null)
    {
      streamTarget = broadcastUI.destinations.outputs[0].streamTargets[0];
      if (streamTarget.nearRealTimeData != null)
      {
        pingTime = streamTarget.nearRealTimeData.pingTime;
        ioSessionScheduledWriteBytesToZero = streamTarget.nearRealTimeData.ioSessionScheduledWriteBytesToZero;
      }
    }

    if (broadcastUI.broadcastEncoders != null && broadcastUI.broadcastEncoders.length > 0)
    {
      if (ioSessionScheduledWriteBytesToZero != null && eventId !== '-1')
      {
        bytesWaiting = ioSessionScheduledWriteBytesToZero - previousIoSessionScheduledWriteBytesToZero;
        if (isNaN(bytesWaiting))
        {
          bytesWaiting = '';
        }
      }
    }

    this.setState({
      eventId:latestNRT.eventId,
      eventTime:latestNRT.eventTime,
      previousIoSessionScheduledWriteBytesToZero:ioSessionScheduledWriteBytesToZero,
      broadcastUI:broadcastUI,
      bytesWaiting:bytesWaiting,
      cpuTemp:cpuTemp,
      cpuLoad:cpuLoad,
      captureCardTemp:captureCardTemp,
      pingTime:pingTime
    });
  }

  render()
  {
    let { strings } = this.props;
    let {
      eventTime,
      broadcastUI,
      bytesWaiting,
      cpuTemp,
      cpuLoad,
      captureCardTemp,
      pingTime
    } = this.state;

    if (!broadcastUI) return null;

    return (
      <>
        <div className="form-label">Monitor</div>
        { eventTime != null &&
          <div className="row">
            <div className="col-4">Updated:</div>
            <div className="col-8"><Moment format="YYYY-MM-DD h:mm:ssa" date={eventTime} /></div>
          </div>
        }
        <div className="row">
          <div className="col-4">{strings.app.MonitoringIOInput}:</div>
          <div className="col-8">{broadcastUI.videoSourceInfo}</div>
        </div>
        {broadcastUI.outputUIs.map((n,key)=>{ return (
          <div key={key}>
            <div className="row">
              <div className="col-4">{strings.app.MonitoringIOOutput}:</div>
              <div className="col-8">{n.frameInfo}</div>
            </div>
            <div className="row">
              <div className="col-4">{strings.app.MonitoringIOCodec}:</div>
              <div className="col-8">{n.codecInfo}</div>
            </div>
          </div>
        )})}
        <div className="row">
          <div className="col-4">Bitrate Target:</div>
          <div className="col-8">{(broadcastUI.destinations.totalTargetBitrate/1024/1024).toFixed(2)} Mbps</div>
        </div>
        <div className="row">
          <div className="col-4">Bitrate Available:</div>
          <div className="col-8">{(broadcastUI.destinations.totalAvailableBitrate/1024/1024).toFixed(2)} Mbps</div>
        </div>
        <div className="row">
          <div className="col-4">Bitrate Actual:</div>
          <div className="col-8">{(broadcastUI.destinations.totalActualBitrate/1024/1024).toFixed(2)} Mbps</div>
        </div>
        { pingTime != null &&
          <div className="row">
            <div className="col-4">Ping Time:</div>
            <div className="col-8">{pingTime} ms</div>
          </div>
        }
        { bytesWaiting != null &&
          <div className="row">
            <div className="col-4">Bytes Waiting:</div>
            <div className="col-8">{bytesWaiting}</div>
          </div>
        }
        { cpuTemp != null &&
          <div className="row">
            <div className="col-4">CPU Temp:</div>
            <div className="col-8">{cpuTemp} &deg;C</div>
          </div>
        }
        { cpuLoad != null &&
          <div className="row">
            <div className="col-4">CPU Load:</div>
            <div className="col-8">{cpuLoad} %</div>
          </div>
        }
        { captureCardTemp != null && captureCardTemp > 0 &&
          <div className="row">
            <div className="col-4">Capture Card Temp:</div>
            <div className="col-8">{captureCardTemp} %</div>
          </div>
        }
      </>
    );
  }

}
export default FacebookSharedBroadcastMonitor;