import React, { Component } from 'react';

import { Button, Table } from 'react-bootstrap'
import { compareObjectValues } from '../../../controller/ClearCasterAdminUtils';
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

import ClearCasterAccessKeyConfirmDeleteDialog from './ClearCasterAccessKeyConfirmDeleteDialog';
import ClearCasterAccessKeyCreateDialog from './ClearCasterAccessKeyCreateDialog';

class ManageIntegrationsAccessKeys extends Component
{
	constructor() {

		super();

		this.addAPIKey = this.addAPIKey.bind(this);
		this.renderTableBody = this.renderTableBody.bind(this);
		this.deleteKey = this.deleteKey.bind(this);
	}

	componentDidMount()
	{
		if (this.props.clearcaster.userWowza.namespaceId !== null)
		{
			this.props.controller.getNamespaceAccessKeys();
		}
	}

	async componentDidUpdate(prevProps)
	{
		// get access keys, but make sure we have a namespaceId first
		if (this.props.clearcaster.userWowza.namespaceId !== null &&
			!this.props.clearcaster.accessKeysLoading)
		{
			let now = new Date();
			if (this.props.clearcaster.accessKeysUpdateTime === null ||
				(now.getTime() - this.props.clearcaster.accessKeysUpdateTime.getTime()) > this.props.clearcaster.accessKeysUpdateInterval)
			{
				await this.props.controller.getNamespaceAccessKeys();
			}
		}
	}
	addAPIKey()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_CCACCESSKEYCREATE_DIALOG
		});
	}

	deleteKey(keyId,name)
	{
		return function (evt)
		{
			console.log('deleteKey:' + keyId);
			ClearCasterStore.dispatch({
				type: ActionTypes.SHOW_CCACCESSKEYCONFIRMDELETE_DIALOG,
				ccAccessKeyInfo: { id: keyId, name: name }
			});
		}
	}

	renderTableBody()
	{
		// let elements;
		//
		// for(let i in this.props.clearcaster.namespaceProviderKeys)
		// {
		// 	let providerKey = this.props.clearcaster.namespaceProviderKeys[i];
		//
		// 	elements = [elements, <tr key={ providerKey.id }><td>{ providerKey.name }</td><td>{ providerKey.accessKey.substr(0, 6)+"..." } </td><td style={{textAlign:"center"}}><i id={ 'delete-'+providerKey.id } onClick={ this.deleteRow } className='integration-icon-button fa fa-trash integration-icon'></i></td></tr>];
		// }
		//
		// return <tbody>{ elements }</tbody>;
	}

	render()
	{
		let accessKeys = [];

		if (this.props.clearcaster.accessKeys.length > 0)
		{
			accessKeys = this.props.clearcaster.accessKeys.sort(compareObjectValues('name', 'asc'));
		}

		return (<div>
			<ClearCasterAccessKeyConfirmDeleteDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
			<ClearCasterAccessKeyCreateDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />

			<div className="integrations-header">
				<img src="/images/integrations-wcc.png" className="integration-header-image" alt="Wowza ClearCaster Integrations" />
			</div>
			<div className="integrations-add">
				<Button variant="primary" onClick={ this.addAPIKey }>
					<i className="fa fa-plus-circle"></i> {this.props.strings.app.AddCCAccessKey}
				</Button>
			</div>
			<div className="integrations-table">
				<Table striped bordered>
					<thead>
						<tr>
							<th>Name</th>
							<th>Access Key</th>
							{process.env.REACT_APP_ENABLE_MANAGE_INTEGRATIONS_DELETE_KEYS === "true" &&
								<th style={{textAlign: "center"}}>Delete</th>
							}
						</tr>
					</thead>
					<tbody>
						{accessKeys.map((kobj,key) => {return (
							<tr key={key}>
								<td>
									{kobj.name.length > 0 ? kobj.name : <i>Unnamed</i>}
								</td>
								<td>
									{kobj.id.substr(0, 6)+"..."}
								</td>
								{process.env.REACT_APP_ENABLE_MANAGE_INTEGRATIONS_DELETE_KEYS === "true" &&
									<td style={{textAlign:"center"}}>
										<i onClick={ this.deleteKey(kobj.id,kobj.name) } className='integration-icon-button fa fa-trash integration-icon'></i>
									</td>
								}
							</tr>
						);})}
					</tbody>
					{ this.renderTableBody() }
				</Table>
			</div>
		</div>);
	}
}

export default ManageIntegrationsAccessKeys;
