module.exports = {
	"name": "General 4kp30",
	"id": "v2-general-4kp30",
	"type": "full",
	"presentation": {
		"layout": "SingleOutput",
		"widgets": [
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					}
				}
			},
			{
				"widget": "VideoProfileH264",
				"parameters": {
					"profile": {
						"variable": "$videoProfile",
						"defaultValue": "main",
						"label": "Video Profile"
					}
				}
			},
			{
				"widget": "Bitrate",
				"parameters": {
					"bitrate": {
						"variable": "$videoBitrate",
						"defaultValue": "25000k",
						"minValue": "4000k",
						"minWarning": "Video bitrate is less than minumum of 4000 Kbps",
						"maxValue": "40000k",
						"maxWarning": "Video bitrate is greater than maximum of 40000 Kbps",
						"units": "BITRATE_KBPS",
						"label": "Video Bitrate",
						"required": true
					}
				}
			},
			{
				"widget": "Bitrate",
				"parameters": {
					"bitrate": {
						"variable": "$audioBitrate",
						"defaultValue": "128k",
						"minValue": "50k",
						"minWarning": "Audio bitrate is less than minumum of 50 Kbps",
						"maxValue": "600k",
						"maxWarning": "Audio bitrate is greater than maximum of 600 Kbps",
						"units": "BITRATE_KBPS",
						"label": "Audio Bitrate",
						"required": true
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "CheckboxSimple",
				"label": "Record Broadcast",
				"parameters": {
					"value": {
						"variable": "$record",
						"defaultValue": false,
						"required": true
					}
				}
			}
		]
	},
	"template": {
		"input": {
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": 3840,
			"videoFrameHeightMax": 2160,
			"videoFrameRateMax": 30,
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition0",
				"record": "$record",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl",
						"streamName": "$streamTargetStreamName",
						"username": "$streamTargetUsername",
						"password": "$streamTargetPassword"
					}
				],
				"encodingConfiguration": {
					"name": "rendition0",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "QuickSync",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 3840,
						"frameSizeHeight": 2160,
						"profile": "$videoProfile",
						"bitrate": "$videoBitrate",
						"bitrateMin": "4m",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "quicksync.TargetUsage",
								"value": "4",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "$audioBitrate",
						"parameters": []
					}
				}
			}
		]
	}
};