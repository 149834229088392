module.exports = {
	"name": "Facebook Paired 1080p60 VR",
	"id": "facebookpaired-1080p60-vr",
	"type": "full",
	"isFacebookPaired": true,
	"presentation": {
		"layout": "SingleOutput",
		"widgets": [
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					}
				}
			},
			{
				"widget": "CheckboxSimple",
				"label": "Primary ingest stream",
				"parameters": {
					"value": {
						"variable": "$isPrimary",
						"defaultValue": true,
						"required": true
					}
				}
			},
			{
				"widget": "CheckboxSimple",
				"label": "Optimize Path to Facebook Live",
				"parameters": {
					"value": {
						"variable": "$doSpeedTest",
						"defaultValue": true,
						"required": true
					}
				}
			},
			{
				"widget": "CheckboxSimple",
				"label": "This live stream is a 360 video",
				"parameters": {
					"value": {
						"variable": "$isSpherical",
						"defaultValue": true,
						"required": true
					}
				}
			},
			{
				"widget": "CheckboxSimple",
				"label": "This live stream is single fisheye",
				"parameters": {
					"value": {
						"variable": "$isFishEye",
						"defaultValue": false,
						"required": true
					}
				}
			},
			{
				"widget": "TextEnterInt",
				"parameters": {
					"value": {
						"variable": "$fov",
						"defaultValue": 180,
						"minValue": 0,
						"minWarning": "Field of View is less than minimum of 0",
						"maxValue": 360,
						"maxWarning": "Field of View is greater than maximum of 360",
						"units": "INTEGER",
						"label": "Field of View (FOV)"
					}
				}
			},
			{
				"widget": "SelectCustom",
				"label": "Count Down Timer Steps",
				"options": {
					"3": {
						"numberOfSteps": 3
					},
					"5": {
						"numberOfSteps": 5
					},
					"15": {
						"numberOfSteps": 15
					}
				},
				"defaultValue": 0,
				"parameters": {
					"numberOfSteps": {
						"variable": "$numberOfSteps",
						"units": "INTEGER"
					}
				}
			},
			{
				"widget": "CheckboxSimple",
				"label": "Record Broadcast",
				"parameters": {
					"value": {
						"variable": "$record",
						"defaultValue": false,
						"required": true
					}
				}
			},
		]
	},
	"template": {
		"input": {
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": 1920,
			"videoFrameHeightMax": 1080,
			"videoFrameRateMax": 60,
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition0",
				"record": "$record",
				"streamTargets": [{
					"tag": "output0_streamTarget0",
					"type": "FB_PAIRED_STREAM_TARGET",
					"protocol": "RTMP",
					"streamTargetEncoderIndex": 0,
					"url": ""
				}],
				"encodingConfiguration": {
					"name": "rendition0",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "x264",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1920,
						"frameSizeHeight": 1080,
						"profile": "high",
						"bitrate": "14m",
						"bitrateMin": "1m",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "x264.preset",
								"value": "2",
								"type": "Long"
							},
							{
								"name": "x264.ref",
								"value": "1",
								"type": "Long"
							},
							{
								"name": "x264.bframes",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "128k"
					}
				}
			}
		],
		"extraProperties": [
			{
				"name": "facebook.isSpherical",
				"value": "$isSpherical",
				"type": "String"
			},
			{
				"name": "facebook.isFishEye",
				"value": "$isFishEye",
				"type": "String"
			},
			{
				"name": "facebook.fov",
				"value": "$fov",
				"type": "String"
			},
			{
				"name": "facebook.doSpeedTest",
				"value": "$doSpeedTest",
				"type": "String"
			},
			{
				"name": "facebook.isPrimary",
				"value": "$isPrimary",
				"type": "String"
			},
			{
				"name": "CountdownTimer.NumberOfSteps",
				"value": "$numberOfSteps",
				"type": "Int"
			},
			{
				"name": "CountdownTimer.TimePerStep",
				"value": "1000",
				"type": "Int"
			}
		]
	}
};
