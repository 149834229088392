import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from "react-router-dom";

class SupportView extends Component
{	
	render()
	{
		if(this.props.clearcaster.broadcastSharing.active){
			window.history.replaceState(null, null, "?k="+this.props.clearcaster.broadcastSharing.accessKey);
		}
		return (
			<div className="Support">
				<h1 style={{marginBottom:"1em"}}>Wowza ClearCaster Help</h1>
				<div className="row">
					<div className="col-sm-7">

						<div className="d-flex mb-4">
							<div>
								<i className="fa fa-file-text-o fa-3x fa-fw mr-3" aria-hidden="true"></i>
							</div>
							<div>
								<a id="support-setup-video-link" href="https://www.wowza.com/docs/wowza-clearcaster-user-guide-register-device" target="_blank" rel="noopener noreferrer"><strong>Setting Up Wowza ClearCaster</strong></a><br />
								Just 5 steps and less than 3 minutes to set up.<br />
							</div>
						</div>

						<div className="d-flex mb-4">
							<div>
								<i className="fa fa-file-text-o fa-3x fa-fw mr-3" aria-hidden="true"></i>
							</div>
							<div>
								<a id="support-getstarted-video-link" href="https://www.wowza.com/docs/wowza-encoder-graphql-api-overview" target="_blank" rel="noopener noreferrer"><strong>Getting started with the ClearCaster GraphQL API</strong></a><br />
								Learn how to use the Wowza ClearCaster with the GraphQL API to manage your appliance, ecnoding configuration, and broadcast workflow.<br />
							</div>
						</div>

						<div className="d-flex mb-4">
							<div>
								<i className="fa fa-file-text-o fa-3x fa-fw mr-3" aria-hidden="true"></i>
							</div>
							<div>
								<a id="support-quicksetup-link" href="https://www.wowza.com/docs/wowza-clearcaster-quick-setup" target="_blank" rel="noopener noreferrer"><strong>Wowza ClearCaster Quick Setup Guide</strong></a><br />
								Quick setup instructions to install Wowza ClearCaster and stream to Facebook Live.<br />
							</div>
						</div>

						<div className="d-flex mb-4">
							<div>
								<i className="fa fa-file-text-o fa-3x fa-fw mr-3" aria-hidden="true"></i>
							</div>
							<div>
								<a id="support-userguide-link" href="https://www.wowza.com/docs/wowza-clearcaster-user-guide" target="_blank" rel="noopener noreferrer"><strong>Wowza ClearCaster User’s Guide</strong></a><br />
								Everything you need to know about the Wowza ClearCaster appliance.<br />
							</div>
						</div>

						<div className="d-flex mb-4">
							<div>
								<i className="fa fa-usb fa-3x fa-fw mr-3" aria-hidden="true"></i>
							</div>
							<div>
								<a id="support-userguide-link" href="https://www.wowza.com/_private/clearcaster/usb-network/yeti.php" target="_blank" rel="noopener noreferrer"><strong>Wowza ClearCaster Network Configuration Tool</strong></a><br />
								Configure Wowza ClearCaster to use a static IP address using a USB flash drive.<br />
							</div>
						</div>

						<div className="d-flex mb-4">
							<div>
								<i className="fa fa-file-text-o fa-3x fa-fw mr-3" aria-hidden="true"></i>
							</div>
							<div>
								<Link id="support-opensourcedeclaration-link" to={"/support/opensourcedeclaration"}>
									<strong>Open Source Declaration</strong>
								</Link>
							</div>
						</div>

					</div>
					<div className="col-sm-5">
						<Card>
							<Card.Header>
								Other Helpful Resources
							</Card.Header>
							<Card.Body>
							<p>
								<a id="support-community-link" href="https://www.wowza.com/community/spaces/51/index.html" target="_blank" rel="noopener noreferrer"><strong>Join the ClearCaster discussion</strong></a><br />
								Participate in the Wowza ClearCaster forums.
							</p>
							<p>
								<strong>Wowza Support</strong><br />
								<a id="support-support-link" href="https://wowza.com/support" target="_blank" rel="noopener noreferrer">wowza.com/support</a>
							</p>
							</Card.Body>
						</Card>
					</div>
				</div>
			</div>
		);
	}
}

export default SupportView;
