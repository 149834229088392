import React, { Component } from 'react';

import { Form, Button, Modal, Alert} from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

class WSCAccessKeyCreateDialog extends Component
{
	static getDefaultState()
	{
		return {
			name:"",
			accessKey:"",
			apiKey:"",
			alert:undefined,
		};
	}

	constructor(props) {
		
		super(props);

		this.state = WSCAccessKeyCreateDialog.getDefaultState();

		this.handleAdd = this.handleAdd.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.onEnter = this.onEnter.bind(this);
	}

	onShow()
	{

	}

	onEnter()
	{
		this.setState(WSCAccessKeyCreateDialog.getDefaultState());
	}

	handleClose()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_WSCACCESSKEYCREATE_DIALOG,
		});
	}

	handleAdd()
	{
		let appStrings = this.props.strings;

		let missingFields;
		let missingCount = 0;

		if (this.state.name === undefined || this.state.name.length <= 0)
		{
			let keyStr = appStrings.app.WSCAccessKeyName;

			if (missingFields !== undefined)
				missingFields = [missingFields, (<span key={ keyStr+" dim" }>, </span>)];

			missingFields = [missingFields, (<strong key={ keyStr }>{ keyStr }</strong>)];
			missingCount++;
		}

		if (this.state.accessKey === undefined || this.state.accessKey.length <= 0)
		{
			let keyStr = appStrings.app.WSCAccessKeyAccessKey;

			if (missingFields !== undefined)
				missingFields = [missingFields, (<span key={ keyStr+" dim" }>, </span>)];

			missingFields = [missingFields, (<strong key={ keyStr }>{ keyStr }</strong>)];
			missingCount++;
		}

		if (this.state.apiKey === undefined || this.state.apiKey.length <= 0)
		{
			let keyStr = appStrings.app.WSCAccessKeyAPIKey;

			if (missingFields !== undefined)
				missingFields = [missingFields, (<span key={ keyStr+" dim" }>, </span>)];

			missingFields = [missingFields, (<strong key={ keyStr }>{ keyStr }</strong>)];
			missingCount++;
		}

		if (missingCount > 0)
		{
			let msg = missingCount>1?appStrings.app.MISSINGFIELDS:appStrings.app.MISSINGFIELD;
			let alert = {
				body: <span key={ "msg" }>{ msg }:&nbsp;<span key={ "body" }>{ missingFields }</span></span>,
			}

			this.setState({alert: alert});

			return;
		}

		let _this = this;

		//oxQ6xgK8vj4lDQrkdismyiIwjMyECkrlMm6SeXTTvL4qa21Sb0Q5mw2A9uUX354e
		//7LVzVgNriS8hOgr7r2QqGceKiDhL8Aml1zfNLUUq9LzV697QeFuHHHiXQYGz3348

		let input = {
			name: this.state.name,
			accessKey: this.state.accessKey,
			secretKey: this.state.apiKey,
			type: "WSC_PUBLIC_API"
		};

		this.props.controller.mutationCreateProviderKey(input).then((resp) => {
			console.log(JSON.stringify(resp));
			_this.props.controller.forcePolling();
		});

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_WSCACCESSKEYCREATE_DIALOG,
		});
	}

	render()
    {
		let appStrings = this.props.strings;

		return (
			<Modal className="integrations-add-wsc-dialog" show={ this.props.clearcaster.wscAccessKeyCreateDialogShowing } onEnter={ this.onEnter } onShow={ this.onShow } onHide={this.handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.app.AddWSCAccessKey }</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{ this.state.alert !== undefined &&
						<Alert dismissible variant="danger">
						{ this.state.alert.heading !== undefined &&
							<Alert.Heading>{ this.state.alert.heading }</Alert.Heading>
						}
						{ this.state.alert.body }
						</Alert>
					}
					<Form>
						<Form.Group controlId="name">
							<Form.Label>{ appStrings.app.WSCAccessKeyName }&nbsp;*</Form.Label>
							<Form.Control value={ this.state.name } onChange={evt => this.setState({ name: evt.target.value })} />
						</Form.Group>
						<Form.Group controlId="accessKey">
							<Form.Label>{ appStrings.app.WSCAccessKeyAccessKey }&nbsp;*</Form.Label>
							<Form.Control value={ this.state.accessKey } onChange={evt => this.setState({ accessKey: evt.target.value })} />
						</Form.Group>
						<Form.Group controlId="apiKey">
							<Form.Label>{ appStrings.app.WSCAccessKeyAPIKey }&nbsp;*</Form.Label>
							<Form.Control value={ this.state.apiKey } onChange={evt => this.setState({ apiKey: evt.target.value })} />
						</Form.Group>
					</Form>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
					<Button variant="primary" onClick={this.handleAdd}>{ appStrings.app.AddWSCAccessKey }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WSCAccessKeyCreateDialog;
