import React, { PureComponent } from 'react';

class SubscriptionAutoRenewBanner extends PureComponent
{
	render()
	{
		let { strings, userIsSubscriptionUnderwater, userSubscriptionDaysUntilUnderwater, userSubscriptionDaysUntilAutoRenew } = this.props;
		let portalPaymentsLink = window.config.REACT_APP_PORTAL_PAYMENTS;

		if ( userIsSubscriptionUnderwater )
		{
			return null;
		}
		else if (userSubscriptionDaysUntilUnderwater > 0 && userSubscriptionDaysUntilUnderwater < 29)
		{
			return null;
		}
		else if ( userSubscriptionDaysUntilAutoRenew > 0 && userSubscriptionDaysUntilAutoRenew < 29 )
		{
			return (
				<div className="alert alert-info">
					<strong>
						{strings.app.SubscriptionBannerSubscriptionWillAutoRenew({days:Math.round(userSubscriptionDaysUntilAutoRenew)})} &nbsp;
						<a href={portalPaymentsLink} target="_blank"  rel="noopener noreferrer">{strings.app.SubscriptionBannerClickToReviewPayment}</a>
					</strong>
				</div>
			)
		}
		return null;
	}
}

export default SubscriptionAutoRenewBanner;
