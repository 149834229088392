import React, { Component } from 'react';

import { Nav, Dropdown } from 'react-bootstrap'
import { getBroadcastsByDateCreated } from '../../controller/ClearCasterAdminUtils';
import { NavLink } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import GetSharedBroadcastPassphrase from '../dialogs/GetSharedBroadcastPassphrase';
import BroadcastItemMonitor from './monitor/BroadcastItemMonitor';
import BroadcastUIAdapter, { getBroadcastTemplateInfo } from '../../model/BroadcastUIAdapter.js';

class MonitorView extends Component
{
	onSelectTab()
	{

	}

	render()
	{

		if (this.props.clearcaster.broadcastSharing.active)
		{
			window.history.replaceState(null, null, "?k="+this.props.clearcaster.broadcastSharing.accessKey);
			if(this.props.clearcaster.broadcastSharing.showGetPassphraseDialog)
				return (
					<GetSharedBroadcastPassphrase strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				);
		}

		if (!this.props.controller.userIsSubscriptionCurrent())
		{
			return (<div>Monitor not available.</div>);
		}
		// LIVE
		let broadcasts = getBroadcastsByDateCreated(this.props.clearcaster.broadcastMap);
		let liveBroadcasts = [];
		let broadcast = null;
		let broadcastUI = null;


		if (broadcasts.length > 0)
		{
			for (let i = 0; i < broadcasts.length; i++)
			{
				if (broadcasts[i].status === 'LIVE')
				{
					liveBroadcasts.push(broadcasts[i]);
				}
				else if (this.props.clearcaster.broadcastSharing.active &&
					broadcasts[i].status === 'PREVIEW' &&
					getBroadcastTemplateInfo(broadcasts[i])['isFacebookPaired'] === true)
				{
					liveBroadcasts.push(broadcasts[i]);
				}
			}
		}

		let broadcastKey = null;
		if (this.props.broadcastId !== null)
		{
			broadcastKey = this.props.broadcastId;
			broadcast = this.props.clearcaster.broadcastMap[broadcastKey];
		}

		let dropdownBroadcastUIs = [];
		if (liveBroadcasts.length > 0)
		{
			if (broadcast == null || broadcast.status !== 'LIVE')
			{
				broadcastKey = liveBroadcasts[0].id;
				broadcast = this.props.clearcaster.broadcastMap[broadcastKey];
			}
			for (let i = 0; i < liveBroadcasts.length; i++)
			{
				dropdownBroadcastUIs.push( new BroadcastUIAdapter(liveBroadcasts[i],null,this.props.clearcaster.encoderMap,this.props.strings));
			}
		}
		if (broadcast != null)
		{
			broadcastUI = new BroadcastUIAdapter(broadcast,null,this.props.clearcaster.encoderMap,this.props.strings);
		}

		return (
			<div className="text-left">
				{this.props.clearcaster.broadcastSharing.active !== true &&
				<div className="row mb-4">
					<Nav variant="tabs" activeKey={this.props.archive} onSelect={ this.onSelectTab } className="w-100">
						<Nav.Item className="ml-5">
							<Nav.Link as={NavLink} to={'/monitor/live'} eventKey={'live'}>
								<span><strong>{this.props.strings.nav.MonitoringLiveBroadcasts}</strong></span>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={NavLink} to={'/monitor/archive'} eventKey={'archive'}>
								<span><strong>{this.props.strings.nav.MonitoringArchivedBroadcasts}</strong></span>
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</div>
				}

				{liveBroadcasts.length === 0 && (
					<div className="row mb-3">
						<div className="col-sm-12">
							{this.props.strings.app.NoLiveBroadcasts}
						</div>
					</div>
				)}
				{liveBroadcasts.length > 0 && (

				<div className="row mb-3">
					<div className="col-sm-12">

						<h3>{this.props.strings.app.Broadcast}
							<span className="d-inline-block ml-3">
								<Dropdown>
									<Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
										{broadcastUI == null ?
											(<span className="mr-3">Choose Broadcast</span>)
											:
											(<span className="mr-3">{broadcastUI.name}<span className="mr-3"/>{broadcastUI.statusBadge}</span>)
										}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										{dropdownBroadcastUIs.map((b, key) =>
										{
											return (
												<Dropdown.Item as={LinkContainer} key={key} to={'/monitor/live/' + b.id}>
													<span>{b.name}<span className="mr-3"/>{b.statusBadge}</span>
												</Dropdown.Item>
											);
										})}
									</Dropdown.Menu>
								</Dropdown>
							</span>
						</h3>

					</div>
				</div>
				)}
				{broadcast != null &&
				<BroadcastItemMonitor broadcast={broadcast}
															broadcastLiveDataMap={this.props.clearcaster.broadcastLiveDataMap}
															encoderMap={this.props.clearcaster.encoderMap}
															clearcaster={this.props.clearcaster}
															controller={this.props.controller}
															strings={this.props.strings} />
				}

			</div>
		);
	}
}

export default MonitorView;
