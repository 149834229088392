import { execute, split, HttpLink, toPromise } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from "@apollo/client/link/error";
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Hex from 'crypto-js/enc-hex';

import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import {redirectToLogin} from './UniversalAdminUtils';
import Log from '../log/Log';

class YetiGraphQLConnection
{
  constructor(graphQLCredentials)
	{
		this.log = Log;
    this.setGraphQLCredentials(graphQLCredentials);
    this.initializeConnection();
	}

  setGraphQLCredentials(graphQLCredentials) {
    if (graphQLCredentials !== undefined)
		{
      this.graphQLCredentials = graphQLCredentials;
      this.key = graphQLCredentials.key; //process.env.REACT_APP_ACCESSKEY_ID;
			this.secret = graphQLCredentials.secret; //process.env.REACT_APP_ACCESSKEY_SECRET;
			this.url = graphQLCredentials.url; //process.env.REACT_APP_GRAPHQL_URL;
		} else {
			this.url = window.config.REACT_APP_GRAPHQL_URL;
		}

    let url = new URL(this.url);
		this.domain = url.hostname;
		if (url.port)
			this.domain = this.domain+":"+url.port;
  }

  initializeConnection() {

    let wsparams = {
      uri: "wss://"+this.domain+"/subscriptions",
      options:{
        connectionParams: this.getAuthHeader()
      }
    }

    let wsLink = new WebSocketLink(wsparams);
    let httpLink = new HttpLink({ uri: this.url });
    
    let logLink = onError(({networkError}) => this.onHttpError(networkError));

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      logLink.concat(httpLink),
    );

    this.link = splitLink;

  }

  getAuthHeader()
	{
		let headers;

		if (this.domain !== undefined && this.secret !== undefined && this.key !== undefined)
		{
			let requestTime = Date.now();
			let hmacDigest = Hex.stringify(hmacSHA256(this.domain, hmacSHA256(requestTime.toString(), this.secret)));
			let authorizationHeader =`HMAC-SHA256, Credential=${this.key}, SignedHeaders=host;x-date, Signature=${hmacDigest}`;

			headers = {
				'Authorization': authorizationHeader,
				'X-Date': requestTime
			}
		}
		else
		{
			let state = ClearCasterStore.getState();
			let authorizationHeader =`JWT, JWTType=wowza_encrypted, JWT=${state.userWowza.jwt}`;
			headers = {
				'Authorization': authorizationHeader
			};
		}

		return headers;
	}

  makeRequest(operation)
	{
		if (this.graphQLCredentials === undefined || (this.graphQLCredentials.key && this.graphQLCredentials.secret))
		{
      operation.context = {...operation.context, headers: this.getAuthHeader()}
      return toPromise(execute(this.link, operation));
		}
		else
		{
			this.log.info('Insufficient credentials to make graphql request');
		}
	}

  /*
      WS subscriptions can't use Promises because they resolve before subsequent events are received from the connection
      Apollo uses the zen-obserable library to impliment Observers. This method will add an observer to the subscription if one is provided
      or return the Obervable so that the calling function may implimnent the Observerble within its own scope.

      operation: GraphQL subscription query
      observer(optional): Observer object {next: [function], error: [function], complete: [function]}
  */
  subscribe(subscription,observer) {
    if(observer === undefined){
      return execute(this.link, subscription);
    }else{
      execute(this.link, subscription).subscribe(observer);
    }
  }

  onHttpError(networkError) {

    if (networkError && networkError.statusCode === 401)
    {
      this.log.error('Network error connecting to GraphQL');

      let state = ClearCasterStore.getState();
      if (state.broadcastSharing.active)
      {
        let graphQLCredentials = state.graphQLCredentials;
        graphQLCredentials['secret'] = '';
        let errorMsg = 'There was an error retrieving this broadcast.';
        if (networkError['result'] != null && networkError['result']['error'] != null)
        {
          if (networkError.result.error.toLowerCase().indexOf('access expired') >= 0)
          {
            errorMsg = 'Broadcast Sharing URL Expired';
          }
        }
        console.log(networkError.result.error);
        ClearCasterStore.dispatch({
          type:ActionTypes.SET_BROADCAST_SHARING,
          error:errorMsg,
          showGetPassphraseDialog:true,
          tryingPassphrase:false,
          graphQLCredentials:graphQLCredentials
        });
      }
      else
      {
        redirectToLogin();
      }
    }
  }
}

export default YetiGraphQLConnection;
