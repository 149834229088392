import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import NearRealTimeDataCache from '../../../model/NearRealTimeDataCache';

class BroadcastItemMonitorConnectionAttemptsChart extends Component
{
	static getDataSetTemplate(targetIndex,targetName,variant)
	{
		const colors = ['#63C53D','#7DC8FE','#FFBC2A','#FF8400','#0081BC','#61388F'];
		// 50% tint values per https://maketintsandshades.com
		const colorsV2 = ['#B1E29E','#BEE4FF','#FFDE95','#FFC280','#80C0DE','#B09CC7'];
		let color;
		if (variant === 1)
		{
			color = colorsV2[targetIndex%colors.length];
		}
		else
		{
			color = colors[targetIndex%colors.length];
		}
		let dataSet = {
			label: targetName,
			fill: false,
			lineTension: 0.1,
			backgroundColor: color,
			borderColor: color,
			borderCapStyle: 'butt',
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			borderWidth: 2,
			pointBorderColor: color,
			pointBackgroundColor: color,
			pointBorderWidth: 1,
			pointHoverRadius: 2,
			pointHoverBackgroundColor: color,
			pointHoverBorderColor: color,
			pointHoverBorderWidth: 2,
			pointRadius: 0.5,
			pointHitRadius: 10,
			data:[]
		};
		return dataSet;
	}
	render()
	{
		let dataSets = [];
		let broadcastData = NearRealTimeDataCache.networkChartAdapter(this.props.broadcastUI.broadcastData);

		for (let streamTargetIndex = 0; streamTargetIndex < broadcastData.streamTargets.length; streamTargetIndex++)
		{
			let streamTargetName = broadcastData.streamTargets[streamTargetIndex].length > 0 ?
				broadcastData.streamTargets[streamTargetIndex][0].url + ' (' + streamTargetIndex + ')':
				'';
			let streamTarget = broadcastData.streamTargets[streamTargetIndex];
			let dataSetConnectionAttempts = BroadcastItemMonitorConnectionAttemptsChart.getDataSetTemplate(streamTargetIndex,streamTargetName,0);
			let dataConnectionAttempts = [];
			for (let dataIndex = 0; dataIndex < streamTarget.length; dataIndex++)
			{
				let dataPoint = streamTarget[dataIndex];
				dataConnectionAttempts.push({x:dataPoint.eventTime,y:dataPoint.pushpubConnectAttemptCount});
			}
			// dataSetPing['data'] = dataPing;
			dataSetConnectionAttempts['data'] = dataConnectionAttempts;
			//dataSets.push(dataSetPing);
			dataSets.push(dataSetConnectionAttempts);
		}
		let now = new Date();
		let windowStartTime = new Date(now.getTime() - (this.props.clearcaster.broadcastLiveDataWindowSizeMinutes * 60000));

		return (
			<Card bg="transparent" border="0">
				<Card.Header className="pl-0 pb-1 border-0 bg-white">
					{this.props.strings.app.MonitoringCardTitleConnectionAttempts}
				</Card.Header>
				<Card.Body className="bg-white p-0">
					<div>
						<Line
							height={200}
							options={{
								maintainAspectRatio:false,
								animation:{
									duration:0
								},
								layout:{
									padding:{
										left:0,
										top:0,
										right:0,
										bottom:0
									}
								},
								scales: {
									xAxes: [{
										type: 'time',
										position: 'bottom',
										time:{
											min:windowStartTime,
											tooltipFormat:'h:mm:ss a'
										},
										ticks:{
											minRotation:0,
											maxRotation:0
										}
									}],
									yAxes: [{
										type: 'linear',
										ticks:{
											min:0,
											suggestedMax:10
										}
									}]
								}
							}}
							legend={{
								display: false
							}}
							data={{
								datasets: dataSets
							}}
						/>
					</div>
					<div className="row bg-light m-1 p-1">
						{dataSets.map((d,key) => { return (
							<div key={key} className="col-lg-6 chart-legend-label">
								<div className="chart-legend-box" style={{
									borderStyle: (d.borderDash.length > 0 ? "dashed" : "solid"),
									borderTopColor: d.borderColor
								}}></div>
								{d.label}
							</div>
						);
						})}
					</div>
				</Card.Body>
			</Card>
		);
	}
}
export default BroadcastItemMonitorConnectionAttemptsChart;