import React, { Component } from 'react';

import { Button, Form } from 'react-bootstrap'

import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/eclipse';

import {
	TEMPLATECOUNTS_TEMPLATES,
	getCustomTemplatePackageV2,
	isTemplateObjReadOnly,
	isTemplateObjManageOnly,
	getTemplateCategoryListV2,
	getTemplateListV2,
	getDefaultTemplateForCategoryV2,
	deflateStr,
	getRootTemplateName,
	getTemplateCategoriesV2
} from '../../../templates/TemplateUtils.js';

import {
	getCustomTemplateCountsV2,
	hashFnv32a,
	testTemplatePackage} from "../../../templates/TemplateUtils";

import CustomTemplateConfirmDeleteDialog from "./CustomTemplateConfirmDeleteDialog";
import ManageDestinationsDialog from "./ManageDestinationsDialog";
import ManageDestinationPickerDialog from "./ManageDestinationPickerDialog";
import ManageTemplatesDialog from "./ManageTemplatesDialog";

import ClearCasterStore, {ActionTypes} from "../../../model/ClearCasterStore";
import LoadingOverlay from 'react-loading-overlay';

const jsonMap = require('json-source-map');

const uuidv1 = require('uuid/v1');

class ManageTemplates extends Component
{
	static getDefaultState()
	{
		return {
			templateInfo:undefined,
			customTemplateCounts:[0,0,0,0],
			templateText:"",
			errorCount:0,
			errorMap:{},
			templateSet: "pro",
			templateCategory: "v2-general-single-target",
			template: undefined,
			templateName: undefined,
			isEdit: false,
			editInfo:undefined,
			isReadOnlyTemplate: false,
			saving: false
		};
	}

	constructor() {

		super();

		this.state = ManageTemplates.getDefaultState();

		this.templateCategory = React.createRef();
		this.template = React.createRef();
		this.templateEditor = React.createRef();
		this.templateSet = React.createRef();

		this.templateCategoryChange = this.templateCategoryChange.bind(this);
		this.templateSetChange = this.templateSetChange.bind(this);
		this.templateChange = this.templateChange.bind(this);

		this.handleTemplateEdit = this.handleTemplateEdit.bind(this);
		this.handleTemplateEditACopy = this.handleTemplateEditACopy.bind(this);
		this.handleTemplateDelete = this.handleTemplateDelete.bind(this);
		this.handleTemplateEditCancel = this.handleTemplateEditCancel.bind(this);
		this.handleTemplateEditSave = this.handleTemplateEditSave.bind(this);
		this.handleManageCategories = this.handleManageCategories.bind(this);
		this.handleManageTemplates = this.handleManageTemplates.bind(this);
		this.onChangeValue = this.onChangeValue.bind(this);
		this.onValidate = this.onValidate.bind(this);
		this.navigateToError = this.navigateToError.bind(this);
		this.doDelete = this.doDelete.bind(this);
		this.doSave = this.doSave.bind(this);
		this.createFakeCategories = this.createFakeCategories.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onDeleteCancel = this.onDeleteCancel.bind(this);
		this.setState = this.setState.bind(this);
		this.doTemplateEditACopy = this.doTemplateEditACopy.bind(this);
		this.cancelTemplateEditACopy = this.cancelTemplateEditACopy.bind(this);
		this.doDeleteTemplate = this.doDeleteTemplate.bind(this);
		this.fixTemplateSelection = this.fixTemplateSelection.bind(this);
		this.doDeleteTemplateCategory = this.doDeleteTemplateCategory.bind(this);
		this.fixTemplateCategorySelection = this.fixTemplateCategorySelection.bind(this);

		this.startupInterval = undefined;
	}

	setState(newState)
	{
		super.setState(newState);
	}

	onChangeValue(newText, evt)
	{
		if (this.state.isEdit)
		{
			this.setState({ templateText: newText })
		}
	}

	onValidate()
	{
		if (this.templateEditor.current && this.state.isEdit)
		{
			let errorList = [];
			let errorMap = {};
			let errorMapAll = {};
			let myMap = undefined;
			let templateName = undefined;

			try
			{
				myMap = jsonMap.parse(this.state.templateText.trim());
				if (myMap !== undefined && myMap.pointers !== undefined && myMap.data !== undefined)
				{
					let fakeTemplatePackage = {
						templateCategories: {
							"mycat":{
								name: "mycat",
								defaultTemplate: "mytmp",
								templates: ["mytmp"]
							}
						},
						templates: {}
					}

					fakeTemplatePackage.templates["mytmp"] = myMap.data;

					if (myMap.data.name !== undefined)
					{
						templateName = myMap.data.name;
					}

					let results = testTemplatePackage(fakeTemplatePackage);
					if (results !== undefined && results.errorList !== undefined)
					{
						errorList = results.errorList;
						for(let i in errorList)
						{
							errorMap[errorList[i].id] = errorList[i];
							errorMapAll[errorList[i].id] =  errorList[i];
						}
					}
				}
			}
			catch(error)
			{
				console.log("onValidate: ERROR: "+error.message);
			}

			let currAnnotations = this.templateEditor.current.editor.session.getAnnotations();

			for(let i in currAnnotations)
			{
				let annotation = currAnnotations[i];
				if (annotation.id !== undefined)
				{
					delete errorMap[annotation.id];
				}
				else
				{
					let annotationNew = JSON.parse(JSON.stringify(annotation));

					annotationNew.id = hashFnv32a("parser:"+annotationNew.line+":"+annotationNew.text+":"+annotationNew.type, true)

					annotationNew.line = annotationNew.row!==undefined?parseInt(annotationNew.row):0;

					errorMapAll[annotationNew.id] =  annotationNew;
				}
			}

			let didAdd = false;
			for(let i in errorMap)
			{
				let errorItem = errorMap[i];

				let path = errorItem.path;

				let prefix = "/templates/mytmp";

				if (path.startsWith(prefix))
					path = path.substr(prefix.length);

				let line = 0;
				if (myMap !== undefined && myMap.pointers[path] !== undefined)
				{
					let mapObj = myMap.pointers[path];

					if (mapObj.key !== undefined && mapObj.key.line !== undefined)
						line = mapObj.key.line;
					else if (mapObj.value !== undefined && mapObj.value.line !== undefined)
						line = mapObj.value.line;
				}
				else
					console.log("WARNING: Can't find JSON path: "+errorItem.path);

				errorItem.line = line;

				let newAnnotation =
					{
						id: errorItem.id,
						row: line,
						column: 0,
						text: errorItem.text,
						type: errorItem.type
					};

				didAdd = true;
				currAnnotations.push(newAnnotation);
			}

			if (didAdd)
			{
				this.templateEditor.current.editor.session.setAnnotations(currAnnotations);
			}

			let newState = { errorCount:currAnnotations.length, errorMap: errorMapAll };

			if (templateName !== undefined)
			{
				newState.editInfo = this.state.editInfo;
				newState.editInfo.templateName = templateName;
			}

			this.setState(newState);
		}
	}

	navigateToError(evt)
	{
		let key = evt.target.id.split("-")[1];

		this.templateEditor.current.editor.moveCursorTo(this.state.errorMap[key].line, 0);
		this.templateEditor.current.editor.centerSelection();
	}

	handleTemplateEdit()
	{
		let templateInfo = this.state.templateInfo;
		let editInfo = {};

		let templateCategoryId = this.state.templateCategory;
		let templateCategoryName = undefined;
		let templateCategories = getTemplateCategoriesV2(templateInfo, this.state.templateSet);

		if (templateCategories.hasOwnProperty(templateCategoryId))
		{
			templateCategoryName = templateCategories[templateCategoryId].name;
		}

		let templateText = this.state.templateText;

		editInfo.templateCategory = this.state.templateCategory;
		editInfo.templateCategoryName = templateCategoryName;
		editInfo.template = this.state.template;
		editInfo.templateName = this.state.templateName;
		editInfo.isNew = false;
		editInfo.isAddCategory = false;

		this.setState( {isEdit: true, editInfo: editInfo, templateText: templateText} );
	}

	handleTemplateEditACopy()
	{
		let contextObj = {
			context: this,
			onPick: this.doTemplateEditACopy,
			onCancel: this.cancelTemplateEditACopy
		}

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_PICKDESTINATION_DIALOG,
			context: contextObj
		});

	}

	cancelTemplateEditACopy(context)
	{

	}

	doTemplateEditACopy(context, templateCategoryId)
	{
		let editInfo = {};

		if (templateCategoryId !== undefined)
		{
			editInfo.isAddCategory = true;
			editInfo.templateCategory = undefined;
			editInfo.templateCategoryName = "Custom";

			let templateInfo = this.state.templateInfo;
			let templateCategories = getTemplateCategoriesV2(templateInfo, this.state.templateSet);

			if (templateCategories !== undefined)
			{
				if (templateCategories.hasOwnProperty(templateCategoryId))
				{
					let templateCategory = templateCategories[templateCategoryId];

					let isReadyOnly = isTemplateObjReadOnly(templateCategory);
					if (isReadyOnly)
					{
						for(let tci in templateCategories)
						{
							templateCategory = templateCategories[tci];
							let isReadyOnly = (templateCategory.isReadOnly !== undefined && templateCategory.isReadOnly)?true:false;
							if (!isReadyOnly)
							{
								editInfo.templateCategory = tci;
								editInfo.templateCategoryName = templateCategory.name;
								editInfo.isAddCategory = false;
								break;
							}
						}
					}
					else
					{
						editInfo.templateCategory = templateCategoryId;
						editInfo.templateCategoryName = templateCategory.name;
						editInfo.isAddCategory = false;
					}
				}
			}

			let templateText = this.state.templateText;

			editInfo.template = this.state.template;
			editInfo.templateName = this.state.templateName+" copy";
			editInfo.isNew = true;

			try
			{
				let templateObj = JSON.parse(templateText);
				if (templateObj)
				{
					templateObj.name = editInfo.templateName;
					templateText = JSON.stringify(templateObj, null, 2);
				}
			}
			catch(error)
			{

			}

			this.setState( {isEdit: true, editInfo: editInfo, templateText: templateText} );
		}
	}

	fixTemplateCategorySelection()
	{
		let templateInfo = this.state.templateInfo;

		let templateCategoryId = this.state.templateCategory;

		let templateCategories = getTemplateCategoriesV2(templateInfo, this.state.templateSet);

		if (!templateCategories.hasOwnProperty(templateCategoryId))
		{
			let newCategoryId = templateInfo.templateCategoryOrder[0];

			this.setState({templateCategory: newCategoryId});
		}
	}

	doDeleteTemplateCategory(templateCategoryId)
	{
		let templateInfo = this.state.templateInfo;
		let templateCategories = getTemplateCategoriesV2(templateInfo, this.state.templateSet);

		if (templateCategories.hasOwnProperty(templateCategoryId))
		{
			let templateCategory = templateCategories[templateCategoryId];

			for(let i in templateCategory.templates)
			{
				let templateId = templateCategory.templates[i];
				delete templateCategory.templates[templateId];
			}

			delete templateCategories[templateCategoryId];

			var index = templateInfo.templateCategoryOrder.indexOf(templateCategoryId);
			if (index !== -1)
				templateInfo.templateCategoryOrder.splice(index, 1);

			this.doSave(templateInfo);
		}
	}

	doDeleteTemplate(templateCategoryId, templateId)
	{
		let templateInfo = this.state.templateInfo;

		let templateSelectId = undefined;

		if (templateInfo.templates.hasOwnProperty(templateId))
		{
			delete templateInfo.templates[templateId];

			let templateCategories = getTemplateCategoriesV2(templateInfo, this.state.templateSet);

			if (templateCategories.hasOwnProperty(templateCategoryId))
			{
				let templateCategory = templateCategories[templateCategoryId];

				var index = templateCategory.templates.indexOf(templateId);
				if (index !== -1)
					templateCategory.templates.splice(index, 1);
				else
					console.log("ERROR: ManageTemplates.handleTemplateDelete: Template not found in template category list: "+templateId);

				if (templateCategory.defaultTemplate === templateId)
				{
					templateCategory.defaultTemplate = undefined;

					if (templateCategory.templates.length > 0)
					{
						templateCategory.defaultTemplate = templateCategory.templates[0];
					}
				}

				templateSelectId = templateCategory.defaultTemplate;
			}
			else
				console.log("ERROR: ManageTemplates.handleTemplateDelete: Template category is missing: "+templateCategoryId);

			this.doSave(templateInfo);
		}

		return templateSelectId;
	}

	fixTemplateSelection(templateSelectId)
	{
		let templateInfo = this.state.templateInfo;

		let newState = {
			template: templateSelectId
		};

		this.getTemplateJSON(templateInfo, templateSelectId, newState);

		this.setState(newState);

	}

	doDelete()
	{
		let templateCategoryId = this.state.templateCategory;
		let templateId = this.state.template;
		let templateSelectId = this.doDeleteTemplate(templateCategoryId, templateId);

		this.fixTemplateSelection(templateSelectId);
	}

	handleTemplateEditCancel()
	{
		this.setState( {isEdit: false} );
	}

	createFakeCategories()
	{
		let templateInfo = this.state.templateInfo;

		for(let i=0;i<20;i++)
		{
			let categoryName = "Custom "+(i+1);

			let templateCategoryId = uuidv1();
			let templateCategory = {
				id: templateCategoryId,
				name: categoryName,
				defaultTemplate: undefined,
				templates: []
			};

			templateInfo.templateCategoryOrder.push(templateCategoryId);

			let templateCategories = getTemplateCategoriesV2(templateInfo, this.state.templateSet);

			templateCategories[templateCategoryId] = templateCategory;
		}

		this.doSave(templateInfo);
	}

	handleTemplateEditSave()
	{
		let templateInfo = this.state.templateInfo;
		let editInfo = this.state.editInfo;

		let templateJSON = undefined;

		try
		{
			templateJSON = JSON.parse(this.state.templateText);
		}
		catch(error)
		{
			console.log("ERROR: ManageTemplates.handleTemplateEditSave: Error converting template to JSON: "+error.message);
		}

		//editInfo.templateText = this.state.templateText;
		//editInfo.templateCategory = this.state.templateCategory;
		//editInfo.template = this.state.template;
		//editInfo.templateName = this.state.templateName;
		//editInfo.isNew = false;
		//editInfo.isAddCategory = false;

		if (templateJSON !== undefined)
		{
			let templateCategory = undefined;
			let templateCategoryId = undefined;
			let templateCategories = getTemplateCategoriesV2(templateInfo, this.state.templateSet);

			if (editInfo.isAddCategory)
			{
				templateCategoryId = uuidv1();
				templateCategory = {
					id: templateCategoryId,
					name: "Custom",
					defaultTemplate: undefined,
					templates: []
				};

				templateInfo.templateCategoryOrder.push(templateCategoryId);
				templateCategories[templateCategoryId] = templateCategory;
			}
			else
			{
				templateCategoryId = editInfo.templateCategory;
				if (templateCategories.hasOwnProperty(templateCategoryId))
					templateCategory = templateCategories[templateCategoryId];
			}

			if (templateCategory !== undefined)
			{
				let templateId = editInfo.template;

				if (editInfo.isNew)
				{
					templateId = uuidv1();
					templateCategory.templates.push(templateId);
				}

				templateJSON.id = templateId;

				templateInfo.templates[templateId] = templateJSON;

				if (templateCategory.defaultTemplate === undefined)
					templateCategory.defaultTemplate = templateId;

				this.doSave(templateInfo);

				let newState = {
					isEdit: false,
					templateInfo: templateInfo,
					templateCategory: templateCategoryId,
					template: templateId};

				this.getTemplateJSON(templateInfo, templateId, newState);

				this.setState(newState);
			}
			else
				console.log("ERROR: ManageTemplates.handleTemplateEditSave: Template category is missing: "+templateCategoryId);
		}

	}

	async doSave(templateInfo, overrideTemplatSet = undefined)
	{
		try
		{
			let templateSet = overrideTemplatSet!==undefined?overrideTemplatSet:this.state.templateSet;

			let customPackage = getCustomTemplatePackageV2(this.state.templateInfo, templateSet);

			//console.log("doSave\n"+JSON.stringify(customPackage, null, 2));

			let customPackageDeflated = deflateStr(JSON.stringify(customPackage));

			this.setState({saving:true});
			await this.props.controller.updateCustomTemplates(customPackage);
			await this.props.controller.persistCustomTemplates(customPackageDeflated);

			let templateInfo = JSON.parse(JSON.stringify(this.props.clearcaster.broadcastTemplates));

			let customTemplateCounts = getCustomTemplateCountsV2(templateInfo, templateSet);

			this.setState({saving:false, templateInfo:templateInfo, customTemplateCounts:customTemplateCounts});

		}
		catch(error)
		{
			console.log("ERROR: ManageTemplates.doSave: "+error.message);
		}
	}

	getTemplateJSON(templateInfo, templateId, newState)
	{
		if (templateInfo !== undefined)
		{
			let template = undefined;

			if (templateId !== undefined && templateInfo.templates.hasOwnProperty(templateId))
			{
				template = JSON.parse(JSON.stringify(templateInfo.templates[templateId]));
			}

			if (template !== undefined)
			{
				if (template.template !== undefined)
				{
					let newTemplate = JSON.parse(JSON.stringify(template));

					let showTemplate = {};
					showTemplate.name = newTemplate.name;
					showTemplate.type = newTemplate.type;
					if (newTemplate['isFacebookPaired']) {
						showTemplate.isFacebookPaired = newTemplate.isFacebookPaired;
					}
					showTemplate.presentation= newTemplate.presentation;
					showTemplate.template= newTemplate.template;

					newState.isReadOnlyTemplate = template.hasOwnProperty("isReadOnly")?template.isReadOnly:false;
					newState.templateName = showTemplate.name;
					newState.templateText = JSON.stringify(showTemplate, null, 2);
				}
				else
				{
					newState.isReadOnlyTemplate = false;
					newState.templateName = "New Template";
					newState.templateText = "";
				}
			}
			else
			{
				newState.isReadOnlyTemplate = false;
				newState.templateName = "No Template";
				newState.templateText = "";
			}
		}
	}

	getDefaultTemplate(templateInfo, newState)
	{
		let templateCategoryId = this.state.templateCategory;
		let templateSetId = this.state.templateSet;

		if (templateInfo !== undefined)
		{
			let defaultTemplate = getDefaultTemplateForCategoryV2(templateInfo, templateSetId, templateCategoryId);

			newState.template = defaultTemplate;

			this.getTemplateJSON(templateInfo, defaultTemplate, newState);
		}
	}

	componentDidMount()
	{
		// there is a timing issue with template loading that makes this required
		if (this.props.clearcaster.broadcastTemplates !== undefined)
		{
			let templateInfo = JSON.parse(JSON.stringify(this.props.clearcaster.broadcastTemplates));

			let customTemplateCounts = getCustomTemplateCountsV2(templateInfo, "pro");

			let newState = { templateInfo:  templateInfo, customTemplateCounts: customTemplateCounts};

			this.getDefaultTemplate(templateInfo, newState);

			this.setState(newState);

			if (this.templateEditor.current)
			{
				this.templateEditor.current.editor.moveCursorTo(0, 0);
				this.templateEditor.current.editor.centerSelection();
			}
		}
		else
		{
			let _this = this;

			this.startupInterval = setInterval(() => {

				if (this.props.clearcaster.broadcastTemplates !== undefined)
				{
					clearInterval(_this.startupInterval);

					let templateInfo = JSON.parse(JSON.stringify(_this.props.clearcaster.broadcastTemplates));

					let customTemplateCounts = getCustomTemplateCountsV2(templateInfo, "pro");

					let newState = { templateInfo:  templateInfo, customTemplateCounts: customTemplateCounts};

					_this.getDefaultTemplate(templateInfo, newState);

					_this.setState(newState);

					if (this.templateEditor.current)
					{
						this.templateEditor.current.editor.moveCursorTo(0, 0);
						this.templateEditor.current.editor.centerSelection();
					}
				}

			}, 100);
		}
	}

	templateSetChange(evt)
	{
		let templateSetId = evt.target.value;

		let templateInfo = this.state.templateInfo;

		let newTemplate = undefined;

		if (this.state.template !== undefined)
		{
			let currRootName = getRootTemplateName(this.state.template);

			let templateList = getTemplateListV2(templateInfo, templateSetId, this.state.templateCategory);
			for(let key in templateList)
			{
				let template = templateList[key];
				let newRootName = getRootTemplateName(template.id);

				if (newRootName.localeCompare(currRootName) === 0)
				{
					newTemplate = template.id;
					break;
				}
			}
		}

		if (newTemplate === undefined)
		{
			newTemplate = getDefaultTemplateForCategoryV2(templateInfo, templateSetId, this.state.templateCategory);
		}

		let newState = {
			templateSet: templateSetId,
			template: newTemplate
		};

		this.getTemplateJSON(templateInfo, newTemplate, newState);

		this.setState(newState);
	}

	templateCategoryChange(evt)
	{
		let templateCategoryName = evt.target.value;

		let templateInfo = this.state.templateInfo;
		let templateSetId = this.state.templateSet;

		let defaultTemplate = getDefaultTemplateForCategoryV2(templateInfo, templateSetId, templateCategoryName);

		let newState = {
			templateCategory: templateCategoryName,
			template: defaultTemplate
		};

		this.getTemplateJSON(templateInfo, defaultTemplate, newState);

		this.setState(newState);
	}

	templateChange(evt)
	{
		var templateId = evt.target.value;

		let templateInfo = this.state.templateInfo;

		let newState = {
			template: templateId
		};

		this.getTemplateJSON(templateInfo, templateId, newState);

		this.setState(newState);
	}

	onDelete(context)
	{
		console.log("===== onDelete");
		this.doDelete();
	}

	onDeleteCancel(context)
	{
		console.log("===== onDeleteCancel");
	}

	handleTemplateDelete()
	{
		let appStrings = this.props.strings;

		let contextObj = {
			titleStr: appStrings.app.DeleteCustomTemplate,
			bodyStr: appStrings.app.DeleteCustomTemplateConfirm,
			buttonStr: appStrings.app.DeleteCustomTemplate,
			context: this,
			onDelete: this.onDelete,
			onCancel: this.onDeleteCancel
		}

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_CUSTOMTEMPLATECONFIRMDELETE_DIALOG,
			context: contextObj
		});
	}

	handleManageCategories()
	{
		//this.createFakeCategories();

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_MANAGEDESTINATIONS_DIALOG,
		});
	}

	handleManageTemplates()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_MANAGETEMPLATES_DIALOG,
		});
	}

	render()
	{
		if (this.state.templateInfo === undefined)
			return <div></div>;

		let appStrings = this.props.strings;
		let templateInfo = this.state.templateInfo;

		let templateCategoryList = getTemplateCategoryListV2(templateInfo, this.state.templateSet);
		let templateList = getTemplateListV2(templateInfo, this.state.templateSet, this.state.templateCategory);

		let templateCategoryListExample = [];
		let templateCategoryListBuiltInGroups = [];
		let templateCategoryListCustom = [];
		for(let i in templateInfo.templateCategoryGroups)
		{
			let group = {...templateInfo.templateCategoryGroups[i],templateCategories:[]};
			templateCategoryListBuiltInGroups.push(group);
		}
		for(let i in templateCategoryList)
		{
			let templateCategory = templateCategoryList[i];

			if (isTemplateObjManageOnly(templateCategory))
				templateCategoryListExample.push(templateCategory);
			else if (isTemplateObjReadOnly(templateCategory))
			{
				for(let j = 0; j < templateInfo.templateCategoryGroups.length; j++ )
				{
					if (templateInfo.templateCategoryGroups[j].templateCategories.includes(templateCategory.id))
					{
						for(let k = 0; k < templateCategoryListBuiltInGroups.length; k++)
						{
							if(templateCategoryListBuiltInGroups[k].name === templateInfo.templateCategoryGroups[j].name)
							{
								templateCategoryListBuiltInGroups[k].templateCategories.push(templateCategory);
							}
						}
					}
				}
			}
			else
				templateCategoryListCustom.push(templateCategory);
		}

		let editDisabled = (this.state.isEdit || this.state.isReadOnlyTemplate || templateList.length <= 0);
		let editCopyDisabled = (this.state.isEdit || templateList.length <= 0);
		let deleteDisabled = (this.state.isEdit || this.state.isReadOnlyTemplate || templateList.length <= 0);
		let saveDisabled = (this.state.errorCount > 0);

		let manageDestinationDisabled = this.state.isEdit;
		let manageTemplatesDisabled = ( this.state.isEdit || this.state.customTemplateCounts[TEMPLATECOUNTS_TEMPLATES] <= 0 );

		// this is shitty but better than keeping it super small
		var maxLines = 30;
		if (window.innerHeight !== undefined)
		{
			if (window.innerHeight > 1200)
				maxLines = 50;
			else if (window.innerHeight > 1000)
				maxLines = 40;
		}

		//maxLines: Infinity
		let editorOptions = {
			readOnly: !this.state.isEdit,
			highlightActiveLine: this.state.isEdit,
			highlightGutterLine: this.state.isEdit,
			showLineNumbers: this.state.isEdit,
			showInvisibles: false,
			showGutter: this.state.isEdit,
			displayIndentGuides: this.state.isEdit,
			tabSize: 2,
			minLines: 30,
			maxLines: maxLines,
		};

		return (
			<div>
				<ManageDestinationsDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} parent={this} />
				<ManageDestinationPickerDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} parent={this} />
				<ManageTemplatesDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} parent={this} />
				<CustomTemplateConfirmDeleteDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} parent={this} />

				<LoadingOverlay active={ this.state.saving } spinner text={ this.props.strings.app.ManageGraphicsSaving } >
				<div className="row">
					<div className="col-sm-3">
						<div className="integration-templates-select-template">
							<div className="integration-heading">{ appStrings.app.SelectAnExistingTemplate }</div>
							<Form.Group controlId="encoderModel">
								<Form.Label>{ appStrings.app.EncodingTemplateModels }</Form.Label>
								<Form.Control ref={ this.templateSet } as="select" value={ this.state.templateSet } onChange={ this.templateSetChange }>
									<option key={ "enterprise" } value={ "enterprise" }>{ appStrings.app.EncoderModel_CC_ENTERPRISE }</option>
									<option key={ "micro" } value={ "micro" }>{ appStrings.app.EncoderModel_CC_MICRO }</option>
									<option key={ "pro" } value={ "pro" }>{ appStrings.app.EncoderModel_CC_PRO }</option>
								</Form.Control>
							</Form.Group>
							<Form.Group controlId="templateCategory">
								<Form.Label>{ appStrings.app.EncodingTemplateCategory }</Form.Label>
								{ this.state.isEdit ?
									<Form.Control disabled={ true } ref={ this.templateCategory } as="select" value={ this.state.templateCategory } onChange={ this.templateCategoryChange }>
										<option key={ this.state.editInfo.templateCategory } value={ this.state.editInfo.templateCategory }>{ this.state.editInfo.templateCategoryName }</option>
									</Form.Control> :
									<Form.Control ref={ this.templateCategory } as="select" value={ this.state.templateCategory } onChange={ this.templateCategoryChange }>
										{
											templateCategoryListCustom.length > 0 &&
											<optgroup label={ appStrings.app.CustomDestinations }>
												{ templateCategoryListCustom.map ((v, key) => { return (
													<option key={ v.id } value={ v.id }>{ v.name }</option>
												)})}
											</optgroup>
										}
										{
											templateCategoryListBuiltInGroups.map((builtInGroup,key) => { return(
												<optgroup key={key} label={builtInGroup.name}>
													{ builtInGroup.templateCategories.map ((category,key2) => { return (
														<option key={category.id} value={category.id}>{category.name}</option>
													)})}
												</optgroup>
											)})
										}
										{
											templateCategoryListExample.length > 0 &&
											<optgroup label={ appStrings.app.ExampleDestinations }>
												{ templateCategoryListExample.map ((v, key) => { return (
													<option key={ v.id } value={ v.id }>{ v.name }</option>
												)})}
											</optgroup>
										}
									</Form.Control>
								}
							</Form.Group>
							<Form.Group controlId="template">
								<Form.Label>{ appStrings.app.EncodingTemplate }</Form.Label>
								{ this.state.isEdit ?
									<Form.Control disabled={ true } ref={ this.templateCategory } as="select" value={ this.state.templateCategory } onChange={ this.templateCategoryChange }>
										<option key={ this.state.editInfo.templateName } value={ this.state.editInfo.templateName }>{ this.state.editInfo.templateName }</option>
									</Form.Control> :
									<div>
									{
										templateList.length <= 0 ?
											<div>&nbsp;<i>No Templates</i></div> :
											<Form.Control ref={ this.template } as="select" value={ this.state.template } onChange={ this.templateChange }>
												{ templateList.map ((v, key) => {

													return (
														<option key={ v.id } value={ v.id }>{ v.name }</option>
													)})}
											</Form.Control>
									}
									</div>
								}
							</Form.Group>
						</div>
						{
							//SelectAnExistingTemplate:"Select an Existing Template",
							//EditThisTemplate:"Edit This Template",
							//EditACopy:"Edit a Copy",
							//DeleteTemplate:"Delete Template",
							//SaveTemplate:"Save Template",
							//ManageCategoriesAndTemplates:"Manage Categories & Templates",
							//ManageCategories:"Manage Categories...",
							//ManageTemplates:"Manage Templates",

							//isEdit: false,
							//isReadOnlyTemplate: false


						}
						<div className="integration-templates-edit-buttons">
							<Button id="buttonTemplateEdit" className="integration-templates-button" disabled={ editDisabled } variant={ (editDisabled?"outline-secondary":(this.state.isReadOnlyTemplate?"secondary":"primary"))  } onClick={ this.handleTemplateEdit }><i className="fa fa-pencil"></i>&ensp;{ appStrings.app.EditThisTemplate }</Button>
							<Button id="buttonTemplateEditACopy" className="integration-templates-button" disabled={ editCopyDisabled } variant={ (editCopyDisabled?"outline-secondary":(this.state.isReadOnlyTemplate?"primary":"secondary")) } onClick={ this.handleTemplateEditACopy }><i className="fa fa-copy"></i>&ensp;{ appStrings.app.EditACopy }</Button>
							{
								this.state.isEdit ?
									<div>
										<Button id="buttonTemplateEditCancel" className="integration-templates-button" variant="secondary" onClick={ this.handleTemplateEditCancel }><i className="fa fa-ban"></i>&ensp;{ appStrings.app.Cancel }</Button>
										<Button id="buttonTemplateEditSave" className="integration-templates-button" disabled={ saveDisabled } variant={ saveDisabled?"outline-secondary":"primary" } onClick={ this.handleTemplateEditSave }><i className="fa fa-save"></i>&ensp;{ appStrings.app.SaveTemplate }</Button>
									</div> :
									<div>
										<Button id="buttonTemplateDelete" className="integration-templates-button" disabled={ deleteDisabled } variant={ (deleteDisabled?"outline-secondary":(this.state.isReadOnlyTemplate?"secondary":"primary"))  } onClick={ this.handleTemplateDelete }>{ appStrings.app.DeleteTemplate }</Button>
									</div>
							}
						</div>
						{
							true &&
							<div className="integration-templates-manage-categories">
								<div className="integration-heading">{ appStrings.app.ManageCategoriesAndTemplates }</div>
								<div className="integration-templates-manage-buttons">
									<Button id="buttonManageCategories" className="integration-templates-button" disabled={ manageDestinationDisabled } variant={ manageDestinationDisabled?"outline-secondary":"secondary" } onClick={ this.handleManageCategories }><i className="fa fa-folder"></i>&ensp;{ appStrings.app.ManageDestinations }&hellip;</Button>
									<Button id="buttonManageTemplates" className="integration-templates-button" disabled={ manageTemplatesDisabled } variant={ manageTemplatesDisabled?"outline-secondary":"secondary" } onClick={ this.handleManageTemplates }><i className="fa fa-file"></i>&ensp;{ appStrings.app.ManageTemplates }&hellip;</Button>
								</div>
							</div>
						}
					</div>
					<div className="col-sm-9">
						<div className="integration-heading">{ appStrings.app.TemplateJSON }</div>
						<AceEditor
							ref={ this.templateEditor }
							className="template-editor-ace"
							style={{width:"100%"}}
							mode="json"
							theme="eclipse"
							onChange={ this.onChangeValue }
							onValidate={ this.onValidate }
							fontSize={14}
							showPrintMargin={false}
							showGutter={true}
							highlightActiveLine={true}
							value={ this.state.templateText }
							editorProps={{
								$blockScrolling: Infinity,
							}}
							setOptions={ editorOptions } />
						{
							this.state.isEdit &&
							<div className="template-editor-error-container" style={{boxSizing: "border-box", width: "100%", height: "60px", overflow:"auto"}}>
								{
									this.state.errorCount <= 0?
										<div><i className="fa fa-check-circle fa-lg fa-fw icon-success" aria-hidden="true"></i>&nbsp;Template is error free!</div> :
										<div>
											{ Object.keys(this.state.errorMap).map ((key, index) => {
												let errorItem = this.state.errorMap[key];
												return <div key={ "error-"+errorItem.id } id={ "error-"+errorItem.id } className="template-editor-error-item" onClick={ this.navigateToError }><i className="fa fa-times-rectangle fa-lg fa-fw icon-error" aria-hidden="true"></i>&nbsp;{ errorItem.text }</div>
											})}
										</div>
								}
							</div>
						}
					</div>
				</div>
				</LoadingOverlay>
			</div>);
	}
}

export default ManageTemplates;
