import React, { Component } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import { PulseLoader } from 'react-spinners';

class GetSharedBroadcastPassphrase extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			passphrase:''
		};

		this.onPassphraseChange = this.onPassphraseChange.bind(this);
		this.applyPassphrase = this.applyPassphrase.bind(this);
	}

	onPassphraseChange(event)
	{
		this.setState({passphrase:event.target.value});
	}

	applyPassphrase(event)
	{
		let graphQLCredentials = this.props.clearcaster.graphQLCredentials || {};
		graphQLCredentials['secret'] = this.state.passphrase;
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_BROADCAST_SHARING,
			error:null,
			tryingPassphrase:true,
			graphQLCredentials:graphQLCredentials
		});
		if (event != null)
			event.preventDefault();
	}

	// close(event)
	// {
	// 	ClearCasterStore.dispatch({
	// 		type:ActionTypes.SET_BROADCAST_SHARING,
	// 		showGetPassphraseDialog:false
	// 	});
	// }

	// async applyUpdate(event)
	// {
	// 	ClearCasterStore.dispatch({
	// 		type:ActionTypes.SET_UPDATE_ENCODER,
	// 		dialogShowing:true,
	// 		updating:true
	// 	});
	// 	await this.props.controller.updateEncoder(this.props.clearcaster.updateEncoder.encoder.id);
	// 	ClearCasterStore.dispatch({
	// 		type:ActionTypes.SET_UPDATE_ENCODER,
	// 		dialogShowing:false,
	// 		updating:false
	// 	});
	// }

	render()
	{
		let canSubmit = this.state.passphrase !== '';

		return (
			<Modal show={this.props.clearcaster.broadcastSharing.showGetPassphraseDialog} onHide={(e)=>{return false;}}>
				<Form onSubmit={this.applyPassphrase}>
					<Modal.Header>
						<Modal.Title>{this.props.strings.app.PassphraseProtected}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.props.clearcaster.broadcastSharing.error !== undefined  &&
							<div className="alert alert-danger">
								{this.props.clearcaster.broadcastSharing.error}
							</div>
						}
						<Form.Group>
							<label style={{width:'100%'}} htmlFor="dialog-passphrase">
								{this.props.strings.app.PassphrasePrompt}
							</label>
							<input id="dialog-passphrase" className="form-control" type="text" value={this.state.passphrase} onChange={this.onPassphraseChange} />
						</Form.Group>
						{this.props.clearcaster.broadcastSharing.tryingPassphrase ?
							<Button className="pull-right mb-3" variant={'outline-secondary'} style={{width:'90px'}} disabled>
								<PulseLoader sizeUnit="px" size="5"/>
							</Button>
						:
							<Button className="pull-right mb-3" style={{width:'90px'}} id="dialog-updates-applyupdate-button" variant={'primary'} onClick={this.applyPassphrase} disabled={!canSubmit}>{ this.props.strings.app.PassphraseSubmit }</Button>
						}
					</Modal.Body>
				</Form>
			</Modal>
		);
	}
}

export default GetSharedBroadcastPassphrase;
