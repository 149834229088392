import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { ClipLoader } from 'react-spinners';
import { Button, Form } from 'react-bootstrap';
import ClearCasterStore, {ActionTypes} from '../../../model/ClearCasterStore';


class ManageUsers extends Component
{
	constructor(props)
	{
		super(props);
		this.hasOwnerPrivs = this.hasOwnerPrivs.bind(this);
		this.isAdmin = this.isAdmin.bind(this);
		this.toggleUserAdmin = this.toggleUserAdmin.bind(this);
		this.toggleLimitAccess = this.toggleLimitAccess.bind(this);
	}

	componentDidMount()
	{
		if (this.props.clearcaster.userWowza.namespaceId !== null && this.props.clearcaster.usersUpdateTime === null && this.props.clearcaster.usersLoading === false)
		{
			this.props.controller.getNamespaceUsers();
		}
	}

	componentDidUpdate()
	{
		if (this.props.clearcaster.userWowza.namespaceId !== null && this.props.clearcaster.usersUpdateTime === null && this.props.clearcaster.usersLoading === false)
		{
			this.props.controller.getNamespaceUsers();
		}
	}

	hasOwnerPrivs()
	{
		return (process.env.REACT_APP_ENABLE_ROLE_RESTRICTIONS === 'false' || this.props.clearcaster.userWowza.roles.indexOf('organization_owner') >= 0);
	}

	isAdmin()
	{
		return (process.env.REACT_APP_ENABLE_ROLE_RESTRICTIONS === 'false' || this.props.clearcaster.userWowza.admin);
	}

	toggleUserAdmin(email,namespaceUserId,roles,admin)
	{
		let _this = this;
		return () => {
			if (roles.includes('organization_owner') || email.indexOf(this.props.clearcaster.userWowza.email) === 0)
			{
				console.log('Cannot change admin privs for organization_owner or self');
				return;
			}
			if (!_this.props.clearcaster.userWowza.admin)
			{
				console.log('User is not an admin');
				return;
			}
			_this.props.controller.setNamespaceUserPrivs(namespaceUserId,{admin:!admin});
		}
	}

	toggleLimitAccess()
	{
		if (this.hasOwnerPrivs())
		{
			let manageSettings = this.props.clearcaster.manageSettings;
			manageSettings['limitAccess'] = !manageSettings['limitAccess'];
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_MANAGE_SETTINGS,
				manageSettings:manageSettings
			});
			this.props.controller.persistManageSettings(manageSettings);
		}
	}

	renderManageRow()
	{
		if (!this.hasOwnerPrivs() && !this.isAdmin())
			return;

		return (
			<div className="row align-items-center mt-3 mb-3">
				<div className="col-12">
				{this.hasOwnerPrivs() &&
					<div className="d-inline-block mr-4">
						<Button variant="primary" href="https://www.wowza.com/portal/account?tab=support">
							<i className="fa fa-external-link mr-2"></i>Manage Support Users
						</Button>
					</div>
				}
				{this.isAdmin() &&
					<div className="d-inline-block">
						<Form.Check checked={ this.props.clearcaster.manageSettings.limitAccess }
									type="checkbox"
									onChange={this.toggleLimitAccess}
									label="Limit Access To Admins Only" />
					</div>
				}
				</div>
			</div>
		);
	}

	render()
	{

		if ((this.props.clearcaster.users.length === 0 && this.props.clearcaster.usersLoading) || this.props.clearcaster.usersUpdateTime === null)
		{
			return (
				<>
					{ this.renderManageRow() }
					<div className="row mt-3">
						<div className="col-md-6">
							Loading...
						</div>
					</div>
				</>
			);
		}

		if (this.props.clearcaster.users.length === 0)
		{
			return (
				<>
					{ this.renderManageRow() }
					<div className="row mt-3">
						<div className="col-md-6">
							No users available.
						</div>
					</div>
				</>
			);
		}

		let columns = [
			{Header:this.props.strings.app.ManageUsersTableFirstName,
				accessor:'firstName',
				className:'d-flex flex-column justify-content-center'
			},
			{Header:this.props.strings.app.ManageUsersTableLastName,
				accessor:'lastName',
				className:'d-flex flex-column justify-content-center',
			},
			{Header:this.props.strings.app.ManageUsersTableEmail,
				accessor: "email",
				className:'d-flex flex-column justify-content-center',
			},
			{Header:this.props.strings.app.ManageUsersTableRoles,
				id: "roles",
				accessor: d => {return d.roles.join(", ");},
				className:'d-flex flex-column justify-content-center text-wrap',
				Cell: props => (
					<div
						style={{
							height: '100%',
						}}
					>
						{props.value}
					</div>
				)
			}
		];

		if (this.isAdmin())
		{
			columns.push(
				{Header:'Admin',
					accessor: "email",
					sortable:false,
					className:'d-flex flex-column justify-content-center',
					Cell: c => (
						<div>{
							this.props.clearcaster.usersUpdating.indexOf(c.original.id) >= 0 ?
								<ClipLoader sizeUnit="px" size="10"/>
							:
								<Form.Check checked={c.original.admin}
									disabled={c.original.id.indexOf(this.props.clearcaster.userWowza.id) === 0 || c.original.roles.indexOf('organization_owner') >= 0}
									onChange={ this.toggleUserAdmin(c.original.email,c.original.id,c.original.roles,c.original.admin) }
									type="checkbox"
									style={{height:'20px'}}
								/>
						}
						</div>
					)
				}
			);
		}

		return (
			<>
				{ this.renderManageRow() }
				<div className="row mt-3">
					<div className="col">
						<ReactTable data={this.props.clearcaster.users}
												columns={columns}
												pageSizeOptions={[ 20, 50, 100]}
												defaultPageSize={20}
						/>
					</div>
				</div>
			</>
		);
	}
}

export default ManageUsers;
