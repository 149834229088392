import React, { Component } from 'react';
import { withCookies } from 'react-cookie';


import { Button, Table } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes} from '../../../model/ClearCasterStore';

import LinkedInAccessTokenConfirmDeleteDialog from './LinkedInAccessTokenConfirmDeleteDialog';
import LinkedInAccessTokenCreateDialog from './LinkedInAccessTokenCreateDialog'

class ManageIntegrationsLinkedIn extends Component

{
	constructor(props) {

		super();
    this.linkedInOauth = this.linkedInOauth.bind(this);
  }

  linkedInOauth() {
    let base_url = window.config.LINKEDIN_AUTH_URL;
		let redirect_uri = window.config.LINKEDIN_REDIRECT_URL;
    let client_id = window.config.LINKEDIN_CID;
		let scope = "r_organization_live%20w_organization_live%20r_organization_admin%20r_member_live%20w_member_live%20r_liteprofile";

    // assign random alphanumeric string to state
    let state = Math.round((Math.pow(36, 10 + 1) - Math.random() * Math.pow(36, 10))).toString(36).slice(1);;
    // store a cookie for the redirect
    this.props.cookies.set("linkedin_state", state);

    window.location.assign(`${base_url}?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=${scope}`);
  }

  deleteRow(evt)
  {
    let accessTokenId = evt.target.id.split('-')[1];

    ClearCasterStore.dispatch({
      type: ActionTypes.SHOW_LINKEDINACCESSTOKENCONFIRMDELETE_DIALOG,
      linkedInAccessTokenInfo: {accessTokenId: accessTokenId}
    });
  }

  providerKeysLinkedIn()
  {
  	return this.props.clearcaster.namespaceProviderKeys.filter((key) => { return key.type === "LINKEDIN_ACCESS_TOKEN"})
  }

  renderTableBody()
	{
		let elements;
		for(const providerKey of this.providerKeysLinkedIn())
		{
			elements = [elements, <tr key={ providerKey.id }><td>{ providerKey.name }</td><td>{ providerKey.accessKey.substr(0, 6)+"..." } </td><td style={{textAlign:"center"}}><i id={ 'delete-'+providerKey.id } onClick={ this.deleteRow } className='integration-icon-button fa fa-trash integration-icon'></i></td></tr>];
		}

		return <tbody>{ elements }</tbody>;
	}

  render()
	{
		return (
      <div>
        <LinkedInAccessTokenConfirmDeleteDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
        <LinkedInAccessTokenCreateDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
        <div>
          <div className="integrations-add">
            <Button variant="primary" onClick={ this.linkedInOauth }>
              <i className="fa fa-plus-circle"></i> {this.props.strings.app.AddLinkedInAccessToken}
            </Button>
          </div>
        </div>
        <div className="integrations-table">
  				<Table striped bordered>
  					<thead>
  						<tr>
  							<th>Name</th>
  							<th>Access Token</th>
  							<th style={{textAlign:"center"}}>Delete</th>
  						</tr>
  					</thead>
  					{ this.renderTableBody() }
  				</Table>
  			</div>
      </div>
    );
	}
}

export default withCookies(ManageIntegrationsLinkedIn);
