import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import AudioInputLevelSlider from '../components/AudioInputLevelSlider';
import AudioChannelMapper from '../components/AudioChannelMapper';

class FacebookSharedBroadcastSettingsDialog extends Component
{
  onShow()
  {
  }

  handleClose(facebookData)
  {
		return (evt) => {
			let newFacebookData = {...facebookData,ui:{...facebookData.ui,showSettingsDialog:false}};
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData:newFacebookData
			});
		}
  }

  handleConfirm()
  {
    return (evt) => {
    }
  }

  render()
  {
    let { broadcast, controller, facebookData, strings } = this.props;
    let broadcastId = broadcast.id;
    let audioChannelLeft = -1;
    let audioChannelRight = -1;
    let audioLevel = 0;
    if (broadcast['input'])
    {
      audioChannelLeft = broadcast['input']['audioChannelLeft'];
      audioChannelRight = broadcast['input']['audioChannelRight'];
      audioLevel = broadcast['input']['audioLevel'];
    }

    return (
      <Modal show={ facebookData.ui.showSettingsDialog !== false } onHide={this.handleClose(facebookData)}>
        <Modal.Header closeButton>
          <Modal.Title>Broadcast Settings</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AudioInputLevelSlider
            broadcastId={broadcastId}
            controller={controller}
            value={ audioLevel }
            tentativeValue={ audioLevel }
            stepNumerator={ 1 }
            stepDenominator={ 2 }
            min={ -12 }
            max={ 12 }
          />
          <AudioChannelMapper 
            broadcastId={broadcastId}
            controller={controller}
            audioChannelLeft={audioChannelLeft}
            audioChannelRight={audioChannelRight}
            strings={strings} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={this.handleClose(facebookData)}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FacebookSharedBroadcastSettingsDialog;
