module.exports = {
	"name": "Checkbox Custom (demo toggle between low / high frame rate output)",
	"id": "example-widget-low-high-framerate",
	"type": "full",
	"presentation": {
		"layout": "SingleOutput",
		"widgets": [
			{
				"widget": "CheckboxCustom",
				"label": "High Frame Rate Broadcast",
				"options": {
					"true": {
						"Framerate": 60,
						"bitrate": "12m"
					},
					"false": {
						"Framerate": 30,
						"bitrate": "6m"
					}
				},
				"defaultValue": false,
				"parameters": {
					"Framerate": {
						"variable": "$videoFrameRateMax",
						"units": "INTEGER"
					},
					"bitrate": {
						"variable": "$videoBitrate",
						"units": "BITRATE_MBPS"
					}
				}
			},
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			}
		]
	},
	"template": {
		"input": {
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": 1280,
			"videoFrameHeightMax": 720,
			"videoFrameRateMax": "$videoFrameRateMax",
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition0",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl",
						"streamName": "$streamTargetStreamName",
						"username": "$streamTargetUsername",
						"password": "$streamTargetPassword"
					}
				],
				"encodingConfiguration": {
					"name": "rendition0",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "x264",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1280,
						"frameSizeHeight": 720,
						"profile": "main",
						"bitrate": "$videoBitrate",
						"bitrateMin": "750k",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "x264.preset",
								"value": "4",
								"type": "Long"
							},
							{
								"name": "x264.ref",
								"value": "1",
								"type": "Long"
							},
							{
								"name": "x264.bframes",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "128k",
						"parameters": []
					}
				}
			}
		]
	}
};