import { Popover, OverlayTrigger } from 'react-bootstrap';
import React, { PureComponent } from 'react';

class HelpTooltip extends PureComponent
{
	render()
	{
		let placement = this.props.placement || "bottom";
		let iconClassName = "fa fa-question-circle icon-tooltip";
		if (this.props.className) iconClassName += " " + this.props.className;
		let trigger = this.props.trigger || <i style={{cursor:"pointer"}} className={iconClassName}></i>;
		// let tooltipClassName = this.props.tooltipClassName || "";

		return (
			<OverlayTrigger
				placement={placement}
				overlay={<Popover id="help-popover"><Popover.Content>{this.props.children}</Popover.Content></Popover>}
			>
				{trigger}
			</OverlayTrigger>
		);
	}
}
export default HelpTooltip;