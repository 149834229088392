import React, { Component } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';

class AudioChannelMapper extends Component
{
  constructor(props)
	{
    super(props);
    this.setAudioChannels = this.setAudioChannels.bind(this);
    this.onLeft = this.onLeft.bind(this);
    this.onRight = this.onRight.bind(this);
    this.state = {
      updating:false
    };
  }

  async setAudioChannels (controller, broadcastId, audioChannelLeft, audioChannelRight)
  {
    try {
      this.setState({updating:true});
      await controller.setBroadcastInputAudioChannels(broadcastId, {
        audioChannelLeft:Number(audioChannelLeft),
        audioChannelRight:Number(audioChannelRight)
      });
      await controller.forcePolling();
      this.setState({updating:false});
    } catch (e) {
      console.log('ERROR setting audio channels:');
      console.log(e);
    }
  }

  onLeft (controller, broadcastId, audioChannelRight)
  {
    let _this = this;
    return async (evt) => {
      let audioChannelLeft = evt.target.value;
      await _this.setAudioChannels(controller, broadcastId, audioChannelLeft, audioChannelRight);
    }
  }

  onRight (controller, broadcastId, audioChannelLeft)
  {
    let _this = this;
    return async (evt) => {
      let audioChannelRight = evt.target.value;
      await _this.setAudioChannels(controller, broadcastId, audioChannelLeft, audioChannelRight);
    }
  }

  render ()
  {
    let { broadcastId, controller, audioChannelLeft, audioChannelRight } = this.props;
    let thisId = this.props.id ? this.props.id : "audioChannelMapper";

    if (!this.state.updating)
    {
      return (
        <div className="audioChannelMapper" id={thisId}>
          <Form.Group>
            <Form.Label>Audio Channel Mapping</Form.Label>
            <div className="row" style={{height:'38px'}}>
              <div className="col-6">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Left</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control as="select" value={audioChannelLeft} onChange={this.onLeft(controller,broadcastId,audioChannelRight)}>
                    <option value="-1">Default</option>
                    <option value="0">Channel 1</option>
                    <option value="1">Channel 2</option>
                    <option value="2">Channel 3</option>
                    <option value="3">Channel 4</option>
                    <option value="4">Channel 5</option>
                    <option value="5">Channel 6</option>
                    <option value="6">Channel 7</option>
                    <option value="7">Channel 8</option>
                  </Form.Control>
                </InputGroup>
              </div>
              <div className="col-6">
              <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Right</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control as="select" value={audioChannelRight} onChange={this.onRight(controller,broadcastId,audioChannelLeft)}>
                    <option value="-1">Default</option>
                    <option value="0">Channel 1</option>
                    <option value="1">Channel 2</option>
                    <option value="2">Channel 3</option>
                    <option value="3">Channel 4</option>
                    <option value="4">Channel 5</option>
                    <option value="5">Channel 6</option>
                    <option value="6">Channel 7</option>
                    <option value="7">Channel 8</option>
                  </Form.Control>
                </InputGroup>
              </div>
            </div>
          </Form.Group>
        </div>
      );
    }
    else
    {
      return (
        <div className="audioChannelMapper" id={thisId}>
          <Form.Group>
            <Form.Label>Audio Channel Mapping</Form.Label>
            <div className="row" style={{height:'38px'}}>
              <div className="col-6">
                <PulseLoader sizeUnit="px" size="5"/>
              </div>
              <div className="col-6">
                <PulseLoader sizeUnit="px" size="5"/>
              </div>
            </div>
          </Form.Group>
        </div>
      );
    }

  }
}
export default AudioChannelMapper;