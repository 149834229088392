import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';

class BroadcastShareDeleteUrlDialog extends Component
{
	constructor()
	{
		super();

		this.handleDelete = this.handleDelete.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	onShow()
	{

	}

	handleClose()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_CCACCESSKEYCONFIRMDELETE_DIALOG,
		});
	}

	handleDelete()
	{
		let accessKeyId = this.props.clearcaster.ccAccessKeyConfirmDelete.id;

		let _this = this;

		ClearCasterStore.dispatch({
			type: ActionTypes.SET_BROADCAST_SHARING,
			deletingKey: accessKeyId
		});

		this.props.controller.deleteAccessKey(accessKeyId).then((resp) => {
			_this.props.controller.getBroadcastAccessKeysMap()
				.then((r2)=>{
					ClearCasterStore.dispatch({
						type: ActionTypes.SET_BROADCAST_SHARING,
						deletingKey:null
					});
				});
		});

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_CCACCESSKEYCONFIRMDELETE_DIALOG,
		});
	}

	render()
    {
		let appStrings = this.props.strings;
		let accessKeyId = '';
		let accessKeyName = '';
		let sharingURL = '';
		if (this.props.clearcaster.ccAccessKeyConfirmDelete !== undefined)
		{
			accessKeyId = this.props.clearcaster.ccAccessKeyConfirmDelete.id;
			accessKeyName = this.props.clearcaster.ccAccessKeyConfirmDelete.name;
			sharingURL = window.location.href + '?k=' + accessKeyId;
		}

		return (
			<Modal show={ this.props.clearcaster.ccAccessKeyConfirmDelete !== undefined } onShow={ this.onShow } onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.app.DeleteSharingURL }</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>{ appStrings.app.DeleteSharingURLPrompt }</p>
					<p className="text-truncate"><strong>{accessKeyName}</strong><br />
						{sharingURL}
					</p>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
					<Button variant="primary" onClick={this.handleDelete}>{ appStrings.app.DeleteSharingURL }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default BroadcastShareDeleteUrlDialog;
