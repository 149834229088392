import React, { Component } from 'react';

import { Card, Badge, Button, Dropdown, Container, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import Moment from 'react-moment';
import { PulseLoader } from 'react-spinners';
import { formatFrameRate } from '../UIUtils';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import BroadcastUIAdapter, { getBroadcastEncoders, isNearRealtimeCurrentBroadcast, healthStatusClasses } from '../../model/BroadcastUIAdapter';
import EncoderStatusBadge from '../components/EncoderStatusBadge';
import {compareObjectValues, checkFeature, isVersionGreaterThan} from '../../controller/ClearCasterAdminUtils';
import SingularLiveGraphicsController from '../../controller/SingularLiveGraphicsController';
import Toggle from '../shell/Toggle';

class BroadcastItem extends Component
{
	constructor(props)
	{
		super(props);
		this.goLiveBroadcast = this.goLiveBroadcast.bind(this);
		this.endBroadcast = this.endBroadcast.bind(this);
		this.deleteBroadcast = this.deleteBroadcast.bind(this);
		this.editBroadcast = this.editBroadcast.bind(this);
		this.shareBroadcast = this.shareBroadcast.bind(this);
		this.toggleStreamTarget = this.toggleStreamTarget.bind(this);
		this.isSingularLiveGraphicShown = this.isSingularLiveGraphicShown.bind(this);
		this.getSingularLiveEnabledGraphics = this.getSingularLiveEnabledGraphics.bind(this);
		this.getSingularLiveAppInstanceId = this.getSingularLiveAppInstanceId.bind(this);
		this.showGraphic = this.showGraphic.bind(this);
		this.hideGraphic = this.hideGraphic.bind(this);
		this.muteAudio = this.muteAudio.bind(this);
		this.unmuteAudio = this.unmuteAudio.bind(this);
		this.isAudioMuted = this.isAudioMuted.bind(this);
		this.selectSlate = this.selectSlate.bind(this);
		this.slateOn = this.slateOn.bind(this);
		this.slateOff = this.slateOff.bind(this);

		this.state = {
			selectedSlateName:null,
			awaitingSlateOn:false,
			awaitingSlateOff:false,
			awaitingStreamTargetToggle:{}
		};

	}

	getEncoderModel(encoder)
	{
		let appStrings = this.props.strings;
		let model = null;
		if (encoder.hasOwnProperty('modelInfo') && encoder.modelInfo != null)
		{
			if (encoder.modelInfo.model === "UNKNOWN")
			{
				if (encoder.clearCasterSoftwareVersion != null && isVersionGreaterThan("2.2.0.0", encoder.clearCasterSoftwareVersion))
				{
					model = appStrings.app['EncoderModel_CC_PRO'];
				}
			}
			else
			{
				model = appStrings.app['EncoderModel_'+encoder.modelInfo.model];
			}
		}
		return model;
	}

	getEncoderModelMarkup(encoder){
		let appStrings = this.props.strings;
		let model = this.getEncoderModel(encoder);
		if (model != null)
		{
			return (<div key="name" className="panel-encoder-model"><span
				className="panel-label">{appStrings.app.EncoderModel}:&nbsp;</span>{model}<br/></div>);
		}
		return null;
	}

	getEncoderName(broadcast, encoder)
	{
		let appStrings = this.props.strings;

		let elements;
		let encoderStatus = null;

		if (encoder.hasOwnProperty('captureSessionInfo') && encoder.captureSessionInfo != null)
		{
			if (encoder.isOnline)
			{
				if (encoder.hasOwnProperty('activeBroadcast') && encoder.activeBroadcast != null && encoder.activeBroadcast.id != null && encoder.activeBroadcast.id === broadcast.id)
				{
					encoderStatus = <EncoderStatusBadge encoder={encoder} showSubscriptionExpired={false} controller={this.props.controller} strings={appStrings} />;
				} 
				else if (this.isEncoderActiveOnDifferentBroadcast(encoder, broadcast))
				{
					encoderStatus =
						<i className="fa fa-exclamation-triangle fa-lg fa-fw icon-warning" aria-hidden="true"></i>;
				} 
				else
				{
					switch (encoder.broadcastStatus)
					{
						case appStrings.app.streamStatusReady:
							encoderStatus = <Badge variant="success">{encoder.broadcastStatus}</Badge>;
							break;
						default:
							encoderStatus = <span></span>;
							break;
					}
				}
			} else
				encoderStatus = <Badge variant="secondary">{appStrings.app.StatusOffline}</Badge>;
		}

		let encoderName = encoder.name;
		let encoderNameShow = encoderName;
		let encoderNameTrim = false;

		if (encoderName.length > 22)
		{
			encoderNameShow = encoderNameShow.substring(0, 22);
			encoderNameTrim = true;
		}

		elements = [elements, (<div key="name" className="panel-fullname-name" title={encoderName}><span
			className="panel-label">{appStrings.app.ClearCaster}:&nbsp;</span>{encoderNameShow}{encoderNameTrim ?
			<span>&hellip;</span> : <span></span>}&ensp;{encoderStatus}<br/></div>)];

		return <div>{elements}</div>;
	}

	// TODO: pull out labels and use broadcastUI
	getEncoderVideoSource(broadcast, encoder)
	{
		let appStrings = this.props.strings;

		let elements;
		let encoderStatus;

		if (encoder.hasOwnProperty('captureSessionInfo') && encoder.captureSessionInfo != null)
		{
			if (encoder.isOnline)
			{
				if (encoder.captureSessionInfo.videoFrameCountModeSwitch > 0)
				{
					let videoSource = 'SDI';
					switch (encoder.captureSessionInfo.videoInput)
					{
						case 1:
							videoSource = 'SDI';
							break;
						case 2:
							videoSource = 'HDMI';
							break;
						default:
					}

					let inputFrameSize = encoder.captureSessionInfo.videoFrameWidth + ' x ' + encoder.captureSessionInfo.videoFrameHeight;

					let inputFrameRate = formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, 1);

					let inputFrameInfo = inputFrameSize;
					if (inputFrameRate.length > 0)
					{
						inputFrameInfo += (encoder.captureSessionInfo.videoProgressive ? ' p ' : ' i ');
						inputFrameInfo += inputFrameRate;
					}

					elements = [elements, (<div key="VideoSourceSource"><span
						className="panel-label">{appStrings.app.BroadcastItemsInput}:&nbsp;</span>{videoSource}&nbsp;{inputFrameInfo}<br/>
					</div>)];
				} else
				{
					elements = [elements, (<div key="noSource"><span
						className="panel-label">{appStrings.app.BroadcastItemsInput}:&nbsp;</span><i
						className="fa fa-exclamation-triangle fa-lg fa-fw icon-warning"
						aria-hidden="true"></i>&nbsp;{appStrings.app.VideoSourceNotDetected}<br/></div>)];
				}
			}
		}

		encoderStatus = <div>{elements}</div>;

		return encoderStatus;
	}

	getEncoderOutputFrameRate(broadcast, encoder)
	{
		let frameRate = '';

		if (encoder.hasOwnProperty('captureSessionInfo') && encoder.captureSessionInfo != null)
		{
			if (encoder.captureSessionInfo.videoFrameCountModeSwitch > 0)
			{
				let frameRateDivisor = 1.0;

				if (encoder.captureSessionInfo.videoFrameRateEnum > 0 && encoder.captureSessionInfo.videoFrameRateDen > 0)
				{
					if (broadcast.input != null && broadcast.input.videoFrameRateMax != null && broadcast.input.videoFrameRateMax > 0)
					{
						let frameRate = encoder.captureSessionInfo.videoFrameRateEnum / encoder.captureSessionInfo.videoFrameRateDen;
						if (frameRate > broadcast.input.videoFrameRateMax)
							frameRateDivisor = 2.0;
					}
				}

				frameRate = formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, frameRateDivisor);
			}
		}

		return frameRate;
	}

	getBroadcastOutputFrameInfo(broadcast, broadcastOutput, frameRate)
	{
		let outputFrameSize = broadcastOutput.encodingConfiguration.encodingConfigurationVideo.frameSizeWidth + ' x ' + broadcastOutput.encodingConfiguration.encodingConfigurationVideo.frameSizeHeight;

		// let maxFrameRate = 0.0;
		// if (broadcast.input && broadcast.input.maxFrameRate)
		// 	maxFrameRate = broadcast.input.maxFrameRate;

		let outputFrameInfo = outputFrameSize;
		if (frameRate.length > 0)
		{
			outputFrameInfo += ' p ';
			outputFrameInfo += frameRate;
		}
		return outputFrameInfo;
	}

	isEncoderActiveOnDifferentBroadcast(encoder, broadcast)
	{
		if (encoder.hasOwnProperty('activeBroadcast') && encoder.activeBroadcast != null && encoder.activeBroadcast.id != null && encoder.activeBroadcast.id !== broadcast.id)
		{
			return true;
		}
		return false;
	}

	isPreviewOrLive(broadcast)
	{
		return broadcast.status === 'PREVIEW' || broadcast.status === 'LIVE';
	}

	isPreview(broadcast)
	{
		return broadcast.status === 'PREVIEW';
	}

	isLive(broadcast)
	{
		return broadcast.status === 'LIVE';
	}

	isSingularLiveEnabled(broadcast)
	{
		return broadcast.input && broadcast.input.overlayVendor && broadcast.input.overlayVendor.indexOf("builtin-singularlive") >= 0;
	}
	isSingularLiveGraphicShown(broadcast,graphic)
	{
		let appInstanceId = this.getSingularLiveAppInstanceId(broadcast);
		if (appInstanceId !== null)
		{
			if (this.props.clearcaster.singularLiveData.showing[appInstanceId] != null)
			{
				return this.props.clearcaster.singularLiveData.showing[appInstanceId][graphic];
			}
		}
		return false;
	}
	getSingularLiveEnabledGraphics(broadcast)
	{
		let appInstanceId = this.getSingularLiveAppInstanceId(broadcast);
		let appInstanceData = SingularLiveGraphicsController.getData(this.props.clearcaster.singularLiveData,appInstanceId);
		let enabledGraphics = [];
		for (let key in appInstanceData)
		{
			if (appInstanceData[key].enabled)
				enabledGraphics.push(key);
		}
		return enabledGraphics;
	}
	getSingularLiveAppInstanceId(broadcast)
	{
		if (this.isSingularLiveEnabled(broadcast))
		{
			return broadcast.input.overlayVendor.substring(21);
		}
		return null;
	}

	showMonitorLink(broadcast, broadcastOutput, encoder)
	{
		if (broadcast.status && broadcast.status === 'LIVE')
		{
			let nearRealtimeOutputData = this.getNearRealtimeOutputData(broadcast, broadcastOutput, encoder);
			let ret = (nearRealtimeOutputData !== undefined);
			return ret;
		}
		return false;
	}

	getNearRealtimeOutputData(broadcast, broadcastOutput, encoder)
	{
		let outputData;

		if (encoder != null && encoder.hasOwnProperty('nearRealTimeData') && encoder.nearRealTimeData != null)
		{
			let nearRealtimeData = encoder.nearRealTimeData;
			if (isNearRealtimeCurrentBroadcast(nearRealtimeData, broadcast.id) && nearRealtimeData.hasOwnProperty('encoder') && nearRealtimeData.encoder != null && nearRealtimeData.encoder.hasOwnProperty('outputs') && nearRealtimeData.encoder.outputs != null)
			{
				let outputs = nearRealtimeData.encoder.outputs;
				for (let i in outputs)
				{
					if (outputs[i].id === broadcastOutput.id)
						outputData = outputs[i];
				}
			}
		}

		return outputData;
	}

	getBroadcastStreamTargetInfo(broadcast, broadcastOutput, encoder)
	{
		let appStrings = this.props.strings;

		let streamTargetInfo;

		if (broadcastOutput.streamTargets != null && broadcastOutput.streamTargets.length > 0)
		{
			let elements;

			let streamTarget = broadcastOutput.streamTargets[0];

			let streamTargetUrl = streamTarget.url;
			let streamTargetUrlShow = streamTargetUrl;
			let streamTargetUrlTrim = false;

			if (streamTargetUrl.length > 25)
			{
				streamTargetUrlShow = streamTargetUrlShow.substring(0, 25);
				streamTargetUrlTrim = true;
			}

			elements = [elements, (
				<div title={streamTargetUrl} className="panel-fullname-name" key="streamTargetUrl"><span
					className="panel-label">{appStrings.app.BroadcastItemsServerURL}:&nbsp;</span>{streamTargetUrlShow}{streamTargetUrlTrim ?
					<span>&hellip;</span> : <span></span>}</div>)];

			if (streamTarget.streamName && streamTarget.streamName.length > 0)
			{
				let streamTargetName = streamTarget.streamName;
				let streamTargetNameShow = streamTargetName;
				let streamTargetNameTrim = false;

				if (streamTargetName.length > 23)
				{
					streamTargetNameShow = streamTargetNameShow.substring(0, 23);
					streamTargetNameTrim = true;
				}

				elements = [elements, (
					<div title={streamTargetName} className="panel-fullname-name" key="streamTargetName"><span
						className="panel-label">{appStrings.app.BroadcastItemsStreamName}:&nbsp;</span>{streamTargetNameShow}{streamTargetNameTrim ?
						<span>&hellip;</span> : <span></span>}</div>)];
			}

			streamTargetInfo = <div>{elements}</div>
		}

		return streamTargetInfo;
	}

	getSharedBroadcastLinksButton(idPrefix, broadcast) {
		let accessKeys = this.props.clearcaster.broadcastAccessKeysMap[broadcast.id];
		let baseUrl = window.location.href;
		if (baseUrl.indexOf('#') > 0)
		{
			baseUrl = baseUrl.substring(0,baseUrl.indexOf('#'));
		}
		if (accessKeys && accessKeys.length > 0)
		{
			return (
				<Dropdown>
					<Dropdown.Toggle as={Button} variant="primary" size="sm">
						Open Shared Page
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{ accessKeys.map((k,key)=>{ return (
							<Dropdown.Item key={key} className="dropdown-link" target="_blank" rel="noopener noreferrer" href={baseUrl+'?k='+k.id}>
									{k.name}
							</Dropdown.Item>
						);})}
					</Dropdown.Menu>
				</Dropdown>
			);
		}
		else
		{
			return (
				<Button id={idPrefix + 'share2'} variant="primary" size="sm" style={{width:'140px'}}
					onClick={this.shareBroadcast}><i id={idPrefix + 'shareicon'}
					className="fa fa-share mr-2"></i>Share
				</Button>
			);
		}
	}

	getGoLiveButton(idPrefix,encoders, broadcast){
		let userIsSubscriptionAtGoLiveLimit = this.props.controller.userIsSubscriptionAtGoLiveLimit();
		// let isPro = encoders.length > 0 && (this.getEncoderModel(encoders[0]) === "ClearCaster Pro");
		let usingExpiredTemplate = broadcast.hasOwnProperty("createBroadcastInfo") && (broadcast.createBroadcastInfo.templateCategory === "expired-subscription-limited")

		//if the subscription is expired and an encoder is using a broadcastTemplate other than the expired template, GoLive is unavailable
		if (userIsSubscriptionAtGoLiveLimit && !usingExpiredTemplate){
			return (
				<Button id={idPrefix + 'golive'} size="sm" variant="secondary" style={{width:'140px', cursor: 'not-allowed'}}>
					<i className="fa exclamation-triangle"></i> {this.props.strings.app.Unavailable}
				</Button>
			)
		}else {
			return (
				<Button id={idPrefix + 'golive'} size="sm" variant="primary" style={{width:'140px'}} onClick={this.goLiveBroadcast}>
					<i className="fa fa-video-camera"></i> {this.props.strings.app.GoLive}
				</Button>
			)
		}
	}

	muteAudio(broadcast)
	{
		let _this = this;
		return (evt) => {

			let broadcastId = broadcast.id;

			this.props.controller.setBroadcastInputAudioLevel(broadcastId, {audioMuted: true}).then((data) =>
			{
				console.log(JSON.stringify(data));
				_this.props.controller.forcePolling();
			});
		}
	}

	unmuteAudio(broadcast)
	{
		let _this = this;

		return (evt) => {

			let broadcastId = broadcast.id;

			this.props.controller.setBroadcastInputAudioLevel(broadcastId, {audioMuted: false}).then((data) =>
			{
				console.log(JSON.stringify(data));
				_this.props.controller.forcePolling();
			});
		}
	}

	goLiveBroadcast(evt)
	{
		console.log(evt.target.id);

		let _this = this;

		let broadcastId = evt.target.id.split('-')[0];

		let broadcast = this.props.broadcast;
		let encoders = getBroadcastEncoders(broadcast, this.props.clearcaster.encoderMap);
		let encoder = encoders.length > 0 ? encoders[0] : null;

		if (encoder != null && this.isEncoderActiveOnDifferentBroadcast(encoder, broadcast))
		{
			let activeBroadcast = this.props.clearcaster.broadcastMap[encoder.activeBroadcast.id];
			ClearCasterStore.dispatch({
				type: ActionTypes.SHOW_GOLIVECONFIRMATION_DIALOG,
				data: {
					encoderName: encoder.name,
					activeBroadcast: activeBroadcast,
					activeBroadcastName: activeBroadcast.name,
					goLiveBroadcast: broadcast,
					goLiveBroadcastName: broadcast.name,
				}
			});
		}
		else
		{
			this.props.controller.setBroadcastChanging(broadcastId,true);
			this.props.controller.goLiveBroadcast(broadcastId).then((data) =>
			{
				console.log(JSON.stringify(data));
				_this.props.controller.forcePolling();
			});
		}

	}

	endBroadcast(evt)
	{
		console.log(evt.target.id);

		let _this = this;

		let broadcastId = evt.target.id.split('-')[0];

		this.props.controller.setBroadcastChanging(broadcastId,true);
		this.props.controller.endBroadcast(broadcastId, false).then((data) =>
		{
			console.log(JSON.stringify(data));
			_this.props.controller.forcePolling();
		});
	}

	deleteBroadcast(evt)
	{
		let broadcastId = evt.target.id.split('-')[0];

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_BROADCASTCONFIRMDELETE_DIALOG,
			broadcastInfo: {broadcastId: broadcastId}
		});
	}

	editBroadcast(evt)
	{
		let broadcastId = evt.target.id.split('-')[0];

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_BROADCASTCREATE_DIALOG,
			broadcastCreateDialogInfo: {broadcastId: broadcastId},
		});
	}

	shareBroadcast(evt)
	{
		let broadcastId = evt.target.id.split('-')[0];

		ClearCasterStore.dispatch({
			type: ActionTypes.SET_BROADCAST_SHARING,
			showBroadcastShareDialog:true,
			broadcastShareDialogId:broadcastId,
			creating:false,
			keyCreated:false
		});
	}

	toggleStreamTarget(streamTarget)
	{
		let _this = this;
		return async (evt) => {
			let awaitingStreamTargetToggle = Object.assign({},this.state.awaitingStreamTargetToggle);
			awaitingStreamTargetToggle[streamTarget.id] = !streamTarget.enable;
			_this.setState({awaitingStreamTargetToggle:awaitingStreamTargetToggle});
			await _this.props.controller.setBroadcastStreamTargetInfo(streamTarget.id,{"enable":!streamTarget.enable});
			_this.props.controller.forcePolling();
		}
	}

	showGraphic(broadcast,graphic)
	{
		let _this = this;
		return async (evt) => {
			let appInstanceId = _this.getSingularLiveAppInstanceId(broadcast);
			let appInstance = SingularLiveGraphicsController.getAppInstanceById(this.props.clearcaster.singularLiveData,appInstanceId);
			let compositionData = SingularLiveGraphicsController.getData(this.props.clearcaster.singularLiveData,appInstanceId);
			await SingularLiveGraphicsController.showGraphic(appInstance,graphic,compositionData);
		}
	}
	hideGraphic(broadcast,graphic)
	{
		let _this = this;
		return async (evt) => {
			let appInstanceId = _this.getSingularLiveAppInstanceId(broadcast);
			let appInstance = SingularLiveGraphicsController.getAppInstanceById(this.props.clearcaster.singularLiveData,appInstanceId);
			let compositionData = SingularLiveGraphicsController.getData(this.props.clearcaster.singularLiveData,appInstanceId);
			await SingularLiveGraphicsController.hideGraphic(appInstance,graphic,compositionData);
		}
	}

	isAudioMuted(broadcast)
	{
		let ret = (broadcast !== undefined && broadcast.input !== undefined && broadcast.input.audioMuted !== undefined && broadcast.input.audioMuted);

		return ret;
	}

	selectSlate(slateName)
	{
		let _this = this;
		return (evt) => {
			_this.setState({selectedSlateName:slateName});
		};
	}

	slateOn(broadcast,slateName)
	{
		let _this = this;
		return async (evt) => {
			if (slateName == null)
				return;
			_this.setState({awaitingSlateOn:true});
			await _this.props.controller.mutationSetSlateOn(broadcast.id,slateName);
			_this.props.controller.fetchPollingData();
		}
	}

	slateOff(broadcast)
	{
		let _this = this;
		return async (evt) => {
			_this.setState({awaitingSlateOff:true});
			await _this.props.controller.mutationSetSlateOff(broadcast.id);
			_this.props.controller.fetchPollingData();
		}
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.broadcast == null || prevProps.broadcast == null)
			return;
		let broadcastId = this.props.broadcast.id;
		if (prevProps.broadcast.status !== this.props.broadcast.status)
		{
			if (this.props.clearcaster.broadcastStateChangingMap[broadcastId] !== undefined)
			{
				this.props.controller.setBroadcastChanging(broadcastId, false);
			}
		}
		if (this.state.awaitingSlateOn || this.state.awaitingSlateOff)
		{
			let broadcastUI = new BroadcastUIAdapter(this.props.broadcast, null, this.props.clearcaster.encoderMap, this.props.strings);
			let encoders = broadcastUI.broadcastEncoders;
			if (encoders != null &&
				encoders[0] != null &&
				encoders[0].encoderStatus != null) {
				if (this.state.awaitingSlateOn && this.props.broadcast.input != null && this.props.broadcast.input.slateOverrideName != null) {
					this.setState({awaitingSlateOn: false});
				}
				if (this.state.awaitingSlateOff && this.props.broadcast.input != null && this.props.broadcast.input.slateOverrideName == null) {
					this.setState({awaitingSlateOff: false});
				}
			}
		}
		let awaitingStreamTargetToggle = Object.assign({},this.state.awaitingStreamTargetToggle);
		let awaitingStreamTargetToggleChanged = false;
		let keys = Object.keys(awaitingStreamTargetToggle);
		for (let i = 0; i < keys.length; i++)
		{
			let streamTargetId = keys[i];
			for (let o = 0; o < this.props.broadcast.outputs.length; o++)
			{
				for (let st = 0; st < this.props.broadcast.outputs[o].streamTargets.length; st++)
				{
					if (streamTargetId === this.props.broadcast.outputs[o].streamTargets[st].id)
					{
						if (awaitingStreamTargetToggle[streamTargetId] === (this.props.broadcast.outputs[o].streamTargets[st].enable===true))
						{
							delete awaitingStreamTargetToggle[streamTargetId];
							awaitingStreamTargetToggleChanged = true;
						}
					}
				}
			}
		}
		if (awaitingStreamTargetToggleChanged)
		{
			this.setState({awaitingStreamTargetToggle:awaitingStreamTargetToggle});
		}
	}

	render()
	{
		let appStrings = this.props.strings;

		let broadcast = this.props.broadcast;
		let broadcastData = null;
		if (this.props.clearcaster.broadcastLiveDataMap[broadcast.id] !== null)
		{
			broadcastData = this.props.clearcaster.broadcastLiveDataMap[broadcast.id];
		}
		let broadcastUI = new BroadcastUIAdapter(broadcast, broadcastData, this.props.clearcaster.encoderMap, this.props.strings);
		let isChanging = false;
		if (this.props.clearcaster.broadcastStateChangingMap[broadcast.id] !== undefined)
			isChanging = true;

		let encoders = broadcastUI.broadcastEncoders;
		let broadcastOutputs = broadcastUI.broadcastOutputs;
		// sort by bitrate descending
		broadcastOutputs = broadcastOutputs.sort(compareObjectValues(['encodingConfiguration','encodingConfigurationVideo','bitrate'],'desc'));

		let enableStreamTargetToggle = false;
		let outputFrameRate = '';
		if (encoders.length > 0)
		{
			outputFrameRate = this.getEncoderOutputFrameRate(broadcast, encoders[0]);
			enableStreamTargetToggle = checkFeature('streamTargetToggle',encoders[0]['clearCasterSoftwareVersion']);
		}
		if (enableStreamTargetToggle && 
			broadcastUI.templateInfo && 
			broadcastUI.templateInfo.templateId && 
			broadcastUI.templateInfo.isFacebookPaired)
		{
			enableStreamTargetToggle = false;
		}

		let idPrefix = broadcast.id + '-';

		let activeSlateName = null;
		let slateNames = [];

		if (broadcast.input != null && broadcast.input.slateOverrideName != null)
		{
			activeSlateName = broadcast.input.slateOverrideName;
		}

		if (encoders.length > 0 && encoders[0].encoderStatus != null)
		{
			// if (encoders[0].encoderStatus.activeSlateName != null)
			// 	activeSlateName = encoders[0].encoderStatus.activeSlateName;

			if (encoders[0].encoderStatus.availableSlateNames != null && encoders[0].encoderStatus.availableSlateNames.length > 0)
				slateNames = encoders[0].encoderStatus.availableSlateNames;
		}

		// slateNames = [
		// 	"blackout",
		// 	"ad1-noloop-mute",
		// 	"ad1-noloop-nomute",
		// 	"ad1-loop",
		// 	"audio-video",
		// 	"ad2"
		// ];

		return (
			<div className="broadcast-item">
				<Card className="shadow-sm">
					<Card.Header>
						{this.props.broadcast.name}
						<div className="pull-right" style={{margin: '-.2em'}}>
							<span
								className="header-label">{appStrings.app.BroadcastItemsStatus}:&nbsp;{broadcastUI.statusBadge}</span>
							{this.isPreviewOrLive(broadcast) ?
								<span>
							{this.showMonitorLink(broadcast, broadcastOutputs[0], encoders[0]) &&
							<span>&ensp;&ensp;<span
								className="header-label">{appStrings.app.BroadcastItemsHealth}:&nbsp;<LinkContainer to={"/monitor/live/"+broadcast.id} style={{cursor:'pointer'}}><i className={healthStatusClasses[broadcastUI.healthStatuses.overall]}></i></LinkContainer></span>
								&ensp;&ensp;<LinkContainer to={"/monitor/live/"+broadcast.id}><Button variant="outline-secondary" size="sm">
									<i className="fa fa-heartbeat"></i> {appStrings.app.Monitor}
								</Button></LinkContainer>
							</span>
							}
							</span>
								:
								<span>&ensp;&ensp;
									<Button id={idPrefix + 'edit'} variant="outline-secondary" size="sm"
										onClick={this.editBroadcast}><i id={idPrefix + 'editicon'}
										className="fa fa fa-pencil"></i>&nbsp;{appStrings.app.Edit}
									</Button>&nbsp;&nbsp;
									{this.props.clearcaster.broadcastSharing.active !== true &&
										<><Button id={idPrefix + 'share'} variant="outline-secondary" size="sm"
											onClick={this.shareBroadcast}><i id={idPrefix + 'shareicon'}
											className="fa fa-share"></i>&nbsp;Share
										</Button>&nbsp;&nbsp;</>
									}
									{this.props.clearcaster.broadcastSharing.active !== true &&
										<Button id={idPrefix + 'delete'} variant="outline-secondary" size="sm"
											onClick={this.deleteBroadcast}><i id={idPrefix + 'deleteicon'}
											className="fa fa-trash"></i>&nbsp;{appStrings.app.Delete}
										</Button>
									}
							</span>
							}
						</div>
					</Card.Header>
					<Card.Body>
						<div className="row">
							<div className="col-md-4 border-light border-right">
								<div>
									<div className="text-center pb-2">
										<span className="panel-heading">{appStrings.app.BroadcastItemsInputs}</span>
									</div>
									<div>
										<div className="panel-valuepair text-left">
											{encoders.length > 0 && this.getEncoderName(broadcast, encoders[0])}
											{encoders.length > 0 && this.getEncoderModelMarkup(encoders[0])}
											{encoders.length > 0 && this.getEncoderVideoSource(broadcast, encoders[0])}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div>
									<div className="text-center pb-2">
										<span className="panel-heading">{appStrings.app.BroadcastItemsOutputs}</span>
									</div>
									<div>
										<div className="text-left">
											{broadcastOutputs.map((o,key)=>{ return (
												<div className="pb-1" key={key}>
													<strong>{this.props.strings.app.VideoSourceOutput} {key+1}: </strong>
													{this.getBroadcastOutputFrameInfo(broadcast, o, outputFrameRate)}
												</div>
											)})}
											{ broadcastUI.templateName !== '' &&
												<div key="template"><span
													className="panel-label">{appStrings.app.BroadcastItemsTemplate}:&nbsp;</span>{broadcastUI.templateName}<br/></div>
											}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 border-light border-left">
								<div className="text-center pb-2">
									<span className="panel-heading">{appStrings.app.BroadcastItemsTargets}</span>
								</div>
								{broadcastOutputs.map((o,key)=>{ return (
									<div className="pb-0 d-block" key={key}>
										<strong>{this.props.strings.app.VideoSourceOutput} {key+1}</strong>
										{o.streamTargets.map((st,key2)=>{ return (
											<Container key={key2} className="w-100" fluid={true}>
												<Row className="align-items-center">
													<Col className="pl-3 pb-1 text-truncate" key={key2}>
														<strong>{this.props.strings.app.BroadcastItemsStreamName}: </strong>{st.streamName}<br />
														<strong>{this.props.strings.app.MonitoringDestinationsTarget} {key2+1}: </strong>{st.url}
													</Col>
													<Col xs="auto" className="p-0">
														{enableStreamTargetToggle &&
															<Toggle on={st.enable} size="2x"
																loading={this.state.awaitingStreamTargetToggle[st.id] != null}
																onClick={this.toggleStreamTarget(st)}
																className="d-inline-block ml-2" />
														}
													</Col>
												</Row>
											</Container>
										)})}
									</div>
								)})}

							</div>
						</div>
					</Card.Body>
					<Card.Footer>
						<div className="row">
							<div className="col d-flex flex-wrap">
								<div className="mr-3">
								{ process.env.REACT_APP_ENABLE_FACEBOOK_PAIRED_WORKFLOW === 'true' &&
									broadcastUI.templateInfo && broadcastUI.templateInfo.templateId && 
									broadcastUI.templateInfo.isFacebookPaired &&
									!this.props.controller.userIsSubscriptionAtGoLiveLimit() ?
										this.getSharedBroadcastLinksButton(idPrefix, broadcast)
									:
									isChanging ?
										<Button id={idPrefix + 'golive'} size="sm" disabled variant="outline-secondary" style={{width:'140px'}}>
											<PulseLoader sizeUnit="px" size="5"/>
										</Button> :
									this.isLive(broadcast) ?
										<Button id={idPrefix + 'golive'} size="sm" variant="danger" style={{width:'140px'}}
												onClick={this.endBroadcast}>
											<i className="fa fa-times"></i> {this.props.strings.app.EndBroadcast}
										</Button> :
										this.getGoLiveButton(idPrefix, encoders,broadcast)
								}
								</div>
								<div className="footer-info mr-3">
									<div className="d-inline-block mr-3">
										<small className="text-nowrap">
											<strong>{appStrings.app.Created}</strong><br/><Moment
											calendar={appStrings.calendarStrings}>{broadcast.createdAt}</Moment>
										</small>
									</div>
									<div className="d-inline-block">
										<small className="text-nowrap">
											<strong>{appStrings.app.BroadcastId}</strong><br/>{broadcast.id}<br/>
										</small>
									</div>
								</div>
								<div className="ml-auto d-flex flex-nowrap">

								{ this.isLive(broadcast) && slateNames.length > 0 &&
									<div className="footer-info text-center mr-3">
										{activeSlateName == null ?
											<>
												<div className="mb-1">
													<small>
														<Dropdown alignRight>
															<Dropdown.Toggle as="div" style={{cursor:'pointer'}}>
																<strong>{ this.state.selectedSlateName == null ? "Select An Asset" : this.state.selectedSlateName }</strong>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																{ slateNames.map((s,key)=>{ return (
																	<Dropdown.Item key={key} className="dropdown-link" onClick={this.selectSlate(s)}>{s}</Dropdown.Item>
																);})}
															</Dropdown.Menu>
														</Dropdown>
													</small>
												</div>
												{this.state.awaitingSlateOn ?
													<Toggle on={false} loading={true} size="2x" /> :
													<Toggle on={false} size="2x" onClick={this.slateOn(broadcast, this.state.selectedSlateName)} />
												}
											</> :
											<>
												<div className="mb-1">
													<small><strong>{activeSlateName}</strong></small>
												</div>
												{this.state.awaitingSlateOff ?
													<Toggle on={true} loading={true} size="2x" /> :
													<Toggle on={true} size="2x" onClick={this.slateOff(broadcast, this.state.selectedSlateName)} />
												}
											</>
										}
									</div>
								}
								{
									this.isLive(broadcast) && this.isSingularLiveEnabled(broadcast) &&
										this.getSingularLiveEnabledGraphics(broadcast).map((graphic,key) => {

											return (
											<div key={key} className="footer-info text-center mr-3">
												<div className="mb-1"><small><strong>{this.props.strings.app['ManageGraphicsTitle_'+graphic]}</strong></small></div>
												{ this.isSingularLiveGraphicShown(broadcast,graphic) ?
													<Toggle id={idPrefix+'toggleSlate'} size="2x" on={true} onClick={this.hideGraphic(broadcast,graphic)} /> :
													<Toggle id={idPrefix+'toggleSlate'} size="2x" on={false} onClick={this.showGraphic(broadcast,graphic)} />
												}
											</div>
										);})
								}
								{
									this.isLive(broadcast) &&
									<div className="footer-info text-center">
										<div className="mb-1"><small><strong>AUDIO</strong></small></div>
										{ this.isAudioMuted(broadcast) ?
												<button id={idPrefix+'muteAudio1'} className="btn btn-light btn-xs active" onClick={ this.unmuteAudio(broadcast) }><i className="fa fa-volume-off" style={{color:'#65686A'}}></i></button>
											:
												<button id={idPrefix+'unmuteAudio1'} className="btn btn-light btn-xs active" onClick={ this.muteAudio(broadcast) }><i className="fa fa-volume-up" style={{color:'#63C53D'}}></i></button>
										}
									</div>
								}
								</div>
							</div>
						</div>
					</Card.Footer>
				</Card>

				<br/>
			</div>
		);
	}
}

export default BroadcastItem;
