import ClearCasterStore from '../model/ClearCasterStore';

// TODO: Pull state-related transformations into a ClearCasterStoreUtils

export function doubleEquals(a, b, threshold = 0.01)
{
	return (Math.abs((a-b)) <= threshold);
}

export function stringEquals(a,b)
{
	return (a.length === b.length && a.indexOf(b) === 0);
}

export function redirectToLogin()
{
	let currentURL = window.location.href;
	let redirectURL = window.config.REACT_APP_AUTH_URL + '?onsuccess=' + encodeURIComponent(currentURL);
	setTimeout(function () {
		window.location.href = redirectURL;
	},1);
}

export function redirectToLogout()
{
	let currentURL = 'https://'+window.location.host;
	let redirectURL = window.config.REACT_APP_AUTH_LOGOUT_URL + '?onsuccess=' + encodeURIComponent(currentURL);
	setTimeout(function () {
		window.location.href = redirectURL;
	},1);
}

export function logout()
{
	redirectToLogout();
}

export function redirectToSwitchOrganization()
{
	let currentURL = 'https://'+window.location.host;
	let redirectURL = window.config.REACT_APP_AUTH_SWITCH_ORG_URL + '?onsuccess=' + encodeURIComponent(currentURL);
	setTimeout(function () {
		window.location.href = redirectURL;
	},1);
}

export function isVersionGreaterThan (v1, v2)
{
	let v1parts = v1.split(".");
	let v2parts = v2.split(".");

	if (v1parts.length !== v2parts.length)
	{
		return v1parts.length > v2parts.length;
	}

	for (var i = 0; i < v1parts.length; i++)
	{
		let v1part = parseInt(v1parts[i],10);
		let v2part = parseInt(v2parts[i],10);
		if (v1part === v2part)
			continue;
		return (v1part > v2part);
	}

	return false;
}

export function checkFeature (featureName, encoderVersion)
{
	if (process.env.REACT_APP_CHECK_FEATURE_VERSIONS === 'false')
		return true;

	let state = ClearCasterStore.getState();
	let featureVersion = state.featureEncoderVersions[ featureName ];

	if (featureVersion === null || typeof featureVersion === 'undefined')
		return false;

	if ( isVersionGreaterThan(featureVersion, encoderVersion))
		return false;

	return true;
}

export function partitionArray (arr,partitionSize)
{
	let returnArray = [];
	for (var i = 0; i < arr.length; i += partitionSize)
	{
		returnArray[returnArray.length] = arr.slice(i,i+partitionSize);
	}
	return returnArray;
}

export function formatFPS(fps)
{
	if (Math.round(fps) === fps)
	{
		return fps+'';
	}
	else
	{
		return fps.toFixed(2);
	}
}

export function testListOfObjectsWithIds (a,b)
{
	if (!a && !b)
	{
		return true;
	}
	if (!a || !b)
	{
		return false;
	}
	if (a.length !== b.length)
	{
		return false;
	}
	for (var i = 0; i < a.length; i++)
	{
		let curr = a[i];
		let foundMatch = false;
		for (var j = 0; j < b.length; j++)
		{
			if (curr['id'] === b[j]['id'])
			{
				foundMatch = true;
				break;
			}
		}
		if (!foundMatch)
		{
			return false;
		}
	}	
	return true;
}

export function mixObjects ()
{
	let obj = {};
	for (var i = 0; i < arguments.length; i++)
	{
		for (var key in arguments[i])
		{
			if (arguments[i].hasOwnProperty(key))
			{
				obj[key] = arguments[i][key];
			}
		}
	}
	return obj;
}

export function toISODate (dateObj)
{
		return dateObj.getFullYear() + '-' +
			('0'+ (dateObj.getMonth()+1)).slice(-2) + '-' +
			('0'+ dateObj.getDate()).slice(-2);
}
