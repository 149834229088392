
export const STREAMTARGET_TYPES = {
	NONE: "NONE",
	FB_PAIRED_STREAM_TARGET: "FB_PAIRED_STREAM_TARGET",
	LINKEDIN_LIVE_STREAM_TARGET: "LINKEDIN_LIVE_STREAM_TARGET",
	WSC_BROADCAST_STREAM_TARGET: "WSC_BROADCAST_STREAM_TARGET",
	GENERIC: "GENERIC",
}

export const CODECS_VIDEO = {
	h264: "H.264",
	h265: "H.265"
}

export const CODEC_IMPLEMENTATIONS_VIDEO = {
	default: "default",
	x264: "x264",
	quicksync: "QuickSync",
	nvenc: "nvenc",
	beamr: "beamr",
}

export const H264_PROFILES = {
	baseline: "baseline",
	main: "main",
	high: "high",
}

// x264.preset
export const X264_PRESETS = {
	ultrafast: "1",
	superfast: "2",
	veryfast: "3",
	faster: "4",
	fast: "5",
	medium: "6",
	slow: "7",
	slower: "8",
	veryslow: "9",
	placebo: "10",
};

// nvenc.preset
export const NVENC_PRESETS = {
	default: "0",
	lowlatency: "1",
	hp: "2",
	hq: "3",
	bd: "4",
	hqlowlatency: "5",
	hplowlatency: "6",
};

// quicksync.TargetUsage - 1=MFX_TARGETUSAGE_BEST_QUALITY, 4=MFX_TARGETUSAGE_BALANCED, 7=MFX_TARGETUSAGE_BEST_SPEED
export const QUICKSYNC_PRESETS = {
	BEST_QUALITY1: "1",
	BEST_QUALITY2: "2",
	BEST_QUALITY3: "3",
	BALANCED4: "4",
	BEST_SPEED5: "5",
	BEST_SPEED6: "6",
	BEST_SPEED7: "7",
};

export function getDefaultSettings()
{
	return {
		width: 1920,
		height: 1080,
		frameRate: 30,
		keyFrameInterval: 2000,
		audioBitrate: "128k",
		videoCodec: CODECS_VIDEO.h264,
		videoImplementation: CODEC_IMPLEMENTATIONS_VIDEO.x264,
		videoFrameSizeFitMode: "stretch",
		videoBitrate: "5m",
		videoBitrateMin: "1m",
		videoBFrames: 0,
		videoRefFrames: 1,
		videoProfile: H264_PROFILES.main,
		videoAutoAdjustBitrate: true,
		videoKeyFrameIntervalFollowSource: true,
		videoSortBufferEnable: true,
		videoPresets: {
			x264: X264_PRESETS.veryfast,
			x264ZeroLatency: false,
			nvenc: NVENC_PRESETS.hq,
			quicksync: QUICKSYNC_PRESETS.BEST_QUALITY2,
		},
		streamTargetType: STREAMTARGET_TYPES.GENERIC,
		streamTargetWithCredentials: false,
		streamTargetEncoderIndex: 0,
		addRecordWidget: true
	};
}

export function createSimpleTemplate(id, name, settings)
{
	let template = createTemplate(id, name);

	templateSetInputCapture(template, getInputSettingsSimple(settings.width, settings.height, settings.keyFrameInterval, settings.frameRate));

	if (!settings.videoSortBufferEnable)
	{
		template.transcodingConfiguration = {postProcessing: {sortBufferEnable: false, sortBufferSize:125, sortBufferFlushInterval:60}};
	}

	let output = templateAddOutput(template, settings.addRecordWidget, settings.addRecordWidget);

	let encodingConfigurationVideo = getEncodingConfigurationVideo(settings);
	let encodingConfigurationVideoParams = getEncodingConfigurationVideoParams(settings);

	outputSetEncodingConfigurationVideo(output, encodingConfigurationVideo, encodingConfigurationVideoParams);
	outputSetEncodingConfigurationAudio(output, getEncodingConfigurationAudioAAC(settings.audioBitrate));

	let streamTarget = undefined;

	switch(settings.streamTargetType)
	{
		case STREAMTARGET_TYPES.NONE:
			// do nothing
			break;
		case STREAMTARGET_TYPES.GENERIC:
			streamTarget = outputAddStreamTargetRTMP(output, settings.streamTargetWithCredentials, settings.streamTargetEncoderIndex);
			break;
		case STREAMTARGET_TYPES.FB_PAIRED_STREAM_TARGET:
			streamTarget = outputAddStreamTargetFacebookPaired(output, settings.streamTargetEncoderIndex);
			break;
		case STREAMTARGET_TYPES.LINKEDIN_LIVE_STREAM_TARGET:
			streamTarget = outputAddStreamTargetLinkedIn(output, settings.streamTargetEncoderIndex);
			break;
		case STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET:
			streamTarget = outputAddStreamTargetWSC(output, settings.streamTargetEncoderIndex);
			break;
		default:
	}

	addOutputWidgetToWidgets(template, output);

	if (settings.streamTargetType === STREAMTARGET_TYPES.FB_PAIRED_STREAM_TARGET)
	{
		template.isFacebookPaired = true;
		addPrimaryOptionToWidgets(template);
		addSpeedTestOptionToWidgets(template);
		addCountDownTimerWidgetToWidgets(template);
	}

	if (streamTarget !== undefined)
		addStreamTargetWidgetToWidgets(template, streamTarget);

	if (settings.addLinkedInRegions)
		addLinkedInRegionsToWidgets(template)

	if (settings.addRecordWidget)
		addRecordWidgetToWidgets(template);

	return template;
}

export function updateSettingsBasedOnTemplateId(id, settings)
{
	if (id.endsWith("+x264"))
		settings.videoImplementation = CODEC_IMPLEMENTATIONS_VIDEO.x264;
	else if (id.endsWith("+quicksync"))
		settings.videoImplementation = CODEC_IMPLEMENTATIONS_VIDEO.quicksync;
}

export function updateTemplateBasedOnTemplateId(id, template)
{
	template.id = id;

	if (id.endsWith("+quicksync"))
	{
		for(let key in template.template.outputs)
		{
			let output = template.template.outputs[key];
			output.encodingConfiguration.encodingConfigurationVideo.implementation = CODEC_IMPLEMENTATIONS_VIDEO.quicksync;
			output.encodingConfiguration.encodingConfigurationVideo.parameters = [];
		}
	}
}

export function createTemplate(id, name)
{
	let template = {
		id: id,
		name: name,
		type: "full",
		presentation: {
			layout: "SingleOutput",
			widgets: []
		},
		template: {
			input: {},
			outputs: [],
			extraProperties: []
		}
	}

	return template;
}

export function getInputSettingsSimple(width, height, keyFrameInterval, frameRate)
{
	return {
		videoFrameWidthMax: width,
		videoFrameHeightMax: height,
		videoFrameRateMax: frameRate,
		videoKeyFrameIntervalMilliseconds: keyFrameInterval,
	}
}

export function templateSetInputCapture(template, settings = undefined)
{
	template.template.input = {
		inputType: "CAPTURE_HARDWARE"
	}

	if (settings)
	{
		for(var key in settings)
		{
			template.template.input[key] = settings[key];
		}
	}

	return template.template.input;
}

export function templateAddOutput(template, addRecordWidget = false)
{
	let index = template.template.outputs.length;

	let output = {};

	output.tag = "output" + index;
	output.streamName = "rendition" + index;

	if (addRecordWidget)
		output.record = "$record";

	output.streamTargets = [];

	output.encodingConfiguration = {
		name: "rendition" + index,
		encodingConfigurationVideo: {},
		encodingConfigurationAudio: {}
	};

	template.template.outputs.push(output);

	return output;
}

export function outputAddStreamTargetLinkedIn(output, streamTargetEncoderIndex = 0)
{
	let index = output.streamTargets.length;

	let streamTarget = {
		tag: output.tag + "_streamTarget" + index,
		type: STREAMTARGET_TYPES.LINKEDIN_LIVE_STREAM_TARGET,
		streamTargetEncoderIndex: streamTargetEncoderIndex,
		url: "rtmp://placeholder.channel.media.azure.net:1935/live/placeholder",
		providerKeyId: "$providerKeyId_" + output.tag + "_" + index,
		providerId: "$providerId_" + output.tag + "_" + index,
	}

	output.streamTargets.push(streamTarget);

	return streamTarget;
}

export function outputAddStreamTargetFacebookPaired(output, streamTargetEncoderIndex = 0)
{
	let index = output.streamTargets.length;

	let streamTarget = {
		tag: output.tag + "_streamTarget" + index,
		type: STREAMTARGET_TYPES.FB_PAIRED_STREAM_TARGET,
		protocol: "RTMP",
		streamTargetEncoderIndex: streamTargetEncoderIndex,
		url: ""
	}

	output.streamTargets.push(streamTarget);

	return streamTarget;
}

export function outputAddStreamTargetWSC(output, streamTargetEncoderIndex = 0)
{
	let index = output.streamTargets.length;

	let streamTarget = {
		tag: output.tag + "_streamTarget" + index,
		type: STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET,
		streamTargetEncoderIndex: streamTargetEncoderIndex,
		providerKeyId: "$providerKeyId_" + output.tag + "_" + index,
		providerId: "$providerId_" + output.tag + "_" + index,
	}

	output.streamTargets.push(streamTarget);

	return streamTarget;
}

export function outputAddStreamTargetRTMP(output, withCredentials = true, streamTargetEncoderIndex = 0)
{
	let index = output.streamTargets.length;

	let streamTarget = {
		tag: output.tag + "_streamTarget" + index,
		type: STREAMTARGET_TYPES.GENERIC,
		protocol: "RTMP",
		streamTargetEncoderIndex: streamTargetEncoderIndex,
		url: "$streamTargetUrl_" + output.tag + "_" + index,
		streamName: "$streamTargetStreamName_" + output.tag + "_" + index,
	}

	if (withCredentials)
	{
		streamTarget.username = "$streamTargetUsername_" + output.tag + "_" + index;
		streamTarget.password = "$streamTargetPassword_" + output.tag + "_" + index;
	}

	output.streamTargets.push(streamTarget);

	return streamTarget;
}

export function addOutputWidgetToWidgets(template, output)
{
	let widgetObj = {
		widget: "OutputDetails",
		parameters: {
			output: {
				"outputTag": output.tag
			}
		}
	};

	template.presentation.widgets.push(widgetObj);
}

export function addRecordWidgetToWidgets(template)
{
	let widgetObj = {
		widget: "CheckboxSimple",
		label: "Record Broadcast",
		parameters: {
			value: {
				"variable": "$record",
				"defaultValue": false,
				"required": true
			}
		}
	};

	template.presentation.widgets.push(widgetObj);
}

export function addLinkedInRegionsToWidgets(template)
{
	let widgetObj = {
		widget: "SelectCustom",
		label: "Broadcast Region",
		disableOnEdit: true,
		parameters: {
			location: {"variable": "$streamTargetProviderBroadcastLocation"}
		},

		options: {
			"West US":{
				location: "WEST_US"
			},
			"Northeastern US":{
				location: "EAST_US_NORTH"
			},
			"Southeastern US":{
				location: "EAST_US_SOUTH"
			},
			"Central US":{
				location: "CENTRAL_US"
			},
			"South Central US":{
				location: "SOUTH_CENTRAL_US"
			},
			"South America":{
				location: "SOUTH_AMERICA"
			},
			"Southeast Asia":{
				location: "SOUTHEAST_ASIA"
			},
			"Central India":{
				location: "CENTRAL_INDIA"
			},
			"North Europe":{
				location: "NORTH_EUROPE"
			},
			"West Europe":{
				location: "WEST_EUROPE"
			}
		}
	}

	template.presentation.widgets.push(widgetObj);
}

export function addStreamTargetWidgetToWidgets(template, streamTarget)
{
	let widgetType = undefined;
	let parameters = {};
	let disableOnEdit = false;

	if (STREAMTARGET_TYPES.LINKEDIN_LIVE_STREAM_TARGET.localeCompare(streamTarget.type) === 0)
	{
		widgetType = "StreamTargetProvider";
		disableOnEdit =  true;
		parameters["providerKeyId"] = {
			variable: streamTarget.providerKeyId,
			label: "LinkedIn Live Access Token",
			required: true,
			filter: "LINKEDIN_ACCESS_TOKEN",
		};

		parameters["providerId"] = {
			variable: streamTarget.providerId,
			label: "LinkedIn Page",
			required: true,
		};
	}
	else if (STREAMTARGET_TYPES.WSC_BROADCAST_STREAM_TARGET.localeCompare(streamTarget.type) === 0)
	{
		widgetType = "StreamTargetProvider";

		parameters["providerKeyId"] = {
			variable: streamTarget.providerKeyId,
			label: "Wowza Streaming Cloud Access Key",
			required: true,
			filter: "WSC_PUBLIC_API"
		};

		parameters["providerId"] = {
			variable: streamTarget.providerId,
			label: "Wowza Streaming Cloud Live Stream",
			required: true,
		};
	}
	else if (STREAMTARGET_TYPES.GENERIC.localeCompare(streamTarget.type) === 0)
	{
		widgetType = "StreamTargetRTMPNoCredentials";

		parameters["url"] = {
			variable: streamTarget.url,
			defaultValue: "",
			label: "Server URL",
			required: true
		};

		parameters["streamName"] = {
			variable: streamTarget.streamName,
			defaultValue: "",
			label: "Stream Name/Key",
			required: false
		};

		if (streamTarget.hasOwnProperty("username"))
		{
			widgetType = "StreamTargetRTMPWithCredentials";

			parameters["username"] = {
				variable: streamTarget.username,
				defaultValue: "",
				label: "Username",
				required: false
			};

			parameters["password"] = {
				variable: streamTarget.password,
				defaultValue: "",
				label: "Password",
				required: false
			};
		}
	}

	let widget = undefined;

	if (widgetType)
	{
		widget = {
			widget: widgetType,
			parameters: parameters,
			disableOnEdit: disableOnEdit
		};

		template.presentation.widgets.push(widget);
	}

	return widget;
}

export function getEncodingConfigurationVideo(settings)
{
	return {
		codec: settings.videoCodec,
		implementation: settings.videoImplementation,
		frameSizeFitMode: settings.videoFrameSizeFitMode,
		frameSizeWidth: settings.width,
		frameSizeHeight: settings.height,
		profile: settings.videoProfile,
		bitrate: settings.videoBitrate,
		bitrateMin: settings.videoBitrateMin,
		autoAdjustBitrate: settings.videoAutoAdjustBitrate,
		keyFrameIntervalFollowSource: settings.videoKeyFrameIntervalFollowSource,
	};
}

export function getEncodingConfigurationVideoParams(settings)
{
	let params = [];

	switch(settings.videoImplementation)
	{
		case CODEC_IMPLEMENTATIONS_VIDEO.x264:
			params.push({
				"name": "x264.preset",
				"value": settings.videoPresets.x264,
				"type": "Long"
			});
			params.push({
				"name": "x264.ref",
				"value": settings.videoRefFrames+"",
				"type": "Long"
			});
			params.push({
				"name": "x264.bframes",
				"value": settings.videoBFrames+"",
				"type": "Long"
			});

			if (settings.videoPresets.x264ZeroLatency)
			{
				params.push({
					"name": "x264.tune-zerolatency",
					"value": "1",
					"type": "Long"
				});
			}
			break;
		case CODEC_IMPLEMENTATIONS_VIDEO.quicksync:
			params.push({
				"name": "quicksync.TargetUsage",
				"value": settings.videoPresets.quicksync,
				"type": "Long"
			});

			if (settings.videoRefFrames > 1)
			{
				params.push({
					"name": "quicksync.NumRefFrame",
					"value": settings.videoRefFrames+"",
					"type": "Long"
				});
			}

			if (settings.videoBFrames > 0)
			{
				params.push({
					"name": "quicksync.GopRefDist",
					"value": (settings.videoBFrames+1)+"",
					"type": "Long"
				});
			}
			break;
		default:
	}

	return params;
}

export function outputSetEncodingConfigurationVideo(output, configuration, parameters = undefined)
{
	for(let key in configuration)
	{
		output.encodingConfiguration.encodingConfigurationVideo[key] = configuration[key];
	}

	if (parameters)
	{
		output.encodingConfiguration.encodingConfigurationVideo.parameters = parameters;
	}
}

export function getEncodingConfigurationAudioAAC(bitrate)
{
	return {
		codec: "aac",
		bitrate: bitrate,
	};
}

export function outputSetEncodingConfigurationAudio(output, configuration, parameters = undefined)
{
	for(let key in configuration)
	{
		output.encodingConfiguration.encodingConfigurationAudio[key] = configuration[key];
	}

	if (parameters)
	{
		output.encodingConfiguration.encodingConfigurationAudio.parameters = parameters;
	}
}

export function addCountDownTimerWidgetToWidgets(template)
{

	let widget = {
		"widget": "SelectCustom",
		"label": "Count Down Timer Steps",
		"options": {
			"3": {
				"numberOfSteps": 3
			},
			"5": {
				"numberOfSteps": 5
			},
			"15": {
				"numberOfSteps": 15
			}
		},
		"defaultValue": 0,
		"parameters": {
			"numberOfSteps":{
				"variable": "$numberOfSteps",
				"units": "INTEGER"
			}
		}
	};

	template.presentation.widgets.push(widget);
	template.template.extraProperties.push({
		"name": "CountdownTimer.NumberOfSteps",
		"value": "$numberOfSteps",
		"type": "Int"
	});
	template.template.extraProperties.push({
		"name": "CountdownTimer.TimePerStep",
		"value": "1000",
		"type": "Int"
	});

return template;
}

export function addPrimaryOptionToWidgets(template)
{
	let widget = {
		"widget": "CheckboxSimple",
		"label": "Primary ingest stream",
		"parameters": {
			"value": {
				"variable": "$isPrimary",
				"defaultValue": true,
				"required": true
			}
		}
	};
	template.presentation.widgets.push(widget);
	template.template.extraProperties.push({
		"name": "facebook.isPrimary",
		"value": "$isPrimary",
		"type": "Boolean"
	});
}


export function addSpeedTestOptionToWidgets(template)
{
	let widget = {
		"widget": "CheckboxSimple",
		"label": "Optimize path to Facebook Live",
		"parameters": {
			"value": {
				"variable": "$doSpeedTest",
				"defaultValue": true,
				"required": true
			}
		}
	};
	template.presentation.widgets.push(widget);
	template.template.extraProperties.push({
		"name": "facebook.doSpeedTest",
		"value": "$doSpeedTest",
		"type": "Boolean"
	});
}