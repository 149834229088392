import React, { Component } from 'react';
import { Button, Form, InputGroup, Col, Dropdown, Modal } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import SingularLiveGraphicsController from '../../../../controller/SingularLiveGraphicsController';
import {
	textColorFromBackground,
	showSlate,
	colorChange,
	formChange,
	isDataDirty,
	persistData,
	graphics_componentDidMount,
	cancel
} from './ManageGraphicsUtils';
import LoadingOverlay from 'react-loading-overlay';

class ManageGraphicsSlate extends Component
{
	constructor(props)
	{
		super(props);
		this.toggleUseBackgroundImage = this.toggleUseBackgroundImage.bind(this);
		this.hasCountdownClockComposition = this.hasCountdownClockComposition.bind(this);
		this.toggleUseCountdownClock = this.toggleUseCountdownClock.bind(this);

		this.SlateImageBackgroundComposition = {
			"slate1":"SlateImageBackground",
			"slate2":"Slate2ImageBackground"
		};
		this.SlateSolidBackgroundComposition = {
			"slate1":"SlateSolidBackground",
			"slate2":"Slate2SolidBackground"
		}
	}

	toggleUseBackgroundImage(previewAppInstance,appInstance,compositionData) {
		let _this = this;
		return () => {
			let newCompositionData = JSON.parse(JSON.stringify(compositionData));
			let compositions = compositionData[_this.props.graphic].compositions;
			let newCompositions = [];
			let prevUseBackgroundImage = false;
			let foundOtherComposition = false;

			for (let i = 0; i < compositions.length; i++)
			{
				if (i === 0)
				{
					prevUseBackgroundImage = (compositions[i].compositionName.indexOf(_this.SlateImageBackgroundComposition[_this.props.graphic]) === 0);
				}
				if (prevUseBackgroundImage && compositions[i].compositionName.indexOf(_this.SlateSolidBackgroundComposition[_this.props.graphic]) === 0)
				{
					foundOtherComposition = true;
					newCompositions.unshift(compositions[i]);
				}
				else if (!prevUseBackgroundImage && compositions[i].compositionName.indexOf(_this.SlateImageBackgroundComposition[_this.props.graphic]) === 0)
				{
					foundOtherComposition = true;
					newCompositions.unshift(compositions[i]);
				}
				else
				{
					newCompositions.push(compositions[i]);
				}
			}
			if (!foundOtherComposition)
			{
				let otherCompositionName = prevUseBackgroundImage ? _this.SlateSolidBackgroundComposition[_this.props.graphic] : _this.SlateImageBackgroundComposition[_this.props.graphic];
				let otherComposition = {
					"compositionName": otherCompositionName,
					"controlNode": {
						"payload": SingularLiveGraphicsController.getDefaultPayload(otherCompositionName)
					}
				};
				for (let changeKey in compositions[0].controlNode.payload)
				{
					if (otherComposition.controlNode.payload.hasOwnProperty(changeKey))
					{
						otherComposition.controlNode.payload[changeKey] = compositions[0].controlNode.payload[changeKey];
					}
				}
				newCompositions.unshift(otherComposition);
			}
			newCompositionData[_this.props.graphic].compositions = newCompositions;
			SingularLiveGraphicsController.saveData(_this.props.clearcaster.singularLiveData,previewAppInstance.id,newCompositionData);
			showSlate(previewAppInstance,_this.props.graphic,newCompositionData)();
		}
	}

	hasCountdownClockComposition(compositions)
	{
		for (let i = 0; i < compositions.length; i++)
		{
			if (compositions[i].compositionName.indexOf('CountdownClock') === 0)
			{
				return true;
			}
		}
		return false;
	}

	toggleUseCountdownClock(previewAppInstance,appInstance,compositionData) {
		let _this = this;
		return () => {
			let newCompositionData = JSON.parse(JSON.stringify(compositionData));
			let compositions = compositionData[_this.props.graphic].compositions;
			let newCompositions = [];

			if (this.hasCountdownClockComposition(compositions))
			{
				for (let i = 0; i < compositions.length; i++)
				{
					if (compositions[i].compositionName.indexOf('CountdownClock') !== 0)
					{
						newCompositions.push(compositions[i]);
					}
				}
			}
			else
			{
				for (let i = 0; i < compositions.length; i++)
				{
					newCompositions.push(compositions[i]);
				}
				newCompositions.push({
					"compositionName": "CountdownClock",
					"controlNode": {
						"payload": SingularLiveGraphicsController.getDefaultPayload("CountdownClock")
					}
				});
			}

			newCompositionData[_this.props.graphic].compositions = newCompositions;
			SingularLiveGraphicsController.saveData(_this.props.clearcaster.singularLiveData,previewAppInstance.id,newCompositionData);
			showSlate(previewAppInstance,_this.props.graphic,newCompositionData)();
		}
	}

	componentDidMount()
	{
		graphics_componentDidMount(this.props.clearcaster.singularLiveData,this.props.appInstance,this.props.graphic);
	}

	render()
	{
		let singularLiveData = this.props.clearcaster.singularLiveData;
		let appInstance = this.props.appInstance;
		let previewAppInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,appInstance.preview);
		let pageState = SingularLiveGraphicsController.getData(singularLiveData,"manage");
		if (pageState == null)
		{
			return;
		}
		let compositionData = SingularLiveGraphicsController.getData(singularLiveData,previewAppInstance.id);
		if (compositionData == null)
		{
			compositionData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
		}
		if (compositionData == null)
		{
			compositionData = SingularLiveGraphicsController.getDefaultData();
		}

		let slateData = compositionData[this.props.graphic].compositions[0].controlNode.payload;
		if (slateData == null)
		{
			return (
				<div>{this.props.strings.app.ManageGraphicsNoDataFound}</div>
			);
		}

		let useBackgroundImage = false;
		if (compositionData[this.props.graphic].compositions[0].compositionName.indexOf(this.SlateImageBackgroundComposition[this.props.graphic]) === 0)
		{
			useBackgroundImage = true;
		}

		let useCountdownClock = this.hasCountdownClockComposition(compositionData[this.props.graphic].compositions);
		let countdownClockData = null;
		if (useCountdownClock)
		{
			for (let i = 0; i < compositionData[this.props.graphic].compositions.length; i++)
			{
				if (compositionData[this.props.graphic].compositions[i].compositionName.indexOf("CountdownClock") === 0)
				{
					countdownClockData = compositionData[this.props.graphic].compositions[i].controlNode.payload;
				}
			}
		}

		let previewUrl = previewAppInstance.definition.onair_url;

		let isDirty = isDataDirty(singularLiveData,appInstance.id,slateData);

		return (
			<Modal size="lg" show={ true } onHide={cancel(singularLiveData,previewAppInstance)} className="manage-graphics-slate">

				<LoadingOverlay active={ pageState.saving } spinner text={ this.props.strings.app.ManageGraphicsSaving } >
					<Modal.Header closeButton>
						<Modal.Title>{this.props.strings.app.ManageGraphicsEditSlate}</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<div className="row">
							<div className="col-lg-4">
								<Form>
									<div className="integration-heading">{this.props.strings.app.ManageGraphicsEditGraphics}</div>
									<Form.Group controlId="formUseBackgroundImage">
										<Form.Check
											type="checkbox"
											label={this.props.strings.app.ManageGraphicsUseBackgroundImage}
											checked={useBackgroundImage}
											onChange={this.toggleUseBackgroundImage(previewAppInstance,appInstance,compositionData)}
										/>
									</Form.Group>
									<Form.Group controlId="formSlateBackgroundColors">
										<Form.Label>
											{this.props.strings.app.ManageGraphicsBackgroundColor}
										</Form.Label>
										<Dropdown>
											<Dropdown.Toggle variant="light"
															 className="color-picker text-right w-100"
															 style={{
																 backgroundColor:slateData.BackgroundBase
															 }}
											>
												<span style={{
													color:textColorFromBackground(slateData.BackgroundBase)
												}}>
													{slateData.BackgroundBase.toLowerCase()} ▼
												</span>
											</Dropdown.Toggle>

											<Dropdown.Menu alignRight>
												<SketchPicker
													color={slateData.BackgroundBase}
													onChangeComplete={colorChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,"BackgroundBase")}
												/>
											</Dropdown.Menu>
										</Dropdown>
									</Form.Group>
									{useBackgroundImage && <>
										<Form.Group controlId="formSlateBackgroundImage">
											<Form.Label>
												{this.props.strings.app.ManageGraphicsBackgroundImageURL}
											</Form.Label>
											<Form.Control type="url"
														  value={slateData.BackgroundImage}
														  onChange={evt => formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{ BackgroundImage: evt.target.value })}
														  onBlur={showSlate(previewAppInstance,this.props.graphic,compositionData)}
											/>
										</Form.Group>
									</>}
									{!useBackgroundImage && <>

										<Form.Group controlId="formSlateBackgroundBevel">
											<Form.Label>
												{this.props.strings.app.ManageGraphicsBevelColor}
											</Form.Label>
											<Dropdown>
												<Dropdown.Toggle variant="light"
																 className="color-picker text-right w-100"
																 style={{
																	 backgroundColor:slateData.BackgroundBevel
																 }}
												>
												<span style={{
													color:textColorFromBackground(slateData.BackgroundBevel)
												}}>
													{slateData.BackgroundBevel.toLowerCase()} ▼
												</span>
												</Dropdown.Toggle>

												<Dropdown.Menu alignRight>
													<SketchPicker
														color={slateData.BackgroundBevel}
														onChangeComplete={colorChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,"BackgroundBevel")}
													/>
												</Dropdown.Menu>
											</Dropdown>
										</Form.Group>
									</>}
									<Form.Group controlId="formSlateTitleText">
										<Form.Label>
											{this.props.strings.app.ManageGraphicsTitle}
										</Form.Label>
										<InputGroup>
											<Form.Control type="text"
														  value={slateData.TitleText}
														  onChange={evt => formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{ TitleText: evt.target.value })}
														  onBlur={showSlate(previewAppInstance,this.props.graphic,compositionData)}
											/>
											<Dropdown as={InputGroup.Append}>
												<Dropdown.Toggle variant="light"
																 className="color-picker text-right pl-0 pr-1"
																 style={{
																	 width:"100px",
																	 backgroundColor:slateData.TitleTextColor
																 }}
												>
												<span style={{
													color:textColorFromBackground(slateData.TitleTextColor)
												}}>
												{slateData.TitleTextColor.toLowerCase()} ▼
												</span>
												</Dropdown.Toggle>

												<Dropdown.Menu alignRight>
													<SketchPicker
														color={slateData.TitleTextColor}
														onChangeComplete={colorChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,"TitleTextColor")}
													/>
												</Dropdown.Menu>
											</Dropdown>
										</InputGroup>
									</Form.Group>
									<Form.Group controlId="formSlateSubtitle">
										<Form.Label>
											{this.props.strings.app.ManageGraphicsSubtitle}
										</Form.Label>
										<InputGroup>
											<Form.Control type="text"
														  value={slateData.SubTitleText}
														  onChange={evt => formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{ SubTitleText: evt.target.value })}
														  onBlur={showSlate(previewAppInstance,this.props.graphic,compositionData)}
											/>
											<Dropdown as={InputGroup.Append}>
												<Dropdown.Toggle variant="light"
																 className="color-picker text-right pl-0 pr-1"
																 style={{
																	 width:"100px",
																	 backgroundColor:slateData.SubTitleTextColor
																 }}
												>
												<span style={{
													color:textColorFromBackground(slateData.SubTitleTextColor)
												}}>
												{slateData.SubTitleTextColor.toLowerCase()} ▼
												</span>
												</Dropdown.Toggle>

												<Dropdown.Menu alignRight>
													<SketchPicker
														color={slateData.SubTitleTextColor}
														onChangeComplete={colorChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,"SubTitleTextColor")}
													/>
												</Dropdown.Menu>
											</Dropdown>
										</InputGroup>
									</Form.Group>

									<Form.Group controlId="formUseCountdownClock">
										<Form.Check
											type="checkbox"
											label={this.props.strings.app.ManageGraphicsUseCountdownClock}
											checked={useCountdownClock}
											onChange={this.toggleUseCountdownClock(previewAppInstance,appInstance,compositionData)}
										/>
									</Form.Group>

									{ useCountdownClock &&
										<>
											<Form.Row>
												<Form.Group as={Col} controlId="formCountdownClockDataHours">
													<Form.Label>{this.props.strings.app.ManageGraphicsCountdownHours}</Form.Label>
													<Form.Control type="text"
														value={countdownClockData.BeginHours}
														onChange={evt => formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{ BeginHours: evt.target.value })}
														onBlur={showSlate(previewAppInstance,this.props.graphic,compositionData)}
													/>
												</Form.Group>
												<Form.Group as={Col} controlId="formCountdownClockDataMinutes">
													<Form.Label>{this.props.strings.app.ManageGraphicsCountdownMinutes}</Form.Label>
													<Form.Control type="text"
														value={countdownClockData.BeginMinutes}
														onChange={evt => formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{ BeginMinutes: evt.target.value })}
														onBlur={showSlate(previewAppInstance,this.props.graphic,compositionData)}
													/>
												</Form.Group>
												<Form.Group as={Col} controlId="formCountdownClockDataSeconds">
													<Form.Label>{this.props.strings.app.ManageGraphicsCountdownSeconds}</Form.Label>
													<Form.Control type="text"
														value={countdownClockData.BeginSeconds}
														onChange={evt => formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{ BeginSeconds: evt.target.value })}
														onBlur={showSlate(previewAppInstance,this.props.graphic,compositionData)}
													/>
												</Form.Group>
											</Form.Row>
											{/*
											<Form.Group controlId="formCountdownClockTextColor">
												<Form.Label>
													Text Color
												</Form.Label>
												<Dropdown>
													<Dropdown.Toggle variant="light"
														className="color-picker text-right w-100"
														style={{
															backgroundColor:countdownClockData.TextColor
														}}
													>
														<span style={{
															color:textColorFromBackground(countdownClockData.TextColor)
														}}>
															{countdownClockData.TextColor.toLowerCase()} ▼
														</span>
													</Dropdown.Toggle>

													<Dropdown.Menu alignRight>
														<SketchPicker
															color={countdownClockData.TextColor}
															onChangeComplete={colorChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,"TextColor")}
														/>
													</Dropdown.Menu>
												</Dropdown>
											</Form.Group>
											*/}
										</>
									}


								</Form>
							</div>
							<div className="col-lg-8">
								{ previewUrl !== null &&
								<>
									<div className="integration-heading">{this.props.strings.app.ManageGraphicsPreview}</div>
									<div style={{position:"relative",overflow:"hidden",height:"0",paddingTop:"56.25%",backgroundColor:"#000",border:"0"}}>
										<iframe title="preview graphics" style={{position:"absolute",top:"0",left:"0",height:"100%",width:"100%",border:"0"}} src={previewUrl}></iframe>
									</div>
								</>
								}
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className="w-100 mb-2" onClick={cancel(singularLiveData,previewAppInstance)} variant="secondary"><i className="fa fa-ban mr-2"></i>Cancel</Button>
						<Button className="w-100 mb-2" disabled={!isDirty} onClick={persistData(this.props.controller,previewAppInstance,appInstance,singularLiveData)} variant={isDirty?"primary":"outline-secondary"}><i className="fa fa-floppy-o mr-2"></i>Save Graphics</Button>
					</Modal.Footer>
				</LoadingOverlay>
			</Modal>
		);
	}
}

export default ManageGraphicsSlate;
