import 'bootstrap/dist/css/bootstrap.min.css';

import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import SubscriptionBanner from './view/shell/SubscriptionBanner';
import Notifications from './view/shell/Notifications';

import AppStrings from './model/AppStrings';
import ClearCasterStore, { ActionTypes } from './model/ClearCasterStore';
import ClearCasterAdminController from './controller/ClearCasterAdminController';
import Log from './log/Log';
import LogLevels from './log/LogLevels';
import { redirectToLogin } from './controller/UniversalAdminUtils';
import { withCookies } from 'react-cookie';


import WZHeader from './view/shell/WZHeader';
import WZFooter from './view/shell/WZFooter';

import Flags from './view/Flags';
import BroadcastView from './view/pages/BroadcastView';
import EncoderView from './view/pages/EncoderView';
import OpenSourceDeclaration from './view/pages/OpenSourceDeclaration';
import ManageView from './view/pages/ManageView';
import MonitorView from './view/pages/MonitorView';
import SupportView from './view/pages/SupportView';
import ArchiveView from './view/pages/ArchiveView';
import TemplateEditor from './view/pages/TemplateEditor';

import LogoutConfirmationDialog from './view/dialogs/LogoutConfirmationDialog';

import { getParameterByName } from './view/UIUtils';

import './App.css';

class App extends Component {

	constructor(props)
	{
		super(props);
		this.startController = this.startController.bind(this);

		let workingCredentials = false;
		let graphQLCredentials = undefined;
		const accessKey = this.getQueryVariable('k');

		if (window.config == null)
		{
			console.log('ERROR: No runtime config detected.');
		}

		if (accessKey)
		{
			// Shared Broadcast Page Credentials

			graphQLCredentials = {
				key:accessKey,
				secret:'',
				url:window.config.REACT_APP_GRAPHQL_URL
			};
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING,
				active:true,
				showGetPassphraseDialog:true,
				accessKey: accessKey
			});
		}
		else
		{
			// JWT credentials

			if (typeof this.props.cookies.get(window.config.REACT_APP_AUTH_COOKIE) !== 'undefined' && typeof this.props.cookies.get(window.config.REACT_APP_USER_COOKIE) !== 'undefined')
			{
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_USER_JWT,
					jwt: this.props.cookies.get(window.config.REACT_APP_AUTH_COOKIE),
					username: this.props.cookies.get(window.config.REACT_APP_USER_COOKIE)
				});
				workingCredentials = true;
			}
		}

		// demo mode credentials

		if (process.env.REACT_APP_IS_DEMO_MODE === "true")
		{
			let credentials = this.getQueryVariable('credentials');

			if (credentials !== undefined)
			{
				graphQLCredentials = JSON.parse(credentials);
				workingCredentials = true;
			}
		}

		if (graphQLCredentials !== undefined)
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_GRAPHQLCREDENTIALS,
				graphQLCredentials:graphQLCredentials
			});
		}

		let logLevel = getParameterByName('loglevel') || '';
		logLevel = logLevel.toUpperCase();
		if (!LogLevels.hasOwnProperty(logLevel))
		{
			logLevel = process.env.REACT_APP_LOG_LEVEL || LogLevels.ERROR;
		}

		ClearCasterStore.dispatch({
			type: ActionTypes.SET_LOG_LEVEL,
			logLevel:logLevel
		});

		this.state = {
			clearcaster: ClearCasterStore.getState(),
			controller: new ClearCasterAdminController(graphQLCredentials),
			strings: AppStrings,
			log: Log,
			workingCredentials:workingCredentials
		};

		this.isWowzaAuth = this.isWowzaAuth.bind(this);

		let linkedinAuthCode = this.getQueryVariable('code');
		let linkedinAuthState = this.getQueryVariable('state');

		if(linkedinAuthCode !== undefined && linkedinAuthState !== undefined)
		{
			let cookieState = this.props.cookies.get("linkedin_state")
			if (cookieState === linkedinAuthState){
				this.props.cookies.remove('linkedin_state');

				ClearCasterStore.dispatch({
					type: ActionTypes.SHOW_LINKEDINACCESSTOKENCREATE_DIALOG, authCode: linkedinAuthCode
				});
			}

		}
	}

	isWowzaAuth()
	{
		if (process.env.REACT_APP_IS_DEMO_MODE !== "true" && !this.state.clearcaster.broadcastSharing.active)
		{
			// See if none of the wowza cookies are present. If so, redirect.
			if (typeof this.props.cookies.get(window.config.REACT_APP_AUTH_COOKIE) === 'undefined' || typeof this.props.cookies.get(window.config.REACT_APP_USER_COOKIE) === 'undefined')
			{
				Log.info('No auth cookie present. Redirect to login.');
				redirectToLogin();
				return false;
			}
		}

		return true;
	}

	getQueryVariable(variable)
	{
		var query = window.location.search.substring(1);

		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++)
		{
			var pair = vars[i].split('=');
			if (decodeURIComponent(pair[0]) === variable)
			{
				return decodeURIComponent(pair[1]);
			}
		}
		return undefined;
	}

	onClearCasterStateChanged()
	{
		let clearCasterState = ClearCasterStore.getState();
		let workingCredentials = this.state.workingCredentials;

		if (clearCasterState.broadcastSharing.active)
		{
			if (workingCredentials && clearCasterState.broadcastSharing.error !== undefined)
			{
				workingCredentials = false;
			}
			else if (!workingCredentials && clearCasterState.graphQLCredentials.key && clearCasterState.graphQLCredentials.secret !== '')
			{
				this.setState({workingCredentials:true});
				this.state.controller.setGraphQLCredentials(clearCasterState.graphQLCredentials);
				this.startController();
				workingCredentials = true;
			}
		}
		this.setState({
			clearcaster: clearCasterState,
			controller: this.state.controller,
			strings: this.state.strings,
			log: this.state.log,
			workingCredentials: workingCredentials
		});
	}

	async componentDidMount()
	{
		ClearCasterStore.subscribe(this.onClearCasterStateChanged.bind(this));

		if (this.state.controller && this.state.workingCredentials)
		{
			await this.startController();
		}
	}

	async startController()
	{
		await this.state.controller.getGraphQLVersion();
		await this.state.controller.startPolling();
		await this.state.controller.loadNamespaceExtraProperties();
		if (this.state.clearcaster.broadcastSharing.active && this.state.clearcaster.broadcastSharing.error === undefined)
		{
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING,
				tryingPassphrase:false,
				showGetPassphraseDialog:false
			});
		}
	}

	renderPageItemHeaderFooter(renderContext, itemToRender)
	{
		return <div className="App container-fluid">
			<WZHeader strings={AppStrings} clearcaster={this.state.clearcaster} controller={this.state.controller} hidemonitoring={renderContext.hideMonitoring} />
			<div className="row">
				<div className="col-sm-12" style={{margin:'0 auto',maxWidth:'1140px'}}>
					<SubscriptionBanner 
						strings={AppStrings} 
						userIsSubscriptionCurrent={this.state.controller.userIsSubscriptionCurrent()}
						userIsSubscriptionDirect={this.state.controller.userIsSubscriptionDirect()}
						userIsSubscriptionUnderwater={this.state.controller.userIsSubscriptionUnderwater()}
						userIsSubscriptionAtGoLiveLimit={this.state.controller.userIsSubscriptionAtGoLiveLimit()}
						userSubscriptionDaysUntilUnderwater={this.state.controller.userSubscriptionDaysUntilUnderwater()}
					/>
					<Notifications />
					<LogoutConfirmationDialog strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />
					{ itemToRender }
				</div>
			</div>
			<WZFooter strings={AppStrings} />
		</div>;
	}

	renderPageItemNoHeaderFooter(renderContext, itemToRender)
	{
		return <div className="App container-fluid">
			{ itemToRender }
		</div>;
	}

	render()
	{
		let hideMonitoring = process.env.REACT_APP_HIDE_MONITORING === "true";
		if (!this.state.controller.userIsSubscriptionCurrent())
		{
			hideMonitoring = true;
		}
		let renderContext = {
			hideMonitoring: hideMonitoring
		};

		if (this.state.clearcaster && this.state.clearcaster.broadcastSharing.active === true)
		{

			return (
				<BrowserRouter>
					<Switch>
						<Route path="/broadcast" render={() => this.renderPageItemHeaderFooter(renderContext, <BroadcastView strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />) }/>
						<Route path="/monitor/live/:broadcastid" render={({ match }) => this.renderPageItemHeaderFooter(renderContext, <MonitorView archive={match.params.archive} broadcastId={match.params.broadcastid} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} /> ) } />
						<Route path="/monitor/live" render={({ match }) => this.renderPageItemHeaderFooter(renderContext, <MonitorView archive={match.params.archive} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />)} />
						<Route path="/monitor" render={() => this.renderPageItemHeaderFooter(renderContext, <MonitorView broadcastId={ null } archive={"live"} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />)} />
						<Route path="/support" render={() => this.renderPageItemHeaderFooter(renderContext, <SupportView clearcaster={this.state.clearcaster}/>)} />
						<Redirect path="/" to="broadcast" />
					</Switch>
				</BrowserRouter>
			);
		}

		return (
			<BrowserRouter>
				<Switch>
					<Route path="/support/flags" render={() => <Flags />} />
					<Route path="/support/opensourcedeclaration" render={() => this.renderPageItemHeaderFooter(renderContext, <OpenSourceDeclaration />)} />
					<Route path="/broadcast" render={() => this.isWowzaAuth() && this.renderPageItemHeaderFooter(renderContext, <BroadcastView strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />) }/>
					<Route path="/encoders" render={() => this.isWowzaAuth() && this.renderPageItemHeaderFooter(renderContext, <EncoderView strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />) } />
					<Route path="/device" render={() => this.isWowzaAuth() && this.renderPageItemHeaderFooter(renderContext, <EncoderView strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />) } />
					<Route path="/manage/graphics/:graphicsid" render={({ match }) => this.isWowzaAuth() && this.renderPageItemHeaderFooter(renderContext, <ManageView tab="graphics" graphicsid={match.params.graphicsid} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />) } />
					<Route path="/manage/:tab(encoders|templates|integrations|recordings|users|graphics)" render={({ match }) => this.isWowzaAuth() && this.renderPageItemHeaderFooter(renderContext, <ManageView tab={match.params.tab} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />) } />
					<Route path="/manage" render={() => this.isWowzaAuth() && this.renderPageItemHeaderFooter(renderContext, <ManageView tab={"encoders"} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />) } />
					<Route path="/monitor/live/:broadcastid" render={({ match }) => this.renderPageItemHeaderFooter(renderContext, <MonitorView archive={match.params.archive} broadcastId={match.params.broadcastid} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} /> ) } />
					<Route path="/monitor/live" render={({ match }) => this.renderPageItemHeaderFooter(renderContext, <MonitorView archive={match.params.archive} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />)} />
					<Route path="/monitor/archive/:broadcastgoliveid" render={({ match }) => this.renderPageItemHeaderFooter(renderContext, <ArchiveView archive={match.params.archive} broadcastGoLiveId={match.params.broadcastgoliveid} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} /> ) } />
					<Route path="/monitor/archive" render={({ match, location }) => this.renderPageItemHeaderFooter(renderContext, <ArchiveView archive={match.params.archive} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} location={location} />)} />
					<Route path="/monitor" render={() => this.renderPageItemHeaderFooter(renderContext, <MonitorView broadcastId={ null } archive={"live"} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />)} />
					<Route path="/template-editor" render={() => this.renderPageItemNoHeaderFooter(renderContext, <TemplateEditor strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} />) } />
					<Route path="/support" render={() => this.renderPageItemHeaderFooter(renderContext, <SupportView clearcaster={this.state.clearcaster}/>)} />
					<Route path="/rbui" render={() => this.isWowzaAuth() && this.renderPageItemHeaderFooter(renderContext, <ManageView tab={"encoders"} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} rbui={true} rbuiForce={false} />) } />
					<Route path="/rbui-force-hidden" render={() => this.isWowzaAuth() && this.renderPageItemHeaderFooter(renderContext, <ManageView tab={"encoders"} strings={AppStrings} log={this.state.log} clearcaster={this.state.clearcaster} controller={this.state.controller} rbui={true} rbuiForce={true} />) } />
					<Redirect path="/" to="broadcast" />
				</Switch>
			</BrowserRouter>
		);
	}
}

export default withCookies(App);
