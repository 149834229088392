module.exports = {
	"name": "Ten Stream Targets",
	"id": "example-config-ten-stream-targets",
	"type": "full",
	"presentation": {
		"layout": "SingleOutput",
		"widgets": [
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl",
						"defaultValue": "",
						"label": "Target 1 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName",
						"defaultValue": "",
						"label": "Target 1 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl1",
						"defaultValue": "",
						"label": "Target 2 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName1",
						"defaultValue": "",
						"label": "Target 2 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername1",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword1",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl2",
						"defaultValue": "",
						"label": "Target 3 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName2",
						"defaultValue": "",
						"label": "Target 3 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername2",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword2",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl3",
						"defaultValue": "",
						"label": "Target 4 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName3",
						"defaultValue": "",
						"label": "Target 4 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername3",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword3",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl4",
						"defaultValue": "",
						"label": "Target 5 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName4",
						"defaultValue": "",
						"label": "Target 5 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername4",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword4",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl5",
						"defaultValue": "",
						"label": "Target 6 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName5",
						"defaultValue": "",
						"label": "Target 6 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername5",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword5",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl6",
						"defaultValue": "",
						"label": "Target 7 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName6",
						"defaultValue": "",
						"label": "Target 7 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername6",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword6",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl7",
						"defaultValue": "",
						"label": "Target 8 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName7",
						"defaultValue": "",
						"label": "Target 8 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername7",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword7",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl8",
						"defaultValue": "",
						"label": "Target 9 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName8",
						"defaultValue": "",
						"label": "Target 9 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername8",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword8",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl9",
						"defaultValue": "",
						"label": "Target 10 Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName9",
						"defaultValue": "",
						"label": "Target 10 Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername9",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword9",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			}
		]
	},
	"template": {
		"input": {
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": 1920,
			"videoFrameHeightMax": 1080,
			"videoFrameRateMax": 30,
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition0",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl",
						"streamName": "$streamTargetStreamName",
						"username": "$streamTargetUsername",
						"password": "$streamTargetPassword"
					},
					{
						"tag": "streamTarget1",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl1",
						"streamName": "$streamTargetStreamName1",
						"username": "$streamTargetUsername1",
						"password": "$streamTargetPassword1"
					},
					{
						"tag": "streamTarget2",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl2",
						"streamName": "$streamTargetStreamName2",
						"username": "$streamTargetUsername2",
						"password": "$streamTargetPassword2"
					},
					{
						"tag": "streamTarget3",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl3",
						"streamName": "$streamTargetStreamName3",
						"username": "$streamTargetUsername3",
						"password": "$streamTargetPassword3"
					},
					{
						"tag": "streamTarget4",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl4",
						"streamName": "$streamTargetStreamName4",
						"username": "$streamTargetUsername4",
						"password": "$streamTargetPassword4"
					},
					{
						"tag": "streamTarget5",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl5",
						"streamName": "$streamTargetStreamName5",
						"username": "$streamTargetUsername5",
						"password": "$streamTargetPassword5"
					},
					{
						"tag": "streamTarget6",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl6",
						"streamName": "$streamTargetStreamName6",
						"username": "$streamTargetUsername6",
						"password": "$streamTargetPassword6"
					},
					{
						"tag": "streamTarget7",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl7",
						"streamName": "$streamTargetStreamName7",
						"username": "$streamTargetUsername7",
						"password": "$streamTargetPassword7"
					},
					{
						"tag": "streamTarget8",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl8",
						"streamName": "$streamTargetStreamName8",
						"username": "$streamTargetUsername8",
						"password": "$streamTargetPassword8"
					},
					{
						"tag": "streamTarget9",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl9",
						"streamName": "$streamTargetStreamName9",
						"username": "$streamTargetUsername9",
						"password": "$streamTargetPassword9"
					}
				],
				"encodingConfiguration": {
					"name": "rendition0",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "x264",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1920,
						"frameSizeHeight": 1080,
						"profile": "high",
						"bitrate": "5.5m",
						"bitrateMin": "1m",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "x264.preset",
								"value": "3",
								"type": "Long"
							},
							{
								"name": "x264.ref",
								"value": "1",
								"type": "Long"
							},
							{
								"name": "x264.bframes",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "128k",
						"parameters": []
					}
				}
			}
		]
	}
};