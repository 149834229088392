import { Component } from 'react';
import { withRouter } from "react-router-dom";

class EncoderView extends Component
{
	render()
	{
		console.log(this.props);
		this.props.history.push('manage/encoders');
		return null;
	}
}

export default withRouter(EncoderView);
