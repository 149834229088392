import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';


class CustomTemplateConfirmDeleteDialog extends Component
{
	constructor() {
		
		super();

		this.handleDelete = this.handleDelete.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	onShow()
	{

	}

	handleClose()
	{
		if (this.props.clearcaster.customTemplateConfirmDelete.onCancel !== undefined)
			this.props.clearcaster.customTemplateConfirmDelete.onCancel(this.props.clearcaster.customTemplateConfirmDelete.context);

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_CUSTOMTEMPLATECONFIRMDELETE_DIALOG,
		});
	}

	handleDelete()
	{
		if (this.props.clearcaster.customTemplateConfirmDelete.onDelete !== undefined)
			this.props.clearcaster.customTemplateConfirmDelete.onDelete(this.props.clearcaster.customTemplateConfirmDelete.context);

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_CUSTOMTEMPLATECONFIRMDELETE_DIALOG,
		});
	}

	render()
    {
		let appStrings = this.props.strings;

		let titleStr = this.props.clearcaster.customTemplateConfirmDelete?this.props.clearcaster.customTemplateConfirmDelete.titleStr:"";
		let bodyStr = this.props.clearcaster.customTemplateConfirmDelete?this.props.clearcaster.customTemplateConfirmDelete.bodyStr:"";
		let buttonStr = this.props.clearcaster.customTemplateConfirmDelete?this.props.clearcaster.customTemplateConfirmDelete.buttonStr:"";

		return (
			<Modal show={ this.props.clearcaster.customTemplateConfirmDelete !== undefined } onShow={ this.onShow } onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{ titleStr }</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{ bodyStr }
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
					<Button variant="primary" onClick={this.handleDelete}>{ buttonStr }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default CustomTemplateConfirmDeleteDialog;
