import React, { Component } from 'react';
import { Form, InputGroup, Col } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';

class AudioInputLevelSlider extends Component
{

  constructor(props)
  {
    super(props);
    this.state = {
      tentativeValue: Math.round(props.value/10)/10,
      updating:false
    };

    this.onRangeChange = this.onRangeChange.bind(this);
    this.onCommitChange = this.onCommitChange.bind(this);
  }

  componentDidUpdate(prevProps)
  {
    if (prevProps.value !== this.props.value) {
      let newValue = Math.round(this.props.value/10)/10;
      if (this.state.tentativeValue !== newValue)
        this.setState({tentativeValue:newValue});
    }
  }

  onRangeChange(event)
  {
    let tentativeValue = event.target.value;
    this.setState({
      tentativeValue:tentativeValue
    });
  }

  onCommitChange(broadcastId,controller)
  {
    let _this = this;
    return async (event) => {
      try {
        _this.setState({updating:true});
        await controller.setBroadcastInputAudioLevel(broadcastId, {
          audioLevel:Number(_this.state.tentativeValue * 100)
        });
        await controller.forcePolling();
        _this.setState({updating:false});
      } catch (e) {
        console.log('ERROR setting audio level:');
        console.log(e);
      }
    }
  }


  render()
  {
    let { broadcastId, controller } = this.props;

    if (!this.state.updating)
    {
      return (
        <div className="audioInputLevelChannel" id="audioInputLevelChannel">
          <Form.Group controlId="formBasicRange">
            <Form.Label>Audio Input Level</Form.Label>
            <Form.Row className="align-items-center">
              <Col sm={8}>
                <Form.Control type="range"
                  value={ this.state.tentativeValue }
                  step={ 0.5 }
                  max={ this.props.max }
                  min={ this.props.min }
                  onChange = { this.onRangeChange }
                  onMouseUp = { this.onCommitChange(broadcastId,controller) }
                />
              </Col>
              <Col sm={4}>
                <InputGroup>
                  <Form.Control as="input" type="number"
                    value={ this.state.tentativeValue }
                    step={ 0.5 }
                    max={ this.props.max }
                    min={ this.props.min }
                    onChange = { this.onRangeChange }
                    onBlur = { this.onCommitChange(broadcastId,controller) }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>dB</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Row>
          </Form.Group>
        </div>
      );
    }
    else
    {
      return (
        <div className="audioInputLevelChannel" id="audioInputLevelChannel">
          <Form.Group>
            <Form.Label>Audio Input Level</Form.Label>
            <div className="row" style={{height:'38px'}}>
              <div className="col-8">
                <PulseLoader sizeUnit="px" size="5"/>
              </div>
              <div className="col-4">
                <PulseLoader sizeUnit="px" size="5"/>
              </div>
            </div>
          </Form.Group>
        </div>
      );      
    }
  }
}
export default AudioInputLevelSlider