import React, { Component } from 'react';

import { Form, Button, Modal } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

class LinkedInAccessTokenCreateDialog extends Component
{
	static getDefaultState(authCode)
	{
		return {
			name:"",
			authCode:authCode,
		};
	}

	constructor(props) {

		super(props);

		this.state = LinkedInAccessTokenCreateDialog.getDefaultState(this.props.clearcaster.linkedinAuthCode);

		this.handleAdd = this.handleAdd.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.onEnter = this.onEnter.bind(this);
	}

  handleAdd() {
    let name = this.state.name;
    if(name === ''){
      name = "LinkedIn Live Auth Token - "+ Date.now();
    }
    // TODO create promise with some error handling
    this.props.controller.persistLinkedInAccessToken(name, this.state.authCode);
    this.handleClose()
  }

  handleClose() {
    ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_LINKEDINACCESSTOKENCREATE_DIALOG,
		});
  }

  onEnter() {

  }


	render()
    {
		let appStrings = this.props.strings;

		// var jsonStr = "{\"key\":\""+this.state.accessKey+"\",\"secret\":\""+this.state.secretKey+"\",\"url\":\""+window.config.REACT_APP_GRAPHQL_URL+"\"}";

		return (
			<Modal className="integrations-add-linkedin-dialog" show={ this.props.clearcaster.linkedinAccessTokenCreateDialogShowing } onEnter={ this.onEnter } onShow={ this.onShow } onHide={this.handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.app.AddLinkedInAccessToken }</Modal.Title>
				</Modal.Header>

				<Modal.Body style={{minHeight:'115px'}}>
					{this.state.authCode !== '' &&
						<Form>
							<Form.Group controlId="name">
								<Form.Label>{ appStrings.app.LinkedInAccessTokenName }&nbsp;*</Form.Label>
								<Form.Control value={ this.state.name } onChange={evt => this.setState({ name: evt.target.value })} />
							</Form.Group>
						</Form>
					}
				</Modal.Body>

				<Modal.Footer>
					{this.state.authCode !== '' &&
						<>
							<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
							<Button variant="primary" onClick={this.handleAdd}>{ appStrings.app.AddLinkedInAccessToken }</Button>
						</>
					}
					{this.state.accessToken === '' &&
						<div>
							<Button variant="primary" onClick={this.handleClose}>{ appStrings.app.Close }</Button>
						</div>
					}
				</Modal.Footer>
			</Modal>
		);
	}
}

export default LinkedInAccessTokenCreateDialog;
