import React, { Component } from 'react';

import ManageIntegrationsAccessKeys from './ManageIntegrationsAccessKeys';
import ManageIntegrationsWSC from './ManageIntegrationsWSC';
import ManageIntegrationsLinkedIn from './ManageIntegrationsLinkedIn';

class ManageIntegrations extends Component
{
	render()
	{
		return (
			<div className="row">
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-6">
							<ManageIntegrationsAccessKeys strings={this.props.strings}
																						log={this.props.log}
																						clearcaster={this.props.clearcaster}
																						controller={this.props.controller} />
						</div>
						<div className="col-md-6">
							<ManageIntegrationsWSC strings={this.props.strings}
																		 log={this.props.log}
																		 clearcaster={this.props.clearcaster}
																		 controller={this.props.controller} />
						</div>
					</div>
					{process.env.REACT_APP_ENABLE_LINKEDIN === "true" &&
					<div className="row">
						<div className="col-md-6">
							<ManageIntegrationsLinkedIn strings={this.props.strings}
														log={this.props.log}
														clearcaster={this.props.clearcaster}
														controller={this.props.controller}/>
						</div>
					</div>
					}
				</div>
			</div>
			);
	}
}

export default ManageIntegrations;
