import React, { Component } from 'react';

import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import BroadcastItemArchive from './archive/BroadcastItemArchive';
import BroadcastGoLiveEventSearch from './archive/BroadcastGoLiveEventSearch';
import Moment from 'react-moment';
import moment from 'moment';
import { withRouter } from "react-router";

// TODO: Rework archives with broadcastGoLiveId's

class ArchiveView extends Component
{
	onSelectTab()
	{

	}

	componentDidMount()
	{
		if (this.props.clearcaster.userWowza.namespaceId !== null)
		{
			this.props.controller.getBroadcastGoLiveEvents();
		}
	}

	async componentDidUpdate(prevProps)
	{
		// get golive events, but make sure we have a namespaceId first
		if (this.props.clearcaster.userWowza.namespaceId !== null &&
			process.env.REACT_APP_ENABLE_ARCHIVE === "true" &&
			!this.props.clearcaster.broadcastGoLiveEventsLoading)
		{
			let now = new Date();
			if (this.props.clearcaster.broadcastGoLiveEventsUpdateTime === null ||
				(now.getTime() - this.props.clearcaster.broadcastGoLiveEventsUpdateTime.getTime()) > this.props.clearcaster.broadcastGoLiveEventsUpdateInterval)
			{
				await this.props.controller.getBroadcastGoLiveEvents();
			}
		}
	}

	render()
	{
		// ARCHIVE
		let broadcastGoLiveId = this.props.broadcastGoLiveId;
		let broadcastGoLiveEventInfo = null;
		let dropdownGoLiveEvents = [];
		for (let goLiveIndex = 0; goLiveIndex < this.props.clearcaster.broadcastGoLiveEvents.length; goLiveIndex++)
		{
			let goLiveEvent = this.props.clearcaster.broadcastGoLiveEvents[goLiveIndex];
			let dropdownItem = {
				broadcastId:goLiveEvent.broadcastId,
				broadcastGoLiveId:goLiveEvent.broadcastUniqueId,
				broadcastName:'',
				encoderIds:goLiveEvent.encoderIds,
				timeGoLive:goLiveEvent.timeGoLive,
				humanTime:'',
				displayName:'',
				startTime:new Date(goLiveEvent.timeGoLive),
				endTime:new Date(goLiveEvent.timeStop)
			};
			dropdownItem.humanTime = <span><Moment format="YYYY-MM-DD" date={dropdownItem.startTime} /><span> @ </span><Moment format="h:mma" date={dropdownItem.startTime} /></span>;
			dropdownItem.duration = moment.duration(dropdownItem.endTime - dropdownItem.startTime);
			dropdownItem.durationHumanTime = dropdownItem.duration.humanize();
			if (this.props.clearcaster.encoderMap[dropdownItem.encoderIds[0]] !== undefined && this.props.clearcaster.encoderMap[dropdownItem.encoderIds[0]] !== null)
			{
				dropdownItem.encoderName = this.props.clearcaster.encoderMap[dropdownItem.encoderIds[0]].name;
				dropdownItem.encoderSerial = this.props.clearcaster.encoderMap[dropdownItem.encoderIds[0]].serial;
			}
			else
			{
				dropdownItem.encoderName = this.props.strings.app.Unknown;
			}

			dropdownItem.displayName = <span>
				{goLiveEvent.title + ': '}
				<Moment format="YYYY-MM-DD" date={dropdownItem.startTime} /> @ <Moment format="h:mma" date={dropdownItem.startTime} />
			</span>;
			dropdownItem.broadcastName = goLiveEvent.title;

			if (broadcastGoLiveId === goLiveEvent.broadcastUniqueId)
			{
				broadcastGoLiveEventInfo = dropdownItem;
			}
			dropdownGoLiveEvents.push(dropdownItem);
		}

		return (
			<div className="text-left">
				<div className="row mb-4">
					<Nav variant="tabs" activeKey={'archive'} onSelect={ this.onSelectTab } className="w-100">
						<Nav.Item className="ml-5">
							<Nav.Link as={NavLink} to={'/monitor/live'} eventKey={'live'}>
								<span><strong>{this.props.strings.nav.MonitoringLiveBroadcasts}</strong></span>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={NavLink} to={'/monitor/archive'} eventKey={'archive'}>
								<span><strong>{this.props.strings.nav.MonitoringArchivedBroadcasts}</strong></span>
							</Nav.Link>
						</Nav.Item>
					</Nav>

				</div>

				{ process.env.REACT_APP_ENABLE_ARCHIVE === "false" ?
					<div>Coming Soon</div>
					:
					<>
						<BroadcastGoLiveEventSearch broadcastGoLiveEvents={dropdownGoLiveEvents}
																				currentEvent={broadcastGoLiveEventInfo}
																				encoderMap={this.props.clearcaster.encoderMap}
																				broadcastMap={this.props.clearcaster.broadcastMap}
																				location={this.props.location}
																				history={this.props.history}
																				strings={this.props.strings}
						/>
						{dropdownGoLiveEvents.length === 0 && (
							<div className="row mb-3">
								<div className="col-sm-12">
									{this.props.strings.app.NoArchivedBroadcasts}
								</div>
							</div>
						)}
						{broadcastGoLiveEventInfo != null &&
						<BroadcastItemArchive broadcastGoLiveEventInfo={broadcastGoLiveEventInfo}
																	clearcaster={this.props.clearcaster}
																	controller={this.props.controller}
																	strings={this.props.strings} />
						}
					</>
				}
			</div>
		);
	}
}

export default withRouter(ArchiveView);
