import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import FacebookConnectionState from '../model/FacebookConnectionState';
import Log from '../log/Log';

export function FacebookInit () {

	let _promise = new Promise((resolve,reject) => {
		window.fbAsyncInit = function()
		{
			window.FB.init({
				appId            : process.env.REACT_APP_FACEBOOK_APPID,
				autoLogAppEvents : true,
				xfbml            : true,
				version          : process.env.REACT_APP_FACEBOOK_GRAPHVERSION,
				cookie           : true,
				status           : true
			});
			window.FB.AppEvents.logPageView();
	
			// WIRE EVENT SUBSCRIPTIONS HERE
			/*
			window.FB.Event.subscribe('auth.login', function(response) {
	
				console.log('## FB.Event: auth.login');
				console.log(response);
			});
			window.FB.Event.subscribe('auth.statusChange', function(response) {
	
				console.log('## FB.Event: auth.statusChange');
				console.log(response);
			});
			*/
			try {
				window.FB.getLoginStatus(async function(response) {
					if (response.status === 'connected' && response['authResponse']['accessToken'] != null)
					{
						let userInfo = await FacebookGetUserInfo(response['authResponse']['accessToken']);
						userInfo.facebookConnectedOnLoad = true;
						ClearCasterStore.dispatch({
							type: ActionTypes.USER_FACEBOOK_LOGIN,
							userInfo:userInfo
						});
						resolve (userInfo);
					}
					resolve(null);
				});
			}
			catch (e) {
				reject(e);
			}
		};
	
		(function(d, s, id){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {return;}
			js = d.createElement(s); js.id = id;
			js.src = "//connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	});
	return _promise;
}

function login()
{
	let _promise = new Promise(function(resolve,reject)
	{
		try {
			window.FB.login( function (response)
			{
				if (response.status === 'connected' && response['authResponse'] != null && response['authResponse']['accessToken'] != null)
				{
					resolve(response);
				}
				else
				{
					reject(response);
				}
			},{
				scope:process.env.REACT_APP_FACEBOOK_SCOPES,
				return_scopes:false
			});
		} catch (e) {
			reject(e);
		}
	});
	return _promise;
}

export async function FacebookLogin(){

	try {
		let loginResponse = await login();
		let userInfo = await FacebookGetUserInfo(loginResponse['authResponse']['accessToken']);
		ClearCasterStore.dispatch({
			type: ActionTypes.USER_FACEBOOK_LOGIN,
			userInfo:userInfo
		});
		return userInfo;
	}
	catch (e) {
		console.log('Error connecting to Facebook');
		console.log(e);
	}
	return null;
}

function logout()
{
	let _promise = new Promise(function(resolve, reject)
	{
		try {
			window.FB.logout( function (response)
			{
				resolve(response);
			});
		}
		catch (e)
		{
			reject(e);
		}
	});
	return _promise;
}

export async function FacebookLogout(){

	try {
		await logout();
		ClearCasterStore.dispatch({
			type: ActionTypes.USER_FACEBOOK_LOGOUT
		});
	}
	catch (e) {
		console.log('Error logging out of Facebook');
		console.log(e);
	}
}


export function FacebookGetUserInfo(accessToken)
{
	let _promise = new Promise(function(resolve, reject)
	{
		window.FB.api('/me', {fields: 'id,email,name,groups.limit(1000),accounts.fields(id,name,access_token,supports_donate_button_in_live_video).limit(1000),supports_donate_button_in_live_video,picture'}, function (response)
		{
			if (response && !response.error)
			{
				let userInfo = {
					facebookConnected:FacebookConnectionState.CONNECTED,
					accessToken:accessToken,
					email:response.email,
					id:response.id,
					name:response.name,
					picture:response.picture,
					userID:response.id,
					supports_donate_button_in_live_video:response.supports_donate_button_in_live_video,
					pages:[],
					pageIdMap:{},
					groups:[],
					events:[]
				};

				if (response.accounts && response.accounts.data)
				{
					userInfo['pages'] = response.accounts.data;
					for(var key in response.accounts.data)
					{
						userInfo['pageIdMap'][response.accounts.data[key].id] = response.accounts.data[key];
					}
				}

				if (response.groups && response.groups.data)
					userInfo['groups'] = response.groups.data;

				if (response.events && response.events.data)
					userInfo['events'] = response.events.data;

				resolve(userInfo);
			}
			else
			{
				reject(response);
			}
		});
	});
	return _promise;
}

export function FacebookCreateBroadcast(broadcastInfo)
{
	let _promise = new Promise(function(resolve, reject)
	{
		let fields = {};
		fields['title'] = broadcastInfo.broadcast.title;
		fields['description'] = broadcastInfo.broadcast.description;
		fields['fields'] = 'id,status,dash_preview_url,permalink_url,overlay_url,planned_start_time,secure_stream_url.inband_go_live(require_inband_signal),ingest_streams,video';
		fields['date_format'] = 'U';
		fields['enable_backup_ingest'] = true;
		fields['num_hosts'] = 2;
		fields['privacy'] = {value:broadcastInfo.destination.privacy};
		
		if (broadcastInfo.isSpherical !== undefined)
			fields['is_spherical'] = broadcastInfo.isSpherical;

		if (broadcastInfo.isSpherical && broadcastInfo.isFishEye)
		{
			fields['projection'] = 'EQUIRECTANGULAR';
			if (broadcastInfo.fov !== undefined)
				fields['fov'] = Number(broadcastInfo.fov);
		}


		if (broadcastInfo.embeddable !== undefined)
			fields['embeddable'] = broadcastInfo.embeddable;

		if (broadcastInfo.unpublishAtEnd !== undefined)
		{
			if (broadcastInfo.unpublishAtEnd)
				fields['stream_type'] = 'AMBIENT';
		}

		if (broadcastInfo.accessToken !== undefined)
			fields['access_token'] = broadcastInfo.accessToken;

		let broadcastId = broadcastInfo.broadcast.id;
		if (broadcastInfo.broadcast.id === '')
		{
			if (broadcastInfo.destination.id !== undefined)
				broadcastId = '/'+broadcastInfo.destination.id+'/live_videos';
			else
				broadcastId = '/me/live_videos';
			
			fields['status'] = 'UNPUBLISHED';
		}

		let logFields = {...fields};
		delete logFields['access_token'];
		console.log('FacebookCreateBroadcast: broadcastId:' + broadcastId +', fields:' + JSON.stringify(logFields));

		try {
			window.FB.api(broadcastId, 'post', fields, function (response)
			{
				if (response['error'])
				{
					console.log('FacebookCreateBroadcast:ERROR: broadcastId:' + broadcastId +', response:' + JSON.stringify(response));
					reject(response);
				}
				else
				{
					console.log('FacebookCreateBroadcast: broadcastId:' + broadcastId +', response:' + JSON.stringify(response));
				}
				resolve(response);
			});
		}
		catch (e) {
			reject(e);
		}
	});
	return _promise;
}

export function FacebookGoLiveBroadcast(broadcastId,accessToken)
{
	let _promise = new Promise(function(resolve,reject)
	{
		let fields = {};

		fields['status'] = 'LIVE_NOW';
		if (accessToken !== undefined)
			fields['access_token'] = accessToken;

		console.log('FacebookGoLiveBroadcast: broadcastId:' + broadcastId + ', fields:' + JSON.stringify(fields));

		try {
			window.FB.api(broadcastId, 'post', fields, function (response)
			{
				console.log('FacebookGoLiveBroadcast[1]: broadcastId:' + broadcastId + ', response:' + JSON.stringify(response));
				resolve(response);
			});
		}
		catch (e) {
			reject(e);
		}
	});
	return _promise;
}

export function FacebookEndBroadcast(broadcastId,accessToken)
{
	let _promise = new Promise(function(resolve,reject)
	{
		let fields = {};

		fields['end_live_video'] = 'true';
		if (accessToken !== undefined)
			fields['access_token'] = accessToken;

		console.log('FacebookEndBroadcast: broadcastId:' + broadcastId + ', fields:' + JSON.stringify(fields));

		try {
			window.FB.api(broadcastId, 'post', fields, function (response)
			{
				console.log('FacebookEndBroadcast[1]: broadcastId:' + broadcastId + ', response:' + JSON.stringify(response));
				resolve(response);
			});
		}
		catch (e) {
			reject(e);
		}
	});
	return _promise;
}

export function FacebookGetActiveVideos(broadcastId, videoId, accessToken = undefined)
{
	let _promise = new Promise(function(resolve, reject) 
	{
		var fields = {};
		fields['fields'] = "id,privacy";
		if (accessToken !== undefined)
		{
			fields['access_token'] = accessToken;
		}
		try {
			window.FB.api('/'+videoId, 'get', fields, function (response)
			{
				response.broadcastId = broadcastId;
				resolve(response);
			});
		}
		catch (e) {
			reject (e);
		}
	});
	return _promise;
}

export function FacebookGetActiveBroadcasts(objectId = 'me', accessToken = undefined)
{
	let _promise = new Promise(function(resolve, reject) {
	
		var blimit = 1000;
		var fields = {};
		fields['source'] = 'owner';
		fields['broadcast_status'] = ['LIVE','UNPUBLISHED','SCHEDULED_LIVE','SCHEDULED_UNPUBLISHED'];

		// live_encoders,broadcast_start_time,creation_time,dash_preview_url,description,embed_html,from,is_manual_mode,permalink_url,planned_start_time,seconds_left,status,title,video,blocked_users,errors";
		// removed from - stopped working with groups 4/18/2018
		// dash_preview_url - stopped working with show pages 4/18/2018
		if (objectId === 'me')
		{
			fields['fields'] = "id,from,video,status,title,description,embed_html,broadcast_start_time,creation_time,dash_preview_url,planned_start_time,permalink_url,secure_stream_url.inband_go_live(require_inband_signal),ingest_streams";
		}
		else
		{
			fields['fields'] = "id,from,video,status,title,description,embed_html,broadcast_start_time,creation_time,dash_preview_url,planned_start_time,permalink_url,secure_stream_url.inband_go_live(require_inband_signal),ingest_streams,crossposted_broadcasts{id}";
		}
		fields['order'] = "reverse_chronological";
		fields['limit'] = blimit;
		if (accessToken !== undefined)
		{
			fields['access_token'] = accessToken;
		}

		try {
			window.FB.api('/'+objectId+'/live_videos', 'get', fields, function (response)
			{
				Log.debug('FacebookGetActiveBroadcasts: objectId:' + objectId + ', fields:' + JSON.stringify(fields) + ', response:' + JSON.stringify(response));
				let videos = [];
				if (response['data'] !== undefined && response['data'].length > 0)
				{
					for(var i = 0; i < response['data'].length; i++)
					{
						let v = response['data'][i];
						v.objectId = objectId;
						if (accessToken !== undefined)
						{
							v.accessToken = accessToken;
						}
						videos.push(v);
					}
				}
				resolve(videos);
			});
		}
		catch (e) {
			reject(e);
		}
	});
	return _promise;
}

export function FacebookGetAllActiveBroadcasts(facebookUser)
{
	let _promise = new Promise(function(resolve, reject) 
	{
		var myCalls = [];

		if (facebookUser == null || facebookUser.accessToken == null || facebookUser.accessToken === '')
		{
			resolve({});
		}

		var myPromise = FacebookGetActiveBroadcasts('me',facebookUser.accessToken);
		myCalls.push(myPromise);

		for(var key in facebookUser.pageIdMap)
		{
			myPromise = FacebookGetActiveBroadcasts(key, facebookUser.pageIdMap[key].access_token);
			myCalls.push(myPromise);
		}

		Promise.all(myCalls)
		.then(function(allData) {

			let activeBroadcasts = [];
			let crosspostedBroadcasts = [];
			let activeBroadcastMap = {};
			let i;

			for(i in allData)
				activeBroadcasts.push.apply(activeBroadcasts, allData[i]);

			var myVideos = [];
				
			//for(i in activeBroadcasts)
			for(i=activeBroadcasts.length-1;i>=0;i--)
			{
				var broadcastInfo = activeBroadcasts[i];

				// remove blank broadcasts created by live/create
				if ((broadcastInfo.title === undefined || broadcastInfo.title.length <= 0) &&
					(broadcastInfo.description === undefined || broadcastInfo.description.length <= 0) &&
					(broadcastInfo.planned_start_time === undefined))
				{
					activeBroadcasts.splice(i, 1);
					continue;
				}

				// collect crossposted broadcast ids
				if (broadcastInfo['crossposted_broadcasts'] && broadcastInfo['crossposted_broadcasts']['data'])
				{
					for (var z = 0; z < broadcastInfo['crossposted_broadcasts']['data'].length; z++)
					{
						crosspostedBroadcasts.push(broadcastInfo['crossposted_broadcasts']['data'][z]);
					}
				}

				activeBroadcastMap[activeBroadcasts[i].id] = broadcastInfo;
				
				var accessToken = undefined;
				if (broadcastInfo.accessToken !== undefined)
				accessToken = broadcastInfo.accessToken;

				myPromise = FacebookGetActiveVideos(broadcastInfo.id, broadcastInfo.video.id, accessToken);
				myVideos.push(myPromise);
			}

			Promise.all(myVideos)
			.then(function(allVideoData) {

				Log.debug("FacebookGetAllActiveBroadcasts: "+JSON.stringify(allVideoData));
				for(var i in allVideoData)
				{
					activeBroadcastMap[allVideoData[i].broadcastId].privacy = allVideoData[i].privacy;
				}
				resolve({activeBroadcasts:activeBroadcasts, activeBroadcastMap:activeBroadcastMap, crosspostedBroadcasts:crosspostedBroadcasts});
			});

		});
	});
	return _promise;
}
