const VALUE_MB = (1024 * 1024);

export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function uiClearTextSelection()
{
	if (window['getSelection'])
	{
		if (window['getSelection']()['empty'])
			window['getSelection']()['empty']();
		else if (window['getSelection']()['removeAllRanges'])
			window['getSelection']()['removeAllRanges']();
	}
	else if (document['selection'])
	{
		document['selection']['empty']();
	}
}

export function getParameterByName(name, url)
{
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function formatMSDuration(durationMS)
{
	let seconds = parseInt((durationMS/1000)%60,10),
		minutes = parseInt((durationMS/(1000*60))%60,10),
		hours = parseInt((durationMS/(1000*60*60))%24,10),
		days = parseInt(durationMS/(1000*60*60*24),10),
		returnStr = '';

	let pad2 = (v) => {return v < 10 ? "0" + v : v;};

	if (days > 0)
		returnStr = days + 'd' + pad2(hours) + ':' + pad2(minutes) + ':' + pad2(seconds);
	else if (hours > 0)
		returnStr = pad2(hours) + ':' + pad2(minutes) + ':' + pad2(seconds);
	else if (minutes > 0)
		returnStr = pad2(minutes) + ':' + pad2(seconds);
	else
		returnStr = '00:' + pad2(seconds);

	return returnStr;
}

export function humanFileSize (bytes, si) 
{
	var thresh = si ? 1000 : 1024;
	if(Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}
	var units = si
		? ['kB','MB','GB','TB','PB','EB','ZB','YB']
		: ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
	var u = -1;
	do {
		bytes /= thresh;
		++u;
	} while(Math.abs(bytes) >= thresh && u < units.length - 1);
	return bytes.toFixed(1)+' '+units[u];
}

export function humanBitrate(inValueStr)
{
	var inValue = Number(inValueStr);

	if (inValue >= VALUE_MB)
	{
		return parseFloat(Math.round((inValue / VALUE_MB) * 100) / 100).toFixed(2) + ' Mbps';
	}
	else if (inValue > (1024.0))
	{
		return Math.round(inValue / (1024.0)) + ' Kbps';
	}

	return inValue + ' bps';
}

export function formatFrameRate(videoFrameRateEnum, videoFrameRateDen, videoFrameRateDivisor)
{
	let frameRate = '';

	if (videoFrameRateEnum > 0 && videoFrameRateDen > 0 && videoFrameRateDivisor > 0)
	{
		frameRate = parseFloat(videoFrameRateEnum / (videoFrameRateDen * videoFrameRateDivisor)).toFixed(2) + "";

		if (frameRate.endsWith(".00"))
			frameRate = frameRate.substr(0, frameRate.length - 3);
	}

	return frameRate;
}

