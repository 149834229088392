import { gql } from '@apollo/client'

// TODO add back   ##srtNetworkInfo## & ##audioMuted## recordingAsset ##status##
export const subscriptionBroadcastStatusChanged = gql`
  subscription broadcastStatusChanged($broadcastId: String!) {
    broadcastStatusChanged(broadcastId:$broadcastId) {
      broadcast{
        id
    		name
    		createdAt
    		updatedAt
    		previewedAt
    		liveAt
    		stoppedAt
    		status
      }
    }
  }
`
