import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';

import {
	subscriptionBroadcastStatusChanged
} from './YetiGraphQLSubscriptions';


class YetiGraphQLSubscriber
{
  constructor(connection){
    this.connection = connection;
  }

  subscribeToBroadcastState(broadcastId){

    let operation = {
			query: subscriptionBroadcastStatusChanged,
      variables:{
				broadcastId:broadcastId
      }
		};
    this.connection.subscribe(operation,{next: (message)=>{
			ClearCasterStore.dispatch({type: ActionTypes.UPDATE_BROADCAST_STATUS, broadcast:message.data.broadcastStatusChanged.broadcast})
		}, error: (error) => console.log(error)});
  }
}
export default YetiGraphQLSubscriber
