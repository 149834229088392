import React, { Component } from 'react';
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';
import FacebookDestinationType from '../../../model/FacebookDestinationType';
import FacebookPrivacyType from '../../../model/FacebookPrivacyType';
import { Form } from 'react-bootstrap';
import HelpTooltip from '../../shell/HelpTooltip';

class FacebookChooseDestination extends Component
{
	changeDestinationType (facebookData,userFacebook) {
		return (evt) => {
			let destinationType = evt.target.value;
			let newFacebookData = {...facebookData,destination:{...facebookData.destination,type:destinationType}};
			newFacebookData.ui.createNewBroadcast = true;
			if (destinationType === FacebookDestinationType.PAGE)
			{
				newFacebookData.destination.id = userFacebook.pages[0].id;
				newFacebookData.destination.name = userFacebook.pages[0].name;
				newFacebookData.destination.privacy = FacebookPrivacyType.EVERYONE;
			}
			if (destinationType === FacebookDestinationType.GROUP)
			{
				newFacebookData.destination.id = userFacebook.groups[0].id;
				newFacebookData.destination.name = userFacebook.groups[0].name;
				newFacebookData.destination.privacy = FacebookPrivacyType.EVERYONE;
			}
			if (destinationType === FacebookDestinationType.TIMELINE)
			{
				newFacebookData.destination.id = 'me';
				newFacebookData.destination.name = 'My Timeline';
				// can we get a default privacy type?
				newFacebookData.destination.privacy = FacebookPrivacyType.ALL_FRIENDS;
			}
			if (destinationType === FacebookDestinationType.TEST)
			{
				newFacebookData.destination.id = 'me';
				newFacebookData.destination.name = 'My Timeline';
				newFacebookData.destination.privacy = FacebookPrivacyType.SELF;
			}
			if (destinationType === FacebookDestinationType.UI_ACTIVE_BROADCAST)
			{
				newFacebookData.ui.createNewBroadcast = false;
			}
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData:newFacebookData
			});
		}
	}
	changeDestination (facebookData,userFacebook) {
		return (evt) => {
			let destinationId = evt.target.value;
			let newFacebookData = {...facebookData,destination:{...facebookData.destination,id:destinationId}};
			if (facebookData.destination.type === FacebookDestinationType.PAGE) {
				for (let index in userFacebook.pages) {
					if (userFacebook.pages[index].id === destinationId) {
						newFacebookData.destination.name = userFacebook.pages[index].name;
						newFacebookData.ui.createNewBroadcast = true;
						break;
					}
				}
			} else if (facebookData.destination.type === FacebookDestinationType.GROUP) {
				for (let index in userFacebook.groups) {
					if (userFacebook.groups[index].id === destinationId) {
						newFacebookData.destination.name = userFacebook.groups[index].name;
						newFacebookData.ui.createNewBroadcast = true;
						break;
					}
				}
			}
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData:newFacebookData
			});
		}
	}
	chooseActiveBroadcast (facebookData,userFacebook,facebookActiveBroadcasts) {
		return (evt) => {
			let destinationId = evt.target.value;
			let destination = facebookActiveBroadcasts.activeBroadcastMap[destinationId];
			let newFacebookData = {...facebookData};
			newFacebookData.broadcast.id = destinationId;
			newFacebookData.broadcast.title = destination.title;
			newFacebookData.broadcast.description = destination.description;
			newFacebookData.broadcast.dash_preview_url = destination.dash_preview_url;
			newFacebookData.broadcast.overlay_url = destination.overlay_url != null ? destination.overlay_url : '';
			newFacebookData.broadcast.permalink_url = destination.permalink_url;
			newFacebookData.broadcast.planned_start_time = destination.planned_start_time != null ? destination.planned_start_time : '';
			newFacebookData.video.id = destination.video.id;
			if (destination.from != null && destination.from.id != null && Object.keys(userFacebook.pageIdMap).includes(destination.from.id))
			{
				let d = userFacebook.pageIdMap[destination.from.id];
				newFacebookData.destination.type = FacebookDestinationType.PAGE;
				newFacebookData.destination.id = d.id;
				newFacebookData.destination.name = d.name;
				newFacebookData.destination.privacy = destination.privacy.value;
			}
			else
			{
				newFacebookData.destination.id = 'me';
				newFacebookData.destination.name = 'Timeline, Group, or Event';
				newFacebookData.destination.privacy = destination.privacy.value;
			}
			console.log(newFacebookData);
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData:newFacebookData
			});
		}
	}
	changePrivacy (facebookData) {
		return (evt) => {
			let privacy = evt.target.value;
			let newFacebookData = {...facebookData,destination:{...facebookData.destination,privacy:privacy}};
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData:newFacebookData
			});
		}
	}
	hasValidData (facebookData,userFacebook)
	{
		let validData = true;
		if (facebookData.destination.type != null)
		{
			if (facebookData.destination.type === 'page')
			{
				let foundPage = false;
				for (let i = 0; i < userFacebook.pages.length; i++)
				{
					if (facebookData.destination.id === userFacebook.pages[i].id)
					{
						foundPage = true;
						break;
					}
				}
				if (!foundPage) validData = false;
			}
			if (facebookData.destination.type === 'group')
			{
				let foundGroup = false;
				for (let i = 0; i < userFacebook.groups.length; i++)
				{
					if (facebookData.destination.id === userFacebook.groups[i].id)
					{
						foundGroup = true;
						break;
					}
				}
				if (!foundGroup) validData = false;
			}
		}
		return validData;
	}
	render()
	{
		let userFacebook = this.props.userFacebook;
		let facebookData = this.props.facebookData;
		let isPrimary = !(this.props.facebookData.isPrimary === 'false');
		let facebookActiveBroadcasts = this.props.facebookActiveBroadcasts;
		let hasActiveBroadcasts = facebookActiveBroadcasts.activeBroadcasts && facebookActiveBroadcasts.activeBroadcasts.length > 0;

		if (!this.hasValidData(facebookData,userFacebook))
		{
			facebookData.destination.type = '';
			facebookData.destination.id ='';
		}

		return (
			<>
				<Form.Group controlId="destinationType">
					<Form.Label>Destination</Form.Label>
					<Form.Control as="select" value={ facebookData.destination.type } onChange={ this.changeDestinationType(facebookData,userFacebook) } aria-describedby="destinationTypeFeedback" 
						className={(!isPrimary && !hasActiveBroadcasts) ? "is-invalid" : ""}>
						<option value="">Choose a Destination</option>
						{ hasActiveBroadcasts &&
							<option value={FacebookDestinationType.UI_ACTIVE_BROADCAST}>Use an Active Broadcast</option>
						}
						{ isPrimary && (userFacebook.pages && userFacebook.pages.length > 0) &&
							<option value={FacebookDestinationType.PAGE}>Share on a Page you manage</option>
						}
						{ isPrimary && 
							<option value={FacebookDestinationType.TIMELINE}>Share on your Timeline</option>
						}
						{ isPrimary && (userFacebook.groups && userFacebook.groups.length > 0) &&
							<option value={FacebookDestinationType.GROUP}>Share in a Group</option>
						}
						{ isPrimary &&
							<option value={FacebookDestinationType.TEST}>Test Broadcast</option>
						}
					</Form.Control>
					{ !isPrimary && !hasActiveBroadcasts &&
						<div id="destinationTypeFeedback" className="invalid-feedback">No active broadcasts found.</div>
					}
				</Form.Group>
				{ facebookData.destination.type === FacebookDestinationType.PAGE &&
					<>
						<Form.Group controlId="destinationId">
							<Form.Label>Page</Form.Label>
							<Form.Control as="select" value={ facebookData.destination.id } onChange={ this.changeDestination(facebookData,userFacebook) }>
								{ userFacebook.pages.map((page, key)=>{ return (
									<option key={key} value={page.id}>{page.name}</option>
								)}) }
							</Form.Control>
						</Form.Group>
						<Form.Group>
							<Form.Label>Who can see this live stream?</Form.Label>
							<Form.Control as="select" disabled={true} value={ facebookData.destination.privacy } style={{cursor:"not-allowed"}}>
								<option value={ facebookData.destination.privacy }>Public</option>
							</Form.Control>
						</Form.Group>
					</>
				}
				{ facebookData.destination.type === FacebookDestinationType.GROUP &&
				<Form.Group controlId="destinationId">
					<Form.Label>Group</Form.Label>
					<Form.Control as="select" value={ facebookData.destination.id } onChange={ this.changeDestination(facebookData,userFacebook) }>
						{ userFacebook.groups.map((group, key)=>{ return (
							<option key={key} value={group.id}>{group.name}</option>
						)}) }
					</Form.Control>
				</Form.Group>
				}
				{ facebookData.destination.type === FacebookDestinationType.TIMELINE &&
				<Form.Group controlId="privacy">
					<Form.Label>Who can see this live stream?
						<HelpTooltip className="ml-1" placement="right">If you select a setting that is more public than your ClearCaster device's privacy ceiling, the privacy ceiling is used instead.</HelpTooltip>
					</Form.Label>
					<Form.Control as="select" value={ facebookData.destination.privacy } onChange={ this.changePrivacy(facebookData) }>
						<option value={FacebookPrivacyType.EVERYONE}>Public</option>
						<option value={FacebookPrivacyType.FRIENDS_OF_FRIENDS}>Friends of Friends</option>
						<option value={FacebookPrivacyType.ALL_FRIENDS}>Friends</option>
						<option value={FacebookPrivacyType.SELF}>Only Me</option>
					</Form.Control>
					<a href="https://www.wowza.com/docs/wowza-clearcaster-how-to-change-the-facebook-privacy-settings" target="_blank" rel="noopener noreferrer">How to change the Facebook privacy ceiling <i className="fa fa-external-link"></i></a>
				</Form.Group>
				}
				{ facebookData.destination.type === FacebookDestinationType.TEST &&
				<Form.Group controlId="privacy">
					<Form.Label>Who can see this live stream?</Form.Label>
					<Form.Control as="select" value={ FacebookPrivacyType.SELF } disabled={true} style={{cursor:"not-allowed"}}>
						<option value={FacebookPrivacyType.SELF}>Only Me</option>
					</Form.Control>
				</Form.Group>
				}
				{ hasActiveBroadcasts && facebookData.destination.type === FacebookDestinationType.UI_ACTIVE_BROADCAST &&
				<>
					<Form.Group controlId="destinationId">
						<Form.Label>Active Broadcast</Form.Label>
						<Form.Control as="select" value={ facebookData.broadcast.id } onChange={ this.chooseActiveBroadcast(facebookData,userFacebook,facebookActiveBroadcasts) }>
							<option value={''}>Choose an Active Broadcast:</option>
							{ hasActiveBroadcasts && facebookActiveBroadcasts.activeBroadcasts.map((b, key)=>{ return (
								<option key={key} value={b.id}>{b.title} {(b.from != null && b.from.id !== userFacebook.id )? '('+b.from.name+')' : ''} [{b.id}]</option>
							)}) }
						</Form.Control>
					</Form.Group>
				</>
				}
			</>
		);
	}
}
export default FacebookChooseDestination;
