import { mixObjects } from './UniversalAdminUtils';
import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';

class SingularLiveGraphicsController
{
	static getDefaultData()
	{
		return {
			"name":"",
			"slate1":{
				"enabled":true,
				"compositions":[{
					"compositionName": "SlateSolidBackground",
					"controlNode": {
						"payload": SingularLiveGraphicsController.getDefaultPayload("SlateSolidBackground")
					}
				}]
			},
			"slate2":{
				"enabled":true,
				"compositions":[{
					"compositionName": "Slate2SolidBackground",
					"controlNode": {
						"payload": SingularLiveGraphicsController.getDefaultPayload("Slate2SolidBackground")
					}
				}]
			},
			"lower3rd":{
				"enabled":true,
				"compositions":[{
					"compositionName": "Lower3rd1Line",
					"controlNode": {
						"payload": SingularLiveGraphicsController.getDefaultPayload("Lower3rd1Line")
					}
				}]
			},
			"logo":{
				"enabled":true,
				"compositions":[{
					"compositionName": "LogoImage",
					"controlNode": {
						"payload": SingularLiveGraphicsController.getDefaultPayload("LogoImage")
					}
				}]
			}
		};
	}
	static getDefaultPayload(compositionName)
	{
		switch(compositionName)
		{
			case "SlateSolidBackground":
			case "Slate2SolidBackground":
				return {
					"TitleText": "",
					"TitleTextColor": "#ffffff",
					"SubTitleText": "",
					"SubTitleTextColor": "#ffffff",
					"BackgroundBevel": "#FF8400",
					"BackgroundBase": "#444648"
				};
			case "SlateImageBackground":
			case "Slate2ImageBackground":
				return {
					"BackgroundImage": "",
					"TitleText": "",
					"TitleTextColor": "#ffffff",
					"SubTitleText": "",
					"SubTitleTextColor":"#ffffff",
					"BackgroundBase": "#cccccc"
				};
			case "CountdownClock":
				return {
					"BeginHours": 0,
					"BeginMinutes": 12,
					"BeginSeconds": 0,
					"EndHours": 0,
					"EndMinutes": 0,
					"EndSeconds": 0,
					"Format": "mm:ss",
					"LeadingZeros": false,
					"TextColor": "#ffffff",
					"Control": { "command": "reset" }
				};
			case "LogoImage":
				return {
					"Image": "",
					"Size": 50,
					"PositionX": 0,
					"PositionY": 0,
					"Transparency": 50
				};
			case "Lower3rd1Line":
				return {
					"Text": "",
					"TextColor": "#FFFFFF",
					"Base": "#FF8400",
					"Bevel": "#FFFFFF"
				};
			default:
				return {};
		}
	}
	static getData(singularLiveData,appInstanceId,returnNull)
	{
		if (singularLiveData.data != null && singularLiveData.data[appInstanceId] != null)
		{
			// Make a copy
			return JSON.parse(JSON.stringify(singularLiveData.data[appInstanceId]));
		}
		return returnNull != null && returnNull ? null : SingularLiveGraphicsController.getDefaultData();
	}

	static saveData(singularLiveData,appInstanceId,data)
	{
		let tmpObj = {};
		tmpObj[appInstanceId] = data;
		let dataObj = mixObjects(singularLiveData.data,tmpObj);
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_SINGULAR_LIVE_DATA,
			data:dataObj
		});
	}

	static async persistData(controller,appInstanceId,data)
	{
		await controller.persistGraphicsData(appInstanceId,data);
	}

	static getAppInstanceById(singularLiveData,id)
	{
		if (singularLiveData.appInstances != null &&
			singularLiveData.appInstances.instances != null)
		{
			return singularLiveData.appInstances.instances[id];
		}
		return undefined;
	}

	static getName(singularLiveData,appInstance)
	{
		let appInstanceData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
		if (appInstanceData.name == null || appInstanceData.name === "" || appInstanceData.name.indexOf("##graphic") === 0)
		{
			return "Package " + (singularLiveData.appInstances.order.indexOf(appInstance.id) + 1);
		}
		return appInstanceData.name;
	}

	static getApiUrl(appInstance)
	{
		return 'https://app.singular.live/apiv1/control/' + appInstance.definition.access_token;
	}

	static isShown(singularLiveData,appInstanceId)
	{
		if (singularLiveData.showing != null && singularLiveData.showing.hasOwnProperty(appInstanceId))
		{
			return singularLiveData.showing[appInstanceId];
		}
		return false;
	}

	static async getAppInstanceShownByAPI(appInstance)
	{
		let apiUrl = 'https://app.singular.live/apiv1/control/'+appInstance.definition.access_token+'/subcompositions';
		let defaultData = SingularLiveGraphicsController.getDefaultData();
		let showing = {};

		try
		{
			let response = await fetch(apiUrl, {
				method: 'GET',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json'
				}
			});

			let responseJson = await response.json();

			if(responseJson.data != null && responseJson.data.length > 0)
			{
				for(let i = 0; i < responseJson.data.length; i++)
				{
					if (responseJson.data[i]['name'] != null && responseJson.data[i]['state'] != null)
					{
						for(let key in defaultData)
						{
							if (defaultData[key].compositions != null)
							{
								if(responseJson.data[i]['name'] === defaultData[key].compositions[0].compositionName)
								{
									showing[key] = (responseJson.data[i]['state'] === "In");
								}
							}
						}
					}
				}
				return (showing);
			}
		}
		catch(e)
		{}
		return false;
	}

	static async getAppInstanceShownById(singularLiveData,appInstanceId)
	{
		let appInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,appInstanceId);
		let showing = await this.getAppInstanceShownByAPI(appInstance);
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_SINGULAR_LIVE_SHOWING,
			appInstanceId:appInstanceId,
			showing:showing
		});
	}

	static async showGraphic(appInstance,graphic,compositionData)
	{
		let hasCountdownClock = false;
		let url = SingularLiveGraphicsController.getApiUrl(appInstance);
		if (url !== null)
		{
			let data = [mixObjects(compositionData[graphic].compositions[0],{
				"animation":{
					"action":"play",
					"from":"Out1",
					"to":"In"
				}
			})];
			for (let i = 1; i < compositionData[graphic].compositions.length; i++)
			{
				let composition = compositionData[graphic].compositions[i];
				if (composition.compositionName.indexOf('CountdownClock') === 0)
				{
					hasCountdownClock = true;
					data.push(mixObjects(composition, {
						"animation": {
							"action": "play",
							"from": "Out1",
							"to": "In"
						}
					}));
					break;
				}
			}
			await fetch(url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});
			if (hasCountdownClock)
			{
				let data2 = [{
					"compositionName": "CountdownClock",
					"controlNode": {
						"payload": {
							"Control": { "command": "play" }
						}
					}
				}];
				await fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(data2)
				});
			}
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_SINGULAR_LIVE_SHOWING,
				appInstanceId:appInstance.id,
				graphic:graphic,
				showing:true
			});
		}
	}
	static async hideAllGraphics(appInstance,compositionData)
	{
		for (let graphic in compositionData)
		{
			await SingularLiveGraphicsController.hideGraphic(appInstance,graphic,compositionData);
		}
	}
	static async hideGraphic(appInstance,graphic,compositionData)
	{
		let hasCountdownClock = false;
		let url = SingularLiveGraphicsController.getApiUrl(appInstance);
		let data = [];
		if (url !== null && compositionData[graphic] != null && compositionData[graphic].compositions != null)
		{
			for (let i = 0; i < compositionData[graphic].compositions.length; i++)
			{
				if (compositionData[graphic].compositions[i].compositionName.indexOf("CountdownClock") === 0)
					hasCountdownClock = true;
				data.push(mixObjects(compositionData[graphic].compositions[i],{
					"animation":{
						"action":"play",
						"from":"In",
						"to":"Out1"
					}
				}));
			}
			if (!hasCountdownClock && graphic.indexOf("slate") === 0)
			{
				data.push({
					"compositionName":"CountdownClock",
					"animation": {
						"action": "play",
						"from": "In",
						"to": "Out1"
					}
				});
			}
			await fetch(url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_SINGULAR_LIVE_SHOWING,
				appInstanceId:appInstance.id,
				graphic:graphic,
				showing:false
			});
		}
	}
}
export default SingularLiveGraphicsController;