import React, { Component } from 'react';
import {isAspectRatioVertical} from '../../../model/FacebookEncodingSettingsAspectRatio'
import Log from '../../../log/Log';
import './FacebookBroadcastPreview.css';

const uuidv4 = require('uuid/v4');

const phase = {
    'PLAYER_NOT_READY':'PLAYER_NOT_READY',
    'LOADING':'LOADING',
    'PLAYER_READY':'PLAYER_READY'
};

class FacebookBroadcastPreview extends Component
{
  constructor(props)
  {
      super(props);

      this.loadManifestTimer = null;

      this.state = {
          playerName:'previewplayer',
          phase:phase.PLAYER_NOT_READY,
          playbackStopDetectTimeout:null
      };

      this.startPreviewPlayer = this.startPreviewPlayer.bind(this);
      this.stopPreviewPlayer = this.stopPreviewPlayer.bind(this);
      this.loadPreviewManifest = this.loadPreviewManifest.bind(this);
  }


  componentDidUpdate() {
    if (this.props.broadcast === null || this.props.broadcast === undefined)
    {
        this.stopPreviewPlayer();
    } else if ((this.state.phase === phase.PLAYER_NOT_READY) && (this.loadManifestTimer === null)) {
        this.loadManifestTimer = 0;
        let dashPreviewUrl = this.props.broadcast.extraProperties.find(prop => prop.name === "facebook.broadcast.dash_preview_url").value
        if(dashPreviewUrl)
          this.loadPreviewManifestTimer(dashPreviewUrl);
    }
  }

  componentDidMount()
  {
      let uniqueId = uuidv4();
      this.setState({playerName:'player_'+uniqueId});
  }

  componentWillUnmount()
  {
      if (this.state.playbackStopDetectTimeout !== null)
      {
          clearTimeout(this.state.playbackStopDetectTimeout);
      }
      this.stopPreviewPlayer();
  }

  loadPreviewManifestTimer(dashPreviewUrl)
  {
      let _this = this;
      this.loadManifestTimer = null;

      this.loadPreviewManifest(dashPreviewUrl, (isManifestReady) =>
      {
          if (isManifestReady)
          {
              _this.startPreviewPlayer(dashPreviewUrl);
          }
          else
          {
              _this.loadManifestTimer = setTimeout(() => {_this.loadPreviewManifestTimer(dashPreviewUrl);}, 1000);
          }
      });
  }

  startPreviewPlayer(manifestURL, attempt = 0)
  {
      let _this = this;
      // this.setState({uuid:uuidv4()});
      if (!window.videojs)
      {
          let _this = this;
          if (attempt < 5)
          {
              setTimeout(() => {
                  _this.startPreviewPlayer(manifestURL, 1);
              }, 2000);
          }
          return;
      }


    // <!-- https://github.com/videojs/video.js/blob/master/docs/guides/options.md -->
    // <!-- http://cdn.dashjs.org/latest/jsdoc/module-MediaPlayer.html -->

    var playerOptions = {
          html5: {
              dash: {
                  setManifestLoaderRetryAttempts: 100,
                  setManifestLoaderRetryInterval: 1000
              }
          },
          fluid:true,
          controlBar: {
              playToggle:false,
              progressControl:false,
              fullscreenToggle:false,
              remainingTimeDisplay:false,
              volumeMenuButton: {
                    inline: true,
                    vertical: false
              }
          }
      };

      try
      {
          let previewPlayerPlayer = window.videojs(_this.state.playerName, playerOptions);

          previewPlayerPlayer.ready(function() {

              _this.setState({
                  phase:phase.PLAYER_READY
              });

              previewPlayerPlayer.play();
              previewPlayerPlayer.src({
                  src: manifestURL,
                  type: 'application/dash+xml'
              });
          });

          previewPlayerPlayer.on('timeupdate',function (e) {
              if (_this.state.bufferDetectTimeout !== null)
              {
                  clearTimeout(_this.state.playbackStopDetectTimeout);
              }
              let newPlaybackStopDetectTimeout = setTimeout(function () {
                  Log.error(this.tag+' playback problem detected, resetting player');
                  if (_this.props.onReset)
                  {
                      _this.props.onReset(null);
                  }
              },5000);
              _this.setState({playbackStopDetectTimeout:newPlaybackStopDetectTimeout});
          });

      }
      catch(e)
      {}
  }

  stopPreviewPlayer()
  {
    this.setState({
        phase:phase.PLAYER_NOT_READY
    });
    if (this.loadManifestTimer !== null)
    {
        clearTimeout(this.loadManifestTimer);
        this.loadManifestTimer = null;
    }
    let previewPlayerPlayer = window.videojs.getPlayers()[this.state.playerName];
    if (previewPlayerPlayer)
    {
      previewPlayerPlayer.dispose();
    }
  }

  loadPreviewManifest(manifestURL, responseFunction)
  {
      this.setState({
          phase:phase.LOADING
      });
      // let _this = this;

    var xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function() {
      if (this.readyState === 4) // DONE
      {
        if (this.status >= 200 && this.status < 300)
        {
          myFunction(this.responseText);
        }
        else
        {
          responseFunction(false);
        }
      }
    };

    xhttp.open("GET", manifestURL, true);
    xhttp.send();

    function myFunction(xmlStr)
    {
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(xmlStr, "text/xml");

      var numSegments = [];

      //MPD/Period/AdaptationSet/Representation/SegmentTemplate/SegmentTimeline

      var mpds = xmlDoc.getElementsByTagName("MPD");
      for(var mpd=0;mpd<mpds.length;mpd++)
      {
        var periods = mpds[mpd].getElementsByTagName("Period");
        for(var period=0;period<periods.length;period++)
        {
          var adaptionSets = periods[period].getElementsByTagName("AdaptationSet");
          for(var adaptionSet=0;adaptionSet<adaptionSets.length;adaptionSet++)
          {
            var representations = adaptionSets[adaptionSet].getElementsByTagName("Representation");
            for(var representation=0;representation<representations.length;representation++)
            {
              var segmentTemplates = representations[representation].getElementsByTagName("SegmentTemplate");
              for(var segmentTemplate=0;segmentTemplate<segmentTemplates.length;segmentTemplate++)
              {
                var segmentTimelines = segmentTemplates[segmentTemplate].getElementsByTagName("SegmentTimeline");
                for(var segmentTimeline=0;segmentTimeline<segmentTimelines.length;segmentTimeline++)
                {
                  var segmentEntries = segmentTimelines[segmentTimeline].getElementsByTagName("S");

                  numSegments[adaptionSet] = segmentEntries.length;

                }
              }
            }
          }
        }
      }

      var isManifestReady = false;

      if (numSegments.length >= 2 && numSegments[0] >=2 && numSegments[1] >= 2)
      {
        isManifestReady = true;
      }

      responseFunction(isManifestReady);
    }
  }


  render()
  {
      let isVertical = false;
      if (this.props.broadcast.input && isAspectRatioVertical(this.props.broadcast.input))
      {
          isVertical = true;
      }

      return (
          <div className={ isVertical ? "go-live-preview vertical" : "go-live-preview" }>
              { this.state.phase !== 'PLAYER_READY' && (
                  <div className="loading-shell">
                      <div className="loading-inner">
                          <div className="loading-flex">
                              <img src="/images/bars.svg" width="40" alt=""/>
                          </div>
                      </div>
                  </div>
              )}
              { this.state.phase !== 'PLAYER_NOT_READY' && (
                  <video id={this.state.playerName} className="video-js vjs-fluid" controls muted></video>
              )}
          </div>
      );
  }
}

export default FacebookBroadcastPreview;
