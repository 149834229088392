module.exports = {
	"name": "Store All Broadcast Configuration",
	"id": "example-config-storeall",
	"type": "full",
	"presentation": {
		"layout": "SingleOutput",
		"widgets": [
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					}
				}
			}
		]
	},
	"template": {
		"input": {
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": 1920,
			"videoFrameHeightMax": 1080,
			"videoFrameRateMax": 30,
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition0",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "rtmp://[serverurl]:1935/myApplication",
						"streamName": "myStream"
					}
				],
				"encodingConfiguration": {
					"name": "rendition0",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "x264",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1920,
						"frameSizeHeight": 1080,
						"profile": "main",
						"bitrate": "5m",
						"bitrateMin": "1m",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "x264.preset",
								"value": "3",
								"type": "Long"
							},
							{
								"name": "x264.ref",
								"value": "1",
								"type": "Long"
							},
							{
								"name": "x264.bframes",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "128k",
						"parameters": []
					}
				}
			}
		]
	}
};