import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import SingularLiveGraphicsController from '../../../../controller/SingularLiveGraphicsController';
import {
	showSlate,
	formChange,
	isDataDirty,
	persistData,
	graphics_componentDidMount,
	cancel
} from './ManageGraphicsUtils';
import LoadingOverlay from 'react-loading-overlay';
import Slider from 'react-input-slider';


class ManageGraphicsLogo extends Component
{
	constructor (props)
	{
		super(props);
		this.state = {
			positionX:0,
			positionY:0,
			size:50,
			transparency:50
		}
	}

	componentDidMount()
	{
		graphics_componentDidMount(this.props.clearcaster.singularLiveData,this.props.appInstance,this.props.graphic);
	}

	static dPadClick(x,y,singularLiveData,compositionData,appInstance,previewAppInstance,graphic)
	{
		return (evt) => {
			showSlate(previewAppInstance,
				graphic,
				formChange(singularLiveData,previewAppInstance,appInstance,graphic,compositionData,{
					PositionX: x,
					PositionY: y
				}),
				false)(evt);
		}
	}

	render()
	{
		let singularLiveData = this.props.clearcaster.singularLiveData;
		let appInstance = this.props.appInstance;
		let previewAppInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,appInstance.preview);
		let pageState = SingularLiveGraphicsController.getData(singularLiveData,"manage");
		if (pageState == null)
		{
			return;
		}
		let compositionData = SingularLiveGraphicsController.getData(singularLiveData,previewAppInstance.id);
		if (compositionData == null)
		{
			compositionData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
		}
		if (compositionData == null)
		{
			compositionData = SingularLiveGraphicsController.getDefaultData();
		}

		let logoData = compositionData[this.props.graphic].compositions[0].controlNode.payload;
		if (logoData == null)
		{
			return (
				<div>{this.props.strings.app.ManageGraphicsNoDataFound}</div>
			);
		}

		let previewUrl = previewAppInstance.definition.onair_url;

		let isDirty = isDataDirty(singularLiveData,appInstance.id,logoData);

		return (
			<Modal size="lg" show={ true } onHide={cancel(singularLiveData,previewAppInstance)} className="manage-graphics-bug">

				<LoadingOverlay active={ pageState.saving } spinner text={ this.props.strings.app.ManageGraphicsSaving } >
					<Modal.Header closeButton>
						<Modal.Title>{this.props.strings.app.ManageGraphicsEditLogo}</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<div className="row">
							<div className="col-lg-4">
								<Form>
									<div className="integration-heading">{this.props.strings.app.ManageGraphicsEditGraphics}</div>

									<Form.Group controlId="formBugImage">
										<Form.Label>
											{this.props.strings.app.ManageGraphicsImageURL}
										</Form.Label>
										<Form.Control type="url"
													  value={logoData.Image}
													  onChange={evt => formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{ Image: evt.target.value })}
													  onBlur={showSlate(previewAppInstance,this.props.graphic,compositionData)}
										/>
									</Form.Group>

									<Form.Group controlId={"formBugLocation"}>
										<Form.Label>
											{this.props.strings.app.ManageGraphicsLocation}
										</Form.Label>
										<div className="d-flex align-items-center">
											<div className="col-3 p-0 text-center">
												<Button size="xs" variant="light" className="border-0 p-0 pl-1 pr-1 m-1" onClick={
													ManageGraphicsLogo.dPadClick(-35,-40,singularLiveData,compositionData,appInstance,previewAppInstance,this.props.graphic)
												}><i className="fa fa-arrow-up" style={{transform:"rotate(315deg)"}}></i></Button>
												<Button size="xs" variant="light" className="border-0 p-0 pl-1 pr-1 m-1" onClick={
													ManageGraphicsLogo.dPadClick(35,-40,singularLiveData,compositionData,appInstance,previewAppInstance,this.props.graphic)
												}><i className="fa fa-arrow-up" style={{transform:"rotate(45deg)"}}></i></Button><br />
												<Button size="xs" variant="light" className="border-0 p-0 m-1" onClick={
													ManageGraphicsLogo.dPadClick(-0,-0,singularLiveData,compositionData,appInstance,previewAppInstance,this.props.graphic)
												}><i className="fa fa-circle"></i></Button><br />
												<Button size="xs" variant="light" className="border-0 p-0 pl-1 pr-1 m-1" onClick={
													ManageGraphicsLogo.dPadClick(-35,40,singularLiveData,compositionData,appInstance,previewAppInstance,this.props.graphic)
												}><i className="fa fa-arrow-up" style={{transform:"rotate(225deg)"}}></i></Button>
												<Button size="xs" variant="light" className="border-0 p-0 pl-1 pr-1 m-1" onClick={
													ManageGraphicsLogo.dPadClick(35,40,singularLiveData,compositionData,appInstance,previewAppInstance,this.props.graphic)
												}><i className="fa fa-arrow-up" style={{transform:"rotate(135deg)"}}></i></Button>
											</div>
											<div className="col-9 pl-0">
												<div className="d-flex align-items-center">
													<div className="col-2">X:</div>
													<Slider
														className="col-10"
														styles={{
															track: {
																backgroundColor: '#F0F0F0'
															},
															thumb: {
																backgroundColor: '#7DC8FE',
																boxShadow: 'none'
															},
															active: {
																backgroundColor: '#F0F0F0'
															}
														}}
														axis="x"
														xmin={-50}
														xmax={50}
														x={logoData.PositionX}
														onChange={({x})=>{
															formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{PositionX:x})
														}}
														onDragEnd={
															showSlate(previewAppInstance,this.props.graphic,compositionData,false)
														}
													/>
												</div>
												<div className="d-flex align-items-center">
													<div className="col-2">Y:</div>
													<Slider
														className="col-10"
														styles={{
															track: {
																backgroundColor: '#F0F0F0'
															},
															thumb: {
																backgroundColor: '#7DC8FE',
																boxShadow: 'none'
															},
															active: {
																backgroundColor: '#F0F0F0'
															}
														}}
														axis="x"
														xmin={-50}
														xmax={50}
														x={logoData.PositionY}
														onChange={({x})=>{
															formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{PositionY:x})
														}}
														onDragEnd={
															showSlate(previewAppInstance,this.props.graphic,compositionData,false)
														}
													/>
												</div>
											</div>
										</div>
									</Form.Group>

									<Form.Group controlId={"formBugSize"}>
										<Form.Label>
											{this.props.strings.app.ManageGraphicsSize}
										</Form.Label>
										<div>
											<Slider
												className="w-100"
												styles={{
													track: {
														backgroundColor: '#F0F0F0'
													},
													thumb: {
														backgroundColor: '#7DC8FE',
														boxShadow: 'none'
													},
													active: {
														backgroundColor: '#F0F0F0'
													}
												}}
												axis="x"
												xmin={0}
												xmax={100}
												x={logoData.Size}
												onChange={({x})=>{
													formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{Size:x})
												}}
												onDragEnd={
													showSlate(previewAppInstance,this.props.graphic,compositionData,false)
												}
											/>
										</div>
									</Form.Group>

									<Form.Group controlId={"formBugTransparency"}>
										<Form.Label>
											{this.props.strings.app.ManageGraphicsTransparency}
										</Form.Label>
										<div>
											<Slider
												className="w-100"
												styles={{
													track: {
														backgroundColor: '#F0F0F0'
													},
													thumb: {
														backgroundColor: '#7DC8FE',
														boxShadow: 'none'
													},
													active: {
														backgroundColor: '#F0F0F0'
													}
												}}
												axis="x"
												xmin={0}
												xmax={100}
												x={logoData.Transparency}
												onChange={({x})=>{
													formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{Transparency:x})
												}}
												onDragEnd={
													showSlate(previewAppInstance,this.props.graphic,compositionData,false)
												}
											/>
										</div>
									</Form.Group>

								</Form>
							</div>
							<div className="col-lg-8">
								{ previewUrl !== null &&
								<>
									<div className="integration-heading">{this.props.strings.app.ManageGraphicsPreview}</div>
									<div style={{position:"relative",overflow:"hidden",height:"0",paddingTop:"56.25%",backgroundColor:"#000",border:"0"}}>
										<iframe title="preview graphics" style={{position:"absolute",top:"0",left:"0",height:"100%",width:"100%",border:"0"}} src={previewUrl}></iframe>
									</div>
								</>
								}
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className="w-100 mb-2" onClick={cancel(singularLiveData,previewAppInstance)} variant="secondary"><i className="fa fa-ban mr-2"></i>Cancel</Button>
						<Button className="w-100 mb-2" disabled={!isDirty} onClick={persistData(this.props.controller,previewAppInstance,appInstance,singularLiveData)} variant={isDirty?"primary":"outline-secondary"}><i className="fa fa-floppy-o mr-2"></i>Save Graphics</Button>
					</Modal.Footer>
				</LoadingOverlay>
			</Modal>
		);
	}

}
export default ManageGraphicsLogo;