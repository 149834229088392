module.exports = {
  "name": "Facebook Paired 1080p60 Vertical 16:9 Rotate Right",
  "type": "full",
  "isFacebookPaired": true,
  "presentation": {
    "layout": "SingleOutput",
    "widgets": [
      {
        "widget": "OutputDetails",
        "parameters": {
          "output": {
            "outputTag": "output0"
          }
        }
      },
      {
        "widget": "CheckboxSimple",
        "label": "Primary ingest stream",
        "parameters": {
          "value": {
            "variable": "$isPrimary",
            "defaultValue": true,
            "required": true
          }
        }
      },
      {
        "widget": "CheckboxSimple",
        "label": "Optimize path to Facebook Live",
        "parameters": {
          "value": {
            "variable": "$doSpeedTest",
            "defaultValue": true,
            "required": true
          }
        }
      },
      {
        "widget": "SelectCustom",
        "label": "Count Down Timer Steps",
        "options": {
          "3": {
            "numberOfSteps": 3
          },
          "5": {
            "numberOfSteps": 5
          },
          "15": {
            "numberOfSteps": 15
          }
        },
        "defaultValue": 0,
        "parameters": {
          "numberOfSteps": {
            "variable": "$numberOfSteps",
            "units": "INTEGER"
          }
        }
      },
      {
        "widget": "CheckboxSimple",
        "label": "Record Broadcast",
        "parameters": {
          "value": {
            "variable": "$record",
            "defaultValue": false,
            "required": true
          }
        }
      }
    ]
  },
  "template": {
    "input": {
      "inputType": "CAPTURE_HARDWARE",
      "videoFrameWidthMax": 1080,
      "videoFrameHeightMax": 1920,
      "videoFrameRateMax": 60,
      "videoKeyFrameIntervalMilliseconds": 2000,
      "videoAspectRatioMode":3,
      "videoAspectRatioMethod":0,
      "videoAspectRatioWidth":9,
      "videoAspectRatioHeight":16,
      "videoAspectRatioRotation":1.57
    },
    "outputs": [
      {
        "tag": "output0",
        "streamName": "rendition0",
        "record": "$record",
        "streamTargets": [
          {
            "tag": "output0_streamTarget0",
            "type": "FB_PAIRED_STREAM_TARGET",
            "protocol": "RTMP",
            "streamTargetEncoderIndex": 0,
            "url": ""
          }
        ],
        "encodingConfiguration": {
          "name": "rendition0",
          "encodingConfigurationVideo": {
            "codec": "H.264",
            "implementation": "x264",
            "frameSizeFitMode": "stretch",
            "frameSizeWidth": 1080,
            "frameSizeHeight": 1920,
            "profile": "high",
            "bitrate": "14m",
            "bitrateMin": "1m",
            "autoAdjustBitrate": true,
            "keyFrameIntervalFollowSource": true,
            "parameters": [
              {
                "name": "x264.preset",
                "value": "2",
                "type": "Long"
              },
              {
                "name": "x264.ref",
                "value": "1",
                "type": "Long"
              },
              {
                "name": "x264.bframes",
                "value": "1",
                "type": "Long"
              }
            ]
          },
          "encodingConfigurationAudio": {
            "codec": "aac",
            "bitrate": "128k"
          }
        }
      }
    ],
    "extraProperties": [
      {
        "name": "facebook.isPrimary",
        "value": "$isPrimary",
        "type": "Boolean"
      },
      {
        "name": "facebook.doSpeedTest",
        "value": "$doSpeedTest",
        "type": "Boolean"
      },
      {
        "name": "CountdownTimer.NumberOfSteps",
        "value": "$numberOfSteps",
        "type": "Int"
      },
      {
        "name": "CountdownTimer.TimePerStep",
        "value": "1000",
        "type": "Int"
      }
    ]
  }
};