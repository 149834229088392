import React, { Component } from 'react';

import {Form, Button, Modal, InputGroup, FormControl} from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

import { getTemplateCategoryListV2 } from '../../../templates/TemplateUtils.js';

const uuidv1 = require('uuid/v1');

class ManageDestinationPickerDialog extends Component
{
	static getDefaultState()
	{
		return {
			createTemplateName:"",
			templateCategory: undefined
		};
	}

	constructor() {
		
		super();

		this.state = ManageDestinationPickerDialog.getDefaultState();

		this.templateSet = "pro";

		this.handleClose = this.handleClose.bind(this);
		this.onShow = this.onShow.bind(this);
		this.onEnter = this.onEnter.bind(this);
		this.handleEditACopy = this.handleEditACopy.bind(this);
		this.handleAddNow = this.handleAddNow.bind(this);

	}

	onShow()
	{

	}

	onEnter()
	{
		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategoryList = getTemplateCategoryListV2(templateInfo, this.templateSet, true);

		if (templateCategoryList.length > 0)
		{
			this.setState({templateCategory: templateCategoryList[0].id});
		}
		else
		{
			this.setState({templateCategory: undefined});
		}
	}

	handleAddNow()
	{
		let templateInfo = this.props.parent.state.templateInfo;

		let templateCategoryId = uuidv1();
		let templateCategory = {
			id: templateCategoryId,
			name: this.state.createTemplateName,
			defaultTemplate: undefined,
			templates: []
		};

		templateInfo.templateCategoryOrder.push(templateCategoryId);
		templateInfo.templateSets[this.templateSet][templateCategoryId] = templateCategory;

		this.props.parent.doSave(templateInfo, this.templateSet);

		this.setState({createTemplateName: "", templateCategory:templateCategoryId});
	}

	handleClose()
	{

		if (this.props.clearcaster.pickDestination.onCancel !== undefined)
			this.props.clearcaster.pickDestination.onCancel(this.props.clearcaster.pickDestination.context);

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_PICKDESTINATION_DIALOG
		});
	}

	handleEditACopy()
	{
		if (this.props.clearcaster.pickDestination.onPick !== undefined)
			this.props.clearcaster.pickDestination.onPick(this.props.clearcaster.pickDestination.context, this.state.templateCategory);

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_PICKDESTINATION_DIALOG
		});
	}

	getTemplateCategory(templateInfo, id)
	{
		let templateCategory = undefined;

		if (templateInfo.templateSets[this.templateSet].hasOwnProperty(id))
			templateCategory = templateInfo.templateSets[this.templateSet][id];

		return templateCategory;
	}

	render()
    {
		let appStrings = this.props.strings;
		let templateInfo = this.props.parent.state.templateInfo;

		let disableAddNow = (this.state.createTemplateName.length <= 0);

		let templateCategoryList = getTemplateCategoryListV2(templateInfo, this.templateSet, true);

		let selectDisable = (templateCategoryList.length <= 0);

		return (
			<Modal size="lg" show={ this.props.clearcaster.pickDestination !== undefined } className="manage-destinations-dialog" onEnter={ this.onEnter } onShow={ this.onShow } onHide={ this.handleClose }>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.app.SelectDestinationTitle }</Modal.Title><br/>
				</Modal.Header>

				<Modal.Body>
					<div className="manage-destinations-list">
						<div className="integration-heading">{ appStrings.app.CustomDestinations }</div>
						<Form.Group>
							{
								templateCategoryList.length <= 0 ?
									<i>&nbsp;No custom destinations</i>
									:
									<Form.Control ref={ this.templateCategory } as="select" value={ this.state.templateCategory } onChange={ (evt) => { this.setState({ templateCategory: evt.target.value }) } }>
										{ templateCategoryList.map ((v, key) => { return (
											<option key={ v.id } value={ v.id }>{ v.name }</option>
										)})}
									</Form.Control>
							}
						</Form.Group>
					</div>
					<div className="manage-destinations-create">
						<div className="integration-heading">{ appStrings.app.CreateANewCategory }</div>
						<div>
							<InputGroup className="mb-3">
								<FormControl key={ "create-name" } id={ "create-name" } value={ this.state.createTemplateName } onChange={ evt => { this.setState({createTemplateName: evt.target.value}) } } />
								<InputGroup.Append>
									<Button key={ "create-add-now" } id={ "create-add-now" } disabled={ disableAddNow } variant={ disableAddNow?"outline-secondary":"secondary" } onClick={ this.handleAddNow }>{ appStrings.app.AddNow }</Button>
								</InputGroup.Append>
							</InputGroup>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Close }</Button>
					<Button variant={selectDisable?"outline-secondary":"primary"} disabled={ selectDisable } onClick={this.handleEditACopy}>{ appStrings.app.SelectDestination }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ManageDestinationPickerDialog;
