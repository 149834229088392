import React, { Component } from 'react';
import { Card, Form, Button, Badge } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import {
	FacebookInit,
	FacebookCreateBroadcast
} from '../../../controller/FacebookController';
import {
	inflateStr,
	deflateStr
} from '../../../templates/TemplateUtils';
import FacebookChooseDestinationConfirmationDialog from '../../dialogs/FacebookChooseDestinationConfirmationDialog';
import FacebookSharedBroadcastSettingsDialog from '../../dialogs/FacebookSharedBroadcastSettingsDialog';
import FacebookDestinationType from '../../../model/FacebookDestinationType';
import FacebookPairAccount from './FacebookPairAccount';
import FacebookChooseDestination from './FacebookChooseDestination';
import FacebookTitleDescription from './FacebookTitleDescription';
import FacebookBroadcastPreview from './FacebookBroadcastPreview';
import FacebookBroadcastPreviewIdleLive from './FacebookBroadcastPreviewIdleLive';
import EncoderStatusBadge from '../../components/EncoderStatusBadge';
import FacebookSharedBroadcastButtons from './FacebookSharedBroadcastButtons';
import FacebookSharedBroadcastMonitor from './FacebookSharedBroadcastMonitor';

import './FacebookSharedBroadcast.css';
import FacebookConnectionState from '../../../model/FacebookConnectionState';
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';
import FacebookPrivacyType from '../../../model/FacebookPrivacyType';
import { PureComponent } from 'react';

const DEBOUNCE_DURATION = 10000;

const deflateKeys = [
	'userFacebook',
	'broadcast.title',
	'broadcast.description'
];
const saveKeys = [
	'broadcast.dash_preview_url',
	'broadcast.dash_preview_secondary_url',
	'broadcast.description',
	'broadcast.id',
	'broadcast.overlay_url',
	'broadcast.permalink_url',
	'broadcast.planned_start_time',
	'broadcast.secure_stream_url',
	'broadcast.secure_stream_secondary_url',
	'broadcast.status',
	'broadcast.title',
	'destination.id',
	'destination.name',
	'destination.privacy',
	'destination.type',
	'doSpeedTest',
	'isPrimary',
	'endFacebookBroadcast',
	'saved',
	'userFacebook',
	'video.id'
];

// uiState:
// - loading
// - ready
// - edit-pairing
// - editing
// - saving
// - saving-pairing



class RenderFacebookLinks extends PureComponent
{
	render () {
		let { facebookData } = this.props;

		if ((facebookData.broadcast.id !== '' || facebookData.video.id !== '') && facebookData.broadcast.status !== 'ERROR')
		{
			return (
				<>
					<hr />
					{facebookData.broadcast.status === 'VOD' &&
						<div className="form-label label-danger">
							The Live Video has ended and is now only available as VOD
						</div>
					}
					<div className="form-label">Broadcast ID</div>
					{ facebookData.broadcast.permalink_url != null && facebookData.broadcast.permalink_url !== '' ?
						<><a target="_blank" rel="noopener noreferrer" href={"https://facebook.com"+facebookData.broadcast.permalink_url}>{facebookData.broadcast.id}</a><br /></>
						:
						<>{facebookData.broadcast.id}<br /></>
					}
					<div className="form-label">Live Video ID</div>
					<a target="_blank" rel="noopener noreferrer" href={"https://facebook.com/live/producer/"+facebookData.video.id}>{facebookData.video.id}</a>
				</>
			)
		}
		else if (facebookData.ui.state === 'ready' && facebookData.broadcast.status === 'ERROR') {
			return (
				<>
					<hr />
					<div className="form-label label-danger">
						Facebook Live Video Error
					</div>
					<p>
						The Live Video for this broadcast has either ended or been deleted in <a target="_blank" rel="noopener noreferrer" href={"https://facebook.com/live/producer/"+facebookData.video.id}> Live Producer</a>
					</p>
					<p>
						Updating the account pairing with Facebook or editing the privacy or destination will resolve this.
					</p>
				</>
			);
		}
		return null;
	}
}


class FacebookSharedBroadcast extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			debounceTimer:null
		};
		this.loadFacebookDataFromBroadcast = this.loadFacebookDataFromBroadcast.bind(this);
	}

	componentDidMount()
	{
		let controller = this.props.controller;
		FacebookInit()
		.then((userFacebook)=>{
			controller.getFacebookActiveBroadcasts(userFacebook);
		});
		this.loadFacebookDataFromBroadcast(true);
	}

	componentWillUnmount()
	{
		if (this.state.debounceTimer != null)
		{
			clearTimeout(this.state.debounceTimer);
			this.setState({debounceTimer:null});
		}
	}

	getAccessToken(userFacebook,facebookData)
	{
		let accessToken = userFacebook.accessToken;
		if (facebookData.destination.type === FacebookDestinationType.PAGE)
		{
			for (let key in userFacebook.pages)
			{
				if (facebookData.destination.id === userFacebook.pages[key].id)
				{
					accessToken = userFacebook.pages[key].access_token;
				}
			}
		}
		return accessToken;
	}

	getStreamTargets()
	{
		let broadcast = this.props.broadcast;
		let streamTargets = [];
		for(let o in broadcast.outputs)
		{
			for(let s in broadcast.outputs[o].streamTargets)
			{
				let streamTarget = broadcast.outputs[o].streamTargets[s];
				if (streamTarget.type === 'FB_PAIRED_STREAM_TARGET')
					streamTargets.push({...streamTarget});
			}
		}
		return streamTargets;
	}

	getFacebookDataFromExtraProperties(facebookData,extraProperties)
	{
		let newFacebookData = {...facebookData};
		for (let index in extraProperties)
		{
			let ep = extraProperties[index];
			if (ep.name.indexOf('facebook.') === 0)
			{
				let saveKey = ep.name.substring(9);
				let saveValue = ep.value;
				if(deflateKeys.includes(saveKey))
				{
					try {
						saveValue = JSON.parse(inflateStr(ep.value));
					} catch (e)
					{}
				}
				if (saveKey.indexOf(".") > 0)
				{
					let keys = saveKey.split(".");
					newFacebookData[keys[0]][keys[1]] = saveValue;
				}
				else
				{
					newFacebookData[saveKey] = saveValue;
				}
			}
		}
		if (newFacebookData.userFacebook != null && newFacebookData.userFacebook.picture != null)
		{
			newFacebookData.userFacebook.picture = JSON.parse(newFacebookData.userFacebook.picture);
		}
		return newFacebookData;
	}

	// debounces extra properties and sets initial UI state
	loadFacebookDataFromBroadcast(setInitialUIState = false)
	{
		if (this.state.debounceTimer != null)
		{
			clearTimeout(this.state.debounceTimer);
			this.setState({debounceTimer:null});
		}
		let broadcast = this.props.broadcast;
		let broadcastSharingFacebookData = this.props.clearcaster.broadcastSharingFacebookData;

		if (setInitialUIState || broadcastSharingFacebookData.ui.state === 'ready')
		{
			if (broadcast.extraProperties.length > 0)
			{
				let facebookData = this.getFacebookDataFromExtraProperties(broadcastSharingFacebookData,broadcast.extraProperties);
				if (setInitialUIState)
				{
					facebookData.ui.createNewBroadcast = false;
					if (facebookData.saved != null && facebookData.saved === 'true')
					{
						facebookData.ui.state = 'ready';
					}
					else
					{
						facebookData.ui.state = 'editing';
					}
				}
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
					facebookData:facebookData
				});
			}
		}
		let _this = this;
		let debouncer = setTimeout(()=>{ _this.loadFacebookDataFromBroadcast(); },DEBOUNCE_DURATION);
		this.setState({debounceTimer:debouncer});
	}

	canSave (userFacebook, facebookData)
	{
		let canSave = true;
		if (facebookData.destination.type == null || facebookData.destination.type === '') canSave = false;
		if (facebookData.destination.id == null || facebookData.destination.id === '') canSave = false;
		if (facebookData.destination.type === 'page')
		{
			let foundPage = false;
			for (let i = 0; i < userFacebook.pages.length; i++)
			{
				if (facebookData.destination.id === userFacebook.pages[i].id)
				{
					foundPage = true;
					break;
				}
			}
			if (!foundPage) canSave = false;
		}
		if (facebookData.destination.type === 'group')
		{
			let foundGroup = false;
			for (let i = 0; i < userFacebook.groups.length; i++)
			{
				if (facebookData.destination.id === userFacebook.groups[i].id)
				{
					foundGroup = true;
					break;
				}
			}
			if (!foundGroup) canSave = false;
		}
		return canSave;
	}

	setUIState(facebookData,uiState)
	{
		let newFacebookData = {...facebookData,ui:{...facebookData.ui,state:uiState}};
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
			facebookData:newFacebookData
		});
	}

	setUIStateEvent(facebookData,uiState)
	{
		let _this = this;
		return (evt) => {
			_this.setUIState(facebookData,uiState);
		}
	}


	mapSaveData(data)
	{
		let retObj = {};
		for(let key in data)
		{
			if (key === 'userFacebook')
			{
				continue;
			}
			if (typeof data[key] === 'object')
			{
				for(let key2 in data[key])
				{
					retObj[key+'.'+key2] = data[key][key2];
				}
			}
			else
			{
				retObj[key] = data[key];
			}
		}
		return retObj;
	}

	saveFacebookEverything(controller,uiState,broadcastId,userFacebook,facebookData)
	{
		return async (evt) => {

			// storeFacebookData ends up in the ClearCasterStore if updateFacebookData = true
			let storeFacebookData = JSON.parse(JSON.stringify(facebookData));
			let finishedState = 'ready';
			if (uiState === 'edit-pairing')
				this.setUIState(facebookData,'saving-pairing');
			else
				this.setUIState(facebookData,'saving');

			let updateFacebookData = false;

			if (storeFacebookData.userFacebook == null || storeFacebookData.userFacebook.id !== userFacebook.id)
			{
				storeFacebookData.userFacebook = {
					name:userFacebook.name,
					email:userFacebook.email,
					id:userFacebook.id,
					picture:userFacebook.picture
				};
				updateFacebookData = true;
			}
			if (storeFacebookData.saved == null || storeFacebookData.saved !== 'true')
			{
				storeFacebookData.saved = 'true';
				if (storeFacebookData.broadcast.title === '' && storeFacebookData.broadcast.description === '')
					finishedState = 'editing';
				updateFacebookData = true;
			}

			// saveFacebookData flattens JSON paths 1 level so broadcast { id:'id' } becomes 'broadcast.id':'id'
			// this ends up in Broadcast extraProperties in yeti
			let saveFacebookData = this.mapSaveData(storeFacebookData);

			saveFacebookData['userFacebook'] = {
				name:userFacebook.name,
				email:userFacebook.email,
				id:userFacebook.id,
				picture:JSON.stringify(userFacebook.picture)
			};

			deflateKeys.forEach (key => {
				if (saveFacebookData[key] != null)
				{
					saveFacebookData[key] = deflateStr(JSON.stringify(saveFacebookData[key]));
				}
			});

			let accessToken = this.getAccessToken(userFacebook,facebookData);
			let secureStreamUrl = '';
			let secureStreamSecondaryUrl = '';

			// create or update Facebook broadcast
			let facebookApiBroadcastInfo = {
				...storeFacebookData,
				accessToken:accessToken
			};
			if (storeFacebookData.ui.createNewBroadcast) {
				facebookApiBroadcastInfo.broadcast.id = '';
				facebookApiBroadcastInfo.video.id = '';
				facebookApiBroadcastInfo.broadcast.dash_preview_url = '';
				facebookApiBroadcastInfo.broadcast.dash_preview_secondary_url = '';
				facebookApiBroadcastInfo.broadcast.secure_stream_url = '';
				facebookApiBroadcastInfo.broadcast.secure_stream_secondary_url = '';
				storeFacebookData.ui.createNewBroadcast = false;
			}
			try
			{
				await controller.persistFacebookPairedAccessToken(broadcastId,facebookData.destination.id,accessToken);
				let facebookApiResponse = await FacebookCreateBroadcast(facebookApiBroadcastInfo);
				if (facebookApiResponse.id != null) {
					storeFacebookData.broadcast.id = facebookApiResponse.id;
					saveFacebookData['broadcast.id'] = facebookApiResponse.id;
				}
				if (facebookApiResponse.status != null) {
					storeFacebookData.broadcast.status = facebookApiResponse.status;
					saveFacebookData['broadcast.status'] = facebookApiResponse.status;
				}
				if (facebookApiResponse.dash_preview_url != null) {
					storeFacebookData.broadcast.dash_preview_url = facebookApiResponse.dash_preview_url;
					saveFacebookData['broadcast.dash_preview_url'] = facebookApiResponse.dash_preview_url;
				}
				if (facebookApiResponse.permalink_url != null) {
					storeFacebookData.broadcast.permalink_url = facebookApiResponse.permalink_url;
					saveFacebookData['broadcast.permalink_url'] = facebookApiResponse.permalink_url;
				}
				if (facebookApiResponse.overlay_url != null) {
					storeFacebookData.broadcast.overlay_url = facebookApiResponse.overlay_url;
					saveFacebookData['broadcast.overlay_url'] = facebookApiResponse.overlay_url;
				}
				if (facebookApiResponse.planned_start_time != null) {
					storeFacebookData.broadcast.planned_start_time = facebookApiResponse.planned_start_time;
					saveFacebookData['broadcast.planned_start_time'] = facebookApiResponse.planned_start_time;
				}
				else {
					storeFacebookData.broadcast.planned_start_time = '';
					saveFacebookData['broadcast.planned_start_time'] = '';
				}
				if (facebookApiResponse.video != null && facebookApiResponse.video.id != null) {
					storeFacebookData.video.id = facebookApiResponse.video.id;
					saveFacebookData['video.id'] = facebookApiResponse.video.id;
				}
				if (facebookApiResponse.secure_stream_url != null) {
					secureStreamUrl = facebookApiResponse.secure_stream_url;
					storeFacebookData.broadcast.secure_stream_url = facebookApiResponse.secure_stream_url;
					saveFacebookData['broadcast.secure_stream_url'] = facebookApiResponse.secure_stream_url;
				}
				if (facebookApiResponse.ingest_streams != null) {
					// backup ingest url is found in the ingest_stream[] array for is_master = false streams
					for (let ikey in facebookApiResponse.ingest_streams) {
						let ingest_stream = facebookApiResponse.ingest_streams[ikey];
						if (ingest_stream.is_master === false)
						{
							if (ingest_stream.secure_stream_url)
							{
								secureStreamSecondaryUrl = ingest_stream.secure_stream_url;
								storeFacebookData.broadcast.secure_stream_secondary_url = ingest_stream.secure_stream_url;
								saveFacebookData['broadcast.secure_stream_secondary_url'] = ingest_stream.secure_stream_url;
							}
							if (ingest_stream.dash_preview_url)
							{
								storeFacebookData.broadcast.dash_preview_secondary_url = ingest_stream.dash_preview_url;
								saveFacebookData['broadcast.dash_preview_secondary_url'] = ingest_stream.dash_preview_url;
							}
							break;
						}
					}
				}
			}
			catch (e)
			{
				console.log('ERROR calling Facebook API:' + JSON.stringify(e));
				storeFacebookData.broadcast.id = '';
				saveFacebookData['broadcast.id'] = '';
				storeFacebookData.broadcast.dash_preview_url = '';
				saveFacebookData['broadcast.dash_preview_url'] = '';
				storeFacebookData.broadcast.dash_preview_secondary_url = '';
				saveFacebookData['broadcast.dash_preview_secondary_url'] = '';
				storeFacebookData.broadcast.permalink_url = '';
				saveFacebookData['broadcast.permalink_url'] = '';
				storeFacebookData.broadcast.overlay_url = '';
				saveFacebookData['broadcast.overlay_url'] = '';
				storeFacebookData.broadcast.planned_start_time = '';
				saveFacebookData['broadcast.planned_start_time'] = '';
				storeFacebookData.video.id = '';
				saveFacebookData['video.id'] = '';
				storeFacebookData.destination.id = '';
				saveFacebookData['destination.id'] = '';
				storeFacebookData.destination.name = 'Invalid Destination';
				saveFacebookData['destination.name'] = 'Invalid Destination';
				storeFacebookData.broadcast.secure_stream_url = '';
				storeFacebookData.broadcast.secure_stream_secondary_url = '';
				secureStreamUrl = '';
				secureStreamSecondaryUrl = '';
			}

			// make promises to store Broadcast extraProperties
			let extraPromises = [];
			for(let key in saveFacebookData)
			{
				if (saveKeys.includes(key))
				{
					extraPromises.push(controller.setBroadcastExtraProperty(broadcastId,{name:'facebook.'+key,value:saveFacebookData[key],type:'String'}));
				}
			}
			extraPromises.push(controller.setBroadcastExtraProperty(broadcastId,{name:'facebook.saved',value:'true',type:'String'}));

			// Update streamTarget urls for the non-speed-test workflow.
			// Backup encoders get the secondary url
			let streamTargets = this.getStreamTargets();
			let streamTargetUrl = secureStreamUrl;
			if (storeFacebookData.isPrimary === 'false')
			{
				streamTargetUrl = secureStreamSecondaryUrl;
			}
			if (streamTargetUrl !== '')
			{
				for (let st in streamTargets)
				{
					let input = {
						url:streamTargetUrl
					};
					extraPromises.push(controller.setBroadcastStreamTargetInfo(streamTargets[st].id,input));
				}
			}
			let _this = this;
			try {
				Promise.all(extraPromises)
				.then(async ()=>{
					if (_this.state.debounceTimer != null) clearTimeout(_this.state.debounceTimer);
					let debouncer = setTimeout(()=>{ _this.loadFacebookDataFromBroadcast(); },DEBOUNCE_DURATION);
					if (updateFacebookData)
					{
						ClearCasterStore.dispatch({
							type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
							facebookData:storeFacebookData
						});
					}
					_this.setState({debounceTimer:debouncer});
					_this.setUIState(storeFacebookData,finishedState);
				})
				.catch((err)=>{
					console.log('errors!');
					console.log(err);
					_this.setUIState(storeFacebookData,'editing');
					throw(err);
				});
			}
			catch(e)
			{
				console.log('errors!');
				console.log(e);
			}
			// finally refresh the facebook active broadcasts cache since we may have created a new one
			controller.getFacebookActiveBroadcasts(userFacebook);
		}
	}

	editSettings (facebookData)
	{
		return (evt) => {
			let newFacebookData = {...facebookData,ui:{...facebookData.ui,showSettingsDialog:true}};
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData:newFacebookData
			});
		}
	}

	editPairing (userFacebook, facebookData)
	{
		return (evt) => {
			let newFacebookData;
			if (userFacebook.id === facebookData.userFacebook.id)
			{
				newFacebookData = {...facebookData,ui:{...facebookData.ui,state:'edit-pairing'}};
				// newFacebookData.ui.createNewBroadcast = newFacebookData.destination.type !== FacebookDestinationType.UI_ACTIVE_BROADCAST;
				newFacebookData.ui.createNewBroadcast = false;
				if (facebookData.broadcast.status === 'VOD' || facebookData.broadcast.status === 'ERROR')
				{
					newFacebookData.ui.createNewBroadcast = true;
				}
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
					facebookData:newFacebookData
				});
			}
			else
			{
				newFacebookData = {...facebookData,ui:{...facebookData.ui,showChooseDestinationConfirmationDialog:true}};
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
					facebookData:newFacebookData
				});
			}
		}
	}

	cancelEdit (broadcast,facebookData)
	{
		return (evt) => {
			this.loadFacebookDataFromBroadcast(true);
		};
	}

	renderEncoders (broadcast, encoders, facebookData)
	{
		let { controller, strings } = this.props;
		let broadcastStatus = broadcast.status;
		let isPathOptimize = broadcastStatus.indexOf('PATH_OPTIMIZE') >= 0;
		let pathOptimizeString = 'Optimizing Path to Facebook Live';
		let pathOptimizeVariant = 'warning';
		if (broadcastStatus.indexOf('PATH_OPTIMIZE_ERROR') >= 0) {
			pathOptimizeString = 'Error optimizing path to Facebook Live';
			pathOptimizeVariant = 'danger';
		}
		let isPrimary = true;
		if (facebookData && facebookData.isPrimary !== undefined) {
			isPrimary = facebookData.isPrimary === 'false' ? false : true;
		}
		return (
			<div className="row">
				<div className="col-12">
					<div className="form-label">{encoders.length > 1 ? "ClearCasters" : "ClearCaster"}</div>
					{encoders.map((e,key)=>{ return (
						<div key={key} className="d-flex justify-content-between">
							<span key={key}>
								{e.name}&nbsp;
								{ isPathOptimize ?
									<Badge variant={pathOptimizeVariant}>{pathOptimizeString}</Badge>
								:
									<EncoderStatusBadge encoder={e} showSubscriptionExpired={true} controller={controller} strings={strings} />
								}
							</span>
							<span>
							{ isPrimary &&
								<span><i className="fa fa-check-circle fa-lg fa-fw icon-success" aria-hidden="true"></i>&nbsp;Primary ingest stream</span>
							}
							{ !isPrimary &&
								<span><i className="fa fa-check-circle fa-lg fa-fw icon-warning" aria-hidden="true"></i>&nbsp;Backup ingest stream</span>
							}
							</span>
						</div>
					);})}
				</div>
			</div>
		);
	}


	renderSaveButtons (controller, broadcast, uiState, facebookData, userFacebook)
	{
		let showCancel = facebookData.saved != null && facebookData.saved === 'true';
		let canSave = this.canSave(userFacebook,facebookData);
		return (
			<div className="text-right">
				<Form.Group>
					{ uiState.indexOf('saving') >= 0 ?
						<>
							{showCancel &&
							<Button variant={'outline-secondary'} disabled className="mr-2"
									style={{width: '125px'}}>
								Cancel
							</Button>
							}
							<Button variant={'outline-secondary'} style={{width: '125px'}} disabled>
								<PulseLoader sizeUnit="px" size="5"/>
							</Button>
						</>
						:
						<>
							{showCancel &&
							<Button variant={'outline-secondary'} className="mr-2"
									style={{width: '125px'}}
									onClick={this.cancelEdit(broadcast,facebookData)}>
								Cancel
							</Button>
							}
							<Button disabled={!canSave} style={canSave ? {width: '125px'} : {width: '125px',cursor:'not-allowed'} } onClick={this.saveFacebookEverything(controller,uiState,broadcast.id,userFacebook,facebookData)}>
								{ facebookData.ui.createNewBroadcast ? "Create" : "Save" }
							</Button>
						</>
					}
				</Form.Group>
			</div>
		);
	}

	getPrivacyString (destination) {
		let privacyString = '';
		if (destination.type === 'group')
		{
			privacyString = 'Group';
		}
		else
		{
			switch(destination.privacy) {
				case FacebookPrivacyType.FRIENDS_OF_FRIENDS:
					privacyString = 'Friends of Friends';
					break;
				case FacebookPrivacyType.ALL_FRIENDS:
					privacyString = 'Friends';
					break;
				case FacebookPrivacyType.SELF:
					privacyString = 'Only Me';
					break;
				case FacebookPrivacyType.EVERYONE:
				default:
					privacyString = 'Public';
					break;
			}
		}
		return privacyString;
	}

	renderReadyPairedAccount (userFacebook,facebookData,showEditButton)
	{
		let hasValidData = false;
		if (facebookData.userFacebook != null &&
			facebookData.userFacebook.id != null &&
			facebookData.userFacebook.picture != null)
			hasValidData = true;
		let privacyString = this.getPrivacyString(facebookData.destination);

		return (
			<>
				<hr />
				{ showEditButton &&
					<div>
						<div className="float-right">
							<Button variant="outline-secondary" style={{width: '100px'}} className="btn-sm" onClick={this.editPairing(userFacebook,facebookData)}>
								<i className="fa fa fa-pencil"></i> Edit
							</Button>
						</div>
					</div>
				}
				{ hasValidData &&
					<>
						<div className="form-label">Paired Facebook Account</div>
						<div className="d-flex mt-2">
							<div className="mr-2">
								<img src={facebookData.userFacebook.picture.data.url} alt="" height="50" width="50"/>
							</div>
							<div>
								<strong>{facebookData.userFacebook.name}</strong><br />
							</div>
						</div>
					</>
				}
				{ !hasValidData &&
					<>
						<div className="form-label">No Paired Facebook Account</div>
					</>
				}
				{ facebookData.destination.id != null &&
					<>
						<div className="form-label mt-2">Destination</div>
						<div className="mb-2">
							{ facebookData.destination.name } ({privacyString})
						</div>
					</>
				}
			</>
		);
	}

	renderEditPairing (broadcast, userFacebook, facebookData, facebookActiveBroadcasts, isLoggedInToFacebook)
	{
		let showCancel = !isLoggedInToFacebook && facebookData.saved != null && facebookData.saved === 'true';
		return (
			<>
				<hr />
				<FacebookPairAccount userFacebook={userFacebook} showCancel={showCancel} cancelEdit={this.cancelEdit(broadcast,facebookData)} controller={this.props.controller} />
				{ isLoggedInToFacebook &&
					<FacebookChooseDestination userFacebook={userFacebook} facebookData={facebookData} facebookActiveBroadcasts={facebookActiveBroadcasts} />
				}
			</>
		)
	}

	renderReadyStreaming (broadcast, encoders, userFacebook, facebookData)
	{
		let hasValidData = false;
		if (facebookData.userFacebook != null &&
			facebookData.userFacebook.id != null &&
			facebookData.userFacebook.picture != null)
			hasValidData = true;
		let privacyString = this.getPrivacyString(facebookData.destination);

		return (
			<>
				{ this.renderEncoders(broadcast, encoders, facebookData)}
				<hr />
				{ hasValidData &&
					<div className="row">
						<div className="col-6">
							<div className="d-flex mt-2">
								<div className="mr-2">
									<img src={facebookData.userFacebook.picture.data.url} alt="" height="50" width="50"/>
								</div>
								<div>
									<strong>{facebookData.userFacebook.name}</strong><br />
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-label mt-2">Destination</div>
							<div className="mb-2">
								{ facebookData.destination.name } ({privacyString})
							</div>
						</div>
					</div>
				}
				<hr />
				<div className="row">
					<div className="col-12">
						<div className="form-label">{ facebookData.broadcast.title }</div>
						<div>{ facebookData.broadcast.description }</div>
					</div>
				</div>
			</>
		)
	}

	renderReady (broadcast, encoders, userFacebook, facebookData)
	{

		return (
			<>
				{ this.renderEncoders(broadcast, encoders, facebookData)}
				<div className="row">
					<div className="col-12">
						{ this.renderReadyPairedAccount (userFacebook, facebookData,true) }
						<hr />
						<div className="float-right">
							<Button variant="outline-secondary" style={{width: '100px'}} className="btn-sm" onClick={this.setUIStateEvent(facebookData,'editing')}>
								<i className="fa fa fa-pencil"></i> Edit
							</Button>
						</div>
						<div className="form-label">Title</div>
						<div className="mb-2">
							{ facebookData.broadcast.title }
						</div>
						<div className="form-label">Description</div>
						<div className="mb-2">
							{ facebookData.broadcast.description }
						</div>
					</div>
				</div>
			</>
		)
	}

	renderEditing (uiState, controller, broadcast, encoders, isLoggedInToFacebook, userFacebook, facebookData, facebookActiveBroadcasts)
	{
		let firstTime = facebookData.saved == null || facebookData.saved === 'false';

		return (
			<>
				{ this.renderEncoders(broadcast, encoders, facebookData)}
				<div className="row">
					<div className="col-12">
						{ firstTime &&
							<>
								{ this.renderEditPairing (broadcast, userFacebook, facebookData, facebookActiveBroadcasts, isLoggedInToFacebook) }
								{ isLoggedInToFacebook &&
									this.renderSaveButtons (controller, broadcast, uiState, facebookData, userFacebook)
								}

							</>
						}
						{ !firstTime && (uiState === 'edit-pairing' || uiState === 'saving-pairing') &&
							<>
								{ this.renderEditPairing (broadcast, userFacebook, facebookData, facebookActiveBroadcasts, isLoggedInToFacebook) }
								{ isLoggedInToFacebook &&
									this.renderSaveButtons (controller, broadcast, uiState, facebookData, userFacebook)
								}
								<hr />
								<div className="form-label">Title</div>
								<div className="mb-2">
									{ facebookData.broadcast.title }
								</div>
								<div className="form-label">Description</div>
								<div className="mb-2">
									{ facebookData.broadcast.description }
								</div>
							</>
						}
						{ !firstTime && (uiState === 'editing' || uiState === 'saving') &&
						<>
							{ this.renderReadyPairedAccount (userFacebook,facebookData,false) }
							<hr />
							<FacebookTitleDescription userFacebook={userFacebook} facebookData={facebookData}/>
							{ this.renderSaveButtons (controller, broadcast, uiState, facebookData, userFacebook) }
						</>
						}
					</div>
				</div>
			</>
		);
	}

	render()
	{
		let facebookData = this.props.clearcaster.broadcastSharingFacebookData;
		let userFacebook = this.props.clearcaster.userFacebook;
		let facebookActiveBroadcasts = this.props.clearcaster.broadcastSharingFacebookActiveBroadcasts;
		let uiState = facebookData.ui.state;
		let isLoggedInToFacebook = userFacebook.facebookConnected === FacebookConnectionState.CONNECTED;
		let encoders = [];
		for (let i = 0; i < this.props.broadcast.broadcastEncoders.length; i++)
		{
			let encoder = this.props.clearcaster.encoderMap[this.props.broadcast.broadcastEncoders[i].encoder.id];
			if (encoder != null)
				encoders.push(encoder);
		}

		return (
			<div className="facebook-shared-broadcast-item">
				<FacebookChooseDestinationConfirmationDialog strings={this.props.strings} facebookData={facebookData} />
				<FacebookSharedBroadcastSettingsDialog strings={this.props.strings} controller={this.props.controller} facebookData={facebookData} broadcast={this.props.broadcast} />
				<div className="row">
					<div className="col-12 alert alert-warning">
						<p dangerouslySetInnerHTML={{ __html: this.props.strings.app.FacebookEncoderVersionBanner}}></p>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1>{this.props.broadcast.name}</h1>
					</div>
				</div>
				<div className="row" style={{margin: '0 0 10px'}}>
					<div className="col-6">{/* Status Badge */}</div>
					<div className="col-6 text-right pr-0">
						<FacebookSharedBroadcastButtons
							broadcast={this.props.broadcast}
							facebookBroadcastId={facebookData.broadcast.id}
							encoders={encoders}
							controller={this.props.controller}
							facebookData={facebookData}
							strings={this.props.strings} />
					</div>
				</div>
				{/* Broadcast Status */}
				<div className="row">
					<div className="col-sm-6">
						<Card className="shadow-sm">
							<Card.Header>
								Broadcast Setup
								<div className="pull-right">
								<Button variant="outline-secondary" size="sm" onClick={this.editSettings(facebookData)}>
									<i className="fa fa-gear"></i> Settings
								</Button>
								</div>
							</Card.Header>
							<Card.Body>
								{ uiState === 'loading' &&
									<PulseLoader sizeUnit="px" size="5"/>
								}
								{ (uiState.indexOf('edit') === 0 || uiState.indexOf('saving') === 0) &&
									this.renderEditing (
										uiState,
										this.props.controller,
										this.props.broadcast,
										encoders,
										isLoggedInToFacebook,
										userFacebook,
										facebookData,
										facebookActiveBroadcasts)
								}
								{ uiState === 'ready' && !(this.props.broadcast.status === "PREVIEW" || this.props.broadcast.status === "LIVE" || this.props.broadcast.status === "PATH_OPTIMIZE") &&
									this.renderReady (
										this.props.broadcast,
										encoders,
										userFacebook,
										facebookData)
								}
								{ uiState === 'ready' && (this.props.broadcast.status === "PREVIEW" || this.props.broadcast.status === "LIVE" || this.props.broadcast.status === "PATH_OPTIMIZE") &&
									this.renderReadyStreaming (
										this.props.broadcast,
										encoders,
										userFacebook,
										facebookData)
								}
								{(this.props.broadcast.status === "PREVIEW" || this.props.broadcast.status === "LIVE") &&
									<>
										<hr />
										<FacebookSharedBroadcastMonitor
											strings={ this.props.strings }
											broadcast={ this.props.broadcast }
											clearcaster={ this.props.clearcaster }
											facebookData={facebookData}
										/>
									</>
								}
								{ !(uiState === 'edit-pairing' || uiState === 'saving-pairing') &&
									<RenderFacebookLinks facebookData={facebookData} />
								}
							</Card.Body>
						</Card>
					</div>
					<div className="col-sm-6">
						{this.props.broadcast.status === "PREVIEW" ?
							<FacebookBroadcastPreview
								strings={ this.props.strings }
								broadcast={ this.props.broadcast }
								controller={this.props.controller}
								facebookBroadcastId={facebookData.broadcast.id}
							/>
							:
							<FacebookBroadcastPreviewIdleLive
								strings={ this.props.strings }
								broadcast={ this.props.broadcast }
								facebookData={ facebookData }
							/>
						}
					</div>
				</div>
			</div>
		);
	}
};

export default FacebookSharedBroadcast;
