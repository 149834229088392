var base64 = require('base-64');
var pako = require('pako');

export const WIDGET_UNITS = {
	INTEGER: 'INTEGER',
	FLOAT: 'FLOAT',
	BOOLEAN: 'BOOLEAN',
	BITRATE_BITS: 'BITRATE_BITS',
	BITRATE_KBPS: 'BITRATE_KBPS',
	BITRATE_MBPS: 'BITRATE_MBPS',
};

export function deflateStr(jsonStr)
{
	var binaryString = base64.encode(pako.deflate(jsonStr, { to: 'string' }));
	return binaryString;
}

export function inflateStr(base64Str)
{
	var jsonStr = pako.inflate(base64.decode(base64Str), { to: 'string' });
	return jsonStr;
}

export function getDefaultTemplateForCategoryV2(templateInfo, templateSet, templateCategoryName)
{
	let defaultTemplate = undefined;

	if (templateCategoryName !== undefined && templateInfo.templateSets[templateSet].hasOwnProperty(templateCategoryName))
	{
		let templateCategory = templateInfo.templateSets[templateSet][templateCategoryName];

		defaultTemplate = templateCategory.defaultTemplate;

		if (defaultTemplate === undefined && templateCategory.templates !== undefined && templateCategory.templates.length > 0)
			defaultTemplate = templateCategory.templates[0];
	}

	return defaultTemplate;
}

export const TEMPLATECOUNTS_DESTINATIONS = 0;
export const TEMPLATECOUNTS_DESTINATIONS_ENABLED = 1;
export const TEMPLATECOUNTS_TEMPLATES = 2;
export const TEMPLATECOUNTS_TEMPLATES_ENABLED = 3;

export function getRootTemplateName(id)
{
	let plusIndex = id.indexOf("+");
	let idMod = id;
	if (plusIndex > 0)
		idMod = idMod.substr(0, plusIndex);

	return idMod;
}

export function getCustomTemplateCountsV2(templateInfo, templateSet)
{
	let counts = [0, 0, 0, 0];

	for(let i in templateInfo.templateCategoryOrder)
	{
		let templateCategoryId = templateInfo.templateCategoryOrder[i];

		if (templateInfo.templateSets[templateSet].hasOwnProperty(templateCategoryId))
		{
			let templateCategory = templateInfo.templateSets[templateSet][templateCategoryId];

			if (isTemplateObjReadOnly(templateCategory))
				continue;

			counts[TEMPLATECOUNTS_DESTINATIONS]++;

			let categoryEnabled = isTemplateObjEnabled(templateCategory);
			if (categoryEnabled)
				counts[TEMPLATECOUNTS_DESTINATIONS_ENABLED]++;

			for(let j in templateCategory.templates)
			{
				let templateId = templateCategory.templates[j];

				if (templateInfo.templates.hasOwnProperty(templateId))
				{
					let template = templateInfo.templates[templateId];

					let templateEnabled = isTemplateObjEnabled(template);

					counts[TEMPLATECOUNTS_TEMPLATES]++;

					if (categoryEnabled & templateEnabled)
						counts[TEMPLATECOUNTS_TEMPLATES_ENABLED]++;
				}
				else
					console.log("ERROR: getCustomTemplateCount: Template missing from templates: "+templateId);
			}
		}
		else
			console.log("ERROR: getCustomTemplateCount: TemplateCategory missing from templateCategories: "+templateCategoryId);
	}

	return counts;

}

export function adjustBroadcastValues(broadcast)
{
	if (broadcast.outputs !== undefined)
	{
		for(let oi in broadcast.outputs)
		{
			let output = broadcast.outputs[oi];

			if (output.encodingConfiguration !== undefined)
			{
				let encodingConfiguration = output.encodingConfiguration;
				if (encodingConfiguration.encodingConfigurationVideo !== undefined)
				{

					if (encodingConfiguration.encodingConfigurationVideo.bitrate !== undefined &&
						typeof encodingConfiguration.encodingConfigurationVideo.bitrate == 'string' &&
						!encodingConfiguration.encodingConfigurationVideo.bitrate.trim().startsWith("$"))
					{
						//this.addToUniqueBitrates(encodingConfiguration.encodingConfigurationVideo.bitrate, uniqueBitrates);

						encodingConfiguration.encodingConfigurationVideo.bitrate = parseBytesStr(encodingConfiguration.encodingConfigurationVideo.bitrate);
						//console.log("output["+oi+"].video.bitrate: "+encodingConfiguration.encodingConfigurationVideo.bitrate);
					}

					if (encodingConfiguration.encodingConfigurationVideo.bitrateMin !== undefined &&
						typeof encodingConfiguration.encodingConfigurationVideo.bitrateMin == 'string' &&
						!encodingConfiguration.encodingConfigurationVideo.bitrateMin.trim().startsWith("$"))
					{
						//this.addToUniqueBitrates(encodingConfiguration.encodingConfigurationVideo.bitrateMin, uniqueBitrates);

						encodingConfiguration.encodingConfigurationVideo.bitrateMin = parseBytesStr(encodingConfiguration.encodingConfigurationVideo.bitrateMin);
						//console.log("output["+oi+"].video.bitrateMin: "+encodingConfiguration.encodingConfigurationVideo.bitrateMin);
					}
				}

				if (encodingConfiguration.encodingConfigurationAudio !== undefined)
				{
					if (encodingConfiguration.encodingConfigurationAudio.bitrate !== undefined &&
						typeof 	encodingConfiguration.encodingConfigurationAudio.bitrate == 'string' &&
						!encodingConfiguration.encodingConfigurationAudio.bitrate.trim().startsWith("$"))
					{
						//this.addToUniqueBitrates(encodingConfiguration.encodingConfigurationAudio.bitrate, uniqueBitrates);

						encodingConfiguration.encodingConfigurationAudio.bitrate = parseBytesStr(encodingConfiguration.encodingConfigurationAudio.bitrate);
						//console.log("output["+oi+"].audio.bitrate: "+encodingConfiguration.encodingConfigurationAudio.bitrate);
					}
				}
			}
		}
	}
}

export function getTemplateCategoriesV2(templateInfo, templateSet)
{
	return templateInfo.templateSets[templateSet];
}

export function getTemplateCategoryListV2(templateInfo, templateSet, customOnly = false, filterEnabled = false, filterEmptyDestinations = false)
{
	let templateCategoryList = [];

	for(let i in templateInfo.templateCategoryOrder)
	{
		let templateCategoryId = templateInfo.templateCategoryOrder[i];

		if (templateInfo.templateSets[templateSet].hasOwnProperty(templateCategoryId))
		{
			let templateCategory = templateInfo.templateSets[templateSet][templateCategoryId];

			let isCategoryEnabled = isTemplateObjEnabled(templateCategory);

			if (filterEnabled && !isCategoryEnabled)
				continue;

			if (customOnly && isTemplateObjReadOnly(templateCategory))
				continue;

			if (filterEmptyDestinations)
			{
				let templateList = getTemplateListV2(templateInfo, templateSet, templateCategoryId, filterEnabled);
				if (templateList.length <= 0)
					continue;
			}

			templateCategoryList.push(templateCategory);
		}
		else
			console.log("INFO: getTemplateCategoryList: Template in templateCategoryOrder missing from templateCategories: "+templateCategoryId);
	}

	return templateCategoryList;
}

export function getTemplateListV2(templateInfo, templateSet, templateCategoryId, filterEnabled = false)
{
	let templateList = [];

	if (templateCategoryId !== undefined && templateInfo.templateSets[templateSet].hasOwnProperty(templateCategoryId))
	{
		let templateCategory = templateInfo.templateSets[templateSet][templateCategoryId];

		for(let i in templateCategory.templates)
		{
			let templateId = templateCategory.templates[i];

			if (templateInfo.templates.hasOwnProperty(templateId))
			{
				let template = templateInfo.templates[templateId];

				let isTemplateEnabled = isTemplateObjEnabled(template);

				if (filterEnabled && !isTemplateEnabled)
					continue;

				templateList.push(template);
			}
		}
	}

	return templateList;
}

export function removeDisabledFeatureTemplates (allTemplates,templateSet) {
	let set = Object.assign({},templateSet);
	let templates = Object.assign({},allTemplates);

	function removeTemplate(property){
		if (set.hasOwnProperty(property))
		{
			delete set[property];
			var index = templates.templateCategoryOrder.indexOf(property);
			if (index >= 0)
				templates.templateCategoryOrder.splice(index, 1);
		}
	}

	if (process.env.REACT_APP_ENABLE_LINKEDIN !== "true"){
		removeTemplate("linkedin");
	}
	if (process.env.REACT_APP_ENABLE_SUBSCRIPTIONS !== "true"){
		removeTemplate("expired-subscription-limited");
	}
	if (process.env.REACT_APP_ENABLE_FACEBOOK_PAIRED_WORKFLOW !== "true"){
		removeTemplate("facebookpaired");
	}
	return {templateSet: set, allTemplates: templates};
}

export function rawValueToUnitValue(param, value)
{
	if (param.units !== undefined)
	{
		switch (param.units)
		{
			case WIDGET_UNITS.BOOLEAN:
				value = parseBoolean(value);
				break;
			case WIDGET_UNITS.INTEGER:
				value = parseInt(value);
				break;
			case WIDGET_UNITS.FLOAT:
				value = parseFloat(value);
				break;
			case WIDGET_UNITS.BITRATE_BITS:
				value = parseFloat(value);
				break;
			case WIDGET_UNITS.BITRATE_KBPS:
				value = value / 1024.0;
				value = parseFloat(parseFloat(Math.round(value * 1000) / 1000).toFixed(3));
				break;
			case WIDGET_UNITS.BITRATE_MBPS:
				value = value / (1024.0 * 1024.0);
				value = parseFloat(parseFloat(Math.round(value * 1000) / 1000).toFixed(3));
				break;
			default:
		}
	}
	else if (value === null)
		value = '';

	return value;
}

export function unitValueToRawValue(param, value)
{
	if (param.units !== undefined)
	{
		switch(param.units)
		{
			case WIDGET_UNITS.BOOLEAN:
				value = parseBoolean(value);
				break;
			case WIDGET_UNITS.INTEGER:
				value = parseInt(value);
				value = isNaN(value)?0:value;
				break;
			case WIDGET_UNITS.FLOAT:
				value = parseFloat(value);
				value = isNaN(value)?0.0:value;
				break;
			case WIDGET_UNITS.BITRATE_BITS:
				value = parseFloat(value);
				value = isNaN(value)?0.0:value;
				value = Math.round(value);
				break;
			case WIDGET_UNITS.BITRATE_KBPS:
				value = parseFloat(value);
				value = isNaN(value)?0.0:value;
				value = Math.round(value * 1024.0);
				break;
			case WIDGET_UNITS.BITRATE_MBPS:
				value = parseFloat(value);
				value = isNaN(value)?0.0:value;
				value = Math.round(value * 1024.0 * 1024.0);
				break;
			default:
		}
	}

	return value;
}

export function parseBoolean(string)
{
	switch (String(string).toLowerCase())
	{
	  case "true":
	  case "1":
	  case "yes":
	  case "y":
		return true;
	  case "false":
	  case "0":
	  case "no":
	  case "n":
		return false;
	  default:
		//you could throw an error, but 'undefined' seems a more logical reply
		return undefined;
	}
}

export function isString(valueStr)
{
	return (typeof valueStr === 'string' || valueStr instanceof String);
}

export function parseBytesStr(valueStr)
{
	//console.log("valueStr:"+valueStr);

	if (isString(valueStr))
	{
		var multipliers = {};

		multipliers.B = 1;
		multipliers.K = ( multipliers.B * 1024 );
		multipliers.M = ( multipliers.K * 1024 );
		multipliers.G = ( multipliers.M * 1024 );
		multipliers.T = ( multipliers.G * 1024 );
		multipliers.P = ( multipliers.T * 1024 );
		multipliers.E = ( multipliers.P * 1024 );
		multipliers.Z = ( multipliers.E * 1024 );

		var multiplier = 1;
		var matches = valueStr.trim().toUpperCase().match( /([KMGTPEZ]I?)?B?P?S?$/ );
		//console.log(JSON.stringify((matches)));
		if (matches)
		{
			var unit = matches[ 0 ].substr(0, 1);

			//console.log("unit:"+JSON.stringify(unit));

			if (multipliers.hasOwnProperty(unit))
				multiplier = multipliers[unit];
			else
				multiplier = 1;
		}

		//console.log("multiplier:"+multiplier);

		return Math.round(parseFloat(valueStr) * multiplier);
	}
	else
		return parseInt(valueStr);
}

export function getIdentifierBroadcastInput(ident, broadcastInput)
{
	let input = undefined;

	if (broadcastInput.input !== undefined)
	{
		input = broadcastInput.input;
	}
	else if (broadcastInput.inputs !== undefined && broadcastInput.inputs.length > 0)
	{
		if (ident.inputTag !== undefined)
		{
			for(let key in broadcastInput.inputs)
			{
				if (broadcastInput.inputs[key].tag !== undefined && broadcastInput.inputs[key].tag === ident.inputTag)
				{
					input = broadcastInput.inputs[key];
					break;
				}
			}
		}
		else if (broadcastInput.inputs.length === 1)
			input = broadcastInput.inputs[0];
	}

	return input;
}

export function getIdentifierBroadcastExtraProperty(ident, broadcastInput)
{
	let extraProperty = undefined;

	if (broadcastInput.extraProperties !== undefined && broadcastInput.extraProperties.length > 0)
	{
		let indexMatch = ident.path.match(/extraProperties\/(.*)\//);
		if (indexMatch != null && indexMatch.length > 1)
		{
			for (let i = 0; i < broadcastInput.extraProperties.length; i++)
			{
				let e = broadcastInput.extraProperties[i];
				if (e['name'] === indexMatch[1])
				{
					extraProperty = e;
					break;
				}
			}
		}
	}

	return extraProperty;
}

export function getIdentifierBroadcastOutput(ident, broadcastInput)
{
	let output = undefined;

	if (broadcastInput.outputs !== undefined && broadcastInput.outputs.length > 0)
	{
		if (ident.outputTag !== undefined)
		{
			for(let key in broadcastInput.outputs)
			{
				if (broadcastInput.outputs[key].tag !== undefined && broadcastInput.outputs[key].tag === ident.outputTag)
				{
					output = broadcastInput.outputs[key];
					break;
				}
			}
		}
		else if (broadcastInput.outputs.length === 1)
			output = broadcastInput.outputs[0];
	}

	return output;
}

export function getBroadcastOutputStreamTarget(ident, output)
{
	let streamTarget = undefined;

	if (output.streamTargets !== undefined && output.streamTargets.length > 0)
	{
		if (ident.streamTargetTag !== undefined)
		{
			for(let key in output.streamTargets)
			{
				if (output.streamTargets[key].tag !== undefined && output.streamTargets[key].tag === ident.streamTargetTag)
				{
					streamTarget = output.streamTargets[key];
					break;
				}
			}
		}
		else if (output.streamTargets.length === 1)
			streamTarget = output.streamTargets[0];
	}

	return streamTarget;
}

export function getBroadcastOutputRecording(ident, output)
{
	let recording = undefined;

	if (output.recordings !== undefined && output.recordings.length > 0)
	{
		if (ident.recordingTag !== undefined)
		{
			for(let key in output.recordings)
			{
				if (output.recordings[key].tag !== undefined && output.recordings[key].tag === ident.recordingTag)
				{
					recording = output.recordings[key];
					break;
				}
			}
		}
		else if (output.recordings.length === 1)
			recording = output.recordings[0];
	}

	return recording;
}

export function getIdentifierBaseObj(ident, broadcastInput)
{
	let ret = undefined;

	switch(ident.type)
	{
		case "input":
		{
			ret = getIdentifierBroadcastInput(ident, broadcastInput);
			break;
		}
		case "output":
		{
			ret = getIdentifierBroadcastOutput(ident, broadcastInput);
			break;
		}
		case "encodingConfiguration":
		{
			let output = getIdentifierBroadcastOutput(ident, broadcastInput);
			if (output !== undefined)
				ret = output.encodingConfiguration;
			break;
		}
		case "encodingConfigurationVideo":
		{
			let output = getIdentifierBroadcastOutput(ident, broadcastInput);
			if (output !== undefined)
				ret = output.encodingConfiguration.encodingConfigurationVideo;
			break;
		}
		case "encodingConfigurationAudio":
		{
			let output = getIdentifierBroadcastOutput(ident, broadcastInput);
			if (output !== undefined)
				ret = output.encodingConfiguration.encodingConfigurationAudio;
			break;
		}
		case "streamTarget":
		{
			let output =getIdentifierBroadcastOutput(ident, broadcastInput);
			if (output !== undefined)
				ret = getBroadcastOutputStreamTarget(ident, output);
			break;
		}
		case "recording":
		{
			let output = getIdentifierBroadcastOutput(ident, broadcastInput);
			if (output !== undefined)
				ret = getBroadcastOutputRecording(ident, output);
			break;
		}
		case "extraProperty":
		{
			ret = getIdentifierBroadcastExtraProperty(ident, broadcastInput);
			break;
		}

		default:
	}

	return ret;
}

export function mergeEncodingParams(currParams, overrideParams)
{
	let newParams = [];

	let paramMap = {};
	for(let i in currParams)
	{
		paramMap[currParams[i].name] = currParams[i];
	}

	for(let i in overrideParams)
	{
		paramMap[overrideParams[i].name] = overrideParams[i];
	}

	for(let i in paramMap)
	{
		newParams.push(paramMap[i]);
	}

	return newParams;
}

export function overrideTemplateValues(template, broadcastInput)
{
	if (template.overrides !== undefined)
	{
		let overrides = template.overrides;

		if (overrides.input !== undefined)
		{
			for(let key in overrides.input)
			{
				broadcastInput.input[key] = overrides.input[key];
			}
		}

		if (overrides.transcodingConfiguration !== undefined)
		{
			broadcastInput.transcodingConfiguration = JSON.parse(JSON.stringify(overrides.transcodingConfiguration));
		}

		if (overrides.outputs && overrides.outputs.length > 0)
		{
			let outputsMap = {};
			for(let i in overrides.outputs)
			{
				let output = overrides.outputs[i];
				outputsMap[output.streamName] = output;
			}

			for(let i in broadcastInput.outputs)
			{
				let overrideOutput = outputsMap[broadcastInput.outputs[i].streamName];

				if (overrideOutput !== undefined && overrideOutput.encodingConfiguration !== undefined)
				{
					if (overrideOutput.encodingConfiguration.encodingConfigurationVideo !== undefined)
					{
						for(let key in overrideOutput.encodingConfiguration.encodingConfigurationVideo)
						{
							if (key === "parameters")
							{
								broadcastInput.outputs[i].encodingConfiguration.encodingConfigurationVideo[key] = mergeEncodingParams(broadcastInput.outputs[i].encodingConfiguration.encodingConfigurationVideo[key], overrideOutput.encodingConfiguration.encodingConfigurationVideo[key]);
							}
							else
							{
								broadcastInput.outputs[i].encodingConfiguration.encodingConfigurationVideo[key] = overrideOutput.encodingConfiguration.encodingConfigurationVideo[key];
							}
						}
					}

					if (overrideOutput.encodingConfiguration.encodingConfigurationAudio !== undefined)
					{
						for(let key in overrideOutput.encodingConfiguration.encodingConfigurationAudio)
						{
							if (key === "parameters")
							{
								broadcastInput.outputs[i].encodingConfiguration.encodingConfigurationAudio[key] = mergeEncodingParams(broadcastInput.outputs[i].encodingConfiguration.encodingConfigurationAudio[key], overrideOutput.encodingConfiguration.encodingConfigurationAudio[key]);
							}
							else
							{
								broadcastInput.outputs[i].encodingConfiguration.encodingConfigurationAudio[key] = overrideOutput.encodingConfiguration.encodingConfigurationAudio[key];
							}
						}
					}
				}
			}
		}
	}
}

export function extractTemplateVariables(obj, variables, context, path = undefined)
{
	for(let key in obj)
	{
		if (isString(obj[key]))
		{
			let varName = obj[key].trim();
			if (varName.startsWith("$"))
			{
				let ident = JSON.parse(JSON.stringify(context));

				ident.name = key;
				if (path !== undefined)
					ident.path = path+"/"+key;

				let entry = variables.hasOwnProperty(varName)?variables[varName]:{identifiers:[]};

				entry.identifiers.push(ident);

				variables[varName] = entry;
			}
		}
	}
}

export function isTemplateObjReadOnly(templateObj)
{
	return ((templateObj.isReadOnly !== undefined && templateObj.isReadOnly)?true:false);
}

export function isTemplateObjManageOnly(templateObj)
{
	return ((templateObj.isManageOnly !== undefined && templateObj.isManageOnly)?true:false);
}

export function isTemplateObjEnabled(templateObj)
{
	return ((templateObj.enabled !== undefined)?templateObj.enabled:true);
}

export function getCustomTemplatePackageV2(templateInfo, templateSet)
{
	let customTemplatePackage = {
		settings: {},
		templateCategories: {},
		templates: {}
	};

	if (templateInfo.settings)
	{
		customTemplatePackage.settings = templateInfo.settings;
	}

	if (templateInfo.templateCategoryOrder !== undefined && templateInfo.templateSets[templateSet] !== undefined && templateInfo.templates !== undefined)
	{
		for(let ci in templateInfo.templateCategoryOrder)
		{
			let templateCategoryId = templateInfo.templateCategoryOrder[ci];

			if (templateInfo.templateSets[templateSet].hasOwnProperty(templateCategoryId))
			{
				let templateCategory = templateInfo.templateSets[templateSet][templateCategoryId];

				let isReadyOnly = isTemplateObjReadOnly(templateCategory);

				//console.log(templateCategoryId+":"+isReadyOnly+"\n"+JSON.stringify(templateCategory));

				if (isReadyOnly)
					continue;

				customTemplatePackage.templateCategories[templateCategoryId] = JSON.parse(JSON.stringify(templateCategory));

				customTemplatePackage.templateCategories[templateCategoryId].templates = [];

				for(let ti in templateCategory.templates)
				{
					let templateId = templateCategory.templates[ti];

					if (templateInfo.templates.hasOwnProperty(templateId))
					{
						let template = templateInfo.templates[templateId];

						customTemplatePackage.templateCategories[templateCategoryId].templates.push(templateId);

						customTemplatePackage.templates[templateId] = JSON.parse(JSON.stringify(template));
					}
					else
						console.log("ERROR: getCustomTemplatePackageV2: Missing template: "+templateId);
				}

				if (!customTemplatePackage.templateCategories[templateCategoryId].templates.includes(customTemplatePackage.templateCategories[templateCategoryId].defaultTemplate))
				{
					let defaultTemplate = undefined;
					if (customTemplatePackage.templateCategories[templateCategoryId].templates.length > 0)
					{
						defaultTemplate = customTemplatePackage.templateCategories[templateCategoryId].templates[0];
					}

					console.log("WARNING: getCustomTemplatePackageV2: Default template not found ["+customTemplatePackage.templateCategories[templateCategoryId].defaultTemplate+"], setting to: "+defaultTemplate);

					customTemplatePackage.templateCategories[templateCategoryId].defaultTemplate = defaultTemplate;
				}
			}
			else
				console.log("ERROR: getCustomTemplatePackageV2: Missing template category: "+templateCategoryId);
		}
	}

	return customTemplatePackage;
}

export function getPhantomRecording(output)
{
	if (output.recordings !== undefined)
	{
		for(let ri in output.recordings)
		{
			let recording = output.recordings[ri];

			if (recording.tag !== undefined && recording.tag === "recordingUI")
			{
				return recording;
			}
		}
	}

	return undefined;
}

export function addPhantomValuesToBroadcast(template, broadcast, insertVariableName = false)
{
	if (template.variables !== undefined)
	{
		for(let varName in template.variables)
		{
			let variable = template.variables[varName];

			for(let di in variable.identifiers)
			{
				let ident = variable.identifiers[di];

				if (ident.type === "output" && ident.name === "record")
				{
					let baseObj = getIdentifierBaseObj(ident, broadcast);

					if (baseObj !== undefined)
					{
						let recording = getPhantomRecording(baseObj);

						baseObj.record = insertVariableName?varName:(recording !== undefined);
					}
				}
			}
		}
	}
}

export function generateTemplateVariables(templateIn)
{
	let variables = {};

	if (templateIn.template !== undefined)
	{
		let template = templateIn.template;

		let context = undefined;

		let isMultiInput = false;

		let inputs = [];
		if (template.inputs !== undefined)
		{
			inputs = template.inputs
			isMultiInput = true;
		}
		else if (template.input !== undefined)
			inputs.push(template.input);

		for(let i in inputs)
		{
			let input = inputs[i];
			context = {type:"input"};

			if (input.tag !== undefined)
				context.inputTag = input.tag;

			let path = "template/input"+(isMultiInput?"s/"+i:"");

			extractTemplateVariables(input, variables, context, path);
		}

		if (template.extraProperties !== undefined)
		{
			for(let i in template.extraProperties)
			{
				let extraProperty = template.extraProperties[i];
				context = {type:"extraProperty"};

				if (extraProperty.tag !== undefined)
					context.extraPropertyTag = extraProperty.tag;

				let path = "template/extraProperties/"+extraProperty['name'];

				extractTemplateVariables(extraProperty, variables, context, path);
			}
		}

		if (template.outputs !== undefined)
		{
			for(let i in template.outputs)
			{
				let output = template.outputs[i];

				context = {type:"output"};

				if (output.tag !== undefined)
					context.outputTag = output.tag;
				else
					delete context.outputTag;

				let path = "template/outputs/"+i;

				extractTemplateVariables(output, variables, context, path);

				if (output.streamTargets !== undefined)
				{
					context.type = "streamTarget";

					for(let s in output.streamTargets)
					{
						let streamTarget = output.streamTargets[s];

						if (streamTarget.tag !== undefined)
							context.streamTargetTag = streamTarget.tag;
						else
							delete context.streamTargetTag

						extractTemplateVariables(streamTarget, variables, context, path+"/streamTargets/"+s);
					}
				}

				if (output.recordings !== undefined)
				{
					context.type = "recording";

					for(let s in output.recordings)
					{
						let recording = output.recordings[s];

						if (recording.tag !== undefined)
							context.recordingTag = recording.tag;
						else
							delete context.recordingTag

						extractTemplateVariables(recording, variables, context, path+"/recordings/"+s);
					}
				}

				if (output.encodingConfiguration !== undefined)
				{
					context.type = "encodingConfiguration";
					extractTemplateVariables(output.encodingConfiguration, variables, context, path+"/encodingConfiguration");

					if (output.encodingConfiguration.encodingConfigurationVideo !== undefined)
					{
						context.type = "encodingConfigurationVideo";
						extractTemplateVariables(output.encodingConfiguration.encodingConfigurationVideo, variables, context, path+"/encodingConfiguration/encodingConfigurationVideo");
					}

					if (output.encodingConfiguration.encodingConfigurationAudio !== undefined)
					{
						context.type = "encodingConfigurationAudio";
						extractTemplateVariables(output.encodingConfiguration.encodingConfigurationAudio, variables, context, path+"/encodingConfiguration/encodingConfigurationAudio");
					}
				}
			}
		}
	}

	return variables;
}

function resolvePath(path, lastElement = undefined)
{
	let pathStr = "";

	for(let i in path)
	{
		pathStr += path[i];
	}

	if (lastElement !== undefined)
	{
		pathStr += "/"+lastElement;
	}

	return pathStr;
}

export function hashFnv32a(str, asString, seed)
{
    /*jshint bitwise:false */
    var i, l,
        hval = (seed === undefined) ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    if( asString ){
        // Convert to 8 digit hex string
        return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
    }
    return hval >>> 0;
}

function createErrorMessage(pathStr, errorType, errorMessage)
{
	let newError = {
		id: hashFnv32a(pathStr+":"+errorType+":"+errorMessage, true),
		path: pathStr,
		type: errorType,
		text: errorMessage
	};
	return newError;
}

// const ERROR_WARN = "warning";
const ERROR_ERROR = "error";

export function testTemplatePackage(json)
{
	var widgetDefs = {
		OutputDetails:{
			parameters: {
				output:{
					outputTag: "outputTag"
				}
			}
		},
		StreamTargetRTMPWithCredentials: {
			parameters: {
				url:{
					variable: "variable"
				},
				streamName:{
					variable: "variable"
				},
				username:{
					variable: "variable"
				},
				password:{
					variable: "variable"
				},
			}
		},
		StreamTargetRTMPNoCredentials: {
			parameters: {
				url:{
					variable: "variable"
				},
				streamName:{
					variable: "variable"
				},
			}
		},
		StreamTargetProvider: {
			parameters: {
				providerKeyId:{
					variable: "variable"
				},
				providerId:{
					variable: "variable"
				},
			}
		},
		TextEnterInt: {
			parameters: {
				value: {
					variable: "variable"
				}
			}
		},
		TextEnterFloat: {
			parameters: {
				value: {
					variable: "variable"
				}
			}
		},
		TextEnterString: {
			parameters: {
				value: {
					variable: "variable"
				}
			}
		},
		Bitrate: {
			parameters: {
				bitrate: {
					variable: "variable"
				}
			}
		},
		VideoProfileH264: {
			parameters: {
				profile: {
					variable: "variable"
				}
			}
		},
		VideoImplementationH264: {
			parameters: {
				implementation: {
					variable: "variable"
				}
			}
		},
		VideoFrameSizeFitMode: {
			parameters: {
				frameSizeFitMode: {
					variable: "variable"
				}
			}
		},
		CheckboxSimple: {
			parameters: {
				value: {
					variable: "variable"
				}
			}
		},
		SelectCustom: {
			parameters: {
			}
		},
		CheckboxCustom: {
			parameters: {
			}
		},
	}

	let errorsText = "";
	let infoText = "";
	let errorList = [];

	let referencedVariables = [];
	let referencedTemplates = [];

	try
	{
		let path = [];

		if (json.templateCategories !== undefined)
		{
			infoText += "=== Categories ===\n";

			let index = 0;
			for(let key in json.templateCategories)
			{
				let category = json.templateCategories[key];

				path.push("/templateCategories/"+key);

				infoText += "\tid: "+key+"\n";

				if (category.name !== undefined)
					infoText += "\tname: "+category.name+"\n";
				else
				{
					errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "name field is missing"));
					errorsText += "Error: category["+key+"]: name missing\n";
				}

				if (category.defaultTemplate !== undefined)
				{
					infoText += "\tdefaultTemplate: "+category.defaultTemplate+"\n";

					if (json.templates[category.defaultTemplate] === undefined)
					{
						errorList.push(createErrorMessage(resolvePath(path, "defaultTemplate"), ERROR_ERROR, "defaultTemplate references non-existent template ["+category.defaultTemplate+"]"));
						errorsText += "Error: category["+key+"]: defaultTemplate references non-existent template: "+category.defaultTemplate+"\n";
					}
				}
				else
				{
					errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "defaultTemplate field is missing"));
					errorsText += "Error: category["+key+"]: defaultTemplate missing\n";
				}

				if (category.templates !== undefined)
				{
					infoText += "\ttemplates: ["+category.templates+"]\n";

					for(let i in category.templates)
					{
						let templateId = category.templates[i];

						if (!referencedTemplates.includes(templateId))
							referencedTemplates.push(templateId);

						if (json.templates[templateId] === undefined)
						{
							errorList.push(createErrorMessage(resolvePath(path, "templates"), ERROR_ERROR, "templates list references non-existent template ["+templateId+"]"));
							errorsText += "Error: category["+key+"]: templates list references non-existent template: "+templateId+"\n";
						}
					}
				}
				else
				{
					errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "templates field is missing"));
					errorsText += "Error: category["+key+"]: templates missing\n";
				}

				path.pop();

				infoText += "\t------------------\n";
				index++;
			}

			if (index <= 0)
			{
				errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "templateCategories is empty"));
				errorsText += "Error: templateCategories is empty\n";
			}
		}
		else
		{
			errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "templateCategories is missing"));
			errorsText += "Error: templateCategories is missing\n";
		}

		if (json.templates !== undefined)
		{
			infoText += "\n=== Templates ===\n";

			let index = 0;
			for(let key in json.templates)
			{
				let template = json.templates[key];

				path.push("/templates/"+key);

				if (template.variables === undefined)
				{
					template.variables = generateTemplateVariables(template);
				}

				if (!referencedTemplates.includes(key))
				{
					errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "template not referenced in any categories"));
					errorsText += "Error: template["+key+"]: not referenced in any categories\n";
				}

				infoText += "\tid: "+key+"\n";

				if (template.name !== undefined)
					infoText += "\tname: "+template.name+"\n";
				else
				{
					errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "name field is missing"));
					errorsText += "Error: template["+key+"]: name missing\n";
				}

				if (template.type !== undefined)
					infoText += "\ttype: "+template.type+"\n";
				else
				{
					//errorsText += "Error: template["+key+"]: type missing\n";
				}

				if (template.presentation !== undefined)
				{
					var presentation = template.presentation;

					path.push("/presentation");

					if (presentation.layout !== undefined)
					{
						infoText += "\tpresentation.layout: "+presentation.layout+"\n";

					}
					else
					{
						errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "name field is missing"));
						errorsText += "Error: template["+key+"].presentation.layout missing\n";
					}

					if (presentation.widgets !== undefined)
					{
						infoText += "\tpresentation.widgets: "+presentation.widgets.length+"\n";

						for(let i in presentation.widgets)
						{
							let widget = presentation.widgets[i];

							path.push("/widgets/"+i);

							if (widget.widget !== undefined)
							{
								if (widgetDefs[widget.widget] !== undefined)
								{
									let widgetDef = JSON.parse(JSON.stringify(widgetDefs[widget.widget]));

									if (widget.widget === "CheckboxCustom" || widget.widget === "SelectCustom")
									{
										for(let paramName in widget.parameters)
										{
											widgetDef.parameters[paramName] = { variable: "variable" };
										}

										if (widget.options !== undefined)
										{
											path.push("/options");

											for(let wo in widget.options)
											{
												path.push("/"+wo);

												let option = widget.options[wo];
												for(let wi in option)
												{
													if (!widget.parameters.hasOwnProperty(wi))
													{
														errorList.push(createErrorMessage(resolvePath(path, wi), ERROR_ERROR, "option field ["+wi+"] is not in parameters"));
													}
												}

												path.pop();
											}

											path.pop();
										}
									}

									for(let paramName in widgetDef.parameters)
									{
										let paramDef = widgetDef.parameters[paramName];

										if (widget.parameters[paramName] !== undefined)
										{
											let param = widget.parameters[paramName];

											path.push("/parameters/"+paramName);

											for(let pa in paramDef)
											{
												let paramItem = paramDef[pa];

												if (param[pa] !== undefined)
												{
													if (paramItem === "variable")
													{
														var variableName = param[pa];

														if (template.variables[variableName] === undefined)
														{
															errorList.push(createErrorMessage(resolvePath(path, pa), ERROR_ERROR, "variable ["+variableName+"] not found in variables collection"));
															errorsText += "Error: template["+key+"].presentation.widgets["+i+"].parameters["+paramName+"]: Variable name not found in variables collection: "+variableName+"\n";
														}

														if (referencedVariables.includes(variableName))
														{
															errorList.push(createErrorMessage(resolvePath(path, pa), ERROR_ERROR, "variable ["+variableName+"] referenced more than once"));
															errorsText += "Error: template["+key+"].presentation.widgets["+i+"].parameters["+paramName+"]: Variable name referenced more than once: "+variableName+"\n";
														}
														else
															referencedVariables.push(variableName);
													}
												}
												else
												{
													errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "required parameter ["+pa+"] is missing"));
													errorsText += "Error: template["+key+"].presentation.widgets["+i+"].parameters["+paramName+"]: Required field missing: "+pa+"\n";
												}
											}

											path.pop();
										}
										else
										{
											errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "required parameter ["+paramName+"] is missing"));
											errorsText += "Error: template["+key+"].presentation.widgets["+i+"].parameters: Required parameter missing: "+paramName+"\n";
										}
									}
								}
								else
								{
									errorList.push(createErrorMessage(resolvePath(path, "widget"), ERROR_ERROR, "invalid widget type ["+widget.widget+"]"));
									errorsText += "Error: template["+key+"].presentation.widgets["+i+"].widget invalid widget type: "+widget.widget+"\n";
								}
							}
							else
							{
								errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "widget field is missing"));
								errorsText += "Error: template["+key+"].presentation.widgets["+i+"].widget missing\n";
							}

							path.pop();
						}
					}
					else
					{
						errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "widgets field is missing"));
						errorsText += "Error: template["+key+"].presentation.widgets missing\n";
					}

					path.pop();
				}
				else
				{
					errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "presentation field is missing"));
					errorsText += "Error: template["+key+"].presentation missing\n";
				}

				if (template.variables !== undefined)
				{
					for(let varName in template.variables)
					{
						infoText += "\tvariable: "+varName+"\n";

						let vari = template.variables[varName];

						if (vari.identifiers != null && vari.identifiers.length > 0)
						{
							for(let vi in vari.identifiers)
							{
								let ident = vari.identifiers[vi];

								var errors = [];

								// var baseObj = getIdentifierBaseObj(ident, template.template, errors);

								let varPath = ident.path!==undefined?ident.path:"";

								for(var ie in errors)
								{
									errorList.push(createErrorMessage(resolvePath(path, varPath), ERROR_ERROR, errors[ie]));
									errorsText += "Error: template["+key+"].variables["+varName+"].identifiers["+vi+"]: "+errors[ie]+"\n";
								}

								if (!referencedVariables.includes(varName))
								{
									errorList.push(createErrorMessage(resolvePath(path, varPath), ERROR_ERROR, "variable ["+varName+"] not referenced in presentation"));
									errorsText += "Error: template["+key+"].variables["+varName+"]: Not referenced in presentation\n";
								}
							}
						}
						else
						{
							errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "no variables are defined"));
							errorsText += "Error: template["+key+"].variables["+varName+"]: No identifiers\n";
						}
					}
				}
				else
				{
					errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "variables field is missing"));
					errorsText += "Error: template["+key+"].variables missing\n";
				}

				if (template.template !== undefined)
				{
					path.push("/template");

					if (template.template.outputs !== undefined && template.template.outputs.length > 0)
					{
						for(let oi in template.template.outputs)
						{
							let output = template.template.outputs[oi];

							path.push("/outputs/"+oi);

							let outputId = output.tag!==undefined?output.tag:oi+"";

							let fields = ["streamName"];

							for(let fi in fields)
							{
								let field = fields[fi];
								if (output[field] === undefined)
								{
									errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, field+" field is missing"));
									errorsText += "Error: template["+key+"].template.output["+outputId+"]: "+field+" not defined\n";
								}
							}

							if (output.encodingConfiguration !== undefined)
							{
								path.push("/encodingConfiguration");

								let encodingConfiguration = output.encodingConfiguration;

								let fields = ["name"];

								for(let fi in fields)
								{
									let field = fields[fi];
									if (encodingConfiguration[field] === undefined)
									{
										errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, field+" field is missing"));
										errorsText += "Error: template["+key+"].template.output["+outputId+"].encodingConfiguration: "+field+" not defined\n";
									}
								}

								if (encodingConfiguration.encodingConfigurationVideo !== undefined)
								{
									path.push("/encodingConfigurationVideo");

									let ecVideo = encodingConfiguration.encodingConfigurationVideo;

									let fields = ["codec","implementation","frameSizeFitMode","frameSizeWidth","frameSizeHeight","profile","bitrate","bitrateMin"];

									for(let fi in fields)
									{
										let field = fields[fi];
										if (ecVideo[field] === undefined)
										{
											errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, field+" field is missing"));
											errorsText += "Error: template["+key+"].template.output["+outputId+"].encodingConfiguration.encodingConfigurationVideo: "+field+" not defined\n";
										}
									}

									path.pop();
								}
								else
								{
									errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "encodingConfigurationVideo field is missing"));
									errorsText += "Error: template["+key+"].template.output["+outputId+"].encodingConfiguration: encodingConfigurationVideo not defined\n";
								}

								if (encodingConfiguration.encodingConfigurationAudio !== undefined)
								{
									path.push("/encodingConfigurationAudio");

									let ecAudio = encodingConfiguration.encodingConfigurationAudio;

									let fields = ["codec","bitrate"];

									for(let fi in fields)
									{
										let field = fields[fi];
										if (ecAudio[field] === undefined)
										{
											errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, field+" field is missing"));
											errorsText += "Error: template["+key+"].template.output["+outputId+"].encodingConfiguration.encodingConfigurationAudio: "+field+" not defined\n";
										}
									}

									path.pop();
								}
								else
								{
									errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "encodingConfigurationAudio field is missing"));
									errorsText += "Error: template["+key+"].template.output["+outputId+"].encodingConfiguration: encodingConfigurationAudio not defined\n";
								}

								path.pop();
							}
							else
							{
								errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "encodingConfiguration field is missing"));
								errorsText += "Error: template["+key+"].template.output["+outputId+"]: encodingConfiguration not defined\n";
							}

							path.pop();
						}
					}
					else
					{
						errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "outputs field is missing"));
						errorsText += "Error: template["+key+"].template: No outputs defined\n";
					}

					path.pop();
				}
				else
				{
					errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "template field is missing"));
					errorsText += "Error: template["+key+"].template missing\n";
				}

				path.pop();

				infoText += "\t------------------\n";
				index++;
			}

			if (index <= 0)
			{
				errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "templates is empty"));
				errorsText += "Error: templates is empty\n";
			}
		}
		else
		{
			errorList.push(createErrorMessage(resolvePath(path), ERROR_ERROR, "templates is missing"));
			errorsText += "Error: templates is missing\n";
		}
	}
	catch(error)
	{
		errorsText += "ERROR[exception]: "+error.message + "\n";
	}

	return {
		errorList: errorList,
		errorsText: errorsText,
		infoText: infoText
	};
}
