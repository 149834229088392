import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

class WSCAccessKeyConfirmDeleteDialog extends Component
{
	constructor() {
		
		super();

		this.handleDelete = this.handleDelete.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	onShow()
	{

	}

	handleClose()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_WSCACCESSKEYCONFIRMDELETE_DIALOG,
		});
	}

	handleDelete()
	{
		let accessKeyId = this.props.clearcaster.wscAccessKeyConfirmDelete.accessKeyId;

		let _this = this;

		console.log(JSON.stringify(accessKeyId));

		this.props.controller.mutationDeleteProviderKey(accessKeyId).then((resp) => {
			console.log(JSON.stringify(resp));
			_this.props.controller.forcePolling();
		});

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_WSCACCESSKEYCONFIRMDELETE_DIALOG,
		});
	}

	render()
    {
		let appStrings = this.props.strings;

		return (
			<Modal show={ this.props.clearcaster.wscAccessKeyConfirmDelete !== undefined } onShow={ this.onShow } onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{ appStrings.app.DeleteWSCAccessKey }</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{ appStrings.app.DeleteWSCAccessKeyConfirm }
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
					<Button variant="primary" onClick={this.handleDelete}>{ appStrings.app.DeleteWSCAccessKey }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WSCAccessKeyConfirmDeleteDialog;
