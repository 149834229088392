// manages an array of nearRealTimeData events

import { compareObjectValues } from '../controller/ClearCasterAdminUtils';

class NearRealTimeDataCache
{
	static insertEvent(data, event)
	{
		return NearRealTimeDataCache.insertEvents(data,[event]);
	}
	static insertEvents(data, events)
	{
		let newArray = [];
		let dataIndex = 0;
		let eventsIndex = 0;
		if (data.length > 0 || events.length > 0)
		{
			while (dataIndex < data.length || eventsIndex < events.length)
			{
				if (dataIndex >= data.length)
				{
					newArray.push(events[eventsIndex++]);
				}
				else if (eventsIndex >= events.length)
				{
					newArray.push(data[dataIndex++]);
				}
				else if (data[dataIndex].eventId === events[eventsIndex].eventId)
				{
					newArray.push(data[dataIndex++]);
					eventsIndex++;
				}
				else
				{
					if (data[dataIndex].eventId < events[eventsIndex].eventId)
					{
						newArray.push(data[dataIndex++]);
					}
					else
					{
						newArray.push(events[eventsIndex++]);
					}
				}
			}
		}
		return newArray;
	}

	static trim(data, startTime, endTime)
	{
		let newArray = [];
		for (let i = 0; i < data.length; i++)
		{
			let eventTime = new Date(data[i].eventTime);
			if (eventTime >= startTime && eventTime <= endTime)
			{
				newArray.push(data[i]);
			}
		}
		return newArray;
	}

	static bitrateChartAdapter(data)
	{
		let adapter = {
			outputs:[]
		};
		for (let i = 0; i < data.length; i++)
		{
			let outputs = [...data[i].encoder.outputs].sort(compareObjectValues('videoBitrate','desc'));

			for (let j = 0; j < outputs.length; j++)
			{
				let newPoint = {};
				newPoint.eventId = data[i].eventId;
				newPoint.eventTime = data[i].eventTime;
				newPoint.bitrateTarget = outputs[j].videoBitrate;
				newPoint.bitrateAvailable = outputs[j].videoBitrateCurrent;
				newPoint.bitrateActual = outputs[j].videoBitrateMeasure;
				if (adapter.outputs[j] === undefined)
				{
					adapter.outputs[j] = [];
				}
				adapter.outputs[j].push(newPoint);
			}
		}
		return adapter;
	}

	static networkChartAdapter(data)
	{
		//console.log("networkChartAdapter:\n"+JSON.stringify(data, null, 2));

		let adapter = {
			streamTargets:[]
		};

		if (data !== null && data !== undefined)
		{
			for (let i = 0; i < data.length; i++)
			{
				for (let j = 0; j < data[i].streamTargets.length; j++)
				{
					let newPoint = {};
					newPoint.eventId = data[i].eventId;
					newPoint.eventTime = data[i].eventTime;
					newPoint.url = data[i].streamTargets[j].url;
					newPoint.pingTime = data[i].streamTargets[j].pingTime;

					newPoint.rtt = 0;
					if (data[i].streamTargets[j].ssNetworkInfo)
					{
						newPoint.rtt = data[i].streamTargets[j].ssNetworkInfo.rtt;
					}
					else if (data[i].streamTargets[j].srtNetworkInfo)
					{
						newPoint.rtt = data[i].streamTargets[j].srtNetworkInfo.msRTT;
					}

					//if (newPoint.rtt == 0)
					//	console.log("WOWOWOW:\n"+JSON.stringify(data, null, 2));

					newPoint.pushpubConnectAttemptCount = data[i].streamTargets[j].pushpubConnectAttemptCount;
					if (adapter.streamTargets[j] === undefined)
					{
						adapter.streamTargets[j] = [];
					}
					adapter.streamTargets[j].push(newPoint);
				}
			}
		}
		return adapter;
	}

	static applianceChartAdapter(data)
	{
		let adapter = {
			appliance:[]
		};

		let initialCoreThrottleCount = 0;
		let initialPackageThrottleCount = 0;
		for (let i = 0; i < data.length; i++)
		{
			if (i === 0)
			{
				initialCoreThrottleCount = data[i].hardwareDynamic.cpuCoreInfo[0].coreThrottleCount;
				initialPackageThrottleCount = data[i].hardwareDynamic.cpuCoreInfo[0].packageThrottleCount;
			}
			let newPoint = {};
			newPoint.eventId = data[i].eventId;
			newPoint.eventTime = data[i].eventTime;
			newPoint.cpuTemp = data[i].hardwareDynamic.cpuTemperature;
			newPoint.cpuLoad = (data[i].hardwareDynamic.cpuLoadSystem * 100).toFixed(2);
			newPoint.coreThrottleCount = data[i].hardwareDynamic.cpuCoreInfo[0].coreThrottleCount - initialCoreThrottleCount;
			newPoint.packageThrottleCount = data[i].hardwareDynamic.cpuCoreInfo[0].packageThrottleCount - initialPackageThrottleCount;
			newPoint.captureCardTemp = data[i].encoder.captureSessionInfo.temperature;
			adapter.appliance.push(newPoint);
		}
		return adapter;
	}

	static framesChartAdapter(data)
	{
		let adapter = {
			frames:[]
		};
		if (data !== null && data !== undefined)
		{
			for (let i = 0; i < data.length; i++)
			{
				let newPoint = {};
				newPoint.eventId = data[i].eventId;
				newPoint.eventTime = data[i].eventTime;
				newPoint.droppedFrames = data[i].encoder.videoDecoderCaptureInfo.droppedFrames + data[i].encoder.captureSessionInfo.videoDroppedFrames;
				newPoint.totalFrames = data[i].encoder.videoDecoderCaptureInfo.frameCountTotal;
				adapter.frames.push(newPoint);
			}
		}
		return adapter;
	}

}
export default NearRealTimeDataCache;