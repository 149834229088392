import React, { Component } from 'react';
import { Button, Form, InputGroup, Dropdown, Modal } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import SingularLiveGraphicsController from '../../../../controller/SingularLiveGraphicsController';
import {
	textColorFromBackground,
	showSlate,
	colorChange,
	formChange,
	isDataDirty,
	persistData,
	graphics_componentDidMount,
	cancel
} from './ManageGraphicsUtils';
import LoadingOverlay from 'react-loading-overlay';


class ManageGraphicsLowerThird extends Component
{
	componentDidMount()
	{
		graphics_componentDidMount(this.props.clearcaster.singularLiveData,this.props.appInstance,this.props.graphic);
	}

	render()
	{
		let singularLiveData = this.props.clearcaster.singularLiveData;
		let appInstance = this.props.appInstance;
		let previewAppInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,appInstance.preview);
		let pageState = SingularLiveGraphicsController.getData(singularLiveData,"manage");
		if (pageState == null)
		{
			return;
		}
		let compositionData = SingularLiveGraphicsController.getData(singularLiveData,previewAppInstance.id);
		if (compositionData == null)
		{
			compositionData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
		}
		if (compositionData == null)
		{
			compositionData = SingularLiveGraphicsController.getDefaultData();
		}

		let lower3rdData = compositionData[this.props.graphic].compositions[0].controlNode.payload;
		if (lower3rdData == null)
		{
			return (
				<div>{this.props.strings.app.ManageGraphicsNoDataFound}</div>
			);
		}

		let previewUrl = previewAppInstance.definition.onair_url;

		let isDirty = isDataDirty(singularLiveData,appInstance.id,lower3rdData);

		return (
			<Modal size="lg" show={ true } onHide={cancel(singularLiveData,previewAppInstance)} className="manage-graphics-lower-third">

				<LoadingOverlay active={ pageState.saving } spinner text={ this.props.strings.app.ManageGraphicsSaving } >
					<Modal.Header closeButton>
						<Modal.Title>{this.props.strings.app.ManageGraphicsEditLowerThird}</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<div className="row">
							<div className="col-lg-4">
								<Form>
									<div className="integration-heading">{this.props.strings.app.ManageGraphicsEditGraphics}</div>

									<Form.Group controlId="formLowerThirdText">
										<Form.Label>
											{this.props.strings.app.ManageGraphicsText}
										</Form.Label>
										<InputGroup>
											<Form.Control type="text"
														  value={lower3rdData.Text}
														  onChange={evt => formChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,{ Text: evt.target.value })}
														  onBlur={showSlate(previewAppInstance,this.props.graphic,compositionData)}
											/>
											<Dropdown as={InputGroup.Append}>
												<Dropdown.Toggle variant="light"
																 className="color-picker text-right pl-0 pr-1"
																 style={{
																	 width:"100px",
																	 backgroundColor:lower3rdData.TextColor
																 }}
												>
												<span style={{
													color:textColorFromBackground(lower3rdData.TextColor)
												}}>
												{lower3rdData.TextColor.toLowerCase()} ▼
												</span>
												</Dropdown.Toggle>

												<Dropdown.Menu alignRight>
													<SketchPicker
														color={lower3rdData.TextColor}
														onChangeComplete={colorChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,"TextColor")}
													/>
												</Dropdown.Menu>
											</Dropdown>
										</InputGroup>
									</Form.Group>

									<Form.Group controlId="formLowerThirdBaseColor">
										<Form.Label>
											{this.props.strings.app.ManageGraphicsBaseColor}
										</Form.Label>
										<Dropdown>
											<Dropdown.Toggle variant="light"
															 className="color-picker text-right w-100"
															 style={{
																 backgroundColor:lower3rdData.Base
															 }}
											>
												<span style={{
													color:textColorFromBackground(lower3rdData.Base)
												}}>
													{lower3rdData.Base.toLowerCase()} ▼
												</span>
											</Dropdown.Toggle>

											<Dropdown.Menu alignRight>
												<SketchPicker
													color={lower3rdData.Base}
													onChangeComplete={colorChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,"Base")}
												/>
											</Dropdown.Menu>
										</Dropdown>
									</Form.Group>

									<Form.Group controlId="formLowerThirdBevel">
										<Form.Label>
											{this.props.strings.app.ManageGraphicsBevelColor}
										</Form.Label>
										<Dropdown>
											<Dropdown.Toggle variant="light"
															 className="color-picker text-right w-100"
															 style={{
																 backgroundColor:lower3rdData.Bevel
															 }}
											>
											<span style={{
												color:textColorFromBackground(lower3rdData.Bevel)
											}}>
												{lower3rdData.Bevel.toLowerCase()} ▼
											</span>
											</Dropdown.Toggle>

											<Dropdown.Menu alignRight>
												<SketchPicker
													color={lower3rdData.Bevel}
													onChangeComplete={colorChange(singularLiveData,previewAppInstance,appInstance,this.props.graphic,compositionData,"Bevel")}
												/>
											</Dropdown.Menu>
										</Dropdown>
									</Form.Group>

								</Form>
							</div>
							<div className="col-lg-8">
								{ previewUrl !== null &&
								<>
									<div className="integration-heading">{this.props.strings.app.ManageGraphicsPreview}</div>
									<div style={{position:"relative",overflow:"hidden",height:"0",paddingTop:"56.25%",backgroundColor:"#000",border:"0"}}>
										<iframe title="preview graphics" style={{position:"absolute",top:"0",left:"0",height:"100%",width:"100%",border:"0"}} src={previewUrl}></iframe>
									</div>
								</>
								}
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className="w-100 mb-2" onClick={cancel(singularLiveData,previewAppInstance)} variant="secondary"><i className="fa fa-ban mr-2"></i>Cancel</Button>
						<Button className="w-100 mb-2" disabled={!isDirty} onClick={persistData(this.props.controller,previewAppInstance,appInstance,singularLiveData)} variant={isDirty?"primary":"outline-secondary"}><i className="fa fa-floppy-o mr-2"></i>Save Graphics</Button>
					</Modal.Footer>
				</LoadingOverlay>
			</Modal>
		);
	}

}
export default ManageGraphicsLowerThird;