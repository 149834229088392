import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { healthStatusClasses } from '../../../model/BroadcastUIAdapter';
import HelpTooltip from '../../shell/HelpTooltip';

class BroadcastItemMonitorDestinations extends Component
{
	renderStreamTarget(streamTarget,key)
	{
		let streamTargetConnected = false;
		let streamTargetWarning = false;
		if (streamTarget.enable)
		{
			if (streamTarget !== null &&
				streamTarget['nearRealTimeData'] !== undefined &&
				streamTarget['nearRealTimeData'] !== null)
			{
				streamTargetConnected = (
					streamTarget['nearRealTimeData'].pushpubConnectLastSuccess > 0 &&
					streamTarget['nearRealTimeData'].pushpubConnectLastAttempt > 0 &&
					streamTarget['nearRealTimeData'].pushpubConnectLastSuccess >= streamTarget['nearRealTimeData'].pushpubConnectLastAttempt
				);
			}
			if (streamTargetConnected)
			{
				if (streamTarget['nearRealTimeData']['ssNetworkInfo'])
				{
					if (streamTarget['nearRealTimeData']['ssNetworkInfo']['rtt'] >= 100)
						streamTargetWarning = true;
				}
				else if (streamTarget['nearRealTimeData']['srtNetworkInfo'])
				{
					if (streamTarget['nearRealTimeData']['srtNetworkInfo']['rtt'] >= 100)
						streamTargetWarning = true;
				}
			}
		}

		return (
			<div className="row" key={key}>
				<div className="col-6 p-1 border-left border-right border-dark">
					<div style={{position:"absolute",top:"0.5em",left:"0.5em"}}>
						{ streamTarget.enable ?
							<>
								{ streamTargetConnected && !streamTargetWarning &&
								<i className="fa fa-check-circle fa-lg text-success"></i>
								}
								{ streamTargetConnected && streamTargetWarning &&
								<i className="fa fa-check-circle fa-lg text-warning"></i>
								}
								{ !streamTargetConnected &&
								<i className="fa fa-exclamation-circle fa-lg text-danger"></i>
								}
							</> :
							<i className="fa fa-times-circle fa-lg text-muted"></i>
						}
					</div>
					<div className="d-inline-block align-top w-100 pl-4">
						<div className="d-block text-truncate">
							{streamTarget.streamName}
						</div>
						<div className="d-block text-secondary text-truncate">
							{streamTarget.url}
						</div>
					</div>
				</div>
				<div className="col-4 border-right border-dark">
				</div>
				<div className="col-2 bg-light border-right border-dark">
				</div>
			</div>
		);
	}

	renderOutput(output,key)
	{
		let backgroundClass = "bg-"+(key%6);

		return (
			<div key={key}>
				<div className="row">
					<div className={backgroundClass + " col-6 p-1 border-left border-right border-bottom border-dark"}>
						{output.streamName}
					</div>
					<div className={backgroundClass + " col-2 p-0 text-center border-right border-bottom border-dark"}>
						<div className={backgroundClass + " label-available"}>
							{(output.availableBitrate/1024/1024).toFixed(2)}
						</div>
					</div>
					<div className={backgroundClass + " col-2 p-0 text-center border-right border-bottom border-dark"}>
						<div className={backgroundClass + " label-target"}>
							{(output.targetBitrate/1024/1024).toFixed(2)}
						</div>
					</div>
					<div className={backgroundClass + " col-2 p-0 text-center border-right border-bottom border-dark"}>
						<div className={backgroundClass + " label-actual"}>
							{(output.actualBitrate/1024/1024).toFixed(2)}
						</div>
					</div>
				</div>
				{output.streamTargets.map((target,key)=> { return this.renderStreamTarget(target,key);})}
				<div className="row">
					<div className="col-6 border-left border-right border-bottom border-dark">
					</div>
					<div className="col-4 text-right p-1 bg-light border-top border-bottom border-dark">
						{this.props.strings.app.MonitoringDestinationsOutputBandwidth({streamName:output.streamName})}
					</div>
					<div className="col-2 text-center p-1 bg-light border-right border-bottom border-dark">
						{(output.totalActualBitrate/1024/1024).toFixed(2)}
					</div>
				</div>
			</div>
		);
	}

	render()
	{
		let destinations = this.props.broadcastUI.destinations;

		if (destinations.bandwidthRealization < 0.2)
			destinations.bandwidthRealizationClassName = "text-danger";
		else if (destinations.bandwidthRealization < 1)
			destinations.bandwidthRealizationClassName = "text-warning";

		return (
			<Card bg="transparent" border="0">
				<Card.Header className="pl-0 pb-1 pr-0 border-0 bg-white d-flex justify-content-between">
					<div>
						{this.props.strings.app.MonitoringCardTitleDestinations}&nbsp;
						{(this.props.broadcastUI !== null &&
							this.props.broadcastUI.healthStatuses.network === 1) &&
							<i className={healthStatusClasses[this.props.broadcastUI.healthStatuses.network]}></i>
						}
					</div>
					<div className="font-weight-normal text-secondary float-right" >
						{this.props.strings.app.MonitoringDestinationsBandwidthRealization}
						<span className={destinations.bandwidthRealizationClassName+' ml-1'}>{(destinations.bandwidthRealization * 100).toFixed(0)}%</span>
						<HelpTooltip className="ml-1" placement="left">{this.props.strings.app.MonitoringHelpBandwidthRealization}</HelpTooltip>
					</div>
				</Card.Header>
				<Card.Body className="bg-white">

					<div className="container m-0 p-0">
						<div className="row">
							<div className="col-6 text-center border border-dark">
								<div className="w-100 p-2">{this.props.strings.app.MonitoringDestinationsOutputsAndTargets}</div>
							</div>
							<div className="col-6">
								<div className="row">
									<div className="col-12 text-center border-top border-right border-bottom border-dark">
										{this.props.strings.app.MonitoringDestinationsBitrates}
									</div>
								</div>
								<div className="row">
									<div className="col-4 bg-dark text-center text-white text-truncate overflow-hidden border-bottom border-dark">
										{this.props.strings.app.MonitoringDestinationsAvailable}
									</div>
									<div className="col-4 bg-dark text-center text-white text-truncate overflow-hidden border-bottom border-dark">
										{this.props.strings.app.MonitoringDestinationsTarget}
									</div>
									<div className="col-4 bg-dark text-center text-white text-truncate overflow-hidden border-bottom border-right border-dark">
										{this.props.strings.app.MonitoringDestinationsActual}
									</div>
								</div>
							</div>
						</div>
						{destinations.outputs.map((b,key)=>{return this.renderOutput(b,key);})}
						{/* Total Summary */}
						<div className="row">
							<div className="col-10 text-right p-1 border-left border-bottom border-dark">
								{this.props.strings.app.MonitoringDestinationsCurrentTotalBandwidth}
							</div>
							<div className="col-2 text-center p-1 border-right border-bottom border-dark">
								{(destinations.totalActualBitrate/1024/1024).toFixed(2)}
							</div>
						</div>

					</div>

				</Card.Body>
			</Card>
		);
	}
}
export default BroadcastItemMonitorDestinations;