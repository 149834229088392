module.exports = {
	"name": "General HEVC",
	"id": "v2-general-hevc",
	"type": "full",
	"presentation": {
		"layout": "SingleOutput",
		"widgets": [
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					}
				}
			},
			{
				"widget": "SelectCustom",
				"label": "Video Frame Size",
				"options": {
					"3840 x 2160": {
						"width": 3840,
						"height": 2160
					},
					"1920 x 1080": {
						"width": 1920,
						"height": 1080
					},
					"1280 x 720": {
						"width": 1280,
						"height": 720
					},
					"960 x 540": {
						"width": 960,
						"height": 540
					}
				},
				"defaultValue": 1,
				"parameters": {
					"width": {
						"variable": "$videoFrameSizeWidth",
						"units": "INTEGER"
					},
					"height": {
						"variable": "$videoFrameSizeHeight",
						"units": "INTEGER"
					}
				}
			},
			{
				"widget": "TextEnterFloat",
				"parameters": {
					"value": {
						"variable": "$videoFrameRateMax",
						"defaultValue": 30,
						"minValue": 10,
						"minWarning": "Frame rate max is less than minimum of 10",
						"maxValue": 100,
						"maxWarning": "Frame rate max is greater than maximum of 100",
						"units": "FLOAT",
						"label": "Set Frame Rate Max (Higher frame rates automatically halved)",
						"required": true
					}
				}
			},
			{
				"widget": "Bitrate",
				"parameters": {
					"bitrate": {
						"variable": "$videoBitrate",
						"defaultValue": "8000k",
						"minValue": "400k",
						"minWarning": "Video bitrate is less than minumum of 400 Kbps",
						"maxValue": "40000k",
						"maxWarning": "Video bitrate is greater than maximum of 40000 Kbps",
						"units": "BITRATE_KBPS",
						"label": "Video Bitrate (Target)",
						"required": true
					}
				}
			},
			{
				"widget": "Bitrate",
				"parameters": {
					"bitrate": {
						"variable": "$videoBitrateMin",
						"defaultValue": "1000k",
						"minValue": "100k",
						"minWarning": "Video bitrate is less than minumum of 100 Kbps",
						"maxValue": "30000k",
						"maxWarning": "Video bitrate is greater than maximum of 30000 Kbps",
						"units": "BITRATE_KBPS",
						"label": "Video Bitrate (Minimum)",
						"required": true
					}
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "CheckboxSimple",
				"label": "Record Broadcast",
				"parameters": {
					"value": {
						"variable": "$record",
						"defaultValue": false,
						"required": true
					}
				}
			}
		]
	},
	"template": {
		"input": {
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": "$videoFrameSizeWidth",
			"videoFrameHeightMax": "$videoFrameSizeHeight",
			"videoFrameRateMax": "$videoFrameRateMax",
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition0",
				"record": "$record",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl",
						"streamName": "$streamTargetStreamName",
						"username": "$streamTargetUsername",
						"password": "$streamTargetPassword"
					}
				],
				"encodingConfiguration": {
					"name": "rendition0",
					"encodingConfigurationVideo": {
						"codec": "H.265",
						"implementation": "QuickSync",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": "$videoFrameSizeWidth",
						"frameSizeHeight": "$videoFrameSizeHeight",
						"profile": "main",
						"bitrate": "$videoBitrate",
						"bitrateMin": "$videoBitrateMin",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "quicksync.TargetUsage",
								"value": "3",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "128k",
						"parameters": []
					}
				}
			}
		]
	}
};