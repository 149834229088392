import React, { Component } from 'react';

import { Form, Button, Modal, InputGroup, FormControl, Alert, Card, Badge, Row, Col } from 'react-bootstrap'
import { formatFrameRate, humanBitrate } from '../UIUtils';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';


import {
	TEMPLATECOUNTS_TEMPLATES_ENABLED,
	WIDGET_UNITS,
	addPhantomValuesToBroadcast,
	adjustBroadcastValues,
	deflateStr,
	generateTemplateVariables,
	getDefaultTemplateForCategoryV2,
	getIdentifierBaseObj,
	getPhantomRecording,
	getRootTemplateName,
	getTemplateCategoryListV2,
	getTemplateListV2,
	isString,
	overrideTemplateValues,
	parseBoolean,
	parseBytesStr,
	rawValueToUnitValue,
	unitValueToRawValue
} from '../../templates/TemplateUtils.js';

import LoadingOverlay from 'react-loading-overlay';
import { isTemplateObjReadOnly, isTemplateObjManageOnly } from "../../templates/TemplateUtils";
import SingularLiveGraphicsController from '../../controller/SingularLiveGraphicsController';

// import {
// 	CODECS_VIDEO,
// 	CODEC_IMPLEMENTATIONS_VIDEO,
// 	H264_PROFILES,
// 	X264_PRESETS,
// 	NVENC_PRESETS,
// 	QUICKSYNC_PRESETS,
// 	getDefaultSettings,
// 	createSimpleTemplate, STREAMTARGET_TYPES
// } from '../../templates/TemplateBuilder.js';

class BroadcastCreateDialog extends Component
{
	static get NONE() {
		return "NONE";
	}

	static getDefaultState()
	{
		return {
			isEdit:false,
			broadcastId:undefined,
			broadcast:undefined,
			title:"",
			encoder1: undefined,
			templateCategory: "v2-general-single-target",
			template: undefined,
			engineeringView: false,
			overlayGraphicsVendor:BroadcastCreateDialog.NONE,
			overlayGraphicsURL: '',
			alert:undefined,
		};
	}

	constructor(props) {

		super();

		this.state = BroadcastCreateDialog.getDefaultState();

		this.handleClose = this.handleClose.bind(this);
		this.createBroadcast = this.createBroadcast.bind(this);
		this.onShow = this.onShow.bind(this);
		this.onEnter = this.onEnter.bind(this);
		this.toggleRTMPShowing = this.toggleRTMPShowing.bind(this);
		this.getEncoderTemplateSet = this.getEncoderTemplateSet.bind(this);
		this.encoderChange = this.encoderChange.bind(this);
		this.templateCategoryChange = this.templateCategoryChange.bind(this);
		this.templateChange = this.templateChange.bind(this);
		this.getStateVariableId = this.getStateVariableId.bind(this);
		this.onChangeVariable = this.onChangeVariable.bind(this);
		this.getVariableValue = this.getVariableValue.bind(this);
		this.getBuiltInOverlays = this.getBuiltInOverlays.bind(this);
		this.getBuiltInOverlayUrl = this.getBuiltInOverlayUrl.bind(this);

		this.filterEnabled = true;
		this.filterEmptyDestinations = true;

		this.loadProviderStreamTargets = true;
		this.loadWowzaCloudStreamTargets = true;
		this.loadLinkedInLiveStreamTargets = true;
		this.providerStreamTargetMap = {};

		this.encoder1 = React.createRef();
		this.templateCategory = React.createRef();
		this.template = React.createRef();

	}

	toggleRTMPShowing(evt)
	{
		let newState = {};

		newState[evt.target.id] = (this.state[evt.target.id]?false:true);

		this.setState(newState);
	}

	setTemplateDefaultValues(template, newState, includeDefaultValues = true)
	{
		if (template.presentation !== undefined && template.presentation.widgets !== undefined)
		{
			for(let v in template.presentation.widgets)
			{
				let widget = template.presentation.widgets[v];

				if (widget.parameters !== undefined)
				{
					for (let p in widget.parameters)
					{
						let param = widget.parameters[p];

						if (param.variable !== undefined)
						{
							let id = this.getStateVariableId(template, param.variable);

							newState[id+'-param'] = param;
							newState[id+'-invalid'] = false;

							if (includeDefaultValues)
							{
								let value = undefined;

								if (widget.widget === "SelectCustom")
								{
									if (widget.options !== undefined)
									{
										let optKeys = Object.keys(widget.options);

										let index = 0;
										if (widget.defaultValue !== undefined)
											index = parseInt(widget.defaultValue);

										if (index >= optKeys.length)
											index = 0;

										let option = widget.options[optKeys[index]];

										if (option !== undefined && option[p] !== undefined)
										{
											value = this.parseParameterValue(param, option[p]);
											value = rawValueToUnitValue(param, value);
										}

										//console.log(optKeys+":"+p+":"+index+":"+value);
									}
								}
								else if (widget.widget === "CheckboxCustom")
								{
									if (widget.options !== undefined)
									{
										let index = false
										if (widget.defaultValue !== undefined)
											index = widget.defaultValue;

										let option = widget.options[index];

										if (option !== undefined && option[p] !== undefined)
										{
											value = this.parseParameterValue(param, option[p]);
											value = rawValueToUnitValue(param, value);
										}

										//console.log(p+":"+index+":"+value);
									}
								}
								else if (widget.widget === "StreamTargetProvider")
								{
									let providerKeyId = undefined;
									let providerId = undefined;

									if (this.props.clearcaster.namespaceProviderKeys.length > 0)
									{
										if(widget.parameters.providerKeyId.filter){
											let filter = widget.parameters.providerKeyId.filter;
											providerKeyId = this.props.clearcaster.namespaceProviderKeys.filter((key) => { return key.type === filter})[0].id;
										}else {
											providerKeyId = this.props.clearcaster.namespaceProviderKeys[0].id;
										}


										if (this.providerStreamTargetMap.hasOwnProperty(providerKeyId))
										{
											let targets = this.providerStreamTargetMap[providerKeyId].list;
											if (targets.length > 0)
											{
												providerId = targets[0].providerId;
											}
										}
									}

									switch(p)
									{
										case "providerKeyId":
											value = providerKeyId;
											break;
										case "providerId":
											value = providerId;
											break;
										default:
									}
								}
								else if (param.defaultValue !== undefined)
								{
									value = this.getParameterDefaultValue(template, param);
									value = rawValueToUnitValue(param, value);
								}

								if (value !== undefined)
									newState[id] = value;
							}
						}
					}
				}
			}
		}

		//console.log(JSON.stringify(newState, null, 2));
	}

	loadVariablesFromBroadcast(template, broadcast, newState)
	{
		if (template.variables !== undefined)
		{
			let streamTargetMap = {};

			for(let name in template.variables)
			{
				let vari = template.variables[name];

				if (vari.identifiers === undefined)
					continue;

				let id = this.getStateVariableId(template, name);

				for(let di in vari.identifiers)
				{
					let ident = vari.identifiers[di];

					let baseObj = getIdentifierBaseObj(ident, broadcast);

					if (baseObj !== undefined && ident.name !== undefined)
					{
						let value = baseObj[ident.name];

						let param = newState[id+'-param'];

						if (param !== undefined)
						{
							value = rawValueToUnitValue(param, value);
						}

						newState[id] = value;

						// this is a little strange but not sure where else to do it
						if (ident.type === "streamTarget")
						{
							let streamTargetId = ident.outputTag + ':' + ident.streamTargetTag;

							let streamTargetObj = {};
							if (streamTargetMap.hasOwnProperty(streamTargetId))
								streamTargetObj = streamTargetMap[streamTargetId];
							else
								streamTargetMap[streamTargetId] = streamTargetObj;

							streamTargetObj.id = streamTargetId;
							streamTargetObj[ident.name] = value;
							streamTargetObj[ident.name+'-variable'] = id;
						}
					}
					else
						console.log("WARN: loadVariablesFromBroadcast: Variable not found:" + JSON.stringify(ident));
				}
			}

			// not sure where else to do this - checks to see if provider stream target is still valid
			for(let i in streamTargetMap)
			{
				let streamTargetItem = streamTargetMap[i];

				if (streamTargetItem.providerKeyId !== undefined && streamTargetItem.providerId !== undefined)
				{
					let providerKeyId = streamTargetItem.providerKeyId;
					let providerId = streamTargetItem.providerId;

					let providerKeyIdId = streamTargetItem['providerKeyId-variable'];
					let providerIdId = streamTargetItem['providerId-variable'];

					let id = providerIdId+'-warning';

					if (this.providerStreamTargetMap.hasOwnProperty(providerKeyId))
					{
						if (this.providerStreamTargetMap[providerKeyId].map.hasOwnProperty(providerId))
						{
							newState[id] = undefined;
						}
						else
						{
							newState[id] = 'The current Wowza Streaming Cloud stream is no longer available.';

							if (this.providerStreamTargetMap[providerKeyId].list.length > 0)
								newState[providerIdId] = this.providerStreamTargetMap[providerKeyId].list[0].providerId;
						}
					}
					else
					{
						newState[id] = 'The current Wowza Streaming Cloud API key is no longer available.';

						if (this.props.clearcaster.namespaceProviderKeys.length > 0)
						{
							newState[providerKeyIdId] = this.props.clearcaster.namespaceProviderKeys[0].id;

							if (this.providerStreamTargetMap.hasOwnProperty(newState[providerKeyIdId]))
							{
								let targets = this.providerStreamTargetMap[newState[providerKeyIdId]].list;
								if (targets.length > 0)
								{
									newState[providerIdId] = targets[0].providerId;
								}
							}
						}
					}
				}
			}
		}
	}

	getEncoderTemplateSet(encoderId)
	{
		let templateSet = "pro";
		if (encoderId != null && this.props.clearcaster.encoderMap[encoderId] != null)
		{
			let model = this.props.clearcaster.encoderMap[encoderId].modelInfo.model;
			if (model === "CC_MICRO")
			{
				templateSet = "micro";
			}
		}
		return templateSet;
	}

	async encoderChange(evt)
	{
		let encoderId = evt.target.value;

		// template sets are different between micro,pro encoders so
		// choose the closest valid template when the encoder changes
		let templateSet = this.getEncoderTemplateSet(encoderId);
		let rootTemplateName = getRootTemplateName(this.state.template);

		let templateList = getTemplateListV2(this.props.clearcaster.broadcastTemplates, templateSet, this.state.templateCategory, this.filterEnabled);
		let templateName = '';
		if (templateList.length > 0)
		{
			templateName = templateList[0].id;
			for (let i = 0; i < templateList.length; i++)
			{
				if (rootTemplateName === getRootTemplateName(templateList[i].id))
				{
					templateName = templateList[i].id;
					break;
				}
			}
		}

		let newState = {};
		newState.encoder1 = encoderId;
		newState.template = templateName;

		if (templateName !== this.state.template)
		{
			let template = this.getTemplate(templateName);
			if (template !== undefined)
			{
				await this.preloadTemplateData(template);
				this.setTemplateDefaultValues(template, newState, true);
			}
		}

		this.setState(newState);
	}
	async templateChange(evt)
	{
		var templateName = evt.target.value;

		let newState = {
			template: templateName
		};

		let template = this.getTemplate(templateName);
		if (template !== undefined)
		{
			await this.preloadTemplateData(template);
			this.setTemplateDefaultValues(template, newState, true);
		}

		this.setState(newState);
	}

	async templateCategoryChange(evt)
	{
		let templateCategoryName = evt.target.value;

		let newState = { };

		newState.templateCategory = templateCategoryName;

		let templateInfo = this.props.clearcaster.broadcastTemplates;

		let defaultTemplate = getDefaultTemplateForCategoryV2(templateInfo, this.getEncoderTemplateSet(this.state.encoder1), templateCategoryName);

		newState.template = defaultTemplate;
		if (defaultTemplate !== undefined)
		{
			let template = this.getTemplate(defaultTemplate);

			if (template !== undefined)
			{
				await this.preloadTemplateData(template);
				this.setTemplateDefaultValues(template, newState, true);
			}
		}

		this.setState(newState);
	}

	onShow()
	{
	}

	// NOTE THIS FUNCTION IS UNUSED, I'M COMMENTING IT OUT PENDING REMOVAL DM-11/2020
	// clearAndInitState()
	// {
	// 	console.log("CLEAR_AND_INIT_STATE");
	// 	let newState = BroadcastCreateDialog.getDefaultState();
	// 	for(let i in this.state)
	// 	{
	// 		if (!newState.hasOwnProperty(i))
	// 		{
	// 			console.log("==== clear: "+i);
	// 			newState[i] = undefined;
	// 		}
	// 	}
	//
	// 	return newState;
	// }

	async onEnter()
	{
		let newState = {}; //this.clearAndInitState();

		this.loadWSCStreams = true;

		if (this.props.clearcaster.broadcastCreateDialogInfo !== undefined && this.props.clearcaster.broadcastCreateDialogInfo.broadcastId !== undefined)
		{
			let broadcast = this.props.clearcaster.broadcastMap[this.props.clearcaster.broadcastCreateDialogInfo.broadcastId];

			if (broadcast !== undefined)
			{
				let isGoodEdit = true;

				newState.templateCategory = undefined;
				newState.template = undefined;
				newState.templateEditInfo = undefined;

				newState.isEdit = true;
				newState.broadcastId = broadcast.id;
				newState.broadcast = broadcast;

				newState.title = broadcast.name;
				newState.overlayGraphicsVendor = BroadcastCreateDialog.NONE;

				if (broadcast.broadcastEncoders && broadcast.broadcastEncoders.length > 0)
				{
					if (broadcast.broadcastEncoders[0].encoder != null && broadcast.broadcastEncoders[0].encoder.id != null)
					{
						newState.encoder1 = broadcast.broadcastEncoders[0].encoder.id;
					}
					else
					{
						console.log("WARN: EditBroadcast: No encoder ID: "+JSON.stringify(broadcast));
						// isGoodEdit = false;
					}
				}
				else
				{
					console.log("WARN: EditBroadcast: No broadcast encoder: "+JSON.stringify(broadcast));
					// isGoodEdit = false;
				}

				newState.engineeringView = false;
				if (broadcast.displays && broadcast.displays.length > 0)
				{
					newState.engineeringView = true;
				}

				if (broadcast.input !== undefined)
				{
					if (broadcast.input.overlayVendor && broadcast.input.overlayVendor.length > 0 && broadcast.input.overlayUrl && broadcast.input.overlayUrl.length > 0)
					{
						newState.overlayGraphicsVendor = broadcast.input.overlayVendor;
						newState.overlayGraphicsURL = broadcast.input.overlayUrl;
						if (newState.overlayGraphicsVendor.startsWith("builtin-singularlive:"))
							newState.overlayGraphicsURL = "";
					}
				}
				else
				{
					console.log("WARN: EditBroadcast: No input: "+JSON.stringify(broadcast));
					isGoodEdit = false;
				}

				if (broadcast.outputs !== undefined && broadcast.outputs.length > 0)
				{
					let broadcastOutput = broadcast.outputs[0];

					if (broadcast.createBroadcastInfo !== undefined)
					{
						if (broadcast.createBroadcastInfo.templateCategory !== undefined && broadcast.createBroadcastInfo.template !== undefined)
						{
							newState.templateCategory = broadcast.createBroadcastInfo.templateCategory;
							newState.template = broadcast.createBroadcastInfo.template;

							if (broadcast.createBroadcastInfo.templateEditInfo !== undefined)
							{
								newState.templateEditInfo = broadcast.createBroadcastInfo.templateEditInfo;
							}
						}
						else if (broadcast.createBroadcastInfo.outputs !== undefined && broadcast.createBroadcastInfo.outputs.hasOwnProperty(broadcastOutput.streamName))
						{
							let outputInfo = broadcast.createBroadcastInfo.outputs[broadcastOutput.streamName];

							newState.templateCategory = outputInfo.templateCategory;
							newState.template = outputInfo.template;
						}

						if (newState.template !== undefined)
						{
							let template = this.getTemplate(newState.template);

							if (template !== undefined)
							{
								await this.preloadTemplateData(template);
								addPhantomValuesToBroadcast(template, broadcast);
								this.setTemplateDefaultValues(template, newState, false);
								this.loadVariablesFromBroadcast(template, broadcast, newState);
							}
							else
							{
								console.log("WARN: EditBroadcast: Cannot load template: "+JSON.stringify(broadcast.createBroadcastInfo, null, 2));
								isGoodEdit = false;
							}
						}
						else
						{
							console.log("WARN: EditBroadcast: No matching createBroadcastInfo output: "+JSON.stringify(broadcast, null, 2));
							isGoodEdit = false;
						}
					}
				}
				else
				{
					console.log("WARN: EditBroadcast: No outputs: "+JSON.stringify(broadcast));
					isGoodEdit = false;
				}

				if (!isGoodEdit)
				{
					ClearCasterStore.dispatch({
						type: ActionTypes.HIDE_BROADCASTCREATE_DIALOG,
					});
				}

				//console.log("loadbroadcastForEdit:\n"+JSON.stringify(newState, null, 2));

				this.setState(newState);
			}
		}
		else
		{
			let templateInfo = this.props.clearcaster.broadcastTemplates;

			if (templateInfo !== undefined && this.templateCategory.current && this.template.current)
			{
				let templateCategoryName = this.templateCategory.current.value;
				if(!this.props.controller.userIsSubscriptionCurrent()){
					templateCategoryName = "expired-subscription-limited";
				}
				let defaultTemplate = getDefaultTemplateForCategoryV2(templateInfo, this.getEncoderTemplateSet(this.encoder1.current.value), templateCategoryName);

				newState.encoder1 = this.encoder1.current.value;
				newState.broadcast = undefined;
				newState.broadcastId = undefined;
				newState.templateEditInfo = undefined;
				newState.templateCategory = templateCategoryName;
				newState.template = defaultTemplate;

				if (defaultTemplate !== undefined)
				{
					let template = this.getTemplate(defaultTemplate);
					if (template !== undefined)
					{
						await this.preloadTemplateData(template);
						this.setTemplateDefaultValues(template, newState, true);
					}
				}

				this.setState(newState);
			}
		}
	}

	handleClose()
	{
		this.setState(BroadcastCreateDialog.getDefaultState());
		let isSubscriptionCurrent = this.props.controller.userIsSubscriptionCurrent();
		if(!isSubscriptionCurrent){
			this.setState({templateCategory: "expired-subscription-limited"})
		}
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_BROADCASTCREATE_DIALOG,
		});
	}

	getBroadcastObjectsReferencedByVariables(template)
	{
		let broadcastObjects = {};

		if (template.variables !== undefined)
		{
			for(let name in template.variables)
			{
				let vari = template.variables[name];

				if (vari.identifiers === undefined)
					continue;

				// let id = this.getStateVariableId(template, name);

				let broadcastInput = template.broadcastInput;

				if (broadcastInput !== undefined)
				{
					for(let di in vari.identifiers)
					{
						let ident = vari.identifiers[di];

						let baseObj = getIdentifierBaseObj(ident, broadcastInput);
						if (baseObj !== undefined && baseObj.id !== undefined)
						{
							let typeId = ident.type + "-" + baseObj.id;

							if (!broadcastObjects.hasOwnProperty(typeId))
							{

								broadcastObjects[typeId] = baseObj;
							}
						}
					}
				}
			}
		}

		//console.log(JSON.stringify(broadcastObjects, null, 2));

		return broadcastObjects;
	}

	injectVariables(template, broadcastInput)
	{
		//console.log("=====injectVariables: variables:"+JSON.stringify(template.variables));
		if (template.variables !== undefined)
		{
			for(let name in template.variables)
			{
				let vari = template.variables[name];

				if (vari.identifiers === undefined)
					continue;

				let id = this.getStateVariableId(template, name);
				let value = this.getVariableValue(id);

				//console.log("injectVariables["+id+"]: "+value);

				if (value !== undefined && isString(value))
					value = value.trim();

				for(let di in vari.identifiers)
				{
					let ident = vari.identifiers[di];

					let baseObj = getIdentifierBaseObj(ident, broadcastInput);
					if (baseObj !== undefined && ident.name !== undefined)
					{
						if (value !== undefined && isString(value) && value.length <= 0)
						{
							if (vari.hasOwnProperty("emptyStringIsNull") && vari.emptyStringIsNull)
								value = null;
							else if (vari.hasOwnProperty("emptyStringIsUndefined") && vari.emptyStringIsUndefined)
								value = undefined;
						}

						if (ident.type === 'extraProperty' && value !== undefined)
						{
							value = value + '';
						}

						if (value === undefined)
						{
							delete baseObj[ident.name];
						}
						else
						{
							baseObj[ident.name] = value;
						}

						//console.log("=====injectVariables: baseObj:"+JSON.stringify(baseObj));
					}
					else
						console.log("WARN: injectVariables: Variable not found:" + JSON.stringify(ident));
				}
			}
		}
	}

	checkRequiredFields(template)
	{
		let missingFields = [];

		if (template.id !== undefined && template.presentation !== undefined && template.presentation.widgets !== undefined)
		{
			for(let wid in template.presentation.widgets)
			{
				let widget = template.presentation.widgets[wid];


				if (widget.parameters !== undefined)
				{
					for(let pi in widget.parameters)
					{
						let param = widget.parameters[pi];

						if (param.required !== undefined && param.required && param.variable !== undefined && param.label !== undefined)
						{
							let id = this.getStateVariableId(template, param.variable);

							let value = this.getVariableValue(id);
							if (value === undefined || value.toString().length <= 0)
								missingFields.push(param.label);
						}
					}
				}

			}
		}

		return missingFields;
	}

	checkLinkedInIntegrationsExist(){
		return (this.props.clearcaster.namespaceProviderKeys.filter((providerKey) => providerKey.type === "LINKEDIN_ACCESS_TOKEN").length > 0)
	}

	checkTemplateCategoryDisabled(category){
		let disabled = false;
		let isSubscriptionCurrent = this.props.controller.userIsSubscriptionCurrent();
		if(category === "linkedin"){
			disabled = !this.checkLinkedInIntegrationsExist();
		}

		if (!isSubscriptionCurrent && (category !== "expired-subscription-limited")){
			disabled = true
		}
		return disabled;
	}

	checkEncoderDisabled(model){
		let disabled = false;
		let isSubscriptionCurrent = this.props.controller.userIsSubscriptionCurrent();
		if(!isSubscriptionCurrent && model.hasOwnProperty("modelInfo") && !(model.modelInfo.model === "CC_PRO" || model.modelInfo.model === "UNKNOWN"))
			disabled = true;

		return disabled;
	}

	getProviderBroadcastLocation(template)
	{
		let id = this.getStateVariableId(template, "$streamTargetProviderBroadcastLocation");
		let value = this.getVariableValue(id);
		if (value === undefined || value.toString().length <= 0)
		{
			value = "US_WEST"; //default LinkedIn location (the only provider to use this method)
		}
		return value;
	}

	getProviderType(providerKeyId, providerId)
	{
		let providerType = undefined;

		//console.log("getProviderType: hasOwnProperty["+providerKeyId+"]:"+this.providerStreamTargetMap.hasOwnProperty(providerKeyId));

		if (this.providerStreamTargetMap.hasOwnProperty(providerKeyId))
		{
			//console.log("getProviderType: hasOwnProperty["+providerId+"]:"+this.providerStreamTargetMap[providerKeyId].map.hasOwnProperty(providerId));

			if (this.providerStreamTargetMap[providerKeyId].map.hasOwnProperty(providerId))
			{
				//console.log(JSON.stringify(this.providerStreamTargetMap[providerKeyId].map[providerId], null, 2));

				providerType = this.providerStreamTargetMap[providerKeyId].map[providerId].providerType;
			}
		}

		return providerType;
	}

	async createBroadcast()
	{
		let appStrings = this.props.strings;

		let missingFields;
		let missingCount = 0;

		let currTemplateCategory = this.templateCategory.current.value;
		let currTemplate = this.template.current.value;

		let template = this.getTemplate(currTemplate);

		if (this.state.title === undefined || this.state.title.length <= 0)
		{
			let keyStr = appStrings.app.BroadcastName;

			if (missingFields !== undefined)
				missingFields = [missingFields, (<span key={ keyStr+" dim" }>, </span>)];

			missingFields = [missingFields, (<strong key={ keyStr }>{ keyStr }</strong>)];
			missingCount++;
		}

		{
			let localMissing = this.checkRequiredFields(template);
			for(let mi in localMissing)
			{
				let keyStr = localMissing[mi];

				if (missingFields !== undefined)
					missingFields = [missingFields, (<span key={ keyStr+" dim" }>, </span>)];

				missingFields = [missingFields, (<strong key={ keyStr }>{ keyStr }</strong>)];
				missingCount++;
			}
		}

		if (missingCount > 0)
		{
			let msg = missingCount>1?appStrings.app.MISSINGFIELDS:appStrings.app.MISSINGFIELD;
			let alert = {
				body: <span key={ "msg" }>{ msg }&nbsp;:<span key={ "body" }>{ missingFields }</span></span>,
			}

			this.setState({alert: alert});

			return;
		}

		this.setState({alert: undefined});

		let _this = this;

		let broadcastEncoder = {
			encoderId: this.state.encoder1,
			streamTargetEncoderIndex: 0,
		}

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_WAITOVERLAY,
			loadingOverlayMessage: (this.state.isEdit?"Saving Broadcast...":"Creating Broadcast...")
		});

		let broadcastRecordingInput = {
			tag: "recordingUI",
			format: "MP4",
			transportType: "HTTP_MULTIPARTUPLOAD_WOWZA"
		};

		if (this.state.isEdit)
		{
			let broadcast = this.state.broadcast;

			// title
			let newTitle = this.state.title;
			if (newTitle !== undefined)
			{
				await this.props.controller.renameBroadcast(this.state.broadcastId, newTitle.trim());
			}

			// broadcastEncoders
			if (broadcast.broadcastEncoders && broadcast.broadcastEncoders.length > 0)
			{
				await this.props.controller.replaceBroadcastEncoder(broadcast.id, broadcast.broadcastEncoders[0].id, broadcastEncoder);
			}

			// overlay
			let overlayInput = {
				overlayVendor:"",
				overlayUrl:"",
			}

			let overlayUrl = undefined;

			if (this.state.overlayGraphicsVendor !== "NONE")
			{
				if (this.state.overlayGraphicsVendor.startsWith("builtin-singularlive:"))
				{
					let graphicId = this.state.overlayGraphicsVendor.split(":")[1];
					overlayUrl = this.getBuiltInOverlayUrl(graphicId);
				}
				else
				{
					if (this.state.overlayGraphicsURL !== undefined && this.state.overlayGraphicsURL.length > 0)
						overlayUrl = this.state.overlayGraphicsURL.trim();
				}
			}

			if (overlayUrl !== undefined)
			{
				overlayInput.overlayVendor = this.state.overlayGraphicsVendor;
				overlayInput.overlayUrl = overlayUrl;
			}
			else
			{
				overlayInput.overlayVendor = null;
				overlayInput.overlayUrl = null;
			}

			console.log("overlayInput:"+JSON.stringify(overlayInput));

			await this.props.controller.setBroadcastInputInfo(this.state.broadcastId, overlayInput);

			// engineeringView
			if (broadcast.displays && broadcast.displays.length > 0)
			{
				await this.props.controller.deleteBroadcastDisplay(broadcast.displays[0].id);
			}

			if (this.state.engineeringView)
			{
				let engineeringViewUrl = window.location.origin + "/monitor-views/engineering.html";

				let broadcastDisplayInput = {
					name: "Engineering View",
					type: "HTML",
					url: engineeringViewUrl
				};

				await this.props.controller.createBroadcastDisplay(broadcast.id, broadcastDisplayInput);
			}

			// templated objects
			let modifiedObjs = this.getBroadcastObjectsReferencedByVariables(template);
			for(let key in modifiedObjs)
			{
				let modifiedObj = modifiedObjs[key];

				var objType = key.substr(0, key.indexOf('-'));

				switch(objType)
				{
					case "output":
					{
						if (modifiedObj.hasOwnProperty("record"))
						{
							let recording = getPhantomRecording(modifiedObj);

							if (modifiedObj.record)
							{
								if (recording === undefined)
								{
									await this.props.controller.createBroadcastRecording(modifiedObj.id, broadcastRecordingInput);
								}
							}
							else
							{
								if (recording !== undefined)
								{
									await this.props.controller.deleteBroadcastRecording(recording.id);
								}
							}

							delete modifiedObj.record;
						}
						break;
					}
					case "input":
					{
						delete modifiedObj.id;

						await this.props.controller.setBroadcastInputInfo(this.state.broadcastId, modifiedObj);
						break;
					}
					case "streamTarget":
					{
						let streamTarget = modifiedObj;
						if (streamTarget.providerKeyId !== undefined && streamTarget.providerId !== undefined)
						{
							let providerType = this.getProviderType(streamTarget.providerKeyId, streamTarget.providerId);

							console.log("queryBroadcastProviderStreamTarget[request]: providerKeyId:"+streamTarget.providerKeyId+" providerId:"+streamTarget.providerId+" providerType:"+providerType);

							let retObj = await this.props.controller.queryBroadcastProviderStreamTarget(streamTarget.providerKeyId, streamTarget.providerId, providerType);
							console.log(JSON.stringify(retObj, null, 2));
							if (retObj && retObj.data && retObj.data.broadcastProviderStreamTarget)
							{
								let streamTargetInfo = retObj.data.broadcastProviderStreamTarget;

								console.log("queryBroadcastProviderStreamTarget[response]:\n"+JSON.stringify(streamTargetInfo, null, 2));

								for(let sii in streamTargetInfo)
								{
									let streamTargetValue = streamTargetInfo[sii];
									streamTarget[sii] = streamTargetValue;
								}
							}
						}

						let id = streamTarget.id;
						delete streamTarget.id;
						delete streamTarget.nearRealTimeData;

						if (streamTarget.username !== null && streamTarget.username !== undefined)
						{
							streamTarget.username = streamTarget.username.trim();
							if (streamTarget.username.length <= 0)
								streamTarget.username = null;
						}

						if (streamTarget.password !== null && streamTarget.password !== undefined)
						{
							streamTarget.password = streamTarget.password.trim();
							if (streamTarget.password.length <= 0)
								streamTarget.password = null;
						}

						await this.props.controller.setBroadcastStreamTargetInfo(id, streamTarget);
						break;
					}
					case "encodingConfigurationVideo":
					{
						let id = modifiedObj.id;

						delete modifiedObj.id;
						delete modifiedObj.parameters;

						await this.props.controller.setBroadcastEncodingConfigurationVideo(id, modifiedObj);
						break;
					}
					case "encodingConfigurationAudio":
					{
						let id = modifiedObj.id;

						delete modifiedObj.id;
						delete modifiedObj.parameters;

						await this.props.controller.setBroadcastEncodingConfigurationAudio(id, modifiedObj);
						break;
					}
					case "extraProperty":
					{
						delete modifiedObj.id;
						await this.props.controller.setBroadcastExtraProperty(broadcast.id, modifiedObj);
						break;
					}
					default:
				}
			}

			_this.props.controller.forcePolling();
			this.handleClose();
		}
		else
		{
			if (template !== undefined && template.broadcastInput !== undefined)
			{
				let broadcastInput = template.broadcastInput;

				let newTitle = this.state.title;
				if (newTitle !== undefined)
				{
					broadcastInput.name = newTitle.trim();
				}

				broadcastInput.broadcastEncoders = [ broadcastEncoder ];

				let templateEditInfo = JSON.parse(JSON.stringify(template));

				delete templateEditInfo.template;
				delete templateEditInfo.broadcastInput;
				delete templateEditInfo.overrides;

				let templateSet = this.getEncoderTemplateSet(this.state.encoder1);

				let createBroadcastInfo = {
					engineeringView: this.state.engineeringView,
					templateSet: templateSet,
					templateCategory: currTemplateCategory,
					template: currTemplate,
					templateEditInfo: templateEditInfo,
				};

				var createBroadcastInfoValue = "v1:"+deflateStr(JSON.stringify(createBroadcastInfo)); //base64.encode(pako.deflate(JSON.stringify(createBroadcastInfo), { to: 'string' }));

				let createBroadcastExtraProperty = {name:"createBroadcastInfo", value:createBroadcastInfoValue, type:"String"};
				if (broadcastInput.extraProperties != null)
				{
					broadcastInput.extraProperties.push(createBroadcastExtraProperty);
				}
				else
				{
					broadcastInput.extraProperties = [createBroadcastExtraProperty];
				}

				let overlayUrl = undefined;

				if (this.state.overlayGraphicsVendor !== "NONE")
				{
					if (this.state.overlayGraphicsVendor.startsWith("builtin-singularlive:"))
					{
						let graphicId = this.state.overlayGraphicsVendor.split(":")[1];
						overlayUrl = this.getBuiltInOverlayUrl(graphicId);
					}
					else
					{
						if (this.state.overlayGraphicsURL !== undefined && this.state.overlayGraphicsURL.length > 0)
							overlayUrl = this.state.overlayGraphicsURL.trim();
					}
				}

				if (overlayUrl !== undefined)
				{
					broadcastInput.input.overlayVendor = this.state.overlayGraphicsVendor;
					broadcastInput.input.overlayUrl = overlayUrl;
				}

				console.log("broadcastInput.input:"+JSON.stringify(broadcastInput.input));

				if (this.state.engineeringView)
				{
					let engineeringViewUrl = window.location.origin + "/monitor-views/engineering.html";

					broadcastInput.displays = [{
						name: "Engineering View",
						type: "HTML",
						url: engineeringViewUrl
					}];
				}
				if (broadcastInput.outputs !== undefined && broadcastInput.outputs.length > 0)
				{

					for(let oi in broadcastInput.outputs)
					{

						let broadcastOutput = broadcastInput.outputs[oi];

						if (broadcastOutput.hasOwnProperty("record"))
						{
							let isRecord = parseBoolean(broadcastOutput.record);

							if (isRecord)
							{
								if (!broadcastOutput.hasOwnProperty("recordings"))
									broadcastOutput.recordings = [];

								broadcastOutput.recordings.push(broadcastRecordingInput);
							}

							delete broadcastOutput.record;
						}
						if (broadcastOutput.streamTargets !== undefined && broadcastOutput.streamTargets.length > 0)
						{
							for(let si in broadcastOutput.streamTargets)
							{
								let streamTarget = broadcastOutput.streamTargets[si];

								if (streamTarget.username !== null && streamTarget.username !== undefined)
								{
									streamTarget.username = streamTarget.username.trim();
									if (streamTarget.username.length <= 0)
										streamTarget.username = null;
								}

								if (streamTarget.password !== null && streamTarget.password !== undefined)
								{
									streamTarget.password = streamTarget.password.trim();
									if (streamTarget.password.length <= 0)
										streamTarget.password = null;
								}

								if (streamTarget.providerKeyId !== undefined && streamTarget.providerId !== undefined)
								{
									let providerType = this.getProviderType(streamTarget.providerKeyId, streamTarget.providerId);

									if(streamTarget.type === "LINKEDIN_LIVE_STREAM_TARGET"){
										let location = this.getProviderBroadcastLocation(template);
										streamTarget.url = JSON.stringify({url: streamTarget.url,linkedInRegion: location});
										streamTarget.providerType = providerType;
									}

									console.log("queryBroadcastProviderStreamTarget[request]: providerKeyId:"+streamTarget.providerKeyId+" providerId:"+streamTarget.providerId+" providerType:"+providerType);

									let retObj = await this.props.controller.queryBroadcastProviderStreamTarget(streamTarget.providerKeyId, streamTarget.providerId, providerType);
									if (retObj && retObj.data && retObj.data.broadcastProviderStreamTarget)
									{
										let streamTargetInfo = retObj.data.broadcastProviderStreamTarget;

										console.log("queryBroadcastProviderStreamTarget[response]:\n"+JSON.stringify(streamTargetInfo, null, 2));

										for(let sii in streamTargetInfo)
										{
											let streamTargetValue = streamTargetInfo[sii];
											if (streamTargetValue !== undefined && streamTargetValue !== null)
											{
												streamTarget[sii] = streamTargetValue;
											}
										}
									}
								}
							}
						}
					}
				}

				console.log("createBroadcast.request:\n"+JSON.stringify(broadcastInput, null, 2));

				this.props.controller.createBroadcast(broadcastInput).then((response) => {
					// console.log("createBroadcast.request:\n"+JSON.stringify(response, null, 2));
					// console.log("createBroadcast.polling:\n"+_this.props.controller.forcePolling);
					_this.props.controller.forcePolling();
				});
				this.handleClose();
			}
			else
				console.log("ERROR: BroadcastInput is undefined: "+currTemplate);
		}

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_WAITOVERLAY,
		});
	}

	getEncodersByModel()
	{
		let encoderList = this.props.clearcaster.encoderList;
		let encodersByModel = {};
		for (let i = 0; i < encoderList.length; i++)
		{
			let model = "CC_PRO";
			if (encoderList[i].hasOwnProperty("modelInfo")) {
				model = encoderList[i].modelInfo.model;
			}
			if (model === "UNKNOWN")
			{
				model = "CC_PRO";
			}
			if (!encodersByModel.hasOwnProperty(model))
			{
				encodersByModel[model] = [];
			}
			encodersByModel[model].push(encoderList[i]);
		}
		return encodersByModel;
	}

	getTemplate(templateName)
	{
		//console.log("getTemplate:"+templateName);

		let template = undefined;

		let templateInfo = this.props.clearcaster.broadcastTemplates;

		if (this.state.templateEditInfo !== undefined)
		{
			template = JSON.parse(JSON.stringify(this.state.templateEditInfo));
		}
		else if (templateName !== undefined && templateInfo.templates.hasOwnProperty(templateName))
		{
			template = JSON.parse(JSON.stringify(templateInfo.templates[templateName]));
		}

		if (template !== undefined)
		{
			if (template.variables === undefined)
			{
				template.variables = generateTemplateVariables(template);
			}

			let broadcastInput = undefined;

			if (this.state.broadcast !== undefined)
			{
				broadcastInput = JSON.parse(JSON.stringify(this.state.broadcast));
			}
			else if (template.template !== undefined)
			{
				broadcastInput = JSON.parse(JSON.stringify(template.template));
			}

			if (broadcastInput !== undefined)
			{
				overrideTemplateValues(template, broadcastInput);

				adjustBroadcastValues(broadcastInput);

				this.injectVariables(template, broadcastInput);
			}

			template.broadcastInput = broadcastInput;
		}

		//console.log("getTemplate:"+templateName+":"+templateInfo.templates.hasOwnProperty(templateName)+":"+JSON.stringify(template, null, 2));

		return template;
	}

	getEncoderStatus(encoder)
	{
		let appStrings = this.props.strings;

		let encoderStatus;

		if (encoder.isOnline)
		{
			switch(encoder.broadcastStatus)
			{
			default:
			case appStrings.app.streamStatusReady:
				encoderStatus = <Badge variant="secondary">{encoder.broadcastStatus}</Badge>;
				break;
			case appStrings.app.streamStatusPreview:
				encoderStatus = <Badge variant="warning">{encoder.broadcastStatus}</Badge>;
				break;
			case appStrings.app.streamStatusLive:
				encoderStatus = <Badge variant="success">{encoder.broadcastStatus}</Badge>;
				break;
			}

			encoderStatus = encoder.broadcastStatus;
		}
		else
		{
			encoderStatus = <Badge variant="secondary">{appStrings.app.StatusOffline}</Badge>
			encoderStatus = appStrings.app.StatusOffline;
		}



		return encoderStatus
	}

	getBroadcastStatus(encoder)
	{
		let appStrings = this.props.strings;

		switch(encoder.broadcastStatus)
		{
		default:
		case appStrings.app.streamStatusReady:
			return <Badge variant="secondary">{encoder.broadcastStatus}</Badge>;
		case appStrings.app.streamStatusPreview:
			return <Badge variant="warning">{encoder.broadcastStatus}</Badge>;
		case appStrings.app.streamStatusLive:
			return <Badge variant="success">{encoder.broadcastStatus}</Badge>;
		}
	}

	getVideoSource(encoder)
	{
		let appStrings = this.props.strings;

		let videoSource = <span key="VideoSourceSource"></span>;

		if (encoder.isOnline)
		{
			videoSource = <span key="VideoSourceSource">&ensp;/&ensp;<i className="fa fa-exclamation-triangle fa-lg fa-fw icon-warning" aria-hidden="true"></i>&nbsp;{ appStrings.app.VideoSourceNotDetected }<br /></span>

			if (encoder.hasOwnProperty("captureSessionInfo") && encoder.captureSessionInfo != null)
			{
				if (encoder.captureSessionInfo.videoFrameCountModeSwitch > 0)
				{
					let inputFrameSize = encoder.captureSessionInfo.videoFrameWidth + " x " + encoder.captureSessionInfo.videoFrameHeight;

					let inputFrameRate = formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, 1);

					let inputFrameInfo = inputFrameSize;
					if (inputFrameRate.length > 0)
					{
						inputFrameInfo += (encoder.captureSessionInfo.videoProgressive?' p ':' i ');
						inputFrameInfo += inputFrameRate;
					}

					videoSource = <span key="VideoSourceSource">&ensp;/&ensp;{appStrings.app.VideoSourceInput.toUpperCase()}:&nbsp;{ inputFrameInfo }</span>;
				}
			}
		}

		return videoSource;
	}

	getBroadcastCard(template, outputId, label = undefined)
	{
		let appStrings = this.props.strings;

		let templateName;

		templateName = this.state.template;

		if (templateName === undefined)
			return;

		if (template === undefined)
			return;

		let broadcastMerged = template.broadcastInput;

		if (broadcastMerged === undefined)
			return;

		let broadcastOutput = undefined;
		let broadcastInput = undefined;

		if (broadcastMerged.outputs !== undefined && outputId < broadcastMerged.outputs.length)
			broadcastOutput = broadcastMerged.outputs[outputId];

		if (broadcastMerged.input !== undefined)
			broadcastInput = broadcastMerged.input;

		if (broadcastOutput === undefined || broadcastInput === undefined)
			return;

		let videoBitrate = parseBytesStr(broadcastOutput.encodingConfiguration.encodingConfigurationVideo.bitrate);
		let audioBitrate = parseBytesStr(broadcastOutput.encodingConfiguration.encodingConfigurationAudio.bitrate);

		let frameRate = 30.0;
		if (broadcastInput.videoFrameRateMax !== undefined)
		{
			frameRate = broadcastInput.videoFrameRateMax;
		}

		let frameWidth = 0;
		let frameHeight = 0;

		if (broadcastOutput.encodingConfiguration &&
			broadcastOutput.encodingConfiguration.encodingConfigurationVideo &&
			broadcastOutput.encodingConfiguration.encodingConfigurationVideo.frameSizeWidth &&
			broadcastOutput.encodingConfiguration.encodingConfigurationVideo.frameSizeHeight)
		{
			frameWidth = broadcastOutput.encodingConfiguration.encodingConfigurationVideo.frameSizeWidth;
			frameHeight = broadcastOutput.encodingConfiguration.encodingConfigurationVideo.frameSizeHeight;
		}

		/*
		let frameWidth = broadcastInput.videoFrameWidthMax;
		let frameHeight = broadcastInput.videoFrameHeightMax;

		//radians: 90: 1.571, 180: 3.141, 270: 4.712

		//public static final int ASPECTRATIOMODE_SOURCE = 1;
		//public static final int ASPECTRATIOMODE_SQUARE = 2;
		//public static final int ASPECTRATIOMODE_CUSTOM = 3;

		if (broadcastInput.videoAspectRatioMode != undefined)
		{
			if (broadcastInput.videoAspectRatioRotation !== undefined)
			{
				if (broadcastInput.videoAspectRatioMode == 3 && Math.abs(broadcastInput.videoAspectRatioRotation - 4.712) < 0.01 || Math.abs(broadcastInput.videoAspectRatioRotation - 1.571) < 0.01)
				{
					let tmp = frameWidth;
					frameWidth = frameHeight;
					frameHeight = tmp;
				}
			}
			else
			{
				switch(broadcastInput.videoAspectRatioMode)
				{
					default:
					case 1:
						break;
					case 2:
						frameWidth = frameHeight;
						break;
					case 3:
						if (broadcastInput.videoAspectRatioWidth !== undefined && broadcastInput.videoAspectRatioHeight !== undefined)
						{
							frameWidth = (frameHeight * broadcastInput.videoAspectRatioWidth) / broadcastInput.videoAspectRatioHeight;
							frameWidth = parseInt((frameWidth + 0.5)/2) * 2;
						}
						break;
				}
			}
		}
		*/

		return (
			<Card key={ "OutputCard"+outputId } className="output-details-card">
				<div key={ "OutputCardBody"+outputId } className="output-details-body">
					{
						label !== undefined &&
						<div className="output-details-title">{ label }</div>
					}
					<div className="output-details-details">
					{ appStrings.app.VideoBitrate.toUpperCase() }:&nbsp;{ humanBitrate(videoBitrate) }&ensp;/&ensp;
					{ appStrings.app.AudioBitrate.toUpperCase() }:&nbsp;{ humanBitrate(audioBitrate) }&ensp;/&ensp;
					{ appStrings.app.VideoSourceOutput.toUpperCase() }:&nbsp;{ frameWidth + " x " + frameHeight + " p " + frameRate }
					</div>
				</div>
			</Card>
		);
	}

	getEncoderCard()
	{
		let appStrings = this.props.strings;

		let encoderId;

		if (this.state.isEdit)
		{
			encoderId = this.state.encoder1;
		}
		else
		{
			if (this.encoder1.current != null && this.encoder1.current.value != null)
				encoderId = this.encoder1.current.value;
		}

		if (encoderId === undefined)
			return;

		let encoderMap = this.props.clearcaster.encoderMap;
		if (!encoderMap.hasOwnProperty(encoderId))
		{
			console.log("getEncoderCard: no encoder id");
			return;
		}

		let encoder = encoderMap[encoderId];

		return (
			<Card className="encoder-status">
				<div className="dialog-card-body">
					{ appStrings.app.ApplianceStatus.toUpperCase() }:&nbsp;{ this.getEncoderStatus(encoder) }{ this.getVideoSource(encoder) }
				</div>
			</Card>
		);
	}

	getStateVariableId(template, variableName)
	{
		let singleOutput = ((template.singleOutput !== undefined)?template.singleOutput:false);

		let variableId = "widget-"+(singleOutput?"singleOutput":template.id)+"-"+variableName;

		return variableId;
	}

	onChangeVariable(evt, id)
	{
		let obj = {};

		let value = evt.target.value;

		obj[id] = value;

		this.setState(obj);
	}

	onChangeVariableCheckbox(evt, id)
	{
		let obj = {};

		let value = evt.target.checked;

		obj[id] = value;

		this.setState(obj);
	}

	getVariableValue(id, applyUnits = true)
	{
		let value = undefined;

		if (this.state.hasOwnProperty(id))
		{
			value = this.state[id];

			if (applyUnits && this.state.hasOwnProperty(id+'-param'))
			{
				let param = this.state[id+'-param'];

				value = unitValueToRawValue(param, value);
			}
		}

		// console.log("getVariableValue["+id+"]: "+this.state.hasOwnProperty(id)+":"+JSON.stringify(value));

		return value;
	}

	isParameterRequired(param)
	{
		return (param.required !== undefined && param.required)?true:false;
	}

	renderWidgetOutputDetails(template, widget)
	{
		let outputId = 0;
		let label = undefined;

		try
		{
			if (widget.parameters !== undefined && widget.parameters.output)
			{
				if (widget.parameters.output.index !== undefined)
				{
					outputId = widget.parameters.output.index;
				}
				else if (widget.parameters.output.outputTag !== undefined)
				{
					if (template.broadcastInput.outputs !== undefined && template.broadcastInput.outputs.length > 0)
					{
						for(let i in template.broadcastInput.outputs)
						{
							let output = template.broadcastInput.outputs[i];

							if (output.tag !== undefined && output.tag === widget.parameters.output.outputTag)
							{
								outputId = i;
								break;
							}
						}
					}
				}

				if (widget.label !== undefined)
					label = widget.label;
				else if (widget.parameters.label !== undefined)
					label = widget.parameters.label;
			}
		}
		catch(error)
		{
		}

		return <Form.Group key={ "OutputDetails"+outputId } controlId="outputCard"> { this.getBroadcastCard(template, outputId, label) }</Form.Group>;
	}

	parseParameterValue(param, value)
	{
		if (param.hasOwnProperty("units"))
		{
			switch(param.units)
			{
				case WIDGET_UNITS.BITRATE_BITS:
				case WIDGET_UNITS.BITRATE_KBPS:
				case WIDGET_UNITS.BITRATE_MBPS:
					value = parseBytesStr(value);
					break;
				default:
			}
		}

		return value;
	}

	getParameterDefaultValue(template, param)
	{
		if (param.hasOwnProperty("defaultValue"))
		{
			let value = param.defaultValue;

			value = this.parseParameterValue(param, value);

			return value;
		}

		return '';
	}

	unitsToStr(units)
	{
		let unitStr = "";

		switch(units)
		{
			case WIDGET_UNITS.BITRATE_BITS:
				unitStr = "bps";
				break;
			case WIDGET_UNITS.BITRATE_KBPS:
				unitStr = "Kbps";
				break;
			case WIDGET_UNITS.BITRATE_MBPS:
				unitStr = "Mbps";
				break;
			default:
		}

		return unitStr;
	}

	onChangeVariableSelectCustom(evt, id)
	{
		this.onChangeVariable(evt, id);

		let valueStr = evt.target.value;

		let valueMap = {};
		let newState = {};

		let parts = valueStr.split(",");
		for(let v in parts)
		{
			let part = parts[v];

			let keyValuePair = part.split("=");
			if (keyValuePair.length >= 2)
			{
				let key = keyValuePair[0];
				let value = keyValuePair[1];

				valueMap[key] = value;
			}
		}

		let varParts = id.split(":");
		if (varParts.length >= 2)
		{
			let baseId = varParts[0];

			let pairs = varParts[1].split(",");
			for(let p in pairs)
			{
				let pair = pairs[p];

				let keyValuePair = pair.split("=");
				if (keyValuePair.length >= 2)
				{
					let key = keyValuePair[0];
					let value = keyValuePair[1];

					if (valueMap.hasOwnProperty(key) && this.state.hasOwnProperty(baseId+value+'-param'))
					{
						let unitValue = rawValueToUnitValue(this.state[baseId+value+'-param'], valueMap[key]);
						newState[baseId+value] = unitValue;
					}
				}
			}

			//console.log(JSON.stringify(newState, null, 2));

			this.setState(newState);
		}
	}

	getVariableValueSelectCustom(id, applyUnits)
	{
		let newValue = "";

		let varParts = id.split(":");
		if (varParts.length >= 2)
		{
			let baseId = varParts[0];

			let pairs = varParts[1].split(",");
			for(let p in pairs)
			{
				let pair = pairs[p];

				let keyValuePair = pair.split("=");
				if (keyValuePair.length >= 2)
				{
					let key = keyValuePair[0];
					let value = keyValuePair[1];

					if (this.state.hasOwnProperty(baseId+value))
					{
						if (newValue.length > 0)
							newValue += ",";

						newValue += key+"="+this.getVariableValue(baseId+value);
					}
				}
			}
		}

		//console.log("newValue:"+newValue);

		return newValue;
	}

	onChangeVariableCheckboxCustom(evt, id, options)
	{
		let option = options[evt.target.checked.toString()];

		let newState = {};

		let varParts = id.split(":");
		if (varParts.length >= 2)
		{
			let baseId = varParts[0];

			let pairs = varParts[1].split(",");
			for(let p in pairs)
			{
				let pair = pairs[p];

				let keyValuePair = pair.split("=");
				if (keyValuePair.length >= 2)
				{
					let key = keyValuePair[0];
					let value = keyValuePair[1];

					if (option.hasOwnProperty(key) && this.state.hasOwnProperty(baseId+value+'-param'))
					{
						let unitValue = rawValueToUnitValue(this.state[baseId+value+'-param'], option[key]);
						newState[baseId+value] = unitValue;
					}
				}
			}

			//console.log(JSON.stringify(newState, null, 2));

			this.setState(newState);
		}
	}

	getVariableValueCheckboxCustom(id, applyUnits, options)
	{
		let scores = {
			true: 0,
			false: 0
		}

		let varParts = id.split(":");
		if (varParts.length >= 2)
		{
			let baseId = varParts[0];

			let pairs = varParts[1].split(",");
			for(let p in pairs)
			{
				let pair = pairs[p];

				let keyValuePair = pair.split("=");
				if (keyValuePair.length >= 2)
				{
					let key = keyValuePair[0];
					let value = keyValuePair[1];

					if (this.state.hasOwnProperty(baseId+value))
					{
						let varValue = this.getVariableValue(baseId+value);

						//console.log(baseId+value+":"+key+":"+varValue);

						if (options[true][key] !== undefined && options[true][key] === varValue)
							scores[true]++;
						else if (options[false][key] !== undefined && options[false][key] === varValue)
							scores[false]++;
					}
				}
			}
		}

		//console.log(JSON.stringify(scores));

		return scores[true] > scores[false]?true:false;
	}

	renderWidgetCheckboxCustom(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined || widget.options === undefined)
			return;

		let id = this.getStateVariableId(template, "");

		id += ":";

		let index = 0;
		for(let o in widget.parameters)
		{
			if (index > 0)
				id += ",";

			id += o+"="+widget.parameters[o].variable;
			index++;
		}

		let options = JSON.parse(JSON.stringify(widget.options));

		for(let i in options)
		{
			let option = options[i];
			for(let o in option)
			{
				if (widget.parameters.hasOwnProperty(o))
				{
					option[o] = this.parseParameterValue(widget.parameters[o], option[o]);
				}
			}
		}

		//console.log(JSON.stringify(options, null, 2));

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Check key={ id } id={ id } checked={ _this.getVariableValueCheckboxCustom(id, false, options) } onChange={ evt => _this.onChangeVariableCheckboxCustom(evt, id, options) } type="checkbox" label={ widget.label } />
			</Form.Group>
		</div>;
	}

	renderWidgetCheckboxSimple(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.value === undefined || widget.parameters.value.variable === undefined
			)
		{
			console.log("renderWidgetCheckboxSimple: missing: "+JSON.stringify(widget.parameters));
			return;
		}

		let id = this.getStateVariableId(template, widget.parameters.value.variable);
		let checkedString = _this.getVariableValue(id, false) + '';
		let checked = (checkedString === 'true') ? true : false;

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Check key={ id } id={ id } checked={ checked } onChange={ evt => _this.onChangeVariableCheckbox(evt, id) } type="checkbox" label={ widget.label } />
			</Form.Group>
		</div>;
	}

	renderWidgetSelectCustom(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined || widget.options === undefined)
			return;

		let id = this.getStateVariableId(template, "");

		id += ":";

		let index = 0;
		for(let o in widget.parameters)
		{
			if (index > 0)
				id += ",";

			id += o+"="+widget.parameters[o].variable;
			index++;
		}
		let required = (widget.required!==undefined?widget.required:false);

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Label>{ widget.label }&nbsp;{ (required?"*":"") }</Form.Label>
				<Form.Control className="broadcast-create-input-select" key={ id } id={ id } value={ _this.getVariableValueSelectCustom(id, false) } onChange={ evt => _this.onChangeVariableSelectCustom(evt, id) } as="select" disabled={widget.disableOnEdit ? this.state.isEdit : false }>
					{ Object.keys(widget.options).map ((key, index) => {

						let option = widget.options[key];

						let idStr = "";
						index = 0;
						for(let o in option)
						{
							if (widget.parameters.hasOwnProperty(o))
							{
								let param = widget.parameters[o];

								if (index > 0)
									idStr += ",";

								let value = option[o];

								value = this.parseParameterValue(param, value);

								idStr += o+"="+value;
								index++;
							}
						}

						//console.log("************ idStr:"+idStr);

						return (
						<option key={ idStr } value={ idStr }>{ key }</option>
					)})}
				</Form.Control>
			</Form.Group>
		</div>;
	}

	renderWidgetVideoFrameSizeFitMode(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.frameSizeFitMode === undefined || widget.parameters.frameSizeFitMode.variable === undefined
		)
			return;

		let id = this.getStateVariableId(template, widget.parameters.frameSizeFitMode.variable);

		let required = this.isParameterRequired(widget.parameters.frameSizeFitMode);

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Label>{ widget.parameters.frameSizeFitMode.label }&nbsp;{ (required?"*":"") }</Form.Label>
				<Form.Control className="broadcast-create-input-select" key={ id } id={ id } value={ _this.getVariableValue(id, false) } onChange={ evt => _this.onChangeVariable(evt, id) } as="select">
					<option key={ "letterbox" } value={ "letterbox" }>{ "Letterbox" }</option>
					<option key={ "fit-width" } value={ "fit-width" }>{ "Fit Width" }</option>
					<option key={ "fit-height" } value={ "fit-height" }>{ "Fit Height" }</option>
					<option key={ "crop" } value={ "crop" }>{ "Crop" }</option>
					<option key={ "stretch" } value={ "stretch" }>{ "Stretch" }</option>
					<option key={ "match-source" } value={ "match-source" }>{ "Match Source" }</option>
				</Form.Control>
			</Form.Group>
		</div>;
	}

	renderWidgetVideoImplementationH264(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.implementation === undefined || widget.parameters.implementation.variable === undefined
		)
			return;

		let id = this.getStateVariableId(template, widget.parameters.implementation.variable);

		let required = this.isParameterRequired(widget.parameters.implementation);

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Label>{ widget.parameters.implementation.label }&nbsp;{ (required?"*":"") }</Form.Label>
				<Form.Control className="broadcast-create-input-select" key={ id } id={ id } value={ _this.getVariableValue(id, false) } onChange={ evt => _this.onChangeVariable(evt, id) } as="select">
					<option key={ "x264" } value={ "x264" }>{ "x264" }</option>
					<option key={ "QuickSync" } value={ "QuickSync" }>{ "Quick Sync" }</option>
				</Form.Control>
			</Form.Group>
		</div>;
	}

	renderWidgetVideoProfileH264(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.profile === undefined || widget.parameters.profile.variable === undefined
		)
			return;

		let id = this.getStateVariableId(template, widget.parameters.profile.variable);

		let required = this.isParameterRequired(widget.parameters.profile);

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Label>{ widget.parameters.profile.label }&nbsp;{ (required?"*":"") }</Form.Label>
				<Form.Control className="broadcast-create-input-select" key={ id } id={ id } value={ _this.getVariableValue(id, false) } onChange={ evt => _this.onChangeVariable(evt, id) } as="select">
					<option key={ "baseline" } value={ "baseline" }>{ "Baseline" }</option>
					<option key={ "main" } value={ "main" }>{ "Main" }</option>
					<option key={ "high" } value={ "high" }>{ "High" }</option>
				</Form.Control>
			</Form.Group>
		</div>;
	}

	onChangeVariableBitrate(evt, id)
	{
		this.onChangeVariable(evt, id);

		let param = this.state[id+'-param'];

		if (param !== undefined)
		{
			let newState = {};

			newState[id + '-invalid'] = false;

			let value = unitValueToRawValue(param, evt.target.value);

			if (param.maxValue !== undefined)
			{
				let maxValue = parseBytesStr(param.maxValue);

				if (value > maxValue && param.maxWarning !== undefined)
				{
					newState[id + '-feedback'] = param.maxWarning;
					newState[id + '-invalid'] = true;
				}
			}

			if (param.minValue !== undefined)
			{
				let minValue = parseBytesStr(param.minValue);

				if (value < minValue && param.minWarning !== undefined)
				{
					newState[id + '-feedback'] = param.minWarning;
					newState[id + '-invalid'] = true;
				}
			}

			this.setState(newState);
		}
	}

	renderWidgetBitrate(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.bitrate === undefined || widget.parameters.bitrate.variable === undefined ||
			widget.parameters.bitrate.units === undefined
		)
			return;

		let id = this.getStateVariableId(template, widget.parameters.bitrate.variable);

		let required = this.isParameterRequired(widget.parameters.bitrate);

		let units = widget.parameters.bitrate.units;

		let unitStr = this.unitsToStr(units);

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Label>{ widget.parameters.bitrate.label }&nbsp;{ (required?"*":"") }</Form.Label>
				<InputGroup className="broadcast-create-input-number">
					<FormControl key={ id } id={ id } value={ _this.getVariableValue(id, false) } onChange={ evt => _this.onChangeVariableBitrate(evt, id) } isInvalid={ this.state[id+'-invalid'] } />
					<InputGroup.Append>
						<InputGroup.Text>{ unitStr }</InputGroup.Text>
					</InputGroup.Append>
					<Form.Control.Feedback type="invalid">{ this.state[id+'-feedback'] }</Form.Control.Feedback>
				</InputGroup>
			</Form.Group>
		</div>;
	}

	onChangeVariableNumeric(evt, id)
	{
		this.onChangeVariable(evt, id);

		let param = this.state[id+'-param'];

		if (param !== undefined)
		{
			let newState = {};

			newState[id + '-invalid'] = false;

			let value = unitValueToRawValue(param, evt.target.value);

			if (param.maxValue !== undefined)
			{
				let maxValue = parseFloat(param.maxValue);

				if (value > maxValue && param.maxWarning !== undefined)
				{
					newState[id + '-feedback'] = param.maxWarning;
					newState[id + '-invalid'] = true;
				}
			}

			if (param.minValue !== undefined)
			{
				let minValue = parseFloat(param.minValue);

				if (value < minValue && param.minWarning !== undefined)
				{
					newState[id + '-feedback'] = param.minWarning;
					newState[id + '-invalid'] = true;
				}
			}

			this.setState(newState);
		}
	}

	renderWidgetTextEnterInt(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.value === undefined || widget.parameters.value.variable === undefined
			)
		{
			console.log("renderWidgetTextEnterInt: missing: "+JSON.stringify(widget.parameters));
			return;
		}

		let id = this.getStateVariableId(template, widget.parameters.value.variable);

		let required = this.isParameterRequired(widget.parameters.value);

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Label>{ widget.parameters.value.label }&nbsp;{ (required?"*":"") }</Form.Label>
				<Form.Control className="broadcast-create-input-number" key={ id } id={ id } value={ _this.getVariableValue(id, false) } onChange={ evt => _this.onChangeVariableNumeric(evt, id) } isInvalid={ this.state[id+'-invalid'] } />
				<Form.Control.Feedback type="invalid">{ this.state[id+'-feedback'] }</Form.Control.Feedback>
			</Form.Group>
			</div>;
	}

	renderWidgetTextEnterFloat(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.value === undefined || widget.parameters.value.variable === undefined
		)
			return;

		let id = this.getStateVariableId(template, widget.parameters.value.variable);

		let required = this.isParameterRequired(widget.parameters.value);

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Label>{ widget.parameters.value.label }&nbsp;{ (required?"*":"") }</Form.Label>
				<Form.Control className="broadcast-create-input-number" key={ id } id={ id } value={ _this.getVariableValue(id, false) } onChange={ evt => _this.onChangeVariableNumeric(evt, id) } isInvalid={ this.state[id+'-invalid'] } />
				<Form.Control.Feedback type="invalid">{ this.state[id+'-feedback'] }</Form.Control.Feedback>
			</Form.Group>
		</div>;
	}

	renderWidgetTextEnterString(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.value === undefined || widget.parameters.value.variable === undefined
		)
			return;

		let id = this.getStateVariableId(template, widget.parameters.value.variable);

		let required = this.isParameterRequired(widget.parameters.value);

		return <div key={ id+"-div" }>
			<Form.Group key={ id+"-group" }>
				<Form.Label>{ widget.parameters.value.label }&nbsp;{ (required?"*":"") }</Form.Label>
				<Form.Control key={ id } id={ id } value={ _this.getVariableValue(id) } onChange={ evt => _this.onChangeVariable(evt, id) } />
			</Form.Group>
		</div>;
	}

	renderWidgetStreamTargetRTMPNoCredentials(template, widget)
	{
		let appStrings = this.props.strings;

		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.url === undefined || widget.parameters.url.variable === undefined ||
			widget.parameters.streamName === undefined || widget.parameters.streamName.variable === undefined)
			return;

		let urlId = this.getStateVariableId(template, widget.parameters.url.variable);
		let streamNameId = this.getStateVariableId(template, widget.parameters.streamName.variable);

		let urlRequired = this.isParameterRequired(widget.parameters.url);
		let streamNameRequired = this.isParameterRequired(widget.parameters.streamName);

		return <div key={ urlId+"-div" }>
			<Form.Group key={ urlId+"-group" }>
				<Form.Label>{ widget.parameters.url.label }&nbsp;{ (urlRequired?"*":"") }</Form.Label>
				<Form.Control key={ urlId } id={ urlId } placeholder={ appStrings.app.EnterServerURL } value={ _this.getVariableValue(urlId) } onChange={ evt => _this.onChangeVariable(evt, urlId) } />
			</Form.Group>
			<Form.Group key={ streamNameId+"-group" }>
				<Form.Label>{ widget.parameters.streamName.label }{ (streamNameRequired?"*":"") }</Form.Label>
				<Form.Control key={ streamNameId } id={ streamNameId } value={ _this.getVariableValue(streamNameId) } onChange={ evt => _this.onChangeVariable(evt, streamNameId) } />
			</Form.Group></div>;
	}

	renderWidgetStreamTargetRTMPWithCredentials(template, widget)
	{
		let appStrings = this.props.strings;

		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.url === undefined || widget.parameters.url.variable === undefined ||
			widget.parameters.streamName === undefined || widget.parameters.streamName.variable === undefined ||
			widget.parameters.username === undefined || widget.parameters.username.variable === undefined ||
			widget.parameters.password === undefined || widget.parameters.password.variable === undefined
			)
			return;

		let urlId = this.getStateVariableId(template, widget.parameters.url.variable);
		let streamNameId = this.getStateVariableId(template, widget.parameters.streamName.variable);
		let usernameId = this.getStateVariableId(template, widget.parameters.username.variable);
		let passwordId = this.getStateVariableId(template, widget.parameters.password.variable);

		let urlRequired = this.isParameterRequired(widget.parameters.url);
		let streamNameRequired = this.isParameterRequired(widget.parameters.streamName);

		let usernameShowHideId = usernameId+"-showhide";

		return <div key={ urlId+"-div" }>
			<Form.Group key={ urlId+"-group" } >
				<Form.Label>{ widget.parameters.url.label }&nbsp;{ (urlRequired?"*":"") }</Form.Label>
				<Form.Control key={ urlId } id={ urlId } placeholder={ appStrings.app.EnterServerURL } value={ _this.getVariableValue(urlId) } onChange={ evt => _this.onChangeVariable(evt, urlId) } />
				{ _this.state[usernameShowHideId] ?
					<div>
						<span id={ usernameShowHideId } onClick={ this.toggleRTMPShowing } className="authentication-prompt">{ appStrings.app.EnterRTMPAuthentication }&nbsp;&nbsp;<i className="fa fa-caret-down" aria-hidden="true"></i></span>
						<div className="dialog-multifield-spacer"></div>
						<div className="dialog-multifield-spacer"></div>
						<InputGroup className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text className="dialog-prepend-label">{ widget.parameters.username.label }</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl key={ usernameId } id={ usernameId } value={ _this.getVariableValue(usernameId) } onChange={ evt => _this.onChangeVariable(evt, usernameId) } />
							<InputGroup.Prepend>
								<InputGroup.Text className="dialog-prepend-label">{ widget.parameters.password.label }</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control key={ passwordId } id={ passwordId } value={ _this.getVariableValue(passwordId) } onChange={ evt => _this.onChangeVariable(evt, passwordId) } />
						</InputGroup>
					</div> :
					<div>
						<span id={ usernameShowHideId } onClick={ this.toggleRTMPShowing } className="authentication-prompt">{ appStrings.app.EnterRTMPAuthentication }&nbsp;&nbsp;<i className="fa fa-caret-right" aria-hidden="true"></i></span>
					</div>
				}
			</Form.Group>
			<Form.Group key={ streamNameId+"-group" }>
				<Form.Label>{ widget.parameters.streamName.label }{ (streamNameRequired?"*":"") }</Form.Label>
				<Form.Control key={ streamNameId } id={ streamNameId } value={ _this.getVariableValue(streamNameId) } onChange={ evt => _this.onChangeVariable(evt, streamNameId) } />
			</Form.Group></div>;
	}

	onChangeVariableStreamTargetProviderProviderKeyId(evt, providerKeyIdId, providerIdId)
	{
		this.onChangeVariable(evt, providerKeyIdId);

		let providerKeyId = evt.target.value;
		let providerId = undefined;

		if (this.providerStreamTargetMap.hasOwnProperty(providerKeyId))
		{
			let targets = this.providerStreamTargetMap[providerKeyId].list;
			if (targets.length > 0)
			{
				providerId = targets[0].providerId;
			}
		}

		let newState = {};

		newState[providerIdId] = providerId;

		console.log("onChangeVariableStreamTargetProviderProviderKeyId:"+JSON.stringify(newState));

		this.setState(newState);

	}

	async onChangeVariableStreamTargetProviderProviderId(evt, providerKeyIdId, providerIdId)
	{
		this.onChangeVariable(evt, providerIdId);

		let providerKeyId = this.getVariableValue(providerKeyIdId);
		let providerId = evt.target.value;

		let providerType = this.getProviderType(providerKeyId, providerId);

		console.log("queryBroadcastProviderStreamTarget[request]: providerKeyId:"+providerKeyId+" providerId:"+providerId+" providerType:"+providerType);

		let retObj = await this.props.controller.queryBroadcastProviderStreamTarget(providerKeyId, providerId, providerType);


		let isValid = (retObj && retObj.data && retObj.data.broadcastProviderStreamTarget)?true:false;

		console.log("onChangeVariableStreamTargetProviderProviderId: isValid:"+isValid);

		// I need to do more work to make this really work
		//let newState = {};
		//newState[providerIdWarning2Id] = isValid?undefined:'Wowza Streaming Cloud stream is not compatible with ClearCaster delivery.';
		//this.setState(newState);
	}

	renderWidgetStreamTargetProvider(template, widget)
	{
		let _this = this;

		if (widget.parameters === undefined ||
			widget.parameters.providerKeyId === undefined || widget.parameters.providerKeyId.variable === undefined ||
			widget.parameters.providerId === undefined || widget.parameters.providerId.variable === undefined
			)
			return;

		let providerKeyIdId = this.getStateVariableId(template, widget.parameters.providerKeyId.variable);
		let providerIdId = this.getStateVariableId(template, widget.parameters.providerId.variable);

		let providerIdWarningId = providerIdId+'-warning';
		let providerIdWarning2Id = providerIdId+'-warning2';

		let providerKeyIdValue = this.getVariableValue(providerKeyIdId);

		// let the widget template define what type of provider keys to display
		let namespaceProviderKeysFilter = widget.parameters.providerKeyId.filter;
		let namespaceProviderKeys = this.props.clearcaster.namespaceProviderKeys.filter((key) => { return key.type === namespaceProviderKeysFilter})

		let streamTargets = [];
		if (providerKeyIdValue !== undefined && this.providerStreamTargetMap.hasOwnProperty(providerKeyIdValue))
		{
			streamTargets = this.providerStreamTargetMap[providerKeyIdValue].list;
		}

		let providerKeyIdRequired = this.isParameterRequired(widget.parameters.providerKeyId);
		let providerIdRequired = this.isParameterRequired(widget.parameters.providerId);

		let noProviderKeyMessage = this.props.strings.app.BroadcastProviderStreamTarget.NoProviderKeyMessage[namespaceProviderKeysFilter];
		let noProviderIdMessage = this.props.strings.app.BroadcastProviderStreamTarget.NoProviderIdMessage[namespaceProviderKeysFilter];
		return <div key={ providerKeyIdId+"-div" }>
			{
				this.state[providerIdWarningId] !== undefined &&
				<div style={{marginBottom:"5px"}}>&nbsp;<i className="fa fa-exclamation-triangle fa-lg fa-fw icon-warning" aria-hidden="true"></i>&nbsp;<i>{ this.state[providerIdWarningId] }</i></div>
			}
			{
				// There is no access key or access token
				namespaceProviderKeys.length <= 0 ?
				<Form.Group key={ providerKeyIdId+"-group" } >
					<Form.Label>{ widget.parameters.providerKeyId.label}</Form.Label>
					<div>&nbsp;<i className="fa fa-exclamation-triangle fa-lg fa-fw icon-warning" aria-hidden="true"></i>&nbsp;<i>{noProviderKeyMessage}</i></div>
				</Form.Group> :
				<div>
				{
					// show the list of provider keys (access token or wsc api keys) if there are more than one
					namespaceProviderKeys.length > 1 &&
					<Form.Group key={ providerKeyIdId+"-group" } >
						<Form.Label>{ widget.parameters.providerKeyId.label }&nbsp;{ (providerKeyIdRequired?"*":"") }</Form.Label>
						<Form.Control className="broadcast-create-input-select" key={ providerKeyIdId } id={ providerKeyIdId } value={ _this.getVariableValue(providerKeyIdId) } onChange={ evt => _this.onChangeVariableStreamTargetProviderProviderKeyId(evt, providerKeyIdId, providerIdId) } as="select" disabled={widget.disableOnEdit ? this.state.isEdit : false }>
						{ namespaceProviderKeys.map ((v, key) => {
							return (
								<option key={ v.id } value={ v.id }>{ v.name }</option>
						)})}
						</Form.Control>
					</Form.Group>
				}
				</div>
			}
			{
				//display the list of provider ID's available for the above selected or default key (wsc tcodrs or linkedin urns)
				namespaceProviderKeys.length > 0 &&
					<div>
					{
						streamTargets.length <= 0 ?
						<Form.Group key={ providerIdId+"-group" } >
							<Form.Label>{ widget.parameters.providerId.label }</Form.Label>
							<div>&nbsp;<i className="fa fa-exclamation-triangle fa-lg fa-fw icon-warning" aria-hidden="true"></i>&nbsp;<i>{noProviderIdMessage}</i></div>
						</Form.Group> :
						<Form.Group key={ providerIdId+"-group" } >
							<Form.Label>{ widget.parameters.providerId.label }&nbsp;{ (providerIdRequired?"*":"") }</Form.Label>
							<Form.Control key={ providerIdId } id={ providerIdId } ref={ providerIdId } value={ _this.getVariableValue(providerIdId) } onChange={ evt => _this.onChangeVariableStreamTargetProviderProviderId(evt, providerKeyIdId, providerIdId) } as="select" disabled={widget.disableOnEdit ? this.state.isEdit : false }>
							{ streamTargets.map ((v, key) => {
								return (
									<option key={ v.providerId } value={ v.providerId }>{ v.providerName }</option>
							)})}
							</Form.Control>
						</Form.Group>
					}
					</div>
			}
			{
				this.state[providerIdWarning2Id] !== undefined &&
				<div style={{marginBottom:"8px"}}>&nbsp;<i className="fa fa-exclamation-triangle fa-lg fa-fw icon-warning" aria-hidden="true"></i>&nbsp;<i>{ this.state[providerIdWarning2Id] }</i></div>
			}
			</div>;
	}

	async preloadTemplateData(template)
	{

		if (template === undefined)
			return;
		if (template.id !== undefined && template.presentation !== undefined && template.presentation.widgets !== undefined)
		{
			for(let wid in template.presentation.widgets)
			{
				let widget = template.presentation.widgets[wid];
				switch(widget.widget)
				{
					case "StreamTargetProvider":
						if (this.loadWowzaCloudStreamTargets || this.loadLinkedInLiveStreamTargets)
						{
							if(widget.parameters.providerKeyId.filter === "WSC_PUBLIC_API")
							{
								this.loadWowzaCloudStreamTargets = false;
							} else if (widget.parameters.providerKeyId.filter === "LINKEDIN_ACCESS_TOKEN")
							{
								this.loadLinkedInLiveStreamTargets = false;
							}

							let namespaceProviderKeysFilter = widget.parameters.providerKeyId.filter;
							let overlayMessage = this.props.strings.app.BroadcastProviderStreamTarget.OverlayMessage[namespaceProviderKeysFilter];
							let namespaceProviderKeys = this.props.clearcaster.namespaceProviderKeys.filter((key) => { return key.type === namespaceProviderKeysFilter})
							ClearCasterStore.dispatch({
								type: ActionTypes.SHOW_WAITOVERLAY,
								loadingOverlayMessage: overlayMessage
							});

							for(let i in namespaceProviderKeys)
							{
								let providerKey = namespaceProviderKeys[i];

								let retObj = await this.props.controller.queryBroadcastProviderStreamTargets(providerKey.id);

								if (retObj && retObj.data && retObj.data.broadcastProviderStreamTargets)
								{
									let myList = retObj.data.broadcastProviderStreamTargets;

									myList.sort((a,b) => { if (a.providerName === undefined || b.providerName === undefined) return 0; return a.providerName.localeCompare(b.providerName); } );

									let myMap = {};
									for(let j in myList)
									{
										let myItem = myList[j];
										myMap[myItem.providerId] = myItem;
									}

									this.providerStreamTargetMap[providerKey.id] = {list: myList, map: myMap};
								}
							}

							ClearCasterStore.dispatch({
								type: ActionTypes.HIDE_WAITOVERLAY,
							});
						}
						break;
					default:
				}
			}
		}
	}

	renderTemplate(template)
	{
		if (template === undefined)
			return;

		let elements;

		if (template.id !== undefined && template.presentation !== undefined && template.presentation.widgets !== undefined)
		{
			for(let wid in template.presentation.widgets)
			{
				let widget = template.presentation.widgets[wid];

				let element = undefined;
				switch(widget.widget)
				{
					case "OutputDetails":
						element = this.renderWidgetOutputDetails(template, widget);
						break;
					case "StreamTargetRTMPNoCredentials":
						element = this.renderWidgetStreamTargetRTMPNoCredentials(template, widget);
						break;
					case "StreamTargetRTMPWithCredentials":
						element = this.renderWidgetStreamTargetRTMPWithCredentials(template, widget);
						break;
					case "StreamTargetProvider":
						element = this.renderWidgetStreamTargetProvider(template, widget);
						break;
					case "TextEnterInt":
						element = this.renderWidgetTextEnterInt(template, widget);
						break;
					case "TextEnterFloat":
						element = this.renderWidgetTextEnterFloat(template, widget);
						break;
					case "TextEnterString":
						element = this.renderWidgetTextEnterString(template, widget);
						break;
					case "Bitrate":
						element = this.renderWidgetBitrate(template, widget);
						break;
					case "VideoProfileH264":
						element = this.renderWidgetVideoProfileH264(template, widget);
						break;
					case "VideoImplementationH264":
						element = this.renderWidgetVideoImplementationH264(template, widget);
						break;
					case "VideoFrameSizeFitMode":
						element = this.renderWidgetVideoFrameSizeFitMode(template, widget);
						break;
					case "SelectCustom":
						element = this.renderWidgetSelectCustom(template, widget);
						break;
					case "CheckboxCustom":
						element = this.renderWidgetCheckboxCustom(template, widget);
						break;
					case "CheckboxSimple":
						element = this.renderWidgetCheckboxSimple(template, widget);
						break;
					default:
					}

				if (element !== undefined)
					elements = [elements, element];
			}
		}

		return <div>{elements}</div>;
	}

	getBuiltInOverlayUrl(graphicId)
	{
		let url = undefined;

		if (this.props.clearcaster.singularLiveData.appInstances.instances !== undefined && this.props.clearcaster.singularLiveData.appInstances.instances.hasOwnProperty(graphicId))
		{
			let graphicDef = this.props.clearcaster.singularLiveData.appInstances.instances[graphicId].definition;
			if (graphicDef !== undefined)
			{
				url = graphicDef.onair_url;
			}
		}

		return url;
	}

	getBuiltInOverlays()
	{
		let elements;

		let appStrings = this.props.strings;
		let singularLiveData = this.props.clearcaster.singularLiveData;

		if (singularLiveData.appInstances.order !== undefined)
		{
			for(let i in singularLiveData.appInstances.order)
			{
				let appInstanceId = singularLiveData.appInstances.order[i];
				let appInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,appInstanceId);
				let name = SingularLiveGraphicsController.getName(singularLiveData,appInstance);

				let element = <option key={ "builtin-singularlive:"+appInstanceId } value={ "builtin-singularlive:"+appInstanceId }>{ appStrings.app.VendorBuiltIn }: { name }</option>;

				elements = [elements, element];
			}
		}

		return elements;
	}

	render()
    {
    	//console.log(JSON.stringify(this.props.clearcaster.broadcastTemplates, null, 2));

		if (this.props.clearcaster.broadcastTemplates === undefined)
			return <div></div>;

		let appStrings = this.props.strings;
		let templateInfo = this.props.clearcaster.broadcastTemplates;

		let encodersByModel = this.getEncodersByModel();

		let customOnly = false;
		if (!templateInfo.settings.showBuiltInTemplates && this.props.clearcaster.broadcastTemplateCounts[TEMPLATECOUNTS_TEMPLATES_ENABLED] > 0)
			customOnly = true;

		let templateSet = this.getEncoderTemplateSet(this.state.encoder1);
		let templateCategoryList = getTemplateCategoryListV2(templateInfo, templateSet, customOnly, this.filterEnabled, this.filterEmptyDestinations);
		let templateList = getTemplateListV2(templateInfo, templateSet, this.state.templateCategory, this.filterEnabled);

		let templateCategoryListBuiltInGroups = [];
		let templateCategoryListCustom = [];
		for(let i in templateInfo.templateCategoryGroups)
		{
			let group = {...templateInfo.templateCategoryGroups[i],templateCategories:[]};
			templateCategoryListBuiltInGroups.push(group);
		}
		for(let i in templateCategoryList)
		{
			let templateCategory = templateCategoryList[i];

			if (isTemplateObjManageOnly(templateCategory))
				continue;

			if (isTemplateObjReadOnly(templateCategory))
			{
				for(let j = 0; j < templateInfo.templateCategoryGroups.length; j++ )
				{
					if (templateInfo.templateCategoryGroups[j].templateCategories.includes(templateCategory.id))
					{
						for(let k = 0; k < templateCategoryListBuiltInGroups.length; k++)
						{
							if(templateCategoryListBuiltInGroups[k].name === templateInfo.templateCategoryGroups[j].name)
							{
								templateCategoryListBuiltInGroups[k].templateCategories.push(templateCategory);
							}
						}
					}
				}
			}
			else
				templateCategoryListCustom.push(templateCategory);
		}

		let template = this.getTemplate(this.state.template);

		return (
			<div>
			<Modal size="lg" show={ this.props.clearcaster.broadcastCreateDialogShowing } onEnter={ this.onEnter } onShow={ this.onShow } onHide={this.handleClose} className="create-broadcast">
				<LoadingOverlay active={ this.props.clearcaster.isLoadingOverlayShowing } spinner text={ this.props.clearcaster.loadingOverlayMessage } >
					<Modal.Header closeButton>
						<Modal.Title>{ this.state.it?appStrings.app.EditBroadcast:appStrings.app.CreateBroadcast }</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						{ this.state.alert !== undefined &&
							<Alert dismissible variant="danger">
							{ this.state.alert.heading !== undefined &&
								<Alert.Heading>{ this.state.alert.heading }</Alert.Heading>
							}
							{ this.state.alert.body }
							</Alert>
						}
						<Form>
							<Form.Group controlId="title">
								<Form.Label>{ appStrings.app.BroadcastName }&nbsp;*</Form.Label>
								<Form.Control placeholder={ appStrings.app.EnterTitle } value={ this.state.title } onChange={evt => this.setState({ title: evt.target.value })} disabled={this.props.clearcaster.broadcastSharing.active}/>
							</Form.Group>
							<Form.Group controlId="encoder1">
								<Form.Label>{ appStrings.app.ClearCaster }&nbsp;*</Form.Label>
								<Form.Control ref={ this.encoder1 } as="select" value={ this.state.encoder1 } onChange={ this.encoderChange }>
									{ Object.keys(encodersByModel).map ((model, key) => {return (
										<optgroup key={key} label={this.props.strings.app['EncoderModel_'+model]}>
										{
											encodersByModel[model].map ((v, key2) => { return (
												<option key={ v.id } value={ v.id } disabled={this.checkEncoderDisabled(v)}>{ v.name + (v.isOnline?"" /*" ("+appStrings.app.StatusReady+")"*/:"") }</option>
											);})
										}
										</optgroup>
										);})
									}
								</Form.Control>
								<div className="dialog-multifield-spacer"></div>
								{ this.getEncoderCard() }
							</Form.Group>
							<Form.Group controlId="templateCategory">
								<Form.Label>{ appStrings.app.EncodingTemplateCategory }&nbsp;*</Form.Label>
								<Form.Control disabled={this.state.isEdit} ref={ this.templateCategory } as="select" value={ this.state.templateCategory } onChange={ this.templateCategoryChange }>
									{
										templateCategoryListCustom.length > 0 &&
										<optgroup label={ appStrings.app.CustomDestinations }>
											{ templateCategoryListCustom.map ((v, key) => { return (
												<option key={ v.id } value={ v.id } disabled={this.checkTemplateCategoryDisabled(v.id)}>{ v.name }</option>
											)})}
										</optgroup>
									}
									{
										templateCategoryListBuiltInGroups.map((builtInGroup,key) => { return(
											<optgroup key={key} label={builtInGroup.name}>
												{ builtInGroup.templateCategories.map ((category,key2) => { return (
													<option key={category.id} value={category.id} disabled={this.checkTemplateCategoryDisabled(category.id)}>{category.name}</option>
												)})}
											</optgroup>
										)})
									}
								</Form.Control>
							</Form.Group>
							<Form.Group controlId="template">
								<Form.Label>{ appStrings.app.EncodingTemplate }&nbsp;*</Form.Label>
								<Form.Control disabled={this.state.isEdit} ref={ this.template } as="select" value={ this.state.template } onChange={ this.templateChange }>
									{ templateList.map ((v, key) => {

										return (
										<option key={ v.id } value={ v.id }>{ v.name }</option>
									)})}
								</Form.Control>
							</Form.Group>
							{ this.renderTemplate(template) }
							<Form.Group controlId="template">
								<Form.Label>{ appStrings.app.TitlingAndGraphics }&nbsp;*</Form.Label>&nbsp;&nbsp;<i className="help-icon fa fa-question-circle" title={this.props.strings.app.TitlingAndGraphicsHelp} style={{color:'#428bca'}} />
								<Form.Control as="select" value={ this.state.overlayGraphicsVendor } onChange={evt => this.setState({ overlayGraphicsVendor: evt.target.value })}>
									<option key={ "NONE" } value={ "NONE" }>{ appStrings.app.VendorNone }</option>
									{ this.getBuiltInOverlays() }
									<option key={ "custom" } value={ "custom" }>{ appStrings.app.VendorCustom }</option>
								</Form.Control>
								{ this.state.overlayGraphicsVendor === "custom" &&
								<div>
									<div className="dialog-multifield-spacer"></div>
									<div className="dialog-multifield-spacer"></div>
									<InputGroup key={ "overlayGraphicsURL-group" } className="mb-3">
										<InputGroup.Prepend>
											<InputGroup.Text className="dialog-prepend-label">{ appStrings.app.GraphicsApplicationURL }</InputGroup.Text>
										</InputGroup.Prepend>
										<Form.Control key={ "overlayGraphicsURL" }value={ this.state.overlayGraphicsURL } onChange={evt => this.setState({ overlayGraphicsURL: evt.target.value })} />
									</InputGroup>
								</div>
								}
							</Form.Group>
							<Form.Group controlId="overlayGraphicsVendor">
								<div className="dialog-multifield-spacer"></div>
								<Row className="checkbox-container">
									<Col className="checkbox-container-checkbox" md="auto">
										<Form.Check key={ "engineeringView" } id="engineeringView" checked={ this.state.engineeringView } onChange={evt => this.setState({ engineeringView: evt.target.checked })} type="checkbox" label={ appStrings.app.EngineeringViewCheckbox } disabled={this.props.clearcaster.broadcastSharing.active}/>
									</Col>
									<Col className="checkbox-container-help">
										<i className="help-icon fa fa-question-circle" title={this.props.strings.app.EngineeringViewCheckboxHelp} style={{color:'#428bca'}} />
									</Col>
								</Row>
							</Form.Group>
						</Form>
						* Required
					</Modal.Body>

					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
						<Button variant="primary" onClick={this.createBroadcast}>{ this.state.isEdit?appStrings.app.SaveBroadcast:appStrings.app.CreateBroadcast }</Button>
					</Modal.Footer>
				</LoadingOverlay>
			</Modal>
			</div>
		);
	}
}

export default BroadcastCreateDialog;
