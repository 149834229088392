import React, { Component } from 'react';

import {Card, Button, Dropdown} from 'react-bootstrap'
import { formatFrameRate } from '../UIUtils';
import {isVersionGreaterThan} from "../../controller/ClearCasterAdminUtils";
import { checkFeature } from '../../controller/UniversalAdminUtils';

import ClearCasterStore, {ActionTypes} from '../../model/ClearCasterStore';
import Moment from "react-moment";
import {PulseLoader} from "react-spinners";

import EncoderStatusBadge from '../components/EncoderStatusBadge';

/*

Missing info:

* encoderStatus

memoryTotal
memoryAvailable
cpuUsage
temperature
MAC address
IPv4 address
IPv6 address

* captureSessionInfo

audioLevelPeakRight
audioLevelPeakLeft
audioLevelAvgRight
audioLevelAvgLeft

*/

class EncoderItem extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			downloadLogsOpen:false,
			downloading:[],
			localVersions:{}
		};
		this.forceUpdateSelect = React.createRef();
		this.getLocalVersions = this.getLocalVersions.bind(this);
		this.forceUpdate = this.forceUpdate.bind(this);
	}

	openDialogSettings(encoder,dialogType)
	{
		return (evt) => {
			console.log(dialogType);
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_ENCODER_SETTINGS,
				dialogShowing:true,
				dialogType:dialogType,
				encoder:encoder,
				encoderName:encoder.name,
				applyAssetPackage:false,
				assetPackageInfo:{
					encoderAssetsUrl:"",
					encoderAssetsVersion: null
				},
				updating:false,
				updateMessage:"",
				confirmRestart:false
			});
		};
	}

	openDialogUpdate(encoder)
	{
		return (evt) => {
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_UPDATE_ENCODER,
				dialogShowing:true,
				rollback:false,
				rollbackVersion:'',
				localVersions:{},
				encoder:encoder,
				updating:false
			});
		}
	}
	openDialogRollback(encoder,rollbackVersion,localVersions)
	{
		return (evt) => {
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_UPDATE_ENCODER,
				dialogShowing:true,
				rollback:true,
				rollbackVersion:rollbackVersion,
				localVersions:localVersions,
				encoder:encoder,
				updating:false
			});
		}
	}

	forceUpdate(encoder)
	{
		let _this = this;
		return (evt) => {
			let updateVersion = _this.forceUpdateSelect.current.options[_this.forceUpdateSelect.current.selectedIndex].value;
			console.log('updateVersion');
			console.log(updateVersion);
			if (updateVersion != null)
			{
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_UPDATE_ENCODER,
					dialogShowing:true,
					rollback:true,
					rollbackVersion:updateVersion,
					localVersions:{clearcaster:_this.props.clearcaster.versions.force_versions},
					encoder:encoder,
					updating:false
				});
			}
		}
	}

	getModel(encoder,softwareVersion)
	{
		let model = null;
		if (encoder.hasOwnProperty('modelInfo') && encoder.modelInfo != null)
		{
			if (encoder.modelInfo.model === "UNKNOWN")
			{
				if (softwareVersion != null && isVersionGreaterThan("2.2.0.0", softwareVersion))
				{
					model = this.props.strings.app['EncoderModel_CC_PRO'];
				}
			}
			else
			{
				model = this.props.strings.app['EncoderModel_'+encoder.modelInfo.model];
			}
		}
		return model;
	}

	getSerialNumber(encoder)
	{
		let clearCasterSerialNumber = null;

		if (encoder.hasOwnProperty("encoderStatus") && encoder.encoderStatus != null && encoder.encoderStatus.hasOwnProperty("clearCasterSerialNumber"))
		{
			clearCasterSerialNumber = encoder.encoderStatus.clearCasterSerialNumber
		}

		return clearCasterSerialNumber;
	}

	getLocalIpV4Address(encoder)
	{
		let localIpV4Address = null;

		if (encoder.hasOwnProperty("encoderStatus") && encoder.encoderStatus != null && encoder.encoderStatus.hasOwnProperty("localIpV4Address"))
		{
			localIpV4Address = encoder.encoderStatus.localIpV4Address
		}

		return localIpV4Address;
	}

	getSoftwareVersion(encoder)
	{
		let clearCasterSoftwareVersion = null;

		if (encoder.hasOwnProperty("encoderStatus") && encoder.encoderStatus != null && encoder.encoderStatus.hasOwnProperty("clearCasterSoftwareVersion"))
		{
			clearCasterSoftwareVersion = encoder.encoderStatus.clearCasterSoftwareVersion
		}

		return clearCasterSoftwareVersion;
	}

	getUpdateSoftwareVersion(encoder)
	{
		if (encoder.hasOwnProperty("encoderStatus") && encoder.encoderStatus != null && encoder.encoderStatus.hasOwnProperty("availableSoftwareUpdateVersion") && encoder.encoderStatus.availableSoftwareUpdateVersion != null)
		{
			let clearCasterSoftwareVersion = this.getSoftwareVersion(encoder);
			let availableSoftwareUpdateVersion = encoder.encoderStatus.availableSoftwareUpdateVersion;
			if (clearCasterSoftwareVersion != null)
			{
				if(isVersionGreaterThan(availableSoftwareUpdateVersion,clearCasterSoftwareVersion))
				{
					return availableSoftwareUpdateVersion;
				}
			}
		}
		return null;
	}

	getVideoSource(encoder)
	{
		let appStrings = this.props.strings;

		let videoSource = <div></div>;

		if (encoder.isOnline)
		{
			videoSource = <div><span className="panel-label">{appStrings.app.VideoSourceInput}:&nbsp;</span><i className="fa fa-exclamation-triangle fa-lg fa-fw icon-warning" aria-hidden="true"></i>&nbsp;{ appStrings.app.VideoSourceNotDetected }<br /></div>

			if (encoder.hasOwnProperty("captureSessionInfo") && encoder.captureSessionInfo != null)
			{
				if (encoder.captureSessionInfo.videoFrameCountModeSwitch > 0)
				{
					let elements;

					switch(encoder.captureSessionInfo.videoInput)
					{
					case 1:
						elements = [elements, (<div key="input"><span className="panel-label">{appStrings.app.VideoSourceInput}:&nbsp;</span>SDI<br /></div>)];
						break;
					case 2:
						elements = [elements, (<div key="input"><span className="panel-label">{appStrings.app.VideoSourceInput}:&nbsp;</span>HDMI<br /></div>)];
						break;
					default:
					}

					elements = [elements, (<div key="frameSize"><span className="panel-label">{appStrings.app.VideoSourceFrameSize}:&nbsp;</span>{ encoder.captureSessionInfo.videoFrameWidth + " x " + encoder.captureSessionInfo.videoFrameHeight }<br /></div>)];

					let frameRate = formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, 1);

					if (frameRate.length > 0)
						elements = [elements, (<div key="frameRate"><span className="panel-label">{appStrings.app.VideoSourceFrameRate}:&nbsp;</span>{ frameRate + " fps" }<br /></div>)];

					elements = [elements, (<div key="frameInterlaced"><span className="panel-label">{appStrings.app.VideoSourceInterlaced}:&nbsp;</span>{ encoder.captureSessionInfo.videoProgressive?appStrings.app.NoTitle:appStrings.app.YesTitle }<br /></div>)];

					elements = [elements, (<div key="cc"><span className="panel-label">{appStrings.app.VideoSourceCaptions}:&nbsp;</span>{ (encoder.captureSessionInfo.videoVANCCEA708PacketsModeSwitch > 0 || encoder.captureSessionInfo.videoLine21CEA708PacketsModeSwitch > 0)?appStrings.app.YesTitle:appStrings.app.NoTitle }<br /></div>)];

					videoSource = <div>{ elements }</div>;
				}
			}
		}

		return videoSource;
	}

	getActiveBroadcast(encoder)
	{
		let appStrings = this.props.strings;

		let activeBroadcast = <div></div>;

		if (encoder.hasOwnProperty("activeBroadcast") && encoder.activeBroadcast != null && encoder.activeBroadcast.id != null)
		{
			let broacastId = encoder.activeBroadcast.id;

			if (this.props.clearcaster.broadcastMap.hasOwnProperty(broacastId))
			{
				let broadcast = this.props.clearcaster.broadcastMap[broacastId];

				activeBroadcast = <div key="input"><span className="panel-label">{appStrings.app.Broadcast}:&nbsp;</span>{ broadcast.name }<br /></div>;
			}
		}

		return activeBroadcast;
	}

	async getLocalVersions(encoder)
	{
		let response;
		let response2;
		console.log('getting localVersions for ' + encoder.id);
		try
		{
			response = await this.props.controller.getLocalVersions(encoder.id);
			if (response.data != null && response.data.sendEncoderCommand != null && response.data.sendEncoderCommand.correlationId != null)
			{
				response2 = await this.props.controller.getEncoderCommandStatus(response.data.sendEncoderCommand.correlationId);
				if (response2['data'] && response2['data']['encoderCommandStatus'] && response2['data']['encoderCommandStatus'].commandResponse != null)
				{
					let commandResponse = JSON.parse(response2['data']['encoderCommandStatus'].commandResponse);
					if (commandResponse.localVersionsBase64 != null)
					{
						let localVersionsObj = JSON.parse(atob(commandResponse.localVersionsBase64));
						this.setState({localVersions:localVersionsObj});
						console.log('localVersionsObj');
						console.log(localVersionsObj);
					}
				}
			}
			else
			{
				console.log('ERROR getting local versions');
				console.log(response);
			}
		}
		catch (e)
		{
			console.log('ERROR getting local versions');
			console.log(response);
			console.log(response2);
			console.log(e);
		}
	}

	downloadLog(logRef)
	{
		return async (evt) => {
			let asset_url = logRef.url;
			let logsDownloading = this.props.clearcaster.logsDownloading.downloading;
			if (logsDownloading.indexOf(asset_url) === -1)
			{
				let newDownloading = [...logsDownloading];
				newDownloading.push(asset_url);
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_LOGS_DOWNLOADING,
					downloading:newDownloading
				});
			}
			let auth_header = this.props.controller.graphQLConnection.getAuthHeader();
			let response = await fetch(asset_url, {
				method: 'GET',
				headers: auth_header
			});
			if (response.status === 200)
			{
				let blob = await response.blob();
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement('a');
				a.href = url;
				a.download = asset_url.substring(asset_url.lastIndexOf('/')+1);
				document.body.appendChild(a);
				a.click();
				a.remove();
			}
			else
			{
				console.log('ERROR downloading ' + asset_url);
				console.log(response);
				let logErrors = this.props.clearcaster.logsDownloading.errors;
				let newLogErrors = [...logErrors];
				if (newLogErrors.indexOf(asset_url) === -1)
				{
					newLogErrors.push(asset_url);
					ClearCasterStore.dispatch({
						type:ActionTypes.SET_LOGS_DOWNLOADING,
						errors:newLogErrors
					});
				}
			}

			logsDownloading = this.props.clearcaster.logsDownloading.downloading;
			if (logsDownloading.indexOf(asset_url) >= 0)
			{
				let newDownloading = [...logsDownloading];
				newDownloading.splice(newDownloading.indexOf(asset_url),1);
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_LOGS_DOWNLOADING,
					downloading:newDownloading
				});
			}
		};
	}

	componentDidMount()
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_LOGS_DOWNLOADING,
			errors:[]
		});
		if (this.props.rbui)
		{
			this.getLocalVersions(this.props.encoder);
		}
	}


	render()
    {
		let appStrings = this.props.strings;
		let encoder = this.props.encoder;
		let serialNumber = this.getSerialNumber(encoder);
		let localIpV4Address = this.getLocalIpV4Address(encoder);
		let softwareVersion = this.getSoftwareVersion(encoder);
		let model = this.getModel(encoder,softwareVersion);
		let updateSoftwareVersion = this.getUpdateSoftwareVersion(encoder);
		let idPrefix = encoder.id + '-';

		let logFiles = undefined;
		if (encoder['logFiles'] != null && encoder['logFiles'].length > 0)
		{
			logFiles = [...encoder['logFiles']];
			logFiles.sort(function (a, b) {
				let aCreated = Date.parse(a.createdAt);
				let bCreated = Date.parse(b.createdAt);
				return bCreated - aCreated;
			});
		}

		let rollbackVersion = '';
		if (this.props.rbui)
		{
			if (this.state.localVersions.clearcaster != null && this.state.localVersions.clearcaster.length > 0)
			{
				for (let i = 0; i < this.state.localVersions.clearcaster.length; i++)
				{
					if(isVersionGreaterThan(softwareVersion,this.state.localVersions.clearcaster[i]['v']))
					{
						rollbackVersion = this.state.localVersions.clearcaster[i]['v'];
						break;
					}
				}
			}
		}

		return (
			<div className="encoder-item">
				<Card className="shadow-sm">
					<Card.Header>
						<div className="testHeader" style={{display:'inline-block'}}>{ encoder.name }</div>
							<div className="pull-right" style={{margin:'-.2em'}}>
								<Dropdown alignRight>
									<Dropdown.Toggle as={Button} variant="outline-secondary" size="sm">
										<i className="fa fa-gear"></i>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item className="dropdown-link" onClick={this.openDialogSettings(encoder,'rename')}>Rename</Dropdown.Item>
										{ encoder.isOnline &&
											<>
												<Dropdown.Item className="dropdown-link" onClick={this.openDialogSettings(encoder,'sendlogs')}>Send Logs</Dropdown.Item>
												<Dropdown.Item className="dropdown-link" onClick={this.openDialogSettings(encoder,'restart')}>Restart</Dropdown.Item>
												{ checkFeature("slates",softwareVersion) && this.props.clearcaster.namespaceAssetPackageData != null && this.props.clearcaster.namespaceAssetPackageData.enabled != null && this.props.clearcaster.namespaceAssetPackageData.enabled &&
													<Dropdown.Item className="dropdown-link" onClick={this.openDialogSettings(encoder,'applyassetpackage')}>Apply Asset Package</Dropdown.Item>
												}
											</>
										}
										{this.props.userIsAdmin &&
											<Dropdown.Item className="dropdown-link" onClick={this.openDialogSettings(encoder,'delete')}>Delete</Dropdown.Item>
										}										
									</Dropdown.Menu>
								</Dropdown>

							</div>
					</Card.Header>
					<Card.Body>
						<div className="row">
							<div className="col-sm-6">
								<div className="panel-valuepair">
									<div><span className="panel-label">{appStrings.app.Status}:&nbsp;</span><EncoderStatusBadge encoder={encoder} showSubscriptionExpired={true} controller={this.props.controller} strings={appStrings} /><br /></div>
									{	model != null && <div><span className="panel-label">{appStrings.app.EncoderModel}:&nbsp;</span>{ model }<br /></div> }
									{	serialNumber != null && <div><span className="panel-label">{appStrings.app.SerialNumber}:&nbsp;</span>{ serialNumber }<br /></div> }
									{	softwareVersion != null && <div><span className="panel-label">{appStrings.app.SoftwareVersion}:&nbsp;</span>{ softwareVersion }<br /></div> }
									{	localIpV4Address != null && <div><span className="panel-label">{appStrings.app.LocalIpV4Address}:&nbsp;</span>{ localIpV4Address }<br /></div> }
									{ this.getActiveBroadcast(encoder) }
									{ encoder.isOnline && updateSoftwareVersion != null &&
										<div>
											<strong>
												<button id={ idPrefix+'update-link' } className="text-primary btn btn-link" style={{cursor:'pointer'}} onClick={ this.openDialogUpdate(encoder) }>
													<i className="fa fa-arrow-circle-up fa-lg fa-fw" aria-hidden="true"></i> {this.props.strings.app.UpdateAvailable}
												</button>
											</strong>
										</div>
									}
									{ encoder.isOnline && encoder.broadcastStatus.indexOf(appStrings.app.streamStatusReady) === 0 && this.props.rbui &&
										<>
											{ rollbackVersion !== '' &&
												<div>
													<button href="" id={ idPrefix+'rollback-link' } className="text-primary btn btn-link" style={{cursor:'pointer'}} onClick={ this.openDialogRollback(encoder,rollbackVersion,this.state.localVersions) }>
													<i className="fa fa-undo fa-lg fa-fw" aria-hidden="true"></i> Rollback to {rollbackVersion}
													</button>
												</div>
											}
											{ this.props.rbuiForce && this.props.clearcaster.versions != null && this.props.clearcaster.versions.force_versions != null && this.props.clearcaster.versions.force_versions.length > 0 &&
												<div className="mt-2">
													Force update to:
													<select ref={this.forceUpdateSelect}>
														<option value="">Choose a version</option>
														{ this.props.clearcaster.versions.force_versions.map ((v,key) => { return (
															<option value={ v.v } key={ key }>{ v.v }</option>
														)})}
													</select>&nbsp;
													<button onClick={ this.forceUpdate(encoder) }>Go</button>
												</div>
											}
										</>
									}
								</div>
							</div>
							<div className="col-sm-6">
							{ this.getVideoSource(encoder) }
							</div>
						</div>
						{ logFiles != null &&
							<>
								<div className="row">
									<div className="col">
										<button id={idPrefix+'toggleDownloadLogs'} className="text-primary btn btn-link" style={{cursor:'pointer'}} onClick={()=>{this.setState({downloadLogsOpen:!this.state.downloadLogsOpen})}}>
											<strong><i className={this.state.downloadLogsOpen ? "fa fa-fw fa-caret-down" : "fa fa-fw fa-caret-right"}></i> Download Logs</strong>
										</button>
									</div>
								</div>
								{ this.state.downloadLogsOpen &&
								<table className="mt-2 table table-striped">
									<tbody>
									{ logFiles.map( (l,key) =>
										<tr key={key}>
											<td>
												<Moment format="YYYY-MM-DD" date={l.createdAt} /> @ <Moment format="h:mma" date={l.createdAt} />
											</td>
											<td>
												{ l.url.indexOf("event") >= 0 ?
													<span>Event Log</span>
												: l.url.indexOf("support") >= 0 ?
													<span>Support Log</span>
												: <span></span>
												}
											</td>
											<td>
												{l.id}
											</td>
											<td className="text-right">
												{ this.props.clearcaster.logsDownloading.errors.indexOf(l.url) >= 0 ?
													<Button disabled={true} id={idPrefix+"_download_"+key} variant="outline-danger" size="sm" style={{width:'150px'}}>
														Error Downloading
													</Button>
													: this.props.clearcaster.logsDownloading.downloading.indexOf(l.url) >= 0 ?
													<Button disabled={true} id={idPrefix+"_download_"+key} variant="outline-secondary" size="sm" style={{width:'150px'}}>
														Downloading <div style={{display:'inline-block'}}><PulseLoader sizeUnit="px" size="5"></PulseLoader></div>
													</Button>
												:
													<Button id={idPrefix+"_download_"+key} size="sm" style={{width:'150px'}}
																	url={l.url} onClick={this.downloadLog(l)} target="_blank" download>Download</Button>
												}
											</td>
										</tr>
									)}
									</tbody>
								</table>
								}
							</>
						}
					</Card.Body>
				</Card>
				<br/>
			</div>
		);
	}
};

export default EncoderItem;
