import React, { Component } from 'react';
import { Button, Form, InputGroup, Dropdown } from 'react-bootstrap';
import SingularLiveGraphicsController from '../../../controller/SingularLiveGraphicsController';
import ManageGraphicsSlate from './graphics/ManageGraphicsSlate';
import { LinkContainer } from 'react-router-bootstrap';
import ManageGraphicsLogo from './graphics/ManageGraphicsLogo';
import ManageGraphicsLowerThird from './graphics/ManageGraphicsLowerThird';
import { PulseLoader } from 'react-spinners';
import Toggle from '../../shell/Toggle';

class ManageGraphics extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			appInstanceNameDirty:undefined,
			appInstanceName:''
		};
		this.editSubComposition = this.editSubComposition.bind(this);
		this.toggleGraphicEnabled = this.toggleGraphicEnabled.bind(this);
		this.changePackageName = this.changePackageName.bind(this);
		this.enableGraphics = this.enableGraphics.bind(this);
		this.renderGraphicToggle = this.renderGraphicToggle.bind(this);
		this.renderEnableGraphics = this.renderEnableGraphics.bind(this);
	}

	editSubComposition(subComposition)
	{
		const _this = this;
		return () => {
			let singularLiveData = _this.props.clearcaster.singularLiveData;
			let pageState = SingularLiveGraphicsController.getData(singularLiveData,"manage");
			pageState.editing = subComposition;
			SingularLiveGraphicsController.saveData(singularLiveData,"manage",pageState);
		}
	}

	componentDidMount()
	{
		let singularLiveData = this.props.clearcaster.singularLiveData;
		let pageState = SingularLiveGraphicsController.getData(singularLiveData,"manage");
		pageState.forceRefresh = true;
		SingularLiveGraphicsController.saveData(singularLiveData,"manage",pageState);
		this.setState({appInstanceNameDirty:undefined});
	}

	async componentDidUpdate(prevProps)
	{
		let singularLiveData = this.props.clearcaster.singularLiveData;
		let pageState = SingularLiveGraphicsController.getData(singularLiveData,"manage");
		if (prevProps.graphicsid != null &&
			(prevProps.graphicsid.indexOf(this.props.graphicsid) !== 0 || pageState.forceRefresh) &&
			!pageState.refreshing)
		{
			let appInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,this.props.graphicsid);
			if (appInstance != null)
			{
				let previewAppInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,appInstance.preview);
				if (previewAppInstance != null)
				{
					let compositionData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
					if (compositionData == null)
					{
						compositionData = SingularLiveGraphicsController.getDefaultData();
					}
					SingularLiveGraphicsController.saveData(singularLiveData,previewAppInstance.id,compositionData);
					pageState.forceRefresh = false;
					pageState.refreshing = false;
					SingularLiveGraphicsController.saveData(singularLiveData,"manage",pageState);
					if (this.state.appInstanceNameDirty === undefined)
					{
						this.setState({
							appInstanceNameDirty:false,
							appInstanceName:SingularLiveGraphicsController.getName(singularLiveData,appInstance)
						});
					}
				}
			}
		}
	}

	toggleGraphicEnabled(singularLiveData,appInstance,graphic)
	{
		const _this = this;
		return async (evt) => {
			const appInstanceData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
			let newData = JSON.parse(JSON.stringify(appInstanceData));
			newData[graphic].enabled = !newData[graphic].enabled;
			SingularLiveGraphicsController.saveData(singularLiveData,appInstance.id,newData);
			await SingularLiveGraphicsController.persistData(_this.props.controller,appInstance.id,newData);
		};
	}

	changePackageName(singularLiveData,appInstance,name)
	{
		const _this = this;
		return async (evt) => {
			const appInstanceData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
			let newData = JSON.parse(JSON.stringify(appInstanceData));
			newData.name = name;
			SingularLiveGraphicsController.saveData(singularLiveData,appInstance.id,newData);
			_this.setState({appInstanceNameDirty:undefined});
			await SingularLiveGraphicsController.persistData(_this.props.controller,appInstance.id,newData);
		}
	}

	async enableGraphics(evt)
	{
		let pageState = SingularLiveGraphicsController.getData(this.props.clearcaster.singularLiveData,"manage");
		pageState.creating = true;
		SingularLiveGraphicsController.saveData(this.props.clearcaster.singularLiveData,"manage",pageState);
		await this.props.controller.enableGraphics();
		await this.props.controller.loadNamespaceExtraProperties();
		pageState = SingularLiveGraphicsController.getData(this.props.clearcaster.singularLiveData,"manage");
		pageState.creating = false;
		SingularLiveGraphicsController.saveData(this.props.clearcaster.singularLiveData,"manage",pageState);
	}

	renderGraphicToggle(singularLiveData,appInstance,graphic)
	{
		const appInstanceData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
		return (
			<Toggle on={appInstanceData[graphic].enabled} size="2x" onClick={ this.toggleGraphicEnabled(singularLiveData,appInstance,graphic) } />
		);
	}

	renderEnableGraphics()
	{
		let pageState = SingularLiveGraphicsController.getData(this.props.clearcaster.singularLiveData,"manage");

		return (
			<>
				<div className="row mb-2">
					<div className="col-md-4 order-md-8 text-right text-muted">
						<span className="mr-1">Powered by</span><a className="m-0 p-0 border-0" target="_blank" rel="noopener noreferrer" href="https://singular.live"><img alt="" src="/images/singular_live_logo_FULLcolor.png" style={{width:'125px'}} /></a>
					</div>
					<div className="col-md-8">
						<div className="integration-heading">{this.props.strings.app.ManageGraphicsEnableGraphics}</div>
						<div className="mb-2">
							{this.props.strings.app.ManageGraphicsEnableGraphicsPrompt}
						</div>
						{pageState.creating &&
							<button id={'EnableGraphics1'} style={{width:'175px'}} className="btn btn-outline-secondary active mt-2" disabled>
								<PulseLoader sizeUnit="px" size="5"/>
							</button>
						}
						{!pageState.creating &&
							<button id={'EnableGraphics1'} style={{width:'175px'}} className="btn btn-primary active mt-2" onClick={ this.enableGraphics }>{this.props.strings.app.ManageGraphicsEnableGraphics}</button>
						}
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-lg-4 col-sm-4 mb-4">
						<img alt="" src="/images/sample-slate-328x184.png" style={{width:'100%'}} />
					</div>
					<div className="col-lg-4 col-sm-4 mb-4">
						<img alt="" src="/images/sample-lower-third-328x184.png" style={{width:'100%'}} />
					</div>
					<div className="col-lg-4 col-sm-4 mb-4">
						<img alt="" src="/images/sample-logo-mark-328x184.png" style={{width:'100%'}} />
					</div>
				</div>
				</>
		)
	}

	render()
	{
		let singularLiveData = this.props.clearcaster.singularLiveData;
		if (!singularLiveData.loaded)
		{
			return(null);
		}
		if (singularLiveData.appInstances.length < 1 ||  singularLiveData.appInstances.order == null)
		{
			return this.renderEnableGraphics();
		}

		let graphicsid = this.props.graphicsid == null ? "0" : this.props.graphicsid;
		let appInstance = null;
		let appInstanceData = null;
		let appInstanceName = '';
		if (this.props.graphicsid != null)
		{
			graphicsid = this.props.graphicsid;
			appInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,graphicsid);
			appInstanceData = SingularLiveGraphicsController.getData(singularLiveData,appInstance.id);
		}

		let previewAppInstance = null;
		if (appInstance != null)
		{
			previewAppInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,appInstance.preview);
			if (previewAppInstance == null)
			{
				return (<div>Coming soon...</div>);
			}
			appInstanceName = SingularLiveGraphicsController.getName(singularLiveData,appInstance);
		}
		let pageState = SingularLiveGraphicsController.getData(singularLiveData,"manage");
		if (pageState == null)
		{
			pageState = {
				editing:'',
				saving:false,
				creating:false,
				forceRefresh:false
			};
		}

		return (
			<>
				{ (pageState.editing === "slate1" || pageState.editing === 'slate2') &&
					<ManageGraphicsSlate
						appInstance={appInstance}
						graphic={pageState.editing}
						controller={this.props.controller}
						clearcaster={this.props.clearcaster}
						strings={this.props.strings} />
				}
				{ (pageState.editing === "lower3rd") &&
				<ManageGraphicsLowerThird
					appInstance={appInstance}
					graphic={pageState.editing}
					controller={this.props.controller}
					clearcaster={this.props.clearcaster}
					strings={this.props.strings} />
				}
				{ (pageState.editing === "logo") &&
				<ManageGraphicsLogo
					appInstance={appInstance}
					graphic={pageState.editing}
					controller={this.props.controller}
					clearcaster={this.props.clearcaster}
					strings={this.props.strings} />
				}

				<div className="row mb-2">
					<div className="col-md-4 order-md-8 text-right text-muted">
						<span className="mr-1">Powered by</span><a className="m-0 p-0 border-0" target="_blank" rel="noopener noreferrer" href="https://singular.live"><img alt="" src="/images/singular_live_logo_FULLcolor.png" style={{width:'125px'}} /></a>
					</div>
					<div className="col-md-8 order-md-0">
						<div className="integration-heading">{this.props.strings.app.ManageGraphicsSelectPackage}</div>

						<Dropdown>
							<Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
								{ appInstance == null ?
									<span>{this.props.strings.app.ManageGraphicsSelectPackage}</span>
									:
									<span>{SingularLiveGraphicsController.getName(singularLiveData,appInstance)}</span>
								}
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{singularLiveData.appInstances.order.map((v, key) =>
								{
									let appInstance = SingularLiveGraphicsController.getAppInstanceById(singularLiveData,v);
									let name = SingularLiveGraphicsController.getName(singularLiveData,appInstance);
									return (
										<Dropdown.Item as={LinkContainer} key={key} to={'/manage/graphics/' + appInstance.id}>
											<span>{name}</span>
										</Dropdown.Item>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				{ appInstance != null &&
					<>
						<div className="row mt-4">
							<div className="col-12">
								<div className="integration-heading">{SingularLiveGraphicsController.getName(singularLiveData,appInstance)}</div>
								<div className="d-inline-block mr-2">
									{this.props.strings.app.ManageGraphicsRename}
								</div>
								<div className="d-inline-block">
									<InputGroup>
										<Form.Control type="text"
											value={this.state.appInstanceName}
											onChange={evt => { this.setState({
												appInstanceName:evt.target.value,
												appInstanceNameDirty:!(evt.target.value === appInstanceName)
											})}}
										>
										</Form.Control>
										{ this.state.appInstanceNameDirty &&
											<InputGroup.Append>
												<Button variant="outline-secondary" onClick={this.changePackageName(singularLiveData,appInstance,this.state.appInstanceName)}><i className="fa fa-check"></i></Button>
												<Button variant="outline-secondary" onClick={evt => {
													this.setState({
														appInstanceName:appInstanceName,
														appInstanceNameDirty:false
													});
												}
												}><i className="fa fa-times"></i></Button>
											</InputGroup.Append>
										}
									</InputGroup>
								</div>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-lg-3 col-sm-6 mb-4">
								<div className="mb-2">
									<span className="integration-heading">{this.props.strings.app.ManageGraphicsTitle_slate1}</span>
									<span className="float-right">
									{ this.renderGraphicToggle(singularLiveData,appInstance,'slate1')}
									</span>
								</div>
								<div className="mb-2">
									<img alt="" src="/images/sample-slate-328x184.png" style={{width:'100%'}} />
								</div>
								<Button className="w-100" variant={appInstanceData['slate1'].enabled?"primary":"outline-secondary"} disabled={ !appInstanceData['slate1'].enabled } onClick={this.editSubComposition('slate1')}><i className="fa fa-pencil mr-2"></i>{this.props.strings.app.ManageGraphicsEditSlate1}</Button>
							</div>
							<div className="col-lg-3 col-sm-6 mb-4">
								<div className="mb-2">
									<span className="integration-heading">{this.props.strings.app.ManageGraphicsTitle_slate2}</span>
									<span className="float-right">
									{ this.renderGraphicToggle(singularLiveData,appInstance,'slate2')}
									</span>
								</div>
								<div className="mb-2">
									<img alt="" src="/images/sample-slate-328x184.png" style={{width:'100%'}} />
								</div>
								<Button className="w-100" variant={appInstanceData['slate2'].enabled?"primary":"outline-secondary"} disabled={ !appInstanceData['slate2'].enabled } onClick={this.editSubComposition('slate2')}><i className="fa fa-pencil mr-2"></i>{this.props.strings.app.ManageGraphicsEditSlate2}</Button>
							</div>
							<div className="col-lg-3 col-sm-6 mb-4">
								<div className="mb-2">
									<span className="integration-heading">{this.props.strings.app.ManageGraphicsTitle_lower3rd}</span>
									<span className="float-right">
									{ this.renderGraphicToggle(singularLiveData,appInstance,'lower3rd')}
									</span>
								</div>
								<div className="mb-2">
									<img alt="" src="/images/sample-lower-third-328x184.png" style={{width:'100%'}} />
								</div>
								<Button className="w-100" variant={appInstanceData['lower3rd'].enabled?"primary":"outline-secondary"} disabled={ !appInstanceData['lower3rd'].enabled } onClick={this.editSubComposition('lower3rd')}><i className="fa fa-pencil mr-2"></i>{this.props.strings.app.ManageGraphicsEditLowerThird}</Button>
							</div>
							<div className="col-lg-3 col-sm-6 mb-4">
								<div className="mb-2">
									<span className="integration-heading">{this.props.strings.app.ManageGraphicsTitle_logo}</span>
									<span className="float-right">
									{ this.renderGraphicToggle(singularLiveData,appInstance,'logo')}
									</span>
								</div>
								<div className="mb-2">
									<img alt="" src="/images/sample-logo-mark-328x184.png" style={{width:'100%'}} />
								</div>
								<Button className="w-100" variant={appInstanceData['logo'].enabled?"primary":"outline-secondary"} disabled={ !appInstanceData['logo'].enabled } onClick={this.editSubComposition('logo')}><i className="fa fa-pencil mr-2"></i>{this.props.strings.app.ManageGraphicsEditLogo}</Button>
							</div>
						</div>
					</>
				}
			</>
		);
	}
}

export default ManageGraphics;
