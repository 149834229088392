import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import NearRealTimeDataCache from '../../../model/NearRealTimeDataCache';

class BroadcastItemArchiveApplianceChart extends Component
{
	static getDataSetTemplate(targetName,variant)
	{
		const colors = ['#0081BC','#63C53D','#FF8400','#7DC8FE'];
		let color;
		color = colors[variant];
		let dataSet = {
			label: targetName,
			yAxisID: (variant === 2 ? '1' : '0'),
			fill: false,
			lineTension: 0.1,
			backgroundColor: color,
			borderColor: color,
			borderCapStyle: 'butt',
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			borderWidth: 2,
			pointBorderColor: color,
			pointBackgroundColor: color,
			pointBorderWidth: 1,
			pointHoverRadius: 2,
			pointHoverBackgroundColor: color,
			pointHoverBorderColor: color,
			pointHoverBorderWidth: 2,
			pointRadius: 0.5,
			pointHitRadius: 10,
			data:[]
		};
		if (variant !== 1)
		{
			dataSet.type = 'line';
		}
		else
		{
			dataSet.type = 'bar';
		}
		return dataSet;
	}
	render()
	{
		let dataSets = [];
		let broadcastData = NearRealTimeDataCache.applianceChartAdapter(this.props.broadcastArchive.data);

		let dataSetCPUTemp = BroadcastItemArchiveApplianceChart.getDataSetTemplate("CPU Temp",0);
		let dataSetCPULoad = BroadcastItemArchiveApplianceChart.getDataSetTemplate("CPU Load",1);
		let dataSetThrottles = BroadcastItemArchiveApplianceChart.getDataSetTemplate("Throttle Events",2);
		let dataSetCaptureCardTemp = BroadcastItemArchiveApplianceChart.getDataSetTemplate("Capture Card Temp",3);
		let dataCPUTemp = [];
		let dataCPULoad = [];
		let dataThrottles = [];
		let dataCaptureCardTemp = [];
		let hasCaptureCardTempData = false;
		for (let i = 0; i < broadcastData.appliance.length; i++)
		{
			let dataPoint = broadcastData.appliance[i];
			dataCPUTemp.push({x:dataPoint.eventTime,y:dataPoint.cpuTemp});
			dataCPULoad.push({x:dataPoint.eventTime,y:dataPoint.cpuLoad});
			dataThrottles.push({x:dataPoint.eventTime,y:(dataPoint.coreThrottleCount + dataPoint.packageThrottleCount)});
			if (dataPoint.captureCardTemp != null && dataPoint.captureCardTemp > 0)
			{
				hasCaptureCardTempData = true;
				dataCaptureCardTemp.push({x:dataPoint.eventTime,y:dataPoint.captureCardTemp});
			}
		}
		dataSetCPUTemp['data'] = dataCPUTemp;
		dataSetCPULoad['data'] = dataCPULoad;
		dataSetThrottles['data'] = dataThrottles;
		dataSetCaptureCardTemp['data'] = dataCaptureCardTemp;

		dataSets.push(dataSetCPUTemp);
		dataSets.push(dataSetThrottles);
		dataSets.push(dataSetCPULoad);
		dataSets.push(dataSetCaptureCardTemp);

		let windowStartTime = this.props.broadcastArchive.startDateTime;

		let chartLegendClassName = hasCaptureCardTempData ? "col-sm-3 chart-legend-label" : "col-sm-4 chart-legend-label";

		return (
			<Card bg="transparent" border="0">
				<Card.Header className="pl-0 pb-1 border-0 bg-white">
					{this.props.strings.app.MonitoringCardTitleCPUGraph}
				</Card.Header>
				<Card.Body className="bg-white p-0">
					<div>
						<Bar
							height={200}
							options={{
								maintainAspectRatio:false,
								animation:{
									duration:0
								},
								layout:{
									padding:{
										left:0,
										top:0,
										right:0,
										bottom:0
									}
								},
								scales: {
									xAxes: [{
										type: 'time',
										position: 'bottom',
										time:{
											min:windowStartTime,
											tooltipFormat:'h:mm:ss a'
										},
										ticks:{
											minRotation:0,
											maxRotation:0
										}
									}],
									yAxes: [{
										id:'0',
										type: 'linear',
										position: 'left',
										ticks:{
											min:0,
											suggestedMax:100
										}
									},{
										id:'1',
										type: 'linear',
										position: 'right',
										ticks:{
											min:0,
											suggestedMax:2,
											precision:0
										}
									}]
								}
							}}
							legend={{
								display: false
							}}
							data={{
								datasets: dataSets
							}}
						/>
					</div>
					<div className="row bg-light m-1 p-1">
						<div className={chartLegendClassName}>
							<div className="chart-legend-box" style={{
								borderStyle: (dataSets[0].borderDash.length > 0 ? "dashed" : "solid"),
								borderTopColor: dataSets[0].borderColor
							}}></div>
							{dataSets[0].label}
						</div>
						{ hasCaptureCardTempData &&
						<div className={chartLegendClassName}>
							<div className="chart-legend-box" style={{
								borderStyle: (dataSets[3].borderDash.length > 0 ? "dashed" : "solid"),
								borderTopColor: dataSets[3].borderColor
							}}></div>
							{dataSets[3].label}
						</div>
						}
						<div className={chartLegendClassName}>
							<div className="chart-legend-box" style={{
								borderStyle: (dataSets[2].borderDash.length > 0 ? "dashed" : "solid"),
								borderTopColor: dataSets[2].borderColor
							}}></div>
							{dataSets[2].label}
						</div>
						<div className={chartLegendClassName}>
							<div className="chart-legend-box" style={{
								borderStyle: (dataSets[1].borderDash.length > 0 ? "dashed" : "solid"),
								borderTopColor: dataSets[1].borderColor
							}}></div>
							{dataSets[1].label}
						</div>
					</div>
				</Card.Body>
			</Card>
		);
	}
}
export default BroadcastItemArchiveApplianceChart;