import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';

class GoLiveConfirmationDialog extends Component
{
	constructor() {
		
		super();

		this.handleClose = this.handleClose.bind(this);
		this.handleGoLive = this.handleGoLive.bind(this);
	}

	onShow()
	{

	}

    handleClose()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_GOLIVECONFIRMATION_DIALOG,
		});
	}

    handleGoLive()
	{
		let goLiveData = this.props.clearcaster.goLiveConfirmationDialog;
		let _this = this;

		this.props.controller.setBroadcastChanging(goLiveData.activeBroadcast.id,true);
		this.props.controller.setBroadcastChanging(goLiveData.goLiveBroadcast.id,true);
		this.props.controller.endBroadcast(goLiveData.activeBroadcast.id, false).then((data) => {

			this.props.controller.goLiveBroadcast(goLiveData.goLiveBroadcast.id).then((data) => {
				_this.props.controller.forcePolling();
			});
		});

		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_GOLIVECONFIRMATION_DIALOG,
		});
	}


	render()
    {
		let appStrings = this.props.strings;

		let titleStr = this.props.strings.app.GoLive;
		let bodyStr = "";

		if (this.props.clearcaster.goLiveConfirmationDialog !== undefined)
		{
			titleStr = <this.props.strings.app.GoLiveConfirmTitle
				goLiveBroadcastName={this.props.clearcaster.goLiveConfirmationDialog.goLiveBroadcastName} />;
			bodyStr = <this.props.strings.app.GoLiveConfirm
				activeBroadcastName={this.props.clearcaster.goLiveConfirmationDialog.activeBroadcastName} />;
		}

        return (
			<Modal show={ this.props.clearcaster.goLiveConfirmationDialog !== undefined } onShow={ this.onShow } onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{ titleStr }</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{ bodyStr }
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>{ appStrings.app.Cancel }</Button>
					<Button variant="primary" onClick={this.handleGoLive}>{ appStrings.app.GoLive }</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default GoLiveConfirmationDialog;
