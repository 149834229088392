import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { checkGraphQLFeature } from '../../../controller/ClearCasterAdminUtils';
import NearRealTimeDataCache from '../../../model/NearRealTimeDataCache';
import HelpTooltip from '../../shell/HelpTooltip';

class BroadcastItemMonitorBitrateChart extends Component
{
	static getDataSetTemplate(outputIndex,streamName,variant)
	{
		const colors = ['#0081BC','#61388F','#63C53D','#7DC8FE','#FFBC2A','#FF8400'];
		// 50% tint values per https://maketintsandshades.com
		const colorsV2 = ['#80C0DE','#B09CC7','#B1E29E','#BEE4FF','#FFDE95','#FFC280'];
		let color;
		if (variant === 2) // Target
		{
			color = colorsV2[outputIndex%colors.length];
		}
		else // Actual and Available
		{
			color = colors[outputIndex%colors.length];
		}
		let dataSet = {
			label: streamName,
			fill: false,
			lineTension: 0.1,
			backgroundColor: color,
			borderColor: color,
			borderCapStyle: 'butt',
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			borderWidth: 2,
			pointBorderColor: color,
			pointBackgroundColor: color,
			pointBorderWidth: 1,
			pointHoverRadius: 2,
			pointHoverBackgroundColor: color,
			pointHoverBorderColor: color,
			pointHoverBorderWidth: 2,
			pointRadius: 0.5,
			pointHitRadius: 10,
			data:[]
		};
		if (variant === 1) // Available
		{
			dataSet.borderDash = [2,4];
			dataSet.borderWidth = 3;
		}
		return dataSet;
	}

	render()
	{
		let broadcastUI = this.props.broadcastUI;

		if (!checkGraphQLFeature("monitoringGraphs",this.props.clearcaster.graphQLBuild) ||
			broadcastUI.broadcastData === undefined ||
			broadcastUI.broadcastData === null)
		{
			return (
				<Card bg="transparent" border="0">
					<Card.Header className="pl-0 pb-1 border-0 bg-white">
						{this.props.strings.app.MonitoringCardTitleAdaptive}
					</Card.Header>
					<Card.Body className="bg-white p-0">
						<i>{this.props.strings.app.ComingSoon}</i>
					</Card.Body>
				</Card>
			);
		}

		let dataSets = [];
		let broadcastData = NearRealTimeDataCache.bitrateChartAdapter(broadcastUI.broadcastData);

		for (let outputIndex = 0; outputIndex < broadcastData.outputs.length; outputIndex++)
		{
			let output = broadcastData.outputs[outputIndex];
			let bOutput = this.props.broadcast.outputs[outputIndex];
			let dataSetActual = BroadcastItemMonitorBitrateChart.getDataSetTemplate(outputIndex,bOutput['streamName']+' Actual',0);
			let dataSetAvailable = BroadcastItemMonitorBitrateChart.getDataSetTemplate(outputIndex,bOutput['streamName']+' Available',2);
			let dataSetTarget = BroadcastItemMonitorBitrateChart.getDataSetTemplate(outputIndex,bOutput['streamName']+' Target',1);
			let dataActual = [];
			let dataAvailable = [];
			let dataTarget = [];
			for (let dataIndex = 0; dataIndex < output.length; dataIndex++)
			{
				let dataPoint = output[dataIndex];
				dataActual.push({x:dataPoint.eventTime,y:(dataPoint.bitrateActual/1024/1024).toFixed(3)});
				dataAvailable.push({x:dataPoint.eventTime,y:(dataPoint.bitrateAvailable/1024/1024).toFixed(2)});
				dataTarget.push({x:dataPoint.eventTime,y:(dataPoint.bitrateTarget/1024/1024).toFixed(2)});
			}
			dataSetActual['data'] = dataActual;
			dataSetAvailable['data'] = dataAvailable;
			dataSetTarget['data'] = dataTarget;
			dataSets.push(dataSetActual);
			dataSets.push(dataSetTarget);
			dataSets.push(dataSetAvailable);
		}
		let now = new Date();
		let windowStartTime = new Date(now.getTime() - (this.props.clearcaster.broadcastLiveDataWindowSizeMinutes * 60000));

		return (
			<Card bg="transparent" border="0">
				<Card.Header className="pl-0 pb-1 border-0 bg-white">
					{this.props.strings.app.MonitoringCardTitleAdaptive}
					<HelpTooltip className="ml-1" placement="right">{this.props.strings.app.MonitoringHelpBitrateChart}</HelpTooltip>
				</Card.Header>
				<Card.Body className="bg-white p-0">
					<Line
						options={{
							maintainAspectRatio:false,
							animation:{
								duration:0
							},
							layout:{
								padding:{
									left:0,
									top:0,
									right:0,
									bottom:0
								}
							},
							scales: {
								xAxes: [{
									type: 'time',
									position: 'bottom',
									time:{
										min:windowStartTime,
										tooltipFormat:'h:mm:ss a'
									},
									ticks:{
										minRotation:0,
										maxRotation:0
									}
								}],
								yAxes: [{
									type: 'linear',
									ticks:{
										beginAtZero:true,
										suggestedMax:4
									}
								}]
							}
						}}
						legend={{
							display:false
						}}
						data={{
							datasets: dataSets
						}} />
				</Card.Body>
			</Card>
		);
	}
}
export default BroadcastItemMonitorBitrateChart;