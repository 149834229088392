import { Badge } from 'react-bootstrap';
import React from 'react';
import { formatFrameRate } from '../view/UIUtils';
import { compareObjectValues } from '../controller/ClearCasterAdminUtils';
import ClearCasterStore from "./ClearCasterStore";

export const healthStatusClasses = [
	'fa fa-lg fa-check-circle text-success',
	'fa fa-lg fa-exclamation-circle text-warning',
	'fa fa-lg fa-exclamation-circle text-danger',
	'fa fa-lg fa-ellipsis-h text-secondary'
];

export function getBroadcastEncoders(broadcast, encoderMap)
{
	let encoders = [];

	if (broadcast.hasOwnProperty('broadcastEncoders') && broadcast.broadcastEncoders != null)
	{
		for (let i in broadcast.broadcastEncoders)
		{
			let broadcastEncoder = broadcast.broadcastEncoders[i];
			if (broadcastEncoder && broadcastEncoder.hasOwnProperty('encoder') && broadcastEncoder.encoder != null)
			{
				if (encoderMap.hasOwnProperty(broadcastEncoder.encoder.id))
				{
					let encoder = encoderMap[broadcastEncoder.encoder.id];

					encoders.push(encoder);
					/*
					if (encoder.hasOwnProperty("activeBroadcast") && encoder.activeBroadcast != null && encoder.activeBroadcast.id != null)
					{
							let broacastId = encoder.activeBroadcast.id;

							if (broacastId == broadcast.id)
							{
									encoders.push(encoder);
							}
					}
					*/
				}
			}
		}
	}

	return encoders;
}

export function getBroadcastStreamTargetHealth(nearRealtimeStreamTargetData)
{
	if (nearRealtimeStreamTargetData != null &&
		nearRealtimeStreamTargetData.pushpubConnectLastSuccess > 0 &&
		nearRealtimeStreamTargetData.pushpubConnectLastAttempt > 0 &&
		nearRealtimeStreamTargetData.pushpubConnectLastSuccess >= nearRealtimeStreamTargetData.pushpubConnectLastAttempt)
	{
		if (nearRealtimeStreamTargetData.ssNetworkInfo)
		{
			if (nearRealtimeStreamTargetData.ssNetworkInfo.rtt >= 100)
				return 1; // warning
		}
		else if (nearRealtimeStreamTargetData.srtNetworkInfo)
		{
			if (nearRealtimeStreamTargetData.srtNetworkInfo.msRTT >= 100)
				return 1; // warning
		}
		return 0; // connected
	}

	return 2; // not connected
}

export function getBroadcastHealthStatusBadge(connected,strings)
{
	return connected ?
		<Badge variant="success">{strings.app.BroadcastItemsConnected}</Badge> :
		<Badge variant="danger">{strings.app.BroadcastItemsNotConnected}</Badge>;
}

export function getBroadcastHealthStatuses(connected, encoder, broadcastData, destinations)
{
	let healthStatuses = {
		video:2,
		audio:2,
		network:2,
		destinations:2,
		encoding:2,
		appliance:2,
		overall:-1
	};
	let nearRealTimeData = null;
	if (broadcastData !== null && broadcastData !== undefined)
	{
		let latestNRT = {eventId:"-1"};
		for (let i in broadcastData)
		{
			if (Number(broadcastData[i].eventId) > Number(latestNRT.eventId))
			{
				latestNRT = broadcastData[i];
				nearRealTimeData = broadcastData[i];
			}
		}
	}

	// VIDEO
	if (encoder !== undefined && encoder !== null &&
		encoder.hasOwnProperty('captureSessionInfo') &&
		encoder.captureSessionInfo != null &&
		encoder.isOnline &&
		encoder.captureSessionInfo.videoFrameCountModeSwitch > 0)
	{
		healthStatuses.video = 0;
	}
	if (nearRealTimeData !== null)
	{
		// AUDIO
		if (healthStatuses.video === 2)
		{
			healthStatuses.audio = 2;
		}
		else if (nearRealTimeData.encoder !== undefined &&
			nearRealTimeData.encoder.captureSessionAudioLevelData !== undefined &&
			nearRealTimeData.encoder.captureSessionInfo !== undefined &&
			Math.max(nearRealTimeData.encoder.captureSessionAudioLevelData.peakLeft,
				nearRealTimeData.encoder.captureSessionInfo.audioLastLevelDataPeakLeft) === 0)
		{
			healthStatuses.audio = 1;
		}
		else
		{
			healthStatuses.audio = 0;
		}
		// DESTINATIONS
		healthStatuses.destinations = 0;
		if (nearRealTimeData.streamTargets !== undefined &&
			nearRealTimeData.streamTargets !== null)
		{
			for (let i = 0; i < nearRealTimeData.streamTargets.length; i++)
			{
				let target = nearRealTimeData.streamTargets[i];
				if (target.id != null) // id is null if target is not enabled
				{
					let streamTargetHealth = getBroadcastStreamTargetHealth(target);
					if (streamTargetHealth > healthStatuses.destinations)
					{
						healthStatuses.destinations = streamTargetHealth;
					}
				}
			}
		}
		// APPLIANCE
		// CPU Load:
		// Green to 60 | Yellow to 70 | Red > 70
		// Temperature:
		// Green to 80 | Yellow to 95 | Red > 95
		// Capture Card Temperature:
		// Green to 89 | Yellow to 95 | Red > 95
		healthStatuses.appliance = 0;
		let applianceHardwareDynamicLevel = 0;
		let applianceCaptureSessionLevel = 0;
		if (nearRealTimeData.hardwareDynamic !== undefined &&
			nearRealTimeData.hardwareDynamic !== null)
		{
			if (nearRealTimeData.hardwareDynamic.cpuLoadSystem > 0.60 ||
				nearRealTimeData.hardwareDynamic.cpuTempurature > 80)
			{
				applianceHardwareDynamicLevel = 1;
			}
			if (nearRealTimeData.hardwareDynamic.cpuLoadSystem > 0.70 ||
				nearRealTimeData.hardwareDynamic.cpuTempurature > 95)
			{
				applianceHardwareDynamicLevel = 2;
			}
		}
		if (nearRealTimeData.encoder.captureSessionInfo != null &&
			nearRealTimeData.encoder.captureSessionInfo.temperature != null)
		{
			if (nearRealTimeData.encoder.captureSessionInfo.temperature > 89)
			{
				applianceCaptureSessionLevel = 1;
			}
			if (nearRealTimeData.encoder.captureSessionInfo.temperature > 95)
			{
				applianceCaptureSessionLevel = 2;
			}
		}
		healthStatuses.appliance = Math.max(applianceHardwareDynamicLevel,applianceCaptureSessionLevel);
	}
	// NETWORK
	if (encoder !== null)
	{
		if (!encoder.isOnline)
		{
			healthStatuses.network = 2;
			healthStatuses.video = 0;
			healthStatuses.audio = 0;
		}
		else if (destinations.bandwidthRealization < 0.6)
		{
			healthStatuses.network = 1;
		}
		else
		{
			healthStatuses.network = 0;
		}
	}
	// ENCODING
	if (broadcastData !== null && broadcastData !== undefined && broadcastData.length > 2)
	{
		if (broadcastData[broadcastData.length-1].encoder.videoDecoderCaptureInfo.droppedFrames >
			broadcastData[broadcastData.length-2].encoder.videoDecoderCaptureInfo.droppedFrames)
		{
			healthStatuses.encoding = 2;
		}
		else
		{
			healthStatuses.encoding = 0;
		}
	}
	else
	{
		healthStatuses.encoding = 0;
	}

	if (nearRealTimeData === null)
	{
		healthStatuses.overall = 3;
	}
	else
	{
		healthStatuses.overall = Math.max(...Object.values(healthStatuses));
	}


	return healthStatuses;
}

export function getBroadcastOutputs(broadcast)
{
	let broadcastOutputs = [];

	if (broadcast.hasOwnProperty('outputs') && broadcast.outputs != null && broadcast.outputs.length > 0)
		broadcastOutputs = broadcast.outputs;

	return broadcastOutputs;
}

export function getBroadcastStatusBadge(broadcast,strings)
{
	let ret = null;
	switch (broadcast.status)
	{
		case 'PREVIEW':
			ret = <Badge variant="warning">{strings.app.streamStatusPreview}</Badge>;
			break;
		case 'LIVE':
			ret = <Badge variant="danger">{strings.app.streamStatusLive}</Badge>;
			break;
		case 'IDLE':
		default:
			ret = <Badge variant="success">{strings.app.streamStatusReady}</Badge>;
	}
	return ret;
}

export function getBroadcastTemplateInfo(broadcast)
{
	let returnString = '';
	let state = ClearCasterStore.getState();
	let templateInfo = state.broadcastTemplates;
	let createBroadcastInfo = broadcast.createBroadcastInfo;
	let templateId = undefined;
	let templateCategoryId = undefined;
	let isFacebookPaired = false;

	if (templateInfo !== undefined && templateInfo.templateSets !== undefined && createBroadcastInfo !== undefined)
	{

		if (createBroadcastInfo.template)
		{
			templateId = createBroadcastInfo.template;
			templateCategoryId = createBroadcastInfo.templateCategory;
		}
		else if (createBroadcastInfo.outputs && createBroadcastInfo.outputs.hasOwnProperty('rendition0'))
		{
			templateId = createBroadcastInfo.outputs.rendition0.template;
			templateCategoryId = createBroadcastInfo.outputs.rendition0.templateCategory;
		}

		let templateSet = "pro";
		if (createBroadcastInfo.hasOwnProperty("templateSet"))
			templateSet = createBroadcastInfo.templateSet;

		if (templateCategoryId && templateInfo.templateSets.hasOwnProperty(templateSet) && templateInfo.templateSets[templateSet].hasOwnProperty(templateCategoryId))
		{
			returnString += templateInfo.templateSets[templateSet][templateCategoryId].name + ': ';
		}

		if (templateId.indexOf('facebookpaired') >= 0)
		{
			isFacebookPaired = true;
		}

		if (templateId && templateInfo.templates != null && templateInfo.templates.hasOwnProperty(templateId))
		{
			returnString += templateInfo.templates[templateId].name;
			if (templateInfo.templates[templateId]['isFacebookPaired'] && templateInfo.templates[templateId].isFacebookPaired === true) {
				isFacebookPaired = true;
			}
		}
	}

	return {
		templateId:templateId,
		templateCategoryId:templateCategoryId,
		templateName:returnString,
		isFacebookPaired:isFacebookPaired
	};
}

export function getBroadcastDestinations(broadcastOutputs,encoder)
{
	let destinations = {
		bandwidthRealization:-1,
		bandwidthRealizationClassName:'text-success',
		totalAvailableBitrate:0,
		totalTargetBitrate:0,
		totalActualBitrate:0,
		outputs:[]
	};
	let nearRealTimeData = null;

	if (encoder !== null && encoder.hasOwnProperty("nearRealTimeData"))
	{
		nearRealTimeData = encoder.nearRealTimeData;
	}
	for (var i = 0; i < broadcastOutputs.length; i++)
	{
		let broadcastOutput = broadcastOutputs[i];
		let nrtdOutput = null;
		let output = {
			streamName:broadcastOutput.streamName,
			availableBitrate:-1,
			targetBitrate:-1,
			actualBitrate:-1,
			totalTargetBitrate:-1,
			totalActualBitrate:-1,
			streamTargets:[]
		};
		// find nearRealTimeData for output
		if (nearRealTimeData != null &&
			nearRealTimeData['encoder'] &&
			nearRealTimeData['encoder']['outputs'] &&
			nearRealTimeData['encoder']['outputs'].length > 0)
		{
			for (var j = 0; j < nearRealTimeData['encoder']['outputs'].length; j++)
			{
				if (broadcastOutput.id === nearRealTimeData['encoder']['outputs'][j].id)
				{
					nrtdOutput = nearRealTimeData['encoder']['outputs'][j];
					break;
				}
			}
		}
		// grab bitrates from nrtd
		if (nrtdOutput != null)
		{
			output.availableBitrate = nrtdOutput.videoBitrateCurrent;
			output.targetBitrate = nrtdOutput.videoBitrate;
			output.actualBitrate = nrtdOutput.videoBitrateMeasure;
		}
		// grab streamTargets
		for (var k = 0; k < broadcastOutput.streamTargets.length; k++)
		{
			let streamTarget = broadcastOutput.streamTargets[k];
			if (nearRealTimeData !== null && nearRealTimeData['streamTargets'])
			{
				for (var l = 0; l < nearRealTimeData['streamTargets'].length; l++)
				{
					if (nearRealTimeData.streamTargets[l].id === streamTarget.id)
					{
						streamTarget['nearRealTimeData'] = nearRealTimeData.streamTargets[l];
						break;
					}
				}
			}
			output.streamTargets.push(streamTarget);
		}
		// accumulate targets and actuals
		output.totalAvailableBitrate = output.availableBitrate * output.streamTargets.length;
		output.totalTargetBitrate = output.targetBitrate * output.streamTargets.length;
		output.totalActualBitrate = output.actualBitrate * output.streamTargets.length;
		destinations.totalAvailableBitrate += output.totalAvailableBitrate
		destinations.totalTargetBitrate += output.totalTargetBitrate;
		destinations.totalActualBitrate += output.totalActualBitrate;
		destinations.outputs.push(output);
	}
	destinations.outputs = destinations.outputs.sort(compareObjectValues('targetBitrate','desc'));
	destinations.bandwidthRealization = destinations.totalAvailableBitrate / destinations.totalTargetBitrate;

	return destinations;
}

export function getEncoderVideoSource(broadcast, encoder, strings)
{
	let returnString;

	if (encoder.hasOwnProperty('captureSessionInfo') && encoder.captureSessionInfo != null)
	{
		if (encoder.isOnline)
		{
			if (encoder.captureSessionInfo.videoFrameCountModeSwitch > 0)
			{
				let videoSource = 'SDI';
				switch (encoder.captureSessionInfo.videoInput)
				{
					case 1:
						videoSource = 'SDI';
						break;
					case 2:
						videoSource = 'HDMI';
						break;
					default:
				}

				let inputFrameSize = encoder.captureSessionInfo.videoFrameWidth + ' x ' + encoder.captureSessionInfo.videoFrameHeight;
				// let outputFrameSize = encoder.captureSessionInfo.videoLastFrameWidth + ' x ' + encoder.captureSessionInfo.videoLastFrameHeight;

				let inputFrameRate = formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, 1);
				// let outputFrameRate = formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, encoder.captureSessionInfo.videoFrameRateDivisor);

				let inputFrameInfo = inputFrameSize;
				if (inputFrameRate.length > 0)
				{
					inputFrameInfo += (encoder.captureSessionInfo.videoProgressive ? ' p ' : ' i ');
					inputFrameInfo += inputFrameRate;
				}
				// let outputFrameInfo = outputFrameSize;
				// if (outputFrameRate.length > 0)
				// {
				// 	outputFrameInfo += ' p ';
				// 	outputFrameInfo += outputFrameRate;
				// }

				returnString = <span>{videoSource}&nbsp;{inputFrameInfo}</span>;
			} else
			{
				returnString = <span><i
					className="fa fa-exclamation-triangle fa-lg fa-fw icon-warning"
					aria-hidden="true"></i>&nbsp;{strings.VideoSourceNotDetected}</span>;
			}
		}
	}

	return returnString;
}

export function getNearRealtimeStreamTargetData(broadcast, broadcastOutput, encoder, streamTarget)
{
	let streamTargetData;

	if (encoder !== null && encoder.hasOwnProperty('nearRealTimeData') && encoder.nearRealTimeData != null)
	{
		let nearRealtimeData = encoder.nearRealTimeData;
		if (isNearRealtimeCurrentBroadcast(nearRealtimeData, broadcast.id) && nearRealtimeData.hasOwnProperty('streamTargets') && nearRealtimeData.streamTargets != null && nearRealtimeData.streamTargets.length > 0)
		{
			let streamTargets = nearRealtimeData.streamTargets;
			for (let i in streamTargets)
			{
				if (streamTargets[i].id === streamTarget.id)
					streamTargetData = streamTargets[i];
			}
		}
	}

	return streamTargetData;
}

export function isNearRealtimeCurrentBroadcast(nearRealtimeData, broadcastId)
{
	return (nearRealtimeData.sessionInfo != null && nearRealtimeData.sessionInfo.publishSession != null && nearRealtimeData.sessionInfo.publishSession.broadcastId === broadcastId)
}


class BroadcastUIAdapter
{
	constructor(broadcast, broadcastData, encoderMap, strings)
	{
		this.id = broadcast.id;
		this.name = broadcast.name;
		this.broadcastData = broadcastData;
		this.broadcastEncoders = [];
		if (encoderMap !== undefined && encoderMap !== null)
		{
			this.broadcastEncoders = getBroadcastEncoders(broadcast, encoderMap);
		}
		this.broadcastOutputs = getBroadcastOutputs(broadcast);
		this.outputUIs = [];

		let encoder = null;
		if (this.broadcastEncoders.length > 0)
		{
			encoder = this.broadcastEncoders[0];
		}

		this.statusBadge = getBroadcastStatusBadge(broadcast,strings);

		this.videoSource = '';
		this.videoSourceInfo = <span />;
		this.videoOutputInfo = <span />;
		this.inputFrameSize = '';
		this.inputFrameRate = '';
		this.inputFrameInfo = '';
		this.inputAudioInfo = <span>{strings.app.NoneDetected}</span>;
		this.outputFrameSize = ''
		this.outputFrameRate = '';
		this.outputFrameInfo = '';

		if (encoder != null && encoder.hasOwnProperty('captureSessionInfo') && encoder.captureSessionInfo != null)
		{
			if (encoder.isOnline)
			{
				if (encoder.captureSessionInfo.videoFrameCountModeSwitch > 0)
				{
					this.videoSource = 'SDI';
					switch (encoder.captureSessionInfo.videoInput)
					{
						case 1:
							this.videoSource = 'SDI';
							break;
						case 2:
							this.videoSource = 'HDMI';
							break;
						default:
					}

					this.inputFrameSize = encoder.captureSessionInfo.videoFrameWidth + ' x ' + encoder.captureSessionInfo.videoFrameHeight;
					this.outputFrameSize = encoder.captureSessionInfo.videoLastFrameWidth + ' x ' + encoder.captureSessionInfo.videoLastFrameHeight;

					this.inputFrameRate = formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, 1);
					this.outputFrameRate = formatFrameRate(encoder.captureSessionInfo.videoFrameRateEnum, encoder.captureSessionInfo.videoFrameRateDen, encoder.captureSessionInfo.videoFrameRateDivisor);

					this.inputFrameInfo = this.inputFrameSize;
					if (this.inputFrameRate.length > 0)
					{
						this.inputFrameInfo += (encoder.captureSessionInfo.videoProgressive ? ' p ' : ' i ');
						this.inputFrameInfo += this.inputFrameRate;
					}
					this.outputFrameInfo = this.outputFrameSize;
					if (this.outputFrameRate.length > 0)
					{
						this.outputFrameInfo += ' p ';
						this.outputFrameInfo += this.outputFrameRate;
					}

					this.videoSourceInfo = <span>{this.videoSource}&nbsp;{this.inputFrameInfo}</span>;
				}
				if (encoder.captureSessionInfo.audioFrameCountModeSwitch > 0)
				{
					this.inputAudioInfo = <span>{encoder.captureSessionInfo.audioChannels} @ {encoder.captureSessionInfo.audioSampleRate / 1000} kHz</span>;
				}
			}
		}

		if (this.broadcastOutputs !== undefined && this.broadcastOutputs !== null && this.broadcastOutputs.length > 0)
		{
			for(let i = 0; i < this.broadcastOutputs.length; i++)
			{
				let output = this.broadcastOutputs[i];
				let outputUI = {};
				outputUI.codecInfo = '';
				outputUI.frameInfo = '';
				if (output.hasOwnProperty('encodingConfiguration') && output.encodingConfiguration !== null)
				{
					if (output.encodingConfiguration.hasOwnProperty('encodingConfigurationVideo') && output.encodingConfiguration.encodingConfigurationVideo !== null)
					{
						outputUI.bitrate = output.encodingConfiguration.encodingConfigurationVideo.bitrate;
						let outputFrameSize = output.encodingConfiguration.encodingConfigurationVideo.frameSizeWidth + ' x ' + output.encodingConfiguration.encodingConfigurationVideo.frameSizeHeight + ' ';
						outputUI.frameInfo += outputFrameSize + ' p ' + this.outputFrameRate + ' ';
						outputUI.frameInfo += '@ ' + (output.encodingConfiguration.encodingConfigurationVideo.bitrate/(1024 * 1024)).toFixed(2) + ' Mbps';

						outputUI.codecInfo += output.encodingConfiguration.encodingConfigurationVideo.codec + ' ';
					}
					if (output.encodingConfiguration.hasOwnProperty('encodingConfigurationAudio') && output.encodingConfiguration.encodingConfigurationAudio !== null)
					{
						outputUI.codecInfo += output.encodingConfiguration.encodingConfigurationAudio.codec.toUpperCase();
					}
					this.outputUIs.push(outputUI);
				}
			}
			this.outputUIs = this.outputUIs.sort(compareObjectValues('bitrate','desc'));
		}

		this.destinations = getBroadcastDestinations(this.broadcastOutputs,encoder);
		this.templateInfo = getBroadcastTemplateInfo(broadcast);
		this.templateName = this.templateInfo.templateName;
		this.healthStatuses = getBroadcastHealthStatuses(this.connected,encoder,broadcastData, this.destinations);
		this.connected = this.healthStatuses.destinations === 0;
		this.healthStatusBadge = getBroadcastHealthStatusBadge(this.connected,strings);

	}
}

export default BroadcastUIAdapter;
