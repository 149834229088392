import React, { PureComponent } from 'react';
import { Button } from 'react-bootstrap';

import './FacebookBroadcastPreviewIdleLive.css';

class FacebookBroadcastPreviewIdleLive extends PureComponent
{
  render() {
    let { facebookData, broadcast } = this.props;

    if (broadcast.status === 'LIVE')
    {
      return (
        <div className="preview-container">
          <div className="preview-rectangle is-live">
            <div className="preview-inner">
              <span className="badge badge-danger" style={{fontSize:'1.5em'}}>LIVE</span>
              <br />
              <br />
              <Button variant="light" href={'https://www.facebook.com'+facebookData.broadcast.permalink_url} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i>&nbsp;&nbsp;View on Facebook&nbsp;&nbsp;<i className="fa fa-external-link"></i></Button>
            </div>
          </div>
        </div>
      );
    }
    else
    {
      return (
        <div className="preview-container">
          <div className="preview-rectangle">
            <div className="preview-inner">VIDEO PREVIEW APPEARS HERE</div>
          </div>
        </div>
      );
    }

  }
}
export default FacebookBroadcastPreviewIdleLive;