import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';

class FacebookChooseDestinationConfirmationDialog extends Component
{
	onShow()
	{
	}

	handleClose(facebookData)
	{
		return (evt) => {
			let newFacebookData = {...facebookData,ui:{...facebookData.ui,showChooseDestinationConfirmationDialog: false}};
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData: newFacebookData
			});
		}
	}

	handleConfirm(facebookData)
	{
		return (evt) => {
			let newFacebookData = {...facebookData,
				ui:{...facebookData.ui,
					showChooseDestinationConfirmationDialog: false,
					state: 'edit-pairing'
				}
			};
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData: newFacebookData
			});
		}
	}

	render()
	{
		let appStrings = this.props.strings;
		let facebookData = this.props.facebookData;
		let accountName = '';
		if (facebookData['userFacebook'] != null && facebookData['userFacebook']['name'] != null)
			accountName = facebookData['userFacebook']['name'];

		return (
			<Modal show={ facebookData.ui.showChooseDestinationConfirmationDialog !== false } onHide={this.handleClose(facebookData)}>
				<Modal.Header closeButton>
					<Modal.Title>Edit Facebook Pairing: Are you sure?</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					This broadcast is paired to <strong>{accountName}'s</strong> Facebook account.<br />
					Are you sure you want to pair the broadcast to a different Facebook account?<br />
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose(facebookData)}>{ appStrings.app.Cancel }</Button>
					<Button variant="primary" onClick={this.handleConfirm(facebookData)}>Edit Facebook Pairing</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default FacebookChooseDestinationConfirmationDialog;
