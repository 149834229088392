import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';

class NoSubscriptionDialog extends Component
{

  close(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ACTIVATE_NO_SUBSCRIPTION,
			dialogShowing:false
		});
		if (event != null)
			event.preventDefault();
	}

  render()
	{

		return (
			<Modal show={this.props.clearcaster.activateNoSubscription.dialogShowing} onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.strings.app.ActivateNoSubscriptionTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: this.props.strings.app.ActivateSubscriptionLimitReached }}>
          </p>
          <p>
            <Button id="dialog-activate-no-subscription-close-button" variant="primary" onClick={this.close}>{ this.props.strings.app.Close }</Button>&nbsp;
          </p>
        </Modal.Body>
      </Modal>
    )
  }
}

export default NoSubscriptionDialog;
