import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { healthStatusClasses } from '../../../model/BroadcastUIAdapter';
import NearRealTimeDataCache from "../../../model/NearRealTimeDataCache";

class BroadcastItemMonitorEncoder extends Component
{
	render()
	{
		let framerateString = '...';
		let droppedFramesString = '...';
		// let packetLossString = '...';

		// TODO: This should be measured frame rate
		if (this.props.broadcastUI !== null)
		{
			framerateString = this.props.broadcastUI.outputFrameRate + ' fps';
		}

		let broadcastUI = this.props.broadcastUI;
		let broadcastData = NearRealTimeDataCache.framesChartAdapter(broadcastUI.broadcastData);
		let minDroppedFrames = Number.MAX_VALUE;
		let windowStartTime = new Date((new Date()).getTime() - (this.props.clearcaster.broadcastLiveDataWindowSizeMinutes * 60000));
		let liveAtTime = windowStartTime;
		if (this.props.broadcast != null && this.props.broadcast.liveAt != null)
		{
			liveAtTime = new Date(this.props.broadcast.liveAt);
		}
		for (let i = 0; i < broadcastData.frames.length; i++)
		{
			let eventTime = new Date(broadcastData.frames[i].eventTime);
			if (eventTime >= windowStartTime && eventTime >= liveAtTime)
			{
				if (broadcastData.frames[i].droppedFrames < minDroppedFrames)
					minDroppedFrames = broadcastData.frames[i].droppedFrames;
			}
		}

		let droppedFramesCapture = 0;
		let droppedFramesEncoder = 0;
		if (this.props.encoder !== null &&
			this.props.encoder['nearRealTimeData'])
		{
			if (this.props.encoder['nearRealTimeData']['encoder'] &&
				this.props.encoder['nearRealTimeData']['encoder']['captureSessionInfo'])
			{
				droppedFramesCapture = this.props.encoder['nearRealTimeData']['encoder']['captureSessionInfo']['videoDroppedFrames'];
			}
			if (this.props.encoder['nearRealTimeData']['encoder'] &&
				this.props.encoder['nearRealTimeData']['encoder']['videoDecoderCaptureInfo'])
			{
				droppedFramesEncoder = this.props.encoder['nearRealTimeData']['encoder']['videoDecoderCaptureInfo']['droppedFrames'];
			}
			// if (this.props.encoder['nearRealTimeData']['hardwareDynamic'] &&
			// 	this.props.encoder['nearRealTimeData']['hardwareDynamic']['networkInfo'] &&
			// 	this.props.encoder['nearRealTimeData']['hardwareDynamic']['networkInfo'].length > 0)
			// {
			// 	let packetLoss = 0;
			// 	for (let i = 0; i < this.props.encoder['nearRealTimeData']['hardwareDynamic']['networkInfo'].length; i++)
			// 	{
			// 		packetLoss += this.props.encoder['nearRealTimeData']['hardwareDynamic']['networkInfo'][i].statsRXDropped;
			// 	}
			// 	packetLossString = ''+packetLoss;
			// }
		}
		if (minDroppedFrames === Number.MAX_VALUE)
			minDroppedFrames = 0;
		droppedFramesString = (droppedFramesCapture+droppedFramesEncoder-minDroppedFrames)+'';

		return (
			<Card bg="transparent" border="0">
				<Card.Header className="pl-0 pb-1 border-0 bg-white">
					{this.props.strings.app.MonitoringCardTitleEncoder}&nbsp;
					{(this.props.broadcastUI !== null &&
						this.props.broadcastUI.healthStatuses.encoding > 0) &&
					<i className={healthStatusClasses[this.props.broadcastUI.healthStatuses.encoding]}></i>
					}
				</Card.Header>
				<Card.Body className="bg-white">
					<div className="container m-0 p-0">
						<div className="row">
							<div className="col-6 p-2 bg-light border border-dark">
								{this.props.strings.app.MonitoringEncoderEncoder}
							</div>
							<div className="col-6 p-2 bg-light border-top border-right border-bottom border-dark">
								{this.props.strings.app.MonitoringEncoderCurrent}
							</div>
						</div>
						<div className="row">
							<div className="col-6 p-2 border-left border-right border-bottom border-dark">
								{this.props.strings.app.MonitoringEncoderFramerate}
							</div>
							<div className="col-6 p-2 border-right border-bottom border-dark">
								{framerateString}
							</div>
						</div>
						<div className="row">
							<div className="col-6 p-2 border-left border-right border-bottom border-dark">
								{this.props.strings.app.MonitoringEncoderDroppedFrames}
							</div>
							<div className="col-6 p-2 border-right border-bottom border-dark">
								{droppedFramesString}
							</div>
						</div>
						{/* Commenting out per convo with Anthony ... is this data good? We have only seen it be 0?
						<div className="row">
							<div className="col-6 p-2 border-left border-right border-bottom border-dark">
								{this.props.strings.app.MonitoringEncoderPacketLoss}
							</div>
							<div className="col-6 p-2 border-right border-bottom border-dark">
								{packetLossString}
							</div>
						</div>
						*/}
					</div>
				</Card.Body>
			</Card>
		);
	}
}
export default BroadcastItemMonitorEncoder;