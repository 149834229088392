const FacebookDestinationType =
	{
		TIMELINE: 'me',
		PAGE: 'page',
		GROUP: 'group',
		TEST: 'test',
		UNKNOWN: 'unknown',
		UI_ACTIVE_BROADCAST: 'ui_active_broadcast'
	};
export default FacebookDestinationType;
