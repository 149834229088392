module.exports = {
	"name": "Social: Facebook, YouTube, and Twitter",
	"id": "v2-general-multitarget-fb-youtube-twitter",
	"type": "full",
	"presentation": {
		"layout": "MultipleOutputs",
		"widgets": [
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					},
					"label": "Facebook and YouTube"
				}
			},
			{
				"widget": "StreamTargetRTMPNoCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrlFacebook",
						"defaultValue": "",
						"label": "Facebook Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamNameFacebook",
						"defaultValue": "",
						"label": "Facebook Stream Key",
						"required": true
					}
				}
			},
			{
				"widget": "StreamTargetRTMPNoCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrlYouTube",
						"defaultValue": "",
						"label": "YouTube Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamNameYouTube",
						"defaultValue": "",
						"label": "YouTube Stream Key",
						"required": true
					}
				}
			},
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output1"
					},
					"label": "Twitter"
				}
			},
			{
				"widget": "StreamTargetRTMPNoCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrlTwitter",
						"defaultValue": "",
						"label": "Twitter Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamNameTwitter",
						"defaultValue": "",
						"label": "Twitter Stream Key",
						"required": true
					}
				}
			},
			{
				"widget": "CheckboxSimple",
				"label": "Record Broadcast",
				"parameters": {
					"value": {
						"variable": "$record",
						"defaultValue": false,
						"required": true
					}
				}
			}
		]
	},
	"template": {
		"input": {
			"tag": "input0",
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": 1920,
			"videoFrameHeightMax": 1080,
			"videoFrameRateMax": 30,
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition-1080",
				"record": "$record",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrlFacebook",
						"streamName": "$streamTargetStreamNameFacebook"
					},
					{
						"tag": "streamTarget1",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrlYouTube",
						"streamName": "$streamTargetStreamNameYouTube"
					}
				],
				"encodingConfiguration": {
					"name": "rendition0",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "QuickSync",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1920,
						"frameSizeHeight": 1080,
						"profile": "high",
						"bitrate": "5.5m",
						"bitrateMin": "1m",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "quicksync.TargetUsage",
								"value": "1",
								"type": "Long"
							},
							{
								"name": "quicksync.GopRefDist",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "128k",
						"parameters": []
					}
				}
			},
			{
				"tag": "output1",
				"streamName": "rendition-720",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrlTwitter",
						"streamName": "$streamTargetStreamNameTwitter"
					}
				],
				"encodingConfiguration": {
					"name": "rendition1",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "QuickSync",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1280,
						"frameSizeHeight": 720,
						"profile": "high",
						"bitrate": "4m",
						"bitrateMin": "750k",
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": false,
						"keyFrameInterval": 90,
						"parameters": [
							{
								"name": "quicksync.TargetUsage",
								"value": "1",
								"type": "Long"
							},
							{
								"name": "quicksync.GopRefDist",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": "128k",
						"parameters": []
					}
				}
			}
		]
	}
};