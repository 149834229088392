module.exports = {
	"name": "ABR Ladder: 1080p30",
	"id": "v2-general-abr-1080p30",
	"type": "full",
	"presentation": {
		"layout": "SingleOutput",
		"widgets": [
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output0"
					},
					"label": "1080p Output"
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output1"
					},
					"label": "720p Output"
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl1",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName1",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername1",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword1",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output2"
					},
					"label": "540p Output"
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl2",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName2",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername2",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword2",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output3"
					},
					"label": "360p Output"
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl3",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName3",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername3",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword3",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "OutputDetails",
				"parameters": {
					"output": {
						"outputTag": "output4"
					},
					"label": "270p Output"
				}
			},
			{
				"widget": "StreamTargetRTMPWithCredentials",
				"parameters": {
					"url": {
						"variable": "$streamTargetUrl4",
						"defaultValue": "",
						"label": "Server URL",
						"required": true
					},
					"streamName": {
						"variable": "$streamTargetStreamName4",
						"defaultValue": "",
						"label": "Stream Name/Key",
						"required": false
					},
					"username": {
						"variable": "$streamTargetUsername4",
						"defaultValue": "",
						"label": "Username",
						"required": false
					},
					"password": {
						"variable": "$streamTargetPassword4",
						"defaultValue": "",
						"label": "Password",
						"required": false
					}
				}
			},
			{
				"widget": "CheckboxSimple",
				"label": "Record Broadcast",
				"parameters": {
					"value": {
						"variable": "$record",
						"defaultValue": false,
						"required": true
					}
				}
			}
		]
	},
	"template": {
		"input": {
			"inputType": "CAPTURE_HARDWARE",
			"videoFrameWidthMax": 1920,
			"videoFrameHeightMax": 1080,
			"videoFrameRateMax": 30,
			"videoKeyFrameIntervalMilliseconds": 2000
		},
		"outputs": [
			{
				"tag": "output0",
				"streamName": "rendition-1080p",
				"record": "$record",
				"streamTargets": [
					{
						"tag": "streamTarget0",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl",
						"streamName": "$streamTargetStreamName",
						"username": "$streamTargetUsername",
						"password": "$streamTargetPassword"
					}
				],
				"encodingConfiguration": {
					"name": "rendition-1080p",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "x264",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1920,
						"frameSizeHeight": 1080,
						"profile": "main",
						"bitrate": 5500000,
						"bitrateMin": 2000000,
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "x264.bframes",
								"value": "0",
								"type": "Long"
							},
							{
								"name": "x264.preset",
								"value": "2",
								"type": "Long"
							},
							{
								"name": "x264.ref",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": 126000,
						"parameters": []
					}
				}
			},
			{
				"tag": "output1",
				"streamName": "rendition-720p",
				"streamTargets": [
					{
						"tag": "streamTarget1",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl1",
						"streamName": "$streamTargetStreamName1",
						"username": "$streamTargetUsername1",
						"password": "$streamTargetPassword1"
					}
				],
				"encodingConfiguration": {
					"name": "rendition-720p",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "QuickSync",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 1280,
						"frameSizeHeight": 720,
						"profile": "main",
						"bitrate": 3600000,
						"bitrateMin": 900000,
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "quicksync.TargetUsage",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": 126000,
						"parameters": []
					}
				}
			},
			{
				"tag": "output2",
				"streamName": "rendition-540p",
				"streamTargets": [
					{
						"tag": "streamTarget2",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl2",
						"streamName": "$streamTargetStreamName2",
						"username": "$streamTargetUsername2",
						"password": "$streamTargetPassword2"
					}
				],
				"encodingConfiguration": {
					"name": "rendition-540p",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "QuickSync",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 960,
						"frameSizeHeight": 540,
						"profile": "main",
						"bitrate": 2000000,
						"bitrateMin": 500000,
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "quicksync.TargetUsage",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": 126000,
						"parameters": []
					}
				}
			},
			{
				"tag": "output3",
				"streamName": "rendition-360p",
				"streamTargets": [
					{
						"tag": "streamTarget3",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl3",
						"streamName": "$streamTargetStreamName3",
						"username": "$streamTargetUsername3",
						"password": "$streamTargetPassword3"
					}
				],
				"encodingConfiguration": {
					"name": "rendition-360p",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "QuickSync",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 640,
						"frameSizeHeight": 360,
						"profile": "main",
						"bitrate": 960000,
						"bitrateMin": 240000,
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "quicksync.TargetUsage",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": 126000,
						"parameters": []
					}
				}
			},
			{
				"tag": "output4",
				"streamName": "rendition-270p",
				"streamTargets": [
					{
						"tag": "streamTarget4",
						"protocol": "RTMP",
						"streamTargetEncoderIndex": 0,
						"url": "$streamTargetUrl4",
						"streamName": "$streamTargetStreamName4",
						"username": "$streamTargetUsername4",
						"password": "$streamTargetPassword4"
					}
				],
				"encodingConfiguration": {
					"name": "rendition-270p",
					"encodingConfigurationVideo": {
						"codec": "H.264",
						"implementation": "QuickSync",
						"frameSizeFitMode": "stretch",
						"frameSizeWidth": 480,
						"frameSizeHeight": 270,
						"profile": "baseline",
						"bitrate": 500000,
						"bitrateMin": 125000,
						"autoAdjustBitrate": true,
						"keyFrameIntervalFollowSource": true,
						"parameters": [
							{
								"name": "quicksync.TargetUsage",
								"value": "1",
								"type": "Long"
							}
						]
					},
					"encodingConfigurationAudio": {
						"codec": "aac",
						"bitrate": 126000,
						"parameters": []
					}
				}
			}
		]
	}
};