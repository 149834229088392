import React, { Component } from 'react';

import { Button } from 'react-bootstrap'
import { hashFnv32a, testTemplatePackage, deflateStr, inflateStr } from '../../templates/TemplateUtils.js';

import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/eclipse';

const jsonic = require('jsonic');
const jsonMap = require('json-source-map');

class TemplateEditor extends Component
{
	static getDefaultState()
	{
		return {
			templateText:"",
			outputText:"",
			errorCount:0,
			errorMap:{}
		};
	}

	constructor() {
		
		super();
		
		this.state = TemplateEditor.getDefaultState();


		this.doTest = this.doTest.bind(this);
		this.doDeflate = this.doDeflate.bind(this);
		this.doInflate = this.doInflate.bind(this);
		this.onChangeValue = this.onChangeValue.bind(this);
		this.onValidate = this.onValidate.bind(this);
		this.navigateToError = this.navigateToError.bind(this);

		this.templateEditor = React.createRef();
	}

	onValidate()
	{
		if (this.templateEditor.current)
		{
			let errorList = [];
			let errorMap = {};
			let errorMapAll = {};
			let myMap = undefined;

			try
			{
				myMap = jsonMap.parse(this.state.templateText.trim());
				if (myMap !== undefined && myMap.pointers !== undefined && myMap.data !== undefined)
				{
					let results = testTemplatePackage(myMap.data);
					if (results !== undefined && results.errorList !== undefined)
					{
						errorList = results.errorList;
						for(let i in errorList)
						{
							errorMap[errorList[i].id] = errorList[i];
							errorMapAll[errorList[i].id] =  errorList[i];
						}
					}
				}
			}
			catch(error)
			{
				console.log("onValidate: ERROR: "+error.message);
			}
		
			let currAnnotations = this.templateEditor.current.editor.session.getAnnotations();

			for(let i in currAnnotations)
			{
				let annotation = currAnnotations[i];
				if (annotation.id !== undefined)
				{
					delete errorMap[annotation.id];
				}
				else
				{
					let annotationNew = JSON.parse(JSON.stringify(annotation));

					annotationNew.id = hashFnv32a("parser:"+annotationNew.line+":"+annotationNew.text+":"+annotationNew.type, true)
					
					annotationNew.line = annotationNew.row!==undefined?parseInt(annotationNew.row):0;
					
					errorMapAll[annotationNew.id] =  annotationNew;
				}
			}

			let didAdd = false;
			for(let i in errorMap)
			{
				let errorItem = errorMap[i];

				let line = 0;
				if (myMap !== undefined && myMap.pointers[errorItem.path] !== undefined)
				{
					let mapObj = myMap.pointers[errorItem.path];

					if (mapObj.key !== undefined && mapObj.key.line !== undefined)
						line = mapObj.key.line;
					else if (mapObj.value !== undefined && mapObj.value.line !== undefined)
						line = mapObj.value.line;
				}
				else
					console.log("WARNING: Can't find JSON path: "+errorItem.path);

				errorItem.line = line;

				let newAnnotation = 
				{
					id: errorItem.id,
					row: line,
					column: 0,
					text: errorItem.text,
					type: errorItem.type
				};

				didAdd = true;
				currAnnotations.push(newAnnotation);
			}

			if (didAdd)
			{
				this.templateEditor.current.editor.session.setAnnotations(currAnnotations);
			}

			this.setState({ errorCount:currAnnotations.length, errorMap: errorMapAll });
		}
	}

	onChangeValue(newText, evt)
	{
		this.setState({ templateText: newText }) 
	}
	
	doTest()
	{

		this.templateEditor.current.editor.moveCursorTo(3, 0);
		this.templateEditor.current.editor.centerSelection();

		let myMap = jsonMap.parse(this.state.templateText);

		console.log(JSON.stringify(myMap.pointers, null, 2));

		//console.log(this.state.templateText);
		
		let templatePackage = undefined;
		let resultStr = "Unknown Error";
		try
		{
			templatePackage = jsonic(this.state.templateText);
		}
		catch(error)
		{
			resultStr = "ERROR: Parsing JSON: "+error.message;
		}

		if (templatePackage !== undefined)
		{
			let results = testTemplatePackage(templatePackage);

			resultStr = results.infoText + (results.errorsText.length>0?"\n=== ERRORS ===\n\n" + results.errorsText:"\n=== No Errors, YAY! ===\n\n");
		}

		this.setState({ outputText: resultStr });
	}
	
	doDeflate()
	{
		this.setState({ templateText: deflateStr(this.state.templateText) });
	}

	doInflate()
	{
		this.setState({ templateText: inflateStr(this.state.templateText) });
	}

	navigateToError(evt)
	{
		console.log(evt.target.id);

		let key = evt.target.id.split("-")[1];

		console.log("navigateToError:"+key+":"+this.state.errorMap[key]);

		this.templateEditor.current.editor.moveCursorTo(this.state.errorMap[key].line, 0);
		this.templateEditor.current.editor.centerSelection();

	}

	render()
	{
		// let annotations = [
		// 	{
		// 	  row: 0, // must be 0 based
		// 	  column: 0, // must be 0 based
		// 	  text: "error.message", // text to show in tooltip
		// 	  type: "warning" // error, warning, info
		// 	}
		//   ];
		// let markers = [{ startRow: 0, startCol: 1, endRow: 0, endCol: 5, className: 'error-marker', type: 'background' }];

		//annotations={ annotations }
		//markers={ markers }
		// <Form.Control as="textarea" style={{boxSizing: "border-box", width: "100%", height: "55%"}} value={ this.state.templateText } onChange={evt => this.setState({ templateText: evt.target.value })} /> 
		//https://github.com/securingsincity/react-ace/blob/master/docs/FAQ.md
		//https://ace.c9.io/#nav=api&api=edit_session

		return (
			<div className="text-left">
				<div className="template-editor-container">

					<h4>Template Editor</h4>



					<AceEditor
						ref={ this.templateEditor }
						className="template-editor-ace"
						style={{width:"100%"}}
						mode="json"
						theme="eclipse"
						onChange={ this.onChangeValue }
						onValidate={ this.onValidate }
						fontSize={14}
						showPrintMargin={false}
						showGutter={true}
						highlightActiveLine={true}
						value={ this.state.templateText }
						editorProps={{
							$blockScrolling: Infinity,
						}}
						setOptions={{
							showLineNumbers: true,
							showInvisibles: false,
							displayIndentGuides: true,
							tabSize: 2,
							minLines: 26,
							maxLines: Infinity
						}} />

					<div className="template-editor-error-container" style={{boxSizing: "border-box", width: "100%", height: "10%", overflow:"auto"}}>
					{
						this.state.errorCount <= 0?
						<div><i className="fa fa-check-circle fa-lg fa-fw icon-success" aria-hidden="true"></i>&nbsp;Template is error free!</div> :
						<div>
						{ Object.keys(this.state.errorMap).map ((key, index) => { 
							let errorItem = this.state.errorMap[key];
							return <div key={ "error-"+errorItem.id } id={ "error-"+errorItem.id } className="template-editor-error-item" onClick={ this.navigateToError }><i className="fa fa-times-rectangle fa-lg fa-fw icon-error" aria-hidden="true"></i>&nbsp;{ errorItem.text }</div>
						})}
						</div>
					}
					</div>
					
					<div className="template-editor-controls">
						{
						//<Button variant="primary" onClick={ this.doTest }>Test Package</Button>&nbsp;&nbsp;
						}
						<Button variant="primary" onClick={ this.doDeflate }>Deflate Package</Button>&nbsp;&nbsp;
						<Button variant="primary" onClick={ this.doInflate }>Inflate Package</Button>
					</div>
					<br/><br/>
				</div>

			</div>
		);
	}
};

export default TemplateEditor;
