import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import { mixObjects } from '../../controller/UniversalAdminUtils';

class Activate extends Component
{
	constructor(props)
	{
		super(props);
		this.activateDevice = this.activateDevice.bind(this);
		this.onActivateCodeChange = this.onActivateCodeChange.bind(this);
	}

	activateDevice(event)
	{
		// micro JXEFZC9PU
		// pro 4QK7EWA49
		// pro JMMYTTUQA - burned
		let activateForm = this.props.clearcaster.activateForm;
		this.props.controller.preflightActivateEncoder(activateForm)
		.then((result)=>{
			let preflightResponse = result.hasOwnProperty("data") && result.data.hasOwnProperty("preflightEncoderCanBeAddedToNamespace") ? result.data.preflightEncoderCanBeAddedToNamespace : {};
			if(preflightResponse.hasOwnProperty("canBeAdded") && preflightResponse.canBeAdded){
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_ACTIVATE_FORM,
					dialogShowing:true
				});
			} else if (preflightResponse.hasOwnProperty("hardwareType") && preflightResponse.hardwareType === "CC_PRO") {
				let activateForm = this.props.clearcaster.activateForm;
				activateForm.proNoSubscription = true;
				activateForm.dialogShowing = true;
				ClearCasterStore.dispatch(mixObjects(
					{type:ActionTypes.SET_ACTIVATE_FORM},
					activateForm
				));
			} else {
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_ACTIVATE_NO_SUBSCRIPTION,
					dialogShowing:true
				});
			}
		}).catch((err)=>{
			console.log("Preflight error:");
			console.log(err);
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_ACTIVATE_NO_SUBSCRIPTION,
				dialogShowing:true
			});
		})
	}

	onActivateCodeChange(event)
	{
		let activateForm = this.props.clearcaster.activateForm;
		activateForm.activationCode = event.target.value.toUpperCase().replace(/[^ABCDEFGHJKMNPQRTUVWXYZ2346789]/g,'');
		ClearCasterStore.dispatch(mixObjects(
			{type:ActionTypes.SET_ACTIVATE_FORM},
			activateForm
		));
	}

	numEncoders(){
		return this.props.clearcaster.encoderList.length;
	}

	numSubscriptions(){
		return this.props.clearcaster.userWowza.subscriptionInfo.service_channel_count;
	}

	render()
	{
		let appStrings = this.props.strings.app;
		let activateForm = this.props.clearcaster.activateForm;
		let numEncoders = this.numEncoders();
		let numSubscriptions = this.numSubscriptions()+'';
		let capacityString = '...';
		if (this.props.clearcaster.userWowza.subscriptionInfo.loaded)
		{
			if (numSubscriptions === '-1')
				numSubscriptions = appStrings.SubscriptionsUnlimited;
			if (numEncoders > 1)
				capacityString = appStrings.SubscriptionsCapacityPlural({numEncoders:numEncoders,numSubscriptions:numSubscriptions});
			else
				capacityString = appStrings.SubscriptionsCapacitySingular({numEncoders:numEncoders,numSubscriptions:numSubscriptions});
		}
		
		return (
			<div>
				<h2>{ appStrings.ActivateTitle }</h2>
				<p>{ capacityString }</p>
				<p className="mb-2"><strong>{ appStrings.ActivateEnterCode }</strong></p>
				<Form inline>
					<Form.Control type="text"
						value={activateForm.activationCode}
						onChange={this.onActivateCodeChange}
						className="mr-2"
					/>
					<Button id="activate-device-button"
						disabled={ activateForm.activationCode.length !== 9}
						variant={ activateForm.activationCode.length === 9 ? 'primary' : 'outline-secondary' }
						onClick={ this.activateDevice }>
						{ appStrings.ActivateNow }
					</Button>
				</Form>
				<p className="activation-prompt">{ appStrings.ActivatePrompt }</p>
			</div>
		);
	}
}
export default Activate;
