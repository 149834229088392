import React, { PureComponent } from 'react';

class SubscriptionBanner extends PureComponent
{
	render()
	{
		let { strings, userIsSubscriptionCurrent, userIsSubscriptionDirect, userIsSubscriptionUnderwater, userIsSubscriptionAtGoLiveLimit, userSubscriptionDaysUntilUnderwater} = this.props;

		let storeLink = window.config.REACT_APP_SUBSCRIPTION_URL;
		let portalLink = window.config.REACT_APP_SUBSCRIPTION_PRODUCT_URL;
		let faqLink = "https://www.wowza.com/products/clearcaster#f-a-q";

		function renderRenewLink (portalLink,faqLink,isDirect,strings)
		{
			if (isDirect)
			{
				return (<span>
					<a href={portalLink} target="_blank"  rel="noopener noreferrer">{strings.app.SubscriptionBannerClickToRenew}</a>&nbsp;
					<a href={faqLink} target="_blank"  rel="noopener noreferrer">{strings.app.SubscriptionBannerClickToFAQ}</a>
				</span>);
			}
			else
			{
				return (<span>
					{strings.app.SubscriptionBannerResellerToRenew}&nbsp;
					<a href={faqLink} target="_blank"  rel="noopener noreferrer">{strings.app.SubscriptionBannerClickToFAQ}</a>
				</span>);
			}
		}

		if (!userIsSubscriptionCurrent)
		{
			return (
				<div className="alert alert-danger">
					<strong>
						{strings.app.SubscriptionBannerNoSubscriptions} &nbsp;
						<a href={storeLink} target="_blank"  rel="noopener noreferrer">{strings.app.SubscriptionBannerClickToBuy}</a><br />
						<a href={faqLink} target="_blank"  rel="noopener noreferrer">{strings.app.SubscriptionBannerClickToFAQ}</a>
					</strong>
				</div>
			);
		}
		else if (userIsSubscriptionAtGoLiveLimit)
		{
			if (userIsSubscriptionDirect)
			{
				return (
					<div className="alert alert-danger">
						<strong>
							{strings.app.SubscriptionBannerSubscriptionAtGoLiveLimit({portalLink:portalLink})}<br />
							<a href={faqLink} target="_blank"  rel="noopener noreferrer">{strings.app.SubscriptionBannerClickToFAQ}</a>
						</strong>
					</div>
				);
			}
			else
			{
				return (
					<div className="alert alert-danger">
						<strong>
							{strings.app.SubscriptionBannerSubscriptionAtGoLiveLimitReseller({storeLink:storeLink})}<br />
							<a href={faqLink} target="_blank"  rel="noopener noreferrer">{strings.app.SubscriptionBannerClickToFAQ}</a>
						</strong>
					</div>
				);
			}
		}
		else if ( userIsSubscriptionUnderwater )
		{
			return (
				<div className="alert alert-warning">
					<strong>
						{strings.app.SubscriptionBannerSubscriptionUnderwater} <br />
						{renderRenewLink(portalLink,faqLink,userIsSubscriptionDirect,strings)}
					</strong>
				</div>
			);
		}
		else if ( userSubscriptionDaysUntilUnderwater > 0 && userSubscriptionDaysUntilUnderwater < 29 )
		{
			return (
				<div className="alert alert-warning">
					<strong>
						{strings.app.SubscriptionBannerSubscriptionWillExpire({days:Math.round(userSubscriptionDaysUntilUnderwater)})} <br />
						{renderRenewLink(portalLink,faqLink,userIsSubscriptionDirect,strings)}
					</strong>
				</div>
			)
		}
		return null;
	}
}

export default SubscriptionBanner;
