module.exports = {

	"templateCategoryOrder": [
		"facebook",
		"facebookpaired",
		"v2-general-multiple-targets",
		"v2-general-single-target",
		"ipinput",
		"linkedin",
		"other",
		"twitch",
		"twitter",
		"vimeo",
		"wowza",
		"wowza-wsc",
		"youtube",
		"example-config",
		"example-widget",
		"expired-subscription-limited",
	],
	"templateCategoryGroups": [
		{
			name:"Built-in Social Media",
			templateCategories:[
				"facebook",
				"facebookpaired",
				"linkedin",
				"twitch",
				"twitter",
				"vimeo",
				"youtube"
			]
		},
		{
			name:"Built-in General",
			templateCategories:[
				"v2-general-multiple-targets",
				"v2-general-single-target",
				"ipinput",
				"other",
				"expired-subscription-limited"
			]
		},
		{
			name:"Built-in Wowza",
			templateCategories:[
				"wowza",
				"wowza-wsc"
			]
		}
	],
	"templateSets": {
		"enterprise": {
			"wowza": {
				"name": "Wowza Streaming Engine",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "wowza-1080p30",
				"templates": [
					"wowza-2160p30",
					"wowza-1080p30",
					"wowza-1080p60",
					"wowza-720p30",
					"wowza-720p60",
					"wowza-360p30",
					"wowza-360p60",
					"wowza-2160p30-h265",
					"wowza-1080p30-h265",
					"wowza-1080p60-h265",
				]
			},
			"wowza-wsc": {
				"name": "Wowza Streaming Cloud",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "wsc-1080p30",
				"templates": [
					"wsc-4k30",
					"wsc-1080p30",
					"wsc-1080p60",
					"wsc-720p30",
					"wsc-720p60",
					"wsc-ull-1080p30",
					"wsc-ull-720p30"
				]
			},
			"general-h265": {
				"name": "Wowza HEVC",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "wowza-1080p30-h265",
				"templates": [
					"wowza-2160p30-h265",
					"wowza-1080p30-h265",
					"wowza-1080p60-h265",
				]
			},
			"facebook": {
				"name": "Facebook",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "facebook-1080p30",
				"templates": [
					"facebook-1080p30",
					"facebook-1080p60",
					"facebook-720p30",
					"facebook-720p60",
				]
			},
			"facebookpaired": {
				"name": "Facebook Paired",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "facebookpaired-1080p30",
				"templates": [
					"facebookpaired-1080p30",
					"facebookpaired-1080p60",
					"facebookpaired-720p30",
					"facebookpaired-720p60",
					"facebookpaired-1080p60-vr",
					"facebookpaired-1080p60-mediacaster",
					"facebookpaired-1080p60-vertical",
				]
			},
			"youtube": {
				"name": "YouTube",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "youtube-1080p30",
				"templates": [
					"youtube-2160p30",
					"youtube-1440p30",
					"youtube-1440p60",
					"youtube-1080p30",
					"youtube-1080p60",
					"youtube-720p30",
					"youtube-720p60",
				]
			},
			"linkedin": {
				"name": "LinkedIn Live",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "linkedin-720p30",
				"templates": [
					"linkedin-1080p30",
					"linkedin-720p30",
				]
			},
			"twitch": {
				"name": "Twitch",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "twitch-1080p30",
				"templates": [
					"twitch-1080p30",
					"twitch-1080p60",
					"twitch-720p30",
					"twitch-720p60",
				]
			},
			"twitter": {
				"name": "Twitter",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "twitter-720p30",
				"templates": [
					"twitter-720p30",
					"twitter-1080p30",
				]
			},
			"vimeo": {
				"name": "Vimeo",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "vimeo-1080p30",
				"templates": [
					"vimeo-1080p30",
					"vimeo-720p30",
				]
			},
			"ipinput": {
				"name": "IP Inputs",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "other-input-ip-applehls",
				"templates": [
					"other-input-ip-applehls",
					"other-input-ip-rtp",
					"other-input-ip-rtsp",
					"other-input-ip-srt",
				]
			},
			"other": {
				"name": "Other",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "other-output-mpegts-udp",
				"templates": [
					"other-output-mpegts-udp",
					"other-output-mpegts-srt"
				]
			},
			"general-high-bitrate": {
				"name": "General",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "general-1080p30",
				"templates": [
					"general-2160p30",
					"general-1080p30",
					"general-1080p60",
					"general-720p30",
					"general-720p60",
				]
			},
			"general-low-bitrate": {
				"name": "General Low Bitrate",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "general-low-1080p30",
				"templates": [
					"general-low-2160p30",
					"general-low-1080p30",
					"general-low-1080p60",
					"general-low-720p30",
					"general-low-720p60",
				]
			},
			"v2-general-single-target": {
				"name": "General Single Target",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "v2-general-1080p30",
				"templates": [
					"v2-general-4kp30",
					"v2-general-1080p30",
					"v2-general-1080p60",
					"v2-general-720p30",
					"v2-general-720p60",
					"v2-general-hevc"
				]
			},
			"v2-general-multiple-targets": {
				"name": "General Multiple Targets",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "v2-general-multitarget-fb-youtube",
				"templates": [
					"v2-general-multitarget-fb-youtube",
					"v2-general-multitarget-fb-youtube-twitter",
					"v2-general-multitarget-2x-1080p30",
					"v2-general-multitarget-3x-1080p30",
					"v2-general-abr-1080p30",
					"v2-general-abr-720p30"
				]
			},
			"low-latency": {
				"name": "Low Latency",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "low-latency-1080p30",
				"templates": [
					"low-latency-1080p30",
					"low-latency-720p30",
					"low-latency-vertical-rotate-right-1080p30",
					"low-latency-vertical-center-cut-1080p30",
					"low-latency-vertical-rotate-right-720p30",
					"low-latency-vertical-center-cut-720p30",
				]
			},
			"vertical": {
				"name": "Vertical",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "vertical-rotate-right-1080p30",
				"templates": [
					"vertical-rotate-right-1080p30",
					"vertical-center-cut-1080p30",
					"vertical-rotate-right-720p30",
					"vertical-center-cut-720p30",
				]
			},
			"example-config": {
				"name": "Encoder Configuration",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": true,
				"defaultTemplate": "example-config-storeall",
				"templates": [
					"example-config-storeall",
					"example-config-lowlatency",
					"example-config-vertical-rotate-right",
					"example-config-vertical-center-cut",
					"example-config-recording",
					"example-config-ten-stream-targets",
					"example-config-max-latency",
					"example-config-audio-config"
				]
			},
			"example-widget": {
				"name": "Template Widgets",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": true,
				"defaultTemplate": "example-widget-rtmp-auth",
				"templates": [
					"example-widget-rtmp-auth",
					"example-widget-ip-input-source",
					"example-widget-max-frame-rate",
					"example-widget-max-latency",
					"example-widget-turn-off-adaptive-encoding",
					"example-widget-low-high-framerate",
					"example-widget-profile-implementation-fit",
					"example-widget-list-frame-sizes"
				]
			},
		},
		"micro": {
			"wowza": {
				"name": "Wowza Streaming Engine",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "wowza-1080p30+quicksync",
				"templates": [
					"wowza-1080p30+quicksync",
					"wowza-1080p60+quicksync",
					"wowza-720p30+quicksync",
					"wowza-720p60+quicksync",
					"wowza-360p30+quicksync",
					"wowza-360p60+quicksync",
					"wowza-1080p30-h265+quicksync",
					"wowza-1080p60-h265+quicksync",
				]
			},
			"wowza-wsc": {
				"name": "Wowza Streaming Cloud",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "wsc-1080p30+quicksync",
				"templates": [
					"wsc-1080p30+quicksync",
					"wsc-1080p60+quicksync",
					"wsc-720p30+quicksync",
					"wsc-720p60+quicksync",
					"wsc-ull-1080p30+quicksync",
					"wsc-ull-720p30+quicksync"
				]
			},
			"general-h265": {
				"name": "Wowza HEVC",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "wowza-1080p30-h265+quicksync",
				"templates": [
					"wowza-1080p30-h265+quicksync",
					"wowza-1080p60-h265+quicksync",
				]
			},
			"facebook": {
				"name": "Facebook",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "facebook-1080p30+quicksync",
				"templates": [
					"facebook-1080p30+quicksync",
					"facebook-1080p60+quicksync",
					"facebook-720p30+quicksync",
					"facebook-720p60+quicksync",
				]
			},
			"facebookpaired": {
				"name": "Facebook Paired",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "facebookpaired-1080p30+quicksync",
				"templates": [
					"facebookpaired-1080p30+quicksync",
					"facebookpaired-1080p60+quicksync",
					"facebookpaired-720p30+quicksync",
					"facebookpaired-720p60+quicksync",
				]
			},
			"youtube": {
				"name": "YouTube",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "youtube-1080p30+quicksync",
				"templates": [
					"youtube-1080p30+quicksync",
					"youtube-1080p60+quicksync",
					"youtube-720p30+quicksync",
					"youtube-720p60+quicksync",
				]
			},
			"linkedin": {
				"name": "LinkedIn Live",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "linkedin-720p30+quicksync",
				"templates": [
					"linkedin-1080p30+quicksync",
					"linkedin-720p30+quicksync",
				]
			},
			"twitch": {
				"name": "Twitch",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "twitch-1080p30+quicksync",
				"templates": [
					"twitch-1080p30+quicksync",
					"twitch-1080p60+quicksync",
					"twitch-720p30+quicksync",
					"twitch-720p60+quicksync",
				]
			},
			"twitter": {
				"name": "Twitter",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "twitter-720p30+quicksync",
				"templates": [
					"twitter-720p30+quicksync",
					"twitter-1080p30+quicksync",
				]
			},
			"vimeo": {
				"name": "Vimeo",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "vimeo-1080p30+quicksync",
				"templates": [
					"vimeo-1080p30+quicksync",
					"vimeo-720p30+quicksync",
				]
			},
			"ipinput": {
				"name": "IP Inputs",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "other-input-ip-applehls+quicksync",
				"templates": [
					"other-input-ip-applehls+quicksync",
					"other-input-ip-rtp+quicksync",
					"other-input-ip-rtsp+quicksync",
					"other-input-ip-srt+quicksync",
				]
			},
			"other": {
				"name": "Other",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "other-output-mpegts-udp+quicksync",
				"templates": [
					"other-output-mpegts-udp+quicksync",
					"other-output-mpegts-srt+quicksync"
				]
			},
			"general-high-bitrate": {
				"name": "General",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "general-1080p30+quicksync",
				"templates": [
					"general-1080p30+quicksync",
					"general-1080p60+quicksync",
					"general-720p30+quicksync",
					"general-720p60+quicksync",
				]
			},
			"general-low-bitrate": {
				"name": "General Low Bitrate",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "general-low-1080p30+quicksync",
				"templates": [
					"general-low-1080p30+quicksync",
					"general-low-1080p60+quicksync",
					"general-low-720p30+quicksync",
					"general-low-720p60+quicksync",
				]
			},
			"v2-general-single-target": {
				"name": "General Single Target",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "v2-general-1080p30+quicksync",
				"templates": [
					"v2-general-1080p30+quicksync",
					"v2-general-1080p60+quicksync",
					"v2-general-720p30+quicksync",
					"v2-general-720p60+quicksync",
					"v2-general-hevc+quicksync"
				]
			},
			"v2-general-multiple-targets": {
				"name": "General Multiple Targets",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "v2-general-multitarget-fb-youtube+quicksync",
				"templates": [
					"v2-general-multitarget-fb-youtube+quicksync",
					"v2-general-multitarget-fb-youtube-twitter+quicksync",
					"v2-general-multitarget-2x-1080p30+quicksync",
					"v2-general-multitarget-3x-1080p30+quicksync",
					"v2-general-abr-1080p30+quicksync",
					"v2-general-abr-720p30+quicksync"
				]
			},
			"low-latency": {
				"name": "Low Latency",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "low-latency-1080p30+quicksync",
				"templates": [
					"low-latency-1080p30+quicksync",
					"low-latency-720p30+quicksync",
					"low-latency-vertical-rotate-right-1080p30+quicksync",
					"low-latency-vertical-center-cut-1080p30+quicksync",
					"low-latency-vertical-rotate-right-720p30+quicksync",
					"low-latency-vertical-center-cut-720p30+quicksync",
				]
			},
			"vertical": {
				"name": "Vertical",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "vertical-rotate-right-1080p30+quicksync",
				"templates": [
					"vertical-rotate-right-1080p30+quicksync",
					"vertical-center-cut-1080p30+quicksync",
					"vertical-rotate-right-720p30+quicksync",
					"vertical-center-cut-720p30+quicksync",
				]
			},
			"example-config": {
				"name": "Encoder Configuration",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": true,
				"defaultTemplate": "example-config-storeall+quicksync",
				"templates": [
					"example-config-storeall+quicksync",
					"example-config-lowlatency+quicksync",
					"example-config-vertical-rotate-right+quicksync",
					"example-config-vertical-center-cut+quicksync",
					"example-config-recording+quicksync",
					"example-config-ten-stream-targets+quicksync",
					"example-config-max-latency+quicksync",
					"example-config-audio-config+quicksync"
				]
			},
			"example-widget": {
				"name": "Template Widgets",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": true,
				"defaultTemplate": "example-widget-rtmp-auth+quicksync",
				"templates": [
					"example-widget-rtmp-auth+quicksync",
					"example-widget-ip-input-source+quicksync",
					"example-widget-max-frame-rate+quicksync",
					"example-widget-max-latency+quicksync",
					"example-widget-turn-off-adaptive-encoding+quicksync",
					"example-widget-low-high-framerate+quicksync",
					"example-widget-profile-implementation-fit+quicksync",
					"example-widget-list-frame-sizes+quicksync"
				]
			},
		},
		"pro": {
			"wowza": {
				"name": "Wowza Streaming Engine",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "wowza-1080p30",
				"templates": [
					"wowza-2160p30",
					"wowza-1080p30",
					"wowza-1080p60",
					"wowza-720p30",
					"wowza-720p60",
					"wowza-360p30",
					"wowza-360p60",
					"wowza-2160p30-h265",
					"wowza-1080p30-h265",
					"wowza-1080p60-h265",
				]
			},
			"wowza-wsc": {
				"name": "Wowza Streaming Cloud",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "wsc-1080p30",
				"templates": [
					"wsc-4k30",
					"wsc-1080p30",
					"wsc-1080p60",
					"wsc-720p30",
					"wsc-720p60",
					"wsc-ull-1080p30",
					"wsc-ull-720p30"
				]
			},
			"general-h265": {
				"name": "Wowza HEVC",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "wowza-1080p30-h265",
				"templates": [
					"wowza-2160p30-h265",
					"wowza-1080p30-h265",
					"wowza-1080p60-h265",
				]
			},
			"facebook": {
				"name": "Facebook",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "facebook-1080p30",
				"templates": [
					"facebook-1080p30",
					"facebook-1080p60",
					"facebook-720p30",
					"facebook-720p60",
				]
			},
			"facebookpaired": {
				"name": "Facebook Paired",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "facebookpaired-1080p30",
				"templates": [
					"facebookpaired-1080p30",
					"facebookpaired-1080p60",
					"facebookpaired-720p30",
					"facebookpaired-720p60",
					"facebookpaired-1080p60-vr",
					"facebookpaired-1080p60-mediacaster",
					"facebookpaired-1080p60-vertical",
				]
			},
			"youtube": {
				"name": "YouTube",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "youtube-1080p30",
				"templates": [
					"youtube-2160p30",
					"youtube-1440p30",
					"youtube-1440p60",
					"youtube-1080p30",
					"youtube-1080p60",
					"youtube-720p30",
					"youtube-720p60",
				]
			},
			"linkedin": {
				"name": "LinkedIn Live",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "linkedin-720p30",
				"templates": [
					"linkedin-1080p30",
					"linkedin-720p30",
				]
			},
			"twitch": {
				"name": "Twitch",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "twitch-1080p30",
				"templates": [
					"twitch-1080p30",
					"twitch-1080p60",
					"twitch-720p30",
					"twitch-720p60",
				]
			},
			"twitter": {
				"name": "Twitter",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "twitter-720p30",
				"templates": [
					"twitter-720p30",
					"twitter-1080p30",
				]
			},
			"vimeo": {
				"name": "Vimeo",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "vimeo-1080p30",
				"templates": [
					"vimeo-1080p30",
					"vimeo-720p30",
				]
			},
			"ipinput": {
				"name": "IP Inputs",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "other-input-ip-applehls",
				"templates": [
					"other-input-ip-applehls",
					"other-input-ip-rtp",
					"other-input-ip-rtsp",
					"other-input-ip-srt",
				]
			},
			"other": {
				"name": "Other",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "other-output-mpegts-udp",
				"templates": [
					"other-output-mpegts-udp",
					"other-output-mpegts-srt"
				]
			},
			"general-high-bitrate": {
				"name": "General",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "general-1080p30",
				"templates": [
					"general-2160p30",
					"general-1080p30",
					"general-1080p60",
					"general-720p30",
					"general-720p60",
				]
			},
			"general-low-bitrate": {
				"name": "General Low Bitrate",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "general-low-1080p30",
				"templates": [
					"general-low-2160p30",
					"general-low-1080p30",
					"general-low-1080p60",
					"general-low-720p30",
					"general-low-720p60",
				]
			},
			"v2-general-single-target": {
				"name": "General Single Target",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "v2-general-1080p30",
				"templates": [
					"v2-general-4kp30",
					"v2-general-1080p30",
					"v2-general-1080p60",
					"v2-general-720p30",
					"v2-general-720p60",
					"v2-general-hevc"
				]
			},
			"v2-general-multiple-targets": {
				"name": "General Multiple Targets",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "v2-general-multitarget-fb-youtube",
				"templates": [
					"v2-general-multitarget-fb-youtube",
					"v2-general-multitarget-fb-youtube-twitter",
					"v2-general-multitarget-2x-1080p30",
					"v2-general-multitarget-3x-1080p30",
					"v2-general-abr-1080p30",
					"v2-general-abr-720p30"
				]
			},
			"low-latency": {
				"name": "Low Latency",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "low-latency-1080p30",
				"templates": [
					"low-latency-1080p30",
					"low-latency-720p30",
					"low-latency-vertical-rotate-right-1080p30",
					"low-latency-vertical-center-cut-1080p30",
					"low-latency-vertical-rotate-right-720p30",
					"low-latency-vertical-center-cut-720p30",
				]
			},
			"vertical": {
				"name": "Vertical",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "vertical-rotate-right-1080p30",
				"templates": [
					"vertical-rotate-right-1080p30",
					"vertical-center-cut-1080p30",
					"vertical-rotate-right-720p30",
					"vertical-center-cut-720p30",
				]
			},
			"expired-subscription-limited": {
				"name": "Subscription Expired Limited Profile",
				"showRTMPCredentials": true,
				"isReadOnly": true,
				"isManageOnly": false,
				"defaultTemplate": "v2-general-1080p60",
				"templates": [
					"v2-general-1080p60"
				]
			},
			"example-config": {
				"name": "Encoder Configuration",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": true,
				"defaultTemplate": "example-config-storeall",
				"templates": [
					"example-config-storeall",
					"example-config-lowlatency",
					"example-config-vertical-rotate-right",
					"example-config-vertical-center-cut",
					"example-config-recording",
					"example-config-ten-stream-targets",
					"example-config-max-latency",
					"example-config-audio-config"
				]
			},
			"example-widget": {
				"name": "Template Widgets",
				"showRTMPCredentials": false,
				"isReadOnly": true,
				"isManageOnly": true,
				"defaultTemplate": "example-widget-rtmp-auth",
				"templates": [
					"example-widget-rtmp-auth",
					"example-widget-ip-input-source",
					"example-widget-max-frame-rate",
					"example-widget-max-latency",
					"example-widget-turn-off-adaptive-encoding",
					"example-widget-low-high-framerate",
					"example-widget-profile-implementation-fit",
					"example-widget-list-frame-sizes"
				]
			},
		}
	}
};
