import React, { Component } from 'react';

import EncoderItem from './EncoderItem';
import EncoderUpdateDialog from '../dialogs/EncoderUpdateDialog';
import EncoderRollbackDialog from "../dialogs/EncoderRollbackDialog";
import EncoderSettingsDialog from '../dialogs/EncoderSettingsDialog';

class EncoderList extends Component
{
	componentDidMount()
	{
		if (this.props.clearcaster.versionsFile == null)
		{
			this.props.controller.getVersionsFile();
		}
	}
	render()
	{
		let encoders = this.props.clearcaster.encoderList;
		let userIsAdmin = this.props.controller.userIsAdmin()
		return (
			<>
				<EncoderUpdateDialog strings={this.props.strings} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				<EncoderRollbackDialog strings={this.props.strings} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				<EncoderSettingsDialog strings={this.props.strings} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				<div className="encoder-list">
				{ encoders.map((v, key) => {
						return <EncoderItem key={key} encoder={v} strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} rbui={this.props.rbui} rbuiForce={this.props.rbuiForce} userIsAdmin={userIsAdmin}/>
					})
				}
				</div>
			</>
		);
	}
};

export default EncoderList;
