import React, { Component } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from 'react-spinners';
import { stringEquals } from '../../controller/UniversalAdminUtils';

class EncoderSettingsDialog extends Component
{

	constructor(props)
	{
		super(props);

		this.close = this.close.bind(this);
		this.renameEncoder = this.renameEncoder.bind(this);
		this.applyAssetPackage = this.applyAssetPackage.bind(this);
		this.restartEncoder = this.restartEncoder.bind(this);
		this.sendLogs = this.sendLogs.bind(this);
		this.onNameChange = this.onNameChange.bind(this);
		this.onAssetPackageInfoChanged = this.onAssetPackageInfoChanged.bind(this);
		this.deactivateEncoder = this.deactivateEncoder.bind(this);
	}

	close(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			dialogShowing:false
		});
	}

	async renameEncoder(event)
	{
		let encoderSettings = this.props.clearcaster.encoderSettings;
		if (!stringEquals(encoderSettings.encoderName.trim(),encoderSettings.encoder.name))
		{
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_ENCODER_SETTINGS,
				dialogShowing:true,
				updating:true
			});
			let response = null;
			try
			{
				response = await this.props.controller.renameEncoder(encoderSettings.encoder.id,encoderSettings.encoderName);
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_ENCODER_SETTINGS,
					dialogShowing:false
				});
			}
			catch(e)
			{
				console.log('ERROR renaming encoder');
				console.log(response);
			}
		}
	}

	async deactivateEncoder(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			dialogShowing:true,
			confirmRestart:false,
			confirmAssetPackageApplied:false,
			updateMessage:"Deleting Encoder",
			updating:true
		});
		let response = null;
		try
		{
			response = await this.props.controller.deactivateEncoder(this.props.clearcaster.encoderSettings.encoder.id);

		}
		catch (e)
		{
			console.log('ERROR deactivating encoder');
			console.log(response);
			console.log(e);
		}
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			dialogShowing:false,
			updating:false
		});
	}

	async applyAssetPackage(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			dialogShowing:true,
			updating:true,
			updatingMessage:"Applying asset package"
		});
		let encoderSettings = this.props.clearcaster.encoderSettings;
		let assetPackageData = JSON.parse(JSON.stringify(this.props.clearcaster.namespaceAssetPackageData));
		for (let key in encoderSettings.assetPackageInfo)
		{
			assetPackageData[key] = encoderSettings.assetPackageInfo[key];
		}
		if (assetPackageData['enabled'] != null)
			delete assetPackageData['enabled'];
		if (assetPackageData['encoderAssetsUrl'] == null || assetPackageData['encoderAssetsUrl'] === '')
		{
			if (encoderSettings.encoder.encoderAssetsUrl != null)
				assetPackageData['encoderAssetsUrl'] = encoderSettings.encoder.encoderAssetsUrl;
		}
		assetPackageData['encoderAssetsVersion'] = (new Date()).toISOString();

		let response = null;
		try
		{
			response = await this.props.controller.setEncoderEncoderAssetInfo(encoderSettings.encoder.id,assetPackageData);
		}
		catch(e)
		{
			console.log('ERROR applying assets package');
			console.log(response);
		}
		this.restartEncoder(event);
	}

	async restartEncoder(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			dialogShowing:true,
			confirmRestart:false,
			confirmAssetPackageApplied:false,
			updateMessage:"Restarting Encoder",
			updating:true
		});
		let response = null;
		let response2 = null;
		try
		{
			response = await this.props.controller.restartEncoder(this.props.clearcaster.encoderSettings.encoder.id);
			if (response.data != null && response.data.sendEncoderCommand != null && response.data.sendEncoderCommand.correlationId != null)
			{
				response2 = await this.props.controller.getEncoderCommandStatus(response.data.sendEncoderCommand.correlationId);
			}
			else
			{
				console.log('ERROR restarting encoder');
				console.log(response);
			}
		}
		catch (e)
		{
			console.log('ERROR restarting encoder');
			console.log(response);
			console.log(response2);
			console.log(e);
		}
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			dialogShowing:false,
			updating:false
		});
	}

	async sendLogs(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			dialogShowing:true,
			updateMessage:"Sending Logs",
			updating:true
		});
		let response = null;
		let response2 = null;
		try
		{
			response = await this.props.controller.sendEncoderLogs(this.props.clearcaster.encoderSettings.encoder.id);
			if (response.data != null && response.data.sendEncoderCommand != null && response.data.sendEncoderCommand.correlationId != null)
			{
				response2 = await this.props.controller.getEncoderCommandStatus(response.data.sendEncoderCommand.correlationId);
			}
			else
			{
				console.log('ERROR sending logs');
				console.log(response);
			}
		}
		catch (e)
		{
			console.log('ERROR sending logs');
			console.log(response);
			console.log(response2);
			console.log(e);
		}
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			dialogShowing:false,
			updating:false
		});
	}

	onNameChange(newValue)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			encoderName:newValue
		});
	}

	onAssetPackageInfoChanged(key,value)
	{
		let assetPackageInfo = this.props.clearcaster.encoderSettings.assetPackageInfo;
		assetPackageInfo[key] = value;
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_ENCODER_SETTINGS,
			assetPackageInfo:assetPackageInfo
		});
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.clearcaster != null && this.props.clearcaster.encoderSettings != null && this.props.clearcaster.encoderSettings.encoder != null)
		{
			let canonicalEncoder = this.props.clearcaster.encoderMap[this.props.clearcaster.encoderSettings.encoder.id];
			if (canonicalEncoder != null && !stringEquals(canonicalEncoder.name,this.props.clearcaster.encoderSettings.encoder.name))
			{
				if (this.props.clearcaster.encoderSettings.updating && stringEquals(this.props.clearcaster.encoderSettings.dialogType,'rename'))
				{
					ClearCasterStore.dispatch({
						type:ActionTypes.SET_ENCODER_SETTINGS,
						encoder:canonicalEncoder,
						updating:false
					});
				}
				else
				{
					ClearCasterStore.dispatch({
						type:ActionTypes.SET_ENCODER_SETTINGS,
						encoder:canonicalEncoder
					});
				}
			}
		}
	}

	render()
	{
		let encoderSettings = this.props.clearcaster.encoderSettings;
		if (encoderSettings.encoder == null)
			return null;

		if (stringEquals(encoderSettings.dialogType,'rename'))
		{
			return (
				<Modal show={encoderSettings.dialogShowing} onHide={this.close}>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Rename {encoderSettings.encoder.name}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Group controlId="formName">
								<Form.Label>
									<strong>{this.props.strings.app.EncoderSettingsClearCasterName}</strong>
								</Form.Label>
								<Form.Control type="text"
									disabled={encoderSettings.updating}
									value={encoderSettings.encoderName}
									onChange={evt => this.onNameChange(evt.target.value)}
								/>
							</Form.Group>
							<div className="d-flex">
								<Button id="dialog-settings-cancel-button" variant="light" className="ml-auto mr-2" onClick={this.close}>{ this.props.strings.app.Close }</Button>
								{ encoderSettings.updating &&
									<Button id="dialog-settings-save-button" variant={'outline-secondary'} style={{width:'90px'}} disabled>
										<PulseLoader sizeUnit="px" size="5"/>
									</Button>
								}
								{ !encoderSettings.updating &&
									<Button id="dialog-settings-save-button" variant={'primary'} style={{width:'90px'}} onClick={this.renameEncoder}>Rename</Button>
								}
							</div>
						</Modal.Body>
					</Form>
				</Modal>
			);
		}
		else if (stringEquals(encoderSettings.dialogType,'sendlogs'))
		{
			return (
				<Modal show={encoderSettings.dialogShowing} onHide={this.close}>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Send logs for {encoderSettings.encoder.name}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="d-flex">
								<Button id="dialog-settings-cancel-button" variant="light" className="ml-auto mr-2" onClick={this.close}>{ this.props.strings.app.Close }</Button>
								{ encoderSettings.updating &&
								<Button id="dialog-settings-save-button" variant={'outline-secondary'} style={{width:'120px'}} disabled>
									<PulseLoader sizeUnit="px" size="5"/>
								</Button>
								}
								{ !encoderSettings.updating &&
								<Button id="dialog-settings-save-button" variant={'primary'} style={{width:'120px'}} onClick={this.sendLogs}>Send Logs</Button>
								}
							</div>
						</Modal.Body>
					</Form>
				</Modal>
			);
		}
		else if (stringEquals(encoderSettings.dialogType,'restart'))
		{
			return (
				<Modal show={encoderSettings.dialogShowing} onHide={this.close}>
					<Modal.Header>
						<Modal.Title>
							Are you sure you want to restart:
							<br />
							{encoderSettings.encoder.name}?
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<div className="d-flex">
								<div className="ml-0 mr-4">
									<i className="fa fa-exclamation-triangle fa-5x icon-warning" style={{marginTop:'10px'}}></i>
								</div>
								<div className="ml-0 mr-auto">
									<div className="form-group">
										Restarting your ClearCaster will disconnect any streams.
										<br />
									</div>
									<Button id="dialog-activate-cancel-button" variant="light" className="mr-2" onClick={this.close}>{ this.props.strings.app.Cancel }</Button>&nbsp;
									<Button id="dialog-activate-activate-button" variant="primary" onClick={this.restartEncoder}>{ this.props.strings.app.Restart }</Button>
								</div>
							</div>
						</Form>
					</Modal.Body>
				</Modal>
			);
		}
		else if (stringEquals(encoderSettings.dialogType,'applyassetpackage'))
		{
			let defaultAssetsUrl = '';
			if (encoderSettings.encoder.encoderAssetsUrl != null)
				defaultAssetsUrl = encoderSettings.encoder.encoderAssetsUrl;

			return (
				<Modal show={encoderSettings.dialogShowing} onHide={this.close}>
					<LoadingOverlay active={encoderSettings.updating} spinner text={encoderSettings.updateMessage}>
						<Form>
							<Modal.Header closeButton>
								<Modal.Title>{encoderSettings.encoder.name}: Apply asset package</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form.Group controlId="formAssetPackageUrl">
									<Form.Label>
										{this.props.strings.app.EncoderSettingsAssetPackageURL}
									</Form.Label>
									<Form.Control type="url"
										defaultValue={defaultAssetsUrl}
										value={encoderSettings.encoderAssetsUrl}
										onChange={evt => this.onAssetPackageInfoChanged("encoderAssetsUrl", evt.target.value)}
									/>
								</Form.Group>
								<Alert variant={'warning'}>
									Applying an asset package will restart your ClearCaster and disconnect any streams.
								</Alert>
								<div className="d-flex">
									<Button id="dialog-settings-cancel-button" variant="light" className="ml-auto mr-2"
										onClick={this.close}>{this.props.strings.app.Close}</Button>
									<Button id="dialog-settings-save-button" variant={'primary'}
										onClick={this.applyAssetPackage}>Apply</Button>
								</div>
							</Modal.Body>
						</Form>
					</LoadingOverlay>
				</Modal>
			);
		}
		else if (stringEquals(encoderSettings.dialogType,'delete'))
		{
			return (
				<Modal show={encoderSettings.dialogShowing} onHide={this.close}>
					<Modal.Header>
						<Modal.Title>
							Are you sure you want to delete:
							<br />
							{encoderSettings.encoder.name}?
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<div className="d-flex">
								<div className="ml-0 mr-4">
									<i className="fa fa-exclamation-triangle fa-5x icon-warning" style={{marginTop:'10px'}}></i>
								</div>
								<div className="ml-0 mr-auto">
									<div className="form-group">
										 Manually restart the appliance for your changes to take effect.
										<br />
									</div>
									<Button id="dialog-activate-cancel-button" variant="light" className="mr-2" onClick={this.close}>{ this.props.strings.app.Cancel }</Button>&nbsp;
									<Button id="dialog-activate-activate-button" variant="primary" onClick={this.deactivateEncoder}>{ this.props.strings.app.Deactivate }</Button>
								</div>
							</div>
						</Form>
					</Modal.Body>
				</Modal>
			);
		}
	}
}

export default EncoderSettingsDialog;
