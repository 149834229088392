
import { gql } from '@apollo/client';
import ClearCasterStore from '../model/ClearCasterStore';
import { isVersionGreaterThanOrEqual } from './ClearCasterAdminUtils';

import {
	queryMe,
	queryGraphQLVersion,
	queryNearRealTimeDataByBroadcast,
	queryNearRealTimeArchiveDataByBroadcast,
	queryFetchNamespaceExtraProperties,
	queryFetchPollingData,
	queryAllEncoders,
	queryEncoderCommandStatus,
	queryBroadcastGoLiveEvents,
	queryNamespaceAccessKeys,
	queryNamespaceUsers,
	queryFetchBroadcastProviderStreamTargets,
	queryFetchBroadcastProviderStreamTarget,
	queryGetBroadcastAccessKeys,
	queryGetBroadcastsByStatus,

} from './YetiGraphQLQueries';

import {
	mutationPreflightActivateEncoder,
	mutationActivateEncoder,
	mutationDeactivateEncoder,
	mutationRenameEncoder,
	mutationSetEncoderEncoderAssetInfo,
	mutationSetNamespaceUserPrivledges,
	mutationCreateAccessKey,
	mutationCreateAccessKey2,
	mutationCreateBroadcast,
	mutationSetNamespaceExtraProperty,
	mutationCreateBroadcastEncoder,
	mutationCreateBroadcastRecording,
	mutationDeleteBroadcastEncoder,
	mutationDeleteAccessKey,
	mutationDeleteBroadcast,
	mutationActivateBroadcastEncoders,
	mutationDeactivateBroadcastEncoders,
	mutationSetBroadcastStatus,
	mutationSetBroadcastInputInfo,
	mutationSetBroadcastInputVideoAspectRatio,
	mutationSetBroadcastInputVideoOverlay,
	mutationSetBroadcastInputAudioLevel,
	mutationSetBroadcastInputAudioChannels,
	mutationSetBroadcastTranscodingConfigurationDecodingVideoParameter,
	mutationCreateBroadcastDisplay,
	mutationSetBroadcastStreamTargetInfo,
	mutationRenameBroadcast,
	mutationSetBroadcastDisplayInfo,
	mutationDeleteBroadcastDisplay,
	mutationDeleteBroadcastRecording,
	mutationSetBroadcastEncodingConfigurationVideo,
	mutationSetBroadcastEncodingConfigurationAudio,
	mutationCreateProviderKey,
	mutationDeleteProviderKey,
	mutationSetNamespaceExtraPropertyOverlayGraphicsData,
	mutationSetSlateOn,
	mutationSetSlateOff, mutationSetBroadcastExtraProperty,

} from './YetiGraphQLMutations';

import {
	commandUpdateEncoder,
	commandRestartEncoder,
	commandSendEncoderLogs,
	commandDeviceGetLocalVersions,
	commandGeneric

} from './YetiGraphQLCommands';


class YetiGraphQLRequester
{
	constructor(connection)
	{
		this.connection = connection
	}

	fetchPollingData(onResult)
	{
		let query = queryFetchPollingData;
		let state = ClearCasterStore.getState();

		query = query.replace("##audioMuted##",
			(process.env.REACT_APP_CHECK_FEATURE_VERSIONS === "true" && isVersionGreaterThanOrEqual(state.graphQLVersion, "1.0.0.33")) ?
				"audioMuted" :
				""
		);

		query = query.replace(/##srtNetworkInfo##/g,
			isVersionGreaterThanOrEqual(state.graphQLVersion, "1.0.0.59") ?
				"srtNetworkInfo { msRTT }" :
				""
		);

		query = gql`${query}`;

		let operation = {
			query: query,
			variables: {}
		};

		return this.connection.makeRequest(operation);
	}

	getMe()
	{

		let operation = {
			query: queryMe,
			variables: {}
		};

		return this.connection.makeRequest(operation);
	}

	getNamespaceExtraProperties()
	{

		let operation = {
			query: queryFetchNamespaceExtraProperties,
			variables: { }
		};

		return this.connection.makeRequest(operation);
	}

	queryGetBroadcastsByStatus(namespaceId, status)
	{
		let query = queryGetBroadcastsByStatus;
		let operation = {
			query: query,
			variables: {
				namespaceId:namespaceId,
				status:status
			}
		};

		return this.connection.makeRequest(operation);
	}


	getNearRealTimeDataByBroadcast(broadcastId, startDateTime, endDateTime)
	{
		let query = queryNearRealTimeDataByBroadcast;
		let state = ClearCasterStore.getState();

		query = query.replace("##srtNetworkInfo##",
			isVersionGreaterThanOrEqual(state.graphQLVersion, "1.0.0.59") ?
				"srtNetworkInfo { msRTT }" :
				""
		);

		query = gql`${query}`;


		let endDateTimeMS = null;
		if (endDateTime !== null)
		{
			endDateTimeMS = endDateTime.getTime();
		}
		let startDateTimeMS = null;
		if (startDateTime !== null)
		{
			startDateTimeMS = startDateTime.getTime();
		}

		let operation = {
			query: query,
			variables:{
				broadcastId:broadcastId,
				startDateTime:startDateTimeMS,
				endDateTime:endDateTimeMS
			}
		};
		return this.connection.makeRequest(operation);
	}

	getNearRealTimeArchiveDataByBroadcast(broadcastId, startDateTime, endDateTime)
	{
		let query = queryNearRealTimeArchiveDataByBroadcast;
		let state = ClearCasterStore.getState();

		query = query.replace("##srtNetworkInfo##",
			isVersionGreaterThanOrEqual(state.graphQLVersion, "1.0.0.59") ?
				"srtNetworkInfo { msRTT }" :
				""
		);

		query = gql`${query}`;
		let startDateTimeMS = null;
		let endDateTimeMS = null;
		if (startDateTime !== null)
		{
			startDateTimeMS = startDateTime.getTime();
		}
		if (endDateTime !== null)
		{
			endDateTimeMS = endDateTime.getTime();
		}
		let operation = {
			query: query,
			variables:{
				broadcastId:broadcastId,
				startDateTime:startDateTimeMS,
				endDateTime:endDateTimeMS
			}
		};
		return this.connection.makeRequest(operation);
	}

	getBroadcastGoLiveEvents(namespaceId)
	{
		let query = queryBroadcastGoLiveEvents;
		let state = ClearCasterStore.getState();

		query = query.replace("##status##",
			(process.env.REACT_APP_CHECK_FEATURE_VERSIONS === "true" && isVersionGreaterThanOrEqual(state.graphQLVersion, "1.0.0.59")) ?
				"status" :
				""
		);

		query = gql`${query}`;
		let operation = {
			query: query,
			variables:{
				namespaceId: namespaceId
			}
		};
		return this.connection.makeRequest(operation);
	}

	getNamespaceAccessKeys(namespaceId)
	{
	let operation = {
			query: queryNamespaceAccessKeys,
			variables:{
				namespaceId: namespaceId
			}
		};
		return this.connection.makeRequest(operation);
	}

	getNamespaceUsers(namespaceId)
	{
	let operation = {
			query: queryNamespaceUsers,
			variables:{
				namespaceId: namespaceId
			}
		};
		return this.connection.makeRequest(operation);
	}

	setNamespaceUserPrivs(namespaceUserId, input)
	{
		let operation = {
			query: mutationSetNamespaceUserPrivledges,
			variables: {
				namespaceUserId: namespaceUserId,
				input: input
			}
		};

		return this.connection.makeRequest(operation);
	}

	setNamespaceExtraPropertyOverlayGraphics(namespaceId)
	{
		let operation = {
			query: mutationSetNamespaceExtraPropertyOverlayGraphicsData,
			variables: {
				namespaceId: namespaceId
			}
		};

		return this.connection.makeRequest(operation);
	}

	async getGraphQLVersion()
	{
		let operation = {
			query: queryGraphQLVersion
		};

		return this.connection.makeRequest(operation);
	}

	getAllEncoders()
	{
		let operation = {
			query: queryAllEncoders,
			variables: {}
		};

		return this.connection.makeRequest(operation);
	}

	preflightActivateEncoder(namespaceId,activateInput)
	{
		let operation = {
			query: mutationPreflightActivateEncoder,
			variables: {
				namespaceId:namespaceId,
				input:activateInput
			}
		}

		return this.connection.makeRequest(operation);
	}

	activateEncoder(namespaceId,activateInput)
	{
		let operation = {
			query: mutationActivateEncoder,
			variables: {
				namespaceId:namespaceId,
				input:activateInput
			}
		};

		return this.connection.makeRequest(operation);
	}

	deactivateEncoder(encoderId)
	{
		let operation = {
			query: mutationDeactivateEncoder,
			variables: {
				encoderId:encoderId,
			}
		};

		return this.connection.makeRequest(operation);
	}

	getEncoderCommandStatus(correlationId)
	{
		let operation = {
			query: queryEncoderCommandStatus,
			variables: {
				correlationId:correlationId
			}
		};

		return this.connection.makeRequest(operation);
	}

	renameEncoder(encoderId,name)
	{
		let operation = {
			query: mutationRenameEncoder,
			variables: {
				encoderId:encoderId,
				name:name
			}
		};

		return this.connection.makeRequest(operation);
	}

	setEncoderEncoderAssetInfo(encoderId,input)
	{
		let operation = {
			query: mutationSetEncoderEncoderAssetInfo,
			variables: {
				encoderId:encoderId,
				input:input
			}
		};

		return this.connection.makeRequest(operation);
	}

	updateEncoder(encoderId)
	{
		let operation = {
			query: commandUpdateEncoder,
			variables: {
				encoderId:encoderId
			}
		};

		return this.connection.makeRequest(operation);
	}

	updateEncoderWithUpdateString(encoderId,jsonStrBase64)
	{
	let command = '{"command":"devicestartsoftwareudpate","jsonStrBase64":"'+jsonStrBase64+'"}';

		let operation = {
			query: commandGeneric,
			variables: {
				encoderId:encoderId,
				command:command
			}
		};

		return this.connection.makeRequest(operation);
	}

	restartEncoder(encoderId)
	{
		let operation = {
			query: commandRestartEncoder,
			variables: {
				encoderId:encoderId
			}
		};

		return this.connection.makeRequest(operation);
	}

	sendEncoderLogs(encoderId)
	{
		let operation = {
			query: commandSendEncoderLogs,
			variables: {
				encoderId:encoderId
			}
		};

		return this.connection.makeRequest(operation);
	}

	getLocalVersions(encoderId)
	{
		let operation = {
			query: commandDeviceGetLocalVersions,
			variables: {
				encoderId:encoderId
			}
		};

		return this.connection.makeRequest(operation);
	}


	async createAccessKey(identityId, name)
	{
		let operation = {
			query: mutationCreateAccessKey,
			variables: {
				identityId: identityId,
				name: name
			}
		};

		return this.connection.makeRequest(operation);
	}

	async createAccessKey2(identityId, input)
	{
		let operation = {
			query: mutationCreateAccessKey2,
			variables: {
				identityId: identityId,
				input: input
			}
		};

		return this.connection.makeRequest(operation);
	}

	async deleteAccessKey(id)
	{
		let operation = {
			query: mutationDeleteAccessKey,
			variables: {
				id: id
			}
		};

		return this.connection.makeRequest(operation);
	}

	createBroadcast(namespaceId, input)
	{
		let inputTrimmed = JSON.parse(JSON.stringify(input));

		let operation = {
			query: mutationCreateBroadcast,
			variables: {
				namespaceId: namespaceId,
				input: inputTrimmed
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastExtraProperty(broadcastId, input)
	{
		let operation = {
			query: mutationSetBroadcastExtraProperty,
			variables: {
				broadcastId: broadcastId,
				input: input
			}
		};

		return this.connection.makeRequest(operation);
	}

	setNamespaceExtraProperty(namespaceId, input)
	{
		let operation = {
			query: mutationSetNamespaceExtraProperty,
			variables: {
				namespaceId: namespaceId,
				input: input
			}
		};

		return this.connection.makeRequest(operation);
	}

	activateBroadcastEncoders(namespaceId, broadcastId)
	{
		let operation = {
			query: mutationActivateBroadcastEncoders,
			variables: {
				broadcastId: broadcastId,
			}
		};

		return this.connection.makeRequest(operation);
	}

	deactivateBroadcastEncoders(namespaceId, broadcastId)
	{
		let operation = {
			query: mutationDeactivateBroadcastEncoders,
			variables: {
				broadcastId: broadcastId,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setStatusBroadcast(namespaceId, broadcastId, status)
	{
		let operation = {
			query: mutationSetBroadcastStatus,
			variables: {
				broadcastId: broadcastId,
				status: status,
			}
		};

		return this.connection.makeRequest(operation);
	}

	deleteBroadcast(namespaceId, broadcastId)
	{
		let operation = {
			query: mutationDeleteBroadcast,
			variables: {
				broadcastId: broadcastId,
			}
		};

		return this.connection.makeRequest(operation);
	}

	renameBroadcast(namespaceId, broadcastId, name)
	{
		let operation = {
			query: mutationRenameBroadcast,
			variables: {
				broadcastId: broadcastId,
				name: name,
			}
		};

		return this.connection.makeRequest(operation);
	}

	deleteBroadcastEncoder(namespaceId, broadcastEncoderId)
	{
		let operation = {
			query: mutationDeleteBroadcastEncoder,
			variables: {
				broadcastEncoderId: broadcastEncoderId,
			}
		};

		return this.connection.makeRequest(operation);
	}

	createBroadcastEncoder(namespaceId, broadcastId, input)
	{
		let operation = {
			query: mutationCreateBroadcastEncoder,
			variables: {
				broadcastId: broadcastId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	createBroadcastRecording(namespaceId, broadcastOutputId, input)
	{
		let operation = {
			query: mutationCreateBroadcastRecording,
			variables: {
				broadcastOutputId: broadcastOutputId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastStreamTargetInfo(namespaceId, broadcastStreamTargetId, input)
	{
		let operation = {
			query: mutationSetBroadcastStreamTargetInfo,
			variables: {
				broadcastStreamTargetId: broadcastStreamTargetId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastInputInfo(broadcastId, input)
	{
		let operation = {
			query: mutationSetBroadcastInputInfo,
			variables: {
				broadcastId: broadcastId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastInputVideoAspectRatio(broadcastId, input)
	{
		let operation = {
			query: mutationSetBroadcastInputVideoAspectRatio,
			variables: {
				broadcastId: broadcastId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastInputVideoOverlay(broadcastId, input)
	{
		let operation = {
			query: mutationSetBroadcastInputVideoOverlay,
			variables: {
				broadcastId: broadcastId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastInputAudioLevel(broadcastId, input)
	{
		let operation = {
			query: mutationSetBroadcastInputAudioLevel,
			variables: {
				broadcastId: broadcastId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastInputAudioChannels(broadcastId, input)
	{
		let operation = {
			query: mutationSetBroadcastInputAudioChannels,
			variables: {
				broadcastId: broadcastId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastTranscodingConfigurationDecodingVideoParameter(broadcastId, input)
	{
		let operation = {
			query: mutationSetBroadcastTranscodingConfigurationDecodingVideoParameter,
			variables: {
				broadcastId: broadcastId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	createBroadcastDisplay(namespaceId, broadcastId, input)
	{
		let operation = {
			query: mutationCreateBroadcastDisplay,
			variables: {
				broadcastId: broadcastId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastDisplayInfo(namespaceId, broadcastDisplayId, input)
	{
		let operation = {
			query: mutationSetBroadcastDisplayInfo,
			variables: {
				broadcastDisplayId: broadcastDisplayId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	deleteBroadcastDisplay(namespaceId, broadcastDisplayId)
	{
		let operation = {
			query: mutationDeleteBroadcastDisplay,
			variables: {
				broadcastDisplayId: broadcastDisplayId,
			}
		};

		return this.connection.makeRequest(operation);
	}

	deleteBroadcastRecording(namespaceId, broadcastRecordingId)
	{
		let operation = {
			query: mutationDeleteBroadcastRecording,
			variables: {
				broadcastRecordingId: broadcastRecordingId,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastEncodingConfigurationVideo(namespaceId, encodingConfigurationVideoId, input)
	{
		let operation = {
			query: mutationSetBroadcastEncodingConfigurationVideo,
			variables: {
				encodingConfigurationVideoId: encodingConfigurationVideoId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	setBroadcastEncodingConfigurationAudio(namespaceId, encodingConfigurationAudioId, input)
	{
		let operation = {
			query: mutationSetBroadcastEncodingConfigurationAudio,
			variables: {
				encodingConfigurationAudioId: encodingConfigurationAudioId,
				input: input,
			}
		};

		return this.connection.makeRequest(operation);
	}

	queryBroadcastProviderStreamTargets(namespaceId, providerKeyId)
	{
		let operation = {
			query: queryFetchBroadcastProviderStreamTargets,
			variables: {
				providerKeyId: providerKeyId,
			}
		};

		return this.connection.makeRequest(operation);
	}

	queryBroadcastProviderStreamTarget(namespaceId, providerKeyId, providerId, providerType)
	{
		let operation = {
			query: queryFetchBroadcastProviderStreamTarget,
			variables: {
				providerKeyId: providerKeyId,
				providerId: providerId,
				providerType: providerType
			}
		};

		return this.connection.makeRequest(operation);
	}

	mutationCreateProviderKey(namespaceId, input)
	{
		let operation = {
			query: mutationCreateProviderKey,
			variables: {
				namespaceId: namespaceId,
				input: input
			}
		};

		return this.connection.makeRequest(operation);
	}

	mutationDeleteProviderKey(namespaceId, providerKeyId)
	{
		let operation = {
			query: mutationDeleteProviderKey,
			variables: {
				providerKeyId: providerKeyId,
			}
		};

		return this.connection.makeRequest(operation);
	}

	getBroadcastAccessKeys()
	{
		let operation = {
			query: queryGetBroadcastAccessKeys,
			variables: {
			}
		};

		return this.connection.makeRequest(operation);
	}

	mutationSetSlateOn(broadcastId,slateName)
	{
		let operation = {
			query: mutationSetSlateOn,
			variables: {
				broadcastId: broadcastId,
				slateName: slateName
			}
		};

		return this.connection.makeRequest(operation);
	}
	mutationSetSlateOff(broadcastId)
	{
		let operation = {
			query: mutationSetSlateOff,
			variables: {
				broadcastId: broadcastId
			}
		};

		return this.connection.makeRequest(operation);
	}


};

export default YetiGraphQLRequester
