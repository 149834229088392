import React, { Component } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

import './EncoderRollbackDialog.css';
import ClearCasterStore, {ActionTypes} from "../../model/ClearCasterStore";


class EncoderRollbackDialog extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			update:null,
			updating:false
		};

		this.close = this.close.bind(this);
		this.applyUpdate = this.applyUpdate.bind(this);
	}

	setUpdate(device)
	{
		// let update = null;
		// if (device !== null &&
		// 	typeof device !== 'undefined' &&
		// 	device.localVersions !== null &&
		// 	typeof device.localVersions !== 'undefined' &&
		// 	device.status &&
		// 	typeof device.status.versionCurrent !== 'undefined'
		// )
		// {
		// 	update = this.props.controller.getRollback(device.localVersions,device.status.versionCurrent);
		// }
		// let state = this.state;
		// state.update = update;
		// this.setState(state);
	}


	close(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_UPDATE_ENCODER,
			dialogShowing:false
		});
	}

	async applyUpdate(event)
	{
		let localVersions = this.props.clearcaster.updateEncoder.localVersions;
		let updateStr = undefined;
		for (let i = 0; i < localVersions.clearcaster.length; i++)
		{
			if (localVersions.clearcaster[i].v.indexOf(this.props.clearcaster.updateEncoder.rollbackVersion) === 0)
			{
				updateStr = JSON.stringify(localVersions.clearcaster[i]);
				console.log(updateStr);
				updateStr = btoa(updateStr);
				console.log(updateStr);
				break;
			}
		}

		if (updateStr != null)
		{
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_UPDATE_ENCODER,
				dialogShowing:true,
				updating:true
			});
			await this.props.controller.updateEncoderWithUpdateString(this.props.clearcaster.updateEncoder.encoder.id,updateStr);
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_UPDATE_ENCODER,
				dialogShowing:false,
				updating:false
			});
		}
	}

	render()
	{
		let encoder = this.props.clearcaster.updateEncoder.encoder;

		if (this.props.clearcaster.updateEncoder.dialogShowing !== true || this.props.clearcaster.updateEncoder.rollback !== true)
			return null;

		return (
			<Modal show={this.props.clearcaster.updateEncoder.dialogShowing === true && this.props.clearcaster.updateEncoder.rollback === true} onShow={this.onShow} onHide={this.close}>
				<Form>
					<Modal.Header closeButton>
						<Modal.Title>{ this.props.strings.app.RollbackDialogTitle }</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group>
							{ this.props.strings.app.RollbackDialogPrompt1 }<br />
							<br />
							<strong>"{ encoder.name }"</strong> { this.props.strings.app.RollbackDialogPrompt2 }<br />
							<br />
							<div className="rollback-panel">
								<div className="current-version">{ this.props.strings.app.CurrentVersionColon }<br />{ encoder.encoderStatus.clearCasterSoftwareVersion }</div>
								<div className="rollback-icon"><i className="fa fa-angle-double-right fa-3x" /></div>
								<div className="rollback-version">{ this.props.strings.app.RollbackToVersion }<br />{this.props.clearcaster.updateEncoder.rollbackVersion}</div>
							</div>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button id="dialog-rollback-cancel-button" variant={'light'} onClick={this.close}>{ this.props.strings.app.Cancel }</Button>&nbsp;
						<Button id="dialog-rollback-applyupdate-button" onClick={this.applyUpdate}>{ this.props.strings.app.RollbackBegin }</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		);
	}
}

export default EncoderRollbackDialog;
