import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { Button } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import Moment from 'react-moment';
import moment from 'moment';
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

class ManageRecordings extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			timer:null
		};
		this.getBroadcastGoLiveEvents = this.getBroadcastGoLiveEvents.bind(this);
		this.downloadRecordingAsset = this.downloadRecordingAsset.bind(this);
	}

	getBroadcastGoLiveEvents()
	{
		let _this = this;
		if (this.state.timer !== null)
		{
			clearTimeout(this.state.timer);
		}
		this.props.controller.getBroadcastGoLiveEvents();
		let newTimer = setTimeout(()=>{
			_this.getBroadcastGoLiveEvents();
		},(10 * 1000));
		_this.setState({timer:newTimer});
	}


	componentDidMount()
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_BROADCAST_GO_LIVE_EVENTS_DOWNLOADING,
			downloading:[]
		});
		if (this.props.clearcaster.userWowza.namespaceId !== null)
		{
			this.getBroadcastGoLiveEvents();
		}
	}

	componentWillUnmount() {
		if (this.state.timer !== null)
		{
			clearTimeout(this.state.timer);
			this.setState({timer:null});
		}
	}

	async componentDidUpdate(prevProps)
	{
		// get golive events, but make sure we have a namespaceId first
		if (this.props.clearcaster.userWowza.namespaceId !== null &&
			this.state.timer === null &&
			!this.props.clearcaster.broadcastGoLiveEventsLoading)
		{
			this.getBroadcastGoLiveEvents();
		}
	}

	downloadRecordingAsset(recordingAsset,downloadName)
	{
		return async (evt) => {
			let downloading = this.props.clearcaster.broadcastGoLiveEventsDownloading;
			if (downloading.indexOf(recordingAsset.id) === -1)
			{
				downloading.push(recordingAsset.id);
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_BROADCAST_GO_LIVE_EVENTS_DOWNLOADING,
					downloading:downloading
				});
			}
			let asset_url = evt.target.attributes.url.value;
			let auth_header = this.props.controller.graphQLConnection.getAuthHeader();
			let response = await fetch(asset_url, {
				method: 'GET',
				headers: auth_header
			});
			let blob = await response.blob();
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement('a');
			a.href = url;
			a.download = downloadName + asset_url.substring(asset_url.lastIndexOf('.'));
			document.body.appendChild(a);
			a.click();
			a.remove();
			downloading = this.props.clearcaster.broadcastGoLiveEventsDownloading;
			if (downloading.indexOf(recordingAsset.id) >= 0)
			{
				downloading.splice(downloading.indexOf(recordingAsset.id),1);
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_BROADCAST_GO_LIVE_EVENTS_DOWNLOADING,
					downloading:downloading
				});
			}
		};
	}

	render()
	{
		if (this.props.clearcaster.broadcastGoLiveEvents.length === 0 && this.props.clearcaster.broadcastGoLiveEventsLoading)
		{
			return (
				<div className="row">
					<div className="col-md-6">
						Loading...
					</div>
				</div>
			);
		}
		// filter goliveevents with recordings
		let recordingEvents = [];
		for (let i = 0; i < this.props.clearcaster.broadcastGoLiveEvents.length; i++)
		{
			let goLiveEvent = this.props.clearcaster.broadcastGoLiveEvents[i];
			if (goLiveEvent.recordingAssets != null && goLiveEvent.recordingAssets.length > 0 && goLiveEvent.recordingAssets[0] != null)
			{
				goLiveEvent.startTime = new Date(goLiveEvent.timeGoLive);
				// Filter out recordings longer than we've been shipping ClearCasters.
				// startTimes of 0 signal something that never went live properly.
				// And $10 says it's a Facebook Paired broadcast.
				if (goLiveEvent.startTime < new Date('2018-01-01'))
					continue;
				goLiveEvent.endTime = new Date(goLiveEvent.timeStop);
				goLiveEvent.humanTime = <span><Moment format="YYYY-MM-DD" date={goLiveEvent.startTime} /><span> @ </span><Moment format="h:mma" date={goLiveEvent.startTime} /></span>;
				goLiveEvent.duration = moment.duration(goLiveEvent.endTime - goLiveEvent.startTime);
				goLiveEvent.durationHumanTime = goLiveEvent.duration.humanize();
				goLiveEvent.downloadFilename = goLiveEvent.title + '-' + moment(goLiveEvent.startTime).format('YYYY-MM-DD-hhmm');

				goLiveEvent.downloadLinks = <div>
					{goLiveEvent.recordingAssets.map((ra,k1)=>{
						return (
						ra.status === undefined || ra.status === null || ra.status === 'FINISHED' ?
						<div key={k1}>
							{this.props.clearcaster.broadcastGoLiveEventsDownloading.indexOf(ra.id) >= 0 ?
								<div className='mb-2'>
									<Button disabled={true} variant="outline-secondary" size="sm" style={{width:'150px'}}>
										Downloading <div style={{display:'inline-block'}}><PulseLoader sizeUnit="px" size="5"></PulseLoader></div>
									</Button>
								</div>
								:
								ra.urls.map((u,k2)=>{return (
									<div key={k2} className='mb-2'>
										<Button id={u + "_download"} size="sm" style={{width:'150px'}} url={u} onClick={this.downloadRecordingAsset(ra,goLiveEvent.downloadFilename)}  target="_blank" download>Download</Button>
									</div>
								);})
							}
						</div>
							:
						<div key={k1}>
							<Button disabled={true} variant="outline-secondary" size="sm" style={{width:'150px'}}>
								Uploading <div style={{display:'inline-block'}}><PulseLoader sizeUnit="px" size="5"></PulseLoader></div>
							</Button>
						</div>
					);})}
				</div>;

				recordingEvents.push(goLiveEvent);
			}
		}

		if (recordingEvents.length === 0)
		{
			return (
				<div className="row">
					<div className="col-md-6">
						No recordings available.
					</div>
				</div>
			);
		}

		return (
			<div className="row mt-3">
				<div className="col">
					<ReactTable data={recordingEvents}
											columns={[
												{Header:this.props.strings.app.ArchiveSearchTableBroadcastName,
													accessor:'title',
													className:'d-flex flex-column'
												},
												{Header:this.props.strings.app.ArchiveSearchTableDateTime,
													accessor:'timeGoLive',
													className:'d-flex flex-column',
													Cell: c => <span>{c.original.humanTime}</span>
												},
												{Header:this.props.strings.app.ArchiveSearchTableDuration,
													id:'duration',
													accessor: d => {return d.duration._milliseconds;},
													className:'d-flex flex-column',
													Cell: c => <span>{c.original.durationHumanTime}</span>
												},
												{Header:"Download",
													accessor:'id',
													sortable:false,
													Cell: c => <span>{c.original.downloadLinks}</span>
												}
											]}
											pageSizeOptions={[ 20, 50, 100]}
											defaultPageSize={20}
					/>
				</div>
			</div>
			);
	}
}

export default ManageRecordings;
