import { gql } from '@apollo/client'

export const commandUpdateEncoder = gql`
mutation sendEncoderCommandDeviceStartSoftwareUpdate($encoderId:ID!) {
	sendEncoderCommand(encoderId: $encoderId,
		command:"{\\"command\\":\\"devicestartsoftwareudpate\\"}") {
		code
		correlationId
	}
}
`

export const commandGeneric = gql`
mutation sendEncoderCommandGeneric($encoderId:ID!,$command:JSON!) {
	sendEncoderCommand(encoderId: $encoderId, command:$command) {
		code
		correlationId
	}
}
`

export const commandRestartEncoder = gql`
mutation sendEncoderCommandDeviceReboot($encoderId: ID!) {
	sendEncoderCommand(encoderId: $encoderId,
		command:"{\\"command\\":\\"devicereboot\\"}") {
		code
		correlationId
	}
}
`

export const commandSendEncoderLogs = gql`
mutation sendEncoderCommandDeviceExportLogData($encoderId: ID!) {
	sendEncoderCommand(encoderId: $encoderId,
		command:"{\\"command\\":\\"deviceexportlogdata\\"}") {
		code
		correlationId
	}
}
`

export const commandDeviceGetLocalVersions = gql`
mutation sendEncoderCommandDeviceGetLocalVersions($encoderId: ID!) {
	sendEncoderCommand(encoderId: $encoderId,
		command:"{\\"command\\":\\"devicegetlocalversions\\"}") {
		code
		correlationId
	}
}
`
