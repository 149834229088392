import React, { Component } from 'react';

import { Button, Table } from 'react-bootstrap'
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

import WSCAccessKeyConfirmDeleteDialog from './WSCAccessKeyConfirmDeleteDialog';
import WSCAccessKeyCreateDialog from './WSCAccessKeyCreateDialog';

class ManageIntegrationsWSC extends Component
{
	constructor() {

		super();

		this.addAPIKey = this.addAPIKey.bind(this);
		this.renderTableBody = this.renderTableBody.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
	}

	addAPIKey()
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_WSCACCESSKEYCREATE_DIALOG
		});
	}

	deleteRow(evt)
	{
		let accessKeyId = evt.target.id.split('-')[1];

		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_WSCACCESSKEYCONFIRMDELETE_DIALOG,
			wscAccessKeyInfo: {accessKeyId: accessKeyId}
		});
	}


  providerKeysWSC()
	{
  	return this.props.clearcaster.namespaceProviderKeys.filter((key) => { return key.type === "WSC_PUBLIC_API"})
  }

	renderTableBody()
	{
		let elements;
		for(const providerKey of this.providerKeysWSC())
		{
			elements = [elements, <tr key={ providerKey.id }><td>{ providerKey.name }</td><td>{ providerKey.accessKey.substr(0, 6)+"..." } </td><td style={{textAlign:"center"}}><i id={ 'delete-'+providerKey.id } onClick={ this.deleteRow } className='integration-icon-button fa fa-trash integration-icon'></i></td></tr>];
		}

		return <tbody>{ elements }</tbody>;
	}

	render()
	{
		return (<div>
			<WSCAccessKeyConfirmDeleteDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />
			<WSCAccessKeyCreateDialog strings={this.props.strings} log={this.props.log} clearcaster={this.props.clearcaster} controller={this.props.controller} />

			<div className="integrations-header">
				<img src="/images/integrations-wsc.png" className="integration-header-image" alt="Wowza Streaming Cloud Integrations" />
			</div>
			<div className="integrations-add">
				<Button variant="primary" onClick={ this.addAPIKey }>
					<i className="fa fa-plus-circle"></i> {this.props.strings.app.AddWSCAPIKey}
				</Button>
			</div>
			<div className="integrations-table">
				<Table striped bordered>
					<thead>
						<tr>
							<th>Name</th>
							<th>Access Key</th>
							<th style={{textAlign:"center"}}>Delete</th>
						</tr>
					</thead>
					{ this.renderTableBody() }
				</Table>
			</div>
		</div>);
	}
}

export default ManageIntegrationsWSC;
