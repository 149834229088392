import React, { Component } from 'react';
import FacebookConnectionState from '../../../model/FacebookConnectionState';
import { FacebookLogin, FacebookLogout } from '../../../controller/FacebookController';
import { Form, Button } from 'react-bootstrap';
import ClearCasterStore, { ActionTypes } from '../../../model/ClearCasterStore';

class FacebookPairAccount extends Component
{
	loginFacebook (controller) {
		return async (evt) => {
			let facebookUser = await FacebookLogin();
			if (facebookUser != null)
				this.props.controller.getFacebookActiveBroadcasts(facebookUser);
		}
	}
	logoutFacebook (evt) {
		evt.preventDefault();
		FacebookLogout();
	}
	cancelEdit (facebookData) {
		return (evt) => {
			let newFacebookData = {...facebookData,ui:{...facebookData.ui,state:'ready'}};
			ClearCasterStore.dispatch({
				type:ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA,
				facebookData:newFacebookData
			});
		}
	}
	render () {
		if (this.props.userFacebook.facebookConnected === FacebookConnectionState.CONNECTED)
		{
			return (
				<div className="mb-2">
					<label className="form-label">Pair with Facebook Account</label><br />
					<div className="d-flex">
						<div className="mr-2">
							<img src={this.props.userFacebook.picture.data.url} alt="" height="50" width="50"/>
						</div>
						<div>
							You are connected to Facebook as <strong>{this.props.userFacebook.name}</strong>.<br />
							{ this.props.userFacebook.facebookConnectedOnLoad ?
								<>
									To pair with a different account, log out from <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">www.facebook.com</a>.
								</>
							:
								<button className={'btn btn-link text-primary'} onClick={this.logoutFacebook}>Unpair account</button>
							}
						</div>
					</div>
				</div>
			)
		}
		else if (this.props.showCancel === true)
		{
			return (
				<Form.Group>
					<div className="mb-2">
						<label className="form-label">Pair with Facebook Account</label><br />
						<div className="text-right">
							<Button variant={'outline-secondary'} className="mr-2"
									style={{width: '125px'}}
									onClick={this.props.cancelEdit}>
								Cancel
							</Button>
							<Button style={{width: '125px'}} onClick={this.loginFacebook(this.props.controller)}>Pair Account</Button>
						</div>
					</div>
				</Form.Group>
			);
		}
		else
		{
			return (
				<Form.Group>
					<div className="d-flex justify-content-between">
						<div>
							<Form.Label>Pair with Facebook Account</Form.Label><br />
						</div>
						<div>
							<Button style={{width: '125px'}} onClick={this.loginFacebook(this.props.controller)}>Pair Account</Button>
						</div>
					</div>
				</Form.Group>
			);
		}
	}
}
export default FacebookPairAccount;