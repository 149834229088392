import { gql } from '@apollo/client'

export const queryMe = gql`
query me {
	me {
		user {
			firstName
			lastName
			email
			namespaceUsers {
				roles
				id
				admin
				namespace {
					id
				}
			}
		}
	}
}
`

export const queryGraphQLVersion = gql`
query release {
	build
	version
}
`

export const queryAllEncoders = gql`
query allEncoders {
	allEncoders {
		id
		name
	}
}
`

export const queryEncoderCommandStatus = gql`
query encoderCommandStatus($correlationId:String!) {
	encoderCommandStatus(correlationId:$correlationId) {
		message
		commandResponse
		status
	}
}
`

export const queryFetchNamespaceExtraProperties = gql`
query fetchNamespaceExtraProperties {
	allNamespaces {
		id
		name
		extraProperties {
			name
			value
		}
	}
}
`


export const queryFetchBroadcastProviderStreamTargets = gql`
query broadcastProviderStreamTargets($providerKeyId:ID!) {
	broadcastProviderStreamTargets(providerKeyId:$providerKeyId) {
		type
		providerId
		providerType
		providerName
  }
}
`

export const queryFetchBroadcastProviderStreamTarget = gql`
query broadcastProviderStreamTarget($providerKeyId:ID!, $providerId:ID!, $providerType:ENUM_STREAMTARGET_PROVIDERTYPE!) {
	broadcastProviderStreamTarget(providerKeyId:$providerKeyId, providerId:$providerId, providerType:$providerType) {
		type
		protocol
		providerType
		providerId
		providerName
		url
		streamName
		username
		password
  }
}
`
export const queryGetBroadcastsByStatus = gql`
query queryGetBroadcastsByStatus($namespaceId: ID!,$status:[ENUM_BROADCASTSTATUS]!) {
	broadcastsByStatus(namespaceId:$namespaceId, status:$status) {
		name
		id
		nearRealTimeData {
			eventTime
			eventId
			encoder {
				outputs {
					id
					videoBitrate
					videoBitrateCurrent
					videoBitrateMeasure
				}
				videoDecoderCaptureInfo {
					droppedFrames
					frameSyncCount
				}
				captureSessionInfo {
					videoDroppedFrames
					temperature
				}
			}
			encoderState
			streamTargets {
				id
				url
				pingTime
				pushpubConnectLastAttempt
				pushpubConnectLastSuccess
				pushpubConnectAttemptCount
				ssNetworkInfo {
					rtt
				}
				srtNetworkInfo {
					msRTT
				}
			}
			hardwareDynamic {
				cpuTemperature
				cpuLoadSystem
				cpuLoadProcess
				cpuCoreInfo {
					coreThrottleCount
					packageThrottleCount
				}
			}
		}
	}
}
`

//cfg turned this into a string so can add fields dynamically
export const queryNearRealTimeDataByBroadcast = `
query nearRealTimeDataByBroadcast($broadcastId: ID!, $startDateTime:Long, $endDateTime: Long) {
	nearRealTimeDataByBroadcast(broadcastId: $broadcastId, startDateTime: $startDateTime, endDateTime: $endDateTime, order: "asc") {
		eventTime
		eventId
		encoder {
			outputs {
				id
				videoBitrate
				videoBitrateCurrent
				videoBitrateMeasure
			}
			videoDecoderCaptureInfo {
				droppedFrames
				frameSyncCount
			}
			captureSessionInfo {
				videoDroppedFrames
				temperature
			}
		}
		encoderState
		streamTargets {
			id
			url
			pingTime
			pushpubConnectLastAttempt
			pushpubConnectLastSuccess
			pushpubConnectAttemptCount
			ssNetworkInfo {
				rtt
			}
			srtNetworkInfo {
				msRTT
			}
		}
    hardwareDynamic {
      cpuTemperature
      cpuLoadSystem
      cpuLoadProcess
      cpuCoreInfo {
      	coreThrottleCount
      	packageThrottleCount
      }
    }
	}
}
`

//cfg turned this into a string so can add fields dynamically
export const queryNearRealTimeArchiveDataByBroadcast = `
query nearRealTimeDataByBroadcast($broadcastId: ID!, $startDateTime: Long, $endDateTime: Long) {
	nearRealTimeDataByBroadcast(broadcastId: $broadcastId, startDateTime: $startDateTime, endDateTime: $endDateTime, order: "asc") {
    eventTime
    eventId
    encoder {
      captureSessionInfo {
        videoInput
        videoDroppedFrames
        videoFrameWidth
        videoFrameHeight
        videoFrameRateEnum
        videoFrameRateDen
        videoFrameRateDivisor
        videoProgressive
        videoKeyFrameIntervalAdjusted
        audioSampleRate
        audioChannels
        temperature
      }
			videoDecoderCaptureInfo {
				droppedFrames
				frameCountTotal
				frameSyncCount
			}
      outputs {
        id
        videoBitrate
        videoBitrateCurrent
        videoBitrateMeasure
        videoFrameSizeWidth
        videoFrameSizeHeight
        videoCodec
        videoCodecVersion
        videoKeyFrameInterval
        audioCodec
        audioCodecVersion
        audioChannels
        audioSampleRate
      }
    }
    sessionInfo {
      publishSession {
        broadcastId
        title
        timeGoLive
      }
    }
    streamTargets {
      id
      outputId
      url
      pingTime
		pushpubConnectLastAttempt
		pushpubConnectLastSuccess
		pushpubConnectAttemptCount
			ssNetworkInfo {
				rtt
			}
			srtNetworkInfo {
				msRTT
			}
    }
    hardwareDynamic {
      cpuTemperature
      cpuLoadSystem
      cpuLoadProcess
      cpuCoreInfo {
      	coreThrottleCount
      	packageThrottleCount
      }
    }
  }
}
`

// cfg- - turned this into string so I can do late substitutions based on api version
export const queryFetchPollingData = `
query fetchPollingData {
	encoders: allEncoders {
		id
		name
		deviceId
		serial
		modelInfo {
			model
		}
		namespace { id name }
		createdAt
		updatedAt
		encoderAssetsUrl
		logFiles {
			id
			url
			createdAt
			updatedAt
		}
		encoderStatus {
			status
			broadcastId
			localIpV4Address
			updatedAt
			clearCasterSoftwareVersion
			clearCasterHardwareVersion
			clearCasterSerialNumber
			availableSoftwareUpdateVersion
			availableSlateNames
			activeSlateName
		}
		captureSessionInfo {
			videoInput
			videoDroppedFrames
			videoFrameCountModeSwitch
			videoLastFrameIndex
			videoFrameWidth
			videoFrameHeight
			videoLastFrameWidth
			videoLastFrameHeight
			videoProgressive
			videoFrameRateDivisor
			videoFrameRateEnum
			videoFrameRateDen
			videoVANCPacketsModeSwitch
			videoVANCCEA708PacketsModeSwitch
			videoLine21CEA708PacketsTotal
			videoLine21CEA708PacketsModeSwitch
			audioFrameCountModeSwitch
			audioSampleRate
			audioChannels
		}
		activeBroadcast { id }
		nearRealTimeData {
			eventId
			eventTime
			sessionInfo {
				publishSession {
					broadcastId
				}
			}
			hardwareDynamic {
				cpuLoadSystem
				cpuTempurature
				cpuCoreInfo {
					coreThrottleCount
					packageThrottleCount
				}
				memoryPhysicalFree
				memoryPhysicalAvailable
				memorySwapFree
				networkInfo {
					name
					statsRXBytes
					statsRXErrors
					statsRXDropped
					statsTXBytes
					statsTXErrors
					statsTXDropped
				}
			}
			streamTargets {
				id
				outputId
				url
				pushpubConnectLastAttempt
				pushpubConnectLastSuccess
				pushpubConnectAttemptCount
				forceReconnectCount
				ioSessionScheduledWriteBytes
				ioSessionScheduledWriteBytesToZero
				pingTime
				ssNetworkInfo {
					rtt
					rttvar
					min_rtt
					cwnd
					pacing_rate
					pacing_rate_max
					delivery_rate
					retrans
					retrans_total
					lost
					not_sent
					send_bps
					bbr_bw
					bbr_min_rtt
					bbr_pacing_gain
					bbr_cwnd_gain
				}
				srtNetworkInfo {
					msRTT
				}
			}
			encoder {
				captureSessionInfo {
					videoLastTimecode
					videoDroppedFrames
					audioLastTimecodeMS
					audioLastLevelDataPeakLeft
					temperature
				}
				captureSessionAudioLevelData {
					averageLeft
					averageRight
					peakLeft
					peakRight
				}
				videoDecoderCaptureInfo {
					droppedFrames
					frameSyncCount
				}
				outputs {
					id
					videoBitrate
					videoBitrateCurrent
					videoBitrateMeasure
					audioBitrate
					audioBitrateMeasure
					lastVideoFrameTimecode
					lastAudioFrameTimecodeMS
				}
			}
		}
		extraProperties { id name value type }
	}

	broadcasts: allBroadcasts {
		id
		name
		namespace { id name }
		createdAt
		updatedAt
		previewedAt
		liveAt
		stoppedAt
		status
		input {
			id
			tag
			inputType
			videoInput
			videoFrameWidthMax
			videoFrameHeightMax
			videoFrameRateMax
			videoKeyFrameIntervalMilliseconds
			videoAspectRatioMode
			videoAspectRatioMethod
			videoAspectRatioWidth
			videoAspectRatioHeight
			videoAspectRatioRotation
			audioLevel
			audioChannelLeft
			audioChannelRight
			overlayVendor
			overlayUrl
			mediaCasterType
			mediaCasterStreamInfo
			slateOverrideName
			audioMuted
		}
		outputs {
			id
			tag
			streamName
			encodingConfiguration {
				name
				encodingConfigurationVideo {
					id
					codec
					implementation
					frameSizeFitMode
					frameSizeWidth
					frameSizeHeight
					profile
					bitrate
					bitrateMin
					autoAdjustBitrate
					keyFrameIntervalFollowSource
					keyFrameInterval
					parameters { name value type }
				}
				encodingConfigurationAudio {
					id
					codec
					bitrate
					parameters { name value type }
				}
			}
			streamTargets {
				id
				tag
				url
				enable
				streamName
				username
				password
				protocol
				streamTargetEncoderIndex
				type
				adaptiveGroupName
				maxLatency
				providerType
				providerId
				providerName
				providerKey { id }
			}
			recordings {
				id
				tag
				format
				maxSize
				transportType
			}
		}
		displays {
			id
			name
			type
			url
		}
		broadcastEncoders { id encoder { id } streamTargetEncoderIndex }
		transcodingConfiguration {
			postProcessing {
				sortBufferEnable
				sortBufferSize
				sortBufferFlushInterval
			}
			decodingVideo {
				deinterlace
				implementation
			}
			scaling {
				implementation
				flags
			}
		}
		extraProperties { id name value type }
	}

	namespaces: allNamespaces {
		id
		name
		providerKeys {
			id
			type
			name
			accessKey
    	}
	}
}
`

// cfg- - turned this into string so I can do late substitutions based on api version
export const queryBroadcastGoLiveEvents = `
query broadcastGoLiveEventsByNamespace($namespaceId: ID!) {
	broadcastGoLiveEventsByNamespace(namespaceId: $namespaceId) {
    broadcastId
    broadcastUniqueId
    title
    timeGoLive
    timeStop
    encoderIds
    recordingAssets {
    	id
     	urls
    	status
   }
  }
}
`

export const queryNamespaceAccessKeys = gql`
query namespaceAccessKeys($namespaceId: ID!) {
  namespace(id: $namespaceId) {
    accessKeys {
      id
      name
      createdAt
      updatedAt
    }
  }
}
`

export const queryNamespaceUsers = gql`
    query namespace($namespaceId: ID!) {
        namespace(id: $namespaceId) {
            namespaceUsers {
				id
                firstName
                lastName
                email
				roles
				admin
            }
        }
    }
`

export const queryGetBroadcastAccessKeys = gql`
query getBroadcastAccessKeys {
	allBroadcasts {
		id
		accessKeys {
			id
			name
			createdAt
			updatedAt
			expiration
		}
	}
}
`
