import React, { Component } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import LoadingOverlay from "react-loading-overlay";

class EncoderUpdateDialog extends Component
{

	constructor(props)
	{
		super(props);

		this.close = this.close.bind(this);
		this.applyUpdate = this.applyUpdate.bind(this);
	}

	close(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_UPDATE_ENCODER,
			dialogShowing:false
		});
	}

	async applyUpdate(event)
	{
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_UPDATE_ENCODER,
			dialogShowing:true,
			updating:true
		});
		await this.props.controller.updateEncoder(this.props.clearcaster.updateEncoder.encoder.id);
		ClearCasterStore.dispatch({
			type:ActionTypes.SET_UPDATE_ENCODER,
			dialogShowing:false,
			updating:false
		});
	}

	render()
	{
		let encoder = this.props.clearcaster.updateEncoder.encoder;
		if (encoder == null)
		{
			return null;
		}
		let currentVersion = this.props.clearcaster.updateEncoder.encoder.encoderStatus.clearCasterSoftwareVersion;
		let updateVersion = this.props.clearcaster.updateEncoder.encoder.encoderStatus.availableSoftwareUpdateVersion;
		let currentDeviceName = this.props.clearcaster.updateEncoder.encoder.name;
		let prompt = this.props.strings.app.UpdateAvailablePrompt({currentVersion:currentVersion,newVersion:updateVersion});

		return (
			<Modal show={this.props.clearcaster.updateEncoder.dialogShowing && this.props.clearcaster.updateEncoder.rollback === false} onHide={this.close}>
				<LoadingOverlay active={ this.props.clearcaster.updateEncoder.updating } spinner text={ this.props.strings.app.UpdatingClearCaster({name:currentDeviceName}) } >
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>{ this.props.strings.app.UpdateAvailable }: { currentDeviceName }</Modal.Title>
						</Modal.Header>
						<Modal.Body>
								{ prompt }
						</Modal.Body>
						<Modal.Footer>
							<Button id="dialog-updates-cancel-button" variant={'light'} onClick={this.close}>{ this.props.strings.app.Cancel }</Button>&nbsp;
							<Button id="dialog-updates-applyupdate-button" variant={'primary'} onClick={this.applyUpdate}>{ this.props.strings.app.UpdateApply }</Button>
						</Modal.Footer>
					</Form>
				</LoadingOverlay>
			</Modal>
		);
	}
}

export default EncoderUpdateDialog;
