import { gql } from '@apollo/client'

export const mutationPreflightActivateEncoder = gql`
mutation preflightActivateEncoder($namespaceId:ID!, $input:EncoderActivateInput!) {
	preflightEncoderCanBeAddedToNamespace(namespaceId:$namespaceId,input:$input) {
		encoderId
  	canBeAdded
  	hardwareType
	}
}
`

export const mutationActivateEncoder = gql`
mutation activateEncoder($namespaceId:ID!, $input:EncoderActivateInput!) {
	activateEncoder(namespaceId:$namespaceId,input:$input) {
		id
	}
}
`

export const mutationDeactivateEncoder = gql`
mutation deactivateEncoder($encoderId:ID!) {
  deactivateEncoder(encoderId:$encoderId) {
    success
  }
}
`

export const mutationRenameEncoder = gql`
mutation renameEncoder($encoderId:ID!,$name:String!) {
  renameEncoder(
    encoderId:$encoderId,
    name:$name
  ) {
    id
    name
  }
}`

export const mutationSetEncoderEncoderAssetInfo = gql`
mutation setEncoderEncoderAssetInfo($encoderId:ID!$input:EncoderAssetInput!) {
	setEncoderEncoderAssetInfo (
		encoderId:$encoderId,
		input:$input
	) {
		id
		name
		encoderAssetsUrl
		encoderAssetsName
		encoderAssetsVendor
		encoderAssetsVersion
	}
}
`

export const mutationCreateProviderKey = gql`
mutation createProviderKey($namespaceId:ID!, $input: ProviderKeyInput!) {
	createProviderKey(
		namespaceId:$namespaceId,
		input:$input
		) {
		id
	}
}
`

export const mutationDeleteProviderKey = gql`
mutation deleteProviderKey($providerKeyId:ID!) {
	deleteProviderKey(
		id:$providerKeyId) {
		success
	}
}
`

export const mutationSetNamespaceUserPrivledges = gql`
	mutation setNamespaceUserPrivledges($namespaceUserId: ID!, $input: NamespaceUserPrivledgesInput!) {
		setNamespaceUserPrivledges(namespaceUserId: $namespaceUserId, input: $input) {
			id
			firstName
			lastName
			email
			roles
			admin
		}
	}
`

export const mutationCreateAccessKey = gql`
mutation createAccessKey($identityId: ID!, $name: String!) {
	createAccessKey(identityId: $identityId, name: $name) {
    id
    secret
    name
    createdAt
    updatedAt
	}
}
`

export const mutationCreateAccessKey2 = gql`
mutation createAccessKey($identityId: ID!, $input: AccessKeyInput!) {
	createAccessKey(identityId: $identityId, name:"", input: $input) {
    id
    secret
    name
    createdAt
    updatedAt
    expiration
	}
}
`

export const mutationDeleteAccessKey = gql`
mutation deleteAccessKey($id: String!) {
	deleteAccessKey(id:$id) {
		success
	}
}
`


export const mutationCreateBroadcast = gql`
mutation createBroadcast($namespaceId: ID!, $input: BroadcastCreateInput!) {
	createBroadcast(namespaceId: $namespaceId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastExtraProperty = gql`
mutation setBroadcastExtraProperty($broadcastId: ID!, $input: ExtraPropertyInput!) {
	setBroadcastExtraProperty(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetNamespaceExtraProperty = gql`
mutation setNamespaceExtraProperty($namespaceId: ID!, $input: ExtraPropertyInput!) {
	setNamespaceExtraProperty(namespaceId: $namespaceId, input: $input) {
		id
	}
}
`

export const mutationSetNamespaceExtraPropertyOverlayGraphicsData = gql`
mutation setNamespaceExtraPropertyOverlayGraphicsData($namespaceId: ID!) {
	setNamespaceExtraPropertyOverlayGraphicsData(namespaceId: $namespaceId) {
		id
	}
}
`

export const mutationCreateBroadcastOutput = gql`
mutation createBroadcastOutput($broadcastId: ID!, $input: BroadcastOutputInput!) {
	createBroadcastOutput(broadcastId: $broadcastId, input: $input) {
		id
		encodingConfiguration {
			encodingConfigurationVideo { id }
			encodingConfigurationAudio { id }
		}
	}
}
`

export const mutationCreateBroadcastRecording = gql`
mutation createBroadcastRecording($broadcastOutputId: ID!, $input: BroadcastRecordingInput!) {
	createBroadcastRecording(broadcastOutputId: $broadcastOutputId, input: $input) {
		id
	}
}
`



export const mutationCreateBroadcastStreamTarget = gql`
mutation createBroadcastStreamTarget($broadcastOutputId: ID!, $input: BroadcastStreamTargetInput!) {
	createBroadcastStreamTarget(broadcastOutputId: $broadcastOutputId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastTranscodingConfigurationScalingInfo = gql`
mutation setBroadcastTranscodingConfigurationScalingInfo($broadcastId: ID!, $input: TranscodingConfigurationScalingInfo!) {
	setBroadcastTranscodingConfigurationScalingInfo(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`
export const mutationSetBroadcastTranscodingConfigurationPostProcessingInfo = gql`
mutation setBroadcastTranscodingConfigurationPostProcessingInfo($broadcastId: ID!, $input: TranscodingConfigurationPostProcessingInfo!) {
	setBroadcastTranscodingConfigurationPostProcessingInfo(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`
export const mutationSetBroadcastTranscodingConfigurationDecodingVideoInfo = gql`
mutation setBroadcastTranscodingConfigurationDecodingVideoInfo($broadcastId: ID!, $input: TranscodingConfigurationDecodingVideoInfo!) {
	setBroadcastTranscodingConfigurationDecodingVideoInfo(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetEncodingConfigurationVideoTranscoderParameter = gql`
mutation setEncodingConfigurationVideoTranscoderParameter($encodingConfigurationVideoId: ID!, $input: TranscoderParameterVideoInput!) {
	setEncodingConfigurationVideoTranscoderParameter(encodingConfigurationVideoId: $encodingConfigurationVideoId, input: $input) {
		id
	}
}
`

export const mutationSetEncodingConfigurationAudioTranscoderParameter = gql`
mutation setEncodingConfigurationAudioTranscoderParameter($encodingConfigurationAudioId: ID!, $input: TranscoderParameterAudioInput!) {
	setEncodingConfigurationAudioTranscoderParameter(encodingConfigurationAudioId: $encodingConfigurationAudioId, input: $input) {
		id
	}
}
`

export const mutationCreateBroadcastEncoder = gql`
mutation createBroadcastEncoder($broadcastId: ID!, $input: BroadcastEncoderInput!) {
	createBroadcastEncoder(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`
export const mutationDeleteBroadcastEncoder = gql`
mutation deleteBroadcastEncoder($broadcastEncoderId: ID!) {
	deleteBroadcastEncoder(broadcastEncoderId: $broadcastEncoderId) {
		success
	}
}
`

export const mutationDeleteBroadcast = gql`
mutation deleteBroadcast($broadcastId: ID!) {
	deleteBroadcast(broadcastId: $broadcastId) {
		success
	}
}
`

export const mutationActivateBroadcastEncoders = gql`
mutation activateBroadcastEncoders($broadcastId: ID!) {
	activateBroadcastEncoders(broadcastId: $broadcastId) {
		id
		broadcastEncoders { encoder { id } }
	}
}
`
export const mutationDeactivateBroadcastEncoders = gql`
mutation deactivateBroadcastEncoders($broadcastId: ID!) {
	deactivateBroadcastEncoders(broadcastId: $broadcastId) {
		id
		broadcastEncoders { encoder { id } }
	}
}
`
export const mutationSetBroadcastStatus = gql`
mutation setBroadcastStatus($broadcastId: ID!, $status: ENUM_BROADCASTSTATUS!) {
	setBroadcastStatus(broadcastId: $broadcastId, status: $status) {
		id
		status
	}
}
`

export const mutationSetBroadcastInputInfo = gql`
mutation setBroadcastInputInfo($broadcastId: ID!, $input: BroadcastInputInfo!) {
	setBroadcastInputInfo(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastInputVideoOverlay = gql`
mutation setBroadcastInputVideoOverlay($broadcastId: ID!, $input: BroadcastInputVideoOverlayInput!) {
	setBroadcastInputVideoOverlay(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastInputInputType = gql`
mutation setBroadcastInputInputType($broadcastId: ID!, $input: BroadcastInputTypeInput!) {
	setBroadcastInputInputType(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`
export const mutationSetBroadcastInputVideoInput = gql`
mutation setBroadcastInputVideoInput($broadcastId: ID!, $input: BroadcastInputVideoInputInput!) {
	setBroadcastInputVideoInput(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastInputVideoMaxFrameSize = gql`
mutation setBroadcastInputVideoMaxFrameSize($broadcastId: ID!, $input: BroadcastInputVideoMaxFrameSizeInput!) {
	setBroadcastInputVideoMaxFrameSize(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastInputVideoMaxFrameRate = gql`
mutation setBroadcastInputVideoMaxFrameRate($broadcastId: ID!, $input: BroadcastInputVideoMaxFrameRateInput!) {
	setBroadcastInputVideoMaxFrameRate(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastInputVideoKeyFrameIntervalMilliseconds = gql`
mutation setBroadcastInputVideoKeyFrameIntervalMilliseconds($broadcastId: ID!, $input: BroadcastInputVideoKeyFrameIntervalMillisecondsInput!) {
	setBroadcastInputVideoKeyFrameIntervalMilliseconds(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastInputVideoAspectRatio = gql`
mutation setBroadcastInputVideoAspectRatio($broadcastId: ID!, $input: BroadcastInputVideoAspectRatioInput!) {
	setBroadcastInputVideoAspectRatio(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastInputAudioChannels = gql`
mutation setBroadcastInputAudioChannels($broadcastId: ID!, $input: BroadcastInputAudioChannelsInput!) {
	setBroadcastInputAudioChannels(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`
export const mutationSetBroadcastInputAudioLevel = gql`
mutation setBroadcastInputAudioLevel($broadcastId: ID!, $input: BroadcastInputAudioLevelInput!) {
	setBroadcastInputAudioLevel(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastTranscodingConfigurationDecodingVideoParameter = gql`
mutation setBroadcastTranscodingConfigurationDecodingVideoParameter($broadcastId: ID!, $input: TranscoderParameterVideoDecodeInput!) {
	setBroadcastTranscodingConfigurationDecodingVideoParameter(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastInputMediaCasterInfo = gql`
mutation setBroadcastInputMediaCasterInfo($broadcastId: ID!, $input: BroadcastMediaCasterInput!) {
	setBroadcastInputMediaCasterInfo(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationCreateBroadcastDisplay = gql`
mutation createBroadcastDisplay($broadcastId: ID!, $input: BroadcastDisplayInput!) {
	createBroadcastDisplay(broadcastId: $broadcastId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastStreamTargetInfo = gql`
mutation setBroadcastStreamTargetInfo($broadcastStreamTargetId: ID!, $input: BroadcastStreamTargetInfo!) {
	setBroadcastStreamTargetInfo(broadcastStreamTargetId: $broadcastStreamTargetId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastDisplayInfo = gql`
mutation setBroadcastDisplayInfo($broadcastDisplayId: ID!, $input: BroadcastDisplayInfo!) {
	setBroadcastDisplayInfo(broadcastDisplayId: $broadcastDisplayId, input: $input) {
		id
	}
}
`
export const mutationDeleteBroadcastDisplay = gql`
mutation deleteBroadcastDisplay($broadcastDisplayId: ID!) {
	deleteBroadcastDisplay(broadcastDisplayId: $broadcastDisplayId) {
		success
	}
}
`

export const mutationDeleteBroadcastRecording = gql`
mutation deleteBroadcastRecording($broadcastRecordingId: ID!) {
	deleteBroadcastRecording(broadcastRecordingId: $broadcastRecordingId) {
		success
	}
}
`

export const mutationRenameBroadcast = gql`
mutation renameBroadcast($broadcastId: ID!, $name: String!) {
	renameBroadcast(broadcastId: $broadcastId, name: $name) {
		id
	}
}
`

export const mutationSetBroadcastEncodingConfigurationVideo = gql`
mutation setBroadcastEncodingConfigurationVideo($encodingConfigurationVideoId: ID!, $input: EncodingConfigurationVideoInfo!) {
	setBroadcastEncodingConfigurationVideo(encodingConfigurationVideoId: $encodingConfigurationVideoId, input: $input) {
		id
	}
}
`

export const mutationSetBroadcastEncodingConfigurationAudio = gql`
mutation setBroadcastEncodingConfigurationAudio($encodingConfigurationAudioId: ID!, $input: EncodingConfigurationAudioInfo!) {
	setBroadcastEncodingConfigurationAudio(encodingConfigurationAudioId: $encodingConfigurationAudioId, input: $input) {
		id
	}
}
`

export const mutationSetSlateOn = gql`
mutation setSlateOn($broadcastId: ID!, $slateName: String) {
	setBroadcastInputInfo(
		broadcastId: $broadcastId,
		input: {
			slateOverrideName: $slateName
			slates: []
	}
) {
		id
		input {
			slateOverrideName
			slates {
				id
				name
				type
				scheduleStartTime
				scheduleDuration
				scheduleRecurrenceRule
			}
		}
	}
}
`

export const mutationSetSlateOff = gql`
mutation setSlateOff($broadcastId: ID!) {
	setBroadcastInputInfo(
		broadcastId: $broadcastId,
		input: {
			slateOverrideName: null,
			slates: []
	}
) {
		id
		input {
			slateOverrideName
			slates {
				id
				name
				type
				scheduleStartTime
				scheduleDuration
				scheduleRecurrenceRule
			}
		}
	}
}
`
