import { createStore } from 'redux';
import NearRealTimeDataCache from './NearRealTimeDataCache';

import AppStrings from '../model/AppStrings';

import LogLevels from '../log/LogLevels';
import { mixObjects } from '../controller/UniversalAdminUtils';
import FacebookConnectionState from './FacebookConnectionState';

var base64 = require('base-64');
var utf8 = require('utf8');
var pako = require('pako');

export const ActionTypes = {
	SET_LOG_LEVEL: 'SET_LOG_LEVEL',
	SET_ACTIVATE_FORM: 'SET_ACTIVATE_FORM',
	SET_ACTIVATE_NO_SUBSCRIPTION: 'SET_ACTIVATE_NO_SUBSCRIPTION',
	LOAD_ARCHIVE_BROADCAST_DATA: 'LOAD_ARCHIVE_BROADCAST_DATA',
	SET_ARCHIVE_BROADCAST_DATA_LOADING: 'SET_ARCHIVE_BROADCAST_DATA_LOADING',
	LOAD_ACCESS_KEYS: 'LOAD_ACCESS_KEYS',
	SET_ACCESS_KEYS_LOADING: 'SET_ACCESS_KEYS_LOADING',
	LOAD_RECORDING_ASSETS: 'LOAD_RECORDING_ASSETS',
	SET_RECORDING_ASSETS_LOADING: 'SET_RECORDING_ASSETS_LOADING',
	LOAD_LIVE_BROADCAST_DATA: 'LOAD_LIVE_BROADCAST_DATA',
	LOAD_BROADCAST_GO_LIVE_EVENTS: 'LOAD_BROADCAST_GO_LIVE_EVENTS',
	SET_BROADCAST_GO_LIVE_EVENTS_DOWNLOADING: 'SET_BROADCAST_GO_LIVE_EVENTS_DOWNLOADING',
	SET_BROADCAST_GO_LIVE_EVENTS_LOADING: 'SET_BROADCAST_GO_LIVE_EVENTS_LOADING',
	UPDATE_REMOTEMODEL_DATA: 'UPDATE_REMOTEMODEL_DATA',
	UPDATE_LIVE_BROADCAST_HEALTHS: 'UPDATE_LIVE_BROADCAST_HEALTHS',
	UPDATE_ENCODER_MAP: 'UPDATE_ENCODER_MAP',
	UPDATE_BROADCAST_MAP: 'UPDATE_BROADCAST_MAP',
	UPDATE_BROADCAST_ACCESSKEYS_MAP: 'UPDATE_BROADCAST_ACCESSKEYS_MAP',
	UPDATE_BROADCAST_STATUS: 'UPDATE_BROADCAST_STATUS',
	SET_BROADCAST_STATE_CHANGING: 'SET_BROADCAST_STATE_CHANGING',
	SHOW_BROADCASTCREATE_DIALOG: 'SHOW_BROADCASTCREATE_DIALOG',
	HIDE_BROADCASTCREATE_DIALOG: 'HIDE_BROADCASTCREATE_DIALOG',
	SHOW_CCACCESSKEYCREATE_DIALOG: 'SHOW_CCACCESSKEYCREATE_DIALOG',
	HIDE_CCACCESSKEYCREATE_DIALOG: 'HIDE_CCACCESSKEYCREATE_DIALOG',
	SHOW_WSCACCESSKEYCREATE_DIALOG: 'SHOW_WSCACCESSKEYCREATE_DIALOG',
	HIDE_WSCACCESSKEYCREATE_DIALOG: 'HIDE_WSCACCESSKEYCREATE_DIALOG',
	SHOW_LINKEDINACCESSTOKENCREATE_DIALOG: 'SHOW_LINKEDINACCESSTOKENCREATE_DIALOG',
	HIDE_LINKEDINACCESSTOKENCREATE_DIALOG: 'HIDE_LINKEDINACCESSTOKENCREATE_DIALOG',
	SHOW_BROADCASTCONFIRMDELETE_DIALOG: 'SHOW_BROADCASTCONFIRMDELETE_DIALOG',
	HIDE_BROADCASTCONFIRMDELETE_DIALOG: 'HIDE_BROADCASTCONFIRMDELETE_DIALOG',
	SHOW_CCACCESSKEYCONFIRMDELETE_DIALOG: 'SHOW_CCACCESSKEYCONFIRMDELETE_DIALOG',
	HIDE_CCACCESSKEYCONFIRMDELETE_DIALOG: 'HIDE_CCACCESSKEYCONFIRMDELETE_DIALOG',
	SHOW_WSCACCESSKEYCONFIRMDELETE_DIALOG: 'SHOW_WSCACCESSKEYCONFIRMDELETE_DIALOG',
	HIDE_WSCACCESSKEYCONFIRMDELETE_DIALOG: 'HIDE_WSCACCESSKEYCONFIRMDELETE_DIALOG',
	SHOW_LINKEDINACCESSTOKENCONFIRMDELETE_DIALOG: 'SHOW_LINKEDINACCESSTOKENCONFIRMDELETE_DIALOG',
	HIDE_LINKEDINACCESSTOKENCONFIRMDELETE_DIALOG: 'HIDE_LINKEDINACCESSTOKENCONFIRMDELETE_DIALOG',
	SHOW_CUSTOMTEMPLATECONFIRMDELETE_DIALOG: 'SHOW_CUSTOMTEMPLATECONFIRMDELETE_DIALOG',
	HIDE_CUSTOMTEMPLATECONFIRMDELETE_DIALOG: 'HIDE_CUSTOMTEMPLATECONFIRMDELETE_DIALOG',
	SHOW_MANAGEDESTINATIONS_DIALOG: 'SHOW_MANAGEDESTINATIONS_DIALOG',
	HIDE_MANAGEDESTINATIONS_DIALOG: 'HIDE_MANAGEDESTINATIONS_DIALOG',
	SHOW_PICKDESTINATION_DIALOG: 'SHOW_PICKDESTINATION_DIALOG',
	HIDE_PICKDESTINATION_DIALOG: 'HIDE_PICKDESTINATION_DIALOG',
	SHOW_MANAGETEMPLATES_DIALOG: 'SHOW_MANAGETEMPLATES_DIALOG',
	HIDE_MANAGETEMPLATES_DIALOG: 'HIDE_MANAGETEMPLATES_DIALOG',
	HIDE_GOLIVECONFIRMATION_DIALOG: 'HIDE_GOLIVECONFIRMATION_DIALOG',
	SHOW_GOLIVECONFIRMATION_DIALOG: 'SHOW_GOLIVECONFIRMATION_DIALOG',
	HIDE_LOGOUTCONFIRMATION_DIALOG: 'HIDE_LOGOUTCONFIRMATION_DIALOG',
	SHOW_LOGOUTCONFIRMATION_DIALOG: 'SHOW_LOGOUTCONFIRMATION_DIALOG',
	SET_GRAPHQLCREDENTIALS: 'SET_GRAPHQLCREDENTIALS',
	SET_GRAPHQLVERSION: 'SET_GRAPHQLVERSION',
	SET_USER_JWT: 'SET_USER_JWT',
	SET_USER_NAMESPACE_ID: 'SET_USER_NAMESPACE_ID',
	SET_USER_NAMES_AND_ROLES: 'SET_USER_NAMES_AND_ROLES',
	SET_USER_SUBSCRIPTION_INFO: 'SET_USER_SUBSCRIPTION_INFO',
	SET_BROADCAST_TEMPLATES: 'SET_BROADCAST_TEMPLATES',
	SHOW_WAITOVERLAY: 'SHOW_WAITOVERLAY',
	HIDE_WAITOVERLAY: 'HIDE_WAITOVERLAY',
	SET_USERS_LOADING: 'SET_USERS_LOADING',
	LOAD_USERS: 'LOAD_USERS',
	SET_SINGULAR_LIVE_APP_INSTANCES: 'SET_SINGULAR_LIVE_APP_INSTANCES',
	SET_SINGULAR_LIVE_DATA: 'SET_SINGULAR_LIVE_DATA',
	SET_SINGULAR_LIVE_SHOWING: 'SET_SINGULAR_LIVE_SHOWING',
	SET_MANAGE_SETTINGS: 'SET_MANAGE_SETTINGS',
	SET_USER_UPDATING: 'SET_USER_UPDATING',
	SET_UPDATE_ENCODER: 'SET_UPDATE_ENCODER',
	SET_ENCODER_SETTINGS: 'SET_ENCODER_SETTINGS',
	SET_NAMESPACE_ASSET_PACKAGE_DATA: 'SET_NAMESPACE_ASSET_PACKAGE_DATA',
	SET_BROADCAST_SHARING: 'SET_BROADCAST_SHARING',
	SET_BROADCAST_SHARING_FACEBOOK_DATA: 'SET_BROADCAST_SHARING_FACEBOOK_DATA',
	SET_BROADCAST_SHARING_FACEBOOK_ACTIVE_BROADCASTS: 'SET_BROADCAST_SHARING_FACEBOOK_ACTIVE_BROADCASTS',
	SET_LOGS_DOWNLOADING: 'SET_LOGS_DOWNLOADING',
	SET_VERSIONS: 'SET_VERSIONS',
	USER_FACEBOOK_LOGIN: 'USER_FACEBOOK_LOGIN',
	USER_FACEBOOK_LOGOUT: 'USER_FACEBOOK_LOGOUT'
};

const initialState = {
	featureGraphQLBuilds:{
		"monitoringGraphs":"0.2.114"
	},
	featureEncoderVersions:{
		"showEncoder":"1.0.8.00",
		"slates":"2.0.2.08",
		"streamTargetToggle":"2.0.2.20"
	},
	logLevel:process.env.REACT_APP_LOG_LEVEL || LogLevels.ERROR,
	graphQLBuild:"0.0.0.00",
	graphQLVersion:"0.0.0.00",
	activateForm:{
		dialogShowing:false,
		loading:false,
		activationCode:"",
		activateName:"",
		acceptedTOS:false,
		error:false,
		errorMessage:"",
		proNoSubscription: false,
	},
	activateNoSubscription:{
		dialogShowing: false
	},
	encoderMap: {},
	encoderList:[],
	accessKeys:[],
	accessKeysMap: {},
	accessKeysLoading: false,
	accessKeysUpdateTime: null,
	accessKeysUpdateInterval: 5 * 60 * 1000,
	broadcastMap: {},
	broadcastAccessKeysMap: {},
	broadcastStateChangingMap: {},
	broadcastArchiveDataMap: {},
	broadcastGoLiveEvents: [],
	broadcastGoLiveEventsDownloading: [],
	broadcastGoLiveEventsLoading: false,
	broadcastGoLiveEventsUpdateTime: null,
	broadcastGoLiveEventsUpdateInterval: 5 * 60 * 1000,
	broadcastLiveDataMap: {},
	broadcastLiveDataWindowSizeMinutes: 10,
	broadcastCreateDialogShowing: false,
	broadcastCreateDialogInfo:undefined,
	broadcastConfirmDelete:undefined,
	broadcastsNumLive:0,
	ccAccessKeyConfirmDelete:undefined,
	ccAccessKeyCreateDialogShowing: false,
	wscAccessKeyConfirmDelete:undefined,
	linkedInAccessTokenConfirmDelete:undefined,
	customTemplateConfirmDelete:undefined,
	wscAccessKeyCreateDialogShowing: false,
	linkedinAccessTokenCreateDialogShowing:false,
	linkedinAuthCode:undefined,
	manageDestinations: undefined,
	pickDestination: undefined,
	manageTemplates: undefined,
	goLiveConfirmationDialog:undefined,
	logoutConfirmationInfo:undefined,
	singularLiveData:{
		loaded:false,
		appInstances:[],
		data:{
			"manage":{
				creating:false,
				editing:'',
				saving:false,
				forceRefresh:false,
				refreshing:false
			}
		},
		showing:{}
	},
	updateEncoder:{
		dialogShowing:false,
		rollback:false,
		rollbackVersion:'',
		localVersions:{},
		encoder:null,
		updating:false
	},
	encoderSettings:{
		dialogShowing:false,
		dialogType:'',
		applyAssetPackage:false,
		assetPackageInfo:{
			encoderAssetsUrl:"",
			encoderAssetsVersion: null,
		},
		encoderName:"",
		encoder:null,
		updating:false,
		updateMessage:""
	},
	namespaceAssetPackageData:{
		enabled:false,
		encoderAssetsName:'ads',
		encoderAssetsVendor:'Wowza',
		encoderAssetsDecryptKey: null,
		encoderAssetsAuthorization: null
	},
	userFacebook:{
		facebookConnectedOnLoad:false,
		facebookConnected:FacebookConnectionState.UNKNOWN,
		accessToken:"",
		email:"",
		id:"",
		name:"",
		picture:null,
		userID:"",
		pages:[],
		pageIdMap:{},
		groups:[],
		events:[]
	},
	userWowza:{
		jwt:"",
		jwtExpiration: null,
		jwtExpired:false,
		admin:false,
		username:"",
		name:"",
		firstName:"",
		lastName:"",
		showExpiredSession:false,
		redirectToLogin:false,
		required:false,
		roles:[],
		namespaceId:null,
		subscriptionInfo:{
			loaded:false,
			service_channel_count:0,
			service_auto_renew_expiration:-1,
			service_non_auto_expiration:-1,
			service_data:{},
			service_subscription_underwater_date:-1
		}
	},
	manageSettings:{
		limitAccess:undefined
	},
	jwtExpirationTime: undefined,
	graphQLCredentials:undefined,
	broadcastTemplates:undefined,
	broadcastTemplatesUniqueBitrates:[],
	broadcastTemplateCounts:[0,0,0,0],
	namespaceProviderKeys:[],
	namespaceProviderKeyMap:{},
	recordingAssetsMap:{},
	recordingAssetsLoading:false,
	recordingAssetsUpdateTime: null,
	recordingAssetsUpdateInterval: 5 * 60 * 1000,
	isLoadingOverlayShowing:false,
	loadingOverlayMessage:"Loading...",
	usersLoading:false,
	usersUpdating:[],
	users: [],
	usersUpdateTime: null,
	broadcastSharing: {
		active:false,
		showGetPassphraseDialog:false,
		tryingPassphrase:false,
		showBroadcastShareDialog:false,
		broadcastShareDialogId:-1,
		deletingKey:undefined,
		error:undefined,
		accessKey:undefined
	},
	broadcastSharingFacebookData: {
		ui: {
			state:'loading',
			showChooseDestinationConfirmationDialog:false,
			showSettingsDialog:false,
			createNewBroadcast:false
		},
		broadcast:{
			dash_preview_url:'',
			dash_preview_secondary_url:'',
			description:'',
			id:'',
			overlay_url:'',
			permalink_url:'',
			secure_stream_url:'',
			secure_stream_secondary_url:'',
			status:'',
			title:''
		},
		destination:{
			id:'',
			name:'',
			privacy:'',
			type:''
		},
		doSpeedTest:'true',
		isPrimary:'',
		endFacebookBroadcast:'true',
		saved:'false',
		video:{
			id:''
		}
	},
	broadcastSharingFacebookActiveBroadcasts: {
		activeBroadcasts:[],
		activeBroadcastMap: {}
	},
	logsDownloading: {
		downloading: [],
		errors: []
	},
	versions: undefined
};

function updateEncoder(encoder)
{
	let currDateMS = new Date().getTime();

	encoder.isOnline = false;
	encoder.broadcastStatus = AppStrings.app.streamStatusReady;
	encoder.clearCasterSoftwareVersion = "1.0.0.01";

	if (encoder.hasOwnProperty("encoderStatus") && encoder.encoderStatus != null)
	{
		if (encoder.encoderStatus.hasOwnProperty("updatedAt") && encoder.encoderStatus.updatedAt != null)
		{
			let udpatedAt = encoder.encoderStatus.updatedAt;

			//2019-01-21T22:17:21.000Z

			let updatedAtMS = Date.parse(udpatedAt);

			if ((currDateMS - updatedAtMS) <= 10000)
				encoder.isOnline = true;

			if (encoder.encoderStatus.clearCasterSoftwareVersion)
				encoder.clearCasterSoftwareVersion = encoder.encoderStatus.clearCasterSoftwareVersion;
		}

		if (encoder.isOnline && encoder.encoderStatus.hasOwnProperty("status") && encoder.encoderStatus.status != null)
		{
			switch(encoder.encoderStatus.status)
			{
			case "CREATE":
				encoder.broadcastStatus = AppStrings.app.streamStatusReady;
				break;
			case "IDLE":
				encoder.broadcastStatus = AppStrings.app.streamStatusReady;
				break;
			case "PREVIEW":
				encoder.broadcastStatus = AppStrings.app.streamStatusPreview;
				break;
			case "LIVE":
				encoder.broadcastStatus = AppStrings.app.streamStatusLive;
				break;
			case "STOPPED":
				encoder.broadcastStatus = AppStrings.app.streamStatusReady;
				break;
			case "DISCONNECTED":
				encoder.broadcastStatus = AppStrings.app.streamStatusReady;
				break;
			default:
			}
		}
	}
}

function updateBroadcast(broadcast)
{
	if (broadcast.extraProperties !== undefined)
	{
		for(let i in broadcast.extraProperties)
		{
			let property = broadcast.extraProperties[i];
			if (property.name === "createBroadcastInfo")
			{
				try
				{
					if (property.value.startsWith("v1:"))
					{
						let binaryString = base64.decode(property.value.substring(3));
						broadcast.createBroadcastInfo = JSON.parse(pako.inflate(binaryString, { to: 'string' }));
					}
					else
					{
						var bytes = base64.decode(property.value);
						var text = utf8.decode(bytes);
						broadcast.createBroadcastInfo = JSON.parse(text);
					}
				}
				catch(error)
				{
				}
			}
		}
	}

	if (broadcast.outputs !== undefined && broadcast.outputs.length > 0)
	{
		for(let oi in broadcast.outputs)
		{
			let broadcastOutput = broadcast.outputs[oi];
			if (broadcastOutput.streamTargets !== undefined && broadcastOutput.streamTargets.length > 0)
			{
				for(let si in broadcastOutput.streamTargets)
				{
					let broadcastStreamTarget = broadcastOutput.streamTargets[si];
					if (broadcastStreamTarget.providerKey && broadcastStreamTarget.providerKey.id)
						broadcastStreamTarget.providerKeyId = broadcastStreamTarget.providerKey.id;

					delete broadcastStreamTarget.providerKey;
				}
			}
		}
	}
}

function clearCasterStore (
	state = initialState, action)
{
	let now = new Date();
	switch (action.type)
	{
		case ActionTypes.SHOW_WAITOVERLAY:
			state.isLoadingOverlayShowing = true;
			if (action.loadingOverlayMessage !== undefined)
				state.loadingOverlayMessage = action.loadingOverlayMessage;
			return state;
		case ActionTypes.HIDE_WAITOVERLAY:
			state.isLoadingOverlayShowing = false;
			state.loadingOverlayMessage = "Loading...";
			return state;
		case ActionTypes.SET_GRAPHQLCREDENTIALS:
			state.graphQLCredentials = action.graphQLCredentials;
			return state;
		case ActionTypes.SET_GRAPHQLVERSION:
			state.graphQLBuild = action.build;
			state.graphQLVersion = action.version;
			console.log("GraphQLRelease: build:"+action.build+" version:"+action.version);
			return state;
		case ActionTypes.SET_USER_JWT:
			state.userWowza.jwt = action.jwt;
			state.userWowza.username = action.username;
			return state;
		case ActionTypes.SET_USER_NAMESPACE_ID:
			state.userWowza.namespaceId = action.namespaceId;
			console.log("namespaceId:"+action.namespaceId);
			return state;
		case ActionTypes.SET_USER_NAMES_AND_ROLES:
			state.userWowza.admin = action.admin;
			state.userWowza.id = action.id;
			state.userWowza.roles = action.roles;
			state.userWowza.email = action.email;
			state.userWowza.firstName = action.firstName;
			state.userWowza.lastName = action.lastName;
			return state;
		case ActionTypes.SET_USER_SUBSCRIPTION_INFO:
			state.userWowza.subscriptionInfo = {loaded:true,...action.subscriptionInfo};
			return state;
		case ActionTypes.SET_ACTIVATE_FORM:
			if (action.dialogShowing != null)
				state.activateForm.dialogShowing = action.dialogShowing;
			if (action.loading != null)
				state.activateForm.loading = action.loading;
			if (action.activationCode != null)
				state.activateForm.activationCode = action.activationCode;
			if (action.activateName != null)
				state.activateForm.activateName = action.activateName;
			if (action.acceptedTOS != null)
				state.activateForm.acceptedTOS = action.acceptedTOS;
			if (action.tosError != null)
				state.activateForm.tosError = action.tosError;
			if (action.proNoSubscription != null)
				state.activateForm.proNoSubscription = action.proNoSubscription;
			return state;
		case ActionTypes.SET_ACTIVATE_NO_SUBSCRIPTION:
			if (action.dialogShowing != null)
				state.activateNoSubscription.dialogShowing = action.dialogShowing;
			return state
		case ActionTypes.SET_BROADCAST_TEMPLATES:
			state.broadcastTemplates = action.broadcastTemplates;
			state.broadcastTemplatesUniqueBitrates = action.uniqueBitrates;
			state.broadcastTemplateCounts = action.customTemplateCounts;
			return state;
		case ActionTypes.SHOW_BROADCASTCONFIRMDELETE_DIALOG:
			state.broadcastConfirmDelete = action.broadcastInfo;
			return state;
		case ActionTypes.HIDE_BROADCASTCONFIRMDELETE_DIALOG:
			state.broadcastConfirmDelete = undefined;
			return state;
		case ActionTypes.SHOW_CCACCESSKEYCONFIRMDELETE_DIALOG:
			state.ccAccessKeyConfirmDelete = action.ccAccessKeyInfo;
			return state;
		case ActionTypes.HIDE_CCACCESSKEYCONFIRMDELETE_DIALOG:
			state.ccAccessKeyConfirmDelete = undefined;
			return state;
		case ActionTypes.SHOW_WSCACCESSKEYCONFIRMDELETE_DIALOG:
			state.wscAccessKeyConfirmDelete = action.wscAccessKeyInfo;
			return state;
		case ActionTypes.HIDE_WSCACCESSKEYCONFIRMDELETE_DIALOG:
			state.wscAccessKeyConfirmDelete = undefined;
			return state;
		case ActionTypes.SHOW_LINKEDINACCESSTOKENCONFIRMDELETE_DIALOG:
			state.linkedInAccessTokenConfirmDelete = action.linkedInAccessTokenInfo;
			return state;
		case ActionTypes.HIDE_LINKEDINACCESSTOKENCONFIRMDELETE_DIALOG:
			state.linkedInAccessTokenConfirmDelete = undefined;
			return state;
		case ActionTypes.SHOW_CUSTOMTEMPLATECONFIRMDELETE_DIALOG:
			state.customTemplateConfirmDelete = action.context;
			return state;
		case ActionTypes.SHOW_PICKDESTINATION_DIALOG:
			state.pickDestination = action.context;
			return state;
		case ActionTypes.HIDE_PICKDESTINATION_DIALOG:
			state.pickDestination = undefined;
			return state;
		case ActionTypes.HIDE_CUSTOMTEMPLATECONFIRMDELETE_DIALOG:
			state.customTemplateConfirmDelete = undefined;
			return state;
		case ActionTypes.SHOW_MANAGEDESTINATIONS_DIALOG:
			state.manageDestinations = {};
			return state;
		case ActionTypes.HIDE_MANAGEDESTINATIONS_DIALOG:
			state.manageDestinations = undefined;
			return state;
		case ActionTypes.SHOW_MANAGETEMPLATES_DIALOG:
			state.manageTemplates = {templateCategoryId: action.templateCategoryId};
			return state;
		case ActionTypes.HIDE_MANAGETEMPLATES_DIALOG:
			state.manageTemplates = undefined;
			return state;
		case ActionTypes.SHOW_LOGOUTCONFIRMATION_DIALOG:
			state.logoutConfirmationInfo = action.logoutConfirmationInfo;
			return state;
		case ActionTypes.HIDE_LOGOUTCONFIRMATION_DIALOG:
			state.logoutConfirmationInfo = undefined;
			return state;
		case ActionTypes.SHOW_BROADCASTCREATE_DIALOG:
			state.broadcastCreateDialogShowing = true;
			state.broadcastCreateDialogInfo = action.broadcastCreateDialogInfo;
			return state;
		case ActionTypes.HIDE_BROADCASTCREATE_DIALOG:
			state.broadcastCreateDialogShowing = false;
			state.broadcastCreateDialogInfo = undefined;
			return state;
		case ActionTypes.SHOW_CCACCESSKEYCREATE_DIALOG:
			state.ccAccessKeyCreateDialogShowing = true;
			return state;
		case ActionTypes.HIDE_CCACCESSKEYCREATE_DIALOG:
			state.ccAccessKeyCreateDialogShowing = false;
			return state;
		case ActionTypes.SHOW_WSCACCESSKEYCREATE_DIALOG:
			state.wscAccessKeyCreateDialogShowing = true;
			return state;
		case ActionTypes.HIDE_WSCACCESSKEYCREATE_DIALOG:
			state.wscAccessKeyCreateDialogShowing = false;
			return state;
		case ActionTypes.SHOW_LINKEDINACCESSTOKENCREATE_DIALOG:
			state.linkedinAccessTokenCreateDialogShowing = true;
			state.linkedinAuthCode = action.authCode;
			return state;
		case ActionTypes.HIDE_LINKEDINACCESSTOKENCREATE_DIALOG:
			state.linkedinAccessTokenCreateDialogShowing = false;
			state.linkedinAuthCode = undefined;
			return state;
		case ActionTypes.SHOW_GOLIVECONFIRMATION_DIALOG:
			state.goLiveConfirmationDialog = action.data;
			return state;
		case ActionTypes.HIDE_GOLIVECONFIRMATION_DIALOG:
			state.goLiveConfirmationDialog = undefined;
			return state;
		case ActionTypes.LOAD_ACCESS_KEYS:
			state.accessKeys = action.accessKeys;
			state.accessKeysMap = action.accessKeysMap;
			state.accessKeysUpdateTime = now;
			return state;
		case ActionTypes.SET_ACCESS_KEYS_LOADING:
			state.accessKeysLoading = action.loading;
			return state;
		case ActionTypes.LOAD_RECORDING_ASSETS:
			state.recordingAssetsMap = action.recordingAssetsMap;
			state.recordingAssetsUpdateTime = now;
			return state;
		case ActionTypes.SET_RECORDING_ASSETS_LOADING:
			state.recordingAssetsLoading = action.loading;
			return state;
		case ActionTypes.LOAD_ARCHIVE_BROADCAST_DATA:
			let archiveBroadcastGoLiveId = action.broadcastGoLiveId;
			let broadcastArchiveData = {};
			if (state.broadcastArchiveDataMap[archiveBroadcastGoLiveId] !== undefined)
			{
				broadcastArchiveData = state.broadcastArchiveDataMap[archiveBroadcastGoLiveId];
			}
			else
			{
				broadcastArchiveData = {
					broadcastId:null,
					startDateTime:null,
					endDateTime:null,
					loading:true,
					data:[]
				};
			}
			broadcastArchiveData.broadcastId = action.broadcastId;
			broadcastArchiveData.startDateTime = action.startDateTime;
			broadcastArchiveData.endDateTime = action.endDateTime;
			broadcastArchiveData.data = NearRealTimeDataCache.insertEvents(broadcastArchiveData.data,action.data);
			state.broadcastArchiveDataMap[archiveBroadcastGoLiveId] = broadcastArchiveData;
			return state;
		case ActionTypes.SET_ARCHIVE_BROADCAST_DATA_LOADING:
			let archiveBroadcastGoLiveId2 = action.broadcastGoLiveId;
			let broadcastArchiveData2 = {};
			if (state.broadcastArchiveDataMap[archiveBroadcastGoLiveId2] !== undefined)
			{
				broadcastArchiveData2 = state.broadcastArchiveDataMap[archiveBroadcastGoLiveId2];
			}
			else
			{
				broadcastArchiveData2 = {
					broadcastId:null,
					startDateTime:null,
					endDateTime:null,
					loading:true,
					data:[]
				};
			}
			broadcastArchiveData2.loading = action.loading;
			state.broadcastArchiveDataMap[archiveBroadcastGoLiveId2] = broadcastArchiveData2;
			return state;
		case ActionTypes.LOAD_LIVE_BROADCAST_DATA:
			let broadcastId = action.broadcastId;
			let windowStartTime = new Date(now.getTime() - (state.broadcastLiveDataWindowSizeMinutes * 60000));
			let broadcastData = [];
			if (state.broadcastLiveDataMap[broadcastId] !== undefined)
			{
				broadcastData = state.broadcastLiveDataMap[broadcastId];
			}

			broadcastData = NearRealTimeDataCache.insertEvents(broadcastData,action.data);
			broadcastData = NearRealTimeDataCache.trim(broadcastData,windowStartTime,now);

			state.broadcastLiveDataMap[broadcastId] = broadcastData;
			return state;
		case ActionTypes.LOAD_BROADCAST_GO_LIVE_EVENTS:
			state.broadcastGoLiveEvents = action.data;
			state.broadcastGoLiveEventsUpdateTime = now;
			return state;
		case ActionTypes.SET_BROADCAST_GO_LIVE_EVENTS_DOWNLOADING:
			state.broadcastGoLiveEventsDownloading = action.downloading;
			return state;
		case ActionTypes.SET_BROADCAST_GO_LIVE_EVENTS_LOADING:
			state.broadcastGoLiveEventsLoading = action.loading;
			return state;
		case ActionTypes.UPDATE_REMOTEMODEL_DATA:
			try
			{
				let encoderMapNew;
				let broadcastMapNew;
				let broadcastsNumLive = 0;

				if (action.encoders !== undefined)
				{
					encoderMapNew = {};
					for(let item in action.encoders)
					{
						let encoder = action.encoders[item];
						if (encoder.id !== undefined)
						{
							updateEncoder(encoder);
							encoderMapNew[encoder.id] = encoder;
						}
					}
				}

				if (action.broadcasts !== undefined)
				{
					broadcastMapNew = {};
					for(let item in action.broadcasts)
					{
						let broadcast = action.broadcasts[item];
						if (broadcast.id !== undefined)
						{
							updateBroadcast(broadcast);
							broadcastMapNew[broadcast.id] = broadcast;
							if (broadcast.status && broadcast.status === 'LIVE')
							{
								broadcastsNumLive ++;
							}
						}
					}
				}

				if (encoderMapNew !== undefined)
				{
					let encoderList = [];

					for(let i in encoderMapNew)
					{
						let encoder = encoderMapNew[i];
						encoderList.push(encoder);
					}

					encoderList.sort(function (a, b) {
						let nameA=a.name.toLowerCase()
						let nameB=b.name.toLowerCase();
						if (nameA < nameB)
							return -1;

						if (nameA > nameB)
							return 1;

						return 0;
					})

					state.encoderMap = encoderMapNew;
					state.encoderList = encoderList;
				}

				if (broadcastMapNew !== undefined)
				{
					state.broadcastMap = broadcastMapNew;
					state.broadcastsNumLive = broadcastsNumLive;
				}

				if (action.namespace !== undefined)
				{
					if (action.namespace.providerKeys !== undefined)
					{
						let providerKeys = action.namespace.providerKeys;

						let providerKeyMap = {};
						for(let pk in providerKeys)
						{
							providerKeyMap[providerKeys[pk].id] = providerKeys[pk];
						}

						providerKeys.sort((a,b) => { if (a.name === undefined || b.name === undefined) return 0; return a.name.localeCompare(b.name); });

						state.namespaceProviderKeys = providerKeys;
						state.namespaceProviderKeyMap = providerKeyMap;
					}
				}
			}
			catch(error)
			{
				console.log("ERROR: "+error.message);
			}
			return state;
		case ActionTypes.UPDATE_LIVE_BROADCAST_HEALTHS:
			try
			{
				if (action.broadcasts !== undefined)
				{
					let broadcastLiveDataMapNew = {...state.broadcastLiveDataMap};
					for (let liveBroadcastId in action.broadcasts)
					{
						let broadcast = action.broadcasts[liveBroadcastId];
						let broadcastData = [];
						if (broadcastLiveDataMapNew[liveBroadcastId] !== undefined)
						{
							broadcastData = broadcastLiveDataMapNew[liveBroadcastId];
						}
						if (broadcast.nearRealTimeData !== null)
						{
							broadcastData = NearRealTimeDataCache.insertEvent(broadcastData,broadcast.nearRealTimeData);
							let now = new Date();
							let windowStartTime = new Date(now.getTime() - (state.broadcastLiveDataWindowSizeMinutes * 60000));
							broadcastData = NearRealTimeDataCache.trim(broadcastData,windowStartTime,now);
						}

						broadcastLiveDataMapNew[broadcast.id] = broadcastData;
					}
					state.broadcastLiveDataMap = broadcastLiveDataMapNew;
				}
			}
			catch(error)
			{
				console.log("ERROR: "+error.message);
			}
			return state;
		case ActionTypes.SET_BROADCAST_STATE_CHANGING:
			if (action.changing)
			{
				state.broadcastStateChangingMap[action.broadcastId] = true;
			}
			else
			{
				delete state.broadcastStateChangingMap[action.broadcastId];
			}
			return state;
		case ActionTypes.UPDATE_BROADCAST_ACCESSKEYS_MAP:
			state.broadcastAccessKeysMap = action.broadcastAccessKeysMap;
			return state;
		case ActionTypes.UPDATE_BROADCAST_STATUS:
			let newBroadcast = state.broadcastMap[action.broadcast.id];
			newBroadcast = {...newBroadcast, status: action.broadcast.status}
			let newBroadcastMap = state.broadcastMap;
			newBroadcastMap = {...newBroadcastMap, [newBroadcast.id]: newBroadcast}
			return {...state, broadcastMap: newBroadcastMap}
		case ActionTypes.SET_SINGULAR_LIVE_APP_INSTANCES:
			state.singularLiveData.loaded = true;
			state.singularLiveData.appInstances = action.appInstances;
			state.singularLiveData.data = mixObjects(state.singularLiveData.data,action.data);
			return state;
		case ActionTypes.SET_SINGULAR_LIVE_DATA:
			state.singularLiveData.data = mixObjects(state.singularLiveData.data,action.data);
			return state;
		case ActionTypes.SET_SINGULAR_LIVE_SHOWING:
			if (action.graphic != null)
			{
				if (typeof state.singularLiveData.showing[action.appInstanceId] === 'boolean')
				{
					state.singularLiveData.showing[action.appInstanceId] = {};
				}
				state.singularLiveData.showing[action.appInstanceId][action.graphic] = action.showing;
			}
			else
			{
				state.singularLiveData.showing[action.appInstanceId] = action.showing;
			}
			return state;
		case ActionTypes.SET_LOG_LEVEL:
			state.logLevel = action.logLevel;
			return state;
		case ActionTypes.SET_USERS_LOADING:
			state.usersLoading = action.loading;
			return state;
		case ActionTypes.LOAD_USERS:
			state.users = action.users;
			state.usersLoading = false;
			state.usersUpdateTime = now;
			return state;
		case ActionTypes.SET_MANAGE_SETTINGS:
			state.manageSettings = action.manageSettings;
			return state;
		case ActionTypes.SET_USER_UPDATING:
			if (action.updating)
			{
				if (state.usersUpdating.indexOf(action.id) === -1) {
					state.usersUpdating.push(action.id);
				}
			}
			else
			{
				if (state.usersUpdating.indexOf(action.id) >= 0)
				{
					state.usersUpdating.splice(state.usersUpdating.indexOf(action.id),1);
				}
			}
			return state;
		case ActionTypes.SET_UPDATE_ENCODER:
			if (action.dialogShowing != null)
				state.updateEncoder.dialogShowing = action.dialogShowing;
			if (action.rollback != null)
				state.updateEncoder.rollback = action.rollback;
			if (action.rollbackVersion != null)
				state.updateEncoder.rollbackVersion = action.rollbackVersion;
			if (action.localVersions != null)
				state.updateEncoder.localVersions = action.localVersions;
			if (action.encoder != null)
				state.updateEncoder.encoder = action.encoder;
			if (action.updating != null)
				state.updateEncoder.updating = action.updating;
			return state;
		case ActionTypes.SET_ENCODER_SETTINGS:
			if (action.dialogShowing != null)
				state.encoderSettings.dialogShowing = action.dialogShowing;
			if (action.dialogType != null)
				state.encoderSettings.dialogType = action.dialogType;
			if (action.applyAssetPackage != null)
				state.encoderSettings.applyAssetPackage = action.applyAssetPackage;
			if (action.assetPackageInfo != null)
				state.encoderSettings.assetPackageInfo = action.assetPackageInfo;
			if (action.encoderName != null)
				state.encoderSettings.encoderName = action.encoderName;
			if (action.encoder != null)
				state.encoderSettings.encoder = action.encoder;
			if (action.updating != null)
				state.encoderSettings.updating = action.updating;
			if (action.updateMessage != null)
				state.encoderSettings.updateMessage = action.updateMessage;
			if (action.confirmRestart != null)
				state.encoderSettings.confirmRestart = action.confirmRestart;
			if (action.confirmAssetPackageApplied != null)
				state.encoderSettings.confirmAssetPackageApplied = action.confirmAssetPackageApplied;
			return state;
		case ActionTypes.SET_NAMESPACE_ASSET_PACKAGE_DATA:
			if (action.namespaceAssetPackageData.enabled != null)
				state.namespaceAssetPackageData.enabled = action.namespaceAssetPackageData.enabled;
			if (action.namespaceAssetPackageData.encoderAssetsName != null)
				state.namespaceAssetPackageData.encoderAssetsName = action.namespaceAssetPackageData.encoderAssetsName;
			if (action.namespaceAssetPackageData.encoderAssetsVendor != null)
				state.namespaceAssetPackageData.encoderAssetsVendor = action.namespaceAssetPackageData.encoderAssetsVendor;
			if (action.namespaceAssetPackageData.encoderAssetsDecryptKey != null)
				state.namespaceAssetPackageData.encoderAssetsDecryptKey = action.namespaceAssetPackageData.encoderAssetsDecryptKey;
			if (action.namespaceAssetPackageData.encoderAssetsAuthorization != null)
				state.namespaceAssetPackageData.encoderAssetsAuthorization = action.namespaceAssetPackageData.encoderAssetsAuthorization;
			return state;
		case ActionTypes.SET_BROADCAST_SHARING:
			if (action.active != null)
				state.broadcastSharing.active = action.active;
			if (action.showGetPassphraseDialog != null)
				state.broadcastSharing.showGetPassphraseDialog = action.showGetPassphraseDialog;
			if (action.tryingPassphrase != null) {
				state.broadcastSharing.tryingPassphrase = action.tryingPassphrase;
			}
			if (action.showBroadcastShareDialog != null)
				state.broadcastSharing.showBroadcastShareDialog = action.showBroadcastShareDialog;
			if (action.broadcastShareDialogId != null)
				state.broadcastSharing.broadcastShareDialogId = action.broadcastShareDialogId;
			if (action.deletingKey !== undefined)
				state.broadcastSharing.deletingKey = action.deletingKey;
			if (action.deletingKey === null)
				state.broadcastSharing.deletingKey = undefined;
			if (action.error !== undefined)
				state.broadcastSharing.error = action.error;
			if (action.error === null)
				state.broadcastSharing.error = undefined;
			if (action.graphQLCredentials != null)
				state.graphQLCredentials = action.graphQLCredentials;
			if (action.accessKey !== undefined)
				state.broadcastSharing.accessKey = action.accessKey;
			return state;
		case ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_DATA:
			state.broadcastSharingFacebookData = {...action.facebookData};
			return state;
		case ActionTypes.SET_BROADCAST_SHARING_FACEBOOK_ACTIVE_BROADCASTS:
			state.broadcastSharingFacebookActiveBroadcasts = {...action.facebookActiveBroadcasts};
			return state;
		case ActionTypes.SET_LOGS_DOWNLOADING:
			if (action.downloading != null)
				state.logsDownloading.downloading = action.downloading;
			if (action.errors != null)
				state.logsDownloading.errors = action.errors;
			return state;
		case ActionTypes.SET_VERSIONS:
			state.versions = action.versions;
			return state;
		case ActionTypes.USER_FACEBOOK_LOGIN:
			let newUserFacebookLogin = {...state.userFacebook};
			for (var userInfoKey in action.userInfo)
			{
				newUserFacebookLogin[userInfoKey] = action.userInfo[userInfoKey];
			}
			state.userFacebook = newUserFacebookLogin;
			return state;
		case ActionTypes.USER_FACEBOOK_LOGOUT:
			let newUserFacebookLogout = {
				facebookConnectedOnLoad: state.facebookConnectedOnLoad,
				facebookConnected:FacebookConnectionState.NOT_CONNECTED,
				accessToken:"",
				email:"",
				id:"",
				name:"",
				picture:null,
				userID:""
			};
			state.userFacebook = newUserFacebookLogout;
			return state;
		default:
			return state;
	}
}

export default createStore(
	clearCasterStore,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
